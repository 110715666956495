import { useQuery } from "react-query";
import axios from "axios";
import config from "../../../config/config";
import { VDSItemSections } from "features/sheets/types";
import { SheetStatus } from "features/sheets/config/statusConfig";

type SubsegmentTextBlock = {
  TextBlockID: string;
  Revision: string;
  Status: SheetStatus;
  RevDate: string;
  LastUpdateBy: string;
  TextBlockName: string;
};

export default function useVDSSubsegmentTextBlock({
  itemSection,
  itemId,
  disabled,
}: {
  itemSection?: VDSItemSections;
  itemId?: number;
  disabled?: boolean;
}) {
  return useQuery<SubsegmentTextBlock[], Error>(
    ["vds-subsegments/vds-textblocks", itemSection, itemId],
    async () => {
      const { data } = await axios.get<{
        getSubsegmentTextblocks: SubsegmentTextBlock[];
      }>(
        `${config.restApiBaseUrl}vds-subsegments/vds-textblocks?&ItemSection=${itemSection}&ItemID=${itemId}`
      );
      return data["getSubsegmentTextblocks"];
    },
    {
      enabled: !disabled && !!itemSection && !!itemId,
    }
  );
}
