import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import config from "config/config";

export type CreateEDSRequest = {
  name?: string;
  revision?: string;
  operation: "Add" | "Copy";
  newName: string;
  newRevision: string;
};

export default function useCreateEDS() {
  const queryClient = useQueryClient();

  async function createEDS({
    name,
    revision,
    operation,
    newName,
    newRevision,
  }: CreateEDSRequest) {
    return await axios({
      url: config.restApiBaseUrl + `eds`,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: {
        Operation: operation,
        EDS: name,
        Revision: revision,
        NewName: newName,
        NewRevision: newRevision,
      },
    });
  }

  return useMutation(createEDS, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sheets", "eds"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
