import { useQuery } from "react-query";
import axios from "axios";
import { getApiUrl } from "../../../queries/queryUtil";

export type VDSTaskMonitorItem = {
  JobName: string;
  Status: string;
  StartDate: string;
  RunDuration: string;
  TaskDescription: string;
  ElapsedTime: string;
};

type VDSTaskMonitorReply = {
  getTaskMonitor: VDSTaskMonitorItem[];
};

export default function useVDSTaskMonitor({
  refetchInterval,
}: {
  refetchInterval?: number;
}) {
  return useQuery<VDSTaskMonitorItem[], Error>(
    ["vds-task-monitor"],
    async () => {
      const { data } = await axios.get<VDSTaskMonitorReply>(
        getApiUrl("vds/task-monitor")
      );
      return data.getTaskMonitor;
    },
    {
      refetchInterval,
    }
  );
}
