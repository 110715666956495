import {
  Button,
  NativeSelect,
  Radio,
  Tabs,
  TextField,
  Typography,
} from "@equinor/eds-core-react";
import React, { useState } from "react";
import { FormOptionsContainer } from "components/form/Form";
import { useAppSelector } from "../../../app/hooks";
import { FlexElement, LinearLoader } from "../../../components/Components";
import {
  PanelButtonContainer,
  PanelContainer,
} from "../../../components/Panels";
import StatusModal from "../../../components/StatusModal";
import { useTabs } from "../../../components/tabs/useTabs";
import { useCodelist } from "../../../queries/useCodelist";
import usePCSCommandQuery from "../queries/usePCSCommandQuery";
import { selectMainPlant } from "../../plant/plantSlice";
import { codelistProperties, Codelists } from "../../../queries/useCodelist";
import { reloadPreviewPane } from "../SheetPane";
import { pcsCommandOnSelected } from "../commands";
import { successMessage } from "../../../utils/successMessage";
import useConfirm from "components/confirm/useConfirm";
import { PCSConfirmQuestion } from "./ConfirmQuestion";

function UpdateCodelist({
  selectedItems,
  codelist,
  pcsCommand,
  remove,
}: {
  selectedItems: any[];
  codelist: Codelists;
  pcsCommand: Function;
  remove?: boolean;
}) {
  const { status, data } = useCodelist({ codelist: codelist });
  const codelistProp = codelistProperties[codelist];
  const [selected, setSelected] = useState("_default");
  const mainPlant = useAppSelector(selectMainPlant);
  const { isConfirmed } = useConfirm();

  return (
    <PanelContainer>
      {status === "error" ? (
        <Typography color="error">
          Error loading {codelistProp.titlePlural}
        </Typography>
      ) : status === "loading" ? (
        <div style={{ maxWidth: 300 }}>
          <LinearLoader label={`Loading ${codelistProp.titlePlural}...`} />
        </div>
      ) : (
        status === "success" &&
        data && (
          <NativeSelect
            id={codelist}
            label=""
            style={{ maxWidth: 300 }}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            <option value="_default">
              Select {codelistProp.titleSingular}...
            </option>
            {remove && <option value="">- Remove property -</option>}
            {data.map((item: any) => {
              return (
                <option
                  key={item[codelistProp.idProp]}
                  value={item[codelistProp.idProp]}
                >
                  {item[codelistProp.nameProp]}
                </option>
              );
            })}
          </NativeSelect>
        )
      )}
      <PanelButtonContainer>
        <Button
          disabled={selectedItems.length === 0 || selected === "_default"}
          onClick={async () =>
            (await isConfirmed(
              PCSConfirmQuestion({
                message: `${
                  selected === ""
                    ? `remove ${codelistProp.titleSingular} from`
                    : `update ${codelistProp.titleSingular} to ${
                        // @ts-ignore
                        data?.find(
                          (e) =>
                            String(e[codelistProp.idProp as keyof typeof e]) ===
                            selected
                        )[codelistProp.nameProp]
                      } in`
                }`,
                selectedItems,
              })
            )) &&
            pcsCommandOnSelected({
              selectedItems,
              mainPlant,
              command: "properties",
              method: "put",
              pcsCommand,
              extraParams: {
                PropertyType: codelistProp?.property,
                MaterialGroupID: codelist === "material-groups" ? selected : "",
                RatingClassID: codelist === "rating-classes" ? selected : "",
                SpecialReqID:
                  codelist === "special-requirements" ? selected : "",
                NotepadAction: "",
                NotepadText: "",
                TubePCSMeasurement: "",
                NewVDSSection: "",
              },
            })
          }
        >
          Update {codelistProp.titleSingular} Property
        </Button>
      </PanelButtonContainer>
    </PanelContainer>
  );
}

function UpdateWithRadio({
  selectedItems,
  options,
  property,
  buttonText,
  pcsCommand,
}: {
  selectedItems: any[];
  options: { title: string; value: string }[];
  property: string;
  buttonText: string;
  pcsCommand: Function;
}) {
  const [selected, setSelected] = useState("");
  const mainPlant = useAppSelector(selectMainPlant);
  const { isConfirmed } = useConfirm();
  return (
    <PanelContainer>
      <FormOptionsContainer>
        {options.map((option) => (
          <FlexElement key={option.title}>
            <Radio
              label={option.title}
              checked={selected === option.value}
              onChange={() => setSelected(option.value)}
            />
          </FlexElement>
        ))}
      </FormOptionsContainer>
      <PanelButtonContainer>
        <Button
          disabled={selectedItems.length === 0 || selected === ""}
          onClick={async () =>
            (await isConfirmed(
              PCSConfirmQuestion({
                message: `${buttonText} to ${
                  options.find((e) => e.value === selected)?.title
                } in`,
                selectedItems,
              })
            )) &&
            pcsCommandOnSelected({
              selectedItems,
              mainPlant,
              command: "properties",
              method: "put",
              pcsCommand,
              extraParams: {
                PropertyType: property,
                MaterialGroupID: "",
                RatingClassID: "",
                SpecialReqID: "",
                NotepadAction: "",
                NotepadText: "",
                TubePCSMeasurement:
                  property === "TubePCS"
                    ? selected === "_empty"
                      ? ""
                      : selected
                    : "",
                NewVDSSection: property === "NewVDSSection" ? selected : "",
              },
            })
          }
        >
          {buttonText}
        </Button>
      </PanelButtonContainer>
    </PanelContainer>
  );
}

function UpdateNote({
  selectedItems,
  pcsCommand,
}: {
  selectedItems: any[];
  pcsCommand: Function;
}) {
  const [selected, setSelected] = useState("add");
  const [note, setNote] = useState("");
  const mainPlant = useAppSelector(selectMainPlant);
  const { isConfirmed } = useConfirm();
  return (
    <PanelContainer>
      <FormOptionsContainer style={{ marginBottom: 12 }}>
        <FlexElement>
          <Radio
            label="Add to note"
            checked={selected === "add"}
            onChange={() => setSelected("add")}
          />
        </FlexElement>
        <FlexElement>
          <Radio
            label="Replace note"
            checked={selected === "replace"}
            onChange={() => setSelected("replace")}
          />
        </FlexElement>
      </FormOptionsContainer>
      <TextField
        id="note"
        label=""
        multiline
        style={{ resize: "none" }}
        rows={3}
        rowsMax={6}
        value={note}
        onChange={(e: any) => setNote(e.target.value)}
      />
      <PanelButtonContainer>
        <Button
          disabled={selectedItems.length === 0}
          onClick={async () =>
            (await isConfirmed(
              PCSConfirmQuestion({
                message: `${selected} the note ${
                  selected === "add" ? "" : "to"
                } "${note}" ${selected === "add" ? "to" : "in"}`,
                selectedItems,
              })
            )) &&
            pcsCommandOnSelected({
              selectedItems,
              mainPlant,
              command: "properties",
              method: "put",
              pcsCommand,
              extraParams: {
                PropertyType: "Notepad",
                MaterialGroupID: "",
                RatingClassID: "",
                SpecialReqID: "",
                NotepadAction: selected === "replace" ? "R" : "A",
                NotepadText: note,
                TubePCSMeasurement: "",
                NewVDSSection: "",
              },
            })
          }
        >
          {selected === "add" ? "Add to Note" : "Replace Note"}
        </Button>
      </PanelButtonContainer>
    </PanelContainer>
  );
}

const PCSPropertiesPanel = React.forwardRef(
  ({ selectedItems }: { selectedItems: any[] }, ref: React.Ref<any>) => {
    const {
      mutate: pcsCommand,
      status: pcsCommandStatus,
      reset: pcsCommandReset,
      error: pcsCommandError,
      data: pcsCommandData,
    } = usePCSCommandQuery();

    const { activeTab, handleChange } = useTabs({
      name: "PropertiesPanelTabs",
    });

    return (
      <div ref={ref}>
        <Tabs activeTab={activeTab} onChange={handleChange} scrollable>
          <Tabs.List>
            <Tabs.Tab>PCS Note</Tabs.Tab>
            <Tabs.Tab>Tube PCS</Tabs.Tab>
            <Tabs.Tab>VDS Layout</Tabs.Tab>
            <Tabs.Tab>Material Group</Tabs.Tab>
            <Tabs.Tab>Rating Class</Tabs.Tab>
            <Tabs.Tab>Special Requirement</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              {activeTab === 0 && (
                <UpdateNote
                  selectedItems={selectedItems}
                  pcsCommand={pcsCommand}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 1 && (
                <UpdateWithRadio
                  selectedItems={selectedItems}
                  options={[
                    { title: "Metric", value: "M" },
                    { title: "Imperial", value: "I" },
                    { title: "Disable", value: "_empty" },
                  ]}
                  property="TubePCS"
                  buttonText="Update Tube PCS Property"
                  pcsCommand={pcsCommand}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 2 && (
                <UpdateWithRadio
                  selectedItems={selectedItems}
                  options={[
                    { title: "New VDS Section", value: "Y" },
                    { title: "Old VDS Section", value: "N" },
                  ]}
                  property="NewVDSSection"
                  buttonText="Update VDS Section Layout Property"
                  pcsCommand={pcsCommand}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 3 && (
                <UpdateCodelist
                  selectedItems={selectedItems}
                  codelist="material-groups"
                  pcsCommand={pcsCommand}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 4 && (
                <UpdateCodelist
                  selectedItems={selectedItems}
                  codelist="rating-classes"
                  pcsCommand={pcsCommand}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 5 && (
                <UpdateCodelist
                  selectedItems={selectedItems}
                  codelist="special-requirements"
                  pcsCommand={pcsCommand}
                  remove={true}
                />
              )}
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
        <StatusModal
          status={pcsCommandStatus}
          successMessage={successMessage(pcsCommandData)}
          onSettledClose={pcsCommandReset}
          onSuccess={() => reloadPreviewPane("main")}
          error={pcsCommandError}
        />
      </div>
    );
  }
);

export default PCSPropertiesPanel;
