import { useUserConfig } from "UserConfigContext";
import { useCallback } from "react";
import { DocumentListItem } from "./queries/useDocumentList";
import { EmmbeddedPictureItem } from "./queries/useEmbeddedPictures";
import { DocumentSpaceProperties } from "./queries/useDocumentProperties";

type DocumentType =
  | DocumentSpaceProperties
  | DocumentListItem
  | EmmbeddedPictureItem
  | undefined;

export const useFileUrl = () => {
  const { configParams } = useUserConfig();

  const getFilePath = useCallback(
    (document: DocumentType) =>
      document
        ? "/document_space/get_document.jsp?" +
          (document.Tag !== ""
            ? "tag=" + document.Tag
            : "docid=" + document.DocumentID)
        : "",
    []
  );

  const getFileUrl = useCallback(
    (document: DocumentType, relative?: boolean) =>
      document
        ? (relative
            ? ".."
            : configParams
            ? configParams.find((e) => e.ID === 1)?.URL
            : "..") + getFilePath(document)
        : "",
    [configParams, getFilePath]
  );

  return { getFileUrl };
};
