import { useLocation } from "react-router-dom";
import { ReportFormHeader } from "../ReportFormHeader";
import { EDSPCSSearchData } from "./edsPCSSearchConfig";
import { useMemo } from "react";
import { NativeSelect, TextField, Typography } from "@equinor/eds-core-react";
import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../../../components/ReportTable";
import { FlexContainer, FlexElement } from "components/Components";
import { sheetProperties } from "features/sheets/config/sheetConfig";
import { sheetStatusConfig } from "features/sheets/config/statusConfig";

export const edsPCSSearchColumns: {
  key: keyof EDSPCSSearchData[0];
  title: string;
}[] = [
  { key: "SST", title: "EDS" },
  { key: "Plant", title: "Plant" },
  { key: "PCS", title: "PCS" },
  { key: "Revision", title: "Revision" },
  { key: "Status", title: "Status" },
  { key: "RevDate", title: "Rev. Date" },
  { key: "LastUpdate", title: "Last Update" },
];

export function EDSPCSSearchReport({
  data,
}: {
  data: EDSPCSSearchData | undefined;
}) {
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <>
      <ReportFormHeader>
        <FlexContainer style={{ justifyContent: "flex-start", gap: 16 }}>
          <FlexElement>
            <TextField
              defaultValue={parsedSearch.get("namefilter") || ""}
              label="EDS"
              id="namefilter"
            />
          </FlexElement>
          <FlexElement>
            <NativeSelect
              id="statusfilter"
              label="Status"
              defaultValue={parsedSearch.get("statusfilter") || ""}
            >
              <option value="">All Statuses</option>
              {sheetProperties.pcs.statuses.map((status) => (
                <option key={status} value={status}>
                  {sheetStatusConfig[status]}
                </option>
              ))}
            </NativeSelect>
          </FlexElement>
        </FlexContainer>
      </ReportFormHeader>
      {data &&
        (data.length === 0 ? (
          <Typography>No result.</Typography>
        ) : (
          <ReportTable>
            <TableHead>
              <TableRow>
                {edsPCSSearchColumns.map((col) => (
                  <HeadCell key={col.key}>{col.title}</HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={JSON.stringify(row)}>
                  {edsPCSSearchColumns.map((col) => (
                    <ContentCell key={col.key}>{row[col.key]}</ContentCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </ReportTable>
        ))}
    </>
  );
}
