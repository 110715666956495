import { FlexContainer, FlexElement } from "components/Components";
import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { FormRow } from "components/form/Form";
import { VDSSubsegment, VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "features/sheets/queries/useVDSTextBlocksDistinctText";
import { useContext, useEffect, useMemo } from "react";

export function VDSTextBlockSectionH3({
  textBlockData,
  subsegmentData,
  setIsLoaded,
}: {
  textBlockData?: VDSTextBlock;
  subsegmentData?: VDSSubsegment;
  setIsLoaded?: React.Dispatch<boolean>;
}) {
  const data = useMemo(
    () =>
      textBlockData
        ? textBlockData
        : subsegmentData
        ? subsegmentData.getVDSSubsegmentHeader[0]
        : undefined,
    [subsegmentData, textBlockData]
  );

  const sectionId = "H";
  const itemId = 3;

  const { disabled: contextDisabled } = useContext(FieldContext);
  const disabled = contextDisabled || !textBlockData;

  const {
    data: sizeRangeFrom,
    isSuccess: sizeRangeFromIsSuccess,
    status: sizeRangeFromStatus,
  } = useVDSTextBlocksDistinctText({
    attributeName: "SF",
    sectionId,
    itemId,
    disabled,
  });

  const {
    data: sizeRangeTo,
    isSuccess: sizeRangeToIsSuccess,
    status: sizeRangeToStatus,
  } = useVDSTextBlocksDistinctText({
    attributeName: "ST",
    sectionId,
    itemId,
    disabled,
  });

  const {
    data: sizeComment,
    isSuccess: sizeCommentIsSuccess,
    status: sizeCommentStatus,
  } = useVDSTextBlocksDistinctText({
    attributeName: "SC",
    sectionId,
    itemId,
    disabled,
  });

  const {
    data: unit,
    isSuccess: unitIsSuccess,
    status: unitStatus,
  } = useVDSTextBlocksDistinctText({
    attributeName: "UN",
    sectionId,
    itemId,
    disabled,
  });

  useEffect(() => {
    sizeRangeFromIsSuccess &&
      sizeRangeToIsSuccess &&
      sizeCommentIsSuccess &&
      unitIsSuccess &&
      setIsLoaded &&
      setIsLoaded(true);
  }, [
    setIsLoaded,
    sizeRangeFromIsSuccess,
    sizeRangeToIsSuccess,
    sizeCommentIsSuccess,
    unitIsSuccess,
  ]);

  const preProp = subsegmentData ? "getVDSSubsegmentHeader.0." : "";

  return (
    <>
      <FormRow>
        <FlexContainer
          style={{ justifyContent: "flex-start", gap: "12px 32px" }}
        >
          <FlexElement>
            <Field
              style={{ margin: 0 }}
              title="Size From"
              prop={preProp + "SizeRangeFrom"}
              value={data?.SizeRangeFrom ?? ""}
              options={sizeRangeFrom}
              type="autocomplete"
              status={sizeRangeFromStatus}
              maxWidth={120}
            />
          </FlexElement>
          <FlexElement>
            <Field
              style={{ margin: 0 }}
              title="Size To"
              prop={preProp + "SizeRangeTo"}
              value={data?.SizeRangeTo ?? ""}
              options={sizeRangeTo}
              type="autocomplete"
              status={sizeRangeToStatus}
              maxWidth={120}
            />
          </FlexElement>
        </FlexContainer>
      </FormRow>
      <Field
        title="Unit"
        prop={preProp + "Unit"}
        value={data?.Unit ?? ""}
        options={unit}
        type="autocomplete"
        status={unitStatus}
        maxWidth={120}
        maxLength={2}
      />
      <Field
        title="Comment"
        prop={preProp + "SizeRangeComment"}
        value={data?.SizeRangeComment ?? ""}
        options={sizeComment}
        type="autocomplete"
        status={sizeCommentStatus}
        maxWidth={500}
      />
    </>
  );
}
