import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

export type EmmbeddedPictureItem = {
  DocumentID: number;
  LastModified: string;
  Tag: string;
  Description: string;
};

export function useEmbeddedPictures() {
  return useQuery(["document-space", "embedded-pictures"], async () => {
    const { data } = await axios.get<{
      getEmbeddedPictureList: EmmbeddedPictureItem[];
    }>(getApiUrl(["document-space", "embedded-pictures"].join("/")));
    return data.getEmbeddedPictureList;
  });
}
