import { check } from "@equinor/eds-icons";
import { IconColumn } from "components/table/Columns";
import { ListAdminConfig } from "../listAdminConfig";

export type VDSSubsegmentGroups = {
  GroupID: number;
  Description: string;
  OptionalSubsegment: "N" | "Y";
  Sequence: number;
  InUse: "N" | "Y";
};

type VDSSubsegmentGroupsSubmit = VDSSubsegmentGroups & { Action: "N" | "" };

export const vdsSubsegmentGroupsConfig: ListAdminConfig<"vds-subsegment-groups"> =
  {
    title: "VDS Subsegment Groups",
    singularTitle: "VDS Suibsegment Group",
    url: "codelists/vds-subsegment-groups",
    itemIdProp: "GroupID",
    itemNameProp: "Description",
    apiListProp: "getVDSSubsegmentGroups",
    submitProp: "vdssubsegmentgroups",
    nameProperty: ["GroupID", "Description"],
    columns: [
      {
        key: "GroupID",
        title: "ID",
      },
      {
        key: "Description",
        title: "Description",
        width: "100%",
      },
      {
        key: "OptionalSubsegment",
        title: "Optional Subsegment",
      },
      {
        key: "InUse",
        title: "In Use",
        Component: IconColumn,
        type: "with-context",
        componentProps: {
          trueValue: "Y",
          falseValue: "N",
          prop: "InUse",
          title: "In Use",
          icon: check,
        },
      },
    ],
    disableDelete: (item: VDSSubsegmentGroups) => item.InUse === "Y",
  };

export function prepareVDSSubsegmentGroupsData(
  submitData: VDSSubsegmentGroups[]
) {
  const { submitProp } = vdsSubsegmentGroupsConfig;
  return {
    [submitProp]: submitData.map(
      (e: Partial<VDSSubsegmentGroupsSubmit>, i: number) => {
        return {
          GroupID: e.GroupID,
          Description: e.Description,
          Optional: e.OptionalSubsegment,
          Sequence: i + 1,
          Action: e.Action ? e.Action : "",
        };
      }
    ),
  };
}
