import axios from "axios";
import { useMutation } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";

export type AffectedVDSActionCodes = "N" | "D" | "R";

export type AffectedVDSAction = {
  VDS: string;
  Revision: string;
  Action: AffectedVDSActionCodes;
  ReplaceWithSubSegmentID: string;
  ReplaceWithSubsegmentRevision: string;
};

export default function useAffectedVDSAction() {
  async function vdsAffectedVDSAction({
    name,
    revision,
    content,
  }: {
    name: string;
    revision: string;
    content: {
      sheets: AffectedVDSAction[];
    };
  }) {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-subsegments",
        name,
        revision,
        postfix: "affected-vds-action",
      }),
      {
        method: "put",
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(vdsAffectedVDSAction, {
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
  });
}
