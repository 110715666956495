import { useMsal } from "@azure/msal-react";
import { Button } from "@equinor/eds-core-react";
import { PageContainer, PageTitle } from "components/Components";
import useConfirm from "components/confirm/useConfirm";
import StatusModal from "components/StatusModal";
import { useResetSettings } from "features/settings/useResetSettings";
import { useSettingsQuery } from "features/settings/useSettingsQuery";
import useUser from "queries/useUser";
import { useUserConfig } from "UserConfigContext";
import { successMessage } from "utils/successMessage";

export function Profile() {
  const { accounts } = useMsal();
  const { data, status, refetch, isRefetching, error } = useUser();
  const {
    mutate: deleteSettings,
    status: deleteSettingsStatus,
    error: deleteSettingsError,
    data: deleteSettingsData,
    reset: deleteSettingsReset,
  } = useResetSettings();
  const { isConfirmed } = useConfirm();
  const ua = useUserConfig();
  const as = useSettingsQuery();

  return (
    <PageContainer>
      <PageTitle variant="h4">Hello {accounts[0].name}!</PageTitle>
      <div style={{ margin: "1em 0" }}>
        <p>TR2000 users: {status}</p>
        <p>TR2000 users response: {JSON.stringify(data)}</p>
        {status === "error" && <p>{JSON.stringify(error)}</p>}
        <p>Context: {JSON.stringify(ua)}</p>
        <p>Settings: {JSON.stringify(as)}</p>
      </div>
      <div style={{ display: "flex", gap: 16 }}>
        <Button onClick={() => refetch()} disabled={isRefetching}>
          Reload user
        </Button>
        <Button
          color="danger"
          onClick={async () =>
            (await isConfirmed(
              <>Are you sure you want to reset all your settings?</>
            )) && deleteSettings()
          }
        >
          Delete user settings
        </Button>
      </div>
      <StatusModal
        status={deleteSettingsStatus}
        error={deleteSettingsError}
        successMessage={successMessage(deleteSettingsData)}
        onSettledClose={() => deleteSettingsReset()}
      />
    </PageContainer>
  );
}
