import { NativeSelect } from "@equinor/eds-core-react";
import { ErrorDisplay } from "components/Components";
import { FiltersDispatchProps } from "components/table/Filters";
import {
  Codelists,
  codelistProperties,
  useCodelist,
} from "queries/useCodelist";
import React, { useEffect, useMemo, useState } from "react";
import { CommonObjectContent } from "utils/filterItemsByProps";

export function useFilter<T extends CommonObjectContent>({
  filtersDispatch,
  codelist,
  items,
  filterProp,
  group,
}: {
  filtersDispatch: React.Dispatch<FiltersDispatchProps>;
  codelist: Codelists;
  items?: T[];
  filterProp?: keyof T;
  group?: string;
}) {
  const [filterState, setFilterState] = useState("");

  const { data, status, error } = useCodelist({
    codelist,
  });

  const codelistProps = useMemo(() => codelistProperties[codelist], [codelist]);
  const currentFilterProp = filterProp ?? (codelistProps.idProp as keyof T);

  const allOptions = useMemo(
    () =>
      data
        ? data?.map((e) => ({
            key: e[codelistProps.idProp as keyof typeof e],
            title: e[codelistProps.nameProp as keyof typeof e],
          }))
        : [],
    [codelistProps.idProp, codelistProps.nameProp, data]
  );

  const presentOptionsKeys = useMemo(
    () =>
      Array.from(
        new Set(items?.map((e) => e[currentFilterProp as keyof typeof e]))
      ),
    [currentFilterProp, items]
  );

  const options = useMemo(
    () =>
      items
        ? allOptions.filter((e) => presentOptionsKeys.includes(e.key))
        : allOptions,
    [allOptions, items, presentOptionsKeys]
  );

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: codelistProps.titleSingular ?? codelist,
        prop: String(currentFilterProp),
        filterOptions: options,
        filterState,
        defaultFilterState: "",
        setFilterState,
        group,
      },
    });
  }, [
    codelist,
    currentFilterProp,
    filterProp,
    filterState,
    filtersDispatch,
    options,
    group,
    codelistProps,
  ]);

  return {
    data,
    status,
    error,
    codelistProps,
    filterState,
    setFilterState,
    options,
  };
}

export function Filter<T extends CommonObjectContent>({
  filtersDispatch,
  codelist,
  items,
  filterProp,
  title,
}: {
  filtersDispatch: React.Dispatch<FiltersDispatchProps>;
  codelist: Codelists;
  items?: T[];
  filterProp?: keyof T;
  title?: string;
}) {
  const { status, error, codelistProps, setFilterState, options } = useFilter({
    filtersDispatch,
    codelist,
    filterProp,
    items,
  });

  return status === "error" ? (
    <ErrorDisplay error={error} title={codelistProps.titlePlural} small />
  ) : (
    <>
      <NativeSelect
        id={String(filterProp)}
        label={codelistProps.titlePlural ?? title ?? ""}
        onChange={(e) => setFilterState(e.target.value)}
      >
        <option key="" value="">
          *
        </option>
        {options.map((option) => (
          <option key={option.key} value={option.key}>
            {option.title}
          </option>
        ))}
      </NativeSelect>
    </>
  );
}
