import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { StatusResponseWithInfo } from "components/StatusModal";

export type VDSBody = {
  ValveTypeID: number;
  RatingClassID: number;
  MaterialTypeID: number;
  EndConnectionID: number;
  BoreID: number;
  VDSSizeID: number;
  HousingDesignID: number;
  SpecialReqID: number;
  Notepad: string;
  PreviewOnly: "Y" | "N";
  MakeNewName: "Y" | "N";
  SerieID: number;
};

export type VDSSubsegmentItem = {
  GroupID: number;
  SubsegmentID: number;
  Revision: string;
};

export type VDSContent = VDSBody & {
  VDSSubsegment?: VDSSubsegmentItem[];
};

export type SaveVDSMutationProps = {
  name: string;
  revision: string;
  content: VDSContent;
  characteristicChange?: boolean;
  invalidateList?: boolean;
  noInvalidateSheet?: boolean;
};

export default function useSaveVDS() {
  const queryClient = useQueryClient();

  async function saveSheet({
    name,
    revision,
    content,
  }: SaveVDSMutationProps): Promise<AxiosResponse<StatusResponseWithInfo>> {
    return await axios(getSheetApiUrl({ sheetType: "vds", name, revision }), {
      method: "put",
      data: JSON.stringify(content),
    });
  }

  return useMutation(saveSheet, {
    onSuccess: (data, variables, context) => {
      variables.invalidateList &&
        queryClient.invalidateQueries(["sheets", "vds"]);
      !variables.noInvalidateSheet &&
        queryClient.invalidateQueries([
          "sheet",
          "vds",
          variables.name,
          variables.revision,
        ]);
      variables.characteristicChange &&
        queryClient.invalidateQueries([
          "vds-subsegment-list",
          variables.name,
          variables.revision,
        ]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type UseSaveVDSProps = ReturnType<typeof useSaveVDS>;
