import {
  craning,
  folder,
  gavel,
  grid_on,
  tag_relations,
  view_list,
} from "@equinor/eds-icons";
import { Access } from "Access";
import { Category, MenuItem } from "components/MainMenuComponents";

export function PMGMenu() {
  return (
    <Access cap="pmg" role="U" hide>
      <Category title="Pipe Elements">
        <Access cap="pmg" hide>
          <MenuItem
            title="Pipe Material Groups"
            to="/pmg/material-groups"
            icon={folder}
          />
          <MenuItem
            title="Master Pipe Elements"
            to="/pmg/master-pipe-elements"
            icon={view_list}
          />
        </Access>
        <Access role="U" hide>
          <MenuItem
            title="Structured Pipe Elements"
            to="/pmg/structured-pipe-elements"
            icon={view_list}
          />
        </Access>
        <Access cap="pmg" hide>
          <MenuItem title="Matrices" to="/pmg/matrices" icon={grid_on} />
          <MenuItem
            title="Article Rules"
            to="/pmg/article-rules"
            icon={gavel}
          />
          <MenuItem
            title="Weight Rules"
            to="/pmg/weight-rules"
            icon={craning}
          />
          <MenuItem
            title="Subelements"
            to="/pmg/subelements"
            icon={tag_relations}
          />
        </Access>
      </Category>
    </Access>
  );
}
