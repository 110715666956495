import { FiltersDispatch } from "components/table/Filters";
import { useEffect } from "react";
import { PFL2Mapping } from "./pfl2MappingConfig";

export function PFL2MappingFilter({
  data,
  filtersDispatch,
  categoryFilter,
  setCategoryFilter,
}: {
  data?: PFL2Mapping[];
  filtersDispatch: FiltersDispatch;
  categoryFilter: string;
  setCategoryFilter: React.Dispatch<string>;
}) {
  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Element Group",
        prop: "ElementGroupID",
        filterOptions: data
          ? (data as PFL2Mapping[])
              .map((i) => ({
                key: String(i.ElementGroupID),
                title: i.ElementGroup,
              }))
              .reduce(
                (a, c) =>
                  a.map((e) => e.key).includes(c.key) ? a : a.concat(c),
                [] as { key: string; title: string }[]
              )
          : [],
        filterState: categoryFilter,
        defaultFilterState: "",
        setFilterState: setCategoryFilter,
        group: "",
      },
    });
    return () => filtersDispatch({ type: "reset" });
  }, [data, categoryFilter, setCategoryFilter, filtersDispatch]);
  return null;
}
