import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { VDSTextBlock } from "features/sheets/types";
import useSheet from "features/sheets/queries/useSheet";
import useVDSTextBlocksDistinctText from "features/sheets/queries/useVDSTextBlocksDistinctText";
import { useContext, useEffect, useMemo } from "react";

export function VDSTextBlockSectionM({
  data,
  name,
  revision,
  setIsLoaded,
  itemId,
}: {
  data?: VDSTextBlock;
  name?: string;
  revision?: string;
  itemId?: number;
  setIsLoaded?: React.Dispatch<boolean>;
}) {
  const isEdit = !!name && !!revision;

  const { disabled } = useContext(FieldContext);

  const {
    data: remarks,
    isSuccess: remarkIsSuccess,
    status: remarkStatus,
  } = useVDSTextBlocksDistinctText({
    attributeName: "MR",
    sectionId: "M",
    itemId,
    disabled,
  });

  const {
    data: subItems,
    isSuccess: subItemIsSuccess,
    status: subItemStatus,
  } = useVDSTextBlocksDistinctText({
    attributeName: "MS",
    sectionId: "M",
    itemId,
    disabled,
  });

  const {
    data: materialNames,
    isSuccess: materialNameIsSuccess,
    status: materialNameStatus,
  } = useVDSTextBlocksDistinctText({
    attributeName: "MN",
    sectionId: "M",
    itemId,
    disabled,
  });

  const {
    data: mdsData,
    isSuccess: mdsIsSuccess,
    status: mdsStatus,
  } = useSheet({
    sheetType: "vds-textblocks",
    name: undefined,
    revision: undefined,
    postfix: "mds",
    disabled: !isEdit || disabled,
  });

  const mdsOptions = useMemo(
    () => mdsData && mdsData.getVDSTextBlockMDS.map((e) => e.MDS),
    [mdsData]
  );

  useEffect(() => {
    remarkIsSuccess &&
      subItemIsSuccess &&
      materialNameIsSuccess &&
      mdsIsSuccess &&
      setIsLoaded &&
      setIsLoaded(true);
  }, [
    materialNameIsSuccess,
    mdsIsSuccess,
    remarkIsSuccess,
    setIsLoaded,
    subItemIsSuccess,
  ]);

  return (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Sub Item"
        prop="MaterialSubItem"
        value={data?.MaterialSubItem ?? ""}
        options={subItems}
        type="autocomplete"
        status={subItemStatus}
        maxWidth={240}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Material"
        prop="MaterialName"
        value={data?.MaterialName ?? ""}
        options={materialNames}
        type="autocomplete"
        status={materialNameStatus}
        maxWidth={240}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        prop="MaterialMDS"
        value={data?.MaterialMDS ?? ""}
        title="MDS"
        type="option"
        options={mdsOptions}
        nonExistingText={disabled ? "" : undefined}
        disableNotSetOption={true}
        status={mdsStatus}
        maxWidth={180}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Description"
        prop="MaterialRemark"
        value={data?.MaterialRemark ?? ""}
        options={remarks}
        type="autocomplete"
        status={remarkStatus}
      />
    </>
  );
}
