import { sheetProperties } from "../config/sheetConfig";
import { useMutation, useQueryClient } from "react-query";
import { PlantProps } from "../../plant/types";
import { plantDependentSheets, SheetTypes } from "../config/sheetConfig";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import axios from "axios";
import { getSheetNameByType } from "features/sheets/util";

export default function useDeleteSheets() {
  const queryClient = useQueryClient();

  async function deleteSheets({
    plant,
    sheetType,
    sheets,
    noListInvalidation,
  }: {
    plant?: PlantProps;
    sheetType: SheetTypes;
    sheets: any[];
    noListInvalidation?: boolean;
  }) {
    const { sheetListProperty, apiDeleteSingle, nameProperty } =
      sheetProperties[sheetType];
    return await axios({
      method: "delete",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      url: apiDeleteSingle
        ? getSheetApiUrl({
            plant: plant,
            sheetType: sheetType,
            name: sheets[0][nameProperty],
            revision: sheets[0].Revision,
          })
        : getSheetApiUrl({ plant: plant, sheetType: sheetType }),
      ...(apiDeleteSingle
        ? {}
        : {
            data: {
              [sheetListProperty ? sheetListProperty : "sheets"]: sheets,
            },
          }),
    });
  }

  return useMutation(deleteSheets, {
    onSuccess: (data, variables, context) => {
      !variables.noListInvalidation &&
        queryClient.invalidateQueries([
          "sheets",
          ...(variables.plant &&
          plantDependentSheets.includes(variables.sheetType)
            ? [variables.plant.PlantID]
            : []),
          variables.sheetType,
        ]);
      variables.sheets.forEach((sheet) => {
        queryClient.removeQueries([
          "sheet",
          ...(variables.plant &&
          plantDependentSheets.includes(variables.sheetType)
            ? [variables.plant.PlantID]
            : []),
          variables.sheetType,
          getSheetNameByType(sheet, variables.sheetType),
          sheet.Revision,
        ]);
      });
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
