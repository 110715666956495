import config from "../../../config/config";
import { useQuery } from "react-query";
import axios from "axios";
import {
  SheetTypes,
  nameProperties,
  plantDependentSheets,
  sheetProperties,
} from "../config/sheetConfig";
import { SheetStatus } from "../config/statusConfig";
import { PlantProps } from "../../plant/types";
import { getItemID } from "../util";

export interface sheetsExtraParams {
  notIn?: PlantProps;
  statuses?: SheetStatus[];
  freeformatFilter?: "F";
  basedOnSubsegment?: "Y" | "N";
  pipeElementFilter?: number;
  pipeElementGroupFilter?: number;
  noteFilter?: number;
  plantFilter?: number;
  valveGroup?: number;
  nameFilter?: string;
  allVariants?: "Y" | "N";
  materialGroupID?: string;
}

export interface SheetsData {
  totalCount?: number;
  sheets: any;
}

/*
 * Deprecated. Use useSheetList instead.
 */

export default function useSheets({
  plant,
  sheetType,
  extraParams,
  select,
  disabled,
}: {
  sheetType: SheetTypes;
  plant?: PlantProps;
  extraParams?: sheetsExtraParams;
  select?: (arg0: any) => any;
  disabled?: boolean;
}) {
  return useQuery<SheetsData, Error>(
    [
      "sheets",
      ...(plantDependentSheets.includes(sheetType) && plant
        ? [plant.PlantID]
        : []),
      sheetType,
      { extraParams },
    ],
    async () => {
      let url = config.restApiBaseUrl;
      if (plantDependentSheets.includes(sheetType) && plant) {
        url += `plants/${plant.PlantID}/${sheetType}`;
      } else {
        url += sheetType === "vds-unstructured" ? "vds" : sheetType;
      }
      let urlParams = [];
      if (extraParams) {
        if (extraParams.notIn) {
          urlParams.push(`notinfilter=${extraParams.notIn.PlantID}`);
        }
        if (extraParams.statuses) {
          urlParams.push(`statusfilter=${extraParams.statuses.join(",")}`);
        }
        if (extraParams.freeformatFilter) {
          urlParams.push(`freeformatfilter=${extraParams.freeformatFilter}`);
        }
        if (extraParams.basedOnSubsegment) {
          urlParams.push(`basedonsubsegment=${extraParams.basedOnSubsegment}`);
        }
        if (extraParams.pipeElementFilter) {
          urlParams.push(`elementID=${extraParams.pipeElementFilter}`);
        }
        if (extraParams.pipeElementGroupFilter) {
          urlParams.push(
            `elementGroupID=${extraParams.pipeElementGroupFilter}`
          );
        }
        if (extraParams.noteFilter) {
          urlParams.push(`noteID=${extraParams.noteFilter}`);
        }
        if (extraParams.plantFilter) {
          urlParams.push(`plantID=${extraParams.plantFilter}`);
        }
        if (extraParams.valveGroup) {
          urlParams.push(`valvecategoryid=${extraParams.valveGroup}`);
        }
        if (extraParams.nameFilter) {
          urlParams.push(`namefilter=${extraParams.nameFilter}`);
        }
        if (extraParams.allVariants) {
          urlParams.push(`AllVariants=${extraParams.allVariants}`);
        }
        if (extraParams.materialGroupID) {
          url = config.restApiBaseUrl + sheetType + "/material-groups";
          urlParams.push(`MaterialGroupID=${extraParams.materialGroupID}`);
        }
      }
      if (urlParams.length > 0) {
        url += `?${urlParams.join("&")}`;
      }
      const { data } = await axios.get(url);
      //  @ts-ignore
      const compiledData = {
        // @ts-ignore
        sheets: data[sheetProperties[sheetType].listProperty].map(
          (item: any) => {
            return {
              ...item,
              ...{
                itemID: getItemID(
                  item[nameProperties[sheetType]],
                  item["Revision"]
                ),
              },
              ...(sheetType === "vds-textblocks" && {
                DesignTemperature: `${item.MinDesignTemperature}${
                  item.MaxDesignTemperature ? " / " : ""
                }${item.MaxDesignTemperature}`,
                OperatingTemperature: `${item.MinOperatingTemperature}${
                  item.MaxOperatingTemperature ? " / " : ""
                }${item.MaxOperatingTemperature}`,
              }),
            };
          }
        ),
        // @ts-ignore
        ...(sheetType === "vds" ? { totalCount: data.totalCount } : {}),
      };
      return compiledData;
    },
    {
      enabled:
        (!plantDependentSheets.includes(sheetType) ||
          (plant && plant.PlantID > 0)) &&
        !disabled,
      select,
    }
  );
}

export const useSheetsNames = ({
  plant,
  sheetType,
  isCopyModal,
  extraParams,
}: {
  plant: PlantProps;
  sheetType: SheetTypes;
  isCopyModal: boolean;
  extraParams?: sheetsExtraParams;
}) =>
  useSheets({
    plant,
    sheetType,
    extraParams,
    select: (data) => {
      return data.sheets.map((item: any) => item[nameProperties[sheetType]]);
    },
    disabled: !isCopyModal || plantDependentSheets.indexOf(sheetType) === -1,
  });
