import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { plantDependentSheets, SheetTypes } from "../config/sheetConfig";
import { SheetStatus } from "../config/statusConfig";
import { PlantProps } from "../../plant/types";

export default function useSetSheetStatus() {
  const queryClient = useQueryClient();

  async function setSheetStatus({
    plant,
    sheetType,
    name,
    revision,
    status,
  }: {
    plant?: PlantProps;
    sheetType: SheetTypes;
    name: string;
    revision: string;
    status: SheetStatus;
  }) {
    return await axios(
      getSheetApiUrl({ plant, sheetType, name, revision, postfix: "status" }),
      {
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: { Status: status },
      }
    );
  }

  return useMutation(setSheetStatus, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType) &&
        variables.plant
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
