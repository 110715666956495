import { Button, Icon } from "@equinor/eds-core-react";
import { grid_on } from "@equinor/eds-icons";
import { getStatusChips } from "components/Components";
import { useTable } from "components/table/useTable";
import { idWidth } from "features/sheets/config/sheetConfig";
import { SheetStatus } from "features/sheets/config/statusConfig";
import { getItemID } from "features/sheets/util";
import { CodelistSpecification, useCodelist } from "queries/useCodelist";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MatrixView } from "./MatrixView";

const matrixColumns = [
  { key: "MatrixType", title: "Type", width: 180 },
  { key: "TableID", title: "ID", width: idWidth },
  { key: "Name", title: "Name", width: 240 },
  { key: "Revision", title: "Revision" },
  { key: "RevDate", title: "Rev. Date" },
  { key: "Status", title: "Status" },
  { key: "Source", title: "Source", width: "70%", minWidth: 300 },
  { key: "SSTReference", title: "EDS Ref.", longTitle: "EDS Reference" },
  { key: "Overlength", title: "Overlength" },
  { key: "AllowableStress", title: "Allowable Stress" },
];

const filterGroupProperties = [
  { group: "Statuses", col: 1 },
  { group: "Type", col: 2 },
];

const ViewMatrix = ({
  item,
}: {
  item: CodelistSpecification["master-matrices"];
}) => {
  const history = useHistory();
  return (
    <Button
      variant="ghost_icon"
      onClick={() =>
        history.push({
          search: `?id=${item.TableID}&rev=${item.Revision}`,
        })
      }
      title="View Matrix"
    >
      <Icon data={grid_on} />
    </Button>
  );
};

export function MasterMatricesList() {
  const query = useCodelist({ codelist: "master-matrices" });
  const { data } = query;
  const { filtersDispatch, content } = useTable({
    query,
    columns: matrixColumns,
    title: "Master Matrices",
    filterGroupProperties,
    getItemID: (e) => getItemID(e.TableID, e.Revision),
    groupBy: "MatrixType",
    Commands: [ViewMatrix],
    nameProperty: ["Name", "TableID"],
  });

  const matrixTypeOptions = useMemo(
    () => (data ? Array.from(new Set(data.map((e) => e.MatrixType))) : []),
    [data]
  );

  const [statusFilter, setStatusFilter] = useState([] as SheetStatus[]);
  const [matrixTypeFilter, setMatrixTypeFilter] = useState([] as string[]);

  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "checkboxes",
        title: "Statuses",
        prop: "Status",
        filterOptions: getStatusChips(["O", "W", "R", "E"]),
        filterState: statusFilter,
        defaultFilterState: ["O", "W"],
        setFilterState: setStatusFilter,
        commaSeparated: false,
        group: "Statuses",
        isArray: true,
        apiFilterPorp: "statusFilter",
      },
    });
  }, [filtersDispatch, statusFilter]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Matrix Type",
        prop: "MatrixType",
        filterOptions: matrixTypeOptions,
        filterState: matrixTypeFilter,
        setFilterState: setMatrixTypeFilter,
        commaSeparated: false,
        group: "Type",
      },
    });
  }, [filtersDispatch, matrixTypeFilter, matrixTypeOptions]);

  const history = useHistory();
  const closeModal = useCallback(() => {
    history.push({
      search: "",
    });
  }, [history]);

  return (
    <>
      {content}
      <MatrixView
        id={parsedSearch.get("id")}
        revision={parsedSearch.get("rev")}
        closeModal={closeModal}
      />
    </>
  );
}
