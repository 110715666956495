import axios from "axios";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { useMutation } from "react-query";

export type ValidationResult = {
  DuplicateVDSFound: "Y" | "N";
  VDS: string;
  Revision: string;
};

type ValidationResultReply = {
  getVDSValidateContentStatus: ValidationResult[];
};

export function useVDSContentValidation() {
  return useMutation(
    async ({
      name,
      revision,
      originalVDS,
    }: {
      name: string;
      revision: string;
      originalVDS?: string;
    }): Promise<ValidationResult> => {
      const { data } = await axios.get<ValidationResultReply>(
        getSheetApiUrl({
          sheetType: "vds",
          name,
          revision,
          postfix: `content-validation${
            originalVDS ? `?OriginalVDS=${originalVDS}` : ""
          }`,
        })
      );
      return data.getVDSValidateContentStatus[0];
    }
  );
}
