import { Button, Icon } from "@equinor/eds-core-react";
import { assignment } from "@equinor/eds-icons";
import { useTable } from "components/table/useTable";
import { idWidth } from "features/sheets/config/sheetConfig";
import {
  MasterPipeElementCodelistItem,
  useCodelist,
} from "queries/useCodelist";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MasterPipeElementProperties } from "./MasterPipeElementProperties";

const columns = [
  { key: "MasterPipeElementID", title: "ID", width: idWidth },
  { key: "MaterialGroup", title: "Material Group" },
  { key: "ElementGroup", title: "Element Group" },
  {
    key: "DimStandard",
    title: "Dim. Standard",
    longTitle: "Dimension Standard",
    width: "33%",
  },
  { key: "ProductForm", title: "Product Form", width: "33%" },
  { key: "MaterialGrade", title: "Material Grade", width: "33%" },
  { key: "Priority", title: "Priority" },
  { key: "WeldingFactorValue", title: "Welding Factor" },
  { key: "AddDimensionManually", title: "Manual Dimension" },
];

function RowMenu({ item }: { item: MasterPipeElementCodelistItem }) {
  const history = useHistory();
  return (
    <Button
      variant="ghost_icon"
      onClick={() => {
        history.push({
          search: `?edit=${item.MasterPipeElementID}&tab=properties`,
        });
      }}
      title="Master Pipe Element Properties"
    >
      <Icon data={assignment} />{" "}
    </Button>
  );
}

export function MasterPipeElementsList() {
  const query = useCodelist({ codelist: "pmg/master-pipe-elements" });
  const { data } = query;

  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const edit = Number(parsedSearch.get("edit")) ?? 0;

  const { content, filtersDispatch } = useTable({
    query,
    columns,
    title: "Master Pipe Elements",
    itemIdProp: "MasterPipeElementID",
    nameProperty: "MasterPipeElementID",
    nameFilterPlaceholder: "Filter IDs",
    RowMenu,
  });
  const [pmgFilter, setPMGFilter] = useState("");
  const [elementGroupFilter, setElementGroupFilter] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("");

  const pmgOptions = useMemo(
    () => Array.from(new Set(data?.map((e) => e.MaterialGroup).sort())),
    [data]
  );

  const elementGroupOptions = useMemo(
    () => Array.from(new Set(data?.map((e) => e.ElementGroup).sort())),
    [data]
  );

  const priorityOptions = useMemo(
    () => Array.from(new Set(data?.map((e) => String(e.Priority)).sort())),
    [data]
  );

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Material Group",
        prop: "MaterialGroup",
        filterState: pmgFilter,
        setFilterState: setPMGFilter,
        filterOptions: pmgOptions,
      },
    });
  }, [filtersDispatch, pmgFilter, pmgOptions]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Element Group",
        prop: "ElementGroup",
        filterState: elementGroupFilter,
        setFilterState: setElementGroupFilter,
        filterOptions: elementGroupOptions,
      },
    });
  }, [filtersDispatch, elementGroupFilter, elementGroupOptions]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Priority",
        prop: "Priority",
        filterState: priorityFilter,
        setFilterState: setPriorityFilter,
        filterOptions: priorityOptions,
      },
    });
  }, [filtersDispatch, priorityFilter, priorityOptions]);

  return (
    <>
      {content}
      <MasterPipeElementProperties edit={edit} />
    </>
  );
}
