import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {
  ItemAdminAreas,
  itemAdminConfig,
  AdminMethods,
  ItemAdminSaveProps,
} from "features/admin/itemAdminConfig";
import config from "config/config";

export default function useSaveItemAdmin<
  T extends ItemAdminAreas,
  M extends AdminMethods
>() {
  const queryClient = useQueryClient();

  async function saveAdmin({
    area,
    method,
    data,
  }: T extends unknown
    ? M extends unknown
      ? {
          area: T;
          method: M;
          data: ItemAdminSaveProps<T, M> | { id: number };
        }
      : never
    : never) {
    let dataCopy = JSON.parse(JSON.stringify(data));
    const { id, ...dataToSend } = dataCopy;
    return await axios({
      url: `${config.restApiBaseUrl}${
        itemAdminConfig[area].editUrl
          ? itemAdminConfig[area].editUrl
          : itemAdminConfig[area].url
      }${method === "put" || method === "delete" ? `/${id}` : ""}`,
      method,
      ...(["put", "post"].includes(method)
        ? { data: JSON.stringify(dataToSend) }
        : {}),
    });
  }

  return useMutation(saveAdmin, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["codelists", variables.area]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
