import { Button } from "@equinor/eds-core-react";
import useConfirm from "components/confirm/useConfirm";
import { MultiselectFieldFromCommaSeparated } from "components/form/EditComponents";
import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import {
  Fieldset,
  FieldsetContainer,
  FormRowBlock,
} from "components/form/Form";
import { MessageModal } from "components/MessageModal";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSaveVDSTextBlockProperties from "features/sheets/queries/useSaveVDSTextBlockProperties";
import useSheet from "features/sheets/queries/useSheet";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { confirmNavigation } from "utils/confirmNavigation";
import { successMessage } from "utils/successMessage";
import { closeEditSheet } from "../../commands";
import { sheetProperties } from "../../config/sheetConfig";
import { SaveVDSTextBlockProperties, VDSTextBlock } from "../../types";
import {
  PropertiesColumns,
  QueryStatusAction,
  statusesReducer,
  VDSValidators,
} from "../VDSCommonProperties";

export function VDSTextBlockName({ data }: { data: VDSTextBlock }) {
  return (
    <Field
      type="text"
      feature="sheets"
      sheetType="vds-textblocks"
      prop="TextBlockName"
      value={data.TextBlockName}
      title="Text Block Name"
    />
  );
}

export function VDSCharacteristics({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSTextBlock;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  const {
    data: vdsCharacteristicsData,
    status,
    error,
  } = useCodelist({
    codelist: "vds-characteristics",
  });

  useEffect(() => {
    statusesDispatch({
      type: "set",
      payload: { "vds-characteristics": status },
    });
  }, [status, statusesDispatch]);

  const vdsCharacteristicsOptions = useMemo(
    () =>
      vdsCharacteristicsData?.map((e) => ({
        key: String(e.CharacteristicID),
        title: e.Description,
      })),
    [vdsCharacteristicsData]
  );

  return (
    <MultiselectFieldFromCommaSeparated
      options={vdsCharacteristicsOptions}
      prop="VDSCharacteristicProperty"
      title="VDS Characteristics"
      value={data.VDSCharacteristicProperty}
      noneSelectedString="None"
      noneSelectedValue=""
      status={status}
      error={error}
      isRefetching={isRefetching}
    />
  );
}

export function VDSTextBlockPropertiesFields({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSTextBlock;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  return (
    <PropertiesColumns>
      <VDSCharacteristics
        data={data}
        statusesDispatch={statusesDispatch}
        isRefetching={isRefetching}
      />
      <VDSValidators
        data={data}
        statusesDispatch={statusesDispatch}
        isRefetching={isRefetching}
      />
    </PropertiesColumns>
  );
}

export function VDSTextBlockPropertiesModal({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const history = useHistory();
  const open = useMemo(
    () => !!name && !!revision && tab === "properties",
    [name, revision, tab]
  );

  const [showAlertModal, setShowAlertModal] = useState(false);

  const {
    mutate: saveVDSTextBlockProperties,
    status: saveVDSTextBlockPropertiesStatus,
    error: saveVDSTextBlockPropertiesError,
    reset: saveVDSTextBlockPropertiesReset,
    data: saveVDSTextBlockPropertiesData,
  } = useSaveVDSTextBlockProperties();

  const onSubmit: SubmitHandler<SaveVDSTextBlockProperties> = (formData) => {
    Object.keys(formData)
      .map((e) => formData[e as keyof typeof formData])
      .includes(null)
      ? setShowAlertModal(true)
      : saveVDSTextBlockProperties({
          name,
          revision,
          content: formData,
        });
  };

  const methods = useForm<SaveVDSTextBlockProperties>({
    shouldUnregister: true,
    mode: "all",
  });
  const { reset, formState, handleSubmit } = methods;
  const { isDirty } = formState;

  const { isConfirmed } = useConfirm();

  const closeModal = async () => {
    (!isDirty || (await confirmNavigation({ isConfirmed }))) &&
      closeEditSheet({ history });
  };

  const { data, status, error, isRefetching, isRefetchError } = useSheet({
    sheetType: "vds-textblocks",
    name,
    revision,
  });

  const [statuses, statusesDispatch] = useReducer(statusesReducer, {});

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(...Object.values(statuses)),
    [statuses]
  );

  useEffect(() => {
    combinedStatuses === "success" &&
      !isRefetching &&
      !isRefetchError &&
      reset(undefined, { keepDirty: false });
  }, [isRefetching, isRefetchError, data, reset, combinedStatuses]);

  const closeOnSuccess = useRef(false);

  const onSuccess = useCallback(() => {
    if (closeOnSuccess.current) {
      reset(undefined, { keepDirty: false });
      saveVDSTextBlockPropertiesReset();
      closeModal();
    }
    // closeModal is not useCallback-able
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, saveVDSTextBlockPropertiesReset]);

  return (
    <>
      <MessageModal
        isOpen={showAlertModal}
        onClose={() => setShowAlertModal(false)}
        title="Modify selection"
        shouldCloseOnOverlayClick={true}
      >
        Either All/None or at least one element should be selected.
      </MessageModal>
      <ModalWindow
        closeModal={closeModal}
        isOpen={open}
        title={`Properties for ${sheetProperties["vds-textblocks"].name} ${name}`}
        layer="default"
        status={status}
        error={error}
      >
        {data && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldContext.Provider value={{ disabled: isRefetching }}>
                <ModalSideMargin>
                  <ModalWindowContentDefault>
                    <FieldsetContainer>
                      <Fieldset>
                        <VDSTextBlockName data={data} />
                      </Fieldset>
                      <Fieldset>
                        <FormRowBlock>
                          <VDSTextBlockPropertiesFields
                            data={data}
                            statusesDispatch={statusesDispatch}
                            isRefetching={isRefetching}
                          />
                        </FormRowBlock>
                      </Fieldset>
                    </FieldsetContainer>
                  </ModalWindowContentDefault>
                </ModalSideMargin>
                <ModalWindowButtonContainer>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={combinedStatuses !== "success" || isRefetching}
                    onClick={() => {
                      closeOnSuccess.current = false;
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="submit"
                    disabled={combinedStatuses !== "success" || isRefetching}
                    onClick={() => {
                      closeOnSuccess.current = true;
                    }}
                  >
                    Save & Close
                  </Button>
                  <Button variant="outlined" onClick={closeModal}>
                    Close
                  </Button>
                </ModalWindowButtonContainer>
              </FieldContext.Provider>
            </form>
          </FormProvider>
        )}
      </ModalWindow>
      <StatusModal
        status={saveVDSTextBlockPropertiesStatus}
        error={saveVDSTextBlockPropertiesError}
        onSettledClose={saveVDSTextBlockPropertiesReset}
        successMessage={successMessage(saveVDSTextBlockPropertiesData)}
        onSuccess={onSuccess}
      />
    </>
  );
}
