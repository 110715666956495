import {
  Button,
  Checkbox,
  Icon,
  Label,
  Typography,
} from "@equinor/eds-core-react";
import { external_link } from "@equinor/eds-icons";
import { InfolineContainer } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowDefaultContainer,
} from "components/ModalWindow";
import StatusModal, { LoadingModal } from "components/StatusModal";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";
import useConfirm from "components/confirm/useConfirm";
import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { FormRowBlock } from "components/form/Form";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { QueryStatus } from "react-query";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { successMessage } from "utils/successMessage";
import { DropzoneField } from "./DropzoneField";
import { FolderBreadcrumbs } from "./FolderBreadcrumbs";
import {
  CreateDocumentType,
  useCreateDocument,
} from "./queries/useCreateDocument";
import {
  DocumentSpaceProperties,
  useDocumentSpaceProperties,
} from "./queries/useDocumentProperties";
import {
  DocumentSpaceDocumentType,
  DocumentSpaceItem,
  documentSpaceDocumentTypeName,
} from "./queries/useDocumentSpace";
import { EditDocumentData, useEditDocument } from "./queries/useEditDocument";
import { usePlantList } from "./queries/usePlantList";
import { useOperatorList } from "./queries/useOperatorList";
import { documentTypesWithFile } from "./DocumentSpace";
import { useFileUrl } from "./useFileUrl";

const InfoBlock = styled.div`
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
  font-size: 90%;
`;

const InfoTitle = styled.div`
  min-width: 60px;
  display: inline-block;
  text-align: right;
  font-weight: 500;
`;

const InfoContent = styled.div``;

export function DocumentSpaceEdit(props: {
  oid: number;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  foldersData: DocumentSpaceItem[] | undefined;
  getParents: (item: DocumentSpaceItem) => DocumentSpaceItem[];
  setPaneCacheBust: React.Dispatch<string>;
}) {
  const { data, error, status } = useDocumentSpaceProperties({
    oid: props.oid,
  });
  return props.open ? (
    data || error ? (
      <DocumentSpaceModal
        isCreate={false}
        documentType={data?.DocumentType ?? "F"}
        status={status}
        data={data}
        {...props}
      />
    ) : (
      <LoadingModal />
    )
  ) : (
    <></>
  );
}

export function DocumentSpaceCreate(props: {
  parentOID: number;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  documentType: CreateDocumentType;
  foldersData: DocumentSpaceItem[] | undefined;
  getParents: (item: DocumentSpaceItem) => DocumentSpaceItem[];
}) {
  return props.open ? (
    <DocumentSpaceModal isCreate={true} oid={0} {...props} />
  ) : (
    <></>
  );
}

type FormData = {
  description: string;
  comment: string;
  referenceId: string;
  visibleOnWeb: "Y" | "N";
  embeddedPicture: "Y" | "N";
  urlAddress: string;
  file: File[];
  plantID: number;
  operatorID: number;
};

export function DocumentSpaceModal({
  isCreate,
  parentOID,
  open,
  setOpen,
  oid,
  documentType,
  data,
  error,
  status,
  foldersData,
  getParents,
  setPaneCacheBust,
}: {
  isCreate: boolean;
  parentOID?: number;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  oid: number;
  documentType: CreateDocumentType | DocumentSpaceDocumentType;
  data?: DocumentSpaceProperties;
  error?: Error | unknown;
  status?: QueryStatus;
  foldersData: DocumentSpaceItem[] | undefined;
  getParents: (item: DocumentSpaceItem) => DocumentSpaceItem[];
  setPaneCacheBust?: React.Dispatch<string>;
}) {
  const shouldClose = useRef(false);

  const {
    mutate: createMutate,
    status: createStatus,
    error: createError,
    data: createData,
    reset: createReset,
  } = useCreateDocument();

  const {
    mutate: editMutate,
    status: editStatus,
    error: editError,
    data: editData,
    reset: editReset,
  } = useEditDocument({ oid });

  const methods = useForm<FormData>({
    shouldUnregister: true,
    mode: "all",
  });

  const { handleSubmit, watch, formState, reset, setValue } = methods;

  const { isConfirmed } = useConfirm();

  const closeModal = async () => {
    (Object.keys(formState.dirtyFields).length === 0 ||
      (await isConfirmed(defaultUnsavedMessage))) &&
      setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    const dataToSend: EditDocumentData = {
      Description: data.description ?? "",
      Comment: data.comment ?? "",
      URLAddress: data.urlAddress ?? "",
      Tag: data.referenceId ?? "",
      MenuCategory:
        menuTab && category ? "B" : menuTab ? "M" : category ? "C" : "",
      VisibleOnWeb: data.visibleOnWeb ? "Y" : "N",
      ContentTextOnly: "N",
      EmbeddedPicture: data.embeddedPicture ? "Y" : "N",
    };
    const file = data.file && data.file.length > 0 ? data.file[0] : undefined;
    if (isCreate) {
      createMutate({
        data: {
          DocumentType: documentType as CreateDocumentType,
          ParentOID: Number(parentOID),
          DocumentIDLink: "",
          OperatorID: data.operatorID ?? 0,
          PlantID: data.plantID ?? 0,
          ...dataToSend,
        },
        file,
      });
    } else {
      editMutate({ data: dataToSend, file });
    }
  });

  const targetItem = useMemo(
    () =>
      foldersData?.find(
        (e) => e.OID === parentOID || e.OID === data?.ParentOID
      ),
    [foldersData, parentOID, data]
  );

  const { data: plantListData, status: plantListStatus } = usePlantList({
    oid: targetItem?.OID ?? 0,
    enabled: documentType === "P" && open,
  });

  const plantListOptions = useMemo(
    () =>
      plantListData?.map((e) => ({
        id: e.PlantID,
        option: e.Description,
      })),
    [plantListData]
  );

  const { data: operatorListData, status: operatorListStatus } =
    useOperatorList({
      oid: targetItem?.OID ?? 0,
      enabled: documentType === "N" && open,
    });

  const operatorListOptions = useMemo(
    () =>
      operatorListData?.map((e) => ({
        id: e.OperatorID,
        option: e.Description,
      })),
    [operatorListData]
  );

  const descriptionWatch = watch("description");
  const urlWatch = watch("urlAddress");
  const fileWatch = watch("file");

  useEffect(() => {
    if (isCreate) {
      documentType === "C"
        ? setValue("description", "Folder content text")
        : setValue("description", fileWatch?.[0]?.name ?? "");
    }
  }, [fileWatch, isCreate, setValue, documentType]);

  const onEditSuccess = useCallback(() => {
    reset(undefined, { keepValues: true });
    shouldClose.current && setOpen(false);
    setPaneCacheBust && setPaneCacheBust(new Date().toString());
  }, [reset, setOpen, setPaneCacheBust]);

  const [menuTab, setMenuTab] = useState(
    isCreate
      ? false
      : data?.MenuOrCategory === "M" || data?.MenuOrCategory === "B"
      ? true
      : false
  );

  const [category, setCategory] = useState(
    isCreate
      ? false
      : data?.MenuOrCategory === "C" || data?.MenuOrCategory === "B"
      ? true
      : false
  );

  const breadcrumbsContent = useMemo(
    () => (
      <FolderBreadcrumbs
        targetItem={targetItem}
        targetParentItems={targetItem ? getParents(targetItem) : []}
        gap={2}
      />
    ),
    [getParents, targetItem]
  );

  const [replaceFile, setReplaceFile] = useState(false);
  const [buttonRef, buttonSize] = useMeasure();
  const { getFileUrl } = useFileUrl();

  return (
    <>
      <ModalWindow
        title={
          isCreate
            ? `Create a ${documentSpaceDocumentTypeName[documentType]}`
            : `Edit ${documentSpaceDocumentTypeName[documentType]} ` + oid
        }
        isOpen={open}
        closeModal={closeModal}
        hide={createStatus === "success"}
        error={error}
        status={status}
        hasInfoline={!isCreate}
      >
        <ModalWindowDefaultContainer style={{ width: 450 }}>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <FieldContext.Provider value={{}}>
                <ModalSideMargin>
                  <Typography as="div" style={{ margin: "6px 8px 20px" }}>
                    <InfoBlock>
                      <InfoTitle>{isCreate ? "In" : "Location"}:</InfoTitle>
                      <InfoContent>{breadcrumbsContent}</InfoContent>
                    </InfoBlock>
                    {documentTypesWithFile.includes(documentType) &&
                      !isCreate && (
                        <InfoBlock>
                          <InfoTitle>Filename:</InfoTitle>
                          <InfoContent>
                            <div style={{ display: "flex", gap: 12 }}>
                              <div>{data?.FileName || <i>Empty</i>}</div>
                              <div style={{ marginTop: -12 }}>
                                <Button
                                  variant="ghost_icon"
                                  onClick={() => {
                                    getFileUrl &&
                                      window.open(getFileUrl(data), "_blank");
                                  }}
                                  title="Download / Open file in new window"
                                >
                                  <Icon data={external_link} size={16} />
                                </Button>
                              </div>
                            </div>
                          </InfoContent>
                        </InfoBlock>
                      )}
                  </Typography>
                  {documentTypesWithFile.includes(documentType) ? (
                    <FormRowBlock>
                      {isCreate || replaceFile ? (
                        <motion.div
                          initial={
                            isCreate
                              ? {}
                              : { height: buttonSize.height, opacity: 0 }
                          }
                          animate={
                            isCreate ? {} : { height: "auto", opacity: 1 }
                          }
                          transition={{ duration: isCreate ? 0 : 0.25 }}
                        >
                          <DropzoneField
                            name="file"
                            key={JSON.stringify(data ?? {})}
                          />
                        </motion.div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            gap: 16,
                          }}
                          ref={buttonRef}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setReplaceFile(true);
                            }}
                          >
                            Replace file
                          </Button>
                        </div>
                      )}
                    </FormRowBlock>
                  ) : (
                    <></>
                  )}
                  {documentType === "P" && (
                    <Field
                      title="Plant"
                      prop="plantID"
                      type="option"
                      optionsWithIds={plantListOptions}
                      value={isCreate ? "" : data?.PlantID ?? ""}
                      status={plantListStatus}
                      disabled={!isCreate}
                      notSetText={"– Choose a Plant... –"}
                    />
                  )}
                  {documentType === "N" && (
                    <Field
                      title="Operator"
                      prop="operatorID"
                      type="option"
                      optionsWithIds={operatorListOptions}
                      value={isCreate ? "" : data?.OperatorID ?? ""}
                      status={operatorListStatus}
                      disabled={!isCreate}
                      notSetText={"– Choose an Operator... –"}
                    />
                  )}
                  {((documentType !== "P" && documentType !== "N") ||
                    !isCreate) && (
                    <Field
                      title="Description"
                      prop="description"
                      autoFocus
                      type="text"
                      value={
                        documentType === "G"
                          ? "Plant Specific"
                          : isCreate
                          ? ""
                          : data?.Description ?? ""
                      }
                      disabled={documentType === "G"}
                    />
                  )}
                  {documentType === "U" ? (
                    <Field
                      title="URL Address"
                      prop="urlAddress"
                      type="text"
                      value={isCreate ? "" : data?.URLAddress ?? ""}
                      afterInputContent={
                        <Button
                          variant="ghost_icon"
                          onClick={() => window.open(urlWatch, "_blank")}
                        >
                          <Icon data={external_link} />
                        </Button>
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {documentType === "D" ? (
                    <Field
                      title="Comment"
                      prop="comment"
                      type="text"
                      rows={3}
                      value={isCreate ? "" : data?.Comment ?? ""}
                    />
                  ) : (
                    <></>
                  )}
                  {documentType === "F" && (
                    <>
                      <Label
                        label="Folder Type"
                        style={{ marginTop: "2.5em" }}
                      />
                      <Checkbox
                        label="Category"
                        checked={category}
                        onChange={() => setCategory(!category)}
                      />
                      <Checkbox
                        label="Menu Tab"
                        checked={menuTab}
                        onChange={() => setMenuTab(!menuTab)}
                      />
                    </>
                  )}
                  {documentType !== "N" && (
                    <>
                      <Label
                        label="Visibility"
                        style={{ marginTop: "2em", marginBottom: -16 }}
                      />
                      <Field
                        type="checkbox"
                        prop="visibleOnWeb"
                        value={
                          isCreate
                            ? "N"
                            : data?.VisibleOnWeb === "Y"
                            ? "Y"
                            : "N"
                        }
                        title="Visible on web"
                        style={{ marginBottom: 0 }}
                      />
                      {documentType === "D" || documentType === "U" ? (
                        <>
                          <Label
                            label="Properties"
                            style={{ marginTop: "2em", marginBottom: -16 }}
                          />
                          <Field
                            type="checkbox"
                            prop="embeddedPicture"
                            value={
                              isCreate
                                ? "N"
                                : data?.EmbeddedPicture === "Y"
                                ? "Y"
                                : "N"
                            }
                            title="Embedded picture"
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {documentType !== "C" && (
                        <Field
                          title="Reference ID (Tag)"
                          prop="referenceId"
                          type="text"
                          value={isCreate ? "" : data?.Tag ?? ""}
                        />
                      )}
                    </>
                  )}
                </ModalSideMargin>
                <ModalWindowButtonContainer
                  style={{ marginTop: 36 }}
                  hasInfoline={!isCreate}
                >
                  {isCreate && (
                    <Button
                      type="submit"
                      disabled={
                        descriptionWatch === "" ||
                        (documentTypesWithFile.includes(documentType) &&
                          (!fileWatch || fileWatch.length === 0))
                      }
                    >
                      Create
                    </Button>
                  )}
                  {!isCreate && (
                    <Button
                      type="submit"
                      disabled={descriptionWatch === "" && documentType !== "G"}
                      variant="outlined"
                      onClick={() => {
                        shouldClose.current = false;
                      }}
                    >
                      Save
                    </Button>
                  )}
                  {!isCreate && (
                    <Button
                      type="submit"
                      disabled={descriptionWatch === ""}
                      onClick={() => {
                        shouldClose.current = true;
                      }}
                    >
                      Save & Close
                    </Button>
                  )}
                  <Button onClick={closeModal} variant="outlined">
                    {isCreate ? "Cancel" : "Close"}
                  </Button>
                </ModalWindowButtonContainer>
                {!isCreate && (
                  <InfolineContainer>
                    <div>
                      Type:{" "}
                      {data
                        ? documentSpaceDocumentTypeName[data.DocumentType]
                        : ""}
                    </div>
                    {documentTypesWithFile.includes(documentType) ? (
                      <div>File size: {data?.FileSizeKB ?? "-"} kB</div>
                    ) : (
                      <></>
                    )}
                    {data?.LastModified ? (
                      <div>Last modified: {data.LastModified}</div>
                    ) : (
                      <></>
                    )}
                  </InfolineContainer>
                )}
              </FieldContext.Provider>
            </form>
          </FormProvider>
        </ModalWindowDefaultContainer>
      </ModalWindow>
      <StatusModal
        status={createStatus}
        error={createError}
        onSettledClose={() => createReset()}
        onSuccessClose={() => setOpen(false)}
        successMessage={successMessage(createData)}
      />
      <StatusModal
        status={editStatus}
        error={editError}
        onSettledClose={() => editReset()}
        onSuccess={onEditSuccess}
        successMessage={successMessage(editData)}
      />
    </>
  );
}
