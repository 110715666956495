import styled from "styled-components";

export const FlexContainerFullHeight = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export const FlexElementFullHeight = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export const AbsoluteScroller = styled.div`
  position: absolute;
  inset: 0;
  overflow: auto;
`;
