import { Button } from "@equinor/eds-core-react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
  ModalWindowDefaultContainer,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";
import useConfirm from "components/confirm/useConfirm";
import { Field } from "components/form/Field";
import { closeEditSheet } from "features/sheets/commands";
import { useMaterialGroups } from "features/pmg/queries/useMaterialGroups";
import { useCallback, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { successMessage } from "utils/successMessage";
import { useSaveMaterialGroupsProperties } from "../queries/useSaveMaterialGroupsProperties";

export function PipeMaterialGroupProperties({
  name,
  revision,
  tab,
}: {
  name: string | null;
  revision: string | null;
  tab: string | null;
}) {
  const isOpen = !!name && !!revision && tab === "properties";
  return isOpen ? (
    <PipeMaterialGroupPropertiesModal
      name={name}
      revision={revision}
      tab={tab}
    />
  ) : null;
}

function PipeMaterialGroupPropertiesModal({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const methods = useForm({ mode: "all" });
  const { handleSubmit, formState, reset } = methods;
  const { isDirty } = formState;

  const { isConfirmed } = useConfirm();
  const history = useHistory();
  const closeModal = async () => {
    isDirty
      ? (await isConfirmed(defaultUnsavedMessage)) &&
        closeEditSheet({ history })
      : closeEditSheet({ history });
  };

  const { data, status, error } = useMaterialGroups({
    name,
    revision,
    postfix: "properties",
  });

  const { data: pmgData } = useMaterialGroups({
    materialGroupId: name,
    postfix: undefined,
  });

  const currentPMGData = pmgData?.find((e) => e.Revision === revision);

  const {
    mutate,
    status: mutateStatus,
    error: mutateError,
    reset: mutateReset,
    data: mutateData,
  } = useSaveMaterialGroupsProperties();

  const onSubmit = useCallback(
    (data) => {
      mutate({ name, revision, data });
    },
    [mutate, name, revision]
  );

  const shouldClose = useRef(false);

  const onSuccess = useCallback(() => {
    reset(undefined, { keepDirty: false, keepValues: true });
    if (shouldClose.current) {
      mutateReset();
      closeModal();
    }
    // closeModal is not useCallback-able
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateReset, reset]);

  return (
    <>
      <ModalWindow
        title={`Properties of Pipe Material Group ${currentPMGData?.MaterialGroup} rev. ${revision}`}
        closeModal={() => {
          closeModal();
        }}
        isOpen={!!name && !!String(revision) && tab === "properties"}
        status={status}
        error={error}
      >
        {data && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalWindowDefaultContainer>
                <ModalWindowContentDefault>
                  <ModalSideMargin>
                    <Field
                      type="text"
                      rows={6}
                      value={data?.Notepad}
                      prop="Notepad"
                      title="Note"
                    />
                  </ModalSideMargin>
                </ModalWindowContentDefault>
              </ModalWindowDefaultContainer>
              <ModalWindowButtonContainer>
                <Button
                  type="submit"
                  variant="outlined"
                  onClick={() => {
                    shouldClose.current = false;
                  }}
                >
                  Save
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    shouldClose.current = true;
                  }}
                >
                  Save & Close
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Close
                </Button>
              </ModalWindowButtonContainer>
            </form>
          </FormProvider>
        )}
      </ModalWindow>
      <StatusModal
        status={mutateStatus}
        error={mutateError}
        onSuccess={onSuccess}
        onSettledClose={mutateReset}
        successMessage={successMessage(mutateData)}
      />
    </>
  );
}
