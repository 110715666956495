import { Access } from "Access";
import { ReportURIs, Reports, ReportsProps } from "./Reports";
import { useParams } from "react-router-dom";

export function AccessReports({ headerWidth }: ReportsProps) {
  const { report } = useParams<{ report: ReportURIs | string }>();
  return (
    <Access cap="reports" module="reports">
      <Access role="X" enabled={report === "activity-log"}>
        <Reports headerWidth={headerWidth} />
      </Access>
    </Access>
  );
}
