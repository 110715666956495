import {
  CircularProgress,
  Label,
  NativeSelect,
  Search,
  Typography,
} from "@equinor/eds-core-react";
import React, { useState } from "react";
import { useMemoOne as useMemo } from "use-memo-one";
import {
  FlexContainer,
  FlexElement,
  InlineBlock,
} from "../../../components/Components";
import Table, { SmallTableContainer } from "../../../components/table/Table";
import { useTableSelect } from "../../../components/table/useTableSelect";
import { useCodelist } from "../../../queries/useCodelist";
import { filterItemsByName } from "../../../utils/filterItemsByName";
import { filterItemsByProps } from "../../../utils/filterItemsByProps";
import { PlantProps } from "../../plant/types";

const standardNotesColumns = [
  {
    key: "NoteID",
    title: "ID",
    width: 45,
  },
  {
    key: "Category",
    title: "Category",
    width: 130,
  },
  {
    key: "NoteText",
    title: "Note",
    width: "100%",
    minWidth: 130,
  },
];

export type NoteSections = "PCS" | "VDS";

export default function useStandardNotes({
  section,
  plant,
  enabled,
  elementID,
  noteSelectionMode,
  defaultSelection,
}: {
  section?: NoteSections;
  plant?: PlantProps;
  enabled?: boolean;
  elementID?: string;
  noteSelectionMode?: "single" | "multi";
  defaultSelection?: string[];
}): {
  selection: string[];
  selectionDispatch: Function;
  standardNotesContent: React.ReactFragment;
} {
  const { data, status, isRefetching, refetch, error } = useCodelist({
    codelist: "pcs-standard-notes",
    extraParams: {
      ...(section && { notefilter: section }),
      ...(plant && { plantID: plant.PlantID }),
      ...(elementID && { elementID }),
    },
    enabled,
  });

  const [textFilter, setTextFilter] = useState("");
  const [category, setCategory] = useState("");

  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: noteSelectionMode ? noteSelectionMode : "single",
    selection: defaultSelection,
  });

  const categories = useMemo(
    () =>
      data
        ? data.reduce((acc, curr) => {
            return acc.includes(curr.Category)
              ? acc
              : acc.concat(curr.Category);
          }, [] as string[])
        : [],
    [data]
  );

  const items = useMemo(
    () =>
      filterItemsByProps({
        filters: [
          {
            type: "select",
            group: "",
            title: "Category",
            prop: "Category",
            filterState: category,
            setFilterState: setCategory,
            defaultFilterState: "",
          },
        ],
        items: filterItemsByName({
          nameFilter: textFilter,
          nameProperty: [
            { name: "NoteText", matchAnywhere: true },
            { name: "NoteID", matchExact: true },
          ],
          items: data ? data : [],
          alwaysPresent: {
            idProp: "NoteID",
            ids: selection,
          },
        }),
        alwaysPresent: {
          idProp: "NoteID",
          ids: selection,
        },
      }),
    // selection omitted on purpose.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category, textFilter, data]
  );

  const standardNotesContent = (
    <SmallTableContainer>
      <Table
        density="compact"
        items={items}
        itemIdProp="NoteID"
        loadingString="Loading Standard Notes..."
        controlHeader={
          <ControlHeader
            textFilter={textFilter}
            setTextFilter={setTextFilter}
            count={items ? items.length : 0}
            isLoading={status === "loading"}
            isRefetching={isRefetching}
            categories={categories}
            category={category}
            setCategory={setCategory}
          />
        }
        columns={standardNotesColumns}
        contextData={{}}
        status={status}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={true}
        error={error}
        refetch={refetch}
        selectedFirst={true}
      />
    </SmallTableContainer>
  );

  return { selection, selectionDispatch, standardNotesContent };
}

function ControlHeader({
  textFilter,
  setTextFilter,
  count,
  isLoading,
  isRefetching,
  categories,
  category,
  setCategory,
}: {
  textFilter: string;
  setTextFilter: Function;
  count: number;
  isLoading: boolean;
  isRefetching: boolean;
  categories: string[];
  category: string;
  setCategory: Function;
}) {
  return (
    <div style={{ paddingBottom: "1em" }}>
      <FlexContainer
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-end",
          marginBottom: "-12px",
        }}
      >
        <FlexElement style={{ paddingRight: "1.5em", marginBottom: "12px" }}>
          <Label htmlFor="NoteSearch" label="Note text & ID search" />
          <Search
            id="NoteSearch"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            style={{ width: 150 }}
          />
        </FlexElement>
        <FlexElement style={{ marginBottom: "12px" }}>
          <NativeSelect
            id="category"
            label="Category"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            style={{ width: 170 }}
          >
            <option value="">- All Categories -</option>
            {categories.map((c) => (
              <option key={c}>{c}</option>
            ))}
          </NativeSelect>
        </FlexElement>
        {!isLoading && (
          <FlexElement style={{ marginLeft: "auto", alignSelf: "flex-end" }}>
            <CircularProgress
              size={16}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                marginRight: "12px",
                verticalAlign: "middle",
              }}
            />
            <InlineBlock>
              <Typography variant="overline">
                {count} {count === 1 ? "item" : "items"}
              </Typography>
            </InlineBlock>
          </FlexElement>
        )}
      </FlexContainer>
    </div>
  );
}
