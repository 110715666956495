import { SheetsTypeData } from "features/sheets/queries/useSheetList";
import { CommonObjectContent } from "utils/filterItemsByProps";
import { PlantProps } from "../plant/types";
import {
  SheetTypes,
  nameProperties,
  plantDependentSheets,
  sheetProperties,
} from "./config/sheetConfig";

export type WithItemId<T extends CommonObjectContent> = T & { itemId: string };

export const splitter = "-";
export const ender = "---";

export function stringToCode(text: string): string {
  return text
    .split("")
    .map((v) => v.charCodeAt(0))
    .join("-");
}

export function codeToString(code: string): string {
  return code
    .split("-")
    .map((v) => String.fromCharCode(parseInt(v)))
    .join("");
}

export function getItemID(name: string | number, revision: string) {
  return `${name}${splitter}${revision}`;
}

export function getItemObj(IDstring: string) {
  return {
    name: getItemName(IDstring),
    revision: getItemRev(IDstring),
  };
}

export function getItemName(IDstring: string) {
  return IDstring.substring(0, IDstring.lastIndexOf(splitter));
}

export function getItemRev(IDstring: string) {
  return IDstring.substring(IDstring.lastIndexOf(splitter) + 1);
}

function zeroFill(number: number, width: number) {
  width -= number.toString().length;
  if (width > 0) {
    return (
      new Array(width + (/\./.test(number.toString()) ? 2 : 1)).join("0") +
      number.toString()
    );
  }
  return number + ""; // always return a string
}

export const getPreviewUrl = ({
  item,
  sheetType,
  previewBaseUrl,
  plant,
}: {
  item: any;
  sheetType: SheetTypes;
  previewBaseUrl: string;
  plant?: PlantProps;
}) => {
  let url = previewBaseUrl + "/datasheet_selection/datasheets_result.jsp";
  if (plantDependentSheets.includes(sheetType) && plant) {
    url += `?${sheetProperties[sheetType].name}=${zeroFill(plant.PlantID, 2)}${
      item[nameProperties[sheetType]]
    }`;
    url += sheetType === "general" ? "" : item.Revision;
    url += sheetType === "vsm" && item.Format ? item?.Format : "";
  } else if (sheetType === "vds-subsegments") {
    url += `?VDS_subsegment=${item[nameProperties[sheetType]]}${item.Revision}`;
  } else if (sheetType === "eds") {
    url += `?SST=${item[nameProperties[sheetType]]}${item.Revision}`;
  } else if (sheetType === "vds" || sheetType === "vds-unstructured") {
    url += `?VDS=${item[nameProperties[sheetType]]}${item.Revision}`;
  } else if (sheetType === "mds") {
    url += `?MDS=${item[nameProperties[sheetType]]}${item.Revision}`;
  } else if (sheetType === "vsk") {
    url += `?VSK=${item[nameProperties[sheetType]]}${item.Revision}`;
  } else if (sheetType === "vsm") {
    url += `?VSM=${item[nameProperties[sheetType]]}${item.Revision}${
      item.Format
    }`;
  }
  return url;
};

export const getSheetNameByType = (sheetData: any, sheetType: SheetTypes) => {
  return sheetData[nameProperties[sheetType]];
};

export const getSheetName = (sheetData: any, sheetType: SheetTypes) => {
  return `${sheetData[nameProperties[sheetType]]}${
    sheetType === "general" ? "" : ` rev. ${sheetData["Revision"]}`
  }`;
};

export function cannotBeRevised<T extends SheetTypes>(
  item: any,
  items: SheetsTypeData<T>[] | undefined,
  sheetType: T
) {
  if (!items) {
    return true;
  }
  const nameProperty = nameProperties[sheetType];
  return (
    !["O", "I"].includes(item.Status) ||
    (["O", "I"].includes(item.Status) &&
      items.filter(
        (i: any) => i[nameProperty] === item[nameProperty] && i.Status === "W"
      ).length > 0) ||
    (item.Status === "O" &&
      items.filter(
        (i: any) => i[nameProperty] === item[nameProperty] && i.Status === "I"
      ).length > 0) ||
    ((sheetType === "vsm" || sheetType === "sc") && item.Format !== "F")
  );
}

export function addIndefiniteArticleToSheetType(sheetTypeString: string) {
  return ["sc", "eds"].indexOf(sheetTypeString.toLowerCase()) > -1
    ? `an ${sheetTypeString}`
    : `a ${sheetTypeString}`;
}

export function stripSheetArrayToIDs(sheetArray: any[], sheetType: SheetTypes) {
  return sheetArray.map((item) => {
    return {
      [nameProperties[sheetType]]: item[nameProperties[sheetType]],
      Revision: item.Revision,
    };
  });
}

export function getControlID(
  prefix: string,
  element: string
): `${string}.${string}` {
  return `${prefix}.${element}` as const;
}

export function generateId() {
  return `p-${(Math.random().toString(36) + "00000000000000000").slice(2, 7)}`;
}
