import { CircularProgress } from "@equinor/eds-core-react";

export function HeaderProgress({
  isRefetching,
  size = 24,
}: {
  isRefetching: boolean;
  size?: 24 | 16 | 32 | 40 | 48 | undefined;
}) {
  return (
    <CircularProgress
      style={{
        visibility: isRefetching ? "visible" : "hidden",
        marginRight: "12px",
        verticalAlign: "middle",
      }}
      size={size}
    />
  );
}
