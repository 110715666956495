import axios from "axios";
import { useQuery } from "react-query";
import { getApiUrl } from "./queryUtil";

type PFL2Verification = {
  getPFL2: {
    ProductFormLevel2ID: number;
    ProductFormLevel2Text: string;
  }[];
};

export function usePFL2Verification(props: {
  materialGrade: string;
  dimStandard: string;
  productForm: string;
  elementGroupId: number | string | undefined;
  disabled?: boolean;
}) {
  const { materialGrade, dimStandard, productForm, elementGroupId, disabled } =
    props;
  return useQuery<PFL2Verification, Error>(
    [
      "pfl2-veridication",
      materialGrade,
      dimStandard,
      productForm,
      elementGroupId,
    ],
    async () => {
      const request = await axios.get(
        getApiUrl(
          "pmg/master-pipe-elements/pfl2-verification?" +
            Object.keys({
              materialGrade,
              dimStandard,
              productForm,
              elementGroupId,
            })
              .map((e) => e + "=" + props[e as keyof typeof props])
              .join("&")
        )
      );
      return request.data;
    },
    {
      enabled: !disabled && elementGroupId !== undefined,
    }
  );
}
