import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useDeleteDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      objects,
    }: {
      objects: {
        oid: number;
      }[];
    }): Promise<AxiosResponse<StatusResponse>> =>
      await axios(getApiUrl("document-space"), {
        method: "delete",
        data: JSON.stringify({
          objects: objects.map((e) => ({
            OID: e.oid,
          })),
        }),
      }),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["document-space"]);
      },
      onError: (error, variables, context) => {
        console.error(`error ${context}`, error, variables);
      },
    }
  );
}

export type UseDeleteDocument = ReturnType<typeof useDeleteDocument>;
