import { Button } from "@equinor/eds-core-react";
import { ErrorMessage } from "components/Components";
import useConfirm from "components/confirm/useConfirm";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import useSaveItemAdmin from "queries/useSaveItemAdmin";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { QueryStatus } from "react-query";
import { useHistory } from "react-router-dom";
import { useMemo } from "use-memo-one";
import { FieldContext } from "../../components/form/FieldContext";
import { successMessage } from "../../utils/successMessage";
import { closeAdminModal } from "./Admin";
import {
  ItemAdminAreas,
  itemAdminConfig,
  ItemAdminProps,
  ItemAdminReturnType,
  ItemAdminSaveProps,
  ItemAdminSections,
  CreateOrUpdate,
} from "./itemAdminConfig";
import { PCSStandardNotes } from "./pcs-standard-notes/pcsStandardNotesConfig";
import { PCSStandardNotesEdit } from "./pcs-standard-notes/PCSStandardNotesEdit";
import { PipeElementGroup } from "./pipe-element-groups/pipeElementGroupsConfig";
import { PipeElementGroupsEdit } from "./pipe-element-groups/PipeElementGroupsEdit";
import { PlantsEdit } from "./plants/PlantsEdit";
import { PMGDimensionStandards } from "./pmg-dimension-standards/pmgDimensionStandardsConfig";
import { PMGDimensionStandardsEdit } from "./pmg-dimension-standards/PMGDimensionStandardsEdit";
import { PMGProductForms } from "./pmg-product-forms/pmgProductFormsConfig";
import { PMGProductFormsEdit } from "./pmg-product-forms/PMGProductFormsEdit";
import { AllUsers } from "./users/usersConfig";
import { UsersEdit } from "./users/UsersEdit";
import { ValveElementGroups } from "./valve-element-groups/valveElementGroupsConfig";
import { ValveElementGroupsEdit } from "./valve-element-groups/ValveElementGroupsEdit";
import { ValveTypes } from "./valve-types/valveTypesConfig";
import { ValveTypesEdit } from "./valve-types/ValveTypesEdit";
import { VDSCharacteristics } from "./vds-characteristics/vdsCharacteristicsConfig";
import { VDSCharacteristicsEdit } from "./vds-characteristics/VDSCharacteristicsEdit";
import { VDSDesign } from "./vds-design/vdsDesignConfig";
import { VDSDesignEdit } from "./vds-design/VDSDesignEdit";
import { VDSMaterial } from "./vds-material/vdsMaterialConfig";
import { VDSMaterialEdit } from "./vds-material/VDSMaterialEdit";
import { VDSMisc } from "./vds-misc/vdsMiscConfig";
import { VDSMiscEdit } from "./vds-misc/VDSMiscEdit";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";

export type EditComponentProps<T extends ItemAdminAreas> = {
  item?: ItemAdminProps<T>;
  data?: ItemAdminProps<T>[];
  edit?: string | null;
  section?: ItemAdminSections<"pcs-standard-notes">[number];
  editLoading?: boolean;
  setEditLoading?: React.Dispatch<boolean>;
  setInitializing?: React.Dispatch<boolean>;
  setChildStatus?: React.Dispatch<QueryStatus>;
  setChildError?: React.Dispatch<Error | null>;
};

function booleanToString(data: any) {
  const dataCopy = JSON.parse(JSON.stringify(data));
  if (typeof data === "object") {
    Object.keys(data).forEach((e) => {
      if (typeof data[e] === "boolean") {
        dataCopy[e] = data[e] ? "Y" : "N";
      }
    });
  }
  return dataCopy;
}

export function ItemEditModal<
  T extends ItemAdminAreas,
  C extends CreateOrUpdate
>({
  area,
  section,
  method,
  edit,
  data,
  isRefetching,
}: {
  area: T;
  section?: ItemAdminSections<T>[number];
  method: C | false;
  edit: string | null;
  data?: ItemAdminReturnType<T>;
  isRefetching: boolean;
}) {
  const areaConfig = useMemo(() => itemAdminConfig[area], [area]);
  const history = useHistory();
  const { isConfirmed } = useConfirm();
  const methods = useForm<ItemAdminSaveProps<T, C>>({
    shouldUnregister: true,
    mode: "all",
  });
  const { handleSubmit, formState, reset, register } = methods;
  const {
    mutate: saveAdmin,
    status: saveAdminStatus,
    error: saveAdminError,
    data: saveAdminData,
    reset: saveAdminReset,
  } = useSaveItemAdmin();

  const onSubmit = handleSubmit((data) => {
    const preparedData = areaConfig.prepareData
      ? areaConfig.prepareData(data)
      : data;
    method && saveAdmin({ area, method, data: booleanToString(preparedData) });
  });

  const [editLoading, setEditLoading] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [childStatus, setChildStatus] = useState<QueryStatus>("idle");
  const [childError, setChildError] = useState<Error | null>(null);

  const closeModal = useCallback(() => {
    closeAdminModal({ history });
  }, [history]);

  const closeModalIfAfterCreate = useCallback(() => {
    edit === null && closeModal();
  }, [closeModal, edit]);

  const checkIfDirtyThenCloseModal = async () => {
    Object.keys(formState.dirtyFields).length > 0
      ? (await isConfirmed(defaultUnsavedMessage)) && closeModal()
      : closeModal();
  };

  const item = useMemo(
    () =>
      edit && data
        ? data.find((e) => String(e[areaConfig.itemIdProp]) === edit)
        : undefined,
    [edit, data, areaConfig]
  );

  useEffect(() => {
    if (saveAdminStatus === "success" && item) {
      reset(undefined, { keepValues: true });
    }
  }, [saveAdminStatus, item, reset]);

  return (
    <>
      <ModalWindow
        closeModal={checkIfDirtyThenCloseModal}
        isOpen={!!method && !!data}
        title={
          method === "post"
            ? `New ${areaConfig.singularTitle}`
            : edit
            ? `Edit ${areaConfig.singularTitle} ${edit}`
            : ""
        }
        layer="default"
        isLoading={initializing}
        status={childStatus}
        error={childError}
      >
        <>
          {typeof method === "string" &&
          ["post", "put"].includes(method) &&
          data &&
          (method === "post" || (data && edit && item)) ? (
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <FieldContext.Provider
                  value={{
                    disabled: isRefetching || saveAdminStatus === "loading",
                    slim: true,
                  }}
                >
                  <ModalSideMargin>
                    <ModalWindowContentDefault style={{ minWidth: 500 }}>
                      {item && method === "put" && (
                        <input
                          type="hidden"
                          defaultValue={
                            item[areaConfig.itemIdProp] as string | number
                          }
                          // @ts-ignore
                          {...register("id")}
                        />
                      )}
                      {area === "pcs-standard-notes" && (
                        <PCSStandardNotesEdit
                          item={item as PCSStandardNotes}
                          data={data && (data as PCSStandardNotes[])}
                          edit={edit}
                          section={section}
                        />
                      )}
                      {area === "users" && (
                        <UsersEdit
                          item={item as AllUsers}
                          setEditLoading={setEditLoading}
                        />
                      )}
                      {area === "plants" && (
                        <PlantsEdit
                          edit={edit}
                          setEditLoading={setEditLoading}
                          setInitializing={setInitializing}
                          setChildStatus={setChildStatus}
                          setChildError={setChildError}
                        />
                      )}
                      {area === "pipe-element-groups" && (
                        <PipeElementGroupsEdit
                          item={item as PipeElementGroup}
                          setEditLoading={setEditLoading}
                        />
                      )}
                      {area === "valve-element-groups" && (
                        <ValveElementGroupsEdit
                          item={item as ValveElementGroups}
                        />
                      )}
                      {area === "vds-design" && (
                        <VDSDesignEdit item={item as VDSDesign} />
                      )}
                      {area === "vds-material" && (
                        <VDSMaterialEdit item={item as VDSMaterial} />
                      )}
                      {area === "vds-characteristics" && (
                        <VDSCharacteristicsEdit
                          item={item as VDSCharacteristics}
                        />
                      )}
                      {area === "vds-misc" && (
                        <VDSMiscEdit
                          item={item as VDSMisc}
                          setInitializing={setInitializing}
                        />
                      )}
                      {area === "pmg-dimension-standards" && (
                        <PMGDimensionStandardsEdit
                          item={item as PMGDimensionStandards}
                          setEditLoading={setEditLoading}
                        />
                      )}
                      {area === "pmg-product-forms" && (
                        <PMGProductFormsEdit
                          item={item as PMGProductForms}
                          setEditLoading={setEditLoading}
                        />
                      )}
                      {area === "valve-types" && (
                        <ValveTypesEdit
                          item={item as ValveTypes}
                          setEditLoading={setEditLoading}
                        />
                      )}
                    </ModalWindowContentDefault>
                  </ModalSideMargin>
                  <ModalWindowButtonContainer>
                    <Button
                      type="submit"
                      disabled={
                        editLoading ||
                        isRefetching ||
                        saveAdminStatus === "loading" ||
                        Object.keys(formState.errors).length > 0
                      }
                    >
                      {method === "post" ? "Create" : "Update"}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => checkIfDirtyThenCloseModal()}
                    >
                      {method === "post" ? "Cancel" : "Close"}
                    </Button>
                  </ModalWindowButtonContainer>
                </FieldContext.Provider>
              </form>
            </FormProvider>
          ) : (
            <ModalSideMargin>
              <ErrorMessage>No such item.</ErrorMessage>
            </ModalSideMargin>
          )}
        </>
      </ModalWindow>
      <StatusModal
        status={saveAdminStatus}
        error={saveAdminError}
        successMessage={successMessage(saveAdminData)}
        onSettledClose={saveAdminReset}
        onSuccess={closeModalIfAfterCreate}
      />
    </>
  );
}
