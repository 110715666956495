import {
  Button,
  Icon,
  NativeSelect,
  Search,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import { refresh } from "@equinor/eds-icons";
import {
  FlexContainer,
  FlexElement,
  HeaderButtonContainer,
} from "components/Components";
import { NameFilterInfo } from "components/NameFilterInfo";
import { HeaderProgress } from "components/table/HeaderProgress";
import {
  ControlHeaderContainer,
  ControlHeaderTitle,
} from "components/table/Table";
import { useCodelist } from "queries/useCodelist";
import { useHistory } from "react-router-dom";
import { NameProperties } from "utils/filterItemsByName";
import { plantMappingConfig } from "./plantMappingConfig";

export default function PlantMappingControlHeader({
  section,
  isRefetching,
  refetch,
  nameFilter,
  setNameFilter,
  setRemoteSystemManagerOpen,
  nameProperty,
}: {
  section: string;
  isRefetching: boolean;
  refetch: Function;
  nameFilter: string;
  setNameFilter: React.Dispatch<string>;
  setRemoteSystemManagerOpen: React.Dispatch<boolean>;
  nameProperty: NameProperties;
}) {
  const { data, status } = useCodelist({ codelist: "remote-systems" });
  const { singularTitle } = plantMappingConfig;
  const history = useHistory();
  return (
    <ControlHeaderContainer>
      <FlexContainer style={{ justifyContent: "flex-start", gap: 12 }}>
        <FlexElement style={{ marginLeft: 16 }}>
          <FlexContainer flexStart>
            <FlexElement>
              <Search
                placeholder="Search..."
                value={nameFilter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNameFilter(e.target.value)
                }
              />
            </FlexElement>
            <FlexElement>
              <NameFilterInfo nameProperty={nameProperty} />
            </FlexElement>
          </FlexContainer>
        </FlexElement>
        <ControlHeaderTitle>{singularTitle}</ControlHeaderTitle>
        <FlexContainer style={{ marginLeft: "auto" }}>
          <div
            style={{
              display: "flex",
              gap: 12,
              alignItems: "baseline",
              marginRight: 12,
            }}
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <Typography variant="caption">Remote System:</Typography>
            </div>
            <div>
              {status === "error" ? (
                <Typography color="danger">
                  Can't load Remote Systems.
                </Typography>
              ) : (
                <NativeSelect
                  id="remote-system"
                  label=""
                  onChange={(e) =>
                    history.push(
                      plantMappingConfig.frontendUrl + "/" + e.target.value
                    )
                  }
                  value={section}
                >
                  <option value="">- Select a Remote System -</option>
                  {data &&
                    data.map((remoteSystem) => (
                      <option key={remoteSystem.RemoteSystemName}>
                        {remoteSystem.RemoteSystemName}
                      </option>
                    ))}
                </NativeSelect>
              )}
            </div>
            <div>
              <Button
                variant="outlined"
                onClick={() => setRemoteSystemManagerOpen(true)}
              >
                Manage Remote Systems
              </Button>
            </div>
          </div>
          <HeaderButtonContainer>
            <FlexElement>
              <HeaderProgress isRefetching={isRefetching} />
            </FlexElement>
            <FlexElement>
              <Tooltip title="Reload">
                <Button variant="ghost_icon" onClick={() => refetch()}>
                  <Icon data={refresh} />
                </Button>
              </Tooltip>
            </FlexElement>
          </HeaderButtonContainer>
        </FlexContainer>
      </FlexContainer>
    </ControlHeaderContainer>
  );
}
