import { Typography } from "@equinor/eds-core-react";
import React from "react";
import { default as ReactModal } from "react-modal";
import styled from "styled-components";
import { merge } from "../utils/styleMerge";
import { MultiColumnList, ScrollShadowContainer } from "./Components";
import { breakpoints } from "./breakpoints";

export const modalStyle = {
  content: {
    position: "relative" as "relative",
    inset: "0",
    maxHeight: "90%",
    maxWidth: "calc(95% - 100px)",
    display: "flex",
    flexDirection: "column" as "column",
    flexWrap: "nowrap" as "nowrap",
    overflow: "auto",
    borderRadius: "4px",
    boxShadow:
      "5px 5px 10px var(--overlayBg), 8px 8px 16px var(--overlayBgFaded)",
    wordBreak: "break-word" as "break-word",
    border: "0",
    background: "var(--bg)",
    color: "var(--text)",
  },
  overlay: {
    zIndex: 1400,
    background: "var(--overlayBg)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const largeModalStyle = {
  content: {
    ...modalStyle.content,
    width: "85%",
  },
  overlay: {
    ...modalStyle.overlay,
  },
};

export const Modal = styled(ReactModal)`
  padding: 10px 0 15px;
  @media (min-width: ${breakpoints.xl}) {
    padding: 12px 0 15px;
  }
`;

export const FatModal = styled(ReactModal)`
  padding: 15px 20px 25px;
  @media (min-width: ${breakpoints.xl}) {
    padding: 30px 50px;
  }
`;

export const ModalContainerFixed = styled.div`
  overflow: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0 2px;
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ModalContainerScrollable = styled.div`
  overflow: auto;
  flex: 0 1;
  min-height: 1em;
  padding: 0 2px;
  display: flex;
  flex-direction: column;
`;

export const ModalContainerAlways = styled.div`
  flex: 1 0;
  padding: 0 2px;
`;

export const ModalPadder = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 2px 15px;
  @media (min-width: ${breakpoints.xl}) {
    padding: 2px 25px;
  }
`;

export const ModalPadderStopper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: auto;
  margin: 0 -15px;
  @media (min-width: ${breakpoints.xl}) {
    margin: 0 -25px;
  }
`;

export const ModalButtonContainer = styled.div<{ $inContent?: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.$inContent ? `space-between` : `space-around`};
  margin: 20px 20px 0;
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 25px;
  }
  gap: 12px;
`;

export const ModalContainerScrollableShadow = merge(
  ModalContainerScrollable,
  ScrollShadowContainer
);

export const ModalInsideContainerScrollable = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ModalPadderStopper>
      <ModalContainerScrollableShadow>
        <ModalPadder>{children}</ModalPadder>
      </ModalContainerScrollableShadow>
    </ModalPadderStopper>
  );
};

export const ConfirmListContainer: React.FC = ({ children }) => (
  <ModalInsideContainerScrollable>
    <MultiColumnList columns={6}>{children}</MultiColumnList>
  </ModalInsideContainerScrollable>
);

export const ModalContentContainer = styled.div`
  margin: 10px 15px;
  word-break: break-word;
  @media (min-width: ${breakpoints.xl}) {
    margin: 10px 25px;
  }
`;

export const ModalTitle = styled(Typography).attrs({ variant: "h4" })`
  margin-bottom: 1em;
`;
