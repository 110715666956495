import { Table } from "@equinor/eds-core-react";
import styled from "styled-components";

export const TableHead = styled(Table.Head)``;
export const TableBody = styled(Table.Body)``;

export const TableCell = styled(Table.Cell)`
  line-height: 1.1;
  padding-top: 7px;
  padding-bottom: 2px;
  white-space: nowrap;
  &:first-child {
    border-left: 1px solid var(--borderDefault);
  }
`;

export const TableRow = styled(Table.Row)`
  &.errorRow {
    ${TableCell} {
      background: var(--dangerBackground);
    }
  }
`;

export const ReportTable = styled(Table)`
  border-right: 1px solid var(--borderDefault);
  margin-bottom: 1px;
  ${TableBody} {
    ${TableRow}:last-child {
      ${TableCell} {
        border-bottom: 1px solid var(--borderDefault);
      }
    }
  }
  ${TableRow} {
    ${TableCell}:last-child {
      border-left: 1px solid var(--borderDefault);
    }
  }
`;

export const ContentCell = styled(TableCell)`
  border: none;
  border-top: 1px solid var(--borderDefault);
  border-left: 1px solid var(--borderDefault);
`;

export const ContentCellWrap = styled(ContentCell)`
  white-space: normal;
`;

export const HeadCell = styled(TableCell)<{
  bottomBorder?: boolean;
  rightBorder?: boolean;
}>(
  ({ bottomBorder, rightBorder }) => `
  background: var(--semiFadedBg);
  border: none;
  border-top: 1px solid var(--borderDefault);
  border-left: 1px solid var(--borderDefault);
  ${bottomBorder ? "border-bottom: 3px solid var(--borderDefault);" : ""}
  ${rightBorder ? "border-right: 3px solid var(--borderDefault);" : ""}
`
);

export const HeadTopCell = styled(HeadCell)``;

export const HeadEmptyCell = styled(TableCell)`
  border: none;
  border-left: 1px solid var(--borderDefault);
`;

export const HeadLeftCell = styled(HeadCell)``;

export const HeadLeftCellEmpty = styled(HeadLeftCell)`
  background: var(--semiFadedBg);
  border: none;
  border-left: 1px solid var(--borderDefault);
`;

export const HeadCellLeftSticky = styled(HeadLeftCell)`
  background: var(--semiFadedBg);
  position: sticky;
  left: 0;
  z-index: 2;
`;

export const HeadCellLeftStickyTop = styled(HeadCellLeftSticky)`
  z-index: 3;
`;

export const HeadEmptyCellLeftSticky = styled(HeadCellLeftSticky)`
  border: none;
  border-left: 1px solid var(--borderDefault);
`;

export const TableContentRow = styled(TableRow)`
  &:hover {
    ${TableCell} {
      box-shadow: inset 0 0 100px var(--transparentBg);
    }
  }
`;
