import { Button, Typography } from "@equinor/eds-core-react";
import { Chip, LinearLoader } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
} from "components/ModalWindow";
import {
  ChipContainer,
  CustomFilterComponentProps,
  SelectComponentProps,
} from "components/table/Filters";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import {
  SheetsTypeData,
  useSheetList,
} from "features/sheets/queries/useSheetList";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { CommonObjectContent } from "utils/filterItemsByProps";
import useVDSSubsegmentSelector from "../../hooks/useVDSSubsegmentSelector";
import { getItemID, getItemName } from "../../util";

const GroupHeader = styled(Typography)`
  margin: 1em 1em 0.25em;
`;

const allRevisionSymbol = "✱";

const VDStoVDSSubsegmentPropertyMap = {
  ValveTypeID: "ValveType",
  RatingClassID: "RatingClass",
  MaterialGroupID: "MaterialGroup",
  EndConnectionID: "EndConnection",
};

export function SubsegmentFilter({
  filter,
  filters,
}: CustomFilterComponentProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, status, error } = useSheetList({
    sheetType: "vds-subsegments",
  });

  const {
    data: vdsSubsegmentGroupCodelist,
    status: vdsSubsegmentGroupsStatus,
  } = useCodelist({
    codelist: "vds-subsegment-groups",
  });

  const selectedData = useMemo(
    () =>
      data?.sheets.filter((e) =>
        filter.filterState.includes(getItemID(e.SubsegmentID, e.Revision))
      ),
    [data, filter.filterState]
  );

  const chips = useMemo(() => {
    const hasAllRevisions: number[] = [];
    return selectedData?.reduce((acc, selected) => {
      if (hasAllRevisions.includes(selected.SubsegmentID)) return acc;
      if (
        data &&
        data?.sheets.filter((e) => e.SubsegmentID === selected.SubsegmentID)
          .length > 1 &&
        selectedData.filter((e) => e.SubsegmentID === selected.SubsegmentID)
          .length ===
          data?.sheets.filter((e) => e.SubsegmentID === selected.SubsegmentID)
            .length
      ) {
        hasAllRevisions.push(selected.SubsegmentID);
        return [...acc, { ...selected, Revision: allRevisionSymbol }];
      } else {
        return [...acc, selected];
      }
    }, [] as SheetsTypeData<"vds-subsegments">[]);
  }, [data, selectedData]);

  const selectedGroups = useMemo(
    () =>
      vdsSubsegmentGroupCodelist?.filter((group) =>
        selectedData?.some((e) => e.GroupID === group.GroupID)
      ),
    [selectedData, vdsSubsegmentGroupCodelist]
  );

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(status, vdsSubsegmentGroupsStatus),
    [status, vdsSubsegmentGroupsStatus]
  );

  const matchesFilter = useCallback(
    (subsegment: SheetsTypeData<"vds-subsegments">) =>
      filters
        .filter((filter) =>
          [
            "ValveTypeID",
            "RatingClassID",
            "MaterialGroupID",
            "EndConnectionID",
          ].includes(filter.prop)
        )
        .every((filter) => {
          const subsegmentProp =
            subsegment[
              VDStoVDSSubsegmentPropertyMap[
                filter.prop as keyof typeof VDStoVDSSubsegmentPropertyMap
              ] as keyof typeof subsegment
            ];
          return (
            !filter.filterState ||
            subsegmentProp === "*" ||
            (typeof filter.filterState === "string" &&
              String(subsegmentProp).split(",").includes(filter.filterState))
          );
        }),
    [filters]
  );

  useEffect(() => {
    filter.filterFn = (item: SheetsTypeData<"vds"> | CommonObjectContent) => {
      return !!selectedGroups?.every((group) => {
        const filteredSubsegments = selectedData?.filter(
          (subsegment) =>
            subsegment.GroupID === group.GroupID && matchesFilter(subsegment)
        );
        return (
          !filteredSubsegments ||
          filteredSubsegments.length === 0 ||
          filteredSubsegments.some((subsegment) =>
            item.SubsegmentList.split(", ").includes(
              getItemID(subsegment.SubsegmentID, subsegment.Revision)
            )
          )
        );
      });
    };
  }, [filter, matchesFilter, selectedData, selectedGroups]);

  return (
    <div style={{ minWidth: 216 }}>
      {status === "loading" ? (
        <LinearLoader label="Loading Subsegments..." />
      ) : combinedStatuses === "error" ? (
        <div>Error: {error?.message}</div>
      ) : combinedStatuses === "success" ? (
        <>
          <div style={{ marginBottom: 12 }}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Select Subsegments
            </Button>
          </div>
          {selectedGroups &&
            selectedGroups.map((group) => (
              <div key={group.GroupID}>
                <GroupHeader variant="caption">{group.Description}</GroupHeader>
                <ChipContainer>
                  {chips &&
                    chips
                      .filter(
                        (subsegment) => subsegment.GroupID === group.GroupID
                      )
                      .map((subsegment) => {
                        const subsegmentId = getItemID(
                          subsegment.SubsegmentID,
                          subsegment.Revision
                        );
                        return (
                          <Chip
                            key={subsegmentId}
                            variant={
                              matchesFilter(subsegment) ? "default" : "error"
                            }
                            onDelete={() =>
                              filter.setFilterState(
                                (filter.filterState as string[]).filter((e) => {
                                  if (
                                    subsegment.Revision === allRevisionSymbol
                                  ) {
                                    return (
                                      getItemName(e) !==
                                      String(subsegment.SubsegmentID)
                                    );
                                  }
                                  return e !== subsegmentId;
                                })
                              )
                            }
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                            title={`${
                              matchesFilter(subsegment)
                                ? ""
                                : "(Will be ignored: Characteristic mismatch) "
                            }${subsegment.SubsegmentName}`}
                          >
                            {subsegmentId}
                          </Chip>
                        );
                      })}
                </ChipContainer>
              </div>
            ))}
          <SelectSubsegment
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            filterState={filter.filterState as string[]}
            setFilterState={filter.setFilterState}
            filters={filters}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export function SelectSubsegment({
  isModalOpen,
  setIsModalOpen,
  filterState,
  setFilterState,
  filters,
}: SelectComponentProps) {
  const selectSubsegments = () => {
    setFilterState(
      selectedLines.map((line) => getItemID(line.SubsegmentID, line.Revision))
    );
    setIsModalOpen(false);
  };

  const closeModal = () => {
    selectionDispatch({
      type: "reset",
      payload: filterState,
    });
    setIsModalOpen(false);
  };

  const { vdsSelectorContent, selectedLines, selectionDispatch } =
    useVDSSubsegmentSelector({
      enabled: isModalOpen,
      extendedDisplay: true,
      filters,
      defaultSelection: filterState,
    });

  useEffect(() => {
    if (!isModalOpen) {
    }
    // Reset to state when closed, to reset changed state when closing a changed table.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <ModalWindow
      title="Select Subsegments"
      closeModal={() => closeModal()}
      isOpen={isModalOpen}
      zIndex={10000}
    >
      <ModalSideMargin style={{ minWidth: 960 }}>
        {vdsSelectorContent}
        <ModalWindowButtonContainer>
          <Button
            onClick={() => {
              selectSubsegments();
            }}
          >
            Select subsegments
          </Button>
          <Button variant="outlined" onClick={() => closeModal()}>
            Close
          </Button>
        </ModalWindowButtonContainer>
      </ModalSideMargin>
    </ModalWindow>
  );
}
