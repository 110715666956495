import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { plantDependentSheets, SheetTypes } from "../config/sheetConfig";
import { PlantProps } from "../../plant/types";

export default function useNewSheetRevision() {
  const queryClient = useQueryClient();

  async function newSheetRevision({
    plant,
    sheetType,
    name,
    revision,
  }: {
    plant: PlantProps;
    sheetType: SheetTypes;
    name: string;
    revision: string;
  }) {
    return await axios(
      getSheetApiUrl({ plant, sheetType, name, revision, postfix: "revision" }),
      {
        method: "post",
      }
    );
  }

  return useMutation(newSheetRevision, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType)
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
