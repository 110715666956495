import { Button, Icon } from "@equinor/eds-core-react";
import { edit, file, visibility } from "@equinor/eds-icons";
import { useHistory } from "react-router-dom";
import { PlantProps } from "../plant/types";
import { editSheet, previewInPane } from "./commands";
import {
  SheetTypes,
  getElementNameSingular,
  sheetProperties,
} from "./config/sheetConfig";

export function PreviewInPaneCommandButton({
  isCopyModal,
  dispatch,
  plant,
  sheetType,
  item,
}: {
  isCopyModal: boolean;
  dispatch: Function;
  plant: PlantProps;
  sheetType: SheetTypes;
  item: any;
}) {
  const { previewInEdit } = sheetProperties[sheetType];
  return previewInEdit ? (
    <></>
  ) : (
    <Button
      variant="ghost_icon"
      title="View in pane"
      onClick={() => {
        previewInPane({
          location: isCopyModal ? "copyModal" : "main",
          dispatch,
          plant,
          sheetType,
          item,
        });
      }}
    >
      <Icon data={visibility} />
    </Button>
  );
}

export function EditCommandButton({
  isCopyModal,
  sheetType,
  item,
}: {
  isCopyModal: boolean;
  sheetType: SheetTypes;
  item: any;
}) {
  const elementName = getElementNameSingular(sheetType);
  const history = useHistory();
  const { previewInEdit } = sheetProperties[sheetType];
  return sheetType === "vds-unstructured" ? (
    <></>
  ) : isCopyModal ||
    !(
      item.Status === "W" ||
      (item.Status === "I" && sheetType === "general") ||
      previewInEdit
    ) ? (
    // placeholder
    <Button variant="ghost_icon" style={{ visibility: "hidden" }}>
      <Icon data={edit} />
    </Button>
  ) : (
    <Button
      variant="ghost_icon"
      title={
        item.Status !== "W" && previewInEdit
          ? `View ${elementName}`
          : `Edit ${elementName}`
      }
      onClick={() => editSheet({ item, history, sheetType })}
    >
      {item.Status !== "W" && previewInEdit ? (
        <Icon data={file} />
      ) : (
        <Icon data={edit} />
      )}
    </Button>
  );
}
