import { Button, NativeSelect, TextField } from "@equinor/eds-core-react";
import { Infoline } from "components/ModalComponents";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
  ModalWindowContentFill,
  ModalWindowContentFixed,
  ModalWindowPrimaryContainer,
  ModalWindowSimpleContainer,
} from "components/ModalWindow";
import {
  MasterPipeElements,
  useMaterialGroups,
} from "features/pmg/queries/useMaterialGroups";
import { closeEditSheet } from "features/sheets/commands";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import styled from "styled-components";

import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import { FormRowBlock } from "components/form/Form";
import { editItem } from "components/table/tableUtils";
import { useCodelist } from "queries/useCodelist";
import { successMessage } from "utils/successMessage";
import { useCreatePipeMaterialGroup } from "../queries/useCreatePipeMaterialGroup";
import { useMDSMatrixRevisions } from "../queries/useUpdateMDSMatrixRevisions";
import { MaterialGroupVerification } from "./MaterialGroupVerification";
import { useMasterPipeElements } from "./useMasterPipeElements";
import { useStructuredPipeElements } from "./useStructuredPipeElements";

export function EditPipeMaterialGroup({
  name,
  revision,
  tab,
}: {
  name: string | null;
  revision: string | null;
  tab: string | null;
}) {
  const isOpen = !!name && !!revision && tab === "";
  return isOpen ? (
    <EditPipeMaterialGroupModal name={name} revision={revision} tab={tab} />
  ) : null;
}

export function CreateNewPipeMaterialGroup({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) {
  const { data, status, error } = useCodelist({ codelist: "material-groups" });
  const { data: existingMaterialGroupData } = useMaterialGroups({
    postfix: undefined,
  });

  const options = useMemo(
    () =>
      data
        ?.filter(
          (e) =>
            !existingMaterialGroupData
              ?.map((f) => f.MaterialGroupID)
              .includes(e.MaterialGroupID)
        )
        .sort((a, b) => a.MaterialGroup.localeCompare(b.MaterialGroup)),
    [data, existingMaterialGroupData]
  );

  const [materialGroupId, setMaterialGroupId] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setNote("");
    }
  }, [isOpen]);

  useEffect(() => {
    options &&
      options.length &&
      setMaterialGroupId(String(options[0].MaterialGroupID));
  }, [options]);

  const {
    mutate,
    status: mutateStatus,
    error: mutateError,
    reset: mutateReset,
    data: mutateData,
  } = useCreatePipeMaterialGroup();

  const history = useHistory();

  const createMaterialGroupId = useRef("");

  const onSuccess = useCallback(() => {
    mutateReset();
    closeModal();
    editItem({
      history,
      itemId: createMaterialGroupId.current,
      revision: "0",
    });
  }, [closeModal, history, mutateReset]);

  return (
    <>
      <ModalWindow
        isOpen={isOpen}
        closeModal={closeModal}
        layer="default"
        title="Create a New Pipe Material Group"
        status={status}
        error={error}
      >
        <ModalWindowContentDefault>
          <ModalSideMargin>
            <ModalWindowSimpleContainer>
              <FormRowBlock>
                <NativeSelect
                  id="material-groups"
                  label="Material Group"
                  value={materialGroupId}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setMaterialGroupId(e.target.value);
                  }}
                >
                  {options?.map((e) => (
                    <option key={e.MaterialGroupID} value={e.MaterialGroupID}>
                      {e.MaterialGroup}
                    </option>
                  ))}
                </NativeSelect>
              </FormRowBlock>
              <FormRowBlock>
                <TextField
                  label="Note"
                  id="note"
                  multiline
                  rows={3}
                  value={note}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNote(e.target.value)
                  }
                />
              </FormRowBlock>
            </ModalWindowSimpleContainer>
          </ModalSideMargin>
        </ModalWindowContentDefault>
        <ModalWindowButtonContainer>
          <Button
            onClick={() => {
              createMaterialGroupId.current = materialGroupId;
              mutate({ materialGroupId, note });
            }}
          >
            Create
          </Button>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </ModalWindowButtonContainer>
      </ModalWindow>
      <StatusModal
        status={mutateStatus}
        error={mutateError}
        onSettledClose={mutateReset}
        onSuccess={onSuccess}
        successMessage={successMessage(mutateData)}
      />
    </>
  );
}

const ContentContainerWrap = styled.div`
  position: absolute;
  inset: 12px 20px 0;
`;

const ContentContainerWrapWithBorder = styled(ContentContainerWrap)`
  border-bottom: 1px solid var(--borderDefault);
`;

export const HeaderContainer = styled.div`
  padding: 12px 0 14px;
`;

export const ButtonContainer = styled.div`
  margin: -16px 6px -14px;
`;

export const IconCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 24px;
`;

export function flattenMasterPipeElements(
  nonFlat: MasterPipeElements[] | undefined
) {
  return nonFlat
    ? [
        ...nonFlat.map((e) =>
          e.MasterPipeElements.map((f) => ({
            ...f,
            elementGroupId: e.ElementGroupID,
            elementGroupName: e.ElementGroup,
            manualDimensions: e.ManualDimension,
          }))
        ),
      ].flat()
    : [];
}

export type FlatMasterPipeElements = ReturnType<
  typeof flattenMasterPipeElements
>;

export const InfoContainer = styled.div`
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoTitle = styled.div`
  font-weight: bold;
  margin-right: 12px;
`;

export const InfoContent = styled.div``;

function EditPipeMaterialGroupModal({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const history = useHistory();
  const closeModal = useCallback(() => {
    closeEditSheet({ history });
  }, [history]);

  const { data, status, error } = useMaterialGroups({
    materialGroupId: name,
    postfix: undefined,
  });
  const currentData = data?.find((e) => e.Revision === revision);

  const isEdit = !!(currentData && currentData.Status === "W");

  const [contentRef, contentSize] = useMeasure();

  const {
    masterPipeElementsContent,
    masterPipeElementInfo,
    masterPipeElementSelection,
    masterPipeElementSelectedLine,
  } = useMasterPipeElements({
    name,
    revision,
    isEdit,
  });

  const { structuredPipeElementsContent } = useStructuredPipeElements({
    name,
    revision,
    mpeId: Number(masterPipeElementSelection[0]),
    masterPipeElementSelectedLine,
    isEdit,
  });

  const [verifiactionOpen, setVerificationOpen] = useState(false);

  const { isConfirmed } = useConfirm();

  const {
    mutate: revisionMutate,
    status: revisionStatus,
    error: revisionError,
    data: revisionData,
    reset: revisionReset,
  } = useMDSMatrixRevisions();

  return (
    <>
      <ModalWindow
        title={
          status === "success"
            ? `${isEdit ? "Edit" : "View"} Pipe Material Group ${
                currentData?.MaterialGroup
              } rev. ${revision}`
            : ""
        }
        closeModal={() => {
          closeModal();
        }}
        isOpen={!!name && !!revision && tab === ""}
        layer="primary"
        status={status}
        error={error}
        loadingString="Loading Pipe Material Group..."
        hasInfoline
      >
        <ModalWindowPrimaryContainer>
          <ModalWindowContentFill ref={contentRef}>
            <Space.Fill>
              <Space.Fill>
                <Space.Fill>
                  <ContentContainerWrapWithBorder style={{ top: 0 }}>
                    <Space.Fill>
                      <div
                        style={{
                          marginRight: 16,
                          position: "absolute",
                          inset: 0,
                        }}
                      >
                        {masterPipeElementsContent}
                      </div>
                    </Space.Fill>
                    <Space.RightResizable size={contentSize.width / 5}>
                      <div
                        style={{
                          borderLeft: "1px solid var(--borderDefault)",
                          paddingLeft: 16,
                          height: "calc(100% - 32px)",
                          overflow: "auto",
                        }}
                      >
                        {masterPipeElementInfo}
                      </div>
                    </Space.RightResizable>
                  </ContentContainerWrapWithBorder>
                </Space.Fill>
                <Space.BottomResizable size={contentSize.height / 3}>
                  <ContentContainerWrapWithBorder>
                    {structuredPipeElementsContent}
                  </ContentContainerWrapWithBorder>
                </Space.BottomResizable>
              </Space.Fill>
            </Space.Fill>
          </ModalWindowContentFill>
          <ModalWindowButtonContainer hasInfoline>
            <div style={{ display: "flex", gap: 16 }}>
              <Button
                variant="outlined"
                onClick={async () => {
                  (await isConfirmed(
                    <>
                      <p>
                        MDS and Master Matrix revisions will be updated for
                        Structured Pipe Elements.
                      </p>
                      <p>Do you want to continue?</p>
                    </>
                  )) && revisionMutate({ name, revision });
                }}
                disabled={!isEdit}
              >
                Update MDS & Matrix Revisions
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setVerificationOpen(true);
                }}
              >
                Verify Material Group
              </Button>
            </div>
            <Button
              variant="outlined"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </Button>
          </ModalWindowButtonContainer>
          <ModalWindowContentFixed>
            <Infoline status item={currentData} />
          </ModalWindowContentFixed>
        </ModalWindowPrimaryContainer>
      </ModalWindow>
      <MaterialGroupVerification
        materialGroupId={name}
        revision={revision}
        isOpen={verifiactionOpen}
        closeModal={() => {
          setVerificationOpen(false);
        }}
      />
      <StatusModal
        status={revisionStatus}
        error={revisionError}
        onSettledClose={revisionReset}
        successMessage={successMessage(revisionData)}
      />
    </>
  );
}
