import { appProps } from "./props";
import config from "../config/config";

export const msalConfig = {
  auth: {
    clientId: config.login.clientId,
    authority: config.login.authority,
    redirectUri: appProps.appBaseUrl + appProps.appBasePath,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalConfigSilent = {
  ...msalConfig,
  auth: {
    ...msalConfig.auth,
    redirectUri: appProps.appBaseUrl + appProps.appBasePath,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: config.login.scopes,
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
