import { activityLogColumns } from "./ActivityLogReport";
import { ReportsConfig } from "../Reports";

export type ActivityLogData = {
  User: string;
  AppModule: string;
  AppSubModule: string;
  ActivityDate: string;
  ActivityTime: string;
  MessageType: "S" | string;
  Message: string;
}[];

export const activityLogConfig: ReportsConfig = {
  title: "Activity Log",
  menuTitle: "Activity Log",
  listProperty: "getActivityLog",
  exportFunction: (data) => ({
    columns: activityLogColumns,
    items: data,
  }),
};
