import { useTable } from "components/table/useTable";
import { idWidth } from "features/sheets/config/sheetConfig";
import { codelistProperties, useCodelist } from "queries/useCodelist";

const columns = [
  { key: "RuleID", title: "ID", width: idWidth },
  { key: "RuleName", title: "Name", width: 250 },
  { key: "Description", title: "Description", width: "100%" },
  { key: "DBProcedure", title: "DB Procedure", width: 250 },
];

export function RulesList({
  list,
}: {
  list: "article-rules" | "weight-rules";
}) {
  const query = useCodelist({ codelist: list });
  const { titlePlural } = codelistProperties[list];
  const { content } = useTable({
    query,
    columns,
    title: titlePlural ?? "",
    nameProperty: ["RuleID", "RuleName", "Description", "DBProcedure"],
  });
  return <>{content}</>;
}
