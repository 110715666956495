import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getApiUrl } from "../../../queries/queryUtil";

export default function useDeleteMaterialGroup() {
  const queryClient = useQueryClient();

  async function deleteMaterialGroup({
    name,
    revision,
  }: {
    name: string | number;
    revision: string;
  }) {
    return await axios({
      method: "delete",
      url: getApiUrl("/pmg/material-groups/" + name + "/rev/" + revision),
    });
  }

  return useMutation(deleteMaterialGroup, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["pmg", "material-groups"]);
      queryClient.invalidateQueries(["codelists", "pmg/master-pipe-elements"]);
      queryClient.invalidateQueries([
        "codelists",
        "pmg/structured-pipe-elements",
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
  });
}
