import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";
import { IsConfirmed } from "components/confirm/useConfirm";

export const confirmNavigation = async ({
  isConfirmed,
}: {
  isConfirmed: IsConfirmed;
}): Promise<boolean> => {
  return await isConfirmed(defaultUnsavedMessage);
};
