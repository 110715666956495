import React from "react";
import { ConfirmListContainer } from "components/Modal";
import { SheetsTypeData } from "features/sheets/queries/useSheetList";

export function PCSConfirmQuestion({
  selectedItems,
  message,
}: {
  selectedItems: SheetsTypeData<"pcs">[];
  message: string;
}) {
  return (
    <>
      Are you sure you want to {message} the following PCS
      {selectedItems.length > 1 ? "s" : ""}?
      <ConfirmListContainer>
        {selectedItems.map((i) => (
          <li key={i.PCS + i.Revision}>
            {i.PCS} rev. {i.Revision}
          </li>
        ))}
      </ConfirmListContainer>
    </>
  );
}

export function VDSConfirmQuestion({
  selectedItems,
  message,
}: {
  selectedItems: SheetsTypeData<"vds">[];
  message: string;
}) {
  return (
    <>
      Are you sure you want to {message} the following VDS
      {selectedItems.length > 1 ? "s" : ""}?
      <ConfirmListContainer>
        {selectedItems.map((i) => (
          <li key={i.VDS + i.Revision}>
            {i.VDS} rev. {i.Revision}
          </li>
        ))}
      </ConfirmListContainer>
    </>
  );
}
