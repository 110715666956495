import axios, { AxiosResponse } from "axios";
import { SaveVDSSubsegment } from "features/sheets/types";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { StatusResponseWithInfo } from "components/StatusModal";

export default function useSaveVDSSubsegment({
  disableListInvalidation,
}: { disableListInvalidation?: boolean } = {}) {
  const queryClient = useQueryClient();

  async function saveSheet({
    name,
    revision,
    content,
    group,
    method,
  }: {
    name?: string;
    revision?: string;
    content: SaveVDSSubsegment;
    group?: number;
    method: "put" | "post";
  }): Promise<AxiosResponse<StatusResponseWithInfo>> {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-subsegments",
        name,
        revision,
        postfix: group ? `group/${group}` : "",
      }),
      {
        method,
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(saveSheet, {
    onSuccess: (data, variables, context) => {
      variables.method === "put" &&
        queryClient.invalidateQueries([
          "sheet",
          "vds-subsegments",
          variables.name,
          variables.revision,
        ]);
      !disableListInvalidation &&
        queryClient.invalidateQueries(["sheets", "vds-subsegments"]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
  });
}
