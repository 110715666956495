import { Editor } from "@tinymce/tinymce-react";
import { appProps } from "app/props";
import { Loader } from "components/Components";
import { useEmbeddedPictures } from "features/document-space/queries/useEmbeddedPictures";
import { useFileUrl } from "features/document-space/useFileUrl";
import React, { useMemo } from "react";
import { Editor as TinyMCEEditor } from "tinymce";
import config from "../../../config/config";
import { useUserConfig } from "UserConfigContext";

function prepareHtml(content: string) {
  return (
    content
      // remove conditional comments
      .replaceAll(/<!\[if !([a-zA-Z]*)\]>/g, "")
      .replaceAll("<![endif]>", "")
  );
}

export default function HTMLEditor({
  content,
  editorRef,
  setInitialContent,
}: {
  content: string;
  editorRef: React.MutableRefObject<TinyMCEEditor | null>;
  setInitialContent?: React.Dispatch<string>;
}) {
  const { data, status } = useEmbeddedPictures();
  const { getFileUrl } = useFileUrl();
  const imageList = useMemo(
    () =>
      data?.map((e) => ({
        title: e.Description,
        value: getFileUrl(e, true),
      })) || [],
    [data, getFileUrl]
  );
  const { configParams } = useUserConfig();

  return status !== "loading" ? (
    <>
      <Editor
        apiKey={config.tinyApiKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={prepareHtml(content)}
        init={{
          height: "100%",
          width: "100%",
          plugins:
            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
          menubar: "file edit view insert format tools table help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
          toolbar_sticky: true,
          content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
              .mce-item-table .table-created-default, .mce-item-table .table-created-default th, .mce-item-table .table-created-default td { border-color: #000; }`,
          content_css: [
            appProps.appBaseUrl + appProps.appBasePath + "styles/sheet.css",
            "document",
          ],
          link_context_toolbar: true,
          document_base_url:
            configParams.find((e) => e.ID === 1)?.URL + "/document_space/",
          setup: function (editor) {
            editor.on("init", function () {
              setInitialContent && setInitialContent(editor.getContent());
            });
          },
          convert_urls: false,
          image_advtab: true,
          image_list: imageList,
          paste_data_images: false,
          table_default_attributes: {
            border: "1",
            cellspacing: "0",
            cellpadding: "1",
            class: "table-created-default",
          },
          table_default_styles: {
            "border-collapse": "collapse",
            width: "600px",
            "border-color": "#000",
          },
          object_resizing: false,
          promotion: false,
          table_sizing_mode: "fixed",
        }}
      />
    </>
  ) : (
    <Loader label="Loading Editor..." />
  );
}
