import { useTableSelect } from "../../components/table/useTableSelect";
import Table from "../../components/table/Table";
import useSheets from "../sheets/queries/useSheets";
import {} from "../plant/plantSlice";
import { Input } from "@equinor/eds-core-react";

const cols = [
  {
    type: "with-context" as "with-context",
    key: "NewName",
    title: "New Name",
    Component: TestInput,
  },
  { key: "EDS", title: "EDS", width: "110px" },
  { key: "Revision", title: "Rev.", width: "38px" },
  { key: "RevDate", title: "Rev. Date", width: "100px" },
  { key: "Status", title: "Status", width: "50px" },
  { key: "LastUpdate", title: "Last Update", width: "100px" },
  { key: "LastUpdateBy", title: "Last Update By", width: "100px" },
  { key: "Notepad", title: "Note", width: "100%" },
];

function TestInput({ testInputData }: { testInputData: string }) {
  return <Input placeholder={testInputData} />;
}

function TestRowMenu({ testMenuData }: { testMenuData: string }) {
  return <>M: {testMenuData}</>;
}

export default function TableTestpage() {
  const { data, status } = useSheets({
    sheetType: "eds",
  });

  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: "multi",
  });

  return (
    <>
      <Table
        items={data && data.sheets}
        itemIdProp="itemID"
        controlHeader={<>ControlHeader</>}
        columns={cols}
        contextData={{
          testInputData: "input",
          testMenuData: "menu",
        }}
        status={status}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={false}
        resizable={true}
        RowMenu={TestRowMenu}
      />
    </>
  );
}
