import { useMutation, useQueryClient } from "react-query";
import { getApiUrl } from "queries/queryUtil";
import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";

export type RelocateObject = {
  oid: number;
};

export function useObjectRelocate() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      oid,
      objects,
    }: {
      oid: number;
      objects: RelocateObject[];
    }): Promise<AxiosResponse<StatusResponse>> => {
      return await axios.put(
        getApiUrl(["document-space", oid, "object-displacement"].join("/")),
        JSON.stringify({
          objects: objects.map((e) => ({ OID: e.oid })),
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["document-space"]);
      },
    }
  );
}

export type UseObjectRelocate = ReturnType<typeof useObjectRelocate>;
