import { Button, EdsProvider } from "@equinor/eds-core-react";
import { Infoline } from "components/ModalComponents";
import {
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFillScrollable,
  ModalWindowContentFixed,
  ModalWindowPrimaryContainer,
} from "components/ModalWindow";
import {
  ContentCell,
  HeadCell,
  HeadCellLeftSticky,
  HeadCellLeftStickyTop,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "components/ReportTable";
import { SemiBold, SubtitleContainer, SubtitleItem } from "components/Subtitle";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import { useMatrixPreview } from "queries/useMatrixPreview";
import { useMemo } from "react";

export function MatrixView({
  id,
  revision,
  closeModal,
}: {
  id: string | null;
  revision: string | null;
  closeModal: () => void;
}) {
  const isOpen = !!(id && revision);
  return isOpen ? (
    <MatrixViewModal id={id} revision={revision} closeModal={closeModal} />
  ) : (
    <></>
  );
}

function MatrixViewModal({
  id,
  revision,
  closeModal,
}: {
  id: string;
  revision: string;
  closeModal: () => void;
}) {
  const { data, status, error } = useMatrixPreview({ id, revision });
  const {
    data: listData,
    status: listStatus,
    error: listError,
  } = useCodelist({ codelist: "master-matrices" });
  const metaData = listData?.find(
    (e) => e.TableID === Number(id) && e.Revision === revision
  );
  const combinedStatus = combineQueryStatuses(status, listStatus);
  const errors = useMemo(
    () => ({ item: error, list: listError }),
    [error, listError]
  );
  const headRows = useMemo(
    () => metaData && data?.filter((e, i) => i < metaData.FixedRows),
    [data, metaData]
  );
  const contentRows = useMemo(
    () => metaData && data?.filter((e, i) => i >= metaData.FixedRows),
    [data, metaData]
  );
  const rowHeight = 32;
  return (
    <ModalWindow
      isOpen={true}
      closeModal={closeModal}
      title={
        combinedStatus === "success" &&
        `Matrix ${id} rev. ${revision}: ${metaData?.Name}`
      }
      subtitle={
        combinedStatus === "success" && (
          <SubtitleContainer>
            <SubtitleItem>
              Type: <SemiBold>{metaData?.MatrixType}</SemiBold>
            </SubtitleItem>
            <SubtitleItem>
              Source: <SemiBold>{metaData?.Source}</SemiBold>
            </SubtitleItem>
            <SubtitleItem>
              EDS Reference: <SemiBold>{metaData?.SSTReference}</SemiBold>
            </SubtitleItem>
            {metaData?.Overlength && (
              <SubtitleItem>
                Overlength: <SemiBold>{metaData?.Overlength}</SemiBold>
              </SubtitleItem>
            )}
            <SubtitleItem>
              Allowable Stress: <SemiBold>{metaData?.AllowableStress}</SemiBold>
            </SubtitleItem>
          </SubtitleContainer>
        )
      }
      layer="primary"
      status={combinedStatus}
      error={errors}
      hasInfoline
    >
      <ModalWindowPrimaryContainer>
        <ModalWindowContentFillScrollable>
          <EdsProvider density="compact">
            {metaData && data && (
              <ReportTable>
                <TableHead sticky>
                  {headRows?.map((row, rowIndex) => (
                    <TableRow>
                      {row.map((cell, cellIndex) =>
                        cellIndex < metaData.FixedCols ? (
                          <HeadCellLeftStickyTop
                            bottomBorder={rowIndex + 1 === metaData.FixedRows}
                            rightBorder={cellIndex + 1 === metaData.FixedCols}
                            style={{ top: rowIndex * rowHeight }}
                          >
                            {cell}
                          </HeadCellLeftStickyTop>
                        ) : (
                          <HeadCell
                            bottomBorder={rowIndex + 1 === metaData.FixedRows}
                            style={{ top: rowIndex * rowHeight }}
                          >
                            {cell}
                          </HeadCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {contentRows?.map((row) => (
                    <TableRow>
                      {row.map((cell, cellIndex) =>
                        cellIndex < metaData.FixedCols ? (
                          <HeadCellLeftSticky
                            rightBorder={cellIndex + 1 === metaData.FixedCols}
                            style={{ fontWeight: "bold" }}
                          >
                            {cell}
                          </HeadCellLeftSticky>
                        ) : (
                          <ContentCell>{cell}</ContentCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </ReportTable>
            )}
          </EdsProvider>
        </ModalWindowContentFillScrollable>
        <ModalWindowButtonContainer hasInfoline>
          <Button onClick={closeModal} variant="outlined">
            Close
          </Button>
        </ModalWindowButtonContainer>
        <ModalWindowContentFixed>
          <Infoline status item={metaData} />
        </ModalWindowContentFixed>
      </ModalWindowPrimaryContainer>
    </ModalWindow>
  );
}
