import { useQuery } from "react-query";
import axios from "axios";
import config from "../config/config";
import { PlantProps } from "../features/plant/types";

export interface PlantDetailedProps {
  OperatorID: number;
  OperatorName: string;
  PlantID: number;
  ShortDescription: string;
  Project: string;
  LongDescription: string;
  CommonLibPlantCode: string;
  InitialRevision: "0" | "1";
  EnableEmbeddedNote: "Y" | "N";
  CategoryID: string;
  Category: string;
  DocumentSpaceLink: "Y" | "N";
  EnableCopyPCSFromPlant: "Y" | "N";
  AreaID: string | number; // for compatibility with PlantProps
  Area: string;
  OverLength: "Y" | "N";
  PCSQA: "Y" | "N";
  EDSMJ: "Y" | "N";
  CelsiusBar: "Y" | "N";
  WebInfoText: string;
  BoltTensionText: string;
  Visible: "Y" | "N";
  WindowsRemarkText: string;
  UserProtected: "P" | "" | " ";
}

export default function usePlant({
  plant,
  plantId,
  cacheTime,
}: {
  plant?: PlantProps;
  plantId?: number;
  cacheTime?: number;
}) {
  const id = plant ? plant.PlantID : plantId ? plantId : 0;
  return useQuery<PlantDetailedProps, Error>(
    ["codelists", "plants", id],
    async () => {
      const { data } = await axios.get(`${config.restApiBaseUrl}plants/${id}`);
      return data["getPlant"][0];
    },
    {
      enabled: !!(plantId || plant),
      cacheTime,
    }
  );
}
