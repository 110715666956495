import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { VDSSubsegment, VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "features/sheets/queries/useVDSTextBlocksDistinctText";
import { useContext, useEffect, useMemo } from "react";

export function VDSTextBlockSectionH1({
  textBlockData,
  subsegmentData,
  setIsLoaded,
}: {
  textBlockData?: VDSTextBlock;
  subsegmentData?: VDSSubsegment;
  setIsLoaded?: React.Dispatch<boolean>;
}) {
  const data = useMemo(
    () =>
      textBlockData
        ? textBlockData
        : subsegmentData
        ? subsegmentData.getVDSSubsegmentHeader[0]
        : undefined,
    [subsegmentData, textBlockData]
  );

  const { disabled } = useContext(FieldContext);

  const { data: valveTypes, status: valveTypesStatus } =
    useVDSTextBlocksDistinctText({
      attributeName: "VT",
      sectionId: "H",
      itemId: 1,
      disabled,
    });

  useEffect(() => {
    valveTypesStatus === "success" && setIsLoaded && setIsLoaded(true);
  }, [valveTypesStatus, setIsLoaded]);

  const preProp = subsegmentData ? "getVDSSubsegmentHeader.0." : "";

  return (
    <>
      <Field
        title="Valve Type"
        prop={preProp + "ValveType"}
        value={data?.ValveType ?? ""}
        options={valveTypes}
        type="autocomplete"
        status={valveTypesStatus}
        maxWidth={500}
      />
    </>
  );
}
