import { NativeSelect, Slider, Typography } from "@equinor/eds-core-react";
import { FlexContainer, FlexElement } from "components/Components";
import { useTable } from "components/table/useTable";
import { CodelistSpecification, useCodelist } from "queries/useCodelist";
import { useEffect, useMemo, useState } from "react";

const columns = [
  {
    key: "OID",
    title: "ID",
    width: 50,
  },
  { key: "Description", title: "Description", width: "100%", minWidth: 250 },
  {
    key: "ArticleRuleID",
    title: "Article Rule",
    width: 200,
    codelist: "article-rules",
  },
  {
    key: "WeightRuleID",
    title: "Weight Rule",
    width: 200,
    codelist: "weight-rules",
  },
  { key: "PDMSElementCodeID", title: "PDMS Element Type" },
  { key: "PDMSSymbolKeyID", title: "PDMS ISO Symbol Key" },
];

const downloadColumns = [
  {
    key: "OID",
    title: "ID",
  },
  {
    key: "ParentOID",
    title: "Parent ID",
  },
  {
    key: "Description",
    title: "Description",
  },
  {
    key: "ArticleRuleID",
    title: "Article Rule ID",
  },
  {
    key: "WeightRuleID",
    title: "Weight Rule ID",
  },
  {
    key: "PDMSElementCodeID",
    title: "PDMS Element Type ID",
  },
  {
    key: "PDMSSymbolKeyID",
    title: "PDMS ISO Symbol Key ID",
  },
];

type SubelementWithLevel = CodelistSpecification["subelements"] & {
  level: number;
};

function addChildrenRecursively(
  acc: SubelementWithLevel[],
  parent: CodelistSpecification["subelements"],
  children: {
    [key: number]: CodelistSpecification["subelements"][] | undefined;
  },
  level: number
) {
  acc.push({
    ...parent,
    level,
    Description:
      (
        String.fromCharCode(160) +
        "–" +
        String.fromCharCode(160).repeat(2)
      ).repeat(level) +
      (level > 0 ? "❯ " + String.fromCharCode(160) : "") +
      parent.Description,
  });
  if (
    children &&
    parent.OID in children &&
    children[parent.OID] !== undefined
  ) {
    children[parent.OID]?.forEach((e) =>
      addChildrenRecursively(acc, e, children, level + 1)
    );
  }
  return acc;
}

export function Subelements() {
  const [elementGroup, setElementGroup] = useState("");
  const [showLevels, setShowLevels] = useState(0);

  const { data: elementGroups } = useCodelist({
    codelist: "pipe-element-groups",
  });
  const query = useCodelist({
    codelist: "subelements",
    enabled: !!elementGroup,
    extraParams: { ElementGroupID: elementGroup },
  });
  const { data } = query;

  const items = useMemo(() => {
    const parents = data?.filter((e) => e.ParentOID === -1);
    if (parents) {
      const children = {} as { [key: number]: typeof data };
      data?.forEach((e) => {
        if (e.ParentOID > -1) {
          if (!children[e.ParentOID]) children[e.ParentOID] = [];
          children[e.ParentOID]?.push(e);
        }
      });
      return parents.reduce(
        (acc, cur) => addChildrenRecursively(acc, cur, children, 0),
        [] as SubelementWithLevel[]
      );
    }
  }, [data]);

  const showItems = useMemo(
    () => items?.filter((e) => e.level <= showLevels),
    [items, showLevels]
  );

  const maxLevel = useMemo(
    () => items?.reduce((acc, cur) => Math.max(acc, cur.level), 0) ?? 0,
    [items]
  );
  useEffect(() => setShowLevels(maxLevel), [elementGroup, maxLevel]);

  const { content } = useTable({
    columns,
    query,
    items: showItems,
    title: "Subelements",
    downloadColumns,
    downloadItems: query.data,
    codelistsToQuery: ["article-rules", "weight-rules"],
    sortable: false,
    noItemsMessage: !elementGroup && "Choose an Element Group.",
    controlHeaderBeginning: (
      <>
        <FlexContainer flexStart gap style={{ alignItems: "center" }}>
          <FlexElement>
            <Typography variant="caption">Element Group:</Typography>
          </FlexElement>
          <FlexElement>
            <NativeSelect
              id="ElementGroup"
              label=""
              value={elementGroup}
              onChange={(e) => setElementGroup(e.target.value)}
            >
              <option value="">- Choose an Element Group -</option>
              {elementGroups?.map((e) => (
                <option key={e.ElementGroupID} value={e.ElementGroupID}>
                  {e.ElementGroup}
                </option>
              ))}
            </NativeSelect>
          </FlexElement>
          <FlexElement style={{ marginLeft: 8 }}>
            <Typography variant="caption">Levels:</Typography>
          </FlexElement>
          <FlexElement style={{ margin: "-20px 16px 0 4px" }}>
            <Slider
              value={showLevels}
              onChange={(e) => setShowLevels(Number(e.target.value))}
              min={0}
              max={maxLevel || 1}
              disabled={maxLevel === 0}
              aria-label="levels"
              style={{ width: 150 }}
            />
          </FlexElement>
        </FlexContainer>
      </>
    ),
  });
  return <>{content}</>;
}
