import React, { createContext, useState } from "react";

export type ConfirmOptions = {
  buttonColor?: "primary" | "secondary" | "danger";
};

type ConfirmProps = {
  prompt: React.ReactNode;
  isOpen: boolean;
  proceed: Function | null;
  cancel: Function | null;
  options: ConfirmOptions;
};

type ConfirmContextProps = {
  confirm: ConfirmProps;
  setConfirm: React.Dispatch<ConfirmProps>;
};

export const ConfirmContext = createContext({} as ConfirmContextProps);

const ConfirmContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirm, setConfirm] = useState({
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null,
    options: {},
  } as ConfirmProps);

  return (
    <ConfirmContext.Provider value={{ confirm, setConfirm }}>
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;
