import { Button, Icon, Tooltip } from "@equinor/eds-core-react";
import { work, work_outline } from "@equinor/eds-icons";
import config from "config/config";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Chip } from "components/Components";
import Table, { ColumnsProps } from "components/table/Table";
import { useSettings } from "features/settings/useSettings";
import { useUpdateSetting } from "features/settings/useUpdateSetting";
import useVDSTaskMonitor from "features/sheets/queries/useVDSTaskMonitor";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { selectUIState, setShowVDSTaskMonitor } from "uiSlice";

const vdsTaskMonitorSettingKey = "vdsTaskMonitorOpen";

export function VDSTaskMonitorButton() {
  const { liveMonitor } = config;
  const dispatch = useAppDispatch();
  const { showVDSTaskMonitor } = useAppSelector(selectUIState);
  const [refetchInterval, setRefetchInterval] = useState(
    liveMonitor ? 30000 : undefined
  );
  const { data, isRefetching, isError } = useVDSTaskMonitor({
    refetchInterval,
  });
  const lastId = useRef<string>("");
  const inited = useRef<boolean>(false);
  const hasRunning = useMemo(
    () => data?.map((e) => e.Status).includes("Running"),
    [data]
  );
  const { [vdsTaskMonitorSettingKey]: vdsTaskMonitorOpenStored } =
    useSettings();
  const { updateSetting } = useUpdateSetting();
  const queryClient = useQueryClient();

  useEffect(() => {
    liveMonitor &&
      (hasRunning ? setRefetchInterval(5000) : setRefetchInterval(30000));
  }, [hasRunning, liveMonitor]);

  useEffect(() => {
    const thisId = data?.[0]?.JobName;
    const thisStatus = data?.[0]?.Status;
    if (thisStatus === "Finished" && thisId && thisId !== lastId.current) {
      if (inited.current) {
        queryClient.invalidateQueries(["sheets", "vds"]);
        queryClient.invalidateQueries(["sheets", "vds-subsegments"]);
        queryClient.invalidateQueries(["sheets", "vds-textblocks"]);
      }
      lastId.current = thisId;
      inited.current = true;
    }
  }, [data, queryClient]);

  useEffect(() => {
    vdsTaskMonitorOpenStored === "true" &&
      dispatch(setShowVDSTaskMonitor(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip
      title={`VDS Task Monitor${!liveMonitor ? " (open to refresh)" : ""}`}
    >
      <Button
        variant={showVDSTaskMonitor ? "contained_icon" : "ghost_icon"}
        color={isRefetching ? "secondary" : isError ? "danger" : "primary"}
        onClick={() => {
          dispatch(setShowVDSTaskMonitor(!showVDSTaskMonitor));
          updateSetting({
            key: vdsTaskMonitorSettingKey,
            value: String(!showVDSTaskMonitor),
          });
        }}
      >
        <Icon data={hasRunning ? work : work_outline} />
      </Button>
    </Tooltip>
  );
}

function TaskStatusChip({ children }: { children: React.ReactNode }) {
  return (
    <Chip variant={children === "Running" ? "active" : "default"}>
      {children}
    </Chip>
  );
}

export function VDSTaskMonitor() {
  const { current: columns } = useRef<ColumnsProps[]>([
    { key: "JobName", title: "Job Name", width: 220 },
    { key: "StartDate", title: "Start Date", width: 130 },
    { key: "TaskDescription", title: "Task Description", width: "100%" },
    { key: "RunDuration", title: "Run Duration" },
    { key: "Status", title: "Status", Component: TaskStatusChip },
  ]);
  const { data, status, error } = useVDSTaskMonitor({
    refetchInterval: config.liveMonitor ? 10000 : undefined,
  });
  return (
    <Table
      itemIdProp="JobName"
      columns={columns}
      items={data}
      status={status}
      error={error}
      loadingString="Loading VDS Task Monitor..."
    />
  );
}
