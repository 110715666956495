// mostly code from reactjs.org/docs/error-boundaries.html
import { Button } from "@equinor/eds-core-react";
import {
  AppTitle,
  AppTitleFullContainer,
} from "components/AppDisplayComponents";
import { ButtonContainer, FullPageContainer } from "components/Components";
import { Component, ErrorInfo, ReactNode } from "react";

interface StateType {
  hasError: boolean;
  errorMessage: string;
  stack: string;
  showDetails: boolean;
}

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: "",
    stack: "",
    showDetails: false,
  };

  static getDerivedStateFromError(): StateType {
    return {
      hasError: true,
      errorMessage: "",
      stack: "",
      showDetails: false,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error("ErrorBoundary caught an error", error, info);
    this.setState({ errorMessage: error.message, stack: error.stack });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <FullPageContainer>
          <AppTitleFullContainer>
            <AppTitle />
          </AppTitleFullContainer>
          <h2>Sorry. There's an error.</h2>
          <div style={{ margin: "1em auto", maxWidth: 600 }}>
            {this.state.errorMessage}
          </div>
          {!this.state.showDetails && (
            <div>
              <Button
                variant="ghost"
                onClick={() => this.setState({ showDetails: true })}
              >
                Show Error Details
              </Button>
            </div>
          )}
          {this.state.showDetails && (
            <pre
              style={{
                textAlign: "left",
                display: "inline-block",
                margin: "1em auto",
              }}
            >
              {this.state.stack}
            </pre>
          )}
          <div style={{ textAlign: "center", marginTop: "1em" }}>
            You can try the following:
            <ButtonContainer style={{ justifyContent: "center" }}>
              <Button
                variant="outlined"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
            </ButtonContainer>
          </div>
        </FullPageContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
