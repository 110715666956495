import { useRef } from "react";

// fastest from here: https://stackoverflow.com/a/7837725/1535873
export function areArraysIdentical(a: any[], b: any[]) {
  var i = a.length;
  if (i !== b.length) return false;
  while (i--) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function areObjectsIdentical(x: any, y: any) {
  var objectsAreSame = true;
  for (var propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

export function areArraysOfObjectsIdentical(a: any[], b: any[]) {
  var i = a.length;
  if (i !== b.length) return false;
  while (i--) {
    if (!areObjectsIdentical(a[i], b[i])) return false;
  }
  return true;
}

export const useFirstRender = () => {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
};

export const stringSort = (a: string, b: string) =>
  a < b ? -1 : a > b ? 1 : 0;

export const naturalSort = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

export const portalContainer = document.getElementsByTagName(
  "body"
)[0] as HTMLElement;

export const preloadLink = (linkHref: string) => {
  const preloadLink = document.createElement("link");
  preloadLink.rel = "preload";
  preloadLink.href = linkHref;
  preloadLink.as = "fetch";
  document.head.appendChild(preloadLink);
};

export function arrayMove(originalArr: any[], from: number, to: number) {
  const arr = [...originalArr];
  if (to >= arr.length) {
    var k = to - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}

export const createId = (label: string) =>
  `id-${label.replaceAll(" ", "-").replaceAll(",", "-").replaceAll(".", "-")}`;
