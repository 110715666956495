import { useQuery } from "react-query";
import axios from "axios";
import config from "../config/config";
import { PlantProps } from "../features/plant/types";

export default function usePlants() {
  return useQuery<PlantProps[], Error>(["codelists", "plants"], async () => {
    const { data } = await axios.get(`${config.restApiBaseUrl}plants`);
    return data["getPlant"];
  });
}
