import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useVisibleOnWeb() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      oid,
    }: {
      oid: number;
    }): Promise<AxiosResponse<StatusResponse>> => {
      return await axios.put(
        getApiUrl(["document-space", oid, "visible-on-web"].join("/"))
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["document-space"]);
      },
    }
  );
}

export type UseVisibleOnWeb = ReturnType<typeof useVisibleOnWeb>;
