import { Access } from "Access";
import { DocumentSpace } from "./DocumentSpace";

export function AccessDocumentSpace() {
  return (
    <Access cap="admin" module="admin" role="A" andOperator>
      <DocumentSpace />
    </Access>
  );
}
