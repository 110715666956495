import { Button, EdsProvider, Icon } from "@equinor/eds-core-react";
import { info_circle } from "@equinor/eds-icons";
import { useState } from "react";
import { MessageModal } from "./MessageModal";

export function InfoPopover({
  title,
  content,
}: {
  title: string;
  content: string | JSX.Element;
}) {
  const [displayPopover, setDisplayPopover] = useState(false);
  return (
    <>
      <EdsProvider density="compact">
        <Button
          variant="ghost_icon"
          title={title}
          onClick={() => {
            setDisplayPopover(true);
          }}
          style={{
            margin: "-16px 6px -16px",
            verticalAlign: -3,
          }}
        >
          <Icon data={info_circle} size={16} />
        </Button>
      </EdsProvider>
      <EdsProvider density="comfortable">
        <MessageModal
          isOpen={displayPopover}
          onClose={() => {
            setDisplayPopover(false);
          }}
          title={title}
          style={{ content: { width: "50vw", minWidth: 400, maxWidth: 800 } }}
          shouldCloseOnOverlayClick={true}
        >
          {content}
        </MessageModal>
      </EdsProvider>
    </>
  );
}
