import { Matrix } from "components/form/EditComponents";
import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { VDSSubsegment, VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "features/sheets/queries/useVDSTextBlocksDistinctText";
import { useContext, useEffect, useMemo } from "react";

export const ratingMatrixProps = [
  [
    "MaxDesignPressure1",
    "MaxDesignPressure2",
    "MaxDesignPressure3",
    "MaxDesignPressure4",
    "MaxDesignPressure5",
    "MaxDesignPressure6",
    "MaxDesignPressure7",
    "MaxDesignPressure8",
    "MaxDesignPressure9",
    "MaxDesignPressure10",
    "MaxDesignPressure11",
    "MaxDesignPressure12",
  ],
  [
    "AtTemperature1",
    "AtTemperature2",
    "AtTemperature3",
    "AtTemperature4",
    "AtTemperature5",
    "AtTemperature6",
    "AtTemperature7",
    "AtTemperature8",
    "AtTemperature9",
    "AtTemperature10",
    "AtTemperature11",
    "AtTemperature12",
  ],
] as const;

export function VDSTextBlockSectionH4({
  textBlockData,
  subsegmentData,
  setIsLoaded,
}: {
  textBlockData?: VDSTextBlock;
  subsegmentData?: VDSSubsegment;
  setIsLoaded?: React.Dispatch<boolean>;
}) {
  const data = useMemo(
    () =>
      textBlockData
        ? textBlockData
        : subsegmentData
        ? subsegmentData.getVDSSubsegmentHeader[0]
        : undefined,
    [subsegmentData, textBlockData]
  );

  const { disabled } = useContext(FieldContext);

  const { data: ratingClasses, status: ratingClassesStatus } =
    useVDSTextBlocksDistinctText({
      attributeName: "RC",
      sectionId: "H",
      itemId: 4,
      disabled: disabled,
    });

  useEffect(() => {
    ratingClassesStatus === "success" && setIsLoaded && setIsLoaded(true);
  }, [ratingClassesStatus, setIsLoaded]);

  const preProp = subsegmentData ? "getVDSSubsegmentHeader.0." : "";

  const props = useMemo(
    () => ratingMatrixProps.map((row) => row.map((prop) => preProp + prop)),
    [preProp]
  );

  return (
    <>
      <Field
        title="Rating"
        prop={preProp + "RatingClass"}
        value={data?.RatingClass ?? ""}
        options={ratingClasses}
        type="autocomplete"
        status={ratingClassesStatus}
        maxWidth={240}
      />
      <Matrix
        props={props}
        titles={["Max. Design Pressure (barg)", "At Temperature (°C)"]}
        values={[
          [
            data?.MaxDesignPressure1,
            data?.MaxDesignPressure2,
            data?.MaxDesignPressure3,
            data?.MaxDesignPressure4,
            data?.MaxDesignPressure5,
            data?.MaxDesignPressure6,
            data?.MaxDesignPressure7,
            data?.MaxDesignPressure8,
            data?.MaxDesignPressure9,
            data?.MaxDesignPressure10,
            data?.MaxDesignPressure11,
            data?.MaxDesignPressure12,
          ],
          [
            data?.AtTemperature1,
            data?.AtTemperature2,
            data?.AtTemperature3,
            data?.AtTemperature4,
            data?.AtTemperature5,
            data?.AtTemperature6,
            data?.AtTemperature7,
            data?.AtTemperature8,
            data?.AtTemperature9,
            data?.AtTemperature10,
            data?.AtTemperature11,
            data?.AtTemperature12,
          ],
        ]}
        maxWidth={900}
        min={[Array(12).fill(-1000), Array(12).fill(-500)]}
        max={[Array(12).fill(5000), Array(12).fill(1000)]}
      />
    </>
  );
}
