function escapeRegex(string: string) {
  return string.replace(/[/\-\\+?.()[\]{}]/g, "\\$&");
}

export type NameProperty<T = string> =
  | T
  | { name: T; matchExact?: boolean; matchAnywhere?: boolean };

export type NameProperties<T = string> = NameProperty<T> | NameProperty<T>[];

export function filterItemsByName<T>({
  nameFilter,
  nameProperty,
  items,
  alwaysPresent,
  skip,
}: {
  nameFilter: string;
  nameProperty: NameProperties | undefined;
  items: T[] | undefined;
  alwaysPresent?: { idProp: string; ids: string[] };
  skip?: boolean;
}): T[] {
  if (!items) return [];
  if (skip || !nameProperty) return items;

  const regexpFilterDefault = new RegExp(
    `^${escapeRegex(nameFilter)
      .replaceAll("|", "|^")
      .replaceAll("_", ".")
      .replaceAll("*", ".*")}`,
    "i"
  );
  const regexpFilterAnywhere = new RegExp(
    `${escapeRegex(nameFilter).replaceAll("_", ".").replaceAll("*", ".*")}`,
    "i"
  );
  const regexpFilterExact = new RegExp(
    `^${escapeRegex(nameFilter)
      .replaceAll("_", ".")
      .replaceAll("*", ".*")
      .replaceAll("|", "$|^")}$`,
    "i"
  );

  return items.filter(
    (item: any) =>
      (alwaysPresent &&
        alwaysPresent.ids.includes(String(item[alwaysPresent.idProp]))) ||
      (Array.isArray(nameProperty)
        ? nameProperty.some((n, i) =>
            String(item[typeof n === "string" ? n : n.name]).match(
              typeof n === "string"
                ? n.slice(-2) === "ID"
                  ? regexpFilterExact
                  : regexpFilterDefault
                : n.matchAnywhere
                ? regexpFilterAnywhere
                : n.matchExact
                ? regexpFilterExact
                : regexpFilterDefault
            )
          )
        : String(
            item[
              typeof nameProperty === "string"
                ? nameProperty
                : nameProperty.name
            ]
          ).match(
            typeof nameProperty === "string"
              ? nameFilter.slice(-2) === "ID"
                ? regexpFilterExact
                : regexpFilterDefault
              : nameProperty.matchAnywhere
              ? regexpFilterAnywhere
              : nameProperty.matchExact
              ? regexpFilterExact
              : regexpFilterDefault
          ))
  );
}
