import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { SaveVDSProperties } from "features/sheets/types";

export default function useSaveVDSProperties() {
  const queryClient = useQueryClient();

  async function saveProperties({
    name,
    revision,
    content,
  }: {
    name: string;
    revision: string;
    content: SaveVDSProperties;
  }) {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds",
        name,
        revision,
        postfix: "properties",
      }),
      {
        method: "put",
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(saveProperties, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheet",
        "vds",
        variables.name,
        variables.revision,
      ]);
      queryClient.invalidateQueries(["sheets", "vds"]);
    },
  });
}
