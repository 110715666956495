import { Button } from "@equinor/eds-core-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFillScrollable,
  ModalWindowDefaultContainer,
} from "./ModalWindow";

const MessageContainer = styled.div`
  p:first-child {
    margin-top: 0;
  }
`;

export function MessageModal({
  isOpen,
  title,
  children,
  onClose,
  closeButton,
  style,
  shouldCloseOnOverlayClick,
}: {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  onClose?: Function;
  closeButton?: string | false;
  style?: ReactModal.Styles;
  shouldCloseOnOverlayClick?: boolean;
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(isOpen), [isOpen]);

  const closeModal = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <ModalWindow
      closeModal={closeModal}
      isOpen={open}
      title={title ? title : ""}
      zIndex={3330}
      shouldCloseOnOverlayClick={
        typeof shouldCloseOnOverlayClick === "undefined"
          ? false
          : shouldCloseOnOverlayClick
      }
      style={style}
    >
      <ModalWindowDefaultContainer>
        <ModalWindowContentFillScrollable style={{ maxHeight: "70vh" }}>
          <ModalSideMargin>
            <MessageContainer>{children}</MessageContainer>
          </ModalSideMargin>
        </ModalWindowContentFillScrollable>
        {closeButton !== false && (
          <ModalWindowButtonContainer>
            <Button variant="outlined" onClick={closeModal}>
              {closeButton ? closeButton : "OK"}
            </Button>
          </ModalWindowButtonContainer>
        )}
      </ModalWindowDefaultContainer>
    </ModalWindow>
  );
}
