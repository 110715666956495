import { Config } from "./config";

export const equinorTestConfig: Config = {
  restApiBaseUrl: "https://tr2000api-test.equinor.com/",
  login: {
    clientId: "ad1a3c4e-b7d5-4e3e-98d3-183977cc8d79",
    authority:
      "https://login.microsoftonline.com/3aa4a235-b6e2-48d5-9195-7fcf05b459b0",
    scopes: ["api://217dec3e-dc57-4977-9545-d3826473b421/.default"],
  },
  modules: ["sheets", "admin", "reports"],
  liveMonitor: true,
  tinyApiKey: "t9pkdefzkedcdwbdo02nujib0qqygw1bbwcplp4lbt2177lt",
};
