import { Button, Icon } from "@equinor/eds-core-react";
import { TableExample } from "./TableExample";
import { flame } from "@equinor/eds-icons";

function RowMenu() {
  return (
    <Button variant="ghost_icon">
      <Icon data={flame} />
    </Button>
  );
}

export function CompactTableExample() {
  return (
    <div style={{ padding: 20 }}>
      <TableExample density="compact" RowMenu={RowMenu} />
    </div>
  );
}
