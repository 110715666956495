import { useUserConfig } from "UserConfigContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import StatusModal from "components/StatusModal";
import { reloadPreviewPane } from "features/sheets/SheetPane";
import { closeEditSheet } from "features/sheets/commands";
import stringify from "json-stringify-deterministic";
import useDeleteSheets from "features/sheets/queries/useDeleteSheets";
import useSaveVDS, {
  SaveVDSMutationProps,
  VDSSubsegmentItem,
} from "features/sheets/queries/useSaveVDS";
import useSheet from "features/sheets/queries/useSheet";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { selectUIState, setPaneSheet } from "uiSlice";
import { VDSCharacteristicsModal } from "./VDSCharacteristicsModal";
import { VDSEditModal } from "./VDSEditModal";
import useConfirm from "components/confirm/useConfirm";
import { QueryStatus } from "react-query";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";

export function EditVDS({
  name,
  revision,
  tab,
  edit,
  createNew,
  showCreateNew,
  setShowCreateNew,
  setVDSCreateStatusCopy,
}: {
  name: string;
  revision: string;
  tab?: string;
  edit?: boolean;
  createNew?: boolean;
  showCreateNew?: boolean;
  setShowCreateNew?: React.Dispatch<boolean>;
  setVDSCreateStatusCopy?: React.Dispatch<QueryStatus>;
}) {
  const isOpen = ((edit && name && revision) || showCreateNew) && !tab;

  return isOpen ? (
    <EditVDSModal
      name={name}
      revision={revision}
      createNew={createNew}
      showCreateNew={showCreateNew}
      setShowCreateNew={setShowCreateNew}
      setVDSCreateStatusCopy={setVDSCreateStatusCopy}
    />
  ) : null;
}

function EditVDSModal({
  name,
  revision,
  createNew,
  showCreateNew,
  setShowCreateNew,
  setVDSCreateStatusCopy,
}: {
  name: string;
  revision: string;
  createNew?: boolean;
  showCreateNew?: boolean;
  setShowCreateNew?: React.Dispatch<boolean>;
  setVDSCreateStatusCopy?: React.Dispatch<QueryStatus>;
}) {
  const history = useHistory();

  const [showCharacteristics, setShowCharacteristics] = useState(createNew);
  const [showEdit, setShowEdit] = useState(!createNew);

  const { data, error } = useSheet({
    name,
    revision,
    sheetType: "vds",
    disabled: createNew,
  });

  const [note, setNote] = useState(data?.getVDSContent[0].Notepad ?? "");
  const [subsegments, setSubsegments] = useState([] as VDSSubsegmentItem[]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const { mutate: deleteSheets } = useDeleteSheets();

  const { userInfo } = useUserConfig();

  const clearPreviewVDS = useCallback(
    () =>
      deleteSheets({
        sheets: [{ VDS: userInfo.UserID, Revision: "0" }],
        sheetType: "vds",
        noListInvalidation: true,
      }),
    [deleteSheets, userInfo.UserID]
  );

  const closeModal = async (force?: boolean) => {
    if (force || (await checkIfDirty())) {
      clearPreviewVDS();
      closeEditSheet({ history });
      setShowCreateNew && setShowCreateNew(false);
    }
  };

  useEffect(() => {
    createNew && showCreateNew && !showEdit && setShowCharacteristics(true);
  }, [createNew, showCreateNew, showEdit]);

  const [isCreateNewEdit, setIsCreateNewEdit] = useState(false);
  const [newNameSeriesSetting, setNewNameSeriesSetting] = useState(1);

  useEffect(() => {
    !showEdit && !showCharacteristics && setIsCreateNewEdit(false);
  }, [showEdit, showCharacteristics]);

  const [valveTypeSetting, setValveTypeSetting] = useState(
    data?.getVDSContent[0].ValveTypeID ?? 0
  );
  const [ratingClassSetting, setRatingClassSetting] = useState(
    data?.getVDSContent[0].RatingClassID ?? 0
  );
  const [materialGroupSetting, setMaterialGroupSetting] = useState(
    data?.getVDSContent[0].MaterialTypeID ?? 0
  );
  const [endConnectionSetting, setEndConnectionSetting] = useState(
    data?.getVDSContent[0].EndConnectionID ?? 0
  );
  const [boreSetting, setBoreSetting] = useState(
    data?.getVDSContent[0].BoreID ?? 0
  );
  const [sizeSetting, setSizeSetting] = useState(
    data?.getVDSContent[0].VDSSizeID ?? 0
  );
  const [housingDesignSetting, setHousingDesignSetting] = useState(
    data?.getVDSContent[0].HousingDesignID ?? 0
  );
  const [specialRequirementSetting, setSpecialRequirementSetting] = useState(
    data?.getVDSContent[0].SpecialReqID ?? 0
  );

  const { isConfirmed } = useConfirm();

  const isDirty = useCallback(() => {
    if (data && isDataLoaded) {
      const content = data.getVDSContent[0];
      const getSubsegments = data.getVDSSubsegment.map((e) => ({
        SubsegmentID: e.SubsegmentID,
        Revision: e.Revision,
        GroupID: e.GroupID,
      }));

      return (
        showCreateNew ||
        content.ValveTypeID !== valveTypeSetting ||
        content.RatingClassID !== ratingClassSetting ||
        content.MaterialTypeID !== materialGroupSetting ||
        content.EndConnectionID !== endConnectionSetting ||
        content.BoreID !== boreSetting ||
        content.VDSSizeID !== sizeSetting ||
        content.HousingDesignID !== housingDesignSetting ||
        content.SpecialReqID !== specialRequirementSetting ||
        content.Notepad !== note ||
        stringify(getSubsegments) !== stringify(subsegments)
      );
    }
    return false;
  }, [
    boreSetting,
    showCreateNew,
    data,
    endConnectionSetting,
    housingDesignSetting,
    isDataLoaded,
    materialGroupSetting,
    note,
    ratingClassSetting,
    sizeSetting,
    specialRequirementSetting,
    subsegments,
    valveTypeSetting,
  ]);

  const checkIfDirty = async () =>
    isDirty() ? await isConfirmed(defaultUnsavedMessage) : true;

  useEffect(() => {
    if (data) {
      const getSubsegments = data.getVDSSubsegment.map((e) => ({
        SubsegmentID: e.SubsegmentID,
        Revision: e.Revision,
        GroupID: e.GroupID,
      }));
      stringify(getSubsegments) !== stringify(subsegments) &&
        setSubsegments(getSubsegments);
      const content = data.getVDSContent[0];
      content.ValveTypeID !== valveTypeSetting &&
        setValveTypeSetting(content.ValveTypeID);
      content.RatingClassID !== ratingClassSetting &&
        setRatingClassSetting(content.RatingClassID);
      content.MaterialTypeID !== materialGroupSetting &&
        setMaterialGroupSetting(content.MaterialTypeID);
      content.EndConnectionID !== endConnectionSetting &&
        setEndConnectionSetting(content.EndConnectionID);
      content.BoreID !== boreSetting && setBoreSetting(content.BoreID);
      content.VDSSizeID !== sizeSetting && setSizeSetting(content.VDSSizeID);
      content.HousingDesignID !== housingDesignSetting &&
        setHousingDesignSetting(content.HousingDesignID);
      content.SpecialReqID !== specialRequirementSetting &&
        setSpecialRequirementSetting(content.SpecialReqID);
      content.Notepad !== note && setNote(content.Notepad);

      setIsDataLoaded(true);
    }
    // Only update on data change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    mutate: saveVDSSilent,
    status: saveVDSSilentStatus,
    data: saveVDSSilentData,
    error: saveVDSSilentError,
    reset: saveVDSSilentReset,
  } = useSaveVDS();

  const vdsSaveData = useMemo<SaveVDSMutationProps>(
    () => ({
      name,
      revision,
      content: {
        ValveTypeID: valveTypeSetting,
        RatingClassID: ratingClassSetting,
        MaterialTypeID: materialGroupSetting,
        EndConnectionID: endConnectionSetting,
        BoreID: boreSetting,
        VDSSizeID: sizeSetting,
        HousingDesignID: housingDesignSetting,
        SpecialReqID: specialRequirementSetting,
        Notepad: note,
        PreviewOnly: "Y",
        MakeNewName: "N",
        SerieID: newNameSeriesSetting,
        VDSSubsegment: subsegments,
      },
    }),
    [
      boreSetting,
      endConnectionSetting,
      housingDesignSetting,
      materialGroupSetting,
      name,
      newNameSeriesSetting,
      note,
      ratingClassSetting,
      revision,
      sizeSetting,
      specialRequirementSetting,
      subsegments,
      valveTypeSetting,
    ]
  );

  const getVDSSaveData = useCallback(
    ({
      PreviewOnly,
      MakeNewName,
      invalidateList,
      noInvalidateSheet,
    }: {
      PreviewOnly: "N" | "Y";
      MakeNewName: "N" | "Y";
      invalidateList?: boolean;
      noInvalidateSheet?: boolean;
    }) => ({
      ...vdsSaveData,
      invalidateList,
      noInvalidateSheet,
      content: { ...vdsSaveData.content, PreviewOnly, MakeNewName },
    }),
    [vdsSaveData]
  );

  const subsegmentsDiffer = useMemo(
    () =>
      !!(
        data &&
        isDataLoaded &&
        stringify(
          data.getVDSSubsegment.map((e) => ({
            SubsegmentID: e.SubsegmentID,
            Revision: e.Revision,
            GroupID: e.GroupID,
          }))
        ) !== stringify(subsegments)
      ),
    [data, subsegments, isDataLoaded]
  );

  const [subsegmentsTouched, setSubsegmentsTouched] = useState(false);

  useEffect(() => {
    subsegmentsDiffer && !subsegmentsTouched && setSubsegmentsTouched(true);
    // Check only when subsegments change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsegmentsDiffer]);

  useEffect(() => {
    if (isDataLoaded) {
      saveVDSSilent(
        getVDSSaveData({
          PreviewOnly: "Y",
          MakeNewName: "N",
          noInvalidateSheet: !subsegmentsTouched,
          invalidateList: false,
        })
      );
    }
    // Save on subsegment change and initial load, but with noInvalidateSheet in the latter case.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsegments]);

  const dispatch = useAppDispatch();
  const { paneSheets } = useAppSelector(selectUIState);

  useEffect(() => {
    if (saveVDSSilentStatus === "success" && subsegmentsTouched) {
      if (saveVDSSilentData && saveVDSSilentData.data.AdditionalInfo) {
        if (
          paneSheets.vdsEdit.name !== saveVDSSilentData.data.AdditionalInfo ||
          paneSheets.vdsEdit.revision !== "0"
        ) {
          dispatch(
            setPaneSheet({
              location: "vdsEdit",
              sheet: {
                name: saveVDSSilentData.data.AdditionalInfo,
                revision: "0",
                sheetType: "vds",
              },
            })
          );
        } else {
          reloadPreviewPane("vdsEdit");
        }
      } else {
        reloadPreviewPane("vdsEdit");
      }
    }
  }, [
    dispatch,
    paneSheets.vdsEdit.name,
    paneSheets.vdsEdit.revision,
    revision,
    saveVDSSilentData,
    saveVDSSilentStatus,
    subsegmentsTouched,
  ]);

  useEffect(() => {
    if (isDirty()) {
      setIsTouched(true);
    }
    // Check only when form data changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vdsSaveData]);

  return (
    <>
      {showEdit && (
        <VDSEditModal
          showCreateNew={showCreateNew}
          name={name}
          revision={revision}
          isOpen={showEdit}
          showEdit={showEdit}
          setShowCharacteristics={setShowCharacteristics}
          closeModal={closeModal}
          setIsCreateNewEdit={setIsCreateNewEdit}
          newNameSeriesSetting={newNameSeriesSetting}
          setNewNameSeriesSetting={setNewNameSeriesSetting}
          subsegments={subsegments}
          setSubsegments={setSubsegments}
          specialRequirementSetting={specialRequirementSetting}
          setSpecialRequirementSetting={setSpecialRequirementSetting}
          note={note}
          setNote={setNote}
          isTouched={isTouched}
          getVDSSaveData={getVDSSaveData}
          isDataLoaded={isDataLoaded}
          saveVDSSilentStatus={saveVDSSilentStatus}
          subsegmentsTouched={subsegmentsTouched}
          setVDSCreateStatusCopy={setVDSCreateStatusCopy}
        />
      )}
      {showCharacteristics && (
        <VDSCharacteristicsModal
          isCreateNewEdit={isCreateNewEdit}
          closeModal={() => {
            showEdit ? setShowCharacteristics(false) : closeModal();
          }}
          isOpen={showCharacteristics}
          name={name}
          revision={revision}
          setShowEdit={setShowEdit}
          setShowCharacteristics={setShowCharacteristics}
          newNameSeriesSetting={newNameSeriesSetting}
          valveTypeSetting={valveTypeSetting}
          setValveTypeSetting={setValveTypeSetting}
          ratingClassSetting={ratingClassSetting}
          setRatingClassSetting={setRatingClassSetting}
          materialGroupSetting={materialGroupSetting}
          setMaterialGroupSetting={setMaterialGroupSetting}
          endConnectionSetting={endConnectionSetting}
          setEndConnectionSetting={setEndConnectionSetting}
          boreSetting={boreSetting}
          setBoreSetting={setBoreSetting}
          sizeSetting={sizeSetting}
          setSizeSetting={setSizeSetting}
          housingDesignSetting={housingDesignSetting}
          setHousingDesignSetting={setHousingDesignSetting}
          error={error}
          subsegments={subsegments}
          specialRequirementSetting={specialRequirementSetting}
          note={note}
        />
      )}
      <StatusModal
        status={saveVDSSilentStatus}
        error={saveVDSSilentError}
        onErrorClose={saveVDSSilentReset}
        onlyError
      />
    </>
  );
}
