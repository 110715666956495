import { Button, Icon } from "@equinor/eds-core-react";
import { chevron_down, chevron_right } from "@equinor/eds-icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseQueryResult } from "react-query";
import styled from "styled-components";
import Table, { ColumnsProps, TableCellContent, TableProps } from "./Table";
import { useTableSelect } from "./useTableSelect";

type ItemInHierarchy = {
  level: number;
  hasChildren: boolean;
  id: string;
  parentId: string;
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TreeSpacer = styled.div`
  flex-shrink: 0;
`;

const IconSpacer = styled(TreeSpacer)`
  width: 33px;
`;

function TreeTitleColumn({
  item,
  column,
  openItems,
  setOpenItems,
  itemIdProp,
  iconFunc,
}: { item: any } & { column: ColumnsProps } & ItemInHierarchy & {
    openItems: string[];
    setOpenItems: React.Dispatch<string[]>;
    itemIdProp: string;
    iconFunc?: (item: any) => JSX.Element;
  }) {
  const { key } = column;
  const { level, hasChildren } = item;
  return (
    <TitleContainer>
      <TreeSpacer style={{ width: level * 20 }}></TreeSpacer>
      <TreeSpacer style={{ width: 38 }}>
        {hasChildren ? (
          <Button
            variant="ghost_icon"
            onClick={() =>
              openItems.includes(String(item[itemIdProp]))
                ? setOpenItems(
                    openItems.filter((e) => e !== String(item[itemIdProp]))
                  )
                : setOpenItems([String(item[itemIdProp]), ...openItems])
            }
          >
            <Icon
              data={
                openItems.includes(String(item[itemIdProp]))
                  ? chevron_down
                  : chevron_right
              }
              size={16}
            />
          </Button>
        ) : (
          <></>
        )}
      </TreeSpacer>
      {iconFunc && <IconSpacer>{iconFunc(item)}</IconSpacer>}
      <TableCellContent title={item[key]}>{item[key]}</TableCellContent>
    </TitleContainer>
  );
}

export function useTree<T>({
  query,
  parentIdProp,
  itemIdProp,
  items,
  titleColumn,
  iconFunc,
  defaultOpenItems,
  ...rest
}: {
  query: UseQueryResult<T[], Error>;
  parentIdProp: keyof T;
  itemIdProp: keyof T;
  items?: T[];
  titleColumn: ColumnsProps;
  iconFunc?: (item: T) => JSX.Element;
  defaultOpenItems?: string[];
} & Omit<Omit<TableProps, "items">, "itemIdProp">) {
  const originalParent = 0;
  const { data, refetch, status, error, isRefetching } = query;

  const { selection, selectionDispatch } = useTableSelect({
    selectionMode: "single",
  });

  const selectedLine = data?.find(
    (e) => String(e[itemIdProp]) === selection[0]
  );

  const itemsGiven: T[] = useMemo(() => items ?? data ?? [], [items, data]);

  const getParents = useCallback(
    (item: T): T[] => {
      const parent = itemsGiven.find(
        (x) => x[itemIdProp] === item[parentIdProp]
      );
      if (parent) {
        return [...getParents(parent), parent];
      }
      return [];
    },
    [itemIdProp, itemsGiven, parentIdProp]
  );

  const selectedItem = itemsGiven?.find(
    (e) => String(e[itemIdProp]) === selection[0]
  );

  const selectionParents = selectedItem ? getParents(selectedItem) : [];

  const getLevel = useCallback(
    (item: T): number => {
      const parent = itemsGiven.find(
        (x) => x[itemIdProp] === item[parentIdProp]
      );
      if (parent) {
        return getLevel(parent) + 1;
      }
      return 0;
    },
    [itemIdProp, itemsGiven, parentIdProp]
  );

  const itemsToUse: (ItemInHierarchy & T)[] = useMemo(
    () =>
      itemsGiven
        .reduce((a, c) => {
          const parent = a.find((x) => x[itemIdProp] === c[parentIdProp]) as T;
          const index = a.lastIndexOf(parent);
          const nextIndex =
            index !== -1
              ? index +
                a.filter((e) => e[parentIdProp] === c[parentIdProp]).length +
                1
              : a.length;
          a.splice(nextIndex, 0, c);
          return a;
        }, [] as T[])
        .map((item) => ({
          id: String(item[itemIdProp]),
          parentId: String(item[parentIdProp]),
          level: getLevel(item),
          hasChildren: itemsGiven.some(
            (e) => e[parentIdProp] === item[itemIdProp]
          ),
          ...item,
        })),
    [itemsGiven, itemIdProp, parentIdProp, getLevel]
  );

  const [openItems, setOpenItems] = useState([
    String(originalParent),
    ...(defaultOpenItems ?? []),
  ] as string[]);

  useEffect(() => {
    defaultOpenItems && setOpenItems(defaultOpenItems);
  }, [defaultOpenItems]);

  const expandAll = useCallback(() => {
    setOpenItems(
      Array.from(
        new Set(
          itemsToUse
            // Filter out occasional items with non-existing parents
            .filter(
              (e) =>
                itemsToUse.map((e) => e.id).includes(e.parentId) ||
                e.parentId === String(originalParent)
            )
            .map((e) => String(e[parentIdProp]))
        )
      )
    );
  }, [parentIdProp, itemsToUse]);

  const collapseAll = useCallback(() => {
    setOpenItems([String(originalParent)]);
  }, []);

  const rowClick = useCallback(
    (clickedItem: T) => {
      setOpenItems([String(clickedItem[itemIdProp]), ...openItems]);
    },
    [itemIdProp, openItems]
  );

  const getItemParentIds = useCallback(
    (item: ItemInHierarchy): string[] => {
      const parent = itemsToUse.find((x) => x.id === item.parentId);
      if (parent) {
        return [...getItemParentIds(parent), parent.id];
      }
      return [];
    },
    [itemsToUse]
  );

  const itemsToDisplay = useMemo(
    () =>
      itemsToUse.filter(
        (item) =>
          (openItems.includes(String(item[parentIdProp])) &&
            getItemParentIds(item).every((e) => openItems.includes(e))) ||
          item[parentIdProp] === String(originalParent)
      ),
    [getItemParentIds, itemsToUse, openItems, parentIdProp]
  );

  const content = (
    <Table
      items={itemsToDisplay}
      itemIdProp={String(itemIdProp)}
      status={status}
      error={error}
      refetch={refetch}
      rowClick={rowClick}
      selection={selection}
      selectionDispatch={selectionDispatch}
      selectionMode="single"
      {...rest}
      columns={[
        { ...titleColumn, Component: TreeTitleColumn, type: "with-context" },
        ...rest.columns,
      ]}
      contextData={{
        openItems,
        itemIdProp,
        setOpenItems,
        iconFunc,
        ...rest.contextData,
      }}
      isRefetching={isRefetching}
    />
  );
  return {
    content,
    expandAll,
    collapseAll,
    selection,
    selectedLine,
    selectionParents,
    getParents,
    openItems,
  };
}

type TreeType<T> = typeof useTree<T>;
export type TreeProps<T> = Parameters<TreeType<T>>[0];
