import {
  Button,
  EdsProvider,
  Icon,
  IconProps,
  Menu,
  Switch,
} from "@equinor/eds-core-react";
import {
  check_circle_outlined,
  chevron_down,
  close,
  warning_outlined,
} from "@equinor/eds-icons";
import {
  Chip,
  DataTable,
  FlexContainer,
  FlexElement,
  FooterContainer,
  InfolineContainer,
  naIfNa,
} from "components/Components";
import { ContextMenu } from "components/ContextMenu";
import { ReportDisplay, ReportURIs } from "features/reports/Reports";
import { combineQueryStatuses } from "queries/queryUtil";
import useSheet, {
  ValLogDetail,
  ValLogStep,
} from "features/sheets/queries/useSheet";
import { useState } from "react";
import { QueryStatus } from "react-query";
import { Link } from "react-scroll";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { PaneLocation } from "uiSlice";
import { useMemoOne as useMemo } from "use-memo-one";
import Table, {
  ItemsClassNames,
  TableGroupCellContentNoClamp,
} from "../../components/table/Table";
import { PlantProps } from "../plant/types";
import { mergeRefs } from "react-merge-refs";
import { useClientDimensions } from "components/useClientDimensions";

type ValLogStepPrep = ValLogStep & {
  id: number;
};

export function StatusIcon({
  status,
  size,
  style,
}: {
  status: string;
  size?: IconProps["size"];
  style?: React.CSSProperties;
}) {
  return status === "0" ? (
    <Icon
      data={warning_outlined}
      color="var(--dangerColor)"
      size={size ? size : 18}
      style={style}
    />
  ) : status === "1" ? (
    <Icon
      data={check_circle_outlined}
      color="var(--okColor)"
      size={size ? size : 18}
      style={style}
    />
  ) : status === "" ? (
    <i>?</i>
  ) : (
    <>{status}</>
  );
}

const StepsContainer = styled.div`
  margin: 0 0 12px 14px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
`;

const StepsStepNo = styled.div``;

const StepButton = styled(Button)`
  height: auto;
  padding: 6px 6px 0 6px;
  span {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;

type PanelType = {
  title: string;
  type: string;
  report?: ReportURIs;
};

type PanelOrNot = PanelType | false;

type Panels = PanelType[];

function PanelMenu({
  panels,
  setShowPanel,
}: {
  panels: Panels;
  setShowPanel: React.Dispatch<PanelOrNot>;
}) {
  return (
    <ContextMenu buttonContent={<Icon data={chevron_down} />}>
      {panels.map((panel) => (
        <Menu.Item onClick={() => setShowPanel(panel)}>{panel.title}</Menu.Item>
      ))}
    </ContextMenu>
  );
}

function ValidationLogSteps({
  steps,
  stepsStatus,
  paneLocation,
  warningsFilter,
  setWarningsFilter,
  setItemsClassNames,
  panels,
  setShowPanel,
}: {
  steps: ValLogStepPrep[];
  stepsStatus: QueryStatus;
  paneLocation: PaneLocation;
  warningsFilter: boolean;
  setWarningsFilter: React.Dispatch<boolean>;
  setItemsClassNames: React.Dispatch<ItemsClassNames[]>;
  panels: Panels;
  setShowPanel: React.Dispatch<PanelOrNot>;
}) {
  return stepsStatus === "loading" ? (
    <></>
  ) : stepsStatus === "error" ? (
    <></>
  ) : stepsStatus === "success" && steps.length > 0 ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: 34,
          flexWrap: "wrap",
        }}
      >
        <StepsContainer>
          {steps.map((step) => (
            <Link
              to={`_group_${step.StepNo}`}
              containerId={`validationLog${paneLocation}`}
              smooth={true}
              duration={250}
              offset={-32}
              key={step.StepNo}
              onClick={() => {
                setItemsClassNames([
                  {
                    className: "_modifiedData",
                    itemID: `_group_${step.StepNo}`,
                    element: "_line",
                  },
                ]);
                setTimeout(() => setItemsClassNames([]), 2000);
              }}
            >
              <StepButton variant="outlined" title={step.Description}>
                <StepsStepNo>{step.id}</StepsStepNo>
                <div>
                  <StatusIcon status={step.Status} />
                </div>
              </StepButton>
            </Link>
          ))}
        </StepsContainer>
      </div>
      <EdsProvider density="comfortable">
        <FlexContainer style={{ margin: "0 10px" }}>
          <FlexElement>
            <Switch
              label="Warnings only"
              checked={warningsFilter}
              onChange={() => setWarningsFilter(!warningsFilter)}
            />
          </FlexElement>
          <FlexElement>
            <PanelMenu panels={panels} setShowPanel={setShowPanel} />
          </FlexElement>
        </FlexContainer>
      </EdsProvider>
    </div>
  ) : (
    <></>
  );
}

function ValidationStatusComponent({ item }: { item: ValLogDetail }) {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", wordBreak: "normal" }}
    >
      {item.Status === "W" ? (
        <Chip variant="error">Warning</Chip>
      ) : item.Status === "S" ? (
        <Chip variant="active">Valid</Chip>
      ) : item.Status === "I" ? (
        <SubHeader></SubHeader>
      ) : (
        <>{item.Status}</>
      )}
    </div>
  );
}

const ValidationLine = styled(TableGroupCellContentNoClamp)`
  padding-left: 32px;
`;

const SubHeader = styled(TableGroupCellContentNoClamp)``;

function DescriptionOrSubHeader({ item }: { item: ValLogDetail }) {
  return item.Status === "I" ? (
    <SubHeader>{item.Description}</SubHeader>
  ) : (
    <ValidationLine>{item.Description}</ValidationLine>
  );
}

const ValidationStepGroupHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: bold;
`;

export function ValidationLog({
  name,
  revision,
  plant,
  paneLocation,
}: {
  name: string;
  revision: string;
  plant: PlantProps;
  paneLocation: PaneLocation;
}) {
  return name && revision && plant && plant.PlantID > 0 ? (
    <ValidationLogDisplay
      name={name}
      revision={revision}
      plant={plant}
      paneLocation={paneLocation}
    />
  ) : (
    <></>
  );
}

function Panel({
  showPanel,
  setShowPanel,
  name,
  revision,
}: {
  showPanel: PanelOrNot;
  setShowPanel: React.Dispatch<PanelOrNot>;
  name: string;
  revision: string;
}) {
  const { clientWidth, refCallback } = useClientDimensions();
  const closeButton = (
    <Button variant="ghost_icon" onClick={() => setShowPanel(false)}>
      <Icon data={close} />
    </Button>
  );

  return showPanel ? (
    <div
      style={{
        borderTop: "1px solid var(--borderDefault)",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: "1px 0 0 0",
          overflow: "auto",
          overflowY: "scroll",
        }}
        ref={mergeRefs([refCallback])}
      >
        {showPanel.type === "report" && showPanel.report && (
          <ReportDisplay
            report={showPanel.report}
            displayIn={true}
            headerRight={closeButton}
            headerWidth={clientWidth}
            name={name}
            revision={revision}
          />
        )}
      </div>
    </div>
  ) : null;
}

function ValidationLogDisplay({
  name,
  revision,
  plant,
  paneLocation,
}: {
  name: string;
  revision: string;
  plant: PlantProps;
  paneLocation: PaneLocation;
}) {
  const { data: stepsData, status: stepsStatus } = useSheet({
    plant,
    sheetType: "pcs",
    name,
    revision,
    postfix: "validation-logs/steps",
    prefix: "sheet-validation",
  });
  const { data: detailsData, status: detailsStatus } = useSheet({
    plant,
    sheetType: "pcs",
    name,
    revision,
    postfix: "validation-logs/details",
    prefix: "sheet-validation",
  });

  const [warningsFilter, setWarningsFilter] = useState(false);
  const [itemsClassNames, setItemsClassNames] = useState(
    [] as ItemsClassNames[]
  );

  const [bottomRef, bottomSize] = useMeasure();

  const panels: Panels = [
    {
      title: "Wall thickness calculation ratios",
      type: "report",
      report: "special/pcs/wall-thickness-calculation-ratios",
    },
    {
      title: "Material and Rating Groups",
      type: "report",
      report: "misc/material-rating-groups",
    },
    {
      title: "Rating Groups",
      type: "report",
      report: "misc/rating-groups",
    },
    {
      title: "Allowable Stress Matrix",
      type: "report",
      report: "misc/allowable-stress-matrix",
    },
  ];

  const [showPanel, setShowPanel] = useState<PanelOrNot>(false);

  const steps = stepsData
    ? stepsData.getValLogSteps.map((e, i) => ({
        ...e,
        id: i + 1,
      }))
    : [];

  const details =
    detailsData && steps && steps.length > 0
      ? detailsData.getValLogDetails
          .map((e) => {
            const group = steps.find((step) => step.StepNo === e.StepNo);
            let subStepEndReached = false;
            return {
              ...e,
              itemID: `${e.StepNo}-$${e.LineNo}`,
              groupTitle: group ? (
                <ValidationStepGroupHeader>
                  <div>
                    <StatusIcon status={group.Status} size={16} />
                  </div>
                  <div>
                    Step {group.id}: {group.Description}
                  </div>
                </ValidationStepGroupHeader>
              ) : (
                <>Missing data</>
              ),
              subStepHasWarning:
                e.Status === "I"
                  ? detailsData.getValLogDetails
                      .filter(
                        (l) => l.StepNo === e.StepNo && l.LineNo > e.LineNo
                      )
                      .reduce((a, c) => {
                        subStepEndReached =
                          subStepEndReached || c.Status === "I";
                        return subStepEndReached ? a : a || c.Status === "W";
                      }, false)
                  : false,
            };
          })
          .filter((e) =>
            warningsFilter ? e.Status === "W" || e.subStepHasWarning : true
          )
      : [];

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(stepsStatus, detailsStatus),
    [stepsStatus, detailsStatus]
  );

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Space.Fill>
        <Space.Fill>
          <Table
            containerId={`validationLog${paneLocation}`}
            groupTitle="groupTitle"
            nonVirtual={true}
            density="compact"
            items={details}
            itemIdProp="itemID"
            columns={[
              {
                key: "Description",
                title: "Description",
                Component: DescriptionOrSubHeader,
                type: "with-context",
              },
              {
                key: "Status",
                title: "Status",
                Component: ValidationStatusComponent,
                type: "with-context",
              },
            ]}
            selectionMode={false}
            status={combinedStatuses}
            controlHeader={
              <ValidationLogSteps
                stepsStatus={stepsStatus}
                steps={steps}
                paneLocation={paneLocation}
                warningsFilter={warningsFilter}
                setWarningsFilter={setWarningsFilter}
                setItemsClassNames={setItemsClassNames}
                panels={panels}
                setShowPanel={setShowPanel}
              />
            }
            groupBy="StepNo"
            groupIdProp="StepNo"
            totalCount={details.length}
            noItemsMessage={"No validation data exists."}
            loadingString="Loading Validation Log..."
            itemsClassNames={itemsClassNames}
            footerTypographyVariant="overline"
          />
        </Space.Fill>
        {showPanel && (
          <Space.BottomResizable size={400}>
            <Panel
              showPanel={showPanel}
              setShowPanel={setShowPanel}
              name={name}
              revision={revision}
            />
          </Space.BottomResizable>
        )}
        <Space.Bottom size={bottomSize.height}>
          {stepsStatus === "success" ? (
            <div ref={bottomRef}>
              <InfolineContainer>
                <FooterContainer>
                  <div style={{ display: "flex", gap: 35 }}>
                    <DataTable>
                      <div>Case handler:</div>
                      <div>{naIfNa(stepsData.CaseHandler)}</div>
                      <div>Validation date:</div>
                      <div>{naIfNa(stepsData.ValidationDate)}</div>
                    </DataTable>
                    <DataTable>
                      <div>Approver:</div>
                      <div>{naIfNa(stepsData.Approver)}</div>
                      <div>Approval date:</div>
                      <div>{naIfNa(stepsData.ApprovalDate)}</div>
                    </DataTable>
                  </div>
                </FooterContainer>
              </InfolineContainer>
            </div>
          ) : (
            <></>
          )}
        </Space.Bottom>
      </Space.Fill>
    </div>
  );
}
