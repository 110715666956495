import axios, { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { StatusResponse } from "components/StatusModal";

export default function useCopyVDS() {
  async function saveSheet({
    name,
    revision,
  }: {
    name: string;
    revision: string;
  }): Promise<AxiosResponse<StatusResponse>> {
    return await axios(getSheetApiUrl({ sheetType: "vds", name, revision }), {
      method: "post",
    });
  }

  return useMutation(saveSheet, {
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
  });
}

export type CopyVDSQuery = ReturnType<typeof useCopyVDS>;
