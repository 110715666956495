import { TreeProps, useTree } from "components/table/useTree";

import { Icon } from "@equinor/eds-core-react";
import {
  IconData,
  business,
  folder,
  folder_favorite,
  platform,
} from "@equinor/eds-icons";
import { UseQueryResult } from "react-query";
import { DocumentSpaceContextData } from "./DocumentSpace";
import { FolderRowMenu } from "./FolderRowMenu";
import { DocumentSpaceItem } from "./queries/useDocumentSpace";

export const protectedFolderOIDs = [1, 2, 3];

const folderIconData = (item: DocumentSpaceItem): IconData =>
  item.MenuOrCategory === "M"
    ? folder_favorite
    : item.DocumentType === "P"
    ? platform
    : item.DocumentType === "N" && item.OperatorID !== 0
    ? business
    : folder;

const folderIconColor = (item: DocumentSpaceItem): string =>
  item.VisibleOnWeb === "Y" ? "var(--textSemiLight)" : "var(--textLight)";

const folderIconFunc = (item: DocumentSpaceItem) => (
  <Icon data={folderIconData(item)} color={folderIconColor(item)} />
);

export const useDirectoryTree = (
  props: Partial<TreeProps<DocumentSpaceItem>> & {
    query: UseQueryResult<DocumentSpaceItem[], Error>;
    contextData: DocumentSpaceContextData;
    title?: string;
    disableRowMenu?: boolean;
  }
) => {
  const {
    content,
    expandAll,
    collapseAll,
    selection,
    selectedLine,
    selectionParents,
    getParents,
    openItems,
  } = useTree<DocumentSpaceItem>({
    itemIdProp: "OID",
    parentIdProp: "ParentOID",
    titleColumn: { key: "Description", title: props.title ?? "Folders" },
    columns: [],
    fullRowSelect: true,
    density: "compact",
    iconFunc: folderIconFunc,
    hideSelectionElement: true,
    RowMenu: props.disableRowMenu ? undefined : FolderRowMenu,
    ...props,
    query: props.query,
    contextData: { ...props.contextData, folderData: props.query.data },
  });

  return {
    content,
    selection,
    selectedLine,
    selectionParents,
    getParents,
    expandAll,
    collapseAll,
    openItems,
  };
};
