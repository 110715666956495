import { QueryStatus } from "react-query";
import { ContentMessage, InfolineContainer, Loader } from "./Components";
import { SheetStatus } from "features/sheets/config/statusConfig";

export function EditModalStatusDisplay({
  status,
  loadingString,
}: {
  status: QueryStatus;
  loadingString?: string;
}) {
  return status === "idle" ? (
    <ContentMessage color="danger">Query is idle.</ContentMessage>
  ) : status === "error" ? (
    <ContentMessage color="danger">Error loading data sheet.</ContentMessage>
  ) : status === "loading" ? (
    <Loader label={loadingString ? loadingString : "Loading Data Sheet..."} />
  ) : null;
}

export function Infoline({
  item,
  status,
}: {
  item?: {
    RevDate: string;
    LastUpdate?: string;
    LastUpdateBy?: string;
    Status?: SheetStatus | string;
  };
  status?: boolean;
}) {
  return (
    <InfolineContainer>
      {item && (
        <>
          <div>Rev. Date: {item.RevDate}</div>
          {item.LastUpdate && <div>Last Update: {item.LastUpdate}</div>}
          {item.LastUpdateBy && <div>Last Update By: {item.LastUpdateBy}</div>}
          {status && item.Status && <div>Status: {item.Status}</div>}
        </>
      )}
    </InfolineContainer>
  );
}
