import { defaultConfig } from "config/config.default";
import { equinorProdConfig } from "config/config.equinor-prod";
import { equinorTestConfig } from "config/config.equinor-test";
import { presightProdTestConfig } from "config/config.presight-prod-test";
import { presightDevConfig } from "./config.presight-dev";

export type Config = {
  restApiBaseUrl: string;
  login: {
    clientId: string;
    authority: string;
    scopes: string[];
  };
  modules: Modules[];
  liveMonitor: boolean;
  tinyApiKey: string;
  extraHeaders?: { [index: string]: string };
  debugLog?: boolean;
};

export type Modules = "reports" | "sheets" | "admin";

const appEnvConfig =
  process.env.REACT_APP_ENV === "equinor-prod"
    ? equinorProdConfig
    : process.env.REACT_APP_ENV === "equinor-test"
    ? equinorTestConfig
    : process.env.REACT_APP_ENV === "presight-prod-test"
    ? presightProdTestConfig
    : process.env.REACT_APP_ENV === "presight-dev"
    ? presightDevConfig
    : defaultConfig;

const config = {
  commonLibPlantCodesInUrl: false,
  useAPIFilters: true,
  checkForUpdateInterval: 1000 * 60 * 5,
  ...appEnvConfig,
};

export default config;
