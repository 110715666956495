import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { VDSTextBlock } from "features/sheets/types";
import useSheet from "features/sheets/queries/useSheet";
import useVDSTextBlocksDistinctText from "features/sheets/queries/useVDSTextBlocksDistinctText";
import { useContext, useEffect, useMemo } from "react";

export function VDSTextBlockSectionR({
  data,
  itemId,
  name,
  revision,
  setIsLoaded,
}: {
  data?: VDSTextBlock;
  itemId?: number;
  name?: string;
  revision?: string;
  setIsLoaded?: React.Dispatch<boolean>;
}) {
  const { disabled } = useContext(FieldContext);

  const { data: descriptions, status: descriptionStatus } =
    useVDSTextBlocksDistinctText({
      attributeName: "MD",
      sectionId: "R",
      itemId: itemId,
      disabled,
    });

  const { data: vskData, status: vskStatus } = useSheet({
    sheetType: "vds-textblocks",
    name: "0",
    revision: "0",
    prepostfix: `item/${itemId}/`,
    postfix: "vsk",
    disabled,
  });

  const vskOptions = useMemo(
    () => vskData && vskData.getVDSTextBlockVSK.map((e) => e.VSK),
    [vskData]
  );

  useEffect(() => {
    descriptionStatus === "success" &&
      vskStatus === "success" &&
      setIsLoaded &&
      setIsLoaded(true);
  }, [descriptionStatus, setIsLoaded, vskStatus]);

  return (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Description"
        prop="MiscDescription"
        value={data?.MiscDescription ?? ""}
        options={descriptions}
        type="autocomplete"
        status={descriptionStatus}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        prop="MiscVSK"
        value={data?.MiscVSK ?? ""}
        title="VSK"
        type="option"
        options={vskOptions}
        disableNotSetOption={true}
        maxWidth={180}
        status={vskStatus}
        nonExistingText={disabled ? "" : undefined}
      />
    </>
  );
}
