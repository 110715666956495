import axios, { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";

type SubsegmentVDSContentValidationReplyItem = {
  VDSAffected: string;
  VDSAffectedRevision: string;
  VDSDuplicate: string;
  VDSDuplicateRevision: string;
};

export type SubsegmentVDSContentValidationSubmitItem = {
  VDS: string;
  Revision: string;
  SubsegmentID: string;
  SubsegmentRevision: string;
};

export default function useVDSSubsegmentVDSContentValidation() {
  async function vdsSubsegmentVDSContentValidation({
    name,
    revision,
    content,
  }: {
    name: string;
    revision: string;
    content: {
      sheets: SubsegmentVDSContentValidationSubmitItem[];
    };
  }): Promise<
    AxiosResponse<{
      getVDSDuplicates: SubsegmentVDSContentValidationReplyItem[];
    }>
  > {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-subsegments",
        name,
        revision,
        postfix: "vds-content-validation",
      }),
      {
        method: "put",
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(vdsSubsegmentVDSContentValidation, {
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
  });
}
