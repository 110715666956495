import config from "config/config";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

export default function useSaveVDSSubsegmentGroupsConfig() {
  const queryClient = useQueryClient();

  async function saveAdmin({
    data,
  }: {
    data: { GroupID: string | null; ItemID: string; ItemSection: string }[];
  }) {
    return await axios({
      url: `${config.restApiBaseUrl}codelists/vds-subsegment-groups-config`,
      method: "put",
      data: JSON.stringify({ vdssubsegmentgroupconfig: data }),
    });
  }
  return useMutation(saveAdmin, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "codelists",
        "vds-subsegment-groups-config",
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
