import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { StatusResponse } from "../../../components/StatusModal";
import { getSheetApiUrl } from "../../../queries/queryUtil";

type VDSSubsegmentReplacementSubmit = {
  TaskDescription: string;
  SubSegmentID: number;
  Revision: string;
  ReplacedBySubSegmentID: number;
  ReplacedByRevision: string;
  sheets: { VDS: string; Revision: string; SetOfficial: "Y" | "N" }[];
};

export const useVDSSubsegmentReplacement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      subsegmentId,
      revision,
      replacedBySubsegmentId,
      replacedByRevision,
      sheets,
    }: {
      subsegmentId: number;
      revision: string;
      replacedBySubsegmentId: number;
      replacedByRevision: string;
      sheets: { vds: string; revision: string; setOfficial: boolean }[];
    }) =>
      await axios.put<
        VDSSubsegmentReplacementSubmit,
        AxiosResponse<StatusResponse>
      >(
        getSheetApiUrl({ sheetType: "vds", postfix: "subsegment-replacement" }),
        {
          TaskDescription: `Replace Subsegment ${subsegmentId}-${revision} with ${replacedBySubsegmentId}-${replacedByRevision}`,
          SubSegmentID: subsegmentId,
          Revision: revision,
          ReplacedBySubSegmentID: replacedBySubsegmentId,
          ReplacedByRevision: replacedByRevision,
          sheets: sheets.map((sheet) => ({
            VDS: sheet.vds,
            Revision: sheet.revision,
            SetOfficial: sheet.setOfficial ? "Y" : "N",
          })),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vds-task-monitor"]);
      },
    }
  );
};
