import {
  FlexColumnContainer,
  FlexContainer,
  FlexElement,
} from "components/Components";
import { Field } from "components/form/Field";
import { VDSTextBlock } from "features/sheets/types";

export function EditVDSTextBlockTemperatures({
  data,
  maxWidth = 180,
}: {
  data?: VDSTextBlock;
  maxWidth?: number;
}) {
  return (
    <FlexColumnContainer style={{ justifyContent: "flex-start" }}>
      <FlexElement>
        <FlexContainer style={{ gap: 32 }}>
          <FlexElement>
            <Field
              title="Min. Design Temperature"
              feature="sheets"
              sheetType="vds-textblocks"
              prop="MinDesignTemperature"
              value={data?.MinDesignTemperature ?? ""}
              type="text"
              maxWidth={maxWidth}
            />
          </FlexElement>
          <FlexElement>
            <Field
              title="Max. Design Temperature"
              feature="sheets"
              sheetType="vds-textblocks"
              prop="MaxDesignTemperature"
              value={data?.MaxDesignTemperature ?? ""}
              type="text"
              maxWidth={maxWidth}
            />
          </FlexElement>
        </FlexContainer>
      </FlexElement>
      <FlexElement>
        <FlexContainer style={{ gap: 32 }}>
          <FlexElement>
            <Field
              title="Min. Operating Temperature"
              feature="sheets"
              sheetType="vds-textblocks"
              prop="MinOperatingTemperature"
              value={data?.MinOperatingTemperature ?? ""}
              type="text"
              maxWidth={maxWidth}
            />
          </FlexElement>
          <FlexElement>
            <Field
              title="Max. Operating Temperature"
              feature="sheets"
              sheetType="vds-textblocks"
              prop="MaxOperatingTemperature"
              value={data?.MaxOperatingTemperature ?? ""}
              type="text"
              maxWidth={maxWidth}
            />
          </FlexElement>
        </FlexContainer>
      </FlexElement>
    </FlexColumnContainer>
  );
}
