import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import {
  plantDependentSheets,
  sheetProperties,
  SheetTypes,
} from "../config/sheetConfig";
import { PlantProps } from "../../plant/types";

export default function useNewSheetRevisions() {
  const queryClient = useQueryClient();

  async function newSheetRevisions({
    plant,
    sheetType,
    sheets,
    command,
  }: {
    plant: PlantProps;
    sheetType: SheetTypes;
    sheets: any[];
    command?: string;
  }) {
    const { sheetListProperty } = sheetProperties[sheetType];
    return await axios(
      getSheetApiUrl({
        plant,
        sheetType,
        command: `/${command ? command : "revisions"}`,
      }),
      {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: { [sheetListProperty ? sheetListProperty : "sheets"]: sheets },
      }
    );
  }

  return useMutation(newSheetRevisions, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType)
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type NewSheetRevisions = ReturnType<typeof useNewSheetRevisions>;
