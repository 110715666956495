import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";
import { SheetStatus } from "../../sheets/config/statusConfig";

export type StructuredPipeElementMatrices = {
  MatrixID: number;
  Revision: string;
  RevDate: string;
  Status: SheetStatus;
  Description: string;
  Selected: "Y" | "N";
};

export type StructuredPipeElementArticles = {
  ArticleNo: string;
  Type: string;
  Unit: string;
  Size1: string;
  Size2: string;
  Other: string;
  Height: string;
  Length: string;
  Width: string;
  WallThickness1: string;
  WallThickness2: string;
  Status: SheetStatus;
};

export type StructuredPipeElementConditionIndicator = "O" | "D" | "N";

export type StructuredPipeElement = {
  ElementID: number;
  Revision: string;
  RevDate: string;
  Status: SheetStatus;
  LastUpdate: string;
  LastUpdateBy: string;
  Priority: number;
  DimStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  EndConnection1: string;
  EndConnection2: string;
  NonStandardUnderTolerance: string;
  RatingStandard: string;
  RatingClass: string;
  FreeTextNote: string;
  ComponentTypeID: string;
  ComponentTypeText: string;
  SubElementID: number;
  SubElementText: string;
  ConditionIndicator: StructuredPipeElementConditionIndicator;
  Area: string;
  SpecialRequirement: string;
  StructuredPipeElementMatrices: StructuredPipeElementMatrices[];
  StructuredPipeElementArticles: StructuredPipeElementArticles[];
};

export function useStructuredPipeElementsQuery({
  name,
  revision,
  mpeId,
}: {
  name: string;
  revision: string;
  mpeId: number | undefined;
}) {
  return useQuery<StructuredPipeElement[], Error>(
    [
      "pmg",
      "material-groups",
      name,
      revision,
      "master-pipe-elements",
      mpeId,
      "structured-pipe-elements",
    ],
    async () => {
      const { data } = await axios.get<{
        getStructuredPipeElements: StructuredPipeElement[];
      }>(
        getApiUrl(
          "pmg/material-groups/" +
            name +
            "/rev/" +
            revision +
            ["/master-pipe-elements", mpeId, "structured-pipe-elements"].join(
              "/"
            )
        )
      );

      return data.getStructuredPipeElements;
    },
    { staleTime: 0, cacheTime: 0, enabled: !!mpeId }
  );
}
