import { Button, Icon, Tabs, Typography } from "@equinor/eds-core-react";
import { check_circle_outlined } from "@equinor/eds-icons";
import { useNamedTabs } from "components/tabs/useNamedTabs";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as Space from "react-spaces";
import { selectUIState, setPaneMode, setPaneSheet } from "uiSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  AbsoluteFlexContainer,
  Fade,
  FlexContainer,
  FlexContentElement,
  FlexElement,
  FlexHeaderElement,
} from "../../../../components/Components";
import ContentModal from "../../../../components/ContentModal";
import StatusModal from "../../../../components/StatusModal";
import useConfirm from "../../../../components/confirm/useConfirm";
import { FormButtonContainer } from "../../../../components/form/Form";
import useSaveSheet from "../../queries/useSaveSheet";
import useSheet from "../../queries/useSheet";
import { successMessage } from "../../../../utils/successMessage";
import { selectMainPlant } from "../../../plant/plantSlice";
import { PreviewButtons, SheetPane, reloadPreviewPane } from "../../SheetPane";
import { closeEditSheet } from "../../commands";
import { sheetDocumentWidth, sheetTypeNames } from "../../config/sheetConfig";
import { PCSHeadingProps } from "../../types";
import {
  EditModalStatusDisplay,
  Infoline,
} from "../../../../components/ModalComponents";
import { PCSEmbeddedNotes, prepareEmbeddedNotesData } from "./PCSEmbeddedNotes";
import { PCSHeading } from "./PCSHeading";
import { PCSPipeElements, preparePipeElementsData } from "./PCSPipeElements";
import { PCSPipeSizeWallThickness, preparePipeSizesData } from "./PCSPipeSize";
import { PCSProperties, preparePropertiesData } from "./PCSProperties";
import { PCSValveElements, prepareValveElementsData } from "./PCSValveElements";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";

export default function EditPCSModal({
  name,
  revision,
}: {
  name: string;
  revision: string;
}) {
  const { paneModes } = useAppSelector(selectUIState);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const plant = useAppSelector(selectMainPlant);

  const { data, status, isRefetching } = useSheet({
    plant,
    sheetType: "pcs",
    name,
    revision,
    cacheTime: 0,
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const item = data ? (data as PCSHeadingProps) : undefined;

  useEffect(() => {
    dispatch(
      setPaneSheet({
        location: "editModal",
        sheet: {
          plant,
          sheetType: "pcs",
          name,
          revision,
        },
      })
    );
    // eslint-disable-next-line
  }, []);

  const methods = useForm({
    shouldUnregister: true,
    mode: "all",
  });

  const { handleSubmit, formState } = methods;
  const { dirtyFields } = formState;

  const {
    mutate: saveSheet,
    status: saveSheetStatus,
    reset: saveSheetReset,
    error: saveSheetError,
    data: saveSheetData,
  } = useSaveSheet();

  const { isConfirmed } = useConfirm();

  const checkIfDirty = async () =>
    Object.keys(dirtyFields).length > 0
      ? await isConfirmed(defaultUnsavedMessage)
      : true;

  const tabTitles = [
    { name: "heading", title: "Heading" },
    { name: "pipe-sizes", title: "Pipe Size / Wall Thickness" },
    { name: "pipe-elements", title: "Pipe Elements" },
    { name: "valve-elements", title: "Valve Elements" },
    { name: "embedded-notes", title: "Embedded Notes" },
    { name: "properties", title: "Properties" },
  ];

  const tabs =
    item && item.Status === "W"
      ? [
          "heading",
          "pipe-sizes",
          "pipe-elements",
          "valve-elements",
          "embedded-notes",
          "properties",
        ]
      : ["properties"];

  const { activeTab, handleChange, activeTabName } = useNamedTabs({
    name: "EditPCS",
    changeConfirm: checkIfDirty,
    tabs,
  });

  const closeModal = async () => {
    (await checkIfDirty()) && closeEditSheet({ history });
  };

  const onSubmit: SubmitHandler<any> = (data) => {
    const content =
      activeTabName === "valve-elements"
        ? prepareValveElementsData(data)
        : activeTabName === "pipe-elements"
        ? preparePipeElementsData(data)
        : activeTabName === "pipe-sizes"
        ? preparePipeSizesData(data)
        : activeTabName === "properties"
        ? preparePropertiesData(data)
        : activeTabName === "embedded-notes"
        ? prepareEmbeddedNotesData(data)
        : data;
    saveSheet({
      plant,
      sheetType: "pcs",
      name,
      revision,
      content,
      postfix: activeTabName === "heading" ? undefined : activeTabName,
    });
  };

  return (
    <>
      <ContentModal
        isOpen={!!(name && revision && plant.PlantID > 0)}
        closeModal={closeModal}
        header={
          <FlexContainer style={{ justifyContent: "flex-start" }}>
            <FlexElement style={{ marginRight: "1em" }}>
              <Typography variant="h3">
                Edit {sheetTypeNames["pcs"]} {name} rev. {revision}
                <Fade> in </Fade>
                {plant.LongDescription}
              </Typography>
            </FlexElement>
            {item && (
              <FlexElement style={{ marginTop: -11, marginBottom: -7 }}>
                <PreviewButtons
                  plant={plant}
                  sheetType="pcs"
                  item={item}
                  size={24}
                  previewLocation="editModal"
                />
                <Button
                  variant="ghost_icon"
                  title="Validation log"
                  onClick={() => {
                    dispatch(
                      setPaneMode({
                        location: "editModal",
                        mode: "validation-log",
                      })
                    );
                  }}
                >
                  <Icon data={check_circle_outlined} />
                </Button>
              </FlexElement>
            )}
          </FlexContainer>
        }
      >
        <Space.Fill>
          <Space.Fill>
            {status === "success" && item ? (
              <Tabs activeTab={activeTab} onChange={handleChange} scrollable>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <AbsoluteFlexContainer>
                      <FlexHeaderElement>
                        <Tabs.List>
                          {tabs.map((tab) => (
                            <Tabs.Tab key={tab}>
                              {tabTitles.find((t) => t.name === tab)!.title}
                            </Tabs.Tab>
                          ))}
                        </Tabs.List>
                      </FlexHeaderElement>
                      <FlexContentElement style={{ overflow: "hidden" }}>
                        <Tabs.Panels>
                          {tabs.map((tab) => (
                            <Tabs.Panel key={tab}>
                              {activeTabName === "heading" &&
                                tab === "heading" && (
                                  <PCSHeading
                                    data={data}
                                    setIsLoaded={setIsLoaded}
                                    isRefetching={isRefetching}
                                    status={status}
                                    saveSheetStatus={saveSheetStatus}
                                  />
                                )}
                              {activeTabName === "pipe-sizes" &&
                                tab === "pipe-sizes" && (
                                  <PCSPipeSizeWallThickness
                                    name={name}
                                    revision={revision}
                                    setIsLoaded={setIsLoaded}
                                    saveSheetStatus={saveSheetStatus}
                                  />
                                )}
                              {activeTabName === "pipe-elements" &&
                                tab === "pipe-elements" && (
                                  <PCSPipeElements
                                    name={name}
                                    revision={revision}
                                    setIsLoaded={setIsLoaded}
                                    saveSheetStatus={saveSheetStatus}
                                  />
                                )}
                              {activeTabName === "valve-elements" &&
                                tab === "valve-elements" && (
                                  <PCSValveElements
                                    name={name}
                                    revision={revision}
                                    setIsLoaded={setIsLoaded}
                                    saveSheetStatus={saveSheetStatus}
                                  />
                                )}
                              {activeTabName === "embedded-notes" &&
                                tab === "embedded-notes" && (
                                  <PCSEmbeddedNotes
                                    name={name}
                                    revision={revision}
                                    setIsLoaded={setIsLoaded}
                                    saveSheetStatus={saveSheetStatus}
                                  />
                                )}
                              {activeTabName === "properties" &&
                                tab === "properties" && (
                                  <PCSProperties
                                    name={name}
                                    revision={revision}
                                    setIsLoaded={setIsLoaded}
                                    saveSheetStatus={saveSheetStatus}
                                  />
                                )}
                            </Tabs.Panel>
                          ))}
                        </Tabs.Panels>
                      </FlexContentElement>
                      <FlexHeaderElement
                        style={{ borderTop: "1px solid var(--borderDefault)" }}
                      >
                        <FormButtonContainer>
                          <Button
                            type="submit"
                            disabled={
                              !isLoaded ||
                              saveSheetStatus === "loading" ||
                              Object.keys(formState.errors).length > 0
                            }
                          >
                            Update
                          </Button>
                          <Button
                            type="button"
                            variant="outlined"
                            onClick={() => closeModal()}
                          >
                            Close
                          </Button>
                        </FormButtonContainer>
                      </FlexHeaderElement>
                      <FlexHeaderElement>
                        <Infoline item={item} />
                      </FlexHeaderElement>
                    </AbsoluteFlexContainer>
                  </form>
                  <StatusModal
                    status={saveSheetStatus}
                    successMessage={successMessage(saveSheetData)}
                    onSuccess={() => {
                      reloadPreviewPane("main");
                      reloadPreviewPane("editModal");
                    }}
                    onSettledClose={() => {
                      saveSheetReset();
                    }}
                    error={saveSheetError}
                  />
                </FormProvider>
              </Tabs>
            ) : (
              <EditModalStatusDisplay status={status} />
            )}
          </Space.Fill>
          {paneModes.editModal && (
            <Space.RightResizable size={sheetDocumentWidth}>
              <SheetPane location="editModal" />
            </Space.RightResizable>
          )}
        </Space.Fill>
      </ContentModal>
    </>
  );
}
