import { Button, EdsProvider } from "@equinor/eds-core-react";
import { useHistory } from "react-router-dom";

export function ReportFormHeader({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const { location } = history;
  const { search } = location;

  return (
    <EdsProvider density="comfortable">
      <div style={{ marginBottom: "1.5em" }}>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formKeys = Object.keys(event.currentTarget.elements).filter(
              (e) => isNaN(Number(e))
            );
            const newSearch =
              "?" +
              formKeys
                .filter((e) => event.currentTarget[e].value !== "")
                .map(
                  (e) =>
                    e +
                    "=" +
                    (event.currentTarget[e].value === "*"
                      ? ""
                      : event.currentTarget[e].value)
                )
                .join("&");

            const bust = search === newSearch ? "&bust=1" : "";

            history.push({
              search: newSearch + bust,
            });
          }}
        >
          <div style={{ marginBottom: "1em" }}>{children}</div>
          <Button type="submit">Submit</Button>
        </form>
      </div>
    </EdsProvider>
  );
}
