import { ReportsConfig } from "../Reports";
import { pmgSPENoMatricesReportColumns } from "./PMGSPENoMatricesReport";

export type PMGSPENoMatricesData = {
  MaterialGroup: string;
  ElementGroup: string;
  DimStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  Area: string;
  SPEID: string;
  SPERev: string;
}[];

export const pmgSPENoMatricesConfig: ReportsConfig = {
  title: "Structured Pipe Elements Missing Matrices",
  menuTitle: "Structured Pipe Elements Missing Matrices",
  listProperty: "getSPENoMatrix",
  exportFunction: (data) => ({
    columns: pmgSPENoMatricesReportColumns,
    items: data,
  }),
};
