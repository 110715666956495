import { Button, Icon, Label, Typography } from "@equinor/eds-core-react";
import { FlexContainer, TotalCenter } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
  ModalWindowContentFixed,
  ModalWindowSimpleContainer,
} from "components/ModalWindow";
import { Field } from "components/form/Field";
import Table, {
  ColumnsProps,
  SmallTableContainer,
} from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import {
  StructuredPipeElement,
  StructuredPipeElementMatrices,
} from "features/pmg/queries/useStructuredPipeElements";
import { useCallback, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Infoline } from "components/ModalComponents";
import { useCodelist } from "queries/useCodelist";
import { combineQueryStatuses } from "queries/queryUtil";
import { useUpdateStructuredPipeElement } from "../queries/useUpdateStructuredPipeElement";
import StatusModal from "components/StatusModal";
import { successMessage } from "utils/successMessage";
import { FieldContext } from "components/form/FieldContext";
import { grid_on, warning_outlined } from "@equinor/eds-icons";
import { MatrixView } from "../matrices/MatrixView";
import { SubtitleContainer, SubtitleItem } from "components/Subtitle";

const matrixColumns: ColumnsProps<StructuredPipeElementMatrices>[] = [
  { key: "MatrixID", title: "ID" },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
  { key: "Status", title: "Status" },
  { key: "Description", title: "Description" },
];

type DependentInformationFormData = {
  subElementId: number;
  componentTypeId: string;
  matrixId: string;
};

export type ViewMatrixData = {
  id: null | string;
  revision: null | string;
};

function MatrixRowMenu({
  item,
  setViewMatrix,
}: {
  item: StructuredPipeElementMatrices;
  setViewMatrix: React.Dispatch<ViewMatrixData>;
}) {
  return (
    <Button
      variant="ghost_icon"
      onClick={() => {
        setViewMatrix({ id: String(item.MatrixID), revision: item.Revision });
      }}
      title="View Matrix"
    >
      <Icon data={grid_on} />
    </Button>
  );
}

function DifferentData({ dataName }: { dataName: string }) {
  return (
    <Typography variant="caption" style={{ margin: "2em 0 1em" }}>
      <FlexContainer flexStart gap={8}>
        <Icon data={warning_outlined} size={16} />
        <div style={{ marginTop: 2 }}>
          The {dataName} of the selected elements differ.
        </div>
      </FlexContainer>
    </Typography>
  );
}

export function EditSPEDependentInformationModal({
  structuredPipeElement,
  materialGroupId,
  materialGroupRevision,
  masterPipeElementId,
  elementGroupId,
  setEditDependent,
  isEdit,
  editMulti,
  setEditMulti,
  selectedLines,
}: {
  structuredPipeElement: StructuredPipeElement | undefined;
  materialGroupId: string;
  materialGroupRevision: string;
  masterPipeElementId: number | undefined;
  elementGroupId: number | undefined;
  setEditDependent: React.Dispatch<number | false>;
  isEdit: boolean;
  editMulti: boolean;
  setEditMulti: React.Dispatch<boolean>;
  selectedLines: StructuredPipeElement[] | undefined;
}) {
  const methods = useForm<DependentInformationFormData>({
    mode: "all",
    shouldUnregister: true,
  });

  const isOpen =
    !!(selectedLines && selectedLines.length > 0) || !!structuredPipeElement;

  const { handleSubmit, reset } = methods;

  const {
    mutate,
    status: mutateStatus,
    error: mutateError,
    data: mutateData,
    reset: mutateReset,
  } = useUpdateStructuredPipeElement({
    masterPipeElementId,
    materialGroupId,
    materialGroupRevision,
  });

  const onSubmit = useCallback(
    (data: DependentInformationFormData) => {
      mutate({
        ...data,
        pipeElements:
          editMulti && selectedLines
            ? selectedLines?.map((e) => ({
                elementId: e.ElementID,
                revision: e.Revision,
              }))
            : structuredPipeElement
            ? [
                {
                  elementId: structuredPipeElement.ElementID,
                  revision: structuredPipeElement.Revision,
                },
              ]
            : [],
      });
    },
    [editMulti, mutate, selectedLines, structuredPipeElement]
  );

  const multiSCP = useMemo(
    () =>
      selectedLines?.length
        ? selectedLines
            .map((e) => e.SubElementID)
            .every((e) => e === selectedLines[0].SubElementID)
          ? selectedLines[0].SubElementID
          : -1
        : -1,
    [selectedLines]
  );

  const multiCT = useMemo(
    () =>
      selectedLines?.length
        ? selectedLines
            .map((e) => e.ComponentTypeID)
            .every((e) => e === selectedLines[0].ComponentTypeID)
          ? selectedLines[0].ComponentTypeID
          : -1
        : -1,
    [selectedLines]
  );

  const multiMatrices = useMemo(
    () =>
      selectedLines?.length
        ? selectedLines[0].StructuredPipeElementMatrices
        : [],
    [selectedLines]
  );

  const multiSelection = useMemo<string[] | undefined>(() => {
    return selectedLines?.length
      ? selectedLines[0].StructuredPipeElementMatrices.filter(
          (e) => e.Selected === "Y"
        ).map((e) => String(e.MatrixID))
      : undefined;
  }, [selectedLines]);

  const multiSelectionType = useMemo<"same" | "different">(
    () =>
      selectedLines
        ?.map((e) =>
          e.StructuredPipeElementMatrices.filter((e) => e.Selected === "Y")
            .map((e) => String(e.MatrixID))
            .join()
        )
        .some((e) => e !== multiSelection?.join())
        ? "different"
        : "same",
    [multiSelection, selectedLines]
  );

  const initialSelection = useMemo(
    () =>
      !editMulti
        ? structuredPipeElement?.StructuredPipeElementMatrices.filter(
            (e) => e.Selected === "Y"
          ).map((e) => String(e.MatrixID))
        : multiSelectionType === "same"
        ? multiSelection
        : [],
    [
      editMulti,
      multiSelection,
      multiSelectionType,
      structuredPipeElement?.StructuredPipeElementMatrices,
    ]
  );

  const { selection, selectionDispatch, selectionMode } = useTableSelect({
    selectionMode: "multi",
    selection: initialSelection,
  });

  const closeModal = useCallback(() => {
    setEditDependent(false);
    setEditMulti(false);
  }, [setEditMulti, setEditDependent]);

  const { data: subelementsData, status: subelementsStatus } = useCodelist({
    codelist: "subelements",
    extraParams: elementGroupId
      ? { ElementGroupID: elementGroupId, FirstLevelOnly: "Y" }
      : {},
    enabled: !!elementGroupId,
  });

  const { data: componentTypesData, status: componentTypesStatus } =
    useCodelist({
      codelist: "component-types",
      extraParams: elementGroupId
        ? { ElementGroupID: elementGroupId, FirstLevelOnly: "Y" }
        : {},
      enabled: !!elementGroupId,
    });

  const subelementOptions = useMemo(
    () =>
      subelementsData?.map((e) => ({ id: e.OID, option: e.Description })) || [],
    [subelementsData]
  );

  const componentTypeOptions = useMemo(
    () =>
      componentTypesData?.map((e) => ({
        id: e.ComponentTypeID,
        option: e.Description,
      })) || [],
    [componentTypesData]
  );

  const combinedStatuses = combineQueryStatuses(
    subelementsStatus,
    componentTypesStatus
  );

  const shouldClose = useRef<boolean>(false);

  const onSuccess = useCallback(() => {
    reset(undefined, { keepDirty: false, keepValues: true });
    if (shouldClose.current) {
      closeModal();
    }
    // closeModal is not useCallback-able.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const [viewMatrix, setViewMatrix] = useState<ViewMatrixData>({
    id: null,
    revision: null,
  });

  const closeMatrixModal = useCallback(() => {
    setViewMatrix({ id: null, revision: null });
  }, [setViewMatrix]);

  return isOpen && masterPipeElementId ? (
    <>
      <ModalWindow
        isOpen={isOpen}
        layer="default"
        closeModal={closeModal}
        title={
          structuredPipeElement
            ? `Edit Dependent Information for Structured Pipe Element ${structuredPipeElement.ElementID} rev. ${structuredPipeElement.Revision}`
            : "Edit Multiple Structured Pipe Elements"
        }
        subtitle={
          editMulti ? (
            <SubtitleContainer>
              {selectedLines?.map((e) => (
                <SubtitleItem key={e.ElementID}>
                  {e.ElementID} rev. {e.Revision}
                </SubtitleItem>
              ))}
            </SubtitleContainer>
          ) : undefined
        }
        hasInfoline
        status={combinedStatuses}
      >
        <FormProvider {...methods}>
          <FieldContext.Provider value={{ disabled: !isEdit }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalWindowContentDefault>
                <ModalSideMargin>
                  <ModalWindowSimpleContainer>
                    {editMulti && multiSCP === -1 && (
                      <DifferentData dataName="Subelement Connection Points" />
                    )}
                    <Field
                      title="Subelement Connection Point"
                      type="option"
                      prop="subElementId"
                      value={
                        structuredPipeElement
                          ? structuredPipeElement.SubElementID
                          : multiSCP === -1
                          ? 0
                          : multiSCP
                      }
                      optionsWithIds={subelementOptions}
                      disableNotSetOption
                    />
                    {editMulti && multiCT === -1 && (
                      <DifferentData dataName="Component Types" />
                    )}
                    <Field
                      title="Component Type"
                      type="option"
                      prop="componentTypeId"
                      value={
                        structuredPipeElement
                          ? structuredPipeElement.ComponentTypeID
                          : multiCT === -1
                          ? ""
                          : multiCT
                      }
                      optionsWithIds={componentTypeOptions}
                      notSetValue={""}
                    />
                    {editMulti && multiSelectionType === "different" && (
                      <DifferentData dataName="Master Matrices" />
                    )}
                    <Label label="Master Matrices" />
                    <SmallTableContainer style={{ width: 700 }}>
                      <Table
                        items={
                          structuredPipeElement
                            ? structuredPipeElement.StructuredPipeElementMatrices
                            : multiMatrices
                        }
                        columns={matrixColumns as ColumnsProps[]}
                        itemIdProp="MatrixID"
                        selection={selection}
                        selectionMode={selectionMode}
                        selectionDispatch={selectionDispatch}
                        density="compact"
                        fullRowSelect
                        nonVirtual
                        disableAllItems={!isEdit}
                        disableSelectAll={!isEdit}
                        RowMenu={MatrixRowMenu}
                        contextData={{ setViewMatrix }}
                      />
                    </SmallTableContainer>
                    <Field
                      type="hidden"
                      prop="matrixId"
                      value={selection.join()}
                    />
                  </ModalWindowSimpleContainer>
                </ModalSideMargin>
              </ModalWindowContentDefault>
              <ModalWindowButtonContainer hasInfoline>
                {isEdit && (
                  <Button
                    variant="outlined"
                    type="submit"
                    onClick={() => {
                      shouldClose.current = true;
                    }}
                  >
                    Save
                  </Button>
                )}
                {isEdit && (
                  <Button
                    type="submit"
                    onClick={() => {
                      shouldClose.current = true;
                    }}
                  >
                    Save & Close
                  </Button>
                )}
                <Button variant="outlined" onClick={closeModal}>
                  Close
                </Button>
              </ModalWindowButtonContainer>
              <ModalWindowContentFixed>
                <Infoline status item={structuredPipeElement} />
              </ModalWindowContentFixed>
            </form>
          </FieldContext.Provider>
        </FormProvider>
      </ModalWindow>
      <MatrixView
        id={viewMatrix.id}
        revision={viewMatrix.revision}
        closeModal={closeMatrixModal}
      />
      <StatusModal
        status={mutateStatus}
        error={mutateError}
        successMessage={successMessage(mutateData)}
        onSuccess={onSuccess}
        onSettledClose={mutateReset}
      />
    </>
  ) : (
    <TotalCenter>Select a Structured Pipe Element.</TotalCenter>
  );
}
