import { History } from "history";

export function editItem({
  history,
  itemId,
  revision,
  tab,
}: {
  history: History;
  itemId: string | number;
  revision: string;
  tab?: string;
}) {
  history.push({
    search: `?edit=${itemId}&rev=${revision}${tab ? `&tab=${tab}` : ""}`,
  });
}
