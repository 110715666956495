import {
  Button,
  Checkbox,
  Popover as EDSPopover,
  Icon,
  NativeSelect,
  Search,
  TextField,
  Typography,
} from "@equinor/eds-core-react";
import { close, filter_alt, filter_alt_active } from "@equinor/eds-icons";
import { InfoPopover } from "components/InfoPopover";
import { NameFilterInfo } from "components/NameFilterInfo";
import { useSettings } from "features/settings/useSettings";
import { useUpdateSetting } from "features/settings/useUpdateSetting";
import { HashProps } from "features/sheets/Sheets";
import { SheetFilter } from "features/sheets/queries/useSheetList";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { QueryStatus } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useMemo } from "use-memo-one";
import { NameProperties } from "utils/filterItemsByName";
import { CommonObjectContent } from "utils/filterItemsByProps";
import { Codelists } from "../../queries/useCodelist";
import { portalContainer } from "../../utils/util";
import {
  Chip,
  ControlButtonContainer,
  FlexContainer,
  FlexElement,
  IconButton,
  InputContainer,
  UnstyledList,
} from "../Components";
import Table, { SmallTableContainer } from "./Table";
import { useTableSelect } from "./useTableSelect";

const Popover = styled(EDSPopover)`
  max-width: none;
  & > div {
    max-width: none;
  }
  & > button {
    right: 8px !important;
  }
`;

export type FilterTypes =
  | "select"
  | "multiselect"
  | "text"
  | "checkboxes"
  | "tags"
  | "custom";

export type FilterOptions =
  | string[]
  | { key: string; title: string | JSX.Element }[];

export type SelectComponentProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<boolean>;
  filterState: string[];
  setFilterState: Dispatch<string[]>;
  filters: FilterProps[];
};

type FilterStates = string | string[];

export type CustomFilterComponentProps = {
  filter: FilterProps;
  filters: FilterProps[];
};

export interface FilterProps {
  type: FilterTypes;
  group?: string;
  title: string;
  prop: string;
  filterState: FilterStates;
  setFilterState: Dispatch<SetStateAction<any>>;
  defaultFilterState?: FilterStates;
  commaSeparated?: boolean;
  filterOptions?: FilterOptions | undefined;
  codelist?: Codelists;
  isArray?: boolean;
  selectTitle?: string;
  SelectComponent?: React.ComponentType<SelectComponentProps> | undefined;
  transformer?: (value: string) => string;
  serverSide?: boolean;
  apiFilterPorp?: keyof SheetFilter | string;
  CustomFilterComponent?: React.ComponentType<CustomFilterComponentProps>;
  filterFn?: (item: CommonObjectContent) => boolean;
  placeholder?: string;
}

export interface FilterGroupPropertiesProps {
  group: string;
  col: number;
  width?: number;
  info?: JSX.Element | string;
}

const FilterColumn = styled(FlexElement)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div:not(:last-child) {
    margin-bottom: 1.5em;
  }
`;

const CaptionContainer = styled.div`
  margin: 0.5em 0;
`;

export type FiltersDispatchProps = {
  type: "update" | "reset" | "replace";
  payload?: FilterProps | undefined | FilterProps[];
};

export type FiltersDispatch = React.Dispatch<FiltersDispatchProps>;

export function getServerSideFilters(filters: FilterProps[]) {
  return filters.reduce(
    (a, c) =>
      c.serverSide && c.apiFilterPorp && c.filterState !== ""
        ? {
            ...a,
            [c.apiFilterPorp]:
              typeof c.filterState === "string"
                ? c.filterState
                : c.filterState.join(","),
          }
        : a,
    {} as SheetFilter
  );
}

export function filtersReducer(
  state: FilterProps[],
  action: FiltersDispatchProps
) {
  const groupSort = (a: FilterProps, b: FilterProps) =>
    a.group && b.group
      ? a.group < b.group
        ? -1
        : a.group > b.group
        ? 1
        : 0
      : 0;
  const { type, payload } = action;
  switch (type) {
    case "reset":
      return [];
    case "update":
      return !payload || Array.isArray(payload)
        ? state
        : state.map((s) => s.prop).includes(payload.prop)
        ? state
            .map((s) => (s.prop === payload.prop ? payload : s))
            .sort(groupSort)
        : state.concat(payload).sort(groupSort);
    case "replace":
      return !payload || !Array.isArray(payload) ? state : payload;
    default:
      return state;
  }
}

function FilterPopover({
  filters,
  groupProperties = [{ col: 1, group: "Selections" }],
  maxWidth,
  anchorRef,
  isOpen,
  closePopover,
  applyConfirmedFilters,
  restoreConfirmedFilters,
  nameFilter,
  setNameFilter,
  nameFilterPlaceholder,
  currentName,
  setCurrentName,
  doNotApplyFilters,
  filteringDisabled,
  nameProperty,
  serverSide,
  nameFilterKey,
}: {
  filters: FilterProps[];
  groupProperties?: FilterGroupPropertiesProps[];
  maxWidth?: number;
  anchorRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
  closePopover: FilterClosePopover;
  applyConfirmedFilters?: () => void | undefined;
  restoreConfirmedFilters?: () => void | undefined;
  nameFilter?: string;
  setNameFilter?: React.Dispatch<string>;
  nameFilterPlaceholder?: string;
  currentName: string;
  setCurrentName: React.Dispatch<string>;
  doNotApplyFilters?: boolean;
  filteringDisabled?: boolean;
  nameProperty?: NameProperties;
  serverSide?: boolean;
  nameFilterKey?: string;
}) {
  const filterCols = groupProperties
    .filter((gp) =>
      filters.map((f) => f.group ?? "Selections").includes(gp.group)
    )
    .reduce((acc: number[], curr) => {
      return acc.includes(curr.col) ? acc : acc.concat(curr.col);
    }, []);

  const resetToDefaults = () => {
    filters.forEach((f) => {
      f.setFilterState(f.defaultFilterState ?? "");
    });
    !!applyConfirmedFilters && !!setNameFilter && setCurrentName("");
  };

  const history = useHistory();

  return ReactDOM.createPortal(
    <Popover
      anchorEl={anchorRef.current}
      open={isOpen}
      onClose={() =>
        closePopover({ save: false, restore: !!restoreConfirmedFilters })
      }
      style={{ zIndex: 1000 }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          !!setNameFilter && setNameFilter(currentName);
          !!applyConfirmedFilters &&
            (!doNotApplyFilters || !filteringDisabled) &&
            applyConfirmedFilters();
          closePopover({ save: true, restore: false });
          // @experiment
          history.replace({ hash: "" });
        }}
      >
        <Popover.Content
          style={{
            overflow: "auto",
            maxWidth: (maxWidth ?? window.innerWidth) - 50,
            maxHeight: "calc(80vh - 46px)",
          }}
        >
          <div style={{ position: "absolute", right: 6, top: 6 }}>
            <Button
              variant="ghost_icon"
              onClick={() => {
                closePopover({
                  save: false,
                  restore: !!restoreConfirmedFilters,
                });
              }}
            >
              <Icon data={close} size={18} />
            </Button>
          </div>
          {!!applyConfirmedFilters && (
            <div style={{ margin: "1em 1.25em 1.25em" }}>
              <CaptionContainer>
                <Typography color="primary" variant="caption">
                  Search
                </Typography>
              </CaptionContainer>
              <FlexContainer flexStart>
                <FlexElement>
                  <Search
                    value={currentName}
                    onChange={(e) => {
                      setCurrentName(e.target.value);
                    }}
                    style={{ width: 300 }}
                    placeholder={nameFilterPlaceholder ?? "Search..."}
                    id={nameFilterKey ?? "FilterSearch"}
                  />
                </FlexElement>
                <FlexElement>
                  <NameFilterInfo
                    nameProperty={nameProperty}
                    serverSide={serverSide}
                  />
                </FlexElement>
              </FlexContainer>
            </div>
          )}
          <FlexContainer
            style={{
              alignItems: "flex-start",
              marginRight: "1.5em",
              justifyContent: "flex-start",
              gap: ".5em .25em",
            }}
          >
            {filterCols.map((filterCol) => (
              <FilterColumn
                key={filterCol}
                style={{
                  padding: ".25em .5em 0",
                  margin: ".25em .75em .5em",
                }}
              >
                {groupProperties
                  .filter((gp) => gp.col === filterCol)
                  .filter((gp) =>
                    filters
                      .map((f) => f.group ?? "Selections")
                      .includes(gp.group)
                  )
                  .map((filterGroup) => (
                    <div
                      key={filterGroup.group}
                      style={{
                        ...(filterGroup.width
                          ? { width: filterGroup.width }
                          : {}),
                      }}
                    >
                      {filterGroup.group && (
                        <CaptionContainer>
                          <Typography color="primary" variant="caption">
                            {filterGroup.group}
                            {filterGroup.info && (
                              <InfoPopover
                                title={`Information on filtering by ${filterGroup.group}`}
                                content={filterGroup.info}
                              />
                            )}
                          </Typography>
                        </CaptionContainer>
                      )}
                      {filters
                        .filter(
                          (filter: FilterProps) =>
                            (filter.group ?? "Selections") === filterGroup.group
                        )
                        .map((filter: FilterProps) => (
                          <InputContainer key={filter.prop}>
                            {filter.type === "text" ? (
                              <TextField
                                id={filter.prop}
                                value={filter.filterState as string}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => filter.setFilterState(e.target.value)}
                                placeholder={filter.placeholder ?? ""}
                              />
                            ) : filter.type === "checkboxes" ? (
                              <CheckboxesFilter
                                filterOptions={
                                  filter?.filterOptions
                                    ? filter.filterOptions
                                    : []
                                }
                                filterState={filter.filterState as string[]}
                                setFilterState={filter.setFilterState}
                              />
                            ) : filter.type === "select" ? (
                              <SelectFilter
                                id={filter.prop}
                                title={filter.title}
                                filterOptions={
                                  filter?.filterOptions
                                    ? filter.filterOptions
                                    : []
                                }
                                filterState={filter.filterState as string}
                                setFilterState={filter.setFilterState}
                                width={filterGroup.width}
                              />
                            ) : filter.type === "multiselect" ? (
                              <MultiselectFilter
                                filterOptions={
                                  filter?.filterOptions
                                    ? filter.filterOptions
                                    : []
                                }
                                filterState={filter.filterState as string[]}
                                setFilterState={filter.setFilterState}
                              />
                            ) : filter.type === "tags" ? (
                              <TagsFilter
                                id={filter.prop}
                                title={filter.title}
                                filterState={filter.filterState as string[]}
                                setFilterState={filter.setFilterState}
                                selectTitle={filter.selectTitle}
                                SelectComponent={filter.SelectComponent}
                                filters={filters}
                              />
                            ) : filter.type === "custom" &&
                              filter.CustomFilterComponent ? (
                              <filter.CustomFilterComponent
                                filter={filter}
                                filters={filters}
                              />
                            ) : null}
                          </InputContainer>
                        ))}
                    </div>
                  ))}
              </FilterColumn>
            ))}
          </FlexContainer>
          <ControlButtonContainer style={{ justifyContent: "center", gap: 20 }}>
            {!!applyConfirmedFilters && <Button type="submit">Apply</Button>}
            <Button
              type="button"
              variant={!applyConfirmedFilters ? "contained" : "outlined"}
              onClick={() => {
                closePopover({
                  save: !applyConfirmedFilters,
                  restore: !!restoreConfirmedFilters,
                });
              }}
            >
              {applyConfirmedFilters ? "Cancel" : "Close"}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={() => resetToDefaults()}
            >
              Reset to defaults
            </Button>
          </ControlButtonContainer>
        </Popover.Content>
      </form>
    </Popover>,
    portalContainer
  );
}

type FilterComponentProps = {
  filters: FilterProps[];
  groupProperties?: FilterGroupPropertiesProps[];
  maxWidth?: number;
  filterKey?: string;
  applyConfirmedFilters?: () => void | undefined;
  restoreConfirmedFilters?: () => void | undefined;
  defaultOpen?: boolean;
  nameFilter?: string;
  nameFilterKey?: string;
  setNameFilter?: React.Dispatch<string>;
  nameFilterPlaceholder?: string;
  combinedCodelistStatuses?: QueryStatus;
  doNotApplyFilters?: boolean;
  filteringDisabled?: boolean;
  nameProperty?: NameProperties;
  serverSide?: boolean;
  hashParts?: HashProps;
};

export default function Filters({
  filters,
  groupProperties,
  maxWidth,
  filterKey,
  nameFilterKey,
  applyConfirmedFilters,
  restoreConfirmedFilters,
  defaultOpen,
  nameFilter,
  setNameFilter,
  nameFilterPlaceholder,
  combinedCodelistStatuses,
  doNotApplyFilters,
  filteringDisabled,
  nameProperty,
  serverSide,
  hashParts,
}: FilterComponentProps) {
  return filters.length > 0 ? (
    <FiltersDefined
      filters={filters}
      groupProperties={groupProperties}
      maxWidth={maxWidth}
      filterKey={filterKey}
      nameFilterKey={nameFilterKey}
      applyConfirmedFilters={applyConfirmedFilters}
      restoreConfirmedFilters={restoreConfirmedFilters}
      defaultOpen={defaultOpen}
      nameFilter={nameFilter}
      setNameFilter={setNameFilter}
      nameFilterPlaceholder={nameFilterPlaceholder}
      combinedCodelistStatuses={combinedCodelistStatuses}
      doNotApplyFilters={doNotApplyFilters}
      filteringDisabled={filteringDisabled}
      nameProperty={nameProperty}
      serverSide={serverSide}
      hashParts={hashParts}
    />
  ) : null;
}

type FilterClosePopover = (arg0?: {
  save?: boolean | undefined;
  restore?: boolean | undefined;
}) => void;

export const areFiltersInDefaultState = (filters: FilterProps[]) =>
  filters.reduce(
    (a, c) =>
      a &&
      (Array.isArray(c.filterState) && Array.isArray(c.defaultFilterState)
        ? c.filterState.join() === c.defaultFilterState.join()
        : c.filterState === (c.defaultFilterState ?? ""))
        ? true
        : false,
    true
  );

function FiltersDefined({
  filters,
  groupProperties,
  maxWidth,
  filterKey,
  nameFilterKey,
  applyConfirmedFilters,
  restoreConfirmedFilters,
  defaultOpen,
  nameFilter,
  setNameFilter,
  nameFilterPlaceholder,
  combinedCodelistStatuses,
  doNotApplyFilters,
  filteringDisabled,
  nameProperty,
  serverSide,
  hashParts,
}: FilterComponentProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const settings = useSettings();
  const { updateSetting } = useUpdateSetting();
  const [currentName, setCurrentName] = useState<string>(nameFilter ?? "");

  useEffect(() => {
    // setTimeout needed for correct Popover position
    defaultOpen && setTimeout(() => setIsOpen(true), 0);
    // Run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filters.forEach((filter) => {
      // @experiment
      // No stored setting load when there are hashParts for now.
      if (!hashParts || Object.keys(hashParts).length === 0) {
        const storedSetting = settings[[filterKey, filter.prop].join()];
        const filterStateString =
          typeof filter.filterState === "string"
            ? filter.filterState
            : filter.filterState.join();
        if (storedSetting !== filterStateString) {
          if (storedSetting) {
            filter.setFilterState(
              filter.isArray
                ? storedSetting.split(",").filter((e) => !!e)
                : storedSetting
            );
          } else {
            filter.setFilterState(
              filter.defaultFilterState ?? (filter.isArray ? [] : "")
            );
          }
        }
      }
    });

    // This effect needs to run when the filterKey changes,
    // and not when the filters change, hence filters are omitted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey]);

  const closePopover: FilterClosePopover = (props?: {
    save?: boolean;
    restore?: boolean;
  }) => {
    const { save, restore } = props ?? {};
    setIsOpen(false);
    if (!!restore) {
      setCurrentName(nameFilter ?? "");
      restoreConfirmedFilters && restoreConfirmedFilters();
    }

    // Filters are only saved if filterKey is provided.
    if (!!filterKey && !!save) {
      filters.forEach((filter) => {
        const filterStateString =
          typeof filter.filterState === "string"
            ? filter.filterState
            : filter.filterState.join();
        const settingKey = [filterKey, filter.prop].join();
        const setting = settings[settingKey];
        if (
          setting !== filterStateString &&
          (setting !== undefined || filterStateString !== "")
        ) {
          updateSetting({
            key: settingKey,
            value: filterStateString,
          });
        }
      });
      nameFilterKey &&
        currentName !== nameFilter &&
        updateSetting({
          key: nameFilterKey,
          value: currentName ?? "",
        });
    }
  };

  const togglePopover = () =>
    isOpen
      ? closePopover({
          save: !applyConfirmedFilters,
          restore: !!restoreConfirmedFilters,
        })
      : setIsOpen(true);

  const isDefault = useMemo(
    () =>
      areFiltersInDefaultState(filters) &&
      (!applyConfirmedFilters || nameFilter === ""),
    [applyConfirmedFilters, filters, nameFilter]
  );

  /* `loaded` is to track the point in time when the assets first needed are loaded for the first time.
      It can happen that subsequent codelists are later loaded (e.g. SubsegmentFilter), but the Filter Popover
      should not be hidden then. */
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (
      !combinedCodelistStatuses ||
      (combinedCodelistStatuses &&
        (combinedCodelistStatuses === "success" ||
          combinedCodelistStatuses === "idle"))
    ) {
      setLoaded(true);
    }
  }, [combinedCodelistStatuses]);

  return filters.length > 0 ? (
    <>
      <IconButton
        title="Filters"
        iconData={isDefault ? filter_alt : filter_alt_active}
        ref={anchorRef}
        variant="outlined"
        onClick={togglePopover}
        disabled={!loaded}
      />
      {loaded && (
        <FilterPopover
          filters={filters}
          groupProperties={groupProperties}
          maxWidth={maxWidth}
          anchorRef={anchorRef}
          isOpen={isOpen}
          closePopover={closePopover}
          applyConfirmedFilters={applyConfirmedFilters}
          restoreConfirmedFilters={restoreConfirmedFilters}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          nameFilterPlaceholder={nameFilterPlaceholder}
          currentName={currentName}
          setCurrentName={setCurrentName}
          doNotApplyFilters={doNotApplyFilters}
          filteringDisabled={filteringDisabled}
          nameProperty={nameProperty}
          serverSide={serverSide}
          nameFilterKey={nameFilterKey}
        />
      )}
    </>
  ) : null;
}

function CheckboxesFilter({
  filterOptions,
  filterState,
  setFilterState,
}: {
  filterOptions: string[] | { key: string; title: string | JSX.Element }[];
  filterState: string[];
  setFilterState: Dispatch<SetStateAction<string[]>>;
}) {
  return (
    <UnstyledList style={{ marginLeft: "-12px" }}>
      {filterOptions.map((option) => {
        const key = typeof option === "string" ? option : option.key;
        const title = typeof option === "string" ? option : option.title;
        return (
          <li key={key}>
            <Checkbox
              // ignore needed because label does not accept JSX by type definition, although it works
              // @ts-ignore
              label={title}
              value={key}
              checked={filterState.includes(key)}
              onChange={() =>
                filterState.includes(key)
                  ? setFilterState(filterState.filter((i: string) => i !== key))
                  : setFilterState(filterState.concat(key))
              }
            />
          </li>
        );
      })}
    </UnstyledList>
  );
}

function SelectFilter({
  filterOptions,
  filterState,
  setFilterState,
  id,
  title,
  width,
}: {
  filterOptions: string[] | { key: string; title: string | JSX.Element }[];
  filterState: string;
  setFilterState: Dispatch<SetStateAction<string>>;
  id: string;
  title: string;
  width?: number;
}) {
  return (
    <NativeSelect
      id={id}
      label={title}
      value={filterState}
      onChange={(e) => {
        setFilterState(e.target.value);
      }}
      style={{ width: width ?? 200 }}
    >
      <option value="" key="">
        *
      </option>
      {filterOptions.map((option) => {
        const key = typeof option === "string" ? option : option.key;
        const title = typeof option === "string" ? option : option.title;
        return (
          <option key={key} value={key}>
            {title}
          </option>
        );
      })}
    </NativeSelect>
  );
}

const columns = [{ key: "title", title: "", width: "100%" }];

function MultiselectFilter({
  filterOptions,
  filterState,
  setFilterState,
}: {
  filterOptions: string[] | { key: string; title: string | JSX.Element }[];
  filterState: string[];
  setFilterState: Dispatch<SetStateAction<string[]>>;
}) {
  const { selectionDispatch, selection } = useTableSelect({
    selectionMode: "multi",
    selection: filterState,
    setState: setFilterState,
  });

  return (
    <SmallTableContainer style={{ width: 300, height: 300 }}>
      <Table
        density="compact"
        items={filterOptions}
        itemIdProp="key"
        columns={columns}
        selectionMode="multi"
        selection={selection}
        selectionDispatch={selectionDispatch}
        fullRowSelect={true}
        headerRowSelectAll={true}
        noFilteredItemMessage=""
        nonVirtual={true}
      />
    </SmallTableContainer>
  );
}

export const ChipContainer = styled.div`
  background: var(--fadedBg);
  padding: 8px;
  width: 200px;
  border-bottom: 1px solid var(--text);
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  min-height: 1.5em;
`;

function TagsFilter({
  filterState,
  setFilterState,
  id,
  title,
  selectTitle,
  SelectComponent,
  filters,
}: {
  filterState: string[];
  setFilterState: Dispatch<string[]>;
  id: string;
  title: string;
  selectTitle?: string;
  SelectComponent: React.ComponentType<SelectComponentProps> | undefined;
  filters: FilterProps[];
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <Button
          type="button"
          variant="outlined"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {selectTitle}
        </Button>
      </div>
      <ChipContainer>
        {Array.isArray(filterState) &&
          filterState
            .filter((e) => !!e)
            .map((tag) => (
              <Chip
                key={tag}
                variant="default"
                onDelete={() =>
                  setFilterState(
                    filterState.filter((e) => {
                      return e !== tag;
                    })
                  )
                }
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                {tag}
              </Chip>
            ))}
      </ChipContainer>
      {SelectComponent && (
        <SelectComponent
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          filterState={filterState}
          setFilterState={setFilterState}
          filters={filters}
        />
      )}
    </>
  );
}
