import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { getApiUrl } from "queries/queryUtil";

type SettingsReply = { getUserSettings: { Key: string; Value: string }[] };

export type Settings = { [k: string]: string | null };

export function useSettingsQuery() {
  return useQuery<SettingsReply, AxiosError<Error>>(
    ["settings"],
    async () => {
      const { data } = await axios.get<SettingsReply>(
        getApiUrl("users/settings/key")
      );
      return data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}
