import {
  Button,
  Icon,
  Menu,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import {
  IconData,
  add,
  assignment,
  block,
  close,
  delete_multiple,
  download,
  external_link,
  file,
  image,
  language,
  library_pdf,
  link,
  microsoft_excel,
  microsoft_powerpoint,
  microsoft_word,
  more_vertical,
  refresh,
  reply,
  sheet_bottom_position,
  sheet_rightposition,
  unfold_less,
  unfold_more,
  visibility,
} from "@equinor/eds-icons";
import {
  FlexContainer,
  FlexElement,
  HeaderButtonContainer,
  TotalCenter,
} from "components/Components";
import { ContextMenu } from "components/ContextMenu";
import { ConfirmListContainer } from "components/Modal";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import { IconColumn } from "components/table/Columns";
import { HeaderProgress } from "components/table/HeaderProgress";
import { useTable } from "components/table/useTable";
import { useTableSelect } from "components/table/useTableSelect";
import React, { useCallback, useMemo, useState } from "react";
import { QueryStatus, useQueryClient } from "react-query";
import * as Space from "react-spaces";
import styled from "styled-components";
import { successMessage } from "utils/successMessage";
import { DocumentSpaceCreate, DocumentSpaceEdit } from "./CreateEdit";
import { CreateNewMenu } from "./CreateNewMenu";
import { FileRowMenu } from "./FileRowMenu";
import { FolderBreadcrumbs } from "./FolderBreadcrumbs";
import { Relocate } from "./Relocate";
import { CreateDocumentType } from "./queries/useCreateDocument";
import { useDeleteDocument } from "./queries/useDeleteDocument";
import { DocumentListItem, useDocumentList } from "./queries/useDocumentList";
import {
  DocumentSpaceItem,
  useDocumentSpace,
} from "./queries/useDocumentSpace";
import {
  RelocateObject,
  UseObjectRelocate,
  useObjectRelocate,
} from "./queries/useObjectRelocate";
import { UseObjectSort, useObjectSort } from "./queries/useObjectSort";
import { UseVisibleOnWeb, useVisibleOnWeb } from "./queries/useVisibleOnWeb";
import { useDirectoryTree } from "./useDirectoryTree";
import { useFileUrl } from "./useFileUrl";

const PanelContainer = styled.div`
  position: absolute;
  inset: 0 10px;
`;

const imageExtensions = [
  "JPG",
  "PEG",
  "PNG",
  "BMP",
  "GIF",
  "SVG",
  "TIF",
  "IFF",
  "RAW",
  "WEBP",
];

const officeExtensions = {
  word: ["DOC", "OCX"],
  excel: ["XLS", "LSX"],
  powerpoint: ["PPT", "PTX"],
};

const textExtensions = ["TXT", "LOG", "PDF"];

export const documentTypesWithFile = ["C", "D"];

export const previewableExtensions = [...imageExtensions, ...textExtensions];

const previewStyles = {
  border: "1px solid var(--borderDefault)",
  flex: 1,
  height: "100%",
};

const fileIconData = (item: DocumentListItem): IconData =>
  item.DocumentType === "C"
    ? assignment
    : officeExtensions.word.includes(item.DocumentFormat)
    ? microsoft_word
    : officeExtensions.excel.includes(item.DocumentFormat)
    ? microsoft_excel
    : officeExtensions.powerpoint.includes(item.DocumentFormat)
    ? microsoft_powerpoint
    : item.DocumentFormat === "PDF"
    ? library_pdf
    : imageExtensions.includes(item.DocumentFormat)
    ? image
    : item.DocumentType === "D"
    ? file
    : item.DocumentType === "U"
    ? link
    : file;

const TypeIconColumn = ({
  item,
  iconFunc,
}: {
  item: DocumentListItem;
  iconFunc: (item: DocumentListItem) => IconData;
}) => (
  <Icon
    data={iconFunc(item)}
    size={18}
    style={{ marginRight: -4 }}
    color="var(--textSemiLight)"
  />
);

export type DeleteItemFunc = (
  item: DocumentListItem | DocumentSpaceItem
) => Promise<void>;

const ViewCommand = ({
  item,
  setPaneDocument,
  setPaneCacheBust,
}: { item: DocumentListItem } & Partial<DocumentSpaceContextData>) => {
  const { getFileUrl } = useFileUrl();

  return documentTypesWithFile.includes(item.DocumentType) ? (
    previewableExtensions.includes(item.DocumentFormat) ? (
      <Button
        title="View in pane"
        variant="ghost_icon"
        onClick={() => {
          setPaneDocument && setPaneDocument(item);
          setPaneCacheBust && setPaneCacheBust(new Date().toString());
        }}
      >
        <Icon data={visibility} />
      </Button>
    ) : (
      <Button
        title="Download / Open"
        variant="ghost_icon"
        disabled={!getFileUrl}
        onClick={() => {
          window.open(getFileUrl(item), "_blank");
        }}
      >
        <Icon data={download} />
      </Button>
    )
  ) : item.DocumentType === "U" ? (
    <Button
      title="Open URL in new window"
      variant="ghost_icon"
      onClick={() => window.open(item.URLAddress, "_blank")}
    >
      <Icon data={external_link} />
    </Button>
  ) : null;
};
const DocumentSpaceHeader = styled.div`
  padding: 20px 0 16px;
`;

const PaneHeader = styled.div`
  margin: 6px 0;
`;

const PaneTitle = styled(Typography)`
  padding: 2px 6px;
`;

export const DirectoryElement = styled.div`
  white-space: nowrap;
`;

export type DocumentSpaceContextData = {
  deleteItem: DeleteItemFunc;
  setNewObjectTargetOID: React.Dispatch<number>;
  setCreateObjectOpen: React.Dispatch<boolean>;
  setCreateDocumentType: React.Dispatch<CreateDocumentType>;
  editItem: number;
  setEditItem: React.Dispatch<number>;
  editOpen: boolean;
  setEditOpen: React.Dispatch<boolean>;
  setPaneDocument: React.Dispatch<DocumentListItem>;
  setPaneCacheBust: React.Dispatch<string>;
  mutateObjectSort: UseObjectSort["mutate"];
  mutateObjectSortStatus: QueryStatus;
  mutateVisibleOnWeb: UseVisibleOnWeb["mutate"];
  folderData?: DocumentSpaceItem[];
  filesData?: DocumentListItem[];
  relocateOpen: boolean;
  setRelocateOpen: React.Dispatch<boolean>;
  relocateItems: RelocateObject[];
  setRelocateItems: React.Dispatch<RelocateObject[]>;
  mutateObjectRelocate: UseObjectRelocate["mutate"];
  oid?: number;
};

export const DocumentSpace: React.FC = () => {
  const [createObjectOpen, setCreateObjectOpen] = useState(false);
  const [createDocumentType, setCreateDocumentType] =
    useState<CreateDocumentType>("F");
  const [newObjectTargetOID, setNewObjectTargetOID] = useState<number>(0);
  const [editItem, setEditItem] = useState(0);
  const [editOpen, setEditOpen] = useState(false);
  const [relocateOpen, setRelocateOpen] = useState(false);
  const [relocateItems, setRelocateItems] = useState<RelocateObject[]>([]);

  const {
    mutate: mutateDelete,
    status: mutateDeleteStatus,
    error: mutateDeleteError,
    reset: mutateDeleteReset,
    data: mutateDeleteData,
  } = useDeleteDocument();

  const {
    mutate: mutateObjectSort,
    status: mutateObjectSortStatus,
    error: mutateObjectSortError,
    reset: mutateObjectSortReset,
  } = useObjectSort();

  const {
    mutate: mutateVisibleOnWeb,
    status: mutateVisibleOnWebStatus,
    error: mutateVisibleOnWebError,
    reset: mutateVisibleOnWebReset,
    data: mutateVisibleOnWebData,
  } = useVisibleOnWeb();

  const {
    mutate: mutateObjectRelocate,
    status: mutateObjectRelocateStatus,
    error: mutateObjectRelocateError,
    reset: mutateObjectRelocateReset,
    data: mutateObjectRelocateData,
  } = useObjectRelocate();

  const { isConfirmed } = useConfirm();

  const deleteItem = useCallback(
    async (item: DocumentListItem | DocumentSpaceItem) => {
      (await isConfirmed(
        <>
          Are you sure you want to delete {item.Description}
          {item.DocumentType === "F" ||
          item.DocumentType === "N" ||
          item.DocumentType === "P" ||
          item.DocumentType === "G"
            ? " and all of its contents"
            : ""}
          ?
        </>,
        { buttonColor: "danger" }
      )) && mutateDelete({ objects: [{ oid: item.OID }] });
    },
    [isConfirmed, mutateDelete]
  );

  const [paneDocument, setPaneDocument] = useState<null | DocumentListItem>(
    null
  );
  const [paneCacheBust, setPaneCacheBust] = useState("");
  const [panePosition, setPanePosition] = useState<"bottom" | "right">(
    "bottom"
  );

  const query = useDocumentSpace();

  const contextData = useMemo(
    () => ({
      deleteItem,
      setCreateObjectOpen,
      setNewObjectTargetOID,
      editItem,
      setEditItem,
      editOpen,
      setEditOpen,
      setPaneDocument,
      setCreateDocumentType,
      setPaneCacheBust,
      mutateObjectSort,
      mutateObjectSortStatus,
      mutateVisibleOnWeb,
      relocateOpen,
      setRelocateOpen,
      relocateItems,
      setRelocateItems,
      mutateObjectRelocate,
    }),
    [
      deleteItem,
      editItem,
      editOpen,
      mutateObjectRelocate,
      mutateObjectSort,
      mutateObjectSortStatus,
      mutateVisibleOnWeb,
      relocateItems,
      relocateOpen,
    ]
  );

  const {
    selection,
    selectedLine,
    content,
    expandAll,
    collapseAll,
    selectionParents,
    getParents,
    openItems,
  } = useDirectoryTree({
    query,
    contextData,
    controlHeader: (
      <DocumentSpaceHeader>
        <FlexContainer>
          <FlexElement>
            <Typography variant="h2">Document Space</Typography>
          </FlexElement>
          <FlexElement>
            <HeaderButtonContainer style={{ margin: 0 }}>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  collapseAll();
                }}
                title="Collapse all"
              >
                <Icon data={unfold_less} />
              </Button>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  expandAll();
                }}
                title="Expand all"
              >
                <Icon data={unfold_more} />
              </Button>
            </HeaderButtonContainer>
          </FlexElement>
        </FlexContainer>
      </DocumentSpaceHeader>
    ),
  });

  const validDocumentTypes = useMemo(
    () => selectedLine?.ValidDocumentTypes.split(",") || [],
    [selectedLine]
  );

  const { data: foldersData, isRefetching } = query;

  const oid = Number(selection[0]) ?? 0;

  const filesQuery = useDocumentList({ oid });

  const { isRefetching: filesIsRefetching } = filesQuery;

  const { data: filesData } = filesQuery;

  const {
    selection: fileSelection,
    selectionDispatch,
    selectionMode,
  } = useTableSelect({ selectionMode: "multi" });

  const queryClient = useQueryClient();

  const DocumentSpaceControlHeader = () => {
    return (
      <DocumentSpaceHeader>
        <FlexContainer flexStart style={{ flexWrap: "nowrap" }}>
          <HeaderButtonContainer style={{ marginLeft: 10 }}>
            <ContextMenu
              buttonContent={<Icon data={more_vertical} />}
              disabled={fileSelection.length === 0}
              placement="bottom-start"
            >
              <Menu.Item
                onClick={async () => {
                  (await isConfirmed(
                    <>
                      Are you sure you want to delete these items?
                      <ConfirmListContainer>
                        {fileSelection.map((s) => (
                          <li>
                            {
                              filesData?.find((e) => e.OID === Number(s))
                                ?.Description
                            }
                          </li>
                        ))}
                      </ConfirmListContainer>
                    </>,
                    { buttonColor: "danger" }
                  )) &&
                    mutateDelete({
                      objects: fileSelection.map((s) => ({ oid: Number(s) })),
                    });
                }}
              >
                <Icon data={delete_multiple} /> Delete selected
              </Menu.Item>
              <Menu.Item
                disabled={fileSelection.length === 0}
                onClick={() => {
                  setRelocateItems(
                    fileSelection.map((s) => ({ oid: Number(s) }))
                  );
                  setRelocateOpen(true);
                }}
              >
                <Icon data={reply} style={{ transform: "rotate(180deg)" }} />{" "}
                Relocate selected
              </Menu.Item>
            </ContextMenu>
          </HeaderButtonContainer>
          <div
            style={{
              marginLeft: 12,
              marginRight: 12,
              overflow: "hidden",
            }}
          >
            <FolderBreadcrumbs
              targetItem={selectedLine}
              targetParentItems={selectionParents}
            />
          </div>
          <HeaderButtonContainer
            style={{ marginLeft: "auto", flexWrap: "nowrap" }}
          >
            <div>
              <HeaderProgress
                isRefetching={isRefetching || filesIsRefetching}
              />
            </div>
            <div>
              <Tooltip title="Reload">
                <Button
                  onClick={() => {
                    queryClient.refetchQueries(["document-space"]);
                  }}
                  variant="ghost_icon"
                >
                  <Icon data={refresh} />
                </Button>
              </Tooltip>
            </div>
            <div>
              <ContextMenu
                buttonContent={<Icon data={add} />}
                disabled={selection.length === 0}
                tooltipTitle="Create new..."
              >
                <CreateNewMenu
                  selection={selection}
                  setCreateDocumentType={setCreateDocumentType}
                  setNewObjectTargetOID={setNewObjectTargetOID}
                  setCreateObjectOpen={setCreateObjectOpen}
                  validDocumentTypes={validDocumentTypes}
                />
              </ContextMenu>
            </div>
          </HeaderButtonContainer>
        </FlexContainer>
      </DocumentSpaceHeader>
    );
  };

  const { content: filesContent } = useTable({
    query: filesQuery,
    columns: [
      {
        key: "DocumentType",
        title: "",
        Component: TypeIconColumn,
        type: "with-context",
        componentProps: {
          iconFunc: fileIconData,
        },
        style: { paddingRight: 4, paddingLeft: 0 },
        width: 16,
      },
      { key: "Description", title: "Description", width: "100%" },
      { key: "FileSizeKB", title: "Size (kB)" },
      { key: "LastModified", title: "Last Modified" },
      {
        key: "VisibleOnWeb",
        title: "VoW",
        longTitle: "Visible on Web",
        Component: IconColumn,
        type: "with-context",
        componentProps: {
          prop: "VisibleOnWeb",
          icon: language,
          falseIcon: block,
          title: "Visible",
          falseTitle: "Not visible",
        },
      },
    ],
    bottomBorder: !!paneDocument,
    title: "",
    density: "compact",
    message: selection.length === 0 ? "Select a folder." : "",
    RowMenu: FileRowMenu,
    Commands: [ViewCommand],
    selection: fileSelection,
    selectionDispatch,
    selectionMode,
    itemIdProp: "OID",
    contextData: {
      deleteItem,
      setPaneDocument,
      setPaneCacheBust,
      setEditItem,
      setEditOpen,
      mutateObjectSort,
      mutateObjectSortStatus,
      filesData,
      oid,
      relocateOpen,
      setRelocateOpen,
      relocateItems,
      setRelocateItems,
      mutateObjectRelocate,
    },
    controlHeader: <DocumentSpaceControlHeader />,
  });

  const PaneElement =
    panePosition === "bottom" ? Space.BottomResizable : Space.RightResizable;

  const { getFileUrl } = useFileUrl();

  return (
    <>
      <Space.Fill>
        <Space.Fill>
          <Space.LeftResizable size={500}>
            <PanelContainer style={{ left: 20 }}>{content}</PanelContainer>
          </Space.LeftResizable>
          <Space.Fill>
            <Space.Fill>
              <PanelContainer style={{ right: 20 }}>
                {filesContent}
              </PanelContainer>
            </Space.Fill>
            {paneDocument !== null && (
              <PaneElement size={500} key={paneCacheBust}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100% - 10px)",
                    marginLeft: 10,
                    marginRight: 20,
                  }}
                >
                  <div>
                    <PaneHeader>
                      <FlexContainer>
                        <FlexElement>
                          <PaneTitle variant="h4">
                            View of {paneDocument.Description}
                          </PaneTitle>
                        </FlexElement>
                        <HeaderButtonContainer>
                          <Button
                            variant="ghost_icon"
                            onClick={() =>
                              setPanePosition(
                                panePosition === "bottom" ? "right" : "bottom"
                              )
                            }
                          >
                            <Icon
                              data={
                                panePosition === "bottom"
                                  ? sheet_rightposition
                                  : sheet_bottom_position
                              }
                              size={16}
                            />
                          </Button>
                          <Button
                            variant="ghost_icon"
                            onClick={() => {
                              setPaneDocument(null);
                            }}
                            title="Close Pane"
                          >
                            <Icon data={close} size={16} />
                          </Button>
                        </HeaderButtonContainer>
                      </FlexContainer>
                    </PaneHeader>
                  </div>
                  {imageExtensions.includes(paneDocument.DocumentFormat) ? (
                    <div
                      style={{
                        ...previewStyles,
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        overflow: "auto",
                      }}
                    >
                      <img src={getFileUrl(paneDocument)} alt="" />
                    </div>
                  ) : textExtensions.includes(paneDocument.DocumentFormat) ? (
                    <iframe
                      style={previewStyles}
                      src={getFileUrl(paneDocument)}
                      title="Pane"
                      key={paneCacheBust}
                    ></iframe>
                  ) : (
                    <TotalCenter>
                      <p>
                        Preview not available for {paneDocument.DocumentFormat}{" "}
                        files.
                      </p>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          window.open(getFileUrl(paneDocument), "_blank")
                        }
                      >
                        Download / Open in new window
                      </Button>
                    </TotalCenter>
                  )}
                </div>
              </PaneElement>
            )}
          </Space.Fill>
        </Space.Fill>
      </Space.Fill>
      <StatusModal
        status={mutateDeleteStatus}
        error={mutateDeleteError}
        successMessage={successMessage(mutateDeleteData)}
        onSettledClose={mutateDeleteReset}
      />
      <StatusModal
        status={mutateObjectSortStatus}
        error={mutateObjectSortError}
        onSuccess={mutateObjectSortReset}
        onSettledClose={mutateObjectSortReset}
      />
      <StatusModal
        status={mutateVisibleOnWebStatus}
        error={mutateVisibleOnWebError}
        onSettledClose={mutateVisibleOnWebReset}
        successMessage={successMessage(mutateVisibleOnWebData)}
      />
      <StatusModal
        status={mutateObjectRelocateStatus}
        error={mutateObjectRelocateError}
        onSettledClose={mutateObjectRelocateReset}
        successMessage={successMessage(mutateObjectRelocateData)}
        onSuccessClose={() => setRelocateOpen(false)}
      />
      <DocumentSpaceCreate
        parentOID={newObjectTargetOID}
        foldersData={foldersData}
        open={createObjectOpen}
        setOpen={setCreateObjectOpen}
        documentType={createDocumentType}
        getParents={getParents}
      />
      <DocumentSpaceEdit
        oid={editItem}
        open={editOpen}
        foldersData={foldersData}
        setOpen={setEditOpen}
        getParents={getParents}
        setPaneCacheBust={setPaneCacheBust}
      />
      <Relocate
        contextData={{ ...contextData, filesData }}
        openItems={openItems}
      />
    </>
  );
};
