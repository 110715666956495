import { useEffect, useLayoutEffect, useState } from "react";
import Table, { SmallTableContainer } from "../../../components/table/Table";
import {
  usePipeElementGroupsQuery,
  usePipeElementsQuery,
} from "../queries/usePipeElementsQuery";
import { useAppSelector } from "../../../app/hooks";
import { selectMainPlant } from "../../plant/plantSlice";
import {
  FlexContainer,
  FlexElement,
  InlineBlock,
  StatusChip,
} from "../../../components/Components";
import {
  CircularProgress,
  NativeSelect,
  Typography,
} from "@equinor/eds-core-react";
import { useTableSelect } from "../../../components/table/useTableSelect";
import { Statuses } from "../../../components/StatusModal";
import { combineQueryStatuses } from "../../../queries/queryUtil";
import { QueryStatus } from "react-query";
import { useMemoOne as useMemo } from "use-memo-one";

export const PEProductFormLabel = "Type";

const pipeElementColumns = [
  { key: "ElementID", title: "ID", minWidth: 65, width: "10%" },
  {
    key: "DimensionStandard",
    title: "Dim. Standard",
    longTitle: "Dimension Standard",
    width: "16%",
  },
  { key: "ProductForm", title: PEProductFormLabel, width: "20%" },
  { key: "MaterialName", title: "Material Name", width: "16%" },
  { key: "MDS", title: "MDS", minWidth: 76, width: "14%" },
  { key: "MDSArea", title: "Area", minWidth: 70, width: "24%" },
];

const extendedPipeElementColumns = [
  { key: "ElementID", title: "ID", minWidth: 65, width: "10%" },
  {
    key: "MaterialGroup",
    title: "Material Group",
    minWidth: 100,
    width: "10%",
  },
  {
    key: "DimensionStandard",
    title: "Dim. Standard",
    longTitle: "Dimension Standard",
    minWidth: 100,
    width: "15%",
  },
  {
    key: "ProductForm",
    title: PEProductFormLabel,
    minWidth: 100,
    width: "10%",
  },
  { key: "MaterialName", title: "Material Name", minWidth: 100, width: "15%" },
  { key: "MDS", title: "MDS", minWidth: 76, width: "15%" },
  {
    key: "MDSStatus",
    title: "Status",
    longTitle: "MDS Status",
    Component: StatusChip,
    width: 40,
  },
  {
    key: "MDSArea",
    title: "Area",
    longTitle: "MDS Area",
    minWidth: 70,
    width: "12.5%",
  },
  {
    key: "MDSSpecialReq",
    title: "Spec. Req.",
    longTitle: "MDS Special Requirement",
    minWidth: 100,
    width: "12.5%",
  },
  { key: "Priority", title: "Pri.", longTitle: "Priority", width: 20 },
];

export default function usePipeElements({
  existing,
  disableGroupSelector,
  extendedDisplay,
  defaultSelection,
  selectedGroupID,
  defaultMaterialGroup,
  pcs,
  revision,
  validityFilter,
}: {
  existing?: boolean;
  disableGroupSelector?: boolean;
  extendedDisplay?: boolean;
  defaultSelection?: string[];
  selectedGroupID?: number;
  defaultMaterialGroup?: string;
  pcs?: string;
  revision?: string;
  validityFilter?: boolean;
}): {
  selection: string[];
  selectionDispatch: Function;
  groupID: number;
  pipeElementsContent: React.ReactFragment;
  selectedLines: any[];
  status: QueryStatus;
} {
  const mainPlant = useAppSelector(selectMainPlant);
  const [groupID, setGroupID] = useState(0);
  const [productForm, setProductForm] = useState("");
  const [productFormList, setProductFormList] = useState([]);
  const [currentDefaultMaterialGroup, setCurrentDefaultMaterialGroup] =
    useState("");
  const [materialGroup, setMaterialGroup] = useState("");
  const [materialGroupList, setMaterialGroupList] = useState([]);
  const [dimStandard, setDimStandard] = useState("");
  const [dimStandardList, setDimStandardList] = useState([]);
  const [materialName, setMaterialName] = useState("");
  const [materialNameList, setMaterialNameList] = useState([]);
  const [priority, setPriority] = useState(extendedDisplay ? "1" : "");
  const [priorityList, setPriorityList] = useState([]);
  const [mds, setMds] = useState("");
  const [mdsList, setMdsList] = useState([]);

  const {
    data: groupData,
    status: groupStatus,
    error: groupError,
  } = usePipeElementGroupsQuery();

  const {
    data,
    status: elementsStatus,
    error,
    isRefetching,
  } = usePipeElementsQuery({
    plant: mainPlant,
    elementGroupID: groupID,
    existing,
    pcs,
    revision,
    validityFilter,
  });

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(groupStatus, elementsStatus),
    [groupStatus, elementsStatus]
  );

  const items = useMemo(
    () =>
      data?.filter(
        (i: any) =>
          (!productForm || i["ProductForm"] === productForm) &&
          (!materialGroup || i["MaterialGroup"] === materialGroup) &&
          (!dimStandard || i["DimensionStandard"] === dimStandard) &&
          (!materialName || i["MaterialName"] === materialName) &&
          (!priority || String(i["Priority"]) === priority) &&
          (!mds || i["MDS"] === mds)
      ),
    [data, dimStandard, materialGroup, materialName, mds, priority, productForm]
  );
  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: "single",
    selection: defaultSelection,
  });

  useLayoutEffect(() => {
    selectedGroupID && setGroupID(selectedGroupID);
  }, [selectedGroupID]);

  useLayoutEffect(() => {
    const elementsData = data ?? [];
    if (elementsData.length === 0) {
      setProductFormList([]);
      setMaterialGroupList([]);
      setDimStandardList([]);
      setMaterialNameList([]);
      setPriorityList([]);
      setMdsList([]);
    }
    if (elementsData && elementsData.length > 0) {
      setProductFormList(
        elementsData
          .reduce((acc: any, current: any) => {
            return acc.includes(current["ProductForm"])
              ? acc
              : acc.concat(current["ProductForm"]);
          }, [])
          .sort()
      );
      setMaterialGroupList(
        elementsData
          .reduce((acc: any, current: any) => {
            return acc.includes(current["MaterialGroup"])
              ? acc
              : acc.concat(current["MaterialGroup"]);
          }, [])
          .sort()
      );
      setDimStandardList(
        elementsData
          .reduce((acc: any, current: any) => {
            return acc.includes(current["DimensionStandard"]) ||
              !current["DimensionStandard"]
              ? acc
              : acc.concat(current["DimensionStandard"]);
          }, [])
          .sort()
      );
      setMaterialNameList(
        elementsData
          .reduce((acc: any, current: any) => {
            return acc.includes(current["MaterialName"]) ||
              !current["MaterialName"]
              ? acc
              : acc.concat(current["MaterialName"]);
          }, [])
          .sort()
      );
      setPriorityList(
        elementsData
          .reduce((acc: any, current: any) => {
            return acc.includes(current["Priority"]) || !current["Priority"]
              ? acc
              : acc.concat(current["Priority"]);
          }, [])
          .sort()
      );
      setMdsList(
        elementsData
          .reduce((acc: any, current: any) => {
            return acc.includes(current["MDS"]) || !current["MDS"]
              ? acc
              : acc.concat(current["MDS"]);
          }, [])
          .sort()
      );
    }
  }, [
    productForm,
    materialGroup,
    dimStandard,
    materialName,
    priority,
    mds,
    groupID,
    data,
  ]);

  useEffect(() => {
    const elementsData = data ?? [];
    if (elementsData.length > 0 && defaultMaterialGroup) {
      const materialGroups = elementsData.map(
        (e) => e["MaterialGroup"]
      ) as string[];
      setCurrentDefaultMaterialGroup(
        materialGroups.includes(defaultMaterialGroup)
          ? defaultMaterialGroup
          : ""
      );
    }
  }, [data, defaultMaterialGroup]);

  useEffect(() => {
    setProductForm("");
    setMaterialGroup(currentDefaultMaterialGroup);
    setDimStandard("");
    setMaterialName("");
    setPriority(extendedDisplay ? "1" : "");
    setMds("");
  }, [groupID, currentDefaultMaterialGroup, extendedDisplay]);

  let pipeElementsContent = <></>;

  if (mainPlant.PlantID === 0) {
    pipeElementsContent = <>No plant selected.</>;
  } else if (groupStatus === "error" || elementsStatus === "error") {
    console.error(error, groupError);
    pipeElementsContent = <Typography color="danger">Error.</Typography>;
  } else {
    pipeElementsContent = (
      <SmallTableContainer
        style={{
          maxWidth: extendedDisplay ? "none" : 1000,
          ...(extendedDisplay && { minHeight: 320 }),
        }}
      >
        <Table
          density="compact"
          message={groupID === 0 ? "Select an element group." : undefined}
          items={items}
          itemIdProp="ElementID"
          loadingString="Loading Pipe Elements..."
          controlHeader={
            <ControlHeader
              groupData={groupData}
              groupID={groupID}
              setGroupID={setGroupID}
              productFormList={productFormList}
              productForm={productForm}
              setProductForm={setProductForm}
              groupStatus={groupStatus}
              elementsStatus={elementsStatus}
              count={items?.length ?? 0}
              disableGroupSelector={disableGroupSelector}
              isRefetching={isRefetching}
              extendedDisplay={!!extendedDisplay}
              materialGroup={materialGroup}
              setMaterialGroup={setMaterialGroup}
              materialGroupList={materialGroupList}
              dimStandard={dimStandard}
              setDimStandard={setDimStandard}
              dimStandardList={dimStandardList}
              materialName={materialName}
              setMaterialName={setMaterialName}
              materialNameList={materialNameList}
              priority={priority}
              setPriority={setPriority}
              priorityList={priorityList}
              mds={mds}
              setMds={setMds}
              mdsList={mdsList}
            />
          }
          columns={
            extendedDisplay ? extendedPipeElementColumns : pipeElementColumns
          }
          contextData={{}}
          status={combineQueryStatuses(groupStatus, elementsStatus)}
          selectionMode={selectionMode}
          selection={selection}
          selectionDispatch={selectionDispatch}
          sortable={true}
          fullRowSelect={true}
          selectedFirst={!!defaultSelection}
        />
      </SmallTableContainer>
    );
  }

  return {
    pipeElementsContent,
    selection,
    selectionDispatch,
    groupID,
    selectedLines: selection.map((s) =>
      items?.find((i) => String(i["ElementID"]) === s)
    ),
    status: combinedStatuses,
  };
}

function FilterSelect({
  value,
  options,
  setFunction,
  title,
  width,
}: {
  value: string;
  options: string[];
  setFunction: React.Dispatch<string>;
  title: string;
  width?: number | string;
}) {
  return (
    <FlexElement style={{ marginBottom: "12px" }}>
      <NativeSelect
        id={title}
        label={title}
        value={value}
        onChange={(e) => setFunction(e.target.value)}
        style={{ width: width ? width : 160 }}
      >
        <option value="">- All -</option>
        {options &&
          options.map((i) => (
            <option key={i ? i : "_"} value={i}>
              {i}
            </option>
          ))}
      </NativeSelect>
    </FlexElement>
  );
}

function ControlHeader({
  groupData,
  groupID,
  setGroupID,
  productForm,
  setProductForm,
  productFormList,
  groupStatus,
  elementsStatus,
  count,
  disableGroupSelector,
  isRefetching,
  extendedDisplay,
  materialGroup,
  setMaterialGroup,
  materialGroupList,
  dimStandard,
  setDimStandard,
  dimStandardList,
  materialName,
  setMaterialName,
  materialNameList,
  priority,
  setPriority,
  priorityList,
  mds,
  setMds,
  mdsList,
}: {
  groupData: any[] | undefined;
  groupID: number;
  setGroupID: React.Dispatch<number>;
  productForm: string;
  setProductForm: React.Dispatch<string>;
  productFormList: string[];
  groupStatus: Statuses;
  elementsStatus: Statuses;
  count: number;
  disableGroupSelector?: boolean;
  isRefetching: boolean;
  extendedDisplay: boolean;
  materialGroup: string;
  setMaterialGroup: React.Dispatch<string>;
  materialGroupList: string[];
  dimStandard: string;
  setDimStandard: React.Dispatch<string>;
  dimStandardList: string[];
  materialName: string;
  setMaterialName: React.Dispatch<string>;
  materialNameList: string[];
  priority: string;
  setPriority: React.Dispatch<string>;
  priorityList: string[];
  mds: string;
  setMds: React.Dispatch<string>;
  mdsList: string[];
}) {
  return (
    <div style={{ paddingBottom: "1em" }}>
      <FlexContainer
        style={{
          justifyContent: "flex-start",
          marginBottom: "-12px",
          gap: "1em",
        }}
      >
        {!disableGroupSelector && (
          <FlexElement style={{ marginBottom: "12px" }}>
            <NativeSelect
              id="PipeElementSelector"
              label="Element Group"
              value={groupID}
              onChange={(e) => setGroupID(parseInt(e.target.value))}
              style={{ width: 160 }}
            >
              <option value="0">
                {groupStatus === "loading" ? `Loading...` : `- Select group -`}
              </option>
              {groupData &&
                groupData.map((i) => (
                  <option key={i.ElementGroupID} value={i.ElementGroupID}>
                    {i.ElementGroup}
                  </option>
                ))}
            </NativeSelect>
          </FlexElement>
        )}
        {extendedDisplay && (
          <FilterSelect
            value={materialGroup}
            options={materialGroupList}
            setFunction={setMaterialGroup}
            title="Material Group"
            width={120}
          />
        )}
        {extendedDisplay && (
          <FilterSelect
            value={dimStandard}
            options={dimStandardList}
            setFunction={setDimStandard}
            title="Dimension Standard"
            width={130}
          />
        )}
        <FilterSelect
          value={productForm}
          options={productFormList}
          setFunction={setProductForm}
          title={PEProductFormLabel}
          width={160}
        />
        {extendedDisplay && (
          <FilterSelect
            value={materialName}
            options={materialNameList}
            setFunction={setMaterialName}
            title="Material Name"
            width={120}
          />
        )}
        {extendedDisplay && (
          <FilterSelect
            value={mds}
            options={mdsList}
            setFunction={setMds}
            title="MDS"
            width={110}
          />
        )}
        {extendedDisplay && (
          <FilterSelect
            value={priority}
            options={priorityList}
            setFunction={setPriority}
            title="Priority"
            width={85}
          />
        )}
        {elementsStatus === "success" && (
          <FlexElement style={{ marginLeft: "auto", alignSelf: "flex-end" }}>
            <CircularProgress
              size={16}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                marginRight: "12px",
                verticalAlign: "middle",
              }}
            />
            <InlineBlock>
              <Typography variant="overline">
                {count} {count === 1 ? "item" : "items"}
              </Typography>
            </InlineBlock>
          </FlexElement>
        )}
      </FlexContainer>
    </div>
  );
}
