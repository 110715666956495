import { sheetProperties } from "../config/sheetConfig";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { plantDependentSheets, SheetTypes } from "../config/sheetConfig";
import { SheetStatus } from "../config/statusConfig";
import { PlantProps } from "../../plant/types";

export default function useSetSheetStatusMultiple() {
  const queryClient = useQueryClient();

  async function setSheetStatusMultiple({
    plant,
    sheetType,
    status,
    sheets,
    async,
    taskDescription,
    postfix,
  }: {
    plant?: PlantProps;
    sheetType: SheetTypes;
    status: SheetStatus;
    sheets: { name: string; revision: string }[];
    async?: boolean;
    taskDescription?: string;
    postfix?: string;
  }) {
    const { sheetListProperty, nameProperty } = sheetProperties[sheetType];
    return await axios(
      getSheetApiUrl({ plant, sheetType, postfix: postfix ?? "status" }),
      {
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: {
          ...(!postfix ? { Status: status } : {}),
          ...(async && taskDescription
            ? { TaskDescription: taskDescription }
            : {}),
          [sheetListProperty ? sheetListProperty : "sheets"]: sheets.map(
            (e) => ({
              [nameProperty]: e.name,
              Revision: e.revision,
            })
          ),
        },
      }
    );
  }

  return useMutation(setSheetStatusMultiple, {
    onSuccess: (data, variables, context) => {
      if (variables.async) {
        queryClient.invalidateQueries(["vds-task-monitor"]);
      }
      // Although the query might be async, small number of sheets
      // would lead to fast execution times, and the VDS Task Monitor's
      // queryInvalidation would not kick in.
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType) &&
        variables.plant
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type SetSheetStatusMultiple = ReturnType<
  typeof useSetSheetStatusMultiple
>;
