import {
  Button,
  CircularProgress,
  Chip as EDSChip,
  Tabs as EDSTabs,
  Typography as EDSTypography,
  Icon,
  IconProps,
  Label,
  Progress,
} from "@equinor/eds-core-react";
import { TypographyVariants } from "@equinor/eds-core-react/dist/types/components/Typography/Typography.tokens";
import type { IconData } from "@equinor/eds-icons";
import { close } from "@equinor/eds-icons";
import { IssueStatus, issueStatusConfig } from "features/issues/statusConfig";
import { motion } from "framer-motion";
import * as React from "react";
import styled, { css } from "styled-components";
import {
  SheetStatus,
  sheetStatusConfig,
} from "../features/sheets/config/statusConfig";
import { merge } from "../utils/styleMerge";
import { breakpoints } from "./breakpoints";
import { ObjectOfErrors } from "./types";

export const TotalCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

export const TextCenter = styled.div`
  width: 100%;
  text-align: center;
`;

export const FlexContainer = styled.div<{
  vertical?: boolean;
  flexStart?: boolean;
  gap?: boolean | number;
  top?: boolean;
}>(
  ({ vertical, flexStart, gap, top }) => `
  display: flex;
  flex-direction: ${!!vertical ? "column" : "row"};
  justify-content: ${!!flexStart ? "flex-start" : "space-between"};
  flex-wrap: wrap;
  align-items: center;
  ${gap === true ? "gap: 12px" : gap ? `gap: ${gap}px` : ""}
  ${top ? "align-items: flex-start" : ""}
`
);

export const FlexColumnContainer = styled(FlexContainer)<{ gap?: number }>(
  ({ gap }) => `
  gap: ${gap ?? 45}px;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  & > div {
    position: relative;
  }
  & > div:not(:first-child) {
    &:before {
      position: absolute;
      margin-left: -${Math.floor((gap ?? 45) / 2) + 1}px;
      height: 100%;
      width: 1px;
      background: var(--borderDefault);
      content: "";
      display: block;
    }
  }
`
);

export const AbsoluteFlexContainer = styled(FlexContainer)`
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  position: absolute;
  inset: 0;
`;

export const FlexElement = styled.div``;

export const FlexHeaderElement = styled(FlexElement)`
  width: 100%;
`;

export const FlexContentElement = styled(FlexElement)`
  overflow: auto;
  width: 100%;
  flex: 1 1 auto;
  position: relative;
`;

export const FlexColumnElement = styled(FlexElement)`
  min-width: 400px;
  flex: 1;
`;

export const FlexColumnSubElement = styled(FlexElement)`
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0.5em;
  gap: 1em;
`;

export const ControlButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 14px 17px;
  gap: 12px;
`;

export const SimpleButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin: 6px 0;
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
  margin: 0 6px;
`;

export const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FullPageContainer = styled.div`
  padding: 0 1em;
  text-align: center;
`;

export const H1Element = styled.h1`
  margin: 0.3em 0;
  font-size: 26px;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 32px;
  }
`;

export const Loader = ({
  label,
  topLabel,
}: {
  label?: string;
  topLabel?: string | JSX.Element;
}) => {
  return (
    <TotalCenter>
      {topLabel && <div style={{ marginBottom: 12 }}>{topLabel}</div>}
      <CircularProgress />
      {label && (
        <motion.div
          initial={{ opacity: 0.25 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.15 }}
          style={{ marginTop: 18 }}
        >
          <Label label={label} />
        </motion.div>
      )}
    </TotalCenter>
  );
};

export const LinearLoader = ({ label }: { label?: string }) => {
  return (
    <>
      <Progress.Linear />
      {label && (
        <Typography
          variant="caption"
          style={{ textAlign: "center", marginTop: "16px" }}
        >
          {label}
        </Typography>
      )}
    </>
  );
};

export const HeaderContainer = styled.div`
  padding: 1em 1.25em 0.75em;
`;

export const ContentHeader = ({ children }: { children: React.ReactNode }) => (
  <HeaderContainer>
    <Typography variant="h3">{children}</Typography>
  </HeaderContainer>
);

export const ContentMessage = ({
  color,
  children,
}: {
  color?: string;
  children: React.ReactNode;
}) => (
  <HeaderContainer>
    <Typography variant="h4" color={color}>
      {children}
    </Typography>
  </HeaderContainer>
);

export const PreLine = styled.pre`
  white-space: pre-line;
`;

export const MultiColumnList = styled.ul<{ columns?: number }>(
  ({ columns }) => `
  display: grid;
  column-gap: 1em;
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(${columns ? columns : 6}, 1fr);
  }
  li {
    padding-right: 15px;
    &::marker {
      color: gray;
    }
  }
`
);

export const ScrollShadowContainer = styled.div`
  background:
    /* Shadow Cover TOP */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      )
      center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%)
      center bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
`;

export const FlexContentElementShadow = merge(
  FlexContentElement,
  ScrollShadowContainer
);

export const HrLine = styled.hr`
  border: none;
  border-top: 1px solid #e5e5e5;
  margin: 0;
`;

const chipVariantOptions = {
  default: {
    backgroundColor: "var(--transparentBgFaded)",
  },
  active: {
    backgroundColor: "rgba(0,203,61,0.09)",
  },
  error: {
    backgroundColor: "transparent",
  },
};

export const Chip = styled(EDSChip)`
  line-height: 2em;
  min-width: 12px;
  grid-auto-columns: auto;
  word-break: normal;
  text-align: center;
  ${({ variant }) =>
    variant &&
    chipVariantOptions[variant] &&
    chipVariantOptions[variant]["backgroundColor"] &&
    css`
      background-color: ${chipVariantOptions[variant].backgroundColor};
    `}
`;

export function CloseButton({
  closeFn,
  size,
}: {
  closeFn: () => void;
  size?: IconProps["size"];
}) {
  return (
    <Button variant="ghost_icon" onClick={closeFn} aria-label="Close">
      <Icon data={close} size={size ? size : 24} />
    </Button>
  );
}

export const Typography = styled(EDSTypography)``;

export const Container = styled.div`
  margin-block-start: 1em;
  margin-block-end: 1em;
`;

export function InputGroup({
  label,
  input,
}: {
  label: string;
  input: React.ReactNode;
}) {
  return (
    <FlexContainer style={{ justifyContent: "flex-start" }}>
      <FlexElement style={{ paddingRight: ".5em" }}>
        <Typography variant="caption">{label}:</Typography>
      </FlexElement>
      <FlexElement>{input}</FlexElement>
    </FlexContainer>
  );
}

export const InputContainer = styled.div`
  padding: 0.5em 0;
`;

export const TopPadder = styled.div`
  margin-top: 1em;
`;

export const IconButton = React.forwardRef(
  (
    {
      title,
      variant,
      iconData,
      onClick,
      style,
      disabled,
    }: {
      title: string;
      variant: "contained" | "outlined" | "ghost" | "ghost_icon";
      iconData: IconData;
      onClick: Function;
      style?: React.CSSProperties;
      disabled?: boolean;
    },
    ref: React.Ref<any>
  ) => {
    return (
      <Button
        variant={variant}
        onClick={() => onClick()}
        style={style}
        ref={ref}
        disabled={disabled}
      >
        {title}{" "}
        <Icon
          data={iconData}
          size={16}
          style={{ marginRight: "-6px", marginLeft: "-2px" }}
        />
      </Button>
    );
  }
);

export const InlineBlock = styled.div`
  display: inline-block;
`;

export function StatusChip({
  children,
  style,
  type,
}: {
  children: SheetStatus | React.ReactNode;
  style?: React.CSSProperties;
  type?: "sheet" | "issue";
}) {
  return children ? (
    <Chip
      variant={
        children === "W"
          ? "active"
          : ["E", "R", "D"].includes(String(children))
          ? "error"
          : "default"
      }
      style={style}
      title={
        typeof children === "string"
          ? type === "sheet" &&
            Object.keys(sheetStatusConfig).includes(children)
            ? sheetStatusConfig[children as SheetStatus]
            : type === "issue" &&
              Object.keys(issueStatusConfig).includes(children)
            ? issueStatusConfig[children as IssueStatus]
            : Object.keys(sheetStatusConfig).includes(children)
            ? sheetStatusConfig[children as SheetStatus]
            : Object.keys(issueStatusConfig).includes(children)
            ? issueStatusConfig[children as IssueStatus]
            : children
          : String(children)
      }
    >
      {children}
    </Chip>
  ) : (
    <></>
  );
}

export const CenterChildren = styled.div`
  display: flex;
  justify-content: center;
`;

export const RevisionMark = styled.div`
  border: 1px solid var(--borderDefault);
  background: var(--bg);
  color: var(--textSemiLight);
  min-width: 20px;
  padding: 2px 2px 1px;
  text-align: center;
  border-radius: 3px;
  min-height: 20px;
  vertical-align: bottom;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

export const RevisionMarkCompactTable = styled.div`
  border: 1px solid var(--borderDefault);
  background: var(--bg);
  color: var(--textSemiLight);
  min-width: 18px;
  min-height: 18px;
  padding: 3px 2px 0px;
  border-radius: 3px;
  vertical-align: bottom;
  display: inline-flex;
  justify-content: center;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  align-items: center;
  line-height: 18px;
  margin: -1px 0;
`;

export const DateDisplay = styled.span``;

export const Tabs = styled(EDSTabs)`
  div[role="tablist"] {
    display: flex;
    flex-wrap: wrap;
  }
  > div {
    overflow: auto;
  }
`;

export const Fade = styled.span`
  opacity: 0.6;
`;

export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 12px;
`;

export const BottomBorderContainer = styled.div`
  border-bottom: 1px solid var(--borderDefault);
`;

export const InfolineContainerElement = styled.div`
  padding: 2px 14px;
  border-top: 1px solid var(--borderDefault);
  display: flex;
  gap: 1.5em;
  justify-content: flex-end;
`;

export const InfolineContainer = ({
  children,
  typographyVariant,
}: {
  children: React.ReactNode | React.ReactNode[];
  typographyVariant?: TypographyVariants;
}) => {
  return (
    <EDSTypography variant={typographyVariant ?? "overline"} as="div">
      <InfolineContainerElement>{children}</InfolineContainerElement>
    </EDSTypography>
  );
};

export const FlexFormContainer = styled(FlexContainer)`
  justify-content: flex-start;
  gap: 1em;
`;

export const FlexFormContainerVertical = styled(FlexFormContainer)`
  flex-direction: row;
`;

export const PageContainer = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100% - 40px);
  flex: 1;
`;

export const PageTitle = styled(Typography)`
  margin-bottom: 16px;
`;

export const PageContent = styled.div`
  margin: 0 0 0 0;
`;

export const FooterContainer = styled.div`
  margin: 8px 10px;
  width: 100%;
`;

export const DataTable = styled.div<{ columns?: number }>(
  ({ columns }) => `
  display: grid;
  grid-template-columns: repeat(${columns ? columns : 2}, max-content);
  gap: 3px 10px;
`
);

export function naIfNa(content?: string) {
  return content ? content : "N/A";
}

export const Paragraphs = styled.div`
  display: flex;
  gap: 1em;
  flex-direction: column;
  p {
    margin: 0;
  }
`;

export const MessageBox = styled.div`
  background: var(--bg);
  border-radius: 4px;
  padding: 6px 12px;
  margin: 12px 0;
`;

export const ErrorMessage = ({ children }: { children: React.ReactNode }) => {
  return <Typography color="danger">{children}</Typography>;
};

export const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-bottom: 1.5em;
`;

export const ValveTypeCodeContainer = styled.span`
  display: inline-block;
  min-width: 28px;
  padding-right: 6px;
`;

export const ConditionalWrapper = ({
  condition,
  Wrapper,
  wrapperProps,
  children,
}: {
  condition: boolean;
  Wrapper: React.ForwardRefExoticComponent<any>;
  wrapperProps?: object;
  children: React.ReactNode;
}) =>
  condition ? <Wrapper {...wrapperProps}>{children}</Wrapper> : <>{children}</>;

export function hasError(
  error: unknown | Error | null | undefined | ObjectOfErrors
) {
  return (
    error instanceof Error ||
    (typeof error === "object" &&
      error !== null &&
      Object.keys(error).some(
        (errorKey) =>
          error[errorKey as keyof typeof error] !== null &&
          (error[errorKey as keyof typeof error] as any) instanceof Error
      ))
  );
}

export const ErrorDisplay = ({
  error,
  title,
  small,
}: {
  error: unknown | Error | null | undefined | ObjectOfErrors;
  title?: string;
  small?: boolean;
}) => {
  return (
    <div style={{ marginBottom: "1em" }}>
      <Typography
        color="danger"
        variant={small ? "body_short" : "h4"}
        style={{ marginBottom: ".5em" }}
      >
        Error {title}
      </Typography>
      {error && error instanceof Error ? (
        <Typography style={{ marginBottom: ".5em" }}>
          {error.message}
        </Typography>
      ) : (
        typeof error === "object" &&
        error !== null &&
        Object.keys(error)
          .filter(
            (errorKey) =>
              error[errorKey as keyof typeof error] !== null &&
              (error[errorKey as keyof typeof error] as any) instanceof Error
          )
          .map((errorKey) => (
            <Typography style={{ marginBottom: ".5em" }}>
              {errorKey}:{" "}
              {(error[errorKey as keyof typeof error] as Error).message}
            </Typography>
          ))
      )}
    </div>
  );
};

export const Paragraph = styled.p`
  margin: 0.5em 0;
`;

export function getStatusChips(statuses: SheetStatus[]) {
  return statuses.map((status) => {
    return {
      key: status,
      title: (
        <FlexContainer>
          <StatusChip>{status}</StatusChip>{" "}
          <span style={{ marginLeft: ".6em" }}>
            {sheetStatusConfig[status]}
          </span>
        </FlexContainer>
      ),
    };
  });
}
