import { useQuery } from "react-query";
import { getApiUrl } from "./queryUtil";
import axios from "axios";

type MatrixPreviewReturn = {
  getMatrixContent: {
    RowReference: number;
    ColReference: number;
    CellContent: string;
  }[];
};

export function useMatrixPreview({
  id,
  revision,
}: {
  id: string;
  revision: string;
}) {
  return useQuery(["matrix-preview", id, revision], async () => {
    const { data } = await axios.get<MatrixPreviewReturn>(
      getApiUrl(`pmg/matrix-preview/${id}/rev/${revision}`)
    );
    return data.getMatrixContent.reduce(
      (acc, cur) => {
        acc[cur.RowReference] ??= [];
        acc[cur.RowReference][cur.ColReference] = cur.CellContent;
        return acc;
      },
      [[]] as string[][]
    );
  });
}
