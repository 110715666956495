import { Access } from "Access";
import { useParams } from "react-router-dom";
import { MasterPipeElementsList } from "./master-pipe-elements/MasterPipeElementsList";
import { PipeMaterialGroupList } from "./material-groups/PipeMaterialGroupList";
import { MasterMatricesList } from "./matrices/MasterMatricesList";
import { RulesList } from "./rules/RulesList";
import { StructuredPipeElementsList } from "./structured-pipe-elements/StructuredPipeElementsList";
import { Subelements } from "./subelements/Subelements";

export type PMGPages =
  | "material-groups"
  | "master-pipe-elements"
  | "structured-pipe-elements"
  | "pipe-element-articles"
  | "matrices"
  | "article-rules"
  | "weight-rules"
  | "subelements";

export function AccessPMG() {
  const { pmgList } = useParams<{ pmgList: PMGPages }>();
  return (
    <Access cap="sheets" module="sheets">
      {pmgList === "material-groups" ? (
        <Access cap="pmg">
          <PipeMaterialGroupList />
        </Access>
      ) : pmgList === "matrices" ? (
        <Access cap="pmg">
          <MasterMatricesList />
        </Access>
      ) : pmgList === "master-pipe-elements" ? (
        <Access cap="pmg">
          <MasterPipeElementsList />
        </Access>
      ) : pmgList === "article-rules" || pmgList === "weight-rules" ? (
        <Access cap="pmg">
          <RulesList list={pmgList} />
        </Access>
      ) : (
        pmgList === "subelements" && (
          <Access cap="pmg">
            <Subelements />
          </Access>
        )
      )}

      {pmgList === "structured-pipe-elements" && (
        <Access role="U">
          <StructuredPipeElementsList />
        </Access>
      )}
    </Access>
  );
}
