import {
  plantMappingConfig,
  PlantMappingUpdate,
} from "../features/admin/plant-mapping/plantMappingConfig";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import config from "config/config";

export default function useSavePlantMapping() {
  const queryClient = useQueryClient();

  async function saveAdmin({
    remoteSystem,
    data,
  }: {
    remoteSystem: string;
    data: PlantMappingUpdate;
  }) {
    return await axios({
      url: `${config.restApiBaseUrl}/${plantMappingConfig.queryKey.join(
        "/"
      )}/${remoteSystem}`,
      data: JSON.stringify({ [plantMappingConfig.submitProp]: data }),
      method: "PUT",
    });
  }

  return useMutation(saveAdmin, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(plantMappingConfig.queryKey);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
