import { ControlHeaderContainer } from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import { exampleItems, TableExample } from "./TableExample";

export function TableWithSelectionExample() {
  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: "multi",
  });

  return (
    <TableExample
      selectionMode={selectionMode}
      selection={selection}
      selectionDispatch={selectionDispatch}
      controlHeader={
        <ControlHeaderContainer style={{ overflowX: "auto" }}>
          Selection:
          <div>{selection.length ? selection.join() : "None"}</div>
        </ControlHeaderContainer>
      }
      infoline={true}
      totalCount={exampleItems.length}
    />
  );
}
