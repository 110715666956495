import {
  Button,
  CircularProgress,
  Icon,
  NativeSelect,
  Search,
  Typography,
} from "@equinor/eds-core-react";
import { refresh } from "@equinor/eds-icons";
import {
  ErrorDisplay,
  FlexColumnContainer,
  FlexColumnElement,
  FlexContainer,
  FlexElement,
  Loader,
  TotalCenter,
} from "components/Components";
import { SemiBold, SubtitleContainer, SubtitleItem } from "components/Subtitle";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFillScrollable,
  ModalWindowContentFixed,
  ModalWindowDefaultContainer,
} from "components/ModalWindow";
import { NameFilterInfo } from "components/NameFilterInfo";
import { FieldContext } from "components/form/FieldContext";
import Table, {
  ColumnsProps,
  SmallTableContainer,
} from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import {
  VDSItemSections,
  VDSSubsegment,
  VDSSubsegmentDesign,
  VDSSubsegmentMaterial,
  VDSSubsegmentMiscReq,
  VDSTextBlock,
} from "features/sheets/types";
import { VDSSubsegmentGroupsConfig } from "queries/useCodelist";
import useSheet from "features/sheets/queries/useSheet";
import useVDSSubsegmentTextBlock from "features/sheets/queries/useVDSSubsegmentTextBlocks";
import React, { useMemo, useState } from "react";
import {
  FieldValues,
  FormProvider,
  UseFieldArrayInsert,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import { QueryStatus } from "react-query";
import { NameProperties, filterItemsByName } from "utils/filterItemsByName";
import { EditVDSTextBlockTemperatures } from "../vds-textblocks/Temperatures";
import { VDSTextBlockSectionD } from "../vds-textblocks/VDSTextBlockSectionD";
import { VDSTextBlockSectionH1 } from "../vds-textblocks/VDSTextBlockSectionH1";
import { VDSTextBlockSectionH2 } from "../vds-textblocks/VDSTextBlockSectionH2";
import { VDSTextBlockSectionH3 } from "../vds-textblocks/VDSTextBlockSectionH3";
import {
  VDSTextBlockSectionH4,
  ratingMatrixProps,
} from "../vds-textblocks/VDSTextBlockSectionH4";
import { VDSTextBlockSectionM } from "../vds-textblocks/VDSTextBlockSectionM";
import { VDSTextBlockSectionR } from "../vds-textblocks/VDSTextBlockSectionR";
import { DisabledInputContainer } from "./EditVDSSubsegment";

const columns: ColumnsProps[] = [
  { key: "TextBlockName", title: "Text Block Name", width: 170 },
  { key: "TextBlockID", title: "Text Block ID", width: 100 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "Status", title: "Status" },
  { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
  { key: "LastUpdateBy", title: "Last Update By" },
];

function TextBlockPreivew({
  itemSection,
  itemId,
  data,
  status,
  error,
  textBlockId,
}: {
  itemSection: VDSItemSections;
  itemId: number;
  data: VDSTextBlock | undefined;
  status: QueryStatus;
  error: Error | null;
  textBlockId: string | undefined;
}) {
  return (
    // Key makes rerenders whole, otherwise the form fields behave incorrectly.
    <div style={{ minHeight: 100 }} key={String(textBlockId) + status}>
      <FieldContext.Provider value={{ disabled: true }}>
        <DisabledInputContainer>
          {status === "idle" ? (
            <TotalCenter>Text Block Preview</TotalCenter>
          ) : status === "loading" ? (
            <Loader label="Loading Text Block..." />
          ) : status === "error" ? (
            <ErrorDisplay error={error} />
          ) : status === "success" && data ? (
            <>
              <Typography variant="h4" style={{ marginBottom: 12 }}>
                Text Block {data.TextBlockID} rev. {data.Revision}
              </Typography>
              <SubtitleContainer style={{ marginBottom: 24 }}>
                <SubtitleItem>
                  Description:{" "}
                  {data.VDSDescription ? (
                    <SemiBold>{data.VDSDescription}</SemiBold>
                  ) : (
                    <i>empty</i>
                  )}
                </SubtitleItem>
              </SubtitleContainer>
              {itemSection === "H" && itemId === 1 ? (
                <VDSTextBlockSectionH1 textBlockData={data} />
              ) : itemSection === "H" && itemId === 2 ? (
                <VDSTextBlockSectionH2 textBlockData={data} />
              ) : itemSection === "H" && itemId === 3 ? (
                <VDSTextBlockSectionH3 textBlockData={data} />
              ) : itemSection === "H" && itemId === 4 ? (
                <VDSTextBlockSectionH4 textBlockData={data} />
              ) : itemSection === "D" ? (
                <VDSTextBlockSectionD data={data} />
              ) : itemSection === "M" ? (
                <VDSTextBlockSectionM data={data} />
              ) : itemSection === "R" ? (
                <VDSTextBlockSectionR data={data} />
              ) : (
                <></>
              )}
              {!(itemSection === "H" && itemId === 4) && (
                <EditVDSTextBlockTemperatures data={data} maxWidth={110} />
              )}
            </>
          ) : (
            <></>
          )}
        </DisabledInputContainer>
      </FieldContext.Provider>
    </div>
  );
}

export function TextBlockSelector({
  isOpen,
  setIsOpen,
  itemSection,
  setItemSection,
  itemId,
  setItemId,
  itemIdProp,
  groupsConfig,
  setValue,
  insert,
  disabledItems,
  fields,
  title,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  itemSection: VDSItemSections;
  setItemSection: React.Dispatch<VDSItemSections>;
  itemId: number;
  setItemId: React.Dispatch<number>;
  itemIdProp?: string;
  groupsConfig: VDSSubsegmentGroupsConfig[] | undefined;
  setValue?: UseFormSetValue<VDSSubsegment>;
  insert?: UseFieldArrayInsert<FieldValues, any>;
  disabledItems: string[];
  fields?: Record<"id", string>[];
  title: string;
}) {
  const isListSection = !!insert;

  const { data, status, error, refetch, isRefetching } =
    useVDSSubsegmentTextBlock({
      itemSection,
      itemId,
    });

  const { selection, selectionDispatch, selectionMode } = useTableSelect({
    selectionMode: "single",
  });

  const [nameFilter, setNameFilter] = useState("");

  const nameProperty: NameProperties = useMemo(
    () => [
      { name: "TextBlockName", matchAnywhere: true },
      { name: "TextBlockID", matchExact: true },
    ],
    []
  );

  const items = useMemo(
    () =>
      filterItemsByName({
        items: data,
        nameFilter,
        nameProperty,
      }),
    [data, nameFilter, nameProperty]
  );

  const selectedLine = useMemo(
    () => items.find((e) => e.TextBlockID === selection[0]),
    [items, selection]
  );

  const methods = useForm();

  const {
    data: textBlockData,
    status: textBlockStatus,
    error: textBlockError,
    refetch: textBlockRefetch,
  } = useSheet({
    name: selectedLine?.TextBlockID,
    revision: selectedLine?.Revision,
    sheetType: "vds-textblocks",
    disabled: !selectedLine,
  });

  const preProp = "getVDSSubsegmentHeader.0." as const;

  const lastItemInSubListIndex = useMemo(
    () =>
      fields && itemIdProp && Array.isArray(fields)
        ? fields.filter((e: any) => e[itemIdProp] === itemId).length > 0
          ? fields.map((e: any) => e[itemIdProp] as number).lastIndexOf(itemId)
          : -1
        : -1,
    [fields, itemId, itemIdProp]
  );

  const lastItemInPreviousSubListIndex = useMemo(
    () =>
      fields && itemIdProp && Array.isArray(fields)
        ? fields.filter((e: any) => e[itemIdProp] < itemId).length > 0
          ? fields.filter((e: any) => e[itemIdProp] < itemId).length
          : -1
        : -1,
    [fields, itemId, itemIdProp]
  );

  const nextIndex =
    lastItemInSubListIndex > -1
      ? lastItemInSubListIndex + 1
      : lastItemInPreviousSubListIndex > -1
      ? lastItemInPreviousSubListIndex
      : 0;

  const itemText =
    groupsConfig?.find(
      (e) => e.ItemSection === itemSection && e.ItemID === itemId
    )?.ItemDescription ?? "";

  return (
    <ModalWindow
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      title={`Select a ${title} Section Text Block`}
      layer="default"
    >
      <FormProvider {...methods}>
        <form>
          <ModalSideMargin>
            <ModalWindowDefaultContainer>
              <ModalWindowContentFillScrollable
                style={{ padding: "0 2px", maxHeight: 610 }}
              >
                <FlexContainer>
                  <FlexElement>
                    <FlexColumnContainer>
                      <FlexColumnElement
                        style={{ minWidth: 740, maxWidth: 740 }}
                      >
                        <FlexContainer
                          style={{
                            alignItems: "flex-end",
                            gap: 32,
                            marginBottom: 12,
                          }}
                        >
                          <FlexContainer>
                            <FlexElement>
                              <Search
                                id="nameFilter"
                                placeholder="Filter names, IDs"
                                onChange={(e) => setNameFilter(e.target.value)}
                                value={nameFilter}
                                style={{ width: 250 }}
                              />
                            </FlexElement>
                            <FlexElement>
                              <NameFilterInfo nameProperty={nameProperty} />
                            </FlexElement>
                          </FlexContainer>
                          <FlexElement>
                            <FlexContainer
                              gap
                              style={{ alignItems: "flex-end" }}
                            >
                              <FlexElement>
                                <CircularProgress
                                  size={24}
                                  style={{
                                    visibility: isRefetching
                                      ? "visible"
                                      : "hidden",
                                    marginBottom: 4,
                                  }}
                                />
                              </FlexElement>
                              <FlexElement>
                                <Button
                                  variant="ghost_icon"
                                  onClick={() => {
                                    refetch();
                                    selectedLine && textBlockRefetch();
                                  }}
                                >
                                  <Icon data={refresh} />
                                </Button>
                              </FlexElement>
                              <FlexElement>
                                <NativeSelect
                                  id="groupsConfig"
                                  label="Text Block Type"
                                  style={{ width: 250 }}
                                  onChange={(e) => {
                                    const value = JSON.parse(e.target.value);
                                    setItemSection(value.itemSection);
                                    setItemId(value.itemId);
                                  }}
                                  value={JSON.stringify({
                                    itemSection,
                                    itemId,
                                  })}
                                >
                                  {groupsConfig
                                    ?.filter(
                                      (config) =>
                                        config.ItemSection === itemSection
                                    )
                                    .map((group) => (
                                      <option
                                        key={group.ItemSection + group.ItemID}
                                        value={JSON.stringify({
                                          itemSection: group.ItemSection,
                                          itemId: group.ItemID,
                                        })}
                                      >
                                        {group.ItemSection} -{" "}
                                        {group.ItemDescription}
                                      </option>
                                    ))}
                                </NativeSelect>
                              </FlexElement>
                            </FlexContainer>
                          </FlexElement>
                        </FlexContainer>
                        <FlexElement>
                          <SmallTableContainer
                            style={{ width: 740, minHeight: 400 }}
                          >
                            <Table
                              itemIdProp="TextBlockID"
                              items={items}
                              columns={columns}
                              status={status}
                              error={error}
                              density="compact"
                              selection={selection}
                              selectionDispatch={selectionDispatch}
                              selectionMode={selectionMode}
                              fullRowSelect={true}
                              disabledItems={disabledItems}
                              sortable
                            />
                          </SmallTableContainer>
                        </FlexElement>
                      </FlexColumnElement>
                      <FlexColumnElement
                        style={{ minWidth: 800, maxWidth: 800 }}
                      >
                        <TextBlockPreivew
                          itemSection={itemSection}
                          itemId={itemId}
                          data={textBlockData}
                          status={textBlockStatus}
                          error={textBlockError}
                          textBlockId={selectedLine?.TextBlockID}
                        />
                      </FlexColumnElement>
                    </FlexColumnContainer>
                  </FlexElement>
                </FlexContainer>
              </ModalWindowContentFillScrollable>

              <ModalWindowContentFixed>
                <ModalWindowButtonContainer>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (!!setValue && textBlockData) {
                        if (itemSection === "H") {
                          if (itemId === 1) {
                            setValue(
                              `${preProp}ValveType`,
                              textBlockData.ValveType,
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}ValveTypeTextBlockID`,
                              String(textBlockData.TextBlockID),
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}ValveTypeTextBlockRevision`,
                              textBlockData.Revision,
                              { shouldDirty: true }
                            );
                          } else if (itemId === 2) {
                            setValue(
                              `${preProp}DesignCode`,
                              textBlockData.DesignCode,
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}DesignCodeTextBlockID`,
                              String(textBlockData.TextBlockID),
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}DesignCodeTextBlockRevision`,
                              textBlockData.Revision,
                              { shouldDirty: true }
                            );
                          } else if (itemId === 3) {
                            setValue(
                              `${preProp}SizeRangeFrom`,
                              textBlockData.SizeRangeFrom,
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}SizeRangeTo`,
                              textBlockData.SizeRangeTo,
                              { shouldDirty: true }
                            );
                            setValue(`${preProp}Unit`, textBlockData.Unit, {
                              shouldDirty: true,
                            });
                            setValue(
                              `${preProp}SizeRangeComment`,
                              textBlockData.SizeRangeComment,
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}SizeRangeTextBlockID`,
                              String(textBlockData.TextBlockID),
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}SizeRangeTextBlockRevision`,
                              textBlockData.Revision,
                              { shouldDirty: true }
                            );
                          } else if (itemId === 4) {
                            ratingMatrixProps.forEach((row) =>
                              row.forEach((item) => {
                                setValue(
                                  `getVDSSubsegmentHeader.0.${item}`,
                                  textBlockData[item],
                                  { shouldDirty: true }
                                );
                              })
                            );
                            setValue(
                              `${preProp}RatingClass`,
                              String(textBlockData.RatingClass),
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}RatingClassTextBlockID`,
                              String(textBlockData.TextBlockID),
                              { shouldDirty: true }
                            );
                            setValue(
                              `${preProp}RatingClassTextBlockRevision`,
                              textBlockData.Revision,
                              { shouldDirty: true }
                            );
                          }
                        }
                      }

                      if (isListSection && !!insert && !!selectedLine) {
                        const designPart: Partial<VDSSubsegmentDesign> =
                          itemSection === "D"
                            ? {
                                DesignItemID: itemId,
                                DesignItemText: itemText,
                                DesignSubItemID: nextIndex,
                                DesignDescription: textBlockData?.DesignRemark,
                                DesignItemRevision: "",
                              }
                            : {};
                        const materialPart: Partial<VDSSubsegmentMaterial> =
                          itemSection === "M"
                            ? {
                                MaterialItemID: itemId,
                                MaterialItemText: itemText,
                                MaterialSubItemID: nextIndex,
                                MaterialSubItemText:
                                  textBlockData?.MaterialSubItem,
                                MaterialName: textBlockData?.MaterialName,
                                MaterialMDS: textBlockData?.MaterialMDS,
                                MaterialDescription:
                                  textBlockData?.MaterialRemark,
                                MaterialItemRevision: "",
                              }
                            : {};
                        const miscReqPart: Partial<VDSSubsegmentMiscReq> =
                          itemSection === "R"
                            ? {
                                MiscReqItemID: itemId,
                                MiscReqItemText: itemText,
                                MiscReqSubItemID: nextIndex,
                                MiscReqVSK: textBlockData?.MiscVSK,
                                MiscReqDescription:
                                  textBlockData?.MiscDescription,
                                MiscReqItemRevision: "",
                              }
                            : {};
                        insert(nextIndex, {
                          TextBlockID: selectedLine?.TextBlockID,
                          TextBlockRevision: selectedLine?.Revision,
                          ...designPart,
                          ...materialPart,
                          ...miscReqPart,
                        });
                      }

                      setIsOpen(false);
                    }}
                    disabled={
                      selection.length === 0 || textBlockStatus !== "success"
                    }
                  >
                    Select
                  </Button>
                  <Button variant="outlined" onClick={() => setIsOpen(false)}>
                    Cancel
                  </Button>
                </ModalWindowButtonContainer>
              </ModalWindowContentFixed>
            </ModalWindowDefaultContainer>
          </ModalSideMargin>
        </form>
      </FormProvider>
    </ModalWindow>
  );
}
