import { Button, Icon, Search, Tooltip } from "@equinor/eds-core-react";
import { add, refresh } from "@equinor/eds-icons";
import {
  FlexContainer,
  FlexElement,
  HeaderButtonContainer,
} from "components/Components";
import { SimpleDownloads } from "components/Downloads";
import { NameFilterInfo } from "components/NameFilterInfo";
import { HeaderProgress } from "components/table/HeaderProgress";
import { useItemAdmin } from "queries/useItemAdmin";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NameProperties } from "utils/filterItemsByName";
import Filters, {
  FilterProps,
  FiltersDispatch,
} from "../../components/table/Filters";
import {
  ControlHeaderContainer,
  ControlHeaderTitle,
} from "../../components/table/Table";
import { PlantProps } from "../plant/types";
import {
  ItemAdminAreas,
  ItemAdminProps,
  ItemAdminSections,
  itemAdminConfig,
} from "./itemAdminConfig";
import { PCSStandardNotesFilter } from "./pcs-standard-notes/PCSStandardNotesFilter";
import { PCSStandardNotes } from "./pcs-standard-notes/pcsStandardNotesConfig";
import { PlantsFilter } from "./plants/PlantsFilter";
import { UsersFilter } from "./users/UsersFilter";
import { AllUsers } from "./users/usersConfig";
import { ValveTypesFilter } from "./valve-types/ValveTypeFilter";
import { ValveTypes } from "./valve-types/valveTypesConfig";

export function ItemControlHeader<T extends ItemAdminAreas>({
  area,
  section,
  nameFilter,
  setNameFilter,
  filters,
  filtersDispatch,
  data,
}: {
  area: T;
  section?: ItemAdminSections<T>[number];
  nameFilter: string;
  setNameFilter: React.Dispatch<string>;
  filters: FilterProps[];
  filtersDispatch: FiltersDispatch;
  data?: ItemAdminProps<T>[];
}) {
  const { title, sectionTitles, columns, nameProperty } = itemAdminConfig[area];
  const history = useHistory();
  const { isRefetching, refetch } = useItemAdmin({ area, section });
  const [categoryFilter, setCategoryFilter] = useState("");
  useEffect(() => setCategoryFilter(""), [area, section]);

  return (
    <ControlHeaderContainer>
      {area === "pcs-standard-notes" && (
        <PCSStandardNotesFilter
          data={data as PCSStandardNotes[]}
          filtersDispatch={filtersDispatch}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />
      )}
      {area === "plants" && (
        <PlantsFilter
          data={data as PlantProps[]}
          filtersDispatch={filtersDispatch}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />
      )}
      {area === "users" && (
        <UsersFilter
          data={data as AllUsers[]}
          filtersDispatch={filtersDispatch}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />
      )}
      {area === "valve-types" && (
        <ValveTypesFilter
          data={data as ValveTypes[]}
          filtersDispatch={filtersDispatch}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />
      )}
      <FlexContainer style={{ justifyContent: "flex-start", gap: 12 }}>
        <FlexElement style={{ marginLeft: 8, marginRight: -12 }}>
          <FlexContainer flexStart>
            <FlexElement>
              <Search
                placeholder="Search..."
                value={nameFilter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNameFilter(e.target.value)
                }
              />
            </FlexElement>
            <FlexElement>
              <NameFilterInfo nameProperty={nameProperty as NameProperties} />
            </FlexElement>
          </FlexContainer>
        </FlexElement>
        {filters.length > 0 && (
          <FlexElement>
            <Filters
              filters={filters}
              groupProperties={[{ group: "", col: 1 }]}
              maxWidth={500}
              filterKey={area}
            />
          </FlexElement>
        )}
        <ControlHeaderTitle>
          {title}
          {section && sectionTitles
            ? sectionTitles[section]
            : section
            ? " – " + section
            : ""}
        </ControlHeaderTitle>
        <FlexContainer style={{ marginLeft: "auto" }}>
          <HeaderButtonContainer>
            <FlexElement>
              <HeaderProgress isRefetching={isRefetching} />
            </FlexElement>
            <FlexElement>
              <Tooltip title="Reload">
                <Button variant="ghost_icon" onClick={() => refetch()}>
                  <Icon data={refresh} />
                </Button>
              </Tooltip>
            </FlexElement>
            <FlexElement>
              <SimpleDownloads
                items={data}
                columns={columns}
                filename={title + (section ? "-" + section : "")}
              />
            </FlexElement>
            <FlexElement>
              <Button
                variant="ghost_icon"
                onClick={() => history.replace({ search: "createNew" })}
              >
                <Icon data={add} />
              </Button>
            </FlexElement>
          </HeaderButtonContainer>
        </FlexContainer>
      </FlexContainer>
    </ControlHeaderContainer>
  );
}
