import axios from "axios";
import { useQuery } from "react-query";
import { PlantProps } from "../../plant/types";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { splitter, ender } from "../util";

type Article = {
  PCS: string;
  Revision: string;
  Status: string;
  StartTime: string;
  AddStackDate: string;
  AddStackTime: string;
  RunDuration: string;
  ArticlesAdded: number;
  ArticlesTotal: number;
};

type ArticleData = Article & { itemID: string; AddStackDateTime: string };

type ArticleStackReply = {
  getPCS: Article[];
};

export function useArticleStackQuery({
  plant,
  lastRunOnlyFilter,
}: {
  plant: PlantProps;
  lastRunOnlyFilter: "Y" | "N";
}) {
  return useQuery<ArticleData[], Error>(
    ["article-stack", plant.PlantID, lastRunOnlyFilter],
    async () => {
      const { data } = await axios.get<ArticleStackReply>(
        getSheetApiUrl({
          plant,
          sheetType: "pcs",
          command: `-articles-stack`,
        }) + `?lastrunonly=${lastRunOnlyFilter}`
      );
      return data["getPCS"].map((item: any) => ({
        ...item,
        ...{
          itemID: `${item.PCS}${splitter}${item.Revision}${ender}${item.AddStackDate}-${item.AddStackTime}`,
        },
        ...{
          AddStackDateTime: item.AddStackDate
            ? `${item.AddStackDate} ${item.AddStackTime}`
            : "",
        },
      }));
    }
  );
}
