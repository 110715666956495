import {
  listAdminConfig,
  ListAdminAreas,
  ListAdminReturnType,
} from "../features/admin/listAdminConfig";
import axios from "axios";
import { useQuery } from "react-query";
import config from "../config/config";

export function useListAdmin<T extends ListAdminAreas>({ area }: { area: T }) {
  return useQuery<ListAdminReturnType<T>, Error>(
    ["codelists", area],
    async () => {
      const { url: areaUrl, apiListProp } = listAdminConfig[area];
      const url = config.restApiBaseUrl + areaUrl;
      const { data }: { data: any } = await axios.get(url);
      return apiListProp ? data[apiListProp] : data;
    }
  );
}
