import { Button, Icon } from "@equinor/eds-core-react";
import { delete_forever, edit } from "@equinor/eds-icons";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import useSaveItemAdmin from "queries/useSaveItemAdmin";
import { successMessage } from "../../utils/successMessage";
import { closeAdminModal } from "./Admin";
import {
  ItemAdminAreas,
  itemAdminConfig,
  ItemAdminProps,
} from "./itemAdminConfig";

export function ItemRowMenu<T extends ItemAdminAreas>({
  area,
  item,
  disableDelete,
}: {
  area: T;
  item: ItemAdminProps<T>;
  disableDelete?: (arg0: ItemAdminProps<T>) => boolean;
}) {
  const history = useHistory();
  const {
    mutate: saveAdmin,
    status: saveAdminStatus,
    error: saveAdminError,
    data: saveAdminData,
    reset: saveAdminReset,
  } = useSaveItemAdmin();
  const closeModal = useCallback(() => {
    closeAdminModal({ history });
  }, [history]);
  const { isConfirmed } = useConfirm();
  const id = useMemo<number>(
    () => item[itemAdminConfig[area].itemIdProp] as number,
    [item, area]
  );

  return (
    <>
      <Button
        variant="ghost_icon"
        onClick={() =>
          history.replace({
            search: `edit=${id}`,
          })
        }
        title="Edit"
      >
        <Icon data={edit} />
      </Button>
      <Button
        variant="ghost_icon"
        disabled={disableDelete && disableDelete(item)}
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to delete{" "}
              {itemAdminConfig[area].singularTitle} {id}?{" "}
              {itemAdminConfig[area].deleteConfirmation && (
                <p>{itemAdminConfig[area].deleteConfirmation}</p>
              )}
            </>,
            { buttonColor: "danger" }
          )) &&
            saveAdmin({
              area,
              method: "delete",
              data: { id },
            });
        }}
        title="Delete"
      >
        <Icon data={delete_forever} />
      </Button>
      <StatusModal
        status={saveAdminStatus}
        error={saveAdminError}
        successMessage={successMessage(saveAdminData)}
        onSettledClose={saveAdminReset}
        onSuccess={closeModal}
      />
    </>
  );
}
