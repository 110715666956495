import { useMutation, useQueryClient } from "react-query";
import { PlantProps } from "features/plant/types";
import axios from "axios";
import config from "config/config";

type IssueSetSheetsParams = {
  sc: {
    sheets: { SC: string; Revision: string }[];
    plant: PlantProps;
    revision: string;
  };
  vsm: {
    sheets: { VSM: string; Revision: string }[];
    plant: PlantProps;
    revision: string;
  };
  vds: {
    sheets: { VDS: string; Revision: string }[];
    plant: PlantProps;
    revision: string;
  };
  eds: {
    sheets: { EDS: string; Revision: string }[];
    plant: PlantProps;
    revision: string;
  };
  mds: {
    sheets: { MDS: string; Revision: string }[];
    plant: PlantProps;
    revision: string;
  };
  vsk: {
    sheets: { VSK: string; Revision: string }[];
    plant: PlantProps;
    revision: string;
  };
};

export type IssueSetSheets = keyof IssueSetSheetsParams;

export const issueSetSheetTypes = [
  "sc",
  "vsm",
  "vds",
  "eds",
  "mds",
  "vsk",
  "esk",
];

export const issueSetSheetNames = {
  sc: "SC",
  vsm: "VSM",
  vds: "VDS",
  eds: "EDS",
  mds: "MDS",
  vsk: "VSK",
  esk: "ESK",
};

export default function useIssueSetSheets<T extends IssueSetSheets>() {
  const queryClient = useQueryClient();

  async function issueCommand({
    command,
    ...rest
  }: { command: T } & IssueSetSheetsParams[T]) {
    const { plant, revision, ...body } = rest;
    return await axios({
      url:
        config.restApiBaseUrl +
        `plants/${plant.PlantID}/issues/rev/${revision}/${command}`,
      method: "put",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: body,
    });
  }

  return useMutation(issueCommand, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["issues", variables.plant.PlantID]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type IssueSetSheetType = ReturnType<typeof useIssueSetSheets>;
