import { Icon, Menu } from "@equinor/eds-core-react";
import {
  assignment,
  business,
  file,
  folder,
  link,
  platform,
} from "@equinor/eds-icons";
import { CreateDocumentType } from "./queries/useCreateDocument";
import React from "react";

export function CreateNewMenu({
  setNewObjectTargetOID,
  setCreateDocumentType,
  setCreateObjectOpen,
  selection,
  validDocumentTypes,
  hide,
  nested,
}: {
  setNewObjectTargetOID: React.Dispatch<number>;
  setCreateDocumentType: React.Dispatch<CreateDocumentType>;
  setCreateObjectOpen: React.Dispatch<boolean>;
  selection: string[];
  validDocumentTypes: string[];
  hide?: boolean;
  nested?: boolean;
}) {
  const folderEnabled = validDocumentTypes.includes("F");
  const documentEnabled = validDocumentTypes.includes("D");
  const urlEnabled = validDocumentTypes.includes("U");
  const contentFolderEnabled = validDocumentTypes.includes("C");
  const plantFolderEnabled = validDocumentTypes.includes("G");
  const plantEnabled = validDocumentTypes.includes("P");
  const operatorEnabled = validDocumentTypes.includes("N");

  const size = nested ? 16 : 24;

  // FirstSection here fixes the problem with EDS Menu Sections:
  // nested items cause the first section to have a divider on top.
  // Hence the Fragment: then there's no divider inserted.
  const FirstSection = React.Fragment;
  const Section = nested ? React.Fragment : Menu.Section;

  function getTitle(objectName: string) {
    return objectName;
  }

  return (
    <>
      {(!hide || folderEnabled || documentEnabled || urlEnabled) && (
        <FirstSection>
          {(!hide || folderEnabled) && (
            <Menu.Item
              onClick={() => {
                setNewObjectTargetOID(Number(selection[0]) ?? 0);
                setCreateDocumentType("F");
                setCreateObjectOpen(true);
              }}
              variant="ghost_icon"
              disabled={!folderEnabled}
            >
              <Icon data={folder} size={size} /> {getTitle("Folder")}
            </Menu.Item>
          )}
          {(!hide || documentEnabled) && (
            <Menu.Item
              onClick={() => {
                setNewObjectTargetOID(Number(selection[0]) ?? 0);
                setCreateDocumentType("D");
                setCreateObjectOpen(true);
              }}
              variant="ghost_icon"
              disabled={!documentEnabled}
            >
              <Icon data={file} size={size} /> {getTitle("Document")}
            </Menu.Item>
          )}
          {(!hide || urlEnabled) && (
            <Menu.Item
              onClick={() => {
                setNewObjectTargetOID(Number(selection[0]) ?? 0);
                setCreateDocumentType("U");
                setCreateObjectOpen(true);
              }}
              variant="ghost_icon"
              disabled={!urlEnabled}
            >
              <Icon data={link} size={size} /> {getTitle("URL address")}
            </Menu.Item>
          )}
        </FirstSection>
      )}
      {(!hide || contentFolderEnabled) && (
        <Section>
          <Menu.Item
            onClick={() => {
              setNewObjectTargetOID(Number(selection[0]) ?? 0);
              setCreateDocumentType("C");
              setCreateObjectOpen(true);
            }}
            disabled={!contentFolderEnabled}
          >
            <Icon data={assignment} size={size} />{" "}
            {getTitle("Folder content text")}
          </Menu.Item>
        </Section>
      )}
      {(!hide || plantFolderEnabled || plantEnabled || operatorEnabled) && (
        <Section>
          {(!hide || plantFolderEnabled) && (
            <Menu.Item
              onClick={() => {
                setNewObjectTargetOID(Number(selection[0]) ?? 0);
                setCreateDocumentType("G");
                setCreateObjectOpen(true);
              }}
              variant="ghost_icon"
              disabled={!plantFolderEnabled}
            >
              <Icon data={folder} size={size} />{" "}
              {getTitle("Plant-specific folder")}
            </Menu.Item>
          )}
          {(!hide || plantEnabled) && (
            <Menu.Item
              onClick={() => {
                setNewObjectTargetOID(Number(selection[0]) ?? 0);
                setCreateDocumentType("P");
                setCreateObjectOpen(true);
              }}
              variant="ghost_icon"
              disabled={!plantEnabled}
            >
              <Icon data={platform} size={size} /> {getTitle("Plant")}
            </Menu.Item>
          )}
          {(!hide || operatorEnabled) && (
            <Menu.Item
              onClick={() => {
                setNewObjectTargetOID(Number(selection[0]) ?? 0);
                setCreateDocumentType("N");
                setCreateObjectOpen(true);
              }}
              variant="ghost_icon"
              disabled={!operatorEnabled}
            >
              <Icon data={business} size={size} /> {getTitle("Operator")}
            </Menu.Item>
          )}
        </Section>
      )}
    </>
  );
}
