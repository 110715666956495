import { Button } from "@equinor/eds-core-react";
import { RevisionMark } from "components/Components";
import {
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowButtonWrapper,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { SmallTableContainer } from "components/table/Table";
import { useTable } from "components/table/useTable";
import { useTableSelect } from "components/table/useTableSelect";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { successMessage } from "utils/successMessage";
import { useDeleteStructuredPipeElement } from "../queries/useDeleteStructuredPipeElement";
import {
  VerifyMGSPENotValid,
  useMaterialGroups,
} from "../queries/useMaterialGroups";
import useConfirm from "components/confirm/useConfirm";
import { UseSetMaterialGroupStatus } from "../queries/useSetMaterialGroupStatus";

type MaterialGroupVerificationProps = {
  materialGroupId: string;
  revision: string;
  isOpen: boolean;
  closeModal: () => void;
  setStatus?: UseSetMaterialGroupStatus["mutate"];
};

const report1Columns = [
  { key: "ElementGroup", title: "Element Group" },
  { key: "DimStandard", title: "Dimension Standard" },
  { key: "ProductForm", title: "Product Form" },
  { key: "MDS", title: "MDS", width: 100 },
  { key: "MDSRevision", title: "MDS Revision", Component: RevisionMark },
  { key: "OperatorName", title: "Operator" },
  { key: "Area", title: "Area", width: 130 },
  { key: "SpecialRequirement", title: "Special Requirement" },
  { key: "Cause", title: "Cause", width: "100%" },
];

const report2Columns = [
  { key: "ElementID", title: "Element ID" },
  { key: "Revision", title: "Revision" },
  {
    key: "MasterPipeElementID",
    title: "MPE ID",
    longTitle: "Master Pipe Element ID",
  },
  { key: "ElementGroup", title: "Element Group" },
  { key: "DimStandard", title: "Dimension Standard" },
  { key: "ProductForm", title: "Product Form" },
  { key: "MaterialGrade", title: "Material", width: 120 },
  { key: "MDS", title: "MDS", width: 100 },
  { key: "MDSRevision", title: "MDS Revision", Component: RevisionMark },
  { key: "OperatorName", title: "Operator" },
  { key: "Area", title: "Area", width: 130 },
  { key: "SpecialRequirement", title: "Special Requirement" },
];

export function MaterialGroupVerification({
  materialGroupId,
  revision,
  isOpen,
  closeModal,
  setStatus,
}: MaterialGroupVerificationProps) {
  return isOpen ? (
    <MaterialGroupVerificationModal
      isOpen={isOpen}
      closeModal={closeModal}
      materialGroupId={materialGroupId}
      revision={revision}
      setStatus={setStatus}
    />
  ) : null;
}

const ReportTitle = styled.h4`
  font-weight: normal;
  margin: 0;
`;

function MaterialGroupVerificationModal({
  materialGroupId,
  revision,
  isOpen,
  closeModal,
  setStatus,
}: MaterialGroupVerificationProps) {
  const {
    data: pmgData,
    status: pmgStatus,
    error: pmgError,
  } = useMaterialGroups({
    materialGroupId,
    postfix: undefined,
  });
  const currentPMGData = pmgData?.find((e) => e.Revision === revision);

  const query = useMaterialGroups({
    name: materialGroupId,
    revision,
    postfix: "material-group-verification",
  });

  const { content: content1 } = useTable({
    columns: report1Columns,
    title: "Structured Pipe Elements not ready to be Set Official",
    query: query,
    getItemID: (item) => JSON.stringify(item),
    dataProp: "getVerifyMGSPENotReady",
    density: "compact",
    loadingString: "Loading Report...",
    TitleComponent: ReportTitle,
    hideDownloadFiltered: true,
  });

  const { selection, selectionDispatch, selectionMode } = useTableSelect({
    selectionMode: "multi",
  });

  const getItemID2 = useCallback(
    (item: VerifyMGSPENotValid) => String(item.ElementID),
    []
  );

  const { content: content2 } = useTable({
    columns: report2Columns,
    title:
      "Structured Pipe Elements that should be deleted because they are not valid according to the master component's validity settings",
    query: query,
    dataProp: "getVerifyMGSPENotValid",
    density: "compact",
    loadingString: "Loading Report...",
    itemIdProp: "itemId",
    getItemID: getItemID2,
    TitleComponent: ReportTitle,
    hideDownloadFiltered: true,
    selectionMode,
    selectionDispatch,
    selection,
  });

  const selectedSPEs = useMemo(
    () =>
      query.data?.getVerifyMGSPENotValid?.filter((item) =>
        selection.includes(String(item.ElementID))
      ),
    [query.data?.getVerifyMGSPENotValid, selection]
  );

  const {
    mutate,
    status,
    error,
    reset,
    data: mutateData,
  } = useDeleteStructuredPipeElement({
    materialGroupId,
    materialGroupRevision: revision,
  });

  const { isConfirmed } = useConfirm();

  return (
    <>
      <ModalWindow
        closeModal={closeModal}
        title={`Material Group Verification for PMG ${currentPMGData?.MaterialGroup} rev. ${revision}`}
        isOpen={isOpen}
        status={pmgStatus}
        error={pmgError}
      >
        <div style={{ display: "flex", flexDirection: "column", width: 1560 }}>
          <div>
            <SmallTableContainer style={{ height: "35vh" }}>
              {content1}
            </SmallTableContainer>
          </div>
          <div>
            <SmallTableContainer style={{ height: "35vh" }}>
              {content2}
            </SmallTableContainer>
          </div>
        </div>
        <ModalWindowButtonContainer>
          <ModalWindowButtonWrapper>
            <Button
              variant="outlined"
              color="danger"
              onClick={async () => {
                selectedSPEs &&
                  selectedSPEs.length > 0 &&
                  (await isConfirmed(
                    "Are you sure you want to delete the selected Structured Pipe Elements?",
                    { buttonColor: "danger" }
                  )) &&
                  mutate({
                    pipeElements: selectedSPEs.map((e) => ({
                      masterPipeElementId: e.MasterPipeElementID,
                      elementId: Number(e.ElementID),
                      revision: e.Revision,
                    })),
                  });
              }}
              disabled={!selectedSPEs || selectedSPEs.length === 0}
            >
              Delete selected SPEs
            </Button>
          </ModalWindowButtonWrapper>
          {setStatus && (
            <ModalWindowButtonWrapper>
              <Button
                onClick={async () => {
                  (query.data?.getVerifyMGSPENotReady.length === 0 ||
                    (await isConfirmed(
                      <>
                        <p>
                          There are Structured Pipe Element(s) which are not
                          ready to be set Official.
                        </p>
                        <p>Do you still want to set this PMG to Official?</p>
                      </>
                    ))) &&
                    setStatus({ name: materialGroupId, revision, status: "O" });
                }}
                disabled={query.status !== "success"}
              >
                Set PMG Official
              </Button>
            </ModalWindowButtonWrapper>
          )}
          <ModalWindowButtonWrapper>
            <Button variant="outlined" onClick={closeModal}>
              Close
            </Button>
          </ModalWindowButtonWrapper>
        </ModalWindowButtonContainer>
      </ModalWindow>
      <StatusModal
        status={status}
        error={error}
        onSettledClose={() => {
          reset();
        }}
        successMessage={successMessage(mutateData)}
      />
    </>
  );
}
