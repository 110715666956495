import styled from "styled-components";
import { InfoPopover } from "./InfoPopover";
import { NameProperties, NameProperty } from "utils/filterItemsByName";

const A = styled.span`
  font-weight: bold;
  display: inline-block;
  min-width: 20px;
`;

const B = styled(A)`
  min-width: 65px;
`;

const H4 = styled.h4`
  margin-bottom: 12px;
  margin-top: 24px;
`;

const InP = styled.p`
  margin-bottom: -6px;
  margin-left: 16px;
`;

function showNameProperty(nameProperty: NameProperty) {
  return typeof nameProperty === "string"
    ? nameProperty.slice(-2) === "ID"
      ? nameProperty + " (exact)"
      : nameProperty
    : nameProperty.name +
        (nameProperty.matchAnywhere
          ? " (anywhere)"
          : nameProperty.matchExact
          ? " (exact)"
          : "");
}

export function NameFilterInfo({
  nameProperty,
  serverSide,
}: {
  nameProperty?: NameProperties;
  serverSide?: boolean;
}) {
  return (
    <InfoPopover
      title="Filtering by search strings"
      content={
        <>
          <p>
            Table columns can be filtered by entering a search string in the
            Filter / Search field.
          </p>
          {nameProperty && (
            <>
              <p>The following columns are filtered in the current table:</p>
              <b>
                <ul>
                  {Array.isArray(nameProperty) ? (
                    nameProperty
                      .map((e) => showNameProperty(e))
                      .map((e) => <li key={e}>{e}</li>)
                  ) : (
                    <li>{showNameProperty(nameProperty)}</li>
                  )}
                </ul>
              </b>
            </>
          )}
          {serverSide && (
            <p>
              <i>Note:</i> Since this table is filtered on the server, the
              filtering options are limited compared to other tables.
            </p>
          )}
          <p>
            {serverSide ? "The" : "By default, the"} search string is matched
            against the beginning of the column content.
            {!serverSide &&
              ` Columns marked with "anywhere" match anywhere in
            the content, and columns marked with "exact" match the value
            exactly.`}
          </p>
          <p>
            Special characters can be used to narrow down or expand the search
            results.
          </p>
          <H4>Special characters</H4>
          <ul>
            <li>
              <A>*</A> any number of characters.
            </li>
            <li>
              <A>_</A> one character.
            </li>
            {!serverSide && (
              <>
                <li>
                  <A>$</A> end of string.
                </li>
                <li>
                  <A>^</A> beginning of string.
                </li>
                <li>
                  <A>|</A> or (to be used between search strings).
                </li>
              </>
            )}
          </ul>
          <H4>Examples</H4>
          {!serverSide && <InP>Default:</InP>}
          <ul>
            <li>
              <B>ABC</B> matches items beginning with "ABC".
            </li>
            <li>
              <B>*ABC</B> matches items containing "ABC" anywhere.
            </li>
            {!serverSide && (
              <>
                <li>
                  <B>ABC$</B> matches items exactly "ABC".
                </li>

                <li>
                  <B>*ABC$</B> matches items ending with "ABC".
                </li>
              </>
            )}
            <li>
              <B>_BC</B> matches items beginning with one character, followed by
              "BC".
            </li>
            {!serverSide && (
              <>
                <li>
                  <B>_B*C$</B> matches items beginning with one character,
                  followed by "B", followed by any number of characters,
                  followed by "C" as the final character.
                </li>

                <li>
                  <B>ABC|DEF</B> matches items beginning with either "ABC" or
                  "DEF".
                </li>
              </>
            )}
          </ul>
          {!serverSide && (
            <>
              <InP>Exact matching (usually IDs):</InP>
              <ul>
                <li>
                  <B>12</B> matches items with ID "12".
                </li>
                <li>
                  <B>12|34</B> matches items with IDs "12" or "34".
                </li>
              </ul>
              <InP>Matching anywhere (typically longer texts):</InP>
              <ul>
                <li>
                  <B>xyz</B> matches items containing "xyz" anywhere.
                </li>
                <li>
                  <B>^xyz</B> matches items beginning with "xyz".
                </li>
                <li>
                  <B>^xyz$</B> matches items exactly "xyz".
                </li>
              </ul>
            </>
          )}
        </>
      }
    />
  );
}
