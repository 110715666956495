import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Icon,
  NativeSelect,
  Popover,
  Switch,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import { account_circle } from "@equinor/eds-icons";
import { AdminRoles, UserRoles, adminRoles, userRoles } from "app/userConfig";
import React from "react";
import ReactDOM from "react-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { FlexContainer } from "./components/Components";
import useUser from "./queries/useUser";
import {
  selectUIState,
  setAdminRoleTest,
  setRoleTest,
  setShowAlpha,
  setShowBeta,
  setShowRoleTest,
} from "./uiSlice";
import { portalContainer } from "./utils/util";

export function handleLogout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch((e: Error) => {
    console.error(e);
  });
}

export default function UserMenu() {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const closePopover = () => setIsOpen(false);
  const togglePopover = () => setIsOpen(!isOpen);
  const { instance, accounts } = useMsal();
  const { data: userData } = useUser();
  const { showAlpha, showBeta, showRoleTest, roleTest, adminRoleTest } =
    useAppSelector(selectUIState);
  const dispatch = useAppDispatch();

  const popover = ReactDOM.createPortal(
    <Popover
      anchorEl={anchorRef.current}
      open={isOpen}
      onClose={closePopover}
      placement="bottom-end"
      style={{ zIndex: 1000 }}
    >
      <Popover.Header>
        <Popover.Title>
          {accounts && accounts[0] ? (
            <>
              Signed in as {accounts[0].name}
              <Typography
                variant="caption"
                style={{ marginTop: ".5em", fontWeight: "normal" }}
              >
                {accounts[0].username}
              </Typography>
            </>
          ) : (
            <>No account info.</>
          )}
          <FlexContainer
            style={{ justifyContent: "center", marginTop: 16, marginBottom: 8 }}
          >
            <Button onClick={() => handleLogout(instance)}>Sign out</Button>
          </FlexContainer>
        </Popover.Title>
      </Popover.Header>
      <Popover.Content>
        {userData && userData.userInfo.Role === "X" && (
          <div style={{ marginBottom: 8 }}>
            <div>
              <Switch
                checked={showAlpha}
                onChange={() => dispatch(setShowAlpha(!showAlpha))}
                label="Show Alpha"
              />
            </div>
            <div>
              <Switch
                checked={showBeta}
                onChange={() => dispatch(setShowBeta(!showBeta))}
                label="Show Beta"
              />
            </div>
            <div>
              <Switch
                checked={showRoleTest}
                onChange={() => dispatch(setShowRoleTest(!showRoleTest))}
                label="Role Test"
              />
            </div>
            {showRoleTest && (
              <div style={{ marginTop: 8 }}>
                <NativeSelect
                  id="role-test"
                  label="Role"
                  disabled={!showRoleTest}
                  onChange={(e) => {
                    dispatch(setRoleTest(e.target.value as UserRoles));
                  }}
                  value={roleTest}
                >
                  {Object.keys(userRoles)
                    .filter((userRole) => userRole !== "X")
                    .map((userRole) => (
                      <option value={userRole}>
                        {userRoles[userRole as UserRoles]}
                      </option>
                    ))}
                </NativeSelect>
              </div>
            )}
            {showRoleTest && roleTest === "A" && (
              <div style={{ marginTop: 16 }}>
                <NativeSelect
                  id="admin-role-test"
                  label="Admin Role"
                  onChange={(e) => {
                    dispatch(setAdminRoleTest(e.target.value as AdminRoles));
                  }}
                  value={adminRoleTest}
                >
                  {Object.keys(adminRoles)
                    .filter((adminRole) => adminRole !== "")
                    .map((adminRole) => (
                      <option value={adminRole}>
                        {adminRoles[adminRole as AdminRoles]}
                      </option>
                    ))}
                </NativeSelect>
              </div>
            )}
          </div>
        )}
      </Popover.Content>
    </Popover>,
    portalContainer
  );

  return (
    <>
      <Tooltip title="Account">
        <Button ref={anchorRef} variant="ghost_icon" onClick={togglePopover}>
          <Icon data={account_circle} />
        </Button>
      </Tooltip>
      {popover}
    </>
  );
}
