import { NativeSelect, TextField, Typography } from "@equinor/eds-core-react";
import { FlexContainer, FlexElement } from "components/Components";
import { combineQueryStatuses } from "queries/queryUtil";
import { useItemAdmin } from "queries/useItemAdmin";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ContentCellWrap,
  HeadCell,
  HeadLeftCell,
  HeadLeftCellEmpty,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../../../components/ReportTable";
import { ReportFormHeader } from "../ReportFormHeader";
import { reportsConfig } from "../Reports";
import useActivityLogParams from "../queries/useActivityLogParams";
import { ActivityLogData } from "./activityLogConfig";

export const activityLogColumns: {
  key: keyof ActivityLogData[0];
  title: string;
}[] = [
  { key: "User", title: "User" },
  { key: "ActivityDate", title: "Activity Date" },
  { key: "ActivityTime", title: "Activity Time" },
  { key: "AppModule", title: "App Module" },
  { key: "AppSubModule", title: "App Submodule" },
  { key: "MessageType", title: "Message Type" },
  { key: "Message", title: "Message" },
];

const headCells = ["User", "ActivityDate", "ActivityTime"];

export function ActivityLogReport({
  data,
  filenameRef,
  headerHeight,
}: {
  data: ActivityLogData | undefined;
  filenameRef: React.MutableRefObject<HTMLInputElement | null>;
  headerHeight: number;
}) {
  const { data: activityLogParams, status: activityLogParamsStatus } =
    useActivityLogParams();
  const appModules = activityLogParams?.filter((e) => e.Category === "M");
  const appSubmodules = activityLogParams?.filter((e) => e.Category === "S");

  const { data: users, status: usersStatus } = useItemAdmin({ area: "users" });
  const conbinedStatus = combineQueryStatuses(
    activityLogParamsStatus,
    usersStatus
  );

  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);

  const selectedAppModule =
    appModules &&
    appModules.find(
      (e) => String(e.ListItems) === String(parsedSearch.get("AppModule"))
    );

  const selectedAppSubmodule =
    appSubmodules &&
    appSubmodules.find(
      (e) => String(e.ListItems) === String(parsedSearch.get("AppSubmodule"))
    );

  const date = new Date();
  const [fromDateISO, setFromDateISO] = useState(
    parsedSearch.get("FromDate") || date.toISOString().split("T")[0]
  );
  const [toDateISO, setToDateISO] = useState(
    parsedSearch.get("ToDate") || date.toISOString().split("T")[0]
  );

  return conbinedStatus === "loading" ? (
    <>Loading Report Parameters...</>
  ) : conbinedStatus === "success" ? (
    <>
      <input
        type="hidden"
        value={reportsConfig["activity-log"].title}
        ref={filenameRef}
      />
      <ReportFormHeader>
        <FlexContainer
          flexStart
          gap={16}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <FlexContainer flexStart gap={24}>
            <FlexElement>
              <input
                type="hidden"
                id="toDate"
                value={
                  toDateISO.split("-")[2] +
                  toDateISO.split("-")[1] +
                  toDateISO.split("-")[0]
                }
              />
              <input
                type="hidden"
                name="fromDate"
                value={
                  fromDateISO.split("-")[2] +
                  fromDateISO.split("-")[1] +
                  fromDateISO.split("-")[0]
                }
              />
              <TextField
                label="Number of lines"
                id="noOfLines"
                type="number"
                defaultValue={Number(parsedSearch.get("NoOfLines")) || 10}
                style={{ width: 100 }}
              />
            </FlexElement>
            <FlexElement>
              <TextField
                id="fromDateISO"
                label="From Date"
                type="date"
                value={fromDateISO}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFromDateISO(e.target.value)
                }
              />
            </FlexElement>
            <FlexElement>
              <TextField
                id="toDateISO"
                label="To Date"
                type="date"
                value={toDateISO}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setToDateISO(e.target.value)
                }
              />
            </FlexElement>
            <FlexElement>
              <NativeSelect
                id="userNo"
                label="User"
                defaultValue={parsedSearch.get("UserNo") || ""}
                style={{ maxWidth: 253 }}
              >
                <option value="">– All –</option>
                {users?.map((e) => (
                  <option key={e.UserNo} value={e.UserNo}>
                    {e.FullName} ({e.UserID})
                  </option>
                ))}
              </NativeSelect>
            </FlexElement>
          </FlexContainer>
          <FlexContainer flexStart gap={24}>
            <FlexElement>
              <NativeSelect
                id="messageType"
                label="Message Type"
                defaultValue={parsedSearch.get("MessageType") || ""}
              >
                <option value="">– All –</option>
                <option value="E">Error</option>
                <option value="S">OK</option>
              </NativeSelect>
            </FlexElement>
            <FlexElement>
              <NativeSelect
                id="appModule"
                label="App Module"
                defaultValue={selectedAppModule?.ListItems}
                style={{ width: 300 }}
              >
                {!selectedAppModule && <option value="">– All –</option>}
                {appModules?.map((e) => (
                  <option key={e.ListItems} value={e.ListItems}>
                    {e.ListItems}
                  </option>
                ))}
              </NativeSelect>
            </FlexElement>
            <FlexElement>
              <NativeSelect
                id="appSubodule"
                label="App Submodule"
                defaultValue={selectedAppSubmodule?.ListItems}
                style={{ width: 300 }}
              >
                {!selectedAppSubmodule && <option value="">– All –</option>}
                {appSubmodules?.map((e) => (
                  <option key={e.ListItems} value={e.ListItems}>
                    {e.ListItems}
                  </option>
                ))}
              </NativeSelect>
            </FlexElement>
          </FlexContainer>
        </FlexContainer>
      </ReportFormHeader>
      {typeof data !== "undefined" &&
        (data.length === 0 ? (
          <>
            <Typography variant="h4" style={{ display: "flex", gap: 8 }}>
              No activity logged for the selected parameters.
            </Typography>
          </>
        ) : (
          <div>
            <ReportTable>
              <TableHead>
                <TableRow>
                  {activityLogColumns.map((e) => (
                    <HeadCell>{e.title}</HeadCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((d, i) => (
                  <TableRow
                    key={i}
                    className={d.MessageType === "E" ? "errorRow" : ""}
                  >
                    {activityLogColumns.map((e) =>
                      headCells.includes(e.key) ? (
                        data[i - 1] && data[i - 1][e.key] === d[e.key] ? (
                          <HeadLeftCellEmpty />
                        ) : (
                          <HeadLeftCell
                            style={{ position: "sticky", top: headerHeight }}
                          >
                            {d[e.key]}
                          </HeadLeftCell>
                        )
                      ) : (
                        <ContentCellWrap>{d[e.key]}</ContentCellWrap>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </ReportTable>
          </div>
        ))}
    </>
  ) : (
    <></>
  );
}
