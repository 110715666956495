import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

export type DocumentListItem = {
  OID: number;
  DocumentID: string;
  Description: string;
  Comment: string;
  DocumentFormat: string;
  DocumentType: "D" | "L" | "U" | "C";
  LastModified: string;
  FileName: string;
  FileSizeKB: number;
  MenuOrCategory: "M" | "C" | "B" | "";
  OperatorID: number;
  PlantID: number;
  VisibleOnWeb: "Y" | "N";
  Rank: number;
  Tag: string;
  Tooltip: string;
  URLAddress: string;
};

export function useDocumentList({ oid }: { oid: number }) {
  return useQuery(
    ["document-space", "documents", oid],
    async () => {
      const { data } = await axios.get<{ getDocList: DocumentListItem[] }>(
        getApiUrl(["document-space", oid, "document-list"].join("/"))
      );
      return data.getDocList;
    },
    {
      enabled: !!oid,
    }
  );
}
