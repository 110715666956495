import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useCreatePipeMaterialGroup() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      materialGroupId,
      note,
    }: {
      materialGroupId: string;
      note: string;
    }): Promise<AxiosResponse<StatusResponse>> =>
      await axios(getApiUrl("pmg/material-groups/" + materialGroupId), {
        method: "post",
        data: JSON.stringify({ Note: note }),
      }),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["pmg", "material-groups"]);
      },
      onError: (error, variables, context) => {
        console.error(`error in ${context}`, error, variables);
      },
    }
  );
}
