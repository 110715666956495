import { Button, Icon } from "@equinor/eds-core-react";
import { new_alert } from "@equinor/eds-icons";
import config from "config/config";
import { debugLog } from "app/debugLog";
import axios from "axios";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
} from "components/ModalWindow";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { hashString } from "utils/hashString";

type UpdateContextType = {
  hasUpdateAvailable: boolean;
};

export const UpdateContext = createContext({} as UpdateContextType);

async function getSiteContent() {
  const baseHref = document.getElementById("basehreftag")?.getAttribute("href");
  const request = await axios.get<string>(baseHref ?? "", {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  return request.data;
}

export const useCheckForAppUpdate = () => {
  const [hasUpdateAvailable, setHasUpdateAvailable] = useState(false);

  const originalSite = useRef("");
  if (originalSite.current === "") {
    getSiteContent().then((siteContent) => {
      originalSite.current = siteContent;
      debugLog("updchk", "saved " + hashString(siteContent));
    });
  }

  useEffect(() => {
    const updateCheckInterval = setInterval(() => {
      debugLog("updchk", "get");
      getSiteContent().then((siteContent) => {
        debugLog("updchk", "got");
        if (siteContent !== originalSite.current) {
          debugLog("updchk: upd found", [
            "original",
            hashString(siteContent),
            "current",
            hashString(originalSite.current),
          ]);
          setHasUpdateAvailable(true);
          clearInterval(updateCheckInterval);
        } else {
          debugLog("updchk", "no upd");
        }
      });
    }, config.checkForUpdateInterval);
    return () => {
      clearInterval(updateCheckInterval);
    };
  }, []);

  const onFocus = () => {
    debugLog("updchk", "get (fcs)");
    getSiteContent().then((siteContent) => {
      debugLog("updchk", "got (fcs)");
      if (siteContent !== originalSite.current) {
        debugLog("updchk", [
          "upd found (fcs)",
          "original",
          hashString(siteContent),
          "current",
          hashString(originalSite.current),
        ]);
        setHasUpdateAvailable(true);
      } else {
        debugLog("updchk", "no upd (fcs)");
      }
    });
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  return { hasUpdateAvailable };
};

function useAppUpdateNotificationModal() {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  return {
    updateModalContent: (
      <ModalWindow
        layer="default"
        title={`${process.env.REACT_APP_TITLE} Update Available`}
        isOpen={updateModalOpen}
        closeModal={() => {
          setUpdateModalOpen(false);
        }}
      >
        <ModalSideMargin>
          <p style={{ marginBottom: "2em" }}>
            A new version of {process.env.REACT_APP_TITLE} is available. To get
            the latest version, click Reload.
          </p>
          <ModalWindowButtonContainer>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setUpdateModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </ModalWindowButtonContainer>
        </ModalSideMargin>
      </ModalWindow>
    ),
    setUpdateModalOpen,
  };
}

export function AppUpdateNotificationIcon() {
  const { hasUpdateAvailable } = useContext(UpdateContext);
  const { setUpdateModalOpen, updateModalContent } =
    useAppUpdateNotificationModal();
  const [wasClicked, setWasClicked] = useState(false);
  return (
    <>
      {hasUpdateAvailable && (
        <>
          <Button
            variant="ghost_icon"
            title="Application Update Available"
            onClick={() => {
              setUpdateModalOpen(true);
              setWasClicked(true);
            }}
          >
            <Icon
              data={new_alert}
              style={{
                ...(wasClicked ? {} : { animation: "pulse 2s infinite" }),
              }}
            />
          </Button>
          {updateModalContent}
        </>
      )}
    </>
  );
}
