import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

type MaterialGroupPostfixes =
  | undefined
  | "properties"
  | "master-pipe-elements"
  | "material-group-verification"
  | "structured-pipe-elements-addition";

export type MaterialGroup = {
  MaterialGroupID: number;
  MaterialGroup: string;
  Revision: string;
  Status: string;
  RevDate: string;
  LastUpdate: string;
  LastUpdateBy: string;
  Notepad: string;
};

type MaterialGroupProperties = { Notepad: string };

type MasterPipeElementValidities = {
  ValidityType: string;
  ValidityID: number;
};

type MasterPipeElementItems = {
  MasterPipeElementID: number;
  MasterPipeElementRevision: string;
  DimStandardID: number;
  DimStandard: string;
  ProductFormID: number;
  ProductForm: string;
  MaterialGradeID: number;
  MaterialGrade: string;
  Priority: number;
  WeldingFactorIndicator: "Y" | "N";
  WeldingFactorValue: number;
  AddDimensionManually: "Y" | "N";
  ProductFormLevel2ID: number;
  FreeTextNote: string;
  MasterPipeElementValidities: MasterPipeElementValidities[];
};

export type MasterPipeElements = {
  ElementGroupID: number;
  ElementGroup: string;
  ManualDimension: "Y" | "N";
  MasterPipeElements: MasterPipeElementItems[];
};

export type StructuredPipeElementsAddition = {
  DimStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  OperatorName: string;
  Area: string;
  SpecialRequirement: string;
  TypeOfChange: "R" | "N";
};

type VerifyMGSPENotReady = {
  ElementGroup: string;
  DimStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  OperatorName: string;
  Area: string;
  SpecialRequirement: string;
  Cause: string;
};

export type VerifyMGSPENotValid = {
  ElementGroup: string;
  DimStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  OperatorName: string;
  Area: string;
  SpecialRequirement: string;
  ElementID: string;
  Revision: string;
  MasterPipeElementID: number;
};

type MaterialGroupVerification = {
  getVerifyMGSPENotReady: VerifyMGSPENotReady[];
  getVerifyMGSPENotValid: VerifyMGSPENotValid[];
  getVerifyMGMatrices: {}[];
};

type MaterialGroupsReply<T> = T extends undefined
  ? { getPipeMaterialGroup: MaterialGroup[] }
  : T extends "properties"
  ? { getPMGProperties: MaterialGroupProperties[] }
  : T extends "master-pipe-elements"
  ? {
      getMasterPipeElements: MasterPipeElements[];
    }
  : T extends "structured-pipe-elements-addition"
  ? { getStructuredPipeElementAddition: StructuredPipeElementsAddition[] }
  : T extends "material-group-verification"
  ? MaterialGroupVerification
  : never;

type MaterialGroupsReturn<T> = T extends undefined
  ? MaterialGroup[]
  : T extends "properties"
  ? MaterialGroupProperties
  : T extends "master-pipe-elements"
  ? MasterPipeElements[]
  : T extends "structured-pipe-elements-addition"
  ? StructuredPipeElementsAddition[]
  : T extends "material-group-verification"
  ? MaterialGroupVerification
  : never;

export function useMaterialGroups<T extends MaterialGroupPostfixes>({
  name,
  revision,
  postfix,
  materialGroupId,
  disabled,
}: {
  postfix: T extends unknown ? T : never;
  name?: string;
  revision?: string;
  materialGroupId?: string;
  disabled?: boolean;
}) {
  return useQuery<MaterialGroupsReturn<T>, Error>(
    ["pmg", "material-groups", name, revision, postfix, materialGroupId],
    async () => {
      const { data } = await axios.get<MaterialGroupsReply<T>>(
        getApiUrl(
          "pmg/material-groups/" +
            (name && revision ? name + "/rev/" + revision : "") +
            (postfix ? "/" + postfix : "") +
            (materialGroupId ? `?MaterialGroupID=${materialGroupId}` : "")
        )
      );

      return (
        postfix === undefined
          ? (data as MaterialGroupsReply<undefined>).getPipeMaterialGroup
          : postfix === "properties"
          ? (data as MaterialGroupsReply<"properties">).getPMGProperties[0]
          : postfix === "master-pipe-elements"
          ? (data as MaterialGroupsReply<"master-pipe-elements">)
              .getMasterPipeElements
          : postfix === "structured-pipe-elements-addition"
          ? (data as MaterialGroupsReply<"structured-pipe-elements-addition">)
              .getStructuredPipeElementAddition
          : data
      ) as MaterialGroupsReturn<T>;
    },
    { enabled: !disabled }
  );
}
