import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getApiUrl } from "../../../queries/queryUtil";

export type ActivityLogParams = {
  ListItems: string;
  Category: "M" | "S";
};

export default function useActivityLogParams() {
  return useQuery<ActivityLogParams[], AxiosError<Error>>(
    ["reports", "activity-log-parameter-list"],
    async () => {
      const { data } = await axios.get<{
        getActivityLogParamList: ActivityLogParams[];
      }>(getApiUrl(`reports/activity-log-parameter-list`));
      return data.getActivityLogParamList;
    }
  );
}
