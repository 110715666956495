import {
  assignment_user,
  category,
  comment,
  library_books,
  platform,
  radio_button_selected,
  swap_horizontal_circle,
  view_module,
} from "@equinor/eds-icons";
import { Access } from "Access";
import { Category, MenuItem } from "components/MainMenuComponents";

export const adminCodeTables = [
  { url: "pipe-element-groups", title: "Pipe Element Groups" },
  { url: "valve-element-groups", title: "Valve Element Groups" },
  { url: "valve-types", title: "Valve Types" },
  { url: "vds-design", title: "VDS Design" },
  { url: "vds-material", title: "VDS Material" },
  { url: "vds-characteristics", title: "VDS Characteristics" },
  { url: "vds-misc", title: "VDS Miscellaneous" },
  { url: "vds-subsegment-groups", title: "VDS Subsegment Groups" },
  { url: "pmg-dimension-standards", title: "PMG Dimension Standards" },
  { url: "pmg-product-forms", title: "PMG Product Forms", icon: "view_module" },
  { url: "product-form-level2-mapping", title: "PMG PFL2 Mapping" },
];

export function AdminMenu() {
  return (
    <Access cap="admin" module="admin" hide>
      <Access cap={["admin", "admin_pcs_notes"]} hide>
        <Access cap={["admin", "sheets"]} hide>
          <Category title="Administration" addCategoryTitle>
            <MenuItem title="Users" to="/admin/users" icon={assignment_user} />
            <MenuItem title="Plants" to="/admin/plants" icon={platform} />
            <MenuItem
              title="Plant Mapping"
              to="/admin/plant-mapping"
              icon={swap_horizontal_circle}
              role="X"
              cap="admin_plant_mapping"
              andOperator
              notExact
            />
            <MenuItem
              title="Document Space"
              to="/document-space"
              icon={library_books}
              role="A"
            />
            <MenuItem
              title="PCS Notes – Pipe Section"
              to="/admin/pcs-standard-notes/PCS"
              icon={comment}
              cap="sheets"
              role="A"
            />
            <MenuItem
              title="PCS Notes – Valve Section"
              to="/admin/pcs-standard-notes/VDS"
              icon={comment}
              cap="sheets"
              role="A"
            />
            <MenuItem
              title="Material Groups"
              to="/admin/material-groups"
              icon={category}
              role="A"
            />
            <MenuItem
              title="VDS Subsegment Config"
              to="/admin/vds-subsegment-groups-config"
              icon={radio_button_selected}
              role="X"
            />
            <MenuItem
              title="Code Tables"
              to="/admin/codetables"
              icon={view_module}
            >
              {adminCodeTables.map((codeTable) => (
                <MenuItem
                  key={codeTable.url}
                  title={codeTable.title}
                  to={`/admin/${codeTable.url}`}
                  icon={view_module}
                />
              ))}
            </MenuItem>
          </Category>
        </Access>
      </Access>
    </Access>
  );
}
