import { Button, Label } from "@equinor/eds-core-react";
import useConfirm from "components/confirm/useConfirm";
import { Field } from "components/form/Field";
import {
  Fieldset,
  FieldsetContainer,
  InfoContainer,
} from "components/form/Form";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import useSavePlantMapping from "queries/useSavePlantMapping";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { successMessage } from "utils/successMessage";
import { closeAdminModal } from "../Admin";
import { PlantMapping } from "./plantMappingConfig";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";

export function PlantMappingEdit({
  item,
  remoteSystem,
}: {
  item?: PlantMapping;
  remoteSystem: string;
}) {
  const history = useHistory();

  const closeModal = useCallback(() => {
    closeAdminModal({ history });
  }, [history]);

  const {
    mutate: savePlantMapping,
    status: savePlantMappingStatus,
    error: savePlantMappingError,
    data: savePlantMappingData,
    reset: savePlantMappingReset,
  } = useSavePlantMapping();

  const methods = useForm<PlantMapping>({
    shouldUnregister: true,
    mode: "all",
  });
  const { handleSubmit, formState, reset, register } = methods;
  const onSubmit = handleSubmit((data) => {
    savePlantMapping({ remoteSystem, data });
  });

  const { isConfirmed } = useConfirm();

  const checkIfDirtyThenCloseModal = async () => {
    Object.keys(formState.dirtyFields).length > 0
      ? (await isConfirmed(defaultUnsavedMessage)) && closeModal()
      : closeModal();
  };

  useEffect(() => {
    item && reset(item);
  }, [item, reset]);

  return (
    <>
      <ModalWindow
        isOpen={!!item}
        closeModal={checkIfDirtyThenCloseModal}
        title={`Edit Plant Mapping ${item?.MappingID}`}
      >
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <ModalSideMargin>
              <ModalWindowContentDefault style={{ minWidth: 500 }}>
                <FieldsetContainer slim>
                  <Fieldset>
                    <input
                      type="hidden"
                      defaultValue={item?.MappingID}
                      {...register("MappingID")}
                    />
                    <Label label="Remote System"></Label>
                    <InfoContainer>{item?.RemoteSystemName}</InfoContainer>
                    <Label label="Short Description"></Label>
                    <InfoContainer>{item?.ShortDescription}</InfoContainer>
                    <Field
                      prop="RemoteSystemPlantID"
                      title="Remote System Plant ID"
                      value={item ? item.RemoteSystemPlantID : ""}
                      type="text"
                    />
                  </Fieldset>
                </FieldsetContainer>
              </ModalWindowContentDefault>
            </ModalSideMargin>
            <ModalWindowButtonContainer>
              <Button
                type="submit"
                disabled={savePlantMappingStatus === "loading"}
              >
                Update
              </Button>
              <Button variant="outlined" onClick={checkIfDirtyThenCloseModal}>
                Close
              </Button>
            </ModalWindowButtonContainer>
          </form>
        </FormProvider>
      </ModalWindow>
      <StatusModal
        status={savePlantMappingStatus}
        error={savePlantMappingError}
        successMessage={successMessage(savePlantMappingData)}
        onSettledClose={savePlantMappingReset}
      />
    </>
  );
}
