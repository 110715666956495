import axios, { AxiosResponse } from "axios";
import { CreateVDSTextBlock, SaveVDSTextBlock } from "features/sheets/types";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { StatusResponseWithInfo } from "components/StatusModal";

export default function useSaveVDSTextBlock() {
  const queryClient = useQueryClient();

  async function saveSheet({
    name,
    revision,
    content,
    method,
  }: {
    name?: string;
    revision?: string;
    content: SaveVDSTextBlock | CreateVDSTextBlock;
    method: "put" | "post";
  }): Promise<AxiosResponse<StatusResponseWithInfo>> {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-textblocks",
        name,
        revision,
      }),
      {
        method,
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(saveSheet, {
    onSuccess: (data, variables, context) => {
      variables.method === "put" &&
        queryClient.invalidateQueries([
          "sheet",
          "vds-textblocks",
          variables.name,
          variables.revision,
        ]);
      queryClient.invalidateQueries(["sheets", "vds-textblocks"]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
  });
}
