import {
  Button,
  CircularProgress,
  EdsProvider,
  Icon,
  NativeSelect,
  Typography,
} from "@equinor/eds-core-react";
import {
  comment_chat,
  crop,
  delete_forever,
  edit,
  refresh,
} from "@equinor/eds-icons";
import {
  FlexContainer,
  HrLine,
  RevisionMark,
  TotalCenter,
} from "components/Components";
import StatusModal from "components/StatusModal";
import Table, { ColumnsProps } from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import { useMaterialGroups } from "features/pmg/queries/useMaterialGroups";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useOperators from "queries/useOperators";
import React, { useEffect, useMemo, useState } from "react";
import { successMessage } from "utils/successMessage";
import {
  UseDeleteMasterPipeElement,
  useDeleteMasterPipeElement,
} from "../queries/useDeleteMasterPipeElement";
import {
  CreateMasterPipeElement,
  EditMasterPipeElement,
} from "./EditMasterPipeElement";
import {
  flattenMasterPipeElements,
  HeaderContainer,
  ButtonContainer,
  InfoContainer,
  InfoTitle,
  InfoContent,
  IconCenter,
  FlatMasterPipeElements,
} from "./EditPipeMaterialGroup";
import useConfirm from "components/confirm/useConfirm";

export const masterPipeElementColumns: ColumnsProps<
  FlatMasterPipeElements[number]
>[] = [
  {
    key: "AddDimensionManually",
    title: "M. D.",
    longTitle: "Manual Dimension",
    Component: ManualDimensionColumn,
  },
  { key: "MasterPipeElementID", title: "ID", width: 50 },
  {
    key: "MasterPipeElementRevision",
    title: "Rev.",
    longTitle: "Revision",
    width: 50,
    Component: RevisionMark,
  },
  { key: "DimStandard", title: "Dimensions Standard", width: 180 },
  { key: "ProductForm", title: "Product Form", width: 230 },
  { key: "MaterialGrade", title: "Material Grade", width: 200 },
  { key: "Priority", title: "Priority", width: 60 },
  {
    key: "WeldingFactorValue",
    title: "WF",
    longTitle: "Welding Factor",
    width: 60,
    type: "with-context",
    Component: WeldingFactor,
  },
  {
    key: "ProductFormLevel2ID",
    title: "Product Form Level 2",
    codelist: "product-form-level2",
    width: "100%",
  },
  {
    key: "FreeTextNote",
    title: "Note",
    Component: ElementNoteIcon,
  },
];

function WeldingFactor({ item }: { item: FlatMasterPipeElements[number] }) {
  return (
    <>{item.WeldingFactorIndicator === "N" ? "N/A" : item.WeldingFactorValue}</>
  );
}

function ElementNoteIcon({ children }: { children: string }) {
  return children ? (
    <IconCenter>
      <Icon data={comment_chat} title={children} size={16} />
    </IconCenter>
  ) : null;
}

function ManualDimensionColumn({ children }: { children: string }) {
  return children ? (
    <IconCenter>
      <Icon
        data={crop}
        title={children === "Y" ? "Add Dimension Manually" : ""}
        size={16}
        style={{ visibility: children === "Y" ? "visible" : "hidden" }}
      />
    </IconCenter>
  ) : null;
}

export function MasterPipeElementRowMenu({
  item,
  name,
  revision,
  setEditMPE,
  deleteMasterPipeElement,
}: {
  item: FlatMasterPipeElements[number];
  name: string;
  revision: string;
  setEditMPE: React.Dispatch<number | false>;
  deleteMasterPipeElement: UseDeleteMasterPipeElement["mutate"];
}) {
  const { isConfirmed } = useConfirm();
  return (
    <>
      <Button
        variant="ghost_icon"
        onClick={() => {
          setEditMPE(item.MasterPipeElementID);
        }}
        title="Edit Master Pipe Element"
      >
        <Icon data={edit} />
      </Button>
      <Button
        variant="ghost_icon"
        onClick={async () => {
          (await isConfirmed(
            <div>
              <p>
                Note that the Structured Pipe Elements for this Master Pipe
                Element will also be deleted.{" "}
              </p>
              <p>
                Are you sure you want to delete Master Pipe Element{" "}
                {item.MasterPipeElementID}?
              </p>
            </div>,
            { buttonColor: "danger" }
          )) &&
            deleteMasterPipeElement({
              masterPipeElementId: item.MasterPipeElementID,
              materialGroupId: name,
              materialGroupRevision: revision,
            });
        }}
        title="Delete Master Pipe Element"
      >
        <Icon data={delete_forever} />
      </Button>
    </>
  );
}

export function useMasterPipeElements({
  name,
  revision,
  isEdit,
}: {
  name: string;
  revision: string;
  isEdit: boolean;
}) {
  const { data, status, error, isRefetching, refetch } = useMaterialGroups({
    name,
    revision,
    postfix: "master-pipe-elements",
  });

  const flatData = useMemo(() => flattenMasterPipeElements(data), [data]);

  const { selection: masterPipeElementSelection, selectionDispatch } =
    useTableSelect({ selectionMode: "single" });

  const selectedLine = useMemo(
    () =>
      flatData?.find(
        (e) => e.MasterPipeElementID === Number(masterPipeElementSelection[0])
      ),
    [flatData, masterPipeElementSelection]
  );

  const groups = useMemo(
    () =>
      flatData.reduce((a, c) => {
        if (a.includes(c.elementGroupName)) {
          return a;
        }
        return [...a, c.elementGroupName];
      }, [] as string[]),
    [flatData]
  );

  const [selectedGroup, setSelectedGroup] = useState("");

  const visibleData = useMemo(
    () =>
      flatData.filter(
        (e) => e.elementGroupName === selectedGroup || !selectedGroup
      ),
    [flatData, selectedGroup]
  );

  const { data: operatorData, status: operatorStatus } = useOperators();
  const { data: areaData, status: areaStatus } = useCodelist({
    codelist: "areas",
  });
  const { data: specialRequirementsData, status: specialRequirementsStatus } =
    useCodelist({ codelist: "special-requirements" });

  const operatorValidity = useMemo(
    () =>
      selectedLine?.MasterPipeElementValidities.filter(
        (e) => e.ValidityType === "O"
      ),
    [selectedLine]
  );
  const areaValidity = useMemo(
    () =>
      selectedLine?.MasterPipeElementValidities.filter(
        (e) => e.ValidityType === "A"
      ),
    [selectedLine]
  );
  const specialRequirementValidity = useMemo(
    () =>
      selectedLine?.MasterPipeElementValidities.filter(
        (e) => e.ValidityType === "S"
      ),
    [selectedLine]
  );

  const { data: pipeElementGroups, status: pipeElementGroupsStatus } =
    useCodelist({
      codelist: "pipe-element-groups",
    });

  const combinedStatuses = combineQueryStatuses(
    status,
    operatorStatus,
    areaStatus,
    specialRequirementsStatus,
    pipeElementGroupsStatus
  );

  const [editMPE, setEditMPE] = useState<number | false>(false);
  const [createNewMPE, setCreateNewMPE] = useState(false);
  const [elementGroupId, setElementGroupId] = useState<number | undefined>();

  useEffect(() => {
    pipeElementGroups && setElementGroupId(pipeElementGroups[0].ElementGroupID);
  }, [pipeElementGroups, setElementGroupId]);

  useEffect(() => {
    const selectedGroupId = flatData.find(
      (e) => e.elementGroupName === selectedGroup
    )?.elementGroupId;
    selectedGroupId && setElementGroupId(selectedGroupId);
  }, [flatData, selectedGroup, setElementGroupId]);

  const {
    mutate: deleteMasterPipeElement,
    status: deleteMasterPipeElementStatus,
    error: deleteMasterPipeElementError,
    data: deleteMasterPipeElementData,
    reset: deleteMasterPipeElementReset,
  } = useDeleteMasterPipeElement();

  const masterPipeElementsContent = (
    <>
      <Table
        itemIdProp="MasterPipeElementID"
        items={visibleData}
        columns={masterPipeElementColumns as ColumnsProps[]}
        density="compact"
        groupBy="elementGroupId"
        groupTitle="elementGroupName"
        groupIdProp="elementGroupId"
        status={combinedStatuses}
        selectionDispatch={selectionDispatch}
        selection={masterPipeElementSelection}
        selectionMode="single"
        fullRowSelect
        error={error}
        codelistsToQuery={["product-form-level2"]}
        RowMenu={isEdit ? MasterPipeElementRowMenu : undefined}
        contextData={{
          setEditMPE,
          flatData,
          deleteMasterPipeElement,
          name,
          revision,
        }}
        loadingString="Loading Master Pipe Elements..."
        controlHeader={
          <HeaderContainer>
            <FlexContainer gap>
              <FlexContainer gap flexStart>
                <div>
                  <Typography variant="h5">Master Pipe Elements</Typography>
                </div>
                {isEdit && (
                  <ButtonContainer>
                    <EdsProvider density="comfortable">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setCreateNewMPE(true);
                        }}
                      >
                        Add a Master Pipe Element
                      </Button>
                    </EdsProvider>
                  </ButtonContainer>
                )}
              </FlexContainer>
              <div>
                <FlexContainer gap>
                  <div>
                    <CircularProgress
                      size={16}
                      style={{
                        visibility: isRefetching ? "visible" : "hidden",
                      }}
                    />
                  </div>
                  <div>
                    <ButtonContainer>
                      <Button
                        onClick={() => refetch()}
                        variant="ghost_icon"
                        title="Reload Master Pipe Elements"
                      >
                        <Icon data={refresh} />
                      </Button>
                    </ButtonContainer>
                  </div>
                  <div style={{ marginTop: -5 }}>
                    <NativeSelect
                      id="groups"
                      label=""
                      onChange={(e) => setSelectedGroup(e.target.value)}
                    >
                      <option value="">- All Element Groups -</option>
                      {groups.map((group) => (
                        <option key={group} value={group}>
                          {group}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </FlexContainer>
              </div>
            </FlexContainer>
          </HeaderContainer>
        }
      />
      {editMPE && (
        <EditMasterPipeElement
          materialGroupId={name}
          materialGroupRevision={revision}
          editMPE={editMPE}
          setEditMPE={setEditMPE}
          flatData={flatData}
        />
      )}
      {createNewMPE && (
        <CreateMasterPipeElement
          materialGroupId={name}
          materialGroupRevision={revision}
          setShowWindow={setCreateNewMPE}
          elementGroupId={elementGroupId}
          setElementGroupId={setElementGroupId}
          pipeElementGroups={pipeElementGroups}
        />
      )}
      <StatusModal
        status={deleteMasterPipeElementStatus}
        error={deleteMasterPipeElementError}
        successMessage={successMessage(deleteMasterPipeElementData)}
        onSuccessClose={() => {
          deleteMasterPipeElementReset();
        }}
      />
    </>
  );

  const masterPipeElementInfo = !selectedLine ? (
    <TotalCenter>Select a Master Pipe Element</TotalCenter>
  ) : (
    <div style={{ marginTop: 12 }}>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        Master Pipe Element Validity
      </Typography>
      <InfoContainer>
        <InfoTitle>Operators:</InfoTitle>
        <InfoContent>
          {operatorStatus === "success" ? (
            operatorValidity?.length === 0 ? (
              <i>All</i>
            ) : operatorValidity?.length === 1 ? (
              operatorValidity?.map(
                (e) =>
                  operatorData?.find((f) => f.OperatorID === e.ValidityID)
                    ?.OperatorName
              )
            ) : (
              <ul style={{ margin: 0, paddingLeft: 24 }}>
                {operatorValidity?.map((e) => (
                  <li key={e.ValidityID}>
                    {
                      operatorData?.find((f) => f.OperatorID === e.ValidityID)
                        ?.OperatorName
                    }
                  </li>
                ))}
              </ul>
            )
          ) : operatorStatus === "loading" ? (
            <>Loading...</>
          ) : operatorStatus === "error" ? (
            <>Error loading Operators.</>
          ) : null}
        </InfoContent>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>Areas:</InfoTitle>
        <InfoContent>
          {areaStatus === "success" ? (
            areaValidity?.length === 0 ? (
              <i>All</i>
            ) : areaValidity?.length === 1 ? (
              areaValidity?.map(
                (e) =>
                  areaData?.find((f) => f.AreaID === e.ValidityID)?.Description
              )
            ) : (
              <ul style={{ margin: 0, paddingLeft: 24 }}>
                {areaValidity?.map((e) => (
                  <li key={e.ValidityID}>
                    {
                      areaData?.find((f) => f.AreaID === e.ValidityID)
                        ?.Description
                    }
                  </li>
                ))}
              </ul>
            )
          ) : areaStatus === "loading" ? (
            <>Loading...</>
          ) : areaStatus === "error" ? (
            <>Error loading Areas.</>
          ) : null}
        </InfoContent>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>Special Requirements:</InfoTitle>
        <InfoContent>
          {specialRequirementsStatus === "success" ? (
            specialRequirementValidity?.length === 0 ? (
              <i>None</i>
            ) : specialRequirementValidity?.length === 1 ? (
              specialRequirementValidity?.map(
                (e) =>
                  specialRequirementsData?.find(
                    (f) => f.SpecialRequirementID === e.ValidityID
                  )?.Description
              )
            ) : (
              <ul style={{ margin: 0, paddingLeft: 24 }}>
                {specialRequirementValidity?.map((e) => (
                  <li key={e.ValidityID}>
                    {
                      specialRequirementsData?.find(
                        (f) => f.SpecialRequirementID === e.ValidityID
                      )?.Description
                    }
                  </li>
                ))}
              </ul>
            )
          ) : specialRequirementsStatus === "loading" ? (
            <>Loading...</>
          ) : specialRequirementsStatus === "error" ? (
            <>Error loading Special Requirements.</>
          ) : null}
        </InfoContent>
      </InfoContainer>
      <HrLine style={{ marginTop: 20, marginBottom: 20 }} />
      <InfoContainer>
        <InfoTitle>Note:</InfoTitle>
        <InfoContent>{selectedLine.FreeTextNote || <i>No Note</i>}</InfoContent>
      </InfoContainer>
    </div>
  );

  return {
    masterPipeElementsContent,
    masterPipeElementInfo,
    masterPipeElementSelection,
    masterPipeElementSelectedLine: selectedLine,
  };
}
