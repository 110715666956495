import { UserConfigContext } from "UserConfigContext";
import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useCallback, useContext } from "react";
import { useMutation } from "react-query";

function useSaveSetting() {
  async function saveSetting({ key, value }: { key: string; value: string }) {
    return await axios(getApiUrl(`users/settings/key/${key}`), {
      method: "put",
      data: value,
    });
  }

  return useMutation(saveSetting, {
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
  });
}

export function useUpdateSetting() {
  const { mutate } = useSaveSetting();
  const { settingsRef } = useContext(UserConfigContext);

  const updateSetting = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      settingsRef.current = { ...settingsRef.current, [key]: value };
      mutate({ key, value });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { updateSetting };
}
