import { useQuery } from "react-query";
import axios from "axios";
import { IssueSheetTypes } from "../features/sheets/config/sheetConfig";
import { SheetStatus } from "../features/sheets/config/statusConfig";
import { PlantProps } from "../features/plant/types";
import config from "config/config";

export interface Issue {
  IssueRevision: string;
  Status: string;
  RevDate: string;
  ProtectStatus: string;
  GeneralRevision: string;
  GeneralRevDate: string;
  PCSRevision: string;
  PCSRevDate: string;
  EDSRevision: string;
  EDSRevDate: string;
  VDSRevision: string;
  VDSRevDate: string;
  VSKRevision: string;
  VSKRevDate: string;
  MDSRevision: string;
  MDSRevDate: string;
  ESKRevision: string;
  ESKRevDate: string;
  SCRevision: string;
  SCRevDate: string;
  VSMRevision: string;
  VSMRevDate: string;
  UserName: string;
  UserEntryTime: string;
}

type IssueList = {
  UserEntryTime: string;
  UserName: string;
  UserProtected: "" | " " | "P";
  getIssueList: Issue[];
};

interface DefaultSheetProps {
  Revision: string;
  RevDate: string;
  Status: SheetStatus;
  OfficialRevision: string;
  Delta: "Y" | "N";
}

export type IssuePCSList = (DefaultSheetProps & {
  PCS: string;
  RevisionSuffix: string;
  RatingClass: string;
  MaterialGroup: string;
  HistoricalPCS: string;
})[];

export type IssueSCList = (DefaultSheetProps & {
  SC: string;
})[];

export type IssueVSMList = (DefaultSheetProps & {
  VSM: string;
})[];

export type IssueVDSList = (DefaultSheetProps & {
  VDS: string;
})[];

export type IssueEDSList = (DefaultSheetProps & {
  EDS: string;
})[];

export type IssueMDSList = (DefaultSheetProps & {
  MDS: string;
  Area: string;
})[];

export type IssueESKList = (DefaultSheetProps & {
  ESK: string;
})[];

export type IssueVSKList = (DefaultSheetProps & {
  VSK: string;
})[];

export type IssueMDSValidationList = {
  ElementGroup: string;
  DimensionStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  Area: string;
  ElementID: number;
  Revision: string;
  RevisionDate: string;
  Status: SheetStatus;
}[];

export type IssuePipeElementsList = (Omit<
  DefaultSheetProps,
  "OfficialRevision"
> & {
  ElementGroup: string;
  DimensionStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  Area: string;
  ElementID: string;
})[];

type PCSSubRevisionList = {
  PCS: string;
  Revision: string;
}[];

type IssueRequest =
  | "issuelist"
  | "issue"
  | "make-official-pcs-subrevision-list"
  | "validation"
  | IssueSheetTypes;

// prettier-ignore
export type IssueReturnType<T> =
    T extends "issuelist" ? IssueList
  : T extends "issue" ? IssueList
  : T extends "pcs" ? IssuePCSList
  : T extends "sc" ? IssueSCList
  : T extends "vsm" ? IssueVSMList
  : T extends "eds" ? IssueEDSList
  : T extends "vds" ? IssueVDSList
  : T extends "vsk" ? IssueVSKList
  : T extends "mds" ? IssueMDSList
  : T extends "esk" ? IssueESKList
  : T extends "pipe-elements" ? IssuePipeElementsList
  : T extends "validation" ? IssueMDSValidationList
  : T extends "make-official-pcs-subrevision-list" ? PCSSubRevisionList
  : never;

const issueListProperties = {
  issue: "getIssueList",
  issuelist: "",
  pcs: "getIssuePCSList",
  sc: "getIssueSCList",
  vsm: "getIssueVSMList",
  eds: "getIssueEDSList",
  vds: "getIssueVDSList",
  vsk: "getIssueVSKList",
  mds: "getIssueMDSList",
  esk: "getIssueESKList",
  "pipe-elements": "getIssuePipeElementList",
  validation: "getIssueMDSValidationList",
  "make-official-pcs-subrevision-list": "getIssuePCSSubRevList",
};

const nonRevisionRequest = ["issuelist", "make-official-pcs-subrevision-list"];

export default function useIssues<T extends IssueRequest>({
  plant,
  revision,
  request,
  disabled,
}: {
  plant: PlantProps;
  revision?: string;
  request: T;
  disabled?: boolean;
}) {
  return useQuery<IssueReturnType<T>, Error>(
    ["issues", plant.PlantID, request, revision],
    async () => {
      let urlParts = [
        "plants",
        plant.PlantID,
        "issues",
        ...(!nonRevisionRequest.includes(request) && revision
          ? ["rev", revision]
          : []),
        ...(request !== "issue" && request !== "issuelist" ? [request] : []),
      ];
      const { data } = await axios.get(
        config.restApiBaseUrl + urlParts.join("/")
      );
      return issueListProperties.hasOwnProperty(request) &&
        issueListProperties[request as keyof typeof issueListProperties] !== ""
        ? data[issueListProperties[request as keyof typeof issueListProperties]]
        : data;
    },
    { cacheTime: 0, enabled: !disabled }
  );
}
