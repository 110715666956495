import { FiltersDispatch } from "components/table/Filters";
import { useEffect } from "react";
import { PCSStandardNotes } from "./pcsStandardNotesConfig";

export function PCSStandardNotesFilter({
  data,
  filtersDispatch,
  categoryFilter,
  setCategoryFilter,
}: {
  data?: PCSStandardNotes[];
  filtersDispatch: FiltersDispatch;
  categoryFilter: string;
  setCategoryFilter: React.Dispatch<string>;
}) {
  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Category",
        prop: "Category",
        filterOptions: data
          ? (data as PCSStandardNotes[])
              .map((i) => ({
                key: i.Category,
                title: i.Category,
              }))
              .reduce(
                (a, c) =>
                  a.map((e) => e.key).includes(c.key) ? a : a.concat(c),
                [] as { key: string; title: string }[]
              )
          : [],
        filterState: categoryFilter,
        defaultFilterState: "",
        setFilterState: setCategoryFilter,
        group: "",
      },
    });
    return () => filtersDispatch({ type: "reset" });
  }, [data, categoryFilter, setCategoryFilter, filtersDispatch]);
  return null;
}
