import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

export function usePlantList({
  oid,
  enabled,
}: {
  oid: number;
  enabled: boolean;
}) {
  return useQuery(
    ["document-space", "plants", oid],
    async () => {
      const { data } = await axios.get<{
        getPlantList: { PlantID: number; Description: string }[];
      }>(getApiUrl(["document-space", oid, "plant-list"].join("/")));
      return data.getPlantList;
    },
    {
      enabled: !!oid && enabled,
    }
  );
}
