import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";
import { GroupPropertyWithCodelistColumn } from "components/table/Columns";

type VSKGroupRead = {
  GroupID: number;
  Description: string;
};

type VSKGroupWrite = {
  GroupID: number;
};

export type VDSMisc = {
  ItemID: number;
  Description: string;
  VSKGroups: VSKGroupRead[];
};

export type VDSMiscCreate = {
  ItemID: number;
  Description: string;
  VSKGroups: VSKGroupWrite[];
};

export type VDSMiscUpdate = CommonUpdateProps & {
  Description: string;
  VSKGroups: VSKGroupWrite[];
};

export const vdsMiscConfig: ItemAdminConfig<"vds-misc"> = {
  title: "VDS Miscellaneous Requirements",
  singularTitle: "VDS Miscellaneous Requirement",
  url: "codelists/vds-misc",
  itemIdProp: "ItemID",
  apiListProp: "getVDSMisc",
  nameProperty: ["ItemID", "Description"],
  columns: [
    {
      key: "ItemID",
      title: "ID",
    },
    { key: "Description", title: "Description", width: "30%" },
    {
      key: "VSKGroups",
      title: "VSK Groups",
      Component: GroupPropertyWithCodelistColumn,
      type: "with-context",
      componentProps: {
        groupProperty: "VSKGroups",
        property: "GroupID",
        codelist: "vsk-groups",
      },
      width: "70%",
    },
  ],
};
