import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getApiUrl } from "../../../queries/queryUtil";
import { StatusResponseWithInfo } from "components/StatusModal";

export default function useNewMaterialGroupRevision() {
  const queryClient = useQueryClient();

  async function newMaterialGroupRevision({
    name,
    revision,
  }: {
    name: string;
    revision: string;
  }): Promise<AxiosResponse<StatusResponseWithInfo>> {
    return await axios({
      url: getApiUrl(
        "pmg/material-groups/" + name + "/rev/" + revision + "/revision"
      ),
      method: "post",
    });
  }

  return useMutation(newMaterialGroupRevision, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["pmg", "material-groups"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
  });
}
