import { useEffect } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { selectMainPlant } from "../../../plant/plantSlice";
import {
  AbsoluteFlexContainer,
  FlexContentElement,
  FlexContainer,
  FlexElement,
} from "../../../../components/Components";
import { PreviewButtons, PreviewButtonsContainer } from "../../SheetPane";
import { PCSHeadingProps } from "../../types";
import useSheets from "../../queries/useSheets";
import { useFormContext } from "react-hook-form";
import { FieldsetContainer, Fieldset } from "../../../../components/form/Form";
import useMeasure from "react-use-measure";
import { Matrix, Note } from "../../../../components/form/EditComponents";
import { FieldContext } from "../../../../components/form/FieldContext";
import { Field } from "../../../../components/form/Field";
import { QueryStatus } from "react-query";
import { useMemo } from "use-memo-one";

export function PCSHeading({
  data,
  setIsLoaded,
  isRefetching,
  status,
  saveSheetStatus,
}: {
  data: PCSHeadingProps;
  setIsLoaded: Function;
  isRefetching: boolean;
  status: QueryStatus;
  saveSheetStatus: QueryStatus;
}) {
  const plant = useAppSelector(selectMainPlant);
  const { data: SCData, status: SCStatus } = useSheets({
    plant: plant,
    sheetType: "sc",
    extraParams: { statuses: ["O", "W"] },
  });
  const { data: VSMData, status: VSMStatus } = useSheets({
    plant: plant,
    sheetType: "vsm",
    extraParams: { statuses: ["O", "W"] },
  });
  if (SCStatus === "error") {
    throw new Error("SC failed to load.");
  }
  if (VSMStatus === "error") {
    throw new Error("VSM failed to load.");
  }
  useEffect(() => {
    setIsLoaded(
      SCStatus === "success" && VSMStatus === "success" && !isRefetching
    );
  }, [setIsLoaded, SCStatus, VSMStatus, isRefetching]);

  const { watch, reset } = useFormContext();

  useEffect(() => {
    if (saveSheetStatus === "success" && !isRefetching) {
      reset(data);
    }
    // Sets the form data after successful saving.
    // isRefetching should be taken into account,
    // because resetting the form before the refetch finishes
    // would reset the form to its previous state.
  }, [reset, saveSheetStatus, isRefetching, data]);

  useEffect(() => {
    status === "success" && reset(data);
    // Sets defaultValues of the form, needed because the data
    // is loaded later than useForm is called for the first time.
    // eslint-disable-next-line
  }, [status]);

  const [fieldsRef, fieldsSize] = useMeasure();
  const [notesRef, notesSize] = useMeasure();
  // This approach has a limitation: switches only "back" to column while enlarging the viewport
  // from a reduced size when the element's length as _row_ would make it not wrap.
  const currentSC = watch("SC");
  const currentVSM = watch("VSM");

  const scOptions = useMemo(
    () =>
      SCData
        ? [
            ...(Array.from(
              new Set(SCData.sheets.map((item: any) => item.SC))
            ) as string[]),
          ]
        : [],
    [SCData]
  );

  const vsmOptions = useMemo(
    () =>
      VSMData
        ? [
            ...(Array.from(
              new Set(VSMData.sheets.map((item: any) => item.VSM))
            ) as string[]),
          ]
        : [],
    [VSMData]
  );

  return (
    <AbsoluteFlexContainer>
      <FlexContentElement>
        <FieldContext.Provider value={{ hasRev: true }}>
          <FieldsetContainer>
            <Fieldset>
              <FlexContainer style={{ alignItems: "stretch" }}>
                <FlexElement ref={fieldsRef}>
                  <Field
                    prop="DesignCode"
                    title="Design Code"
                    type="text"
                    value={data.DesignCode}
                    revMark={data.DesignCodeRevMark}
                    maxWidth={200}
                    maxLength={12}
                  />
                  <Matrix
                    props={[
                      [
                        "DesignPress01",
                        "DesignPress02",
                        "DesignPress03",
                        "DesignPress04",
                        "DesignPress05",
                        "DesignPress06",
                        "DesignPress07",
                        "DesignPress08",
                        "DesignPress09",
                        "DesignPress10",
                        "DesignPress11",
                        "DesignPress12",
                      ],
                      [
                        "DesignTemp01",
                        "DesignTemp02",
                        "DesignTemp03",
                        "DesignTemp04",
                        "DesignTemp05",
                        "DesignTemp06",
                        "DesignTemp07",
                        "DesignTemp08",
                        "DesignTemp09",
                        "DesignTemp10",
                        "DesignTemp11",
                        "DesignTemp12",
                      ],
                    ]}
                    titles={[
                      "Max. Design Pressure (barg)",
                      "At Temperature (°C)",
                    ]}
                    values={[
                      [
                        data.DesignPress01,
                        data.DesignPress02,
                        data.DesignPress03,
                        data.DesignPress04,
                        data.DesignPress05,
                        data.DesignPress06,
                        data.DesignPress07,
                        data.DesignPress08,
                        data.DesignPress09,
                        data.DesignPress10,
                        data.DesignPress11,
                        data.DesignPress12,
                      ],
                      [
                        data.DesignTemp01,
                        data.DesignTemp02,
                        data.DesignTemp03,
                        data.DesignTemp04,
                        data.DesignTemp05,
                        data.DesignTemp06,
                        data.DesignTemp07,
                        data.DesignTemp08,
                        data.DesignTemp09,
                        data.DesignTemp10,
                        data.DesignTemp11,
                        data.DesignTemp12,
                      ],
                    ]}
                    revMarks={[data.DesignPressRevMark, data.DesignTempRevMark]}
                    maxWidth={900}
                    changeIndicators={[
                      data.PressElementChange.split("").map((i) =>
                        i === "1" ? true : false
                      ),
                      data.TempElementChange.split("").map((i) =>
                        i === "1" ? true : false
                      ),
                    ]}
                    min={[Array(12).fill(-1000), Array(12).fill(-500)]}
                    max={[Array(12).fill(5000), Array(12).fill(1000)]}
                  />
                </FlexElement>
                <FlexElement ref={notesRef}>
                  <FlexContainer
                    style={{
                      flexDirection:
                        fieldsSize.top < notesSize.top ? "row" : "column",
                      justifyContent: "space-around",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    <FlexElement>
                      <Note
                        noteProp="NoteIDGeneralPCS"
                        title="General PCS Note"
                        note={data.NoteIDGeneralPCS}
                      />
                    </FlexElement>
                    <FlexElement>
                      <Note
                        noteProp="NoteIDDesignCode"
                        title="Design Code Note"
                        note={data.NoteIDDesignCode}
                      />
                    </FlexElement>
                    <FlexElement>
                      <Note
                        noteProp="NoteIDPressTempTable"
                        title="Pressure / Temperature Note"
                        note={data.NoteIDPressTempTable}
                      />
                    </FlexElement>
                  </FlexContainer>
                </FlexElement>
              </FlexContainer>
            </Fieldset>
            <Fieldset>
              <Field
                prop="CorrAllowance"
                title="Corrosion Allowance"
                meta="mm"
                type="text"
                value={data.CorrAllowance}
                revMark={data.CorrAllowanceRevMark}
                note={data.NoteIDCorrAllowance}
                maxWidth={200}
                min={0}
                max={100}
              />
              <Field
                prop="SC"
                title="Service Code"
                type="option"
                value={data.SC}
                options={scOptions}
                revMark={data.ServiceRemarkRevMark}
                note={data.NoteIDServiceCode}
                noteProp="NoteIDServiceCode"
                status={SCStatus}
                nonExistingText="not in plant"
                afterInputContent={
                  <PreviewButtonsContainer>
                    <PreviewButtons
                      item={
                        SCData &&
                        SCData.sheets.find((sc: any) => sc.SC === currentSC)
                      }
                      sheetType="sc"
                      plant={plant}
                      previewLocation="editModal"
                    />
                  </PreviewButtonsContainer>
                }
              />
              <Field
                prop="ServiceRemark"
                title="Service Remark"
                type="text"
                value={data.ServiceRemark}
                note={data.NoteIDPipeSizeWthTable}
                noteProp="NoteIDPipeSizeWthTable"
                noteTitle="Pipe Size / Wall Thickness Note"
                maxLength={60}
              />
              <Field
                prop="VSM"
                title="VSM"
                type="option"
                value={data.VSM}
                options={vsmOptions}
                status={VSMStatus}
                nonExistingText="not in plant"
                afterInputContent={
                  <PreviewButtonsContainer>
                    <PreviewButtons
                      item={
                        VSMData &&
                        VSMData.sheets.find(
                          (vsm: any) => vsm.VSM === currentVSM
                        )
                      }
                      sheetType="vsm"
                      plant={plant}
                      previewLocation="editModal"
                    />
                  </PreviewButtonsContainer>
                }
              />
            </Fieldset>
            <Fieldset>
              <Field
                prop="WallThkTol"
                title="Wall Thickness Tolerance"
                type="text"
                value={data.WallThkTol}
                revMark={data.WallThkTolRevMark}
                note={data.NoteIDWallThkTol}
              />
              <Field
                prop="LongWeldEff"
                title="Longitudinal Weld Efficiency"
                type="text"
                value={data.LongWeldEff}
                revMark={data.LongWeldEffRevMark}
                note={data.NoteIDLongWeldEff}
              />
            </Fieldset>
          </FieldsetContainer>
        </FieldContext.Provider>
      </FlexContentElement>
    </AbsoluteFlexContainer>
  );
}
