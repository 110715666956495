import { ReportURIs, ReportsData, reportsConfig } from "./Reports";
import { SimpleDownloads } from "components/Downloads";

export function ReportsDownload<T extends ReportURIs>({
  report,
  data,
  filenameRef,
}: {
  report: T;
  data: ReportsData[T] | undefined;
  filenameRef: React.MutableRefObject<HTMLInputElement | null>;
}) {
  const reportConfig = reportsConfig[report];
  const { columns, items } = data
    ? reportsConfig[report].exportFunction(data)
    : { columns: [], items: [] };

  return (
    <SimpleDownloads
      columns={columns}
      items={items}
      filename={
        filenameRef.current ? filenameRef.current.value : reportConfig.title
      }
      disabled={typeof data === "undefined"}
    />
  );
}
