import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useDeleteStructuredPipeElement({
  materialGroupId,
  materialGroupRevision,
}: {
  materialGroupId: string;
  materialGroupRevision: string;
}) {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      pipeElements,
    }: {
      pipeElements: {
        masterPipeElementId: number;
        elementId: number;
        revision: string;
      }[];
    }): Promise<AxiosResponse<StatusResponse>> =>
      await axios(
        getApiUrl(
          "pmg/material-groups/" +
            materialGroupId +
            "/rev/" +
            materialGroupRevision +
            "/structured-pipe-elements"
        ),
        {
          method: "delete",
          data: JSON.stringify({
            pipeelements: pipeElements.map((e) => ({
              MasterPipeElementID: e.masterPipeElementId,
              ElementID: e.elementId,
              Revision: e.revision,
            })),
          }),
        }
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["pmg", "material-groups"]);
        queryClient.invalidateQueries([
          "codelists",
          "pmg/structured-pipe-elements",
        ]);
      },
      onError: (error, variables, context) => {
        console.error(`error ${context}`, error, variables);
      },
    }
  );
}

export type UseDeleteStructuredPipeElement = ReturnType<
  typeof useDeleteStructuredPipeElement
>;
