import AppDisplay from "AppDisplay";
import { AppOut } from "AppOut";
import { GlobalStyle } from "GlobalStyle";
import { StatusBar } from "StatusBar";
import { UserAccessGate } from "UserConfigContext";
import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { UpdateContext, useCheckForAppUpdate } from "useCheckForUpdate";

type DocumentTitleContextType = {
  setDocumentTitle: React.Dispatch<string>;
  setCategoryTitle: React.Dispatch<string>;
  pathname: string;
};

export const DocumentTitleContext = React.createContext(
  {} as DocumentTitleContextType
);

function App() {
  const location = useLocation();
  const { pathname } = location;
  const locationParts = location.pathname.split("/");
  const out = locationParts[1] === "out";

  const [documentTitle, setDocumentTitle] = useState<string>("");
  const [categoryTitle, setCategoryTitle] = useState<string>("");

  useEffect(() => {
    if (documentTitle) {
      document.title = `${
        categoryTitle ? `${categoryTitle}: ` : ""
      }${documentTitle} - ${process.env.REACT_APP_TITLE}${
        process.env.REACT_APP_SUBTITLE
          ? ` ${process.env.REACT_APP_SUBTITLE}`
          : ""
      }`;
    }
  }, [categoryTitle, documentTitle]);

  const { hasUpdateAvailable } = useCheckForAppUpdate();

  return (
    <div data-testid="App">
      <DocumentTitleContext.Provider
        value={{ setDocumentTitle, setCategoryTitle, pathname }}
      >
        <UpdateContext.Provider value={{ hasUpdateAvailable }}>
          <UserAccessGate>{out ? <AppOut /> : <AppDisplay />}</UserAccessGate>
          <StatusBar out={out} />
          <GlobalStyle />
        </UpdateContext.Provider>
      </DocumentTitleContext.Provider>
    </div>
  );
}

export default withRouter(App);
