import { useMutation, useQueryClient } from "react-query";
import { DocumentSpaceMenuOrCategory } from "./useDocumentSpace";
import { getApiUrl } from "queries/queryUtil";
import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";

export type EditDocumentData = {
  Description: string;
  Comment: string;
  URLAddress: string;
  Tag: string;
  MenuCategory: DocumentSpaceMenuOrCategory;
  VisibleOnWeb: "Y" | "N";
  ContentTextOnly: "Y" | "N";
  EmbeddedPicture: "Y" | "N";
};

export function useEditDocument({ oid }: { oid: number }) {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      data,
      file,
    }: {
      data: EditDocumentData;
      file?: File;
    }): Promise<AxiosResponse<StatusResponse>> => {
      const form = new FormData();
      const blob = new Blob();
      form.append("data", JSON.stringify(data));
      form.append("file", file ?? blob);
      return await axios.put(
        getApiUrl(["document-space", oid].join("/")),
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["document-space"]);
      },
    }
  );
}
