import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useCreateVerifyStructuredPipeElements() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      materialGroupId,
      materialGroupRevision,
      masterPipeElementId,
      doNotUseWildcardLogic,
    }: {
      materialGroupId: string;
      materialGroupRevision: string;
      masterPipeElementId: number;
      doNotUseWildcardLogic: boolean;
    }): Promise<AxiosResponse<StatusResponse>> =>
      await axios(
        getApiUrl(
          "pmg/material-groups/" +
            materialGroupId +
            "/rev/" +
            materialGroupRevision +
            "/master-pipe-elements/" +
            masterPipeElementId +
            "/structured-pipe-elements"
        ),
        {
          method: "post",
          data: JSON.stringify({
            DoNotUseWildCardLogic: doNotUseWildcardLogic ? "1" : "0",
          }),
        }
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["pmg", "material-groups"]);
        queryClient.invalidateQueries([
          "codelists",
          "pmg/structured-pipe-elements",
        ]);
      },
      onError: (error, variables, context) => {
        console.error(`error in ${context}`, error, variables);
      },
    }
  );
}
