import { useContext, useEffect, useState } from "react";
import { ConfirmContext, ConfirmOptions } from "./ConfirmContextProvider";

const useConfirm = () => {
  const { confirm, setConfirm } = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (
    prompt: React.ReactFragment,
    options?: ConfirmOptions
  ) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
        options: options || {},
      });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm({
        prompt: "",
        proceed: null,
        cancel: null,
        isOpen: false,
        options: {},
      });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;

export type UseConfirm = ReturnType<typeof useConfirm>;
export type IsConfirmed = UseConfirm["isConfirmed"];
