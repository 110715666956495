import axios from "axios";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { UseQueryOptions, useQueries, useQuery } from "react-query";

export type SubsegmentSelection = {
  SubsegmentID: number;
  Revision: string;
  SubsegmentName: string;
  GroupID: number;
};

type SubsegmentListReply = {
  getSubsegmentSelectionList: SubsegmentSelection[];
};

export function useVDSSubsegmentList({
  name,
  revision,
  groupId,
}: {
  name: string;
  revision: string;
  groupId?: number;
}) {
  return useQuery<SubsegmentSelection[], Error>(
    ["vds-subsegment-list", name, revision, groupId],
    async () => {
      const { data } = await axios.get<SubsegmentListReply>(
        getSheetApiUrl({
          sheetType: "vds",
          name,
          revision,
          postfix: `subsegment-list${groupId ? `?groupId=${groupId}` : ""}`,
        })
      );
      return data.getSubsegmentSelectionList;
    }
  );
}

export function useVDSSubsegmentListMulti({
  name,
  revision,
  groupIds,
}: {
  name: string;
  revision: string;
  groupIds?: number[];
}) {
  return useQueries(
    groupIds
      ? groupIds.map<UseQueryOptions<SubsegmentSelection[], Error>>(
          (groupId) => {
            return {
              queryKey: ["vds-subsegment-list", name, revision, groupId],
              queryFn: async () => {
                const { data } = await axios.get<SubsegmentListReply>(
                  getSheetApiUrl({
                    sheetType: "vds",
                    name,
                    revision,
                    postfix: "subsegment-list?groupId=" + groupId,
                  })
                );
                return data.getSubsegmentSelectionList;
              },
            };
          }
        )
      : []
  );
}
