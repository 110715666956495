import { Button, NativeSelect } from "@equinor/eds-core-react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
  ModalWindowSimpleContainer,
} from "components/ModalWindow";
import { useCodelist } from "queries/useCodelist";
import { useEffect, useState } from "react";
import { EditVDSSubsegment } from "./EditVDSSubsegment";

export function NewVDSSubsegment({
  showCreateNew,
  setShowCreateNew,
  groupFilter,
}: {
  showCreateNew: boolean;
  setShowCreateNew: React.Dispatch<boolean>;
  groupFilter: string;
}) {
  const closeModal = () => {
    setShowCreateNew(false);
  };

  const [showEditNew, setShowEditNew] = useState(false);

  const { data: vdsSubsegmentGroups, status: vdsSubsegmentGroupsStatus } =
    useCodelist({ codelist: "vds-subsegment-groups" });

  const [group, setGroup] = useState(Number(groupFilter));

  useEffect(() => {
    groupFilter
      ? setGroup(Number(groupFilter))
      : vdsSubsegmentGroups &&
        vdsSubsegmentGroups.length > 0 &&
        setGroup(vdsSubsegmentGroups[0].GroupID);
  }, [groupFilter, vdsSubsegmentGroups]);

  useEffect(() => {
    !showCreateNew && setShowEditNew(false);
  }, [showCreateNew]);

  return (
    <>
      <ModalWindow
        isOpen={showCreateNew && !showEditNew}
        closeModal={closeModal}
        title="Create a new VDS Subsegment"
        isLoading={vdsSubsegmentGroupsStatus === "loading"}
      >
        <>
          <ModalWindowContentDefault>
            <ModalSideMargin>
              <ModalWindowSimpleContainer>
                <NativeSelect
                  label="Group"
                  id="vds-subsegment-group"
                  value={group}
                  onChange={(e) => setGroup(Number(e.target.value))}
                >
                  {vdsSubsegmentGroups?.map((e) => (
                    <option key={e.GroupID} value={e.GroupID}>
                      {e.Description}
                    </option>
                  ))}
                </NativeSelect>
              </ModalWindowSimpleContainer>
            </ModalSideMargin>
          </ModalWindowContentDefault>
          <ModalWindowButtonContainer>
            <Button
              onClick={() => {
                setShowEditNew(true);
              }}
            >
              Continue
            </Button>
            <Button variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
          </ModalWindowButtonContainer>
        </>
      </ModalWindow>
      {showEditNew && (
        <EditVDSSubsegment
          isCreate={true}
          group={group}
          showCreateNew={showCreateNew}
          setShowCreateNew={setShowCreateNew}
        />
      )}
    </>
  );
}
