import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "features/sheets/queries/useVDSTextBlocksDistinctText";
import { useContext, useEffect } from "react";

export function VDSTextBlockSectionD({
  data,
  itemId,
  setIsLoaded,
}: {
  data?: VDSTextBlock;
  itemId?: number;
  setIsLoaded?: React.Dispatch<boolean>;
}) {
  const { disabled } = useContext(FieldContext);

  const { data: designRemarks, status: designRemarksStatus } =
    useVDSTextBlocksDistinctText({
      attributeName: "DR",
      sectionId: "D",
      itemId: itemId,
      disabled,
    });

  useEffect(() => {
    designRemarksStatus === "success" && setIsLoaded && setIsLoaded(true);
  }, [designRemarksStatus, setIsLoaded]);

  return (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Design Remark"
        prop="DesignRemark"
        value={data?.DesignRemark ?? ""}
        options={designRemarks}
        type="autocomplete"
        status={designRemarksStatus}
      />
    </>
  );
}
