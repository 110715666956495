import { NameProperties } from "./../../utils/filterItemsByName";
import { PlantProps } from "features/plant/types";
import { ColumnsProps, TableProps } from "../../components/table/Table";
import {
  PCSStandardNotes,
  pcsStandardNotesConfig,
  PCSStandardNotesCreate,
  PCSStandardNotesUpdate,
} from "./pcs-standard-notes/pcsStandardNotesConfig";
import {
  PipeElementGroup,
  pipeElementGroupsConfig,
  PipeElementGroupsCreate,
  PipeElementGroupsUpdate,
} from "./pipe-element-groups/pipeElementGroupsConfig";
import { PlantCreate, plantsConfig, PlantUpdate } from "./plants/plantsConfig";
import {
  PMGDimensionStandards,
  pmgDimensionStandardsConfig,
  PMGDimensionStandardsCreate,
  PMGDimensionStandardsUpdate,
} from "./pmg-dimension-standards/pmgDimensionStandardsConfig";
import {
  PMGProductForms,
  pmgProductFormsConfig,
  PMGProductFormsCreate,
  PMGProductFormsUpdate,
} from "./pmg-product-forms/pmgProductFormsConfig";
import {
  AllUsers,
  allUsersConfig,
  UsersCreate,
  UsersUpdate,
} from "./users/usersConfig";
import {
  ValveElementGroups,
  valveElementGroupsConfig,
  ValveElementGroupsCreate,
  ValveElementGroupsUpdate,
} from "./valve-element-groups/valveElementGroupsConfig";
import {
  ValveTypes,
  valveTypesConfig,
  ValveTypesCreate,
  ValveTypesUpdate,
} from "./valve-types/valveTypesConfig";
import {
  VDSCharacteristics,
  vdsCharacteristicsConfig,
  VDSCharacteristicsCreate,
  VDSCharacteristicsUpdate,
} from "./vds-characteristics/vdsCharacteristicsConfig";
import {
  VDSDesign,
  vdsDesignConfig,
  VDSDesignCreate,
  VDSDesignUpdate,
} from "./vds-design/vdsDesignConfig";
import {
  VDSMaterial,
  vdsMaterialConfig,
  VDSMaterialCreate,
  VDSMaterialUpdate,
} from "./vds-material/vdsMaterialConfig";
import {
  VDSMisc,
  vdsMiscConfig,
  VDSMiscCreate,
  VDSMiscUpdate,
} from "./vds-misc/vdsMiscConfig";

export const itemAdminAreas = [
  "pcs-standard-notes",
  "plants",
  "users",
  "pipe-element-groups",
  "valve-element-groups",
  "vds-design",
  "vds-material",
  "vds-characteristics",
  "vds-misc",
  "pmg-dimension-standards",
  "pmg-product-forms",
  "valve-types",
] as const;

export function isItemAdminArea(area: any): area is ItemAdminAreas {
  return itemAdminAreas.includes(area);
}

export const itemAdminSections = {
  "pcs-standard-notes": ["PCS", "VDS"],
} as {
  [T in ItemAdminAreas]: string[];
};

export const itemAdminConfig: {
  [T in ItemAdminAreas]: ItemAdminConfig<T>;
} = {
  "pcs-standard-notes": pcsStandardNotesConfig,
  plants: plantsConfig,
  users: allUsersConfig,
  "pipe-element-groups": pipeElementGroupsConfig,
  "valve-element-groups": valveElementGroupsConfig,
  "vds-design": vdsDesignConfig,
  "vds-material": vdsMaterialConfig,
  "vds-characteristics": vdsCharacteristicsConfig,
  "vds-misc": vdsMiscConfig,
  "pmg-dimension-standards": pmgDimensionStandardsConfig,
  "pmg-product-forms": pmgProductFormsConfig,
  "valve-types": valveTypesConfig,
};

// prettier-ignore
export type ItemAdminProps<T extends ItemAdminAreas> =
  T extends "plants" ? PlantProps
: T extends "pcs-standard-notes" ? PCSStandardNotes
: T extends "users" ? AllUsers
: T extends "pipe-element-groups" ? PipeElementGroup
: T extends "valve-element-groups" ? ValveElementGroups
: T extends "vds-design" ? VDSDesign
: T extends "vds-material" ? VDSMaterial
: T extends "vds-characteristics" ? VDSCharacteristics
: T extends "vds-misc" ? VDSMisc
: T extends "pmg-dimension-standards" ? PMGDimensionStandards
: T extends "pmg-product-forms" ? PMGProductForms
: T extends "valve-types" ? ValveTypes
: never;

export type CreateOrUpdate = "post" | "put";

export type AdminMethods = CreateOrUpdate | "delete";

export type ItemAdminSaveProps<
  T extends ItemAdminAreas,
  M extends AdminMethods
> = M extends "delete"
  ? undefined
  : T extends "plants"
  ? M extends "post"
    ? PlantCreate
    : M extends "put"
    ? PlantUpdate
    : never
  : T extends "pcs-standard-notes"
  ? M extends "post"
    ? PCSStandardNotesCreate
    : M extends "put"
    ? PCSStandardNotesUpdate
    : never
  : T extends "user"
  ? M extends "post"
    ? UsersCreate
    : M extends "put"
    ? UsersUpdate
    : never
  : T extends "pipe-element-groups"
  ? M extends "post"
    ? PipeElementGroupsCreate
    : M extends "put"
    ? PipeElementGroupsUpdate
    : never
  : T extends "valve-element-groups"
  ? M extends "post"
    ? ValveElementGroupsCreate
    : M extends "put"
    ? ValveElementGroupsUpdate
    : never
  : T extends "vds-design"
  ? M extends "post"
    ? VDSDesignCreate
    : M extends "put"
    ? VDSDesignUpdate
    : never
  : T extends "vds-material"
  ? M extends "post"
    ? VDSMaterialCreate
    : M extends "put"
    ? VDSMaterialUpdate
    : never
  : T extends "vds-characteristics"
  ? M extends "post"
    ? VDSCharacteristicsCreate
    : M extends "put"
    ? VDSCharacteristicsUpdate
    : never
  : T extends "vds-misc"
  ? M extends "post"
    ? VDSMiscCreate
    : M extends "put"
    ? VDSMiscUpdate
    : never
  : T extends "pmg-dimension-standards"
  ? M extends "post"
    ? PMGDimensionStandardsCreate
    : M extends "put"
    ? PMGDimensionStandardsUpdate
    : never
  : T extends "pmg-product-forms"
  ? M extends "post"
    ? PMGProductFormsCreate
    : M extends "put"
    ? PMGProductFormsUpdate
    : never
  : T extends "valve-types"
  ? M extends "post"
    ? ValveTypesCreate
    : M extends "put"
    ? ValveTypesUpdate
    : never
  : never;

export type ItemAdminAreas = (typeof itemAdminAreas)[number];

export type CommonUpdateProps = { id: number };

export type ItemAdminSections<T extends ItemAdminAreas> =
  (typeof itemAdminSections)[T];

export type ItemAdminReturnType<T extends ItemAdminAreas> = ItemAdminProps<T>[];

export type ItemColumnKeyType<T extends ItemAdminAreas> = {
  key: keyof ItemAdminProps<T> & string;
};

export type ItemAdminConfig<T extends ItemAdminAreas> = Omit<
  TableProps,
  "columns" | "itemIdProp" | "selectionMode" | "status" | "items"
> & {
  title: string;
  singularTitle: string;
  url: string;
  editUrl?: string;
  itemIdProp: keyof ItemAdminProps<T> & string;
  columns: (Omit<ColumnsProps, "key"> & ItemColumnKeyType<T>)[];
  apiListProp: string;
  nameProperty: NameProperties<keyof ItemAdminProps<T>>;
  sectionFilter?: string;
  sectionTitles?: { [index: string]: string };
  disableDelete?: (item: ItemAdminProps<T>) => boolean;
  prepareData?: (data?: any) => any;
  deleteConfirmation?: string;
  submitProp?: string;
  disallowCreateNew?: boolean;
};
