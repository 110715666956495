import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export type MasterPipeElementProperites = {
  Priority: string;
  WeldingFactor: string;
};

export function useSaveMasterPipeElementProperties() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      name,
      data,
    }: {
      name: number;
      data: MasterPipeElementProperites;
    }): Promise<AxiosResponse<StatusResponse>> =>
      await axios(
        getApiUrl("pmg/master-pipe-elements/" + name + "/properties"),
        {
          method: "put",
          data: JSON.stringify(data),
        }
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([
          "codelists",
          "pmg/master-pipe-elements",
        ]);
        queryClient.invalidateQueries(["pmg", "material-groups"]);
      },
      onError: (error, variables, context) => {
        console.error(`error ${context}`, error, variables);
      },
    }
  );
}
