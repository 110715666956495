import { AllowableStressMatrixData } from "./allowableStressMatrixConfig";
import {
  ContentCell,
  HeadCell,
  HeadCellLeftSticky,
  HeadCellLeftStickyTop,
  HeadEmptyCellLeftSticky,
  ReportTable,
  TableBody,
  TableContentRow,
  TableHead,
  TableRow,
} from "../../../components/ReportTable";
import useMeasure from "react-use-measure";

type RowData = {
  MaterialGroup: string;
  MaterialStandard: string;
  MaterialGrade: string;
  [index: number]: number;
}[];

const prepareAllowableStressMatrixData = (data: AllowableStressMatrixData) => ({
  temperatures: data
    .reduce((acc, cur) => {
      if (!acc.includes(cur.Temperature)) {
        acc.push(cur.Temperature);
      }
      return acc;
    }, [] as number[])
    .sort((a, b) => (a > b ? 1 : -1)),
  rows: data.reduce((acc, cur) => {
    const index = acc.findIndex(
      (d) =>
        d.MaterialGroup === cur.MaterialGroup &&
        d.MaterialStandard === cur.MaterialStandard &&
        d.MaterialGrade === cur.MaterialGrade
    );
    if (index === -1) {
      acc.push({
        MaterialGroup: cur.MaterialGroup,
        MaterialStandard: cur.MaterialStandard,
        MaterialGrade: cur.MaterialGrade,
        [cur.Temperature]: cur.AllowableStress,
      });
    } else {
      acc[index][cur.Temperature] = cur.AllowableStress;
    }
    return acc;
  }, [] as RowData),
});

export const exportAllowableStressMatrix = (
  data: AllowableStressMatrixData
) => {
  const { temperatures, rows } = prepareAllowableStressMatrixData(data);
  return {
    columns: [
      { title: "Group", key: "MaterialGroup" },
      { title: "Standard", key: "MaterialStandard" },
      { title: "Grade", key: "MaterialGrade" },
      ...temperatures.map((temperature) => ({
        title: String(temperature) + "°C",
        key: String(temperature),
      })),
    ],
    items: rows,
  };
};

export function AllowableStressMatrixReport({
  data,
  headerHeight,
}: {
  data: AllowableStressMatrixData;
  headerHeight: number;
}) {
  const { temperatures, rows } = prepareAllowableStressMatrixData(data);
  let prevGroup = "",
    prevStandard = "",
    prevGrade = "";

  const [groupRef, groupSize] = useMeasure();
  const [standardRef, standardSize] = useMeasure();
  const [tempRef, tempSize] = useMeasure();

  return (
    <ReportTable>
      <TableHead sticky>
        <TableRow>
          <HeadCellLeftStickyTop
            rowSpan={2}
            ref={groupRef}
            bottomBorder
            style={{ top: headerHeight }}
          >
            Group
          </HeadCellLeftStickyTop>
          <HeadCellLeftStickyTop
            rowSpan={2}
            ref={standardRef}
            style={{ left: groupSize.width, top: headerHeight }}
            bottomBorder
          >
            Standard
          </HeadCellLeftStickyTop>
          <HeadCellLeftStickyTop
            rowSpan={2}
            style={{
              left: groupSize.width + standardSize.width,
              top: headerHeight,
            }}
            bottomBorder
            rightBorder
          >
            Grade
          </HeadCellLeftStickyTop>
          <HeadCell
            colSpan={temperatures.length}
            ref={tempRef}
            style={{ top: headerHeight }}
          >
            Temperatures (°C)
          </HeadCell>
        </TableRow>
        <TableRow>
          {temperatures.map((t) => (
            <HeadCell
              key={t}
              style={{ top: tempSize.height + headerHeight }}
              bottomBorder
            >
              {t}
            </HeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((d) => {
          const group = d.MaterialGroup !== prevGroup ? d.MaterialGroup : "";
          const standard =
            d.MaterialGroup !== prevGroup || d.MaterialStandard !== prevStandard
              ? d.MaterialStandard
              : "";
          const grade =
            d.MaterialGroup !== prevGroup ||
            d.MaterialStandard !== prevStandard ||
            d.MaterialGrade !== prevGrade
              ? d.MaterialGrade
              : "";
          prevGroup = d.MaterialGroup;
          prevStandard = d.MaterialStandard;
          prevGrade = d.MaterialGrade;
          return (
            <TableContentRow
              key={d.MaterialGroup + d.MaterialGrade + d.MaterialStandard}
            >
              {group ? (
                <HeadCellLeftSticky>{group}</HeadCellLeftSticky>
              ) : (
                <HeadEmptyCellLeftSticky />
              )}
              {standard ? (
                <HeadCellLeftSticky style={{ left: groupSize.width }}>
                  {standard}
                </HeadCellLeftSticky>
              ) : (
                <HeadEmptyCellLeftSticky style={{ left: groupSize.width }} />
              )}
              {grade ? (
                <HeadCellLeftSticky
                  style={{ left: groupSize.width + standardSize.width }}
                  rightBorder
                >
                  {grade}
                </HeadCellLeftSticky>
              ) : (
                <HeadEmptyCellLeftSticky
                  style={{ left: groupSize.width + standardSize.width }}
                  rightBorder
                />
              )}
              {temperatures.map((t) => (
                <ContentCell key={t}>{d[t] ?? ""}</ContentCell>
              ))}
            </TableContentRow>
          );
        })}
      </TableBody>
    </ReportTable>
  );
}
