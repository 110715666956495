import { Button } from "@equinor/eds-core-react";
import {
  AbsoluteScroller,
  FlexContainerFullHeight,
  FlexElementFullHeight,
} from "components/FullHeightComponents";
import { ModalButtonContainer } from "components/Modal";
import {
  ModalWindow,
  ModalWindowContentFill,
  ModalWindowContentFillScrollable,
  ModalWindowContentFixed,
  ModalWindowDefaultContainer,
  ModalWindowPrimaryContainer,
} from "components/ModalWindow";
import { useState } from "react";
import styled from "styled-components";
import Table from "../../components/table/Table";

const ModalWindowButtons = styled.div``;

const table = false;
const multiTable = false;

export default function ModalWindowTestpage() {
  const [openPrimary, setOpenPrimary] = useState(false);
  const [openDefault, setOpenDefault] = useState(false);
  const [openDefaultLong, setOpenDefaultLong] = useState(false);

  const tableContent = (
    <Table
      items={Array(200).fill({ Test: "Test" })}
      columns={[{ key: "Test", title: "Test" }]}
      selectionMode={false}
      itemIdProp="Test"
    />
  );

  return (
    <div>
      <div style={{ display: "flex", gap: 12, padding: 12 }}>
        <Button onClick={() => setOpenPrimary(true)}>
          Open primary ModalWindow
        </Button>
        <Button onClick={() => setOpenDefault(true)}>
          Open default ModalWindow
        </Button>
        <Button onClick={() => setOpenDefaultLong(true)}>
          Open default long ModalWindow
        </Button>
      </div>
      <ModalWindow
        isOpen={openDefault}
        closeModal={() => setOpenDefault(false)}
        title="default"
        layer="default"
      >
        <ModalWindowDefaultContainer>Test</ModalWindowDefaultContainer>
      </ModalWindow>
      <ModalWindow
        isOpen={openDefaultLong}
        closeModal={() => setOpenDefaultLong(false)}
        title="default"
        layer="default"
      >
        <ModalWindowDefaultContainer>
          <ModalWindowContentFixed>Test 1</ModalWindowContentFixed>
          <ModalWindowContentFillScrollable style={{ maxHeight: "80vh" }}>
            {tableContent}
          </ModalWindowContentFillScrollable>
          <ModalWindowContentFixed>Test 2</ModalWindowContentFixed>
        </ModalWindowDefaultContainer>
      </ModalWindow>
      <ModalWindow
        isOpen={openPrimary}
        closeModal={() => setOpenPrimary(false)}
        title="MW"
        layer="primary"
      >
        <ModalWindowPrimaryContainer>
          {table ? (
            multiTable ? (
              <ModalWindowContentFill>
                <ModalWindowPrimaryContainer>
                  <ModalWindowContentFixed>
                    Hello, this is my area.
                  </ModalWindowContentFixed>
                  <ModalWindowContentFill>
                    {tableContent}
                  </ModalWindowContentFill>
                </ModalWindowPrimaryContainer>
              </ModalWindowContentFill>
            ) : (
              <ModalWindowContentFill>{tableContent}</ModalWindowContentFill>
            )
          ) : (
            <ModalWindowContentFill>
              <FlexContainerFullHeight>
                <FlexElementFullHeight>
                  <AbsoluteScroller>
                    {Array(200)
                      .fill("")
                      .map((e, i) => (
                        <div>test {i}</div>
                      ))}
                  </AbsoluteScroller>
                </FlexElementFullHeight>
                <FlexElementFullHeight>hello</FlexElementFullHeight>
              </FlexContainerFullHeight>
            </ModalWindowContentFill>
          )}
          <ModalWindowContentFixed>
            <ModalWindowButtons>
              <ModalButtonContainer>
                <Button>Test</Button>
                <Button variant="outlined">Test</Button>
              </ModalButtonContainer>
            </ModalWindowButtons>
          </ModalWindowContentFixed>
        </ModalWindowPrimaryContainer>
      </ModalWindow>
    </div>
  );
}
