import axios, { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";

type SubsegmentReplacementReplyItem = {
  VDS: string;
  ValidationStatus: "1" | "0";
};

export type SubsegmentReplacementSubmitItem = {
  VDS: string;
  Revision: string;
  ReplaceWithSubSegmentID: string;
  ReplaceWithSubsegmentRevision: string;
};

export default function useVDSSubsegmentReplacementValidation() {
  async function vdsSubsegmentReplacementValidation({
    name,
    revision,
    content,
  }: {
    name: string;
    revision: string;
    content: {
      sheets: SubsegmentReplacementSubmitItem[];
    };
  }): Promise<
    AxiosResponse<{
      getReplaceSubsegmentValidation: SubsegmentReplacementReplyItem[];
    }>
  > {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-subsegments",
        name,
        revision,
        postfix: "replace-subsegment-validation",
      }),
      {
        method: "put",
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(vdsSubsegmentReplacementValidation, {
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
  });
}
