import {
  Button,
  EdsProvider,
  Icon,
  NativeSelect,
  Typography,
} from "@equinor/eds-core-react";
import { delete_forever, edit, view_agenda } from "@equinor/eds-icons";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { QueryStatus } from "react-query";
import { useAppSelector } from "app/hooks";
import {
  AbsoluteFlexContainer,
  ControlButtonContainer,
  Loader,
} from "components/Components";
import { FormRowBlock } from "components/form/Form";
import { ModalButtonContainer, ModalContainerFixed } from "components/Modal";
import { ModalSideMargin, ModalWindow } from "components/ModalWindow";
import { selectMainPlant } from "features/plant/plantSlice";
import { HTMLColumn, IconColumn } from "components/table/Columns";
import Table, { ItemsClassNames } from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import useConfirm from "components/confirm/useConfirm";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSheet from "features/sheets/queries/useSheet";
import { useMemoOne as useMemo } from "use-memo-one";
import {
  Pagebreak,
  PagebreakFormElement,
} from "../../../../components/form/EditComponents";
import HTMLEditor from "../HTMLEditor";

interface EmbeddedNote {
  PCSName: string;
  Revision: string;
  TextSectionID: string;
  TextSectionDescription: string;
  PageBreak: "N" | "P";
  HTMLCLOB: string;
}

interface EmbeddedNotePosition {
  NoteID: string;
  NoteText: string;
}

export function prepareEmbeddedNotesData(data: { embeddednotes: string }) {
  const parsedData = JSON.parse(data.embeddednotes) as EmbeddedNote[];
  return {
    embeddednotes: parsedData.map((item) => ({
      PositionID: item.TextSectionID,
      Pagebreak: item.PageBreak,
      HTMLContent: item.HTMLCLOB,
    })),
  };
}

export function PCSEmbeddedNotes({
  name,
  revision,
  saveSheetStatus,
  setIsLoaded,
}: {
  name: string;
  revision: string;
  saveSheetStatus: QueryStatus;
  setIsLoaded: React.Dispatch<boolean>;
}) {
  const plant = useAppSelector(selectMainPlant);
  const [editModal, setEditModal] = useState("");

  const { data, status, isRefetching } = useSheet({
    plant,
    sheetType: "pcs",
    name,
    revision,
    postfix: "embedded-notes",
    cacheTime: 0,
  });

  const [embeddedNotes, setEmbeddedNotes] = useState<
    EmbeddedNote[] | undefined
  >(undefined);

  useLayoutEffect(() => {
    setEmbeddedNotes(
      data && data.hasOwnProperty("getEmbeddedNotes")
        ? data.getEmbeddedNotes
        : undefined
    );
    setInited(false);
  }, [data]);

  const {
    data: embeddedNotesPositionsData,
    status: embeddedNotesPositionsStatus,
  } = useCodelist({
    codelist: "embedded-notes",
  });

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(status, embeddedNotesPositionsStatus),
    [status, embeddedNotesPositionsStatus]
  );

  useEffect(() => {
    setIsLoaded(combinedStatuses === "success");
  }, [setIsLoaded, combinedStatuses]);

  const { selection, selectionMode, selectionDispatch } = useTableSelect({
    selectionMode: "multi",
  });

  const { register, setValue, reset } = useFormContext();

  const [inited, setInited] = useState(false);

  const [itemsClassNames, setItemsClassNames] = useState(
    [] as ItemsClassNames[]
  );

  useEffect(() => {
    isRefetching && setInited(false);
  }, [isRefetching]);

  useLayoutEffect(() => {
    if (combinedStatuses === "success" && embeddedNotes) {
      setValue("embeddednotes", JSON.stringify(embeddedNotes), {
        shouldDirty: inited,
      });
      setInited(true);
    }
    // inited omitted on purpose.
    // eslint-disable-next-line
  }, [embeddedNotes, combinedStatuses]);

  useLayoutEffect(() => {
    if (saveSheetStatus === "success") {
      reset(
        { embeddednotes: JSON.stringify(embeddedNotes) },
        { keepDirty: false }
      );
      setInited(false);
    }
    // embeddedNotes omitted on purpose.
    // eslint-disable-next-line
  }, [saveSheetStatus]);

  return (
    <ModalSideMargin>
      {combinedStatuses === "success" &&
      embeddedNotesPositionsStatus &&
      data ? (
        <>
          <Table
            density="compact"
            columns={[
              {
                key: "TextSectionID",
                title: "Pos.",
                longTitle: "Position",
                width: 45,
              },
              { key: "TextSectionDescription", title: "Section", width: 210 },
              {
                key: "PageBreak",
                title: "PB",
                longTitle: "Page Break",
                type: "with-context",
                componentProps: {
                  trueValue: "P",
                  falseValue: "N",
                  prop: "PageBreak",
                  title: "Page Break",
                  icon: view_agenda,
                },
                Component: IconColumn,
                width: 35,
              },
              {
                key: "HTMLCLOB",
                title: "Note",
                Component: HTMLColumn,
                componentProps: {
                  prop: "HTMLCLOB",
                },
                type: "with-context",
                width: "100%",
              },
            ]}
            items={
              embeddedNotes
                ? embeddedNotes.sort((a, b) =>
                    a.TextSectionID > b.TextSectionID
                      ? 1
                      : a.TextSectionID < b.TextSectionID
                      ? -1
                      : 0
                  )
                : []
            }
            itemIdProp="TextSectionID"
            selection={selection}
            selectionMode={selectionMode}
            selectionDispatch={selectionDispatch}
            nonVirtual={true}
            status={combinedStatuses}
            controlHeader={
              <ControlHeader
                setEditModal={setEditModal}
                selection={selection}
                embeddedNotes={embeddedNotes}
                setEmbeddedNotes={setEmbeddedNotes}
              />
            }
            Commands={[EditCommandButton, DeleteCommandButton]}
            contextData={{
              embeddedNotes,
              setEmbeddedNotes,
              setEditModal,
            }}
            itemsClassNames={itemsClassNames}
          />
        </>
      ) : combinedStatuses === "loading" ? (
        <Loader label="Loading Embedded Notes..." />
      ) : (
        <>Error</>
      )}
      {editModal !== "" && embeddedNotes && embeddedNotesPositionsData && (
        <EditModal
          embeddedNotes={embeddedNotes}
          setEmbeddedNotes={setEmbeddedNotes}
          embeddedNotesPositions={embeddedNotesPositionsData}
          editModal={editModal}
          setEditModal={setEditModal}
          setItemsClassNames={setItemsClassNames}
        />
      )}
      <input type="hidden" {...register("embeddednotes")} />
    </ModalSideMargin>
  );
}

function EditCommandButton({
  setEditModal,
  item,
}: {
  setEditModal: React.Dispatch<string>;
  item: EmbeddedNote;
}) {
  return (
    <Button
      variant="ghost_icon"
      onClick={() => setEditModal(item.TextSectionID)}
    >
      <Icon data={edit} />
    </Button>
  );
}

function DeleteCommandButton({
  item,
  embeddedNotes,
  setEmbeddedNotes,
}: {
  item: EmbeddedNote;
  embeddedNotes: EmbeddedNote[];
  setEmbeddedNotes: React.Dispatch<EmbeddedNote[]>;
}) {
  const { isConfirmed } = useConfirm();
  return (
    <Button
      variant="ghost_icon"
      onClick={async () => {
        (await isConfirmed(<>Are you sure you want to delete this note?</>, {
          buttonColor: "danger",
        })) &&
          setEmbeddedNotes(
            embeddedNotes.filter(
              (note) => note.TextSectionID !== item.TextSectionID
            )
          );
      }}
    >
      <Icon data={delete_forever} />
    </Button>
  );
}

function ControlHeader({
  setEditModal,
  selection,
  embeddedNotes,
  setEmbeddedNotes,
}: {
  setEditModal: React.Dispatch<string>;
  selection: string[];
  embeddedNotes: EmbeddedNote[] | undefined;
  setEmbeddedNotes: React.Dispatch<EmbeddedNote[]>;
}) {
  return (
    <ControlButtonContainer>
      <EdsProvider density="comfortable">
        <Button onClick={() => setEditModal("_new")} variant="outlined">
          Add new note
        </Button>
        <Button
          onClick={() =>
            embeddedNotes &&
            setEmbeddedNotes(
              embeddedNotes.filter(
                (note) => !selection.includes(note.TextSectionID)
              )
            )
          }
          variant="outlined"
          color="danger"
          disabled={selection.length === 0}
        >
          <span>
            Remove selected note
            <span
              style={{
                visibility: selection.length === 1 ? "hidden" : "visible",
              }}
            >
              s
            </span>
          </span>
        </Button>
      </EdsProvider>
    </ControlButtonContainer>
  );
}

function EditModal({
  embeddedNotesPositions,
  embeddedNotes,
  setEmbeddedNotes,
  editModal,
  setEditModal,
  setItemsClassNames,
}: {
  embeddedNotesPositions: EmbeddedNotePosition[];
  embeddedNotes: EmbeddedNote[];
  setEmbeddedNotes: React.Dispatch<EmbeddedNote[]>;
  editModal: string;
  setEditModal: React.Dispatch<string>;
  setItemsClassNames: React.Dispatch<ItemsClassNames[]>;
}) {
  const newMode = editModal === "_new";
  const currentNote = embeddedNotes.find(
    (note) => note.TextSectionID === editModal
  );
  const editorRef = useRef(null);
  const availableNotePositons = embeddedNotesPositions?.filter(
    (e: EmbeddedNotePosition) =>
      !embeddedNotes
        .map((d: EmbeddedNote) => d.TextSectionID)
        .includes(e.NoteID)
  );
  const [notePosition, setNotePosition] = useState(
    !newMode && currentNote
      ? currentNote.TextSectionID
      : availableNotePositons.length > 0
      ? availableNotePositons[0].NoteID
      : ""
  );
  const [pagebreak, setPagebreak] = useState<Pagebreak>(
    currentNote ? currentNote.PageBreak : "N"
  );
  const { data, status } = useCodelist({
    codelist: "pcs-embedded-note-template",
    enabled: newMode,
  });

  return (
    <ModalWindow
      closeModal={() => setEditModal("")}
      isOpen={!!editModal}
      title={
        newMode
          ? "Add new note"
          : `Edit note: ${currentNote!.TextSectionID} ${
              currentNote!.TextSectionDescription
            }`
      }
    >
      {status === "loading" ? (
        <ModalContainerFixed style={{ minHeight: 200, minWidth: 400 }}>
          <AbsoluteFlexContainer>
            <Loader label="Loading New Note Template..." />
          </AbsoluteFlexContainer>
        </ModalContainerFixed>
      ) : (
        <ModalSideMargin>
          {availableNotePositons.length === 0 ? (
            <>All note positions are taken.</>
          ) : (
            <>
              {newMode && (
                <FormRowBlock>
                  <NativeSelect
                    id="position"
                    label="Note Position"
                    value={notePosition}
                    onChange={(e) => setNotePosition(e.target.value)}
                  >
                    {availableNotePositons.map((noteType) => {
                      return (
                        <option value={noteType.NoteID} key={noteType.NoteID}>
                          {noteType.NoteID} {noteType.NoteText}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormRowBlock>
              )}
              {status === "error" && (
                <p>
                  <Typography color="danger">
                    The new note template could not be loaded.
                  </Typography>
                </p>
              )}
              <FormRowBlock
                style={{ minHeight: 400, minWidth: 800, position: "relative" }}
              >
                <AbsoluteFlexContainer>
                  <HTMLEditor
                    content={
                      newMode && data
                        ? data[0].HTMLContent
                        : currentNote
                        ? currentNote.HTMLCLOB
                        : ""
                    }
                    editorRef={editorRef}
                  />
                </AbsoluteFlexContainer>
              </FormRowBlock>
              <PagebreakFormElement
                pagebreak={pagebreak}
                setPagebreak={setPagebreak}
              />
              <ModalButtonContainer>
                <Button
                  onClick={() => {
                    setEmbeddedNotes([
                      ...embeddedNotes.filter(
                        (note) => note.TextSectionID !== editModal
                      ),
                      {
                        TextSectionID: notePosition,
                        TextSectionDescription: embeddedNotesPositions.find(
                          (position) => position.NoteID === notePosition
                        )!.NoteText,
                        HTMLCLOB: editorRef.current
                          ? // @ts-ignore
                            editorRef.current.getContent()
                          : "",
                        PageBreak: pagebreak,
                        PCSName: "",
                        Revision: "",
                      },
                    ]);
                    setItemsClassNames([
                      {
                        className: newMode ? "_newData" : "_modifiedData",
                        element: "_line",
                        itemID: notePosition,
                      },
                    ]);
                    setTimeout(() => {
                      setItemsClassNames([]);
                    }, 2000);
                    setEditModal("");
                  }}
                >
                  {newMode ? "Add" : "Update"}
                </Button>
                <Button onClick={() => setEditModal("")} variant="outlined">
                  Cancel
                </Button>
              </ModalButtonContainer>
            </>
          )}
        </ModalSideMargin>
      )}
    </ModalWindow>
  );
}
