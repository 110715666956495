import styled from "styled-components";

export const SemiBold = styled.span`
  font-weight: 500;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const SubtitleItem = styled.div`
  &:not(:first-child) {
    &:before {
      content: "•";
      padding-right: 12px;
      color: var(--textSemiLight);
    }
  }
`;
