import { AppTitle } from "components/AppDisplayComponents";
import { AccessAdmin } from "features/admin/AccessAdmin";
import { AccessDocumentSpace } from "features/document-space/AccessDocumentSpace";
import { Examples } from "features/examples/Examples";
import { AccessIssues } from "features/issues/AccessIssues";
import { AccessPMG } from "features/pmg/AccessPMG";
import { AccessReports } from "features/reports/AccessReports";
import { AccessArticleStack, AccessSheets } from "features/sheets/AccessSheets";
import { motion } from "framer-motion";
import { Route, Switch } from "react-router-dom";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import {
  ContentHeader,
  H1Element,
  Loader,
  TotalCenter,
} from "./components/Components";
import PlantPage from "./features/plant/PlantPage";
import { Profile } from "./features/profile/Profile";
import Testpages from "./features/testpages/Testpages";
import { useUserPlantsWithOperators } from "./queries/useUserPlantsWithOperators";

const ContentWrapper = styled.main`
  background: var(--bg);
  display: flex;
  flex-direction: column;
  height: max-content;
  min-height: 100%;
`;

function Content() {
  const { isLoading } = useUserPlantsWithOperators();
  const [contentWrapperRef, contentWrapperSize] = useMeasure();

  return (
    <ContentWrapper ref={contentWrapperRef}>
      <Switch>
        <Route path="/plant/:plantId/">
          {isLoading ? (
            <Loader label="Loading Plants..." />
          ) : (
            <Switch>
              <Route path="/plant/:plantId/issues">
                <AccessIssues />
              </Route>
              <Route path={["/plant/:plantId/:sheetType/:sheetCommand?"]}>
                <Switch>
                  <Route path="/plant/:plantId/pcs/article-stack">
                    <AccessArticleStack />
                  </Route>
                  <Route>
                    <AccessSheets />
                  </Route>
                </Switch>
              </Route>
              <Route path="/plant/:plantId/" component={PlantPage} />
            </Switch>
          )}
        </Route>
        <Route
          path={[
            "/:sheetType(eds|vds|vds-unstructured|vds-subsegments|vds-textblocks)/:sheetCommand?/",
          ]}
        >
          <AccessSheets />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/" exact>
          <TotalCenter>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <div style={{ display: "flex", gap: "0.5em" }}>
                <H1Element>Welcome to</H1Element>
                <AppTitle />
              </div>
            </motion.div>
          </TotalCenter>
        </Route>
        <Route path="/admin">
          <Route path="/admin/:area/:section?">
            <AccessAdmin />
          </Route>
        </Route>
        <Route path="/reports">
          <Route path="/reports/:report+">
            <AccessReports headerWidth={contentWrapperSize.width} />
          </Route>
        </Route>
        <Route path="/pmg/:pmgList">
          <AccessPMG />
        </Route>
        <Route path="/testpage/">
          <Testpages />
        </Route>
        <Route path="/examples/">
          <Examples />
        </Route>
        <Route path="/document-space">
          <AccessDocumentSpace />
        </Route>
        <Route
          path="/:path"
          render={(props) => (
            <ContentHeader>
              No such route: ({props.match.params.path}).
            </ContentHeader>
          )}
        />
      </Switch>
    </ContentWrapper>
  );
}

export default Content;
