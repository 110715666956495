import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "../../../queries/queryUtil";
import { StatusResponse } from "components/StatusModal";

type VDSWorkbenchCommands = "content";

type VDSSheets = { VDS: string; Revision: string };

type VDSSheetsWithNotes = VDSSheets & { Note: string };

type VDSSubsegmentCommandReturn = StatusResponse;

export default function useVDSWorkbenchCommand<T extends VDSWorkbenchCommands>({
  command,
}: {
  command: T;
}) {
  const queryClient = useQueryClient();

  async function vdsWorkbenchCommand({
    sheets,
    TaskDescription,
  }: T extends "content"
    ? {
        sheets: VDSSheetsWithNotes[];
        TaskDescription: string;
      }
    : {
        sheets: VDSSheets[];
      }): Promise<AxiosResponse<VDSSubsegmentCommandReturn>> {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds",
        postfix: command,
      }),
      {
        method: "put",
        data: JSON.stringify({ sheets, TaskDescription }),
      }
    );
  }

  return useMutation(vdsWorkbenchCommand, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["vds-task-monitor"]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
