import { Button } from "@equinor/eds-core-react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowSimpleContainer,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";
import useConfirm from "components/confirm/useConfirm";
import { Field } from "components/form/Field";
import { useCodelist } from "queries/useCodelist";
import { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { successMessage } from "utils/successMessage";
import { priorities } from "../priorities";
import {
  MasterPipeElementProperites,
  useSaveMasterPipeElementProperties,
} from "../queries/useSaveMasterPipeElementProperties";

export function MasterPipeElementProperties({ edit }: { edit: number }) {
  const { data, status, error } = useCodelist({
    codelist: "pmg/master-pipe-elements",
  });
  const currentData = useMemo(
    () => data?.find((e) => e.MasterPipeElementID === edit),
    [data, edit]
  );

  const methods = useForm<MasterPipeElementProperites>({
    mode: "all",
    shouldUnregister: true,
  });

  const { formState, handleSubmit, reset } = methods;
  const { dirtyFields } = formState;

  const { isConfirmed } = useConfirm();

  const history = useHistory();
  const closeModal = useCallback(async () => {
    (Object.keys(dirtyFields).length === 0 ||
      (await isConfirmed(defaultUnsavedMessage))) &&
      history.push({ search: "" });
  }, [dirtyFields, history, isConfirmed]);

  const {
    mutate,
    status: mutateStatus,
    error: mutateError,
    reset: mutateReset,
    data: mutateData,
  } = useSaveMasterPipeElementProperties();

  const onSubmit = handleSubmit((data) => {
    mutate({ data, name: edit });
  });

  return (
    <>
      <ModalWindow
        title={`Master Pipe Element ${edit} Properties`}
        closeModal={closeModal}
        isOpen={!!edit}
        status={status}
        error={error}
      >
        <ModalSideMargin>
          <form onSubmit={onSubmit}>
            <FormProvider {...methods}>
              <ModalWindowSimpleContainer>
                <Field
                  title="Priority"
                  prop="Priority"
                  type="option"
                  value={currentData?.Priority ?? priorities[0]}
                  options={priorities}
                  style={{ width: 200 }}
                />
                <Field
                  title="Welding Factor"
                  prop="WeldingFactor"
                  type="text"
                  value={currentData?.WeldingFactorValue ?? ""}
                  style={{ width: 200 }}
                />
              </ModalWindowSimpleContainer>
              <ModalWindowButtonContainer>
                <Button type="submit">Save</Button>
                <Button variant="outlined" onClick={closeModal}>
                  Close
                </Button>
              </ModalWindowButtonContainer>
            </FormProvider>
          </form>
        </ModalSideMargin>
      </ModalWindow>
      <StatusModal
        status={mutateStatus}
        error={mutateError}
        successMessage={successMessage(mutateData)}
        onSettledClose={mutateReset}
        onSuccess={reset}
      />
    </>
  );
}
