import { Access } from "Access";
import { useParams } from "react-router-dom";
import { Admin, ValidAdminAreas } from "./Admin";
import { ItemAdminAreas, ItemAdminSections } from "./itemAdminConfig";
import { adminCodeTables } from "./AdminMenu";

export function AccessAdmin<T extends ValidAdminAreas>() {
  const { area, section } = useParams<{
    area?: T;
    section?: T extends keyof ItemAdminAreas
      ? ItemAdminSections<T>[number]
      : string;
  }>();

  return (
    <Access
      role="X"
      enabled={
        area !== "pcs-standard-notes" &&
        area !== "users" &&
        area !== "plants" &&
        area !== "codetables" &&
        area !== "material-groups" &&
        !adminCodeTables.map((e) => e.url).includes(area as string)
      }
    >
      <Access cap="admin" module="admin">
        <Access
          cap={["admin_pcs_notes", "sheets"]}
          enabled={area === "pcs-standard-notes"}
        >
          <Admin area={area} section={section} key={`${area}${section}`} />
        </Access>
      </Access>
    </Access>
  );
}
