import {
  Button,
  Table as EDSTable,
  EdsProvider,
  Icon,
  Input,
  Label,
  NativeSelect,
  Switch,
  Tooltip,
} from "@equinor/eds-core-react";
import { delete_forever, warning_outlined } from "@equinor/eds-icons";
import { ModalSideMargin, ModalWindow } from "components/ModalWindow";
import useConfirm, { IsConfirmed } from "components/confirm/useConfirm";
import { useEffect, useLayoutEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { QueryStatus } from "react-query";
import styled from "styled-components";
import { useMemoOne as useMemo } from "use-memo-one";
import { naturalSort } from "utils/util";
import { useAppSelector } from "../../../../app/hooks";
import {
  AbsoluteFlexContainer,
  ButtonContainer,
  FlexContentElement,
  Paragraph,
  RevisionMark,
} from "../../../../components/Components";
import {
  ModalButtonContainer,
  ModalContainerAlways,
  ModalContainerFixed,
} from "../../../../components/Modal";
import { FormRowBlock } from "../../../../components/form/Form";
import Table, {
  ItemsClassNames,
  TableRowWithDefaultColor,
} from "../../../../components/table/Table";
import { useTableSelect } from "../../../../components/table/useTableSelect";
import { combineQueryStatuses } from "../../../../queries/queryUtil";
import { useCodelist } from "../../../../queries/useCodelist";
import useSheet from "../../queries/useSheet";
import { selectMainPlant } from "../../../plant/plantSlice";
import { EditModalStatusDisplay } from "../../../../components/ModalComponents";

const schWthSeparator = " – ";
const emptySchedule = "(none)";

function getSchWth(sch: string, wth: string) {
  return `${sch ? sch : emptySchedule}${schWthSeparator}${wth}`;
}

function sepSchWth(schwth: string) {
  return {
    Schedule:
      schwth.split(schWthSeparator)[0] === emptySchedule
        ? ""
        : schwth.split(schWthSeparator)[0],
    WallThickness: schwth.split(schWthSeparator)[1],
  };
}

function WallThicknessInput({
  item,
  npsScheduleSizes,
  lookup,
}: {
  item: any;
  npsScheduleSizes: string[];
  lookup: boolean;
}) {
  const { register } = useFormContext();
  return (
    <Input
      readOnly={npsScheduleSizes.includes(item.NomSize) && lookup}
      defaultValue={item.WallThickness}
      {...register(`pipesizes.${item.id}.WallThickness`)}
      className={item.DimElementChange[2] === "_" ? "" : "value-changed"}
    />
  );
}

function OuterDiameterInput({
  item,
  npsScheduleSizes,
  npsScheduleData,
  lookup,
}: {
  item: any;
  npsScheduleSizes: string[];
  npsScheduleData: any;
  lookup: boolean;
}) {
  const { register } = useFormContext();
  return (
    <Input
      readOnly={npsScheduleSizes.includes(item.NomSize) && lookup}
      defaultValue={
        item.OuterDiam
          ? item.OuterDiam
          : npsScheduleSizes.includes(item.NomSize)
          ? npsScheduleData.find((nps: any) => nps.NomSize === item.NomSize)
              .OuterDiameter
          : ""
      }
      {...register(`pipesizes.${item.id}.OuterDiameter`)}
      className={item.DimElementChange[1] === "_" ? "" : "value-changed"}
    />
  );
}

function ScheduleInput({
  item,
  options,
  lookup,
}: {
  item: any;
  options: any[];
  lookup: boolean;
}) {
  const { register, setValue } = useFormContext();
  const { NomSize, Schedule, WallThickness, OuterDiam } = item;
  const filteredOptions = options.filter(
    (option) => option.NomSize === NomSize
  );
  const defaultSelectedSchedule = options.find(
    (n) =>
      n.NomSize === NomSize &&
      n.Schedule === Schedule &&
      n.WallThickness === WallThickness
  );

  return (
    <>
      <input
        type="hidden"
        defaultValue={item.NomSize}
        {...register(`pipesizes.${item.id}.NPS`)}
      />
      {filteredOptions.length === 0 || !lookup ? (
        <Input
          defaultValue={item.Schedule}
          {...register(`pipesizes.${item.id}.Schedule`)}
          className={item.DimElementChange[3] === "_" ? "" : "value-changed"}
        />
      ) : (
        <>
          <input
            type="hidden"
            defaultValue={item.Schedule}
            {...register(`pipesizes.${item.id}.Schedule`)}
          />
          <NativeSelect
            id={`${item.id}.SchWth`}
            label=""
            defaultValue={
              defaultSelectedSchedule
                ? getSchWth(Schedule, WallThickness)
                : Schedule
            }
            className={`${
              item.DimElementChange[3] === "_" ? "" : "value-changed"
            } NativeSelect`}
            onChange={(e) => {
              if (e.target.value === "") {
                setValue(`pipesizes.${item.id}.WallThickness`, "");
                setValue(`pipesizes.${item.id}.Schedule`, "");
                setValue(`pipesizes.${item.id}.OuterDiameter`, "");
              } else {
                const currentSelectedSchedule = options.find(
                  (n) =>
                    n.NomSize === NomSize &&
                    n.Schedule === sepSchWth(e.target.value).Schedule &&
                    n.WallThickness === sepSchWth(e.target.value).WallThickness
                );
                if (currentSelectedSchedule) {
                  setValue(
                    `pipesizes.${item.id}.WallThickness`,
                    sepSchWth(e.target.value).WallThickness
                  );
                  setValue(
                    `pipesizes.${item.id}.Schedule`,
                    sepSchWth(e.target.value).Schedule
                  );
                  setValue(
                    `pipesizes.${item.id}.OuterDiameter`,
                    filteredOptions.find(
                      (i) =>
                        i.Schedule === sepSchWth(e.target.value).Schedule &&
                        i.WallThickness ===
                          sepSchWth(e.target.value).WallThickness
                    ).OuterDiameter
                  );
                } else {
                  setValue(`pipesizes.${item.id}.WallThickness`, WallThickness);
                  setValue(`pipesizes.${item.id}.Schedule`, Schedule);
                  setValue(`pipesizes.${item.id}.OuterDiameter`, OuterDiam);
                }
              }
            }}
          >
            <option></option>
            {!defaultSelectedSchedule && Schedule && (
              <option>{Schedule}</option>
            )}
            {filteredOptions.map((option) => (
              <option key={getSchWth(option.Schedule, option.WallThickness)}>
                {getSchWth(option.Schedule, option.WallThickness)}
              </option>
            ))}
          </NativeSelect>
        </>
      )}
    </>
  );
}

function OptionsInput({
  item,
  options,
  id,
  dimChangePosition,
}: {
  item: any;
  options: any[];
  id: string;
  dimChangePosition: number;
}) {
  const { register } = useFormContext();
  return (
    <NativeSelect
      id={`${item.id}.${id}`}
      label=""
      defaultValue={item[id]}
      {...register(`pipesizes.${item.id}.${id}`)}
      className={`${
        item.DimElementChange[dimChangePosition] === "_" ? "" : "value-changed"
      } NativeSelect`}
    >
      <option></option>
      {options.map((option) => (
        <option key={option}>{option}</option>
      ))}
    </NativeSelect>
  );
}

function NumberInput({
  item,
  id,
  submitId,
  dimChangePosition,
  min,
  max,
}: {
  item: any;
  id: string;
  submitId: string;
  dimChangePosition: number;
  min: number;
  max: number;
}) {
  const { register, formState } = useFormContext();
  const registerProp = `pipesizes.${item.id}.${submitId}`;
  const invalid =
    formState.errors &&
    formState.errors.pipesizes &&
    // @ts-ignore
    formState.errors.pipesizes[item.id] &&
    // @ts-ignore
    formState.errors.pipesizes[item.id][submitId];
  return (
    <Tooltip title={invalid ? `Value must be between ${min} and ${max}.` : ""}>
      <Input
        defaultValue={item[id]}
        {...register(registerProp, {
          min,
          max,
        })}
        className={
          item.DimElementChange[dimChangePosition] === "_"
            ? ""
            : "value-changed"
        }
        variant={invalid ? "error" : undefined}
      />
    </Tooltip>
  );
}

const TableRevCont = styled.div`
  margin-right: 6px;
  margin-top: -1px;
`;

function TableHeader({ title, revision }: { title: string; revision: string }) {
  return (
    <>
      <TableRevCont>
        <RevisionMark>{revision}</RevisionMark>
      </TableRevCont>
      {title}
    </>
  );
}

function DisplayValue({
  item,
  id,
  dimChangePosition,
}: {
  item: any;
  id: string;
  dimChangePosition: number;
}) {
  return (
    <div
      className={
        item.DimElementChange[dimChangePosition] === "_" ? "" : "value-changed"
      }
    >
      {item[id]}
    </div>
  );
}

function RemoveLineCommandButton({
  nomSizes,
  setNomSizes,
  item,
}: {
  nomSizes: string[];
  setNomSizes: Function;
  item: any;
}) {
  const { unregister } = useFormContext();
  return (
    <Button
      variant="ghost_icon"
      onClick={() => {
        setNomSizes(nomSizes.filter((i) => i !== item.NomSize));
        unregister(`pipesizes.${item.id}`);
      }}
    >
      <Icon data={delete_forever} />
    </Button>
  );
}

const NativeSelectNoMinWidth = styled(NativeSelect)`
  min-width: 0;
`;

function FillColumn({
  ids,
  submitId,
  title,
  options,
}: {
  ids: string[];
  submitId: string;
  title: string;
  options?: string[];
}) {
  const [fill, setFill] = useState("");
  const { setValue } = useFormContext();
  return (
    <>
      {options ? (
        <NativeSelectNoMinWidth
          style={{ width: 85 }}
          id={submitId}
          label=""
          title={title}
          value={fill}
          onChange={(e) => setFill(e.target.value)}
        >
          <option value="">{""}</option>
          {options.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </NativeSelectNoMinWidth>
      ) : (
        <Input
          style={{ width: 38 }}
          value={fill}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFill(e.target.value)
          }
          title={title}
        />
      )}
      <ButtonContainer>
        <Button
          title={`Fill ${title}`}
          variant="ghost_icon"
          onClick={() => {
            ids.map((id) =>
              setValue(`pipesizes.${id}.${submitId}`, fill, {
                shouldDirty: true,
              })
            );
          }}
        >
          Fill
        </Button>
      </ButtonContainer>
    </>
  );
}

function ControlHeaderRow({
  selection,
  nomSizes,
  setNomSizes,
  ids,
  underToleranceData,
  setNewModalOpen,
  lookup,
  setLookup,
  isConfirmed,
}: {
  selection: string[];
  nomSizes: string[];
  setNomSizes: Function;
  ids: string[];
  underToleranceData: any;
  setNewModalOpen: Function;
  lookup: boolean;
  setLookup: React.Dispatch<boolean>;
  isConfirmed: IsConfirmed;
}) {
  const changeLookup = async () => {
    const confirmed =
      !lookup ||
      (await isConfirmed(
        <>
          <Paragraph>
            To be able to filter articles through PCS, Wall Thickness values in
            Pipe Size / Wall Thickness table has to match the values used in
            articles.
          </Paragraph>
          <Paragraph>
            The lookup functionality ensures this consistency.
          </Paragraph>
          <Paragraph>
            Turning off the lookup functionality should only be done in cases
            like:
          </Paragraph>
          <ul>
            <li>Dimensions does not exists in master matrix</li>
            <li>Non-schedule Wall Thickness value has to be specified</li>
          </ul>
          <Paragraph>
            Confirm that you want to turn off the lookup functionality.
          </Paragraph>
        </>
      ));
    confirmed && setLookup(!lookup);
  };

  return nomSizes.length === 0 ? (
    <ButtonContainer>
      <EdsProvider density="comfortable">
        <Button variant="outlined" onClick={() => setNewModalOpen(true)}>
          Add new line
        </Button>
      </EdsProvider>
    </ButtonContainer>
  ) : (
    <TableRowWithDefaultColor>
      <EDSTable.Cell colSpan={6}>
        <ButtonContainer>
          <EdsProvider density="comfortable">
            <Button variant="outlined" onClick={() => setNewModalOpen(true)}>
              Add new line
            </Button>
            <Button
              variant="outlined"
              color="danger"
              disabled={!selection.length}
              onClick={() => {
                setNomSizes(
                  nomSizes.filter((nomSize) => !selection.includes(nomSize))
                );
              }}
            >
              <span>
                Remove selected line
                <span
                  style={{
                    visibility: selection.length === 1 ? "hidden" : "visible",
                  }} /* using visibility to avoid button resize */
                >
                  s
                </span>
              </span>
            </Button>
          </EdsProvider>
        </ButtonContainer>
        <EdsProvider density="comfortable">
          <div
            title="Lookup from Wall Thickness master matrix"
            style={{ marginLeft: 12 }}
          >
            <Switch
              checked={lookup}
              onChange={() => {
                changeLookup();
              }}
              id="lookup-matrix"
              label="Lookup matrix"
            />
          </div>
        </EdsProvider>
      </EDSTable.Cell>
      <EDSTable.Cell>
        <FillColumn
          submitId="UnderTolerance"
          title="Under Tolerances"
          options={
            underToleranceData
              ? underToleranceData.map((ut: any) =>
                  String(Number(ut.UnderToleranceValue))
                )
              : []
          }
          ids={ids}
        />
      </EDSTable.Cell>
      <EDSTable.Cell>
        <FillColumn
          ids={ids}
          submitId="CorrAllowance"
          title="Corrosion Allowances"
        />
      </EDSTable.Cell>
      <EDSTable.Cell>
        <FillColumn ids={ids} submitId="WeldFactor" title="Welding Factors" />
      </EDSTable.Cell>
      <EDSTable.Cell colSpan={2}></EDSTable.Cell>
    </TableRowWithDefaultColor>
  );
}

function AddNewModal({
  setNewModalOpen,
  nomSizes,
  setNomSizes,
  npsScheduleSizes,
  setItemsClassNames,
}: {
  setNewModalOpen: Function;
  nomSizes: string[];
  setNomSizes: Function;
  npsScheduleSizes: string[];
  setItemsClassNames: React.Dispatch<ItemsClassNames[]>;
}) {
  const availableSizes = npsScheduleSizes.filter(
    (size) => !nomSizes.includes(size)
  );

  const { register, watch, getValues, formState, trigger } = useForm({
    shouldUnregister: true,
    defaultValues: {
      size: availableSizes.length > 0 ? availableSizes[0] : "custom",
      custom: "",
    },
  });

  const size = watch("size");

  const newSize = size === "custom" ? getValues("custom") : getValues("size");

  return (
    <ModalWindow
      isOpen={true}
      closeModal={() => setNewModalOpen(false)}
      title="Add a new line"
    >
      <ModalSideMargin>
        <ModalContainerFixed>
          <FormRowBlock style={{ marginTop: 0 }}>
            <Label label="Nominal Pipe Size" />
            <NativeSelect id="newSize" label="" {...register("size")}>
              {availableSizes.map((size) => (
                <option key={size}>{size}</option>
              ))}
              <option key="custom" value="custom">
                Custom...
              </option>
            </NativeSelect>
          </FormRowBlock>
          {size === "custom" && (
            <FormRowBlock>
              <Label label="Custom size" />
              <Input
                {...register("custom", {
                  validate: (value: string) =>
                    !nomSizes.includes(value) && !isNaN(Number(value)),
                  onChange: () => setTimeout(() => trigger()),
                })}
                variant={
                  formState?.errors && formState.errors.custom
                    ? "error"
                    : undefined
                }
              />
            </FormRowBlock>
          )}
        </ModalContainerFixed>
        <ModalContainerAlways>
          <ModalButtonContainer>
            <Button
              onClick={() => {
                setNomSizes([...nomSizes, newSize]);
                setItemsClassNames([
                  { className: "_newData", element: "_line", itemID: newSize },
                ]);
                setTimeout(() => {
                  setItemsClassNames([]);
                }, 2000);
                setNewModalOpen(false);
              }}
              disabled={Object.keys(formState?.errors).length > 0}
            >
              Add
            </Button>
            <Button variant="outlined" onClick={() => setNewModalOpen(false)}>
              Cancel
            </Button>
          </ModalButtonContainer>
        </ModalContainerAlways>
      </ModalSideMargin>
    </ModalWindow>
  );
}

function CombinationWarning({
  item,
  npsScheduleData,
}: {
  item: any;
  npsScheduleData: any[];
}) {
  const warning =
    item.ScheduleInMatrix === "N" ||
    (item.ScheduleInMatrix === "" &&
      !npsScheduleData.map((n) => n.NomSize).includes(item.NomSize));

  return warning ? (
    <Tooltip title="NPS and Wall Thickness combination does not exists in master matrix and will not filter articles.">
      <Icon data={warning_outlined} size={16} />
    </Tooltip>
  ) : (
    <></>
  );
}

const emptyLine = {
  OuterDiam: "",
  WallThickness: "",
  Schedule: "",
  UnderTolerance: "",
  CorrosionAllowance: "",
  WeldingFactor: "",
  DimElementChange: "_______",
  NomSizeRevisionMark: "",
  OuterDiamRevisionMark: "",
  WallThicknessRevMark: "",
  ScheduleRevisionMark: "",
  CorrAllowRevisionMark: "",
  WeldFactorRevisionMark: "",
  UnderTolRevisionMark: "",
  ScheduleInMatrix: "",
};

export function preparePipeSizesData(data: any) {
  return data.hasOwnProperty("pipesizes") &&
    Object.values(data.pipesizes).length
    ? { pipesizes: data.pipesizes ? Object.values(data.pipesizes) : [] }
    : { BackEndAction: "DeleteWholeSet" };
}

export function PCSPipeSizeWallThickness({
  setIsLoaded,
  name,
  revision,
  saveSheetStatus,
}: {
  setIsLoaded: Function;
  name: string;
  revision: string;
  saveSheetStatus: QueryStatus;
}) {
  const plant = useAppSelector(selectMainPlant);
  const { data, status, isRefetching } = useSheet({
    plant,
    sheetType: "pcs",
    name,
    revision,
    postfix: "pipe-sizes",
    cacheTime: 0,
  });
  const [nomSizes, setNomSizes] = useState([] as string[]);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [itemsClassNames, setItemsClassNames] = useState(
    [] as ItemsClassNames[]
  );
  const [lookup, setLookup] = useState(true);
  const [init, setInit] = useState(true);

  const { isConfirmed } = useConfirm();

  const { data: npsScheduleData, status: npsScheduleStatus } = useCodelist({
    codelist: "nps-schedules",
  });
  const { data: underToleranceData, status: underToleranceStatus } =
    useCodelist({
      codelist: "under-tolerances",
    });

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(status, npsScheduleStatus, underToleranceStatus),
    [npsScheduleStatus, status, underToleranceStatus]
  );

  const npsScheduleSizes = useMemo(
    () =>
      npsScheduleData
        ? npsScheduleData.reduce(
            (acc, curr) =>
              acc.includes(curr.NomSize) ? acc : acc.concat(curr.NomSize),
            [] as string[]
          )
        : [],
    [npsScheduleData]
  );

  useLayoutEffect(() => {
    setIsLoaded(combinedStatuses === "success");
  }, [combinedStatuses, setIsLoaded]);

  useLayoutEffect(() => {
    if (data && data.hasOwnProperty("getPipeSize") && !isRefetching) {
      setNomSizes(data.getPipeSize.map((s: any) => s.NomSize));
    }
  }, [data, isRefetching]);

  const { selection, selectionDispatch, selectionMode } = useTableSelect({
    selectionMode: "multi",
  });

  const items = useMemo(
    () =>
      data && data.getPipeSize
        ? [
            ...data.getPipeSize
              .map((line: any) => ({
                id: Number(line.NomSize) * 1000,
                ...line,
              }))
              .filter((s: any) => nomSizes.includes(s.NomSize)),
            ...nomSizes
              .filter(
                (nomSize) =>
                  !data.getPipeSize
                    .map((line: any) => line.NomSize)
                    .includes(nomSize)
              )
              .map((nomSize) => ({
                ...emptyLine,
                NomSize: nomSize,
                id: Number(nomSize) * 1000,
              })),
          ].sort((a: any, b: any) => naturalSort.compare(a.id, b.id))
        : [],
    [data, nomSizes]
  );

  const ids = useMemo(() => items.map((i: any) => i.id), [items]);

  const { register, setValue, reset } = useFormContext();

  useLayoutEffect(() => {
    if (nomSizes.length || !init) {
      setValue(
        "listNomSizes",
        { value: nomSizes.join() },
        { shouldDirty: !init }
      );
      setInit(false);
    }
    // The sizes hidden input is set to represent the current nomSizes
    // and the change is marked as dirty only when it's not the initial load.
    // eslint-disable-next-line
  }, [nomSizes]);

  useEffect(() => {
    if (saveSheetStatus === "success" && !isRefetching) {
      reset(items);
    }
    // Similar to the main form, this sets the form data after saving.
  }, [reset, saveSheetStatus, isRefetching, items]);

  return combinedStatuses === "success" &&
    data &&
    data.hasOwnProperty("getPipeSize") ? (
    <>
      <AbsoluteFlexContainer>
        <FlexContentElement>
          <input {...register("listNomSizes")} type="hidden" />
          <Table
            columns={[
              {
                key: "CombinationWarning",
                title: "",
                Component: CombinationWarning,
                type: "with-context",
                componentProps: {
                  npsScheduleData,
                },
              },
              {
                key: "NomSize",
                title: (
                  <TableHeader
                    title="NPS"
                    revision={data.NomSizeRevisionMark}
                  />
                ),
                longTitle: "Nominal Pipe Size",
                Component: DisplayValue,
                type: "with-context",
                componentProps: {
                  id: "NomSize",
                  dimChangePosition: 0,
                },
              },
              {
                key: "OuterDiam",
                title: (
                  <TableHeader
                    title="OD"
                    revision={data.OuterDiamRevisionMark}
                  />
                ),
                longTitle: "Outer Diameter (mm)",
                Component: OuterDiameterInput,
                type: "with-context",
                componentProps: {
                  npsScheduleSizes,
                  npsScheduleData,
                  lookup,
                },
                minWidth: 60,
              },
              {
                key: "WallThickness",
                title: (
                  <TableHeader
                    title="WTH"
                    revision={data.WallThicknessRevisionMark}
                  />
                ),
                longTitle: "Wall Thickness (mm)",
                Component: WallThicknessInput,
                type: "with-context",
                componentProps: {
                  npsScheduleSizes,
                  lookup,
                },
                minWidth: 60,
              },
              {
                key: "Schedule",
                title: (
                  <TableHeader
                    title="SCH"
                    revision={data.ScheduleRevisionMark}
                  />
                ),
                longTitle: "Schedule",
                Component: ScheduleInput,
                type: "with-context",
                componentProps: {
                  options: npsScheduleData,
                  lookup,
                },
                minWidth: 160,
              },
              {
                key: "UnderTolerance",
                title: (
                  <TableHeader
                    title="UT"
                    revision={data.UnderTolRevisionMark}
                  />
                ),
                longTitle: "Under Tolerance (% / mm)",
                Component: OptionsInput,
                type: "with-context",
                componentProps: {
                  id: "UnderTolerance",
                  options: underToleranceData?.map((ut) =>
                    String(Number(ut.UnderToleranceValue))
                  ),
                  dimChangePosition: 4,
                },
                minWidth: 40,
              },
              {
                key: "CorrosionAllowance",
                title: (
                  <TableHeader
                    title="CA"
                    revision={data.CorrAllowRevisionMark}
                  />
                ),
                longTitle: "Corrosion Allowance (mm)",
                Component: NumberInput,
                type: "with-context",
                componentProps: {
                  id: "CorrosionAllowance",
                  submitId: "CorrAllowance",
                  dimChangePosition: 5,
                  min: 0,
                  max: 999,
                },
                minWidth: 40,
              },
              {
                key: "WeldingFactor",
                title: (
                  <TableHeader
                    title="WF"
                    revision={data.WeldFactorRevisionMark}
                  />
                ),
                longTitle: "Welding Factor",
                Component: NumberInput,
                type: "with-context",
                componentProps: {
                  id: "WeldingFactor",
                  submitId: "WeldFactor",
                  dimChangePosition: 6,
                  min: 0,
                  max: 1,
                },
                minWidth: 40,
              },
            ]}
            nonVirtual={true}
            items={items}
            totalCount={items.length}
            itemIdProp="NomSize"
            density="compact"
            status={combinedStatuses}
            Commands={[RemoveLineCommandButton]}
            contextData={{ nomSizes, setNomSizes }}
            fillRest={true}
            aboveHeaderRow={
              <ControlHeaderRow
                selection={selection}
                nomSizes={nomSizes}
                setNomSizes={setNomSizes}
                underToleranceData={underToleranceData}
                ids={ids}
                setNewModalOpen={setNewModalOpen}
                lookup={lookup}
                setLookup={setLookup}
                isConfirmed={isConfirmed}
              />
            }
            noItemsMessage={
              <>
                No items.
                <ControlHeaderRow
                  selection={selection}
                  nomSizes={nomSizes}
                  setNomSizes={setNomSizes}
                  underToleranceData={underToleranceData}
                  ids={ids}
                  setNewModalOpen={setNewModalOpen}
                  lookup={lookup}
                  setLookup={setLookup}
                  isConfirmed={isConfirmed}
                />
              </>
            }
            selectionMode={selectionMode}
            selection={selection}
            selectionDispatch={selectionDispatch}
            itemsClassNames={itemsClassNames}
          />
        </FlexContentElement>
      </AbsoluteFlexContainer>
      {newModalOpen && (
        <AddNewModal
          setNewModalOpen={setNewModalOpen}
          nomSizes={nomSizes}
          setNomSizes={setNomSizes}
          npsScheduleSizes={npsScheduleSizes}
          setItemsClassNames={setItemsClassNames}
        />
      )}
    </>
  ) : (
    <EditModalStatusDisplay
      status={combinedStatuses}
      loadingString="Loading Pipe Sizes..."
    />
  );
}
