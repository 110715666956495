import Table from "components/table/Table";
import { useCodelist } from "queries/useCodelist";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { filterItemsByName } from "utils/filterItemsByName";
import { plantMappingConfig } from "./plantMappingConfig";
import PlantMappingControlHeader from "./PlantMappingControlHeader";
import { PlantMappingEdit } from "./PlantMappingEdit";
import { EditCommandButton } from "./PlantMappingRowMenu";
import RemoteSystemManager from "./RemoteSystemManager";

export function PlantMappingAdmin({ section }: { section: string }) {
  const { nameProperty } = plantMappingConfig;
  const { data, status, error, isRefetching, refetch, remove } = useCodelist({
    codelist: "plant-mapping",
    subpath: section,
    enabled: !!section,
  });
  const [nameFilter, setNameFilter] = useState("");
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const edit = useMemo(() => parsedSearch.get("edit"), [parsedSearch]);
  const item = useMemo(
    () =>
      edit && data ? data.find((e) => String(e.MappingID) === edit) : undefined,
    [edit, data]
  );
  const [remoteSystemManagerOpen, setRemoteSystemManagerOpen] = useState(false);

  return (
    <>
      <Table
        items={
          data &&
          filterItemsByName({
            items: data,
            nameFilter,
            nameProperty,
          })
        }
        columns={[
          {
            key: "MappingID",
            title: "ID",
            width: 60,
          },
          {
            key: "TR2000PlantID",
            title: "Plant ID",
          },
          {
            key: "ShortDescription",
            title: "Short Description",
          },
          {
            key: "RemoteSystemPlantID",
            title: "Remote System Plant ID",
          },
        ]}
        itemIdProp="MappingID"
        controlHeader={
          <PlantMappingControlHeader
            isRefetching={isRefetching}
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}
            refetch={refetch}
            section={section}
            setRemoteSystemManagerOpen={setRemoteSystemManagerOpen}
            nameProperty={nameProperty}
          />
        }
        status={status}
        error={error}
        refetch={refetch}
        message={!section ? "Choose a Remote System." : ""}
        Commands={[EditCommandButton]}
        isRefetching={isRefetching}
        remove={remove}
        totalCount={data && data.length}
        infoline
      />
      <PlantMappingEdit item={item} remoteSystem={section} />
      <RemoteSystemManager
        remoteSystemManagerOpen={remoteSystemManagerOpen}
        closeModal={() => setRemoteSystemManagerOpen(false)}
      />
    </>
  );
}
