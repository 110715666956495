import {
  Button,
  EdsProvider,
  Icon,
  NativeSelect,
  Tabs,
  TextField,
  Typography,
} from "@equinor/eds-core-react";
import { useUserConfig } from "UserConfigContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  Fade,
  FlexContainer,
  FlexElement,
  Paragraph,
} from "components/Components";
import { SemiBold } from "components/Subtitle";
import { MessageModal } from "components/MessageModal";
import {
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFillScrollable,
  ModalWindowContentFixed,
  ModalWindowPrimaryContainer,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { FormRow } from "components/form/Form";
import { useTabs } from "components/tabs/useTabs";
import {
  PreviewButtons,
  PreviewLink,
  SheetPane,
} from "features/sheets/SheetPane";
import { sheetDocumentWidth } from "features/sheets/config/sheetConfig";
import { getItemID, getItemName, getItemRev } from "features/sheets/util";
import { combineQueryStatuses } from "queries/queryUtil";
import {
  CodelistSpecification,
  Codelists,
  codelistProperties,
  useCodelist,
} from "queries/useCodelist";
import { useSheetList } from "features/sheets/queries/useSheetList";
import useSaveVDS, {
  SaveVDSMutationProps,
  VDSSubsegmentItem,
} from "features/sheets/queries/useSaveVDS";
import useSheet, { VDS } from "features/sheets/queries/useSheet";
import {
  SubsegmentSelection,
  useVDSSubsegmentList,
} from "features/sheets/queries/useVDSSubsegmentList";
import { useVDSContentValidation } from "features/sheets/queries/useValidateVDS";
import { useEffect, useMemo, useRef, useState } from "react";
import { QueryStatus } from "react-query";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { selectUIState, setPaneMode, setPaneSheet } from "uiSlice";
import { successMessage } from "utils/successMessage";
import { Infoline } from "../../../../components/ModalComponents";
import { refresh } from "@equinor/eds-icons";

const CodelistBlock = styled.div`
  margin-bottom: 0.4em;
  display: flex;
  gap: 12px;
  font-size: 90%;
`;

const CodelistTitle = styled.div`
  min-width: 120px;
  display: inline-block;
  text-align: right;
`;

const CodelistContent = styled.div`
  font-weight: 500;
`;

export const CodelistDisplay = ({
  codelist,
  vdsData,
  value,
}: {
  codelist: Codelists;
  vdsData?: VDS;
  value?: number;
}) => {
  const { data: codelistData } = useCodelist({ codelist });
  const { titleSingular, idProp, nameProp, vdsProperty } =
    codelistProperties[codelist];

  const vdsValue = vdsData
    ? vdsData.getVDSContent[0][
        (vdsProperty ?? idProp) as keyof (typeof vdsData.getVDSContent)[0]
      ]
    : value;
  const codelistValue = useMemo(
    () =>
      codelistData?.find(
        (codelistItem) =>
          codelistItem[idProp as keyof typeof codelistItem] === vdsValue
      ),
    [codelistData, idProp, vdsValue]
  );
  const compiledValue = codelistValue
    ? codelistValue[nameProp as keyof typeof codelistValue]
    : vdsValue;

  return (
    <CodelistBlock>
      <CodelistTitle>{titleSingular}:</CodelistTitle>{" "}
      <CodelistContent>
        {compiledValue ? compiledValue : <i>Not specified</i>}
      </CodelistContent>
    </CodelistBlock>
  );
};

export const InsideScroller = styled.div`
  position: absolute;
  inset: 0 10px;
  padding: 0 10px;
  overflow: auto;
`;

export const getSubsegmentObj = (subsegment: unknown) =>
  subsegment &&
  typeof subsegment === "object" &&
  "SubsegmentID" in subsegment &&
  "Revision" in subsegment &&
  "GroupID" in subsegment
    ? {
        SubsegmentID: Number(subsegment.SubsegmentID),
        Revision: String(subsegment.Revision),
        GroupID: Number(subsegment.GroupID),
      }
    : undefined;

export function SubsegmentSelector({
  name,
  revision,
  group,
  subsegments,
  setSubsegments,
  subsegmentList,
  subsegmentListIsRefetching,
  markedSubsegments,
  setMarkedSubsegments,
  requiredSubsegmentsMissing,
  setRequiredSubsegmentsMissing,
}: {
  name: string;
  revision: string;
  group: CodelistSpecification["vds-subsegment-groups"];
  subsegments: VDSSubsegmentItem[];
  setSubsegments: React.Dispatch<VDSSubsegmentItem[]>;
  subsegmentList: SubsegmentSelection[];
  subsegmentListIsRefetching: boolean;
  markedSubsegments: VDSSubsegmentItem[] | undefined;
  setMarkedSubsegments: React.Dispatch<VDSSubsegmentItem[]>;
  requiredSubsegmentsMissing: number[];
  setRequiredSubsegmentsMissing: React.Dispatch<number[]>;
}) {
  const thisSubsegment = subsegments.find((s) => s.GroupID === group.GroupID);
  const mark = markedSubsegments?.find((e) => e.GroupID === group.GroupID);
  const requiredHighlight = requiredSubsegmentsMissing.includes(group.GroupID);

  return (
    <FormRow>
      <FlexContainer
        style={{
          flexWrap: "nowrap",
          width: "100%",
          gap: 12,
          alignItems: "flex-end",
        }}
      >
        <FlexElement
          style={{ flexGrow: 1 }}
          className={
            requiredHighlight ? "inputError" : mark ? "inputWarning" : ""
          }
        >
          <NativeSelect
            meta={
              mark
                ? `Removed: ${mark.SubsegmentID}-${mark.Revision}`
                : requiredHighlight
                ? "Required"
                : undefined
            }
            label={`${group.Description}${
              group.OptionalSubsegment === "N" ? "*" : ""
            }`}
            disabled={subsegmentListIsRefetching}
            id={group.Description}
            value={
              thisSubsegment
                ? getItemID(
                    thisSubsegment?.SubsegmentID,
                    thisSubsegment?.Revision
                  )
                : ""
            }
            title={
              subsegmentList.find(
                (e) =>
                  e.GroupID === group.GroupID &&
                  e.SubsegmentID === thisSubsegment?.SubsegmentID &&
                  e.Revision === thisSubsegment?.Revision
              )?.SubsegmentName ?? ""
            }
            onClick={() => {
              setRequiredSubsegmentsMissing(
                requiredSubsegmentsMissing.filter((e) => e !== group.GroupID)
              );
            }}
            onChange={(e) => {
              setSubsegments([
                ...subsegments.filter((e) => e.GroupID !== group.GroupID),
                ...(e.target.value
                  ? [
                      {
                        SubsegmentID: Number(getItemName(e.target.value)),
                        Revision: getItemRev(e.target.value),
                        GroupID: group.GroupID,
                      },
                    ]
                  : []),
              ]);
              if (
                markedSubsegments?.map((m) => m.GroupID).includes(group.GroupID)
              ) {
                setMarkedSubsegments(
                  markedSubsegments.filter((m) => m.GroupID !== group.GroupID)
                );
              }
            }}
          >
            <option value=""></option>
            {subsegmentList
              .filter((e) => e.GroupID === group.GroupID)
              .map((item) => {
                const itemId = getItemID(item.SubsegmentID, item.Revision);
                return (
                  <option key={itemId} value={itemId}>
                    ({itemId}) {item.SubsegmentName}
                  </option>
                );
              })}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <div style={{ display: "flex" }}>
            <PreviewButtons
              item={mark ?? thisSubsegment}
              offIcon={!!mark}
              sheetType="vds-subsegments"
              previewLocation="vdsEditSubsegment"
              noExternal
            />
          </div>
        </FlexElement>
      </FlexContainer>
    </FormRow>
  );
}

export function VDSEditModal({
  showCreateNew,
  name,
  revision,
  isOpen,
  showEdit,
  setShowCharacteristics,
  closeModal,
  setIsCreateNewEdit,
  newNameSeriesSetting,
  setNewNameSeriesSetting,
  subsegments,
  setSubsegments,
  specialRequirementSetting,
  setSpecialRequirementSetting,
  note,
  setNote,
  isTouched,
  getVDSSaveData,
  isDataLoaded,
  saveVDSSilentStatus,
  subsegmentsTouched,
  setVDSCreateStatusCopy,
}: {
  showCreateNew?: boolean;
  name: string;
  revision: string;
  isOpen: boolean;
  showEdit: boolean;
  setShowCharacteristics: React.Dispatch<boolean>;
  closeModal: (arg0?: boolean) => void;
  setIsCreateNewEdit: React.Dispatch<boolean>;
  newNameSeriesSetting: number;
  setNewNameSeriesSetting: React.Dispatch<number>;
  subsegments: VDSSubsegmentItem[];
  setSubsegments: React.Dispatch<VDSSubsegmentItem[]>;
  specialRequirementSetting: number;
  setSpecialRequirementSetting: React.Dispatch<number>;
  note: string;
  setNote: React.Dispatch<string>;
  isTouched: boolean;
  getVDSSaveData: (arg0: {
    MakeNewName: "N" | "Y";
    PreviewOnly: "N" | "Y";
    invalidateList?: boolean;
  }) => SaveVDSMutationProps;
  isDataLoaded: boolean;
  saveVDSSilentStatus: QueryStatus;
  subsegmentsTouched: boolean;
  setVDSCreateStatusCopy?: React.Dispatch<QueryStatus>;
}) {
  const { data, status, error, isRefetching, refetch } = useSheet({
    name,
    revision,
    sheetType: "vds",
  });

  const {
    data: otherRevisions,
    status: otherRevisionsStatus,
    error: otherRevisionsError,
  } = useSheetList({ sheetType: "vds", sheetFilter: { nameFilter: name } });

  const {
    data: specialRequirements,
    status: specialRequirementsStatus,
    error: specialRequirementsError,
  } = useCodelist({ codelist: "special-requirements" });

  const {
    data: vdsSubsegmentGroups,
    status: vdsSubsegmentGroupStatus,
    error: vdsSubsegmentGroupError,
  } = useCodelist({ codelist: "vds-subsegment-groups" });

  const {
    data: newNameSeries,
    status: newNameSeriesStatus,
    error: newNameSeriesError,
  } = useCodelist({ codelist: "vds/new-name-series" });

  const subsegmentGroupIds = useMemo(
    () => vdsSubsegmentGroups?.map((e) => e.GroupID),
    [vdsSubsegmentGroups]
  );

  const {
    data: subsegmentList,
    status: subsegmentListStatus,
    isRefetching: subsegmentListIsRefetching,
    error: subsegmentListError,
    refetch: subsegmentListRefetch,
  } = useVDSSubsegmentList({ name, revision });

  const combinedStatuses = combineQueryStatuses(
    status,
    specialRequirementsStatus,
    newNameSeriesStatus,
    vdsSubsegmentGroupStatus,
    subsegmentListStatus,
    otherRevisionsStatus
  );

  const allErrors = useMemo(
    () => ({
      ...{ error },
      ...{ specialRequirementsError },
      ...{ newNameSeriesError },
      ...{ vdsSubsegmentGroupError },
      ...{ subsegmentListError },
      ...{ otherRevisionsError },
    }),
    [
      error,
      specialRequirementsError,
      newNameSeriesError,
      subsegmentListError,
      vdsSubsegmentGroupError,
      otherRevisionsError,
    ]
  );

  const [spaceRef, spaceSize] = useMeasure();
  const characteristicsWidth = 350;
  const subsegmentsWidth = Math.max(
    spaceSize.width - characteristicsWidth - sheetDocumentWidth,
    400
  );

  const { paneModes } = useAppSelector(selectUIState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setPaneSheet({
        location: "vdsEdit",
        sheet: { name, revision, sheetType: "vds" },
      })
    );
    dispatch(setPaneMode({ location: "vdsEdit", mode: "preview" }));

    dispatch(
      setPaneSheet({
        location: "vdsRevisions",
        sheet: { name, revision, sheetType: "vds" },
      })
    );
    dispatch(setPaneMode({ location: "vdsRevisions", mode: "preview" }));
    // Run on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nonAllowedSubsegments = useMemo(
    () =>
      subsegmentGroupIds &&
      subsegmentList &&
      subsegments.filter(
        (subsegment) =>
          subsegmentList
            .filter((e) => e.GroupID === subsegment.GroupID)
            .filter(
              (d) =>
                d.SubsegmentID === subsegment.SubsegmentID &&
                d.Revision === subsegment.Revision
            ).length === 0
      ),
    [subsegmentGroupIds, subsegmentList, subsegments]
  );

  const requiredSubsegments = useMemo(
    () =>
      vdsSubsegmentGroups &&
      vdsSubsegmentGroups
        .filter((g) => g.OptionalSubsegment === "N")
        .map((g) => g.GroupID),
    [vdsSubsegmentGroups]
  );

  const [requiredSubsegmentsMissing, setRequiredSubsegmentsMissing] = useState(
    [] as number[]
  );

  const checkRequiredSubsegments = () => {
    const missing =
      requiredSubsegments &&
      requiredSubsegments?.filter(
        (r) => !subsegments.map((s) => s.GroupID).includes(r)
      );
    setRequiredSubsegmentsMissing(missing ?? []);
    (!missing || missing.length > 0) && setShowRequiredAlert(true);
    return !missing || missing.length === 0;
  };

  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showRequiredAlert, setShowRequiredAlert] = useState(false);
  const [markedSubsegments, setMarkedSubsegments] = useState(
    [] as VDSSubsegmentItem[]
  );

  const nonAllowedSubsegmentsCopy = useRef<VDSSubsegmentItem[]>([]);

  useEffect(() => {
    if (
      !subsegmentListIsRefetching &&
      nonAllowedSubsegments &&
      nonAllowedSubsegments.length > 0 &&
      (markedSubsegments.filter((e) =>
        nonAllowedSubsegments.map((n) => n.GroupID).includes(e.GroupID)
      ).length === 0 ||
        nonAllowedSubsegments.filter((e) =>
          markedSubsegments.map((n) => n.GroupID).includes(e.GroupID)
        ).length !== 0)
    ) {
      setShowRemoveAlert(true);
      setMarkedSubsegments([...nonAllowedSubsegments, ...markedSubsegments]);
      nonAllowedSubsegmentsCopy.current = nonAllowedSubsegments;
      setSubsegments(
        subsegments.filter(
          (s) =>
            !nonAllowedSubsegments.map((n) => n.GroupID).includes(s.GroupID)
        )
      );
    }
    // This effect should only run when nonAllowedSubsegments just "came in".
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonAllowedSubsegments, subsegmentListIsRefetching]);

  const {
    mutate: validateVDS,
    status: validateVDSStatus,
    error: validateVDSError,
    reset: validateVDSReset,
    data: validateVDSData,
  } = useVDSContentValidation();

  const {
    mutateAsync: validateVDSSilentAsync,
    status: validateVDSSilentStatus,
    error: validateVDSSilentError,
    reset: validateVDSSilentReset,
  } = useVDSContentValidation();

  const {
    mutate: saveVDS,
    status: saveVDSStatus,
    error: saveVDSError,
    reset: saveVDSReset,
    data: saveVDSData,
  } = useSaveVDS();

  const {
    mutate: createVDS,
    status: createVDSStatus,
    error: createVDSError,
    reset: createVDSReset,
    data: createVDSData,
  } = useSaveVDS();

  useEffect(() => {
    if (setVDSCreateStatusCopy) {
      setVDSCreateStatusCopy(createVDSStatus);
    }
    return () => {
      if (setVDSCreateStatusCopy) {
        setVDSCreateStatusCopy("idle");
      }
    };
  }, [createVDSStatus, setVDSCreateStatusCopy]);

  const duplicateMessage = ({
    VDS,
    Revision,
  }: {
    VDS: string;
    Revision: string;
  }) => `A VDS with the name ${VDS} rev. ${Revision} already exists.`;

  const { userInfo } = useUserConfig();

  const { activeTab, handleChange } = useTabs({
    name: "VDSEdit",
  });

  return (
    <>
      <ModalWindow
        status={combinedStatuses}
        loadingString="Loading VDS..."
        error={allErrors}
        title={
          showCreateNew ? `Create new VDS` : `Edit VDS ${name} rev. ${revision}`
        }
        layer="primary"
        closeModal={() => closeModal()}
        isOpen={isOpen && showEdit}
        hasInfoline
        subtitle={
          data?.getVDSContent[0].VDSDescription && (
            <>
              Description:{" "}
              <SemiBold>{data?.getVDSContent[0].VDSDescription}</SemiBold>
            </>
          )
        }
      >
        {data && vdsSubsegmentGroups && specialRequirements && (
          <form>
            <ModalWindowPrimaryContainer>
              <ModalWindowContentFillScrollable ref={spaceRef}>
                <Space.Fill>
                  <Space.Left size={characteristicsWidth}>
                    <InsideScroller>
                      <FlexContainer
                        style={{
                          justifyContent: "space-between",
                          gap: 12,
                          marginBottom: 12,
                        }}
                      >
                        <FlexElement>
                          <Typography variant="h4" style={{ paddingTop: 3 }}>
                            Characteristics
                          </Typography>
                        </FlexElement>
                        {showCreateNew && (
                          <FlexElement>
                            <EdsProvider density="compact">
                              <Button
                                onClick={() => {
                                  setIsCreateNewEdit(true);
                                  setShowCharacteristics(true);
                                }}
                                variant="outlined"
                              >
                                Change
                              </Button>
                            </EdsProvider>
                          </FlexElement>
                        )}
                      </FlexContainer>
                      <FlexContainer
                        style={{
                          flexDirection: "column",
                          gap: 12,
                          alignItems: "space-between",
                          marginLeft: 6,
                        }}
                      >
                        <FlexElement>
                          <CodelistDisplay
                            codelist="valve-types"
                            vdsData={data}
                          />
                          <CodelistDisplay
                            codelist="rating-classes"
                            vdsData={data}
                          />
                          <CodelistDisplay
                            codelist="material-groups"
                            vdsData={data}
                          />
                          <CodelistDisplay
                            codelist="end-connections"
                            vdsData={data}
                          />
                          <CodelistDisplay
                            codelist="vds-sizes"
                            vdsData={data}
                          />
                          {data.getVDSContent[0].FullReducedBoreIndicator ===
                            "Y" && (
                            <CodelistDisplay codelist="bores" vdsData={data} />
                          )}
                          {data.getVDSContent[0].HousingDesignIndicator ===
                            "Y" && (
                            <CodelistDisplay
                              codelist="housing-design"
                              vdsData={data}
                            />
                          )}
                        </FlexElement>
                        <FlexElement
                          style={{
                            borderTop: "1px solid var(--borderDefault)",
                            paddingTop: 8,
                            width: "100%",
                          }}
                        >
                          {showCreateNew && (
                            <FormRow>
                              <NativeSelect
                                label="New Name Series"
                                id="NewNameSeries"
                                title={
                                  newNameSeries?.find(
                                    (e) => e.SerieID === newNameSeriesSetting
                                  )?.Description
                                }
                                value={newNameSeriesSetting}
                                onChange={(e) => {
                                  setNewNameSeriesSetting(
                                    Number(e.target.value)
                                  );
                                }}
                              >
                                {newNameSeries?.map((newNameSeriesItem) => (
                                  <option
                                    key={newNameSeriesItem.SerieID}
                                    value={newNameSeriesItem.SerieID}
                                  >
                                    {newNameSeriesItem.Description}
                                  </option>
                                ))}
                              </NativeSelect>
                            </FormRow>
                          )}
                          <FormRow>
                            <NativeSelect
                              label="Special Requirement"
                              id="SpecialRequirement"
                              value={specialRequirementSetting}
                              onChange={(e) => {
                                setSpecialRequirementSetting(
                                  Number(e.target.value)
                                );
                              }}
                            >
                              <option value="0">- Not set -</option>
                              {specialRequirements?.map(
                                (specialRequirement) => (
                                  <option
                                    key={
                                      specialRequirement.SpecialRequirementID
                                    }
                                    value={
                                      specialRequirement.SpecialRequirementID
                                    }
                                  >
                                    {specialRequirement.Description}
                                  </option>
                                )
                              )}
                            </NativeSelect>
                          </FormRow>
                          <FormRow>
                            <TextField
                              label="Notes"
                              id="Notepad"
                              rows={12}
                              width="100%"
                              multiline
                              value={note}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setNote(e.target.value)}
                            />
                          </FormRow>
                        </FlexElement>
                      </FlexContainer>
                    </InsideScroller>
                  </Space.Left>
                  <Space.LeftResizable
                    size={subsegmentsWidth}
                    style={{ borderLeft: "1px solid var(--borderDefault)" }}
                  >
                    <InsideScroller>
                      <FlexContainer>
                        <FlexElement>
                          <Typography
                            variant="h4"
                            style={{ paddingTop: 3, marginBottom: 12 }}
                          >
                            Subsegments
                          </Typography>
                        </FlexElement>
                        <FlexElement>
                          <Button
                            variant="ghost_icon"
                            onClick={() => {
                              refetch();
                              subsegmentListRefetch();
                            }}
                          >
                            <Icon data={refresh} />
                          </Button>
                        </FlexElement>
                      </FlexContainer>

                      {vdsSubsegmentGroups &&
                        subsegmentList &&
                        vdsSubsegmentGroups.map((group) => (
                          <SubsegmentSelector
                            name={name}
                            revision={revision}
                            group={group}
                            subsegments={subsegments}
                            subsegmentList={subsegmentList}
                            subsegmentListIsRefetching={
                              subsegmentListIsRefetching
                            }
                            setSubsegments={setSubsegments}
                            key={group.GroupID}
                            markedSubsegments={markedSubsegments}
                            setMarkedSubsegments={setMarkedSubsegments}
                            requiredSubsegmentsMissing={
                              requiredSubsegmentsMissing
                            }
                            setRequiredSubsegmentsMissing={
                              setRequiredSubsegmentsMissing
                            }
                          />
                        ))}
                    </InsideScroller>
                  </Space.LeftResizable>
                  <Space.Fill>
                    <Space.Fill
                      style={{
                        borderBottom: "1px solid var(--borderDefault)",
                      }}
                    >
                      <Tabs
                        activeTab={activeTab}
                        onChange={handleChange}
                        style={{
                          height: "100%",
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Tabs.List>
                          <Tabs.Tab>Preview</Tabs.Tab>
                          <Tabs.Tab>Other Revisions</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panels style={{ display: "flex", flex: 1 }}>
                          <Tabs.Panel style={{ flex: 1, padding: 0 }}>
                            <SheetPane
                              location="vdsEdit"
                              disableClose={true}
                              title={
                                !subsegmentsTouched &&
                                !showCreateNew ? undefined : (
                                  <>
                                    {showCreateNew ? (
                                      paneModes["vdsEdit"] === "preview" ? (
                                        "Preview of the new VDS"
                                      ) : (
                                        "Text Block List for the new VDS"
                                      )
                                    ) : (
                                      <>
                                        <Fade>
                                          {`${
                                            paneModes["vdsEdit"] === "preview"
                                              ? "Preview of"
                                              : "Text Block List Preview for"
                                          }`}
                                        </Fade>{" "}
                                        VDS {name} rev. {revision}
                                      </>
                                    )}
                                  </>
                                )
                              }
                            />
                          </Tabs.Panel>
                          <Tabs.Panel style={{ flex: 1, padding: 0 }}>
                            <SheetPane
                              location="vdsRevisions"
                              disableClose={true}
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 12,
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    {paneModes["vdsRevisions"] === "preview" ? (
                                      <Fade>View of</Fade>
                                    ) : (
                                      <Fade>Text Block List for</Fade>
                                    )}{" "}
                                    VDS {name} rev.{" "}
                                  </div>
                                  {otherRevisions && (
                                    <NativeSelect
                                      id="Revisions"
                                      label=""
                                      style={{ width: 50 }}
                                      defaultValue={revision}
                                      onChange={(e) => {
                                        dispatch(
                                          setPaneSheet({
                                            location: "vdsRevisions",
                                            sheet: {
                                              name,
                                              revision: e.target.value,
                                              sheetType: "vds",
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      {otherRevisions.sheets.map((e) => (
                                        <option key={e.Revision}>
                                          {e.Revision}
                                        </option>
                                      ))}
                                    </NativeSelect>
                                  )}
                                </div>
                              }
                            />
                          </Tabs.Panel>
                        </Tabs.Panels>
                      </Tabs>
                    </Space.Fill>
                    {paneModes.vdsEditSubsegment && (
                      <Space.BottomResizable
                        size={300}
                        style={{
                          borderBottom: "1px solid var(--borderDefault)",
                        }}
                      >
                        <SheetPane location="vdsEditSubsegment" />
                      </Space.BottomResizable>
                    )}
                  </Space.Fill>
                </Space.Fill>
              </ModalWindowContentFillScrollable>
              <ModalWindowContentFixed>
                <ModalWindowButtonContainer hasInfoline>
                  <FlexContainer
                    style={{
                      gap: 20,
                      width: "33.333%",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        validateVDS({
                          name: userInfo.UserID,
                          revision: "0",
                          originalVDS: name,
                        });
                      }}
                      variant="outlined"
                      disabled={
                        saveVDSStatus === "loading" ||
                        saveVDSSilentStatus === "loading" ||
                        isRefetching
                      }
                    >
                      Validate
                    </Button>
                  </FlexContainer>
                  <FlexContainer
                    style={{ width: "33.333%", justifyContent: "center" }}
                  >
                    <Button
                      disabled={
                        isRefetching ||
                        !isDataLoaded ||
                        createVDSStatus === "loading" ||
                        saveVDSStatus === "loading" ||
                        saveVDSSilentStatus === "loading" ||
                        subsegmentListIsRefetching ||
                        subsegmentListStatus !== "success"
                      }
                      onClick={async () => {
                        if (checkRequiredSubsegments()) {
                          const validationResult = await validateVDSSilentAsync(
                            {
                              name: userInfo.UserID,
                              revision: "0",
                              originalVDS: name,
                            }
                          );
                          if (
                            validationResult &&
                            validationResult.DuplicateVDSFound === "N"
                          ) {
                            if (showCreateNew) {
                              createVDS(
                                getVDSSaveData({
                                  MakeNewName: "Y",
                                  PreviewOnly: "N",
                                  invalidateList: true,
                                })
                              );
                            } else {
                              saveVDS(
                                getVDSSaveData({
                                  MakeNewName: "N",
                                  PreviewOnly: "N",
                                  invalidateList: true,
                                })
                              );
                            }
                          } else {
                            // This is only called again to display the message.
                            validateVDS({
                              name: userInfo.UserID,
                              revision: "0",
                            });
                          }
                        }
                      }}
                    >
                      {showCreateNew ? "Create" : "Save"}
                    </Button>
                  </FlexContainer>
                  <FlexContainer
                    style={{ justifyContent: "center", width: "33.333%" }}
                  >
                    <Button
                      onClick={() => {
                        closeModal();
                      }}
                      variant="outlined"
                    >
                      {showCreateNew ? "Cancel" : "Close"}
                    </Button>
                  </FlexContainer>
                </ModalWindowButtonContainer>
                <ModalWindowContentFixed>
                  {data && <Infoline item={data.getVDSContent[0]} />}
                </ModalWindowContentFixed>
              </ModalWindowContentFixed>
            </ModalWindowPrimaryContainer>
          </form>
        )}
      </ModalWindow>
      <StatusModal
        status={validateVDSStatus}
        error={validateVDSError}
        successMessage={
          validateVDSData && validateVDSData.DuplicateVDSFound === "Y"
            ? duplicateMessage(validateVDSData)
            : "No VDS exists with this data yet."
        }
        onSettledClose={validateVDSReset}
        successTitle={
          validateVDSData?.DuplicateVDSFound === "Y"
            ? "Duplicate VDS Found"
            : "Validation Successful"
        }
      />
      <StatusModal
        status={validateVDSSilentStatus}
        error={validateVDSSilentError}
        onSuccess={validateVDSSilentReset}
        onSettledClose={validateVDSSilentReset}
      />
      <StatusModal
        status={saveVDSStatus}
        error={saveVDSError}
        onSettledClose={saveVDSReset}
        successMessage={successMessage(saveVDSData)}
      />
      <StatusModal
        status={createVDSStatus}
        error={createVDSError}
        onSettledClose={createVDSReset}
        successMessage={`A new VDS is created with the name ${
          createVDSData ? createVDSData.data.AdditionalInfo : "?"
        }.`}
        onSuccessClose={() => closeModal(true)}
      />
      <MessageModal
        title="Not allowed subsegments in VDS"
        isOpen={showRemoveAlert}
        onClose={() => setShowRemoveAlert(false)}
      >
        <Paragraph>
          The following subsegment
          {markedSubsegments && markedSubsegments.length > 1 ? "s" : ""} can't
          be used with this VDS:
        </Paragraph>
        <ul>
          {nonAllowedSubsegmentsCopy.current.map((s) => (
            <li key={s.GroupID}>
              {
                vdsSubsegmentGroups?.find((g) => g.GroupID === s.GroupID)
                  ?.Description
              }
              : (
              <PreviewLink item={s} sheetType="vds-subsegments">
                {s.SubsegmentID}-{s.Revision}
              </PreviewLink>
              )
            </li>
          ))}
        </ul>
        <Paragraph>Reason: mismatched characteristics or expiration.</Paragraph>
        <Paragraph>
          Affected{" "}
          {markedSubsegments && markedSubsegments.length > 1
            ? "subsegments are"
            : "subsegment is"}{" "}
          removed and field
          {markedSubsegments && markedSubsegments.length > 1
            ? "s are"
            : " is"}{" "}
          marked.
        </Paragraph>
        <Paragraph>
          Original subsegment
          {markedSubsegments && markedSubsegments.length > 1 && "s"} can be
          viewed until replaced.
        </Paragraph>
      </MessageModal>
      <MessageModal
        title="Required Subsegments Groups"
        isOpen={showRequiredAlert}
        onClose={() => setShowRequiredAlert(false)}
      >
        <Paragraph>
          {requiredSubsegmentsMissing.length > 1
            ? "The following Subsegment Groups are required but not set:"
            : "The following Subsegment Group is required but not set:"}
        </Paragraph>
        <ul>
          {requiredSubsegmentsMissing.map((e) => (
            <li key={e}>
              {vdsSubsegmentGroups?.find((g) => g.GroupID === e)?.Description ??
                e}
            </li>
          ))}
        </ul>
      </MessageModal>
    </>
  );
}
