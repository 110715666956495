import { UserConfigContext } from "UserConfigContext";
import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useContext } from "react";
import { useMutation } from "react-query";

export function useResetSettings() {
  async function deleteSettings() {
    return await axios(getApiUrl(`users/settings/keys`), {
      method: "delete",
    });
  }

  const { settingsRef } = useContext(UserConfigContext);

  return useMutation(deleteSettings, {
    onSuccess: (data, variables, context) => {
      settingsRef.current = {};
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
