import { Button, EdsProvider, NativeSelect } from "@equinor/eds-core-react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowSimpleContainer,
} from "components/ModalWindow";
import { useEffect, useMemo, useState } from "react";
import { DocumentListItem } from "./queries/useDocumentList";
import { DocumentSpaceItem } from "./queries/useDocumentSpace";
import { SortObject, UseObjectSort } from "./queries/useObjectSort";

export function useSort({
  neighbors,
  item,
  oid,
  mutateObjectSort,
  mutateObjectSortStatus,
}: {
  neighbors: DocumentSpaceItem[] | DocumentListItem[];
  item: DocumentSpaceItem | DocumentListItem;
  oid: number;
  mutateObjectSort: UseObjectSort["mutate"];
  mutateObjectSortStatus: UseObjectSort["status"];
}) {
  const sortedNeighbors = useMemo(
    () =>
      [...neighbors].sort((a, b) =>
        !("Rank" in a) || !("Rank" in b)
          ? 0
          : a?.Rank > b?.Rank
          ? 1
          : a?.Rank < b?.Rank
          ? -1
          : 0
      ),
    [neighbors]
  );
  const objects = useMemo(
    () => sortedNeighbors?.map((e, i) => ({ oid: e.OID, rank: i + 1 })),
    [sortedNeighbors]
  );

  const currentObject = useMemo(
    () => objects.find((e) => e.oid === item.OID)!,
    [item.OID, objects]
  );

  const [showMoveToPosition, setShowMoveToPosition] = useState(false);

  useEffect(() => {
    mutateObjectSortStatus === "success" && setShowMoveToPosition(false);
  }, [mutateObjectSortStatus, setShowMoveToPosition]);

  const moveUp =
    currentObject.rank === 1
      ? undefined
      : () =>
          mutateObjectSort({
            oid,
            objects: objects.map((e) => ({
              oid: e.oid,
              rank:
                e.oid === item.OID
                  ? e.rank - 1
                  : e.rank === currentObject.rank - 1
                  ? e.rank + 1
                  : e.rank,
            })),
          });

  const moveDown =
    currentObject.rank === objects.length
      ? undefined
      : () =>
          mutateObjectSort({
            oid,
            objects: objects.map((e) => ({
              oid: e.oid,
              rank:
                e.oid === item.OID
                  ? e.rank + 1
                  : e.rank === currentObject.rank + 1
                  ? e.rank - 1
                  : e.rank,
            })),
          });

  return {
    moveUp,
    moveDown,
    setShowMoveToPosition,
    moveToPositionProps: {
      isOpen: showMoveToPosition,
      setIsOpen: setShowMoveToPosition,
      objects,
      sortedNeighbors,
      mutateObjectSort,
      currentObject,
      oid,
      item,
    },
  };
}

export function MoveToPositionModal({
  isOpen,
  setIsOpen,
  objects,
  sortedNeighbors,
  mutateObjectSort,
  currentObject,
  oid,
  item,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  objects: SortObject[];
  sortedNeighbors: (DocumentSpaceItem | DocumentListItem)[];
  mutateObjectSort: UseObjectSort["mutate"];
  currentObject: SortObject;
  oid: number;
  item: DocumentSpaceItem | DocumentListItem;
}) {
  const [newPosition, setNewPosition] = useState<number>(1);

  const newObjects = objects.map((e) =>
    currentObject.oid === e.oid
      ? { oid: e.oid, rank: newPosition }
      : newPosition > currentObject.rank
      ? e.rank > currentObject.rank && e.rank <= newPosition
        ? { oid: e.oid, rank: e.rank - 1 }
        : { oid: e.oid, rank: e.rank }
      : newPosition < currentObject.rank
      ? e.rank < currentObject.rank && e.rank >= newPosition
        ? { oid: e.oid, rank: e.rank + 1 }
        : { oid: e.oid, rank: e.rank }
      : { oid: e.oid, rank: e.rank }
  );

  return (
    <EdsProvider density="comfortable">
      <ModalWindow
        isOpen={isOpen}
        title={`Move ${item.Description} to position`}
        closeModal={() => setIsOpen(false)}
      >
        <ModalWindowSimpleContainer style={{ minWidth: 400 }}>
          <ModalSideMargin>
            <NativeSelect
              id="position"
              label="New position"
              value={newPosition}
              onChange={(e) => {
                setNewPosition(Number(e.target.value));
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {currentObject.rank === 1 ? null : (
                <option value="1">First position</option>
              )}
              {objects
                .filter((e) => e.rank !== currentObject.rank - 1)
                .map((e) =>
                  e.oid === currentObject.oid ? (
                    <option key={e.oid} value={e.rank} disabled>
                      Current position
                    </option>
                  ) : (
                    <option key={e.oid} value={e.rank + 1}>
                      Below{" "}
                      {
                        sortedNeighbors.find((n) => n.OID === e.oid)
                          ?.Description
                      }
                    </option>
                  )
                )}
            </NativeSelect>
          </ModalSideMargin>
        </ModalWindowSimpleContainer>
        <ModalWindowButtonContainer>
          <Button
            onClick={(e) => {
              mutateObjectSort({ oid, objects: newObjects });
            }}
          >
            Move
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </ModalWindowButtonContainer>
      </ModalWindow>
    </EdsProvider>
  );
}
