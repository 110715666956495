import { createGlobalStyle } from "styled-components";

export const AppDisplayGlobalStyle = createGlobalStyle`
html {
  overflow: hidden;
}`;

export const GlobalStyle = createGlobalStyle`
html {
  --bg: white;
  --semiFadedBg: #f7f7f7;
  --fadedBg: #f3f3f3;
  --furtherFadedBg: #ededed;
  --primary: rgba(0, 112, 121, 1);
  --warningText: #AD6200;
  --dangerText: #B30D2F;
  --successText: #2ca02c;
  --borderDefault: #e1e1e1;
  --borderLight: #f1f1f1;
  --borderDark: #d1d1d1;

  --text: rgb(61 61 61);
  --textSemiLight: rgb(91 91 91);
  --textLight: rgb(183 183 183);

  --transparentBg: rgba(0, 0, 0, 0.1);
  --transparentBgFaded: rgba(100, 100, 100, 0.075);
  --overlayBg: rgba(0, 0, 0, 0.3);
  --overlayBgFaded: rgba(0, 0, 0, 0.2);

  --fadeLessLess: brightness(0.95);
  --fadeLess: brightness(0.85);
  --fade: brightness(0.7);
  --fadeMore: brightness(0.4);

  --primaryOverlay: rgba(0, 112, 121, 0.1);
  --primaryFaded: rgba(229, 240 , 241, 1);
  --primaryFadedSelected: rgba(222, 233 , 234, 1);

  --okColor: rgba(0,203,61,1);
  --dangerColor: rgba(235,0,0,1);
  --dangerBackground: rgba(250, 225, 225, 1);
}

html[data-theme='dark'] {
  --bg: #272826;
  --semiFadedBg: #171816;
  --fadedBg: #121311;
  --furtherFadedBg: #1b1b1b;
  --primary: #1ca4ad;
  --borderDefault: #323331;
  --borderLight: #222321;

  --text: rgba(231 231 231);
  --textSemiLight: rgb(201 201 201);
  --textLight: rgb(123 123 123);

  --transparentBg: rgba(240, 240, 240, 0.3);
  --transparentBgFaded: rgba(240, 240, 240, 0.15);
  --overlayBg: rgba(240, 240, 240, 0.3);
  --overlayBgFaded: rgba(240, 240, 240, 0.2);

  --fadeLessLess: brightness(1.1);
  --fadeLess: brightness(1.2);
  --fade: brightness(1.4);
  --fadeMore: brightness(1.7);
  
  --eds_text__static_icons__default: rgba(194, 194, 194, 1);
  --eds_text__static_icons__secondary: rgba(169, 169, 169, 1);
  --eds_text__static_icons__tertiary: rgba(144, 144, 144, 1);
  --eds_text__static_icons__primary_white: rgba(0, 0, 0, 1);
  
  --eds_ui_background__default: rgba(0, 0, 0, 1);
  --eds_ui_background__semitransparent: rgba(0, 0, 0, 0.2);
  --eds_ui_background__light: rgba(8, 8, 8, 1);
  --eds_ui_background__scrim: rgba(255, 255, 255, 0.4);
  --eds_ui_background__overlay: rgba(255, 255, 255, 0.8);
  --eds_ui_background__medium: rgba(75, 75, 75, 1);
  --eds_ui_background__info: rgba(42, 21, 11, 1);
  --eds_ui_background__warning: rgba(0, 24, 41, 1);
  --eds_ui_background__danger: rgba(0, 62, 62, 1);

  --eds_logo_fill_positive: rgba(20, 255, 200, 1);
  --eds_logo_fill_negative: rgba(0, 0, 0, 1);
  
  --eds_interactive_primary__selected_highlight: rgba(25, 5, 19, 1);
  --eds_interactive_primary__selected_hover: rgba(12, 60, 45, 1);
  --eds_interactive_primary__resting: rgba(143, 255, 134, 1);
  --eds_interactive_primary__hover: rgba(176, 255, 170, 1);
  --eds_interactive_primary__hover_alt: rgba(18, 33, 17, 1);
  --eds_interactive_secondary__highlight: rgba(21, 42, 11, 1);
  --eds_interactive_secondary__resting: rgba(200, 219, 185, 1);
  --eds_interactive_secondary__link_hover: rgba(219, 232, 208, 1);
  --eds_interactive_table__cell__fill_resting: rgba(0, 0, 0, 1);
  --eds_interactive_table__cell__fill_hover: rgba(21, 21, 21, 1);
  --eds_interactive_table__cell__fill_activated: rgba(5, 25, 19, 1);
  --eds_interactive_table__header__fill_activated: rgba(21, 21, 21, 1);
  --eds_interactive_table__header__fill_hover: rgba(35, 35, 35, 1);
  --eds_interactive_table__header__fill_resting: rgba(8, 8, 8, 1);
  --eds_interactive_disabled__text: rgba(65, 65, 65, 1);
  --eds_interactive_text_highlight: rgba(42, 21, 11, 1);
  --eds_interactive_focus: rgba(143, 255, 134, 1);
  --eds_interactive_disabled__border: rgba(35, 35, 35, 1);
  --eds_interactive_disabled__fill: rgba(21, 21, 21, 1);
  --eds_interactive_link_on_interactive_colors: rgba(0, 0, 0, 1);
  --eds_interactive_icon_on_interactive_colors: rgba(0, 0, 0, 1);
  --eds_interactive_link_in_snackbars: rgba(151, 202, 206, 1);
  --eds_interactive_pressed_overlay_dark: rgba(255, 255, 255, 0.2);
  --eds_interactive_pressed_overlay_light: rgba(0, 0, 0, 0.2);
  
  --eds_interactive_primary__resting: var(--primary);
  --eds_ui_background__default: var(--bg);
  --eds_text__static_icons__default: var(--text);
  --eds_interactive_table__cell__fill_hover: var(--transparentBgFaded);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* TinyMCE fixes */
.tox-menu {
  position: fixed !important;
}
`;
