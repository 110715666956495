import { Button } from "@equinor/eds-core-react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
  ModalWindowContentFixed,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import {
  CreateVDSTextBlock,
  SaveVDSTextBlock,
  VDSItemSections,
} from "features/sheets/types";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSaveVDSTextBlock from "features/sheets/queries/useSaveVDSTextBlock";
import useSheet from "features/sheets/queries/useSheet";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { confirmNavigation } from "utils/confirmNavigation";
import { successMessageWithId } from "utils/successMessage";
import { closeEditSheet, editSheet } from "../../commands";
import { Infoline } from "../../../../components/ModalComponents";
import { EditVDSTextBlockTemperatures } from "./Temperatures";
import { VDSTextBlockSectionD } from "./VDSTextBlockSectionD";
import { VDSTextBlockSectionH1 } from "./VDSTextBlockSectionH1";
import { VDSTextBlockSectionH2 } from "./VDSTextBlockSectionH2";
import { VDSTextBlockSectionH3 } from "./VDSTextBlockSectionH3";
import { VDSTextBlockSectionH4 } from "./VDSTextBlockSectionH4";
import { VDSTextBlockSectionM } from "./VDSTextBlockSectionM";
import { VDSTextBlockSectionR } from "./VDSTextBlockSectionR";
import { SemiBold, SubtitleContainer, SubtitleItem } from "components/Subtitle";

const emptyVDSTextBlock: SaveVDSTextBlock = {
  ValveType: "",
  DesignCode: "",
  SizeRangeFrom: "",
  SizeRangeTo: "",
  SizeRangeComment: "",
  RatingClass: "",
  Unit: "",
  MaxDesignPressure1: "",
  MaxDesignPressure2: "",
  MaxDesignPressure3: "",
  MaxDesignPressure4: "",
  MaxDesignPressure5: "",
  MaxDesignPressure6: "",
  MaxDesignPressure7: "",
  MaxDesignPressure8: "",
  MaxDesignPressure9: "",
  MaxDesignPressure10: "",
  MaxDesignPressure11: "",
  MaxDesignPressure12: "",
  AtTemperature1: "",
  AtTemperature2: "",
  AtTemperature3: "",
  AtTemperature4: "",
  AtTemperature5: "",
  AtTemperature6: "",
  AtTemperature7: "",
  AtTemperature8: "",
  AtTemperature9: "",
  AtTemperature10: "",
  AtTemperature11: "",
  AtTemperature12: "",
  DesignRemark: "",
  MaterialSubItem: "",
  MaterialName: "",
  MaterialMDS: "",
  MaterialRemark: "",
  MiscDescription: "",
  MiscVSK: "",
  VDSDescription: "",
  TextBlockName: "",
  MinDesignTemperature: "",
  MaxDesignTemperature: "",
  MinOperatingTemperature: "",
  MaxOperatingTemperature: "",
};

type EditVDSTextBlockProps = Partial<{
  isCreate: boolean;
  isEdit: boolean;
  isCopy: boolean;
  name: string;
  revision: string;
  tab: string;
  itemSection: VDSItemSections;
  itemId: number;
  group: number;
  showCreateNew: boolean;
  setShowCreateNew: React.Dispatch<boolean>;
}>;

export function EditVDSTextBlock({
  isCreate,
  isEdit,
  isCopy,
  name,
  revision,
  tab,
  itemSection,
  itemId,
  group,
  showCreateNew,
  setShowCreateNew,
}: EditVDSTextBlockProps &
  (
    | {
        isEdit: true;
        name: string;
        revision: string;
        tab: string;
      }
    | {
        isCreate: true;
        itemSection: VDSItemSections;
        itemId: number;
        group: number;
        showCreateNew: boolean;
        setShowCreateNew: React.Dispatch<boolean>;
      }
    | {
        isCopy: true;
        name: string;
        revision: string;
        showCreateNew: boolean;
        setShowCreateNew: React.Dispatch<boolean>;
      }
  )) {
  const open =
    !!(isEdit && name && revision && !tab) ||
    !!(isCreate && showCreateNew) ||
    !!(isCopy && showCreateNew);
  const history = useHistory();

  // Used to hide the modal after creating a new Text Block.
  const [hideEditModal, setHideEditModal] = useState(false);

  // Sections set this when they are done loading.
  const [isLoaded, setIsLoaded] = useState(false);

  const { data, status, error, isLoading } = useSheet({
    name: name ?? "",
    revision: revision ?? "",
    sheetType: "vds-textblocks",
    disabled: isCreate || !showCreateNew,
  });

  const {
    data: vdsSubsegmentGroupsConfig,
    status: vdsSubsegmentGroupsConfigStatus,
  } = useCodelist({ codelist: "vds-subsegment-groups-config" });

  const combinedStatus = useMemo(
    () => combineQueryStatuses(status, vdsSubsegmentGroupsConfigStatus),
    [status, vdsSubsegmentGroupsConfigStatus]
  );

  const currentItemSection = isCreate ? itemSection : data?.ItemSection;
  const currentItemId = isCreate ? itemId : data?.ItemID;
  const currentGroup = isCreate ? group : data?.GroupID;
  const currentConfig = useMemo(
    () =>
      vdsSubsegmentGroupsConfig?.find(
        (e) =>
          e.GroupID === currentGroup &&
          e.ItemSection === currentItemSection &&
          e.ItemID === currentItemId
      ),
    [currentGroup, currentItemId, currentItemSection, vdsSubsegmentGroupsConfig]
  );

  const methods = useForm<SaveVDSTextBlock>({
    shouldUnregister: true,
    mode: "all",
  });
  const { reset, formState, handleSubmit } = methods;

  const { isConfirmed } = useConfirm();

  const {
    mutate: saveVDSTextBlock,
    status: saveVDSTextBlockStatus,
    error: saveVDSTextBlockError,
    reset: saveVDSTextBlockReset,
    data: saveVDSTextBlockData,
  } = useSaveVDSTextBlock();

  const onSubmit: SubmitHandler<SaveVDSTextBlock | CreateVDSTextBlock> = (
    formData
  ) => {
    saveVDSTextBlock({
      name: isEdit ? name : undefined,
      revision: isEdit ? revision : undefined,
      content: {
        ...emptyVDSTextBlock,
        ...(isCreate || isCopy
          ? { ItemID: currentItemId, ItemSection: currentItemSection }
          : {}),
        ...formData,
      },
      method: isEdit ? "put" : "post",
    });
  };

  const closeModal = async () => {
    if (
      (Object.keys(formState.dirtyFields).length === 0 &&
        !(isCopy || isCreate)) ||
      (await confirmNavigation({ isConfirmed }))
    ) {
      setIsLoaded(false);
      closeEditSheet({ history });
      setShowCreateNew && setShowCreateNew(false);
    }
  };

  const disabled = useMemo(
    () => isEdit && (!data || data.Status !== "W"),
    [data, isEdit]
  );

  const shouldClose = useRef<boolean>(false);

  const resetForm = () => {
    reset(undefined, { keepValues: true, keepDirty: false });
  };

  useEffect(() => {
    if (isLoaded) {
      resetForm();
    }
    // resetForm is not useCallback-able
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const onSuccess = useCallback(() => {
    if (shouldClose.current) {
      resetForm();
      saveVDSTextBlockReset();
      closeModal();
    }
    if (isCreate) {
      setHideEditModal(true);
    }
    // closeModal is not useCallback-able
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, saveVDSTextBlockReset]);

  const onSettledClose = useCallback(() => {
    saveVDSTextBlockReset();
  }, [saveVDSTextBlockReset]);

  const onSuccessClose = useCallback(() => {
    reset(undefined, { keepValues: true });
    saveVDSTextBlockReset();
    setShowCreateNew && setShowCreateNew(false);
  }, [reset, saveVDSTextBlockReset, setShowCreateNew]);

  useEffect(() => {
    if (saveVDSTextBlockStatus === "idle") {
      setHideEditModal(false);
    }
  }, [saveVDSTextBlockStatus]);

  return (
    <>
      <ModalWindow
        closeModal={closeModal}
        isOpen={open && !hideEditModal}
        title={
          isEdit
            ? `${
                disabled ? "View" : "Edit"
              } VDS Text Block ${name} rev. ${revision}`
            : "Create a new VDS Text Block"
        }
        subtitle={
          <SubtitleContainer>
            <SubtitleItem>
              Group: <SemiBold>{currentConfig?.GroupDescription}</SemiBold>
            </SubtitleItem>
            <SubtitleItem>
              Type:{" "}
              <SemiBold>
                {currentConfig?.ItemSection} - {currentConfig?.ItemDescription}
              </SemiBold>
            </SubtitleItem>
          </SubtitleContainer>
        }
        status={combinedStatus}
        error={error}
        isLoading={isLoading}
        layer="default"
        hasInfoline
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalSideMargin>
              <ModalWindowContentDefault
                style={{ minWidth: 500, width: "90vw" }}
              >
                <FieldContext.Provider value={{ disabled }}>
                  <FieldsetContainer>
                    <Fieldset>
                      <Field
                        feature="sheets"
                        sheetType="vds-textblocks"
                        prop="TextBlockName"
                        title="Name"
                        type="text"
                        value={data?.TextBlockName ?? ""}
                        required
                      />
                      <Field
                        feature="sheets"
                        sheetType="vds-textblocks"
                        prop="VDSDescription"
                        title="Description"
                        type="text"
                        value={data?.VDSDescription ?? ""}
                      />
                    </Fieldset>
                    <Fieldset>
                      {currentItemSection === "D" && (
                        <VDSTextBlockSectionD
                          data={data}
                          setIsLoaded={setIsLoaded}
                          itemId={currentItemId}
                        />
                      )}

                      {currentItemSection === "R" && (
                        <VDSTextBlockSectionR
                          data={data}
                          name={name}
                          revision={revision}
                          itemId={currentItemId}
                          setIsLoaded={setIsLoaded}
                        />
                      )}
                      {currentItemSection === "M" && (
                        <VDSTextBlockSectionM
                          data={data}
                          name={name}
                          revision={revision}
                          itemId={currentItemId}
                          setIsLoaded={setIsLoaded}
                        />
                      )}
                      {currentItemSection === "H" && currentItemId === 1 && (
                        <VDSTextBlockSectionH1
                          textBlockData={data}
                          setIsLoaded={setIsLoaded}
                        />
                      )}
                      {currentItemSection === "H" && currentItemId === 2 && (
                        <VDSTextBlockSectionH2
                          textBlockData={data}
                          setIsLoaded={setIsLoaded}
                        />
                      )}
                      {currentItemSection === "H" && currentItemId === 3 && (
                        <VDSTextBlockSectionH3
                          textBlockData={data}
                          setIsLoaded={setIsLoaded}
                        />
                      )}
                      {currentItemSection === "H" && currentItemId === 4 && (
                        <VDSTextBlockSectionH4
                          textBlockData={data}
                          setIsLoaded={setIsLoaded}
                        />
                      )}
                    </Fieldset>

                    {!(currentItemSection === "H" && currentItemId === 4) && (
                      <Fieldset>
                        <EditVDSTextBlockTemperatures data={data} />
                      </Fieldset>
                    )}
                  </FieldsetContainer>
                </FieldContext.Provider>
              </ModalWindowContentDefault>
            </ModalSideMargin>
            <ModalWindowButtonContainer hasInfoline>
              {isEdit && !disabled && (
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={!isLoaded}
                  onClick={() => {
                    shouldClose.current = false;
                  }}
                >
                  Save
                </Button>
              )}
              {isEdit && !disabled && (
                <Button
                  type="submit"
                  disabled={!isLoaded}
                  onClick={() => {
                    shouldClose.current = true;
                  }}
                >
                  Save & Close
                </Button>
              )}
              {(isCreate || isCopy) && !disabled && (
                <Button type="submit" disabled={!isLoaded}>
                  Create
                </Button>
              )}
              <Button variant="outlined" onClick={closeModal}>
                {isEdit ? "Close" : "Cancel"}
              </Button>
            </ModalWindowButtonContainer>
            {data && (
              <ModalWindowContentFixed>
                <Infoline item={data} />
              </ModalWindowContentFixed>
            )}
          </form>
        </FormProvider>
      </ModalWindow>
      <StatusModal
        status={saveVDSTextBlockStatus}
        error={saveVDSTextBlockError}
        onSuccess={onSuccess}
        onSettledClose={onSettledClose}
        onSuccessClose={onSuccessClose}
        successMessage={successMessageWithId(saveVDSTextBlockData)}
        extraButton={
          (isCreate || isCopy) && saveVDSTextBlockStatus === "success" ? (
            <Button
              variant="outlined"
              onClick={() => {
                editSheet({
                  history,
                  item: {
                    TextBlockID: saveVDSTextBlockData.data.AdditionalInfo,
                    Revision: "0",
                  },
                  sheetType: "vds-textblocks",
                });
                saveVDSTextBlockReset();
                setShowCreateNew && setShowCreateNew(false);
              }}
            >
              Open the new Text Block
            </Button>
          ) : null
        }
      />
    </>
  );
}
