import { Button, Icon } from "@equinor/eds-core-react";
import {
  IconData,
  chevron_down,
  external_link,
  menu,
} from "@equinor/eds-icons";
import { Access } from "Access";
import { UserRoles } from "app/userConfig";
import { settingKey } from "features/settings/settingsKey";
import { useSettings } from "features/settings/useSettings";
import { useUpdateSetting } from "features/settings/useUpdateSetting";
import { motion } from "framer-motion";
import { CodelistSpecification } from "queries/useCodelist";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectUIState, setMenuOpen } from "../uiSlice";
import { Typography } from "./Components";
import { breakpoints } from "./breakpoints";
import { DocumentTitleContext } from "App";
import { selectMainPlant } from "features/plant/plantSlice";

const MenuItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 1em;
`;

const SubmenuItemList = styled.ul`
  list-style: none;
  margin: 0 0 0 1em;
  padding: 0 0 0;
`;

const MenuItemContainer = styled.li`
  position: relative;
  a {
    text-decoration: none;
    color: var(--primary);
    svg {
      vertical-align: -3px;
      margin-right: 0.5em;
    }
    &:hover {
      filter: var(--fade);
    }
    &.active {
      filter: var(--fadeMore);
    }
  }
`;

const linkStyle = `display: block;
border-radius: 4px;
padding: 1px 5px 1px 34px;
text-indent: -24px;
&.hasChildren {
  margin-right: 30px;
}
&:hover {
  background: var(--transparentBgFaded);
}
&:active {
  background: var(--transparentBg);
}
&.active {
  background: var(--transparentBg);
}`;

const MenuNavLink = styled(NavLink)`
  ${linkStyle}
`;

const AHref = styled.a`
  ${linkStyle}
`;

const SubmenuIconContainer = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  color: var(--textLight);
  cursor: pointer;
  padding: 0;
  border: 0;
  background: transparent;
  overflow: hidden;
  &:hover {
    background: var(--transparentBgFaded);
    color: var(--textSemiLight);
  }
  &:active {
    background: var(--transparentBg);
  }
`;

const SubmenuIcon = styled(Icon)`
  transition: 0.25s;
  padding: 1px 6px 2px;
  margin: 2px 0 -2px;
`;

const MenuContext = React.createContext("");

type MenuItemPropType = React.ComponentProps<typeof MenuItem>;

export function MenuItemFunc({
  title,
  icon,
  to,
  notExact,
  children,
  off,
  semiOff,
  iconStyle,
  disabled,
  external,
  hideSubmenu,
  cap,
  role,
  andOperator,
}: {
  title: string;
  icon: IconData;
  to: string;
  notExact?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  off?: boolean;
  semiOff?: boolean;
  iconStyle?: React.CSSProperties;
  disabled?: boolean;
  external?: boolean;
  hideSubmenu?: boolean;
  cap?: string | string[];
  role?: UserRoles;
  andOperator?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const childrenTos = useMemo(
    () =>
      (Array.isArray(children) ? children : [children]).map(
        (child) => child?.props.to
      ),
    [children]
  );

  const [open, setOpen] = useState(
    childrenTos.includes(pathname) ? true : false
  );

  const [manuallyOpened, setManuallyOpened] = useState(false);
  const parentTitle = useContext(MenuContext);

  useEffect(() => {
    if (![...childrenTos, to].includes(pathname) && !manuallyOpened) {
      setOpen(false);
    }
  }, [pathname, childrenTos, manuallyOpened, to]);

  useEffect(() => {
    if ([...childrenTos, to].includes(pathname)) {
      setOpen(true);
    }
  }, [pathname, childrenTos, to]);

  const { setDocumentTitle } = useContext(DocumentTitleContext);

  const mainPlant = useAppSelector(selectMainPlant);

  const pageTitle = parentTitle ? `${parentTitle}: ${title}` : title;

  useEffect(() => {
    if (pathname === to) {
      const fullTitle = `${pageTitle} ${
        pathname.split("/")[1] === "plant"
          ? `in ${mainPlant.ShortDescription} `
          : ""
      }`;
      setDocumentTitle(fullTitle);
    }
  }, [mainPlant, pageTitle, pathname, setDocumentTitle, to]);

  return (
    <Access
      cap={cap}
      role={role}
      hide
      enabled={!!(cap || role)}
      andOperator={andOperator}
    >
      <MenuContext.Provider value={children ? title : parentTitle}>
        <MenuItemContainer>
          {external ? (
            <AHref
              href={disabled ? "" : to}
              target="_blank"
              className={`${disabled ? "disable-link faded grayscale" : ""}`}
            >
              <Icon data={icon} size={16} style={iconStyle} />
              {title}
            </AHref>
          ) : (
            <MenuNavLink
              exact={!notExact}
              to={to}
              title={pageTitle}
              activeClassName="active"
              onClick={() => {
                dispatch(setMenuOpen(false));
                if (children && !open) {
                  setManuallyOpened(false);
                  setOpen(true);
                }
              }}
              className={`${children ? "hasChildren" : ""} ${
                off ? "faded" : ""
              } ${semiOff ? "semiFaded" : ""} ${
                disabled ? "disable-link faded grayscale" : ""
              }`}
              target={external ? "_blank" : undefined}
            >
              <Icon data={icon} size={16} style={iconStyle} />
              {title}
            </MenuNavLink>
          )}
          {!hideSubmenu && children && (
            <SubmenuIconContainer
              onClick={() => {
                setOpen(!open);
                setManuallyOpened(true);
              }}
            >
              <SubmenuIcon
                data={chevron_down}
                size={16}
                style={{
                  ...(open ? {} : { transform: "rotate(-90deg)" }),
                }}
              />
            </SubmenuIconContainer>
          )}
          {!hideSubmenu && children && (
            <motion.div
              initial={false}
              animate={{
                height: open ? "auto" : 0,
                transitionEnd: { display: open ? "block" : "none" },
              }}
              transition={{ ease: "easeInOut", duration: 0.25 }}
              style={{ overflow: "hidden" }}
            >
              <SubmenuItemList>{children}</SubmenuItemList>
            </motion.div>
          )}
        </MenuItemContainer>
      </MenuContext.Provider>
    </Access>
  );
}

export const MenuItem = React.memo(MenuItemFunc);

const CategoryContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const ExpandCollapseButton = styled.button`
  border: none;
  padding: 0;
  background: transparent;
  width: 100%;
  &::after {
    content: url("data:image/svg+xml;utf8, <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='${chevron_down.svgPathData}' /></svg>");
    display: block;
    position: absolute;
    right: 8px;
    top: 5px;
    width: 16px;
    height: 16px;
    transition: 0.25s;
    transform: rotate(-90deg);
    opacity: 0.25;
  }
  &:hover {
    &::after {
      opacity: 0.45;
    }
  }
  &.open {
    &::after {
      top: 2px;
      transform: rotate(0);
    }
  }
`;

const CategoryTitle = styled(Typography)`
  border: 0;
  margin-bottom: 0.3em;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 1px 30px 1px 10px;
`;

export function Category({
  children,
  title,
  addCategoryTitle,
}: {
  children:
    | React.ReactElement<MenuItemPropType>
    | React.ReactElement<MenuItemPropType>[];
  title: string;
  addCategoryTitle?: boolean;
}) {
  const directChildren = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );
  const grandChildren = useMemo(
    () =>
      directChildren.reduce((a, c) => {
        return c.props.children
          ? [
              ...a,
              ...(Array.isArray(c.props.children)
                ? c.props.children
                : [c.props.children]),
            ]
          : a;
      }, [] as React.ReactElement[]),
    [directChildren]
  );
  const allChildren = useMemo(
    () => [...directChildren, ...grandChildren],
    [directChildren, grandChildren]
  );
  const { pathname } = useLocation();

  const menuSettingKey = useMemo(
    () => settingKey(["menuOpen-" + title]),
    [title]
  );

  const { [menuSettingKey]: isSettingsOpen } = useSettings();
  const isDefaultOpen = useMemo(
    () => (isSettingsOpen ? !!JSON.parse(isSettingsOpen) : false),
    [isSettingsOpen]
  );

  const [open, setOpen] = useState(isDefaultOpen);

  useEffect(() => {
    if (allChildren.some((c) => c.props.to === pathname) && !open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChildren, pathname]);

  const { setCategoryTitle } = useContext(DocumentTitleContext);

  useEffect(() => {
    if (allChildren.some((c) => c.props.to === pathname) && addCategoryTitle) {
      setCategoryTitle(title);
    }
    return () => {
      setCategoryTitle("");
    };
  }, [addCategoryTitle, allChildren, pathname, setCategoryTitle, title]);

  const { updateSetting } = useUpdateSetting();

  return (
    <CategoryContainer>
      <ExpandCollapseButton
        onClick={() => {
          setOpen(!open);
          updateSetting({ key: menuSettingKey, value: JSON.stringify(!open) });
        }}
        className={open ? "open" : ""}
      >
        <CategoryTitle variant="h5">{title}</CategoryTitle>
      </ExpandCollapseButton>
      <motion.div
        initial={false}
        animate={{
          height: open ? "auto" : 0,
          transitionEnd: {
            display: open ? "block" : "none",
          },
        }}
        transition={{ ease: "easeInOut", duration: 0.25 }}
      >
        <MenuItemList>{children}</MenuItemList>
      </motion.div>
    </CategoryContainer>
  );
}

const MenuToggleButton = styled(Button)`
  display: block;
  width: 30px;
  margin-right: 1em;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

export function MenuToggle() {
  const { isMenuOpen } = useAppSelector(selectUIState);
  const dispatch = useAppDispatch();
  return (
    <MenuToggleButton
      onClick={() => dispatch(setMenuOpen(!isMenuOpen))}
      variant="outlined"
    >
      <Icon data={menu} />
    </MenuToggleButton>
  );
}

export const MenuWrapper = styled.nav`
  z-index: 200;
  background-color: var(--bg);
  display: none;
  position: fixed;
  left: 0;
  top: 60px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3), 8px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 1em;
  max-height: calc(100vh - 120px);
  overflow-y: scroll;
  &.open {
    display: block;
  }
  @media (min-width: ${breakpoints.lg}) {
    background-color: transparent;
    padding: 15px 0 15px 0;
    display: block;
    box-shadow: none;
    position: relative;
    left: 0;
    top: 0;
    border-radius: 4px;
    max-height: none;
    overflow: hidden;
  }
`;

export function ExternalLink({
  title,
  configParamId,
  urlPostFix,
  configParams,
}: {
  title: string;
  configParamId: number;
  urlPostFix?: string;
  configParams: CodelistSpecification["configuration-parameters"][];
}) {
  const to = configParams.find((e) => e.ID === configParamId)?.URL ?? "";
  return (
    <MenuItem
      title={title}
      to={to + (urlPostFix ?? "")}
      icon={external_link}
      disabled={!to}
      external
    />
  );
}
