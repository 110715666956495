import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

export type DocumentSpaceMenuOrCategory = "" | "M" | "C" | "B";

export type DocumentSpaceDocumentType =
  | "S"
  | "I"
  | "O"
  | "G"
  | "D"
  | "N"
  | "P"
  | "U"
  | "F"
  | "C"
  | "L";

export const documentSpaceDocumentTypeName: {
  [index in DocumentSpaceDocumentType]: string;
} = {
  S: "Main app folder",
  I: "Main operator-independent folder",
  O: "Main operator-dependent folder",
  G: "Plant-specific folder",
  D: "Document",
  N: "Operator",
  P: "Plant",
  U: "URL address",
  F: "Folder",
  C: "Folder content text",
  L: "Temporary object",
};

export type DocumentSpaceItem = {
  OID: number;
  ParentOID: number;
  Description: string;
  LowestLevel: "Y" | "N";
  DocumentType: DocumentSpaceDocumentType;
  MenuOrCategory: DocumentSpaceMenuOrCategory;
  OperatorID: number;
  PlantID: number;
  VisibleOnWeb: "Y" | "N";
  ValidDocumentTypes: string;
};

export function useDocumentSpace() {
  return useQuery<DocumentSpaceItem[], Error>("document-space", async () => {
    const { data } = await axios.get<{ getDocSpace: DocumentSpaceItem[] }>(
      getApiUrl("document-space")
    );
    return data.getDocSpace;
  });
}
