import { Button } from "@equinor/eds-core-react";
import useConfirm from "./useConfirm";
import {
  FatModal,
  modalStyle,
  ModalButtonContainer,
  ModalContainerFixed,
  ModalContainerAlways,
  ModalPadder,
} from "../Modal";

export const defaultUnsavedMessage = (
  <>Unsaved changes will be lost. Are you sure you want to close the window?</>
);

const ConfirmModal = () => {
  const {
    prompt = "",
    isOpen = false,
    proceed,
    cancel,
    options,
  } = useConfirm();

  const close = () => {
    cancel && cancel();
  };

  return (
    <FatModal
      isOpen={isOpen}
      style={{
        ...modalStyle,
        overlay: { ...modalStyle.overlay, zIndex: 1200 },
      }}
      shouldCloseOnEsc={true}
      onRequestClose={close}
    >
      <ModalContainerFixed>
        <ModalPadder>
          <div>{prompt}</div>
        </ModalPadder>
      </ModalContainerFixed>
      <ModalContainerAlways>
        <ModalButtonContainer>
          <Button
            onClick={() => proceed && proceed()}
            disabled={!proceed}
            color={options?.buttonColor}
          >
            Yes
          </Button>
          <Button variant="outlined" onClick={close} disabled={!cancel}>
            Cancel
          </Button>
        </ModalButtonContainer>
      </ModalContainerAlways>
    </FatModal>
  );
};

export default ConfirmModal;
