import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export type EditMasterPipeElementType = {
  DimStandardID: string;
  ProductFormID: string;
  MaterialGradeID: string;
  AddDimensionManually: "N" | "Y";
  Priority: string;
  WeldingFactorMandatory: "Y" | "N";
  WeldingFactor: string;
  Note: string;
  OperatorValidity: string;
  AreaValidity: string;
  SpecialReqValidity: string;
  PFL2ID: string;
};

export type CreateMasterPipeElementType = {
  ElementGroupID: number;
} & EditMasterPipeElementType;

export function useSaveMasterPipeElement<T extends "put" | "post">() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      materialGroupId,
      materialGroupRevision,
      mpeId,
      method,
      data,
    }: {
      materialGroupId: string;
      materialGroupRevision: string;
      mpeId?: number;
      method: T;
      data: T extends "put"
        ? EditMasterPipeElementType
        : CreateMasterPipeElementType;
    }): Promise<AxiosResponse<StatusResponse>> =>
      await axios(
        getApiUrl(
          "pmg/material-groups/" +
            materialGroupId +
            "/rev/" +
            materialGroupRevision +
            "/master-pipe-elements/" +
            mpeId ?? ""
        ),
        {
          method,
          data: JSON.stringify(data),
        }
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["pmg", "material-groups"]);
        queryClient.invalidateQueries([
          "codelists",
          "pmg/master-pipe-elements",
        ]);
      },
      onError: (error, variables, context) => {
        console.error(`error ${context}`, error, variables);
      },
    }
  );
}
