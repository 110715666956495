import { useMutation, useQueryClient } from "react-query";
import { getApiUrl } from "queries/queryUtil";
import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";

export type SortObject = {
  oid: number;
  rank: number;
};

export function useObjectSort() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      oid,
      objects,
    }: {
      oid: number;
      objects: SortObject[];
    }): Promise<AxiosResponse<StatusResponse>> => {
      return await axios.put(
        getApiUrl(["document-space", oid, "object-sort"].join("/")),
        JSON.stringify({
          objects: objects.map((e) => ({ Rank: e.rank, OID: e.oid })),
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["document-space"]);
      },
    }
  );
}

export type UseObjectSort = ReturnType<typeof useObjectSort>;
