type SettingKeyElements =
  | string
  | { [index: string]: string | number | boolean | null };

function settingsString(s: string) {
  return s.replaceAll(" ", "_").replaceAll("/", "-");
}

export function settingKey(keyElements: SettingKeyElements[]) {
  return keyElements
    .map((e) =>
      typeof e === "string"
        ? settingsString(e)
        : Object.entries(e)
            .map((f) => (typeof f === "string" ? settingsString(f) : f))
            .join()
    )
    .join();
}
