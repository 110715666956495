import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

type PipeArticleConfigValues = "Y" | "N" | "O" | "M";

type PipeArticleConfig = {
  MaterialGroupID: number;
  SubElementConnection: PipeArticleConfigValues;
  ComponentType: PipeArticleConfigValues;
  Matrix: PipeArticleConfigValues;
  EndConnection: PipeArticleConfigValues;
  UnderTolerance: PipeArticleConfigValues;
  ManualDimension: PipeArticleConfigValues;
  ElementType: PipeArticleConfigValues;
  SubElement1: PipeArticleConfigValues;
  SubElement2: PipeArticleConfigValues;
  Manufacturer: PipeArticleConfigValues;
  Size1: PipeArticleConfigValues;
  Size2: PipeArticleConfigValues;
  WallThickness1: PipeArticleConfigValues;
  WallThickness2: PipeArticleConfigValues;
  SizeOther: PipeArticleConfigValues;
  Height: PipeArticleConfigValues;
  Length: PipeArticleConfigValues;
  Width: PipeArticleConfigValues;
  SizeType: PipeArticleConfigValues;
  SizeUnit: PipeArticleConfigValues;
};

export function usePipeArticleConfig({
  pipeElementGroup,
}: {
  pipeElementGroup: number;
}) {
  return useQuery<PipeArticleConfig[], Error>(
    [
      "codelists",
      "pipe-element-groups",
      pipeElementGroup,
      "pipe-article-config",
    ],
    async ({ queryKey }) => {
      const { data } = await axios.get<{
        getPipeElementGroupArticleConfig: PipeArticleConfig[];
      }>(getApiUrl(queryKey.join("/")));
      return data.getPipeElementGroupArticleConfig;
    }
  );
}
