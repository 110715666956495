import axios, { AxiosResponse } from "axios";
import { StatusResponse } from "components/StatusModal";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useMDSMatrixRevisions() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      name,
      revision,
    }: {
      name: string;
      revision: string;
    }): Promise<AxiosResponse<StatusResponse>> =>
      await axios(
        getApiUrl(
          "pmg/material-groups/" +
            name +
            "/rev/" +
            revision +
            "/mds-matrix-revisions"
        ),
        {
          method: "put",
        }
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["pmg", "material-groups"]);
      },
      onError: (error, variables, context) => {
        console.error(`error ${context}`, error, variables);
      },
    }
  );
}
