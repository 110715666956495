import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";
import {
  DocumentSpaceDocumentType,
  DocumentSpaceMenuOrCategory,
} from "./useDocumentSpace";

export type DocumentSpaceProperties = {
  Description: string;
  Comment: string;
  DocumentFormat: string;
  DocumentType: DocumentSpaceDocumentType;
  LastModified: string;
  FileName: string;
  FileSizeKB: number;
  MenuOrCategory: DocumentSpaceMenuOrCategory;
  VisibleOnWeb: "Y" | "N";
  Rank: number;
  Tag: string;
  EmbeddedPicture: "Y" | "N";
  InfoText: string;
  URLAddress: string;
  ValidArea: string;
  ParentOID: number;
  PlantID: number;
  OperatorID: number;
  DocumentID: string;
};

export function useDocumentSpaceProperties({ oid }: { oid: number }) {
  return useQuery(
    ["document-space", "documents", oid, "properties"],
    async () => {
      const { data } = await axios.get<{
        getDocProperties: DocumentSpaceProperties[];
      }>(getApiUrl(["document-space", oid, "properties"].join("/")));
      return data.getDocProperties[0];
    }
  );
}
