import { Button, Icon, Search, Switch, Tooltip } from "@equinor/eds-core-react";
import { library_add, refresh } from "@equinor/eds-icons";
import { HeaderProgress } from "components/table/HeaderProgress";
import { useMemo, useState } from "react";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import { selectUIState } from "uiSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  AbsoluteFlexContainer,
  BottomButtonContainer,
  Fade,
  FlexContainer,
  FlexContentElement,
  FlexElement,
  FlexHeaderElement,
  StatusChip,
} from "../../components/Components";
import StatusModal from "../../components/StatusModal";
import Table, {
  ControlHeaderContainer,
  ControlHeaderTitle,
} from "../../components/table/Table";
import { useTableSelect } from "../../components/table/useTableSelect";
import { filterItemsByName } from "../../utils/filterItemsByName";
import { successMessage } from "../../utils/successMessage";
import { selectMainPlant } from "../plant/plantSlice";
import { PlantProps } from "../plant/types";
import { PreviewInPaneCommandButton } from "./CommandButtons";
import { SheetPane } from "./SheetPane";
import { pcsCommandOnSelected } from "./commands";
import { minTableWidth, sheetDocumentWidth } from "./config/sheetConfig";
import { useArticleStackQuery } from "./queries/useArticleStack";
import usePCSCommandQuery from "./queries/usePCSCommandQuery";
import { ender, splitter } from "./util";

const articleStackColumns = [
  { key: "AddStackDateTime", title: "Added at", width: 140 },
  { key: "StartTime", title: "Start Time", width: 80 },
  { key: "RunDuration", title: "Run Duration", width: 95 },
  { key: "PCS", title: "PCS", width: 80 },
  { key: "Revision", title: "Rev.", width: 38 },
  { key: "Status", title: "Status", width: 50, Component: StatusChip },
  { key: "ArticlesAdded", title: "Art. Added", width: 85 },
  { key: "ArticlesTotal", title: "Art. Total", width: 85 },
];

export function AddToStackButton({
  plant,
  item,
  pcsCommand,
}: {
  plant: PlantProps;
  item: any;
  pcsCommand: Function;
}) {
  return (
    <Button
      title="Add to Stack"
      variant="ghost_icon"
      onClick={() => {
        pcsCommandOnSelected({
          selectedItems: [item],
          mainPlant: plant,
          command: "articles-stack",
          method: "post",
          pcsCommand,
        });
      }}
    >
      <Icon data={library_add} />
    </Button>
  );
}

export default function ArticleStack() {
  const plant = useAppSelector(selectMainPlant);
  const {
    mutate: pcsCommand,
    status: pcsCommandStatus,
    reset: pcsCommandReset,
    error: pcsCommandError,
    data: pcsCommandData,
  } = usePCSCommandQuery();
  const [lastRunOnlyFilter, setLastRunOnlyFilter] = useState("Y" as "Y" | "N");
  const [nameFilter, setNameFilter] = useState("");
  const { paneModes } = useAppSelector(selectUIState);
  const { data, status, refetch, isRefetching, error } = useArticleStackQuery({
    plant,
    lastRunOnlyFilter,
  });
  const { selection, selectionMode, selectionDispatch } = useTableSelect({
    selectionMode: "multi",
  });
  const dispatch = useAppDispatch();
  const [containerRef, containerSize] = useMeasure();
  const items = useMemo(
    () =>
      data &&
      filterItemsByName({
        nameFilter,
        nameProperty: "PCS",
        items: data,
      }),
    [data, nameFilter]
  );

  return (
    <AbsoluteFlexContainer ref={containerRef}>
      <FlexContentElement>
        <Space.Fill>
          <Space.Fill>
            <Table
              columns={articleStackColumns}
              items={items}
              itemIdProp="itemID"
              selectionMode={selectionMode}
              selection={selection}
              selectionDispatch={selectionDispatch}
              status={status}
              error={error}
              resizable={true}
              sortable={true}
              fillRest={true}
              infoline={true}
              totalCount={data ? data.length : 0}
              contextData={{
                plant,
                dispatch,
                sheetType: "pcs",
                isCopyModal: false,
                nameProperty: "PCS",
                pcsCommand,
              }}
              Commands={[PreviewInPaneCommandButton, AddToStackButton]}
              controlHeader={
                <ArticleStackControlHeader
                  plant={plant}
                  refetch={refetch}
                  isRefetching={isRefetching}
                  lastRunOnlyFilter={lastRunOnlyFilter}
                  setLastRunOnlyFilter={setLastRunOnlyFilter}
                  nameFilter={nameFilter}
                  setNameFilter={setNameFilter}
                />
              }
            />
          </Space.Fill>
          {paneModes.main && (
            <Space.RightResizable
              size={
                containerSize.width > sheetDocumentWidth + minTableWidth
                  ? sheetDocumentWidth
                  : "50%"
              }
              maximumSize={containerSize.width - minTableWidth}
            >
              <SheetPane location="main" />
            </Space.RightResizable>
          )}
        </Space.Fill>
      </FlexContentElement>
      <FlexHeaderElement
        style={{
          textAlign: "center",
          borderTop: "1px solid var(--borderDefault)",
        }}
      >
        <BottomButtonContainer>
          <Button
            disabled={selection.length === 0}
            onClick={() =>
              pcsCommandOnSelected({
                selectedItems: selection.map((sel) => ({
                  PCS: sel.split(ender)[0].split(splitter)[0],
                  Revision: sel.split(ender)[0].split(splitter)[1],
                })),
                mainPlant: plant,
                command: "articles-stack",
                method: "post",
                pcsCommand,
              })
            }
          >
            Add selected sheets to Article Stack
          </Button>
        </BottomButtonContainer>
      </FlexHeaderElement>
      <StatusModal
        status={pcsCommandStatus}
        successMessage={successMessage(pcsCommandData)}
        onSettledClose={pcsCommandReset}
        onSuccess={() => refetch()}
        error={pcsCommandError}
      />
    </AbsoluteFlexContainer>
  );
}

function ArticleStackControlHeader({
  plant,
  refetch,
  isRefetching,
  lastRunOnlyFilter,
  setLastRunOnlyFilter,
  nameFilter,
  setNameFilter,
}: {
  plant: PlantProps;
  refetch: Function;
  isRefetching: boolean;
  lastRunOnlyFilter: "Y" | "N";
  setLastRunOnlyFilter: Function;
  nameFilter: string;
  setNameFilter: Function;
}) {
  return (
    <ControlHeaderContainer>
      <FlexContainer style={{ justifyContent: "flex-start", gap: 12 }}>
        <FlexElement style={{ marginLeft: ".5em" }}>
          <Search
            onChange={(e) => {
              setNameFilter(e.target.value);
            }}
            placeholder="PCS filter"
            value={nameFilter}
            style={{ width: "150px" }}
          />
        </FlexElement>
        <FlexElement>
          <ControlHeaderTitle>
            PCS Article Stack<Fade> in </Fade>
            {plant.LongDescription}
          </ControlHeaderTitle>
        </FlexElement>
        <FlexElement style={{ paddingLeft: "1em" }}>
          <Switch
            checked={lastRunOnlyFilter === "Y"}
            label="Last run only"
            onChange={() =>
              setLastRunOnlyFilter(lastRunOnlyFilter === "Y" ? "N" : "Y")
            }
          />
        </FlexElement>
        <FlexElement style={{ marginLeft: "auto" }}>
          <HeaderProgress isRefetching={isRefetching} />
          <Tooltip title="Reload">
            <Button variant="ghost_icon" onClick={() => refetch()}>
              <Icon data={refresh} />
            </Button>
          </Tooltip>
        </FlexElement>
      </FlexContainer>
    </ControlHeaderContainer>
  );
}
