import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { VDSSubsegment, VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "features/sheets/queries/useVDSTextBlocksDistinctText";
import { useContext, useEffect, useMemo } from "react";

export function VDSTextBlockSectionH2({
  textBlockData,
  subsegmentData,
  setIsLoaded,
}: {
  textBlockData?: VDSTextBlock;
  subsegmentData?: VDSSubsegment;
  setIsLoaded?: React.Dispatch<boolean>;
}) {
  const data = useMemo(
    () =>
      textBlockData
        ? textBlockData
        : subsegmentData
        ? subsegmentData.getVDSSubsegmentHeader[0]
        : undefined,
    [subsegmentData, textBlockData]
  );

  const { disabled } = useContext(FieldContext);

  const { data: designCodes, status: designCodesStatus } =
    useVDSTextBlocksDistinctText({
      attributeName: "CO",
      sectionId: "H",
      itemId: 2,
      disabled,
    });

  useEffect(() => {
    designCodesStatus === "success" && setIsLoaded && setIsLoaded(true);
  }, [designCodesStatus, setIsLoaded]);

  const preProp = subsegmentData ? "getVDSSubsegmentHeader.0." : "";

  return (
    <>
      <Field
        title="Code"
        prop={preProp + "DesignCode"}
        value={data?.DesignCode ?? ""}
        options={designCodes}
        type="autocomplete"
        status={designCodesStatus}
        maxWidth={500}
      />
    </>
  );
}
