import { StatusResponse, StatusResponseWithInfo } from "components/StatusModal";
import { AxiosResponse } from "axios";

export function successMessage(
  serverData?: AxiosResponse<StatusResponse>,
  defaultMessage: string = "The operation was successful."
) {
  if (!serverData || !serverData.hasOwnProperty("data")) {
    return <p>{defaultMessage}</p>;
  }
  const serverReply = serverData["data"];
  return (
    <>
      <p>{serverReply.UserMessage}</p>
      {serverReply.SystemMessage !== serverReply.UserMessage && (
        <p>{serverReply.SystemMessage}</p>
      )}
    </>
  );
}

export function successMessageWithId(
  serverData?: AxiosResponse<StatusResponseWithInfo>
) {
  const message = successMessage(serverData);
  const createdId = serverData?.data.AdditionalInfo;
  return (
    <>
      {message}
      {createdId ? <p>New item created with ID {createdId}.</p> : <></>}
    </>
  );
}
