import Table, { SmallTableContainer } from "../../../components/table/Table";
import { RevisionMark, StatusChip } from "../../../components/Components";
import { useTableSelect } from "../../../components/table/useTableSelect";
import { PreviewButtons } from "../../sheets/SheetPane";
import { useMemoOne as useMemo } from "use-memo-one";
import usePipeElementRevList from "queries/usePipeElementRevList";

export default function usePipeElementSelector({
  defaultSelection,
  elementId,
}: {
  defaultSelection?: string[];
  elementId: number;
}): {
  pipeElementSelectorContent: React.ReactFragment;
  selection: string[];
  selectedLines: any[];
  selectionDispatch: Function;
  isLoading: boolean;
} {
  const { data, status, isLoading, error, refetch } = usePipeElementRevList({
    elementId,
  });

  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: "single",
    selection: defaultSelection,
  });

  const columns = useMemo(
    () => [
      { key: "ElementID", title: "Element ID" },
      { key: "Revision", title: "Rev.", longTitle: "Revision" },
      { key: "Status", title: "Status", Component: StatusChip },
      { key: "DimensionStandard", title: "Dim. Standard" },
      { key: "ProductForm", title: "Type" },
      { key: "MaterialGrade", title: "Material Name" },
      { key: "MDS", title: "MDS", width: 90 },
      {
        key: "MDSRevision",
        title: "MDS Rev.",
        longTitle: "MDS Revision",
        Component: RevisionMark,
      },
      { key: "Area", title: "Area", width: 160 },
    ],
    []
  );

  const pipeElementSelectorContent = (
    <SmallTableContainer>
      <Table
        density="compact"
        items={data}
        itemIdProp="Revision"
        columns={columns}
        status={status}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={true}
        error={error}
        refetch={refetch}
        loadingString="Loading Pipe Elements..."
        selectedFirst={true}
        RowMenu={PreviewButtons}
        fillRest={true}
      />
    </SmallTableContainer>
  );

  return {
    pipeElementSelectorContent,
    selection,
    selectionDispatch,
    selectedLines:
      selection && data
        ? selection.map((s) => data.find((i: any) => i.Revision === s))
        : [],
    isLoading,
  };
}
