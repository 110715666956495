import { Button } from "@equinor/eds-core-react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
} from "components/ModalWindow";
import { MediumTableContainer } from "components/table/Table";
import { useCallback } from "react";
import { DocumentSpaceContextData } from "./DocumentSpace";
import { useDocumentSpace } from "./queries/useDocumentSpace";
import { useDirectoryTree } from "./useDirectoryTree";

export function Relocate({
  contextData,
  openItems,
}: {
  contextData: DocumentSpaceContextData;
  openItems: string[];
}) {
  const {
    relocateOpen,
    setRelocateOpen,
    relocateItems,
    mutateObjectRelocate,
    filesData,
  } = contextData;

  const closeModal = useCallback(() => {
    setRelocateOpen(false);
  }, [setRelocateOpen]);

  const query = useDocumentSpace();

  const { content, selection } = useDirectoryTree({
    query,
    contextData,
    title: "Select target folder",
    disableRowMenu: true,
    defaultOpenItems: openItems,
  });

  return (
    <ModalWindow
      title={`Relocate ${
        relocateItems.length === 1
          ? filesData?.find((e) => e.OID === relocateItems[0].oid)?.Description
          : relocateItems.length + " files"
      }`}
      isOpen={relocateOpen}
      closeModal={closeModal}
    >
      <ModalSideMargin>
        <MediumTableContainer style={{ width: 600 }}>
          {content}
        </MediumTableContainer>
      </ModalSideMargin>
      <ModalWindowButtonContainer>
        <Button
          disabled={selection.length === 0}
          onClick={() => {
            mutateObjectRelocate({
              oid: Number(selection[0]),
              objects: relocateItems,
            });
          }}
        >
          Relocate
        </Button>
        <Button onClick={closeModal} variant="outlined">
          Cancel
        </Button>
      </ModalWindowButtonContainer>
    </ModalWindow>
  );
}
