import { Button, NativeSelect } from "@equinor/eds-core-react";
import { FlexRowContainer } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import { useEffect, useMemo, useState } from "react";
import { EditVDSTextBlock } from "./EditVDSTextBlock";

export function NewVDSTextBlock({
  showCreateNew,
  setShowCreateNew,
  groupFilter,
}: {
  showCreateNew: boolean;
  setShowCreateNew: React.Dispatch<boolean>;
  groupFilter: string;
}) {
  const closeModal = () => {
    setShowCreateNew(false);
  };

  const [showEditNew, setShowEditNew] = useState(false);

  const { data: vdsSubsegmentGroups, status: vdsSubsegmentGroupsStatus } =
    useCodelist({ codelist: "vds-subsegment-groups" });
  const {
    data: vdsSubsegmentGroupsConfig,
    status: vdsSubsegmentGroupsConfigStatus,
  } = useCodelist({ codelist: "vds-subsegment-groups-config" });

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        vdsSubsegmentGroupsStatus,
        vdsSubsegmentGroupsConfigStatus
      ),
    [vdsSubsegmentGroupsStatus, vdsSubsegmentGroupsConfigStatus]
  );

  const [group, setGroup] = useState(Number(groupFilter));
  const [itemId, setItemId] = useState(0);

  const configOptions = useMemo(
    () =>
      vdsSubsegmentGroupsConfig
        ? vdsSubsegmentGroupsConfig
            .filter((e) => e.GroupID === group)
            .map((e) => ({
              itemId: e.ItemID,
              itemSection: e.ItemSection,
              description: e.ItemDescription,
            }))
        : [],
    [group, vdsSubsegmentGroupsConfig]
  );

  useEffect(() => {
    groupFilter
      ? setGroup(Number(groupFilter))
      : vdsSubsegmentGroups &&
        vdsSubsegmentGroups.length > 0 &&
        setGroup(vdsSubsegmentGroups[0].GroupID);
  }, [groupFilter, vdsSubsegmentGroups]);

  useEffect(() => {
    if (configOptions.length > 0) {
      setItemId(configOptions[0].itemId);
    }
  }, [configOptions]);

  const selectedConfigOption = useMemo(
    () => configOptions.find((e) => e.itemId === itemId),
    [configOptions, itemId]
  );

  useEffect(() => {
    !showCreateNew && setShowEditNew(false);
  }, [showCreateNew]);

  return (
    <>
      <ModalWindow
        isOpen={showCreateNew && !showEditNew}
        closeModal={closeModal}
        title="Create a new VDS Text Block"
        isLoading={combinedStatuses === "loading"}
      >
        <>
          <ModalWindowContentDefault style={{ width: 400 }}>
            <ModalSideMargin>
              <FlexRowContainer>
                <div>
                  <NativeSelect
                    label="Group"
                    id="vds-subsegment-group"
                    value={group}
                    onChange={(e) => setGroup(Number(e.target.value))}
                  >
                    {vdsSubsegmentGroups?.map((e) => (
                      <option key={e.GroupID} value={e.GroupID}>
                        {e.Description}
                      </option>
                    ))}
                  </NativeSelect>
                </div>
                <div>
                  <NativeSelect
                    label="Type"
                    value={itemId}
                    id="vds-subsegment-group-type"
                    onChange={(e) => {
                      setItemId(Number(e.target.value));
                    }}
                  >
                    {configOptions.map((e) => (
                      <option key={e.itemId} value={e.itemId}>
                        {e.itemSection} - {e.description}
                      </option>
                    ))}
                  </NativeSelect>
                </div>
              </FlexRowContainer>
            </ModalSideMargin>
          </ModalWindowContentDefault>
          <ModalWindowButtonContainer>
            <Button
              disabled={!selectedConfigOption}
              onClick={() => {
                setShowEditNew(true);
              }}
            >
              Continue
            </Button>
            <Button variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
          </ModalWindowButtonContainer>
        </>
      </ModalWindow>
      {showEditNew && selectedConfigOption && (
        <EditVDSTextBlock
          isCreate={true}
          itemId={selectedConfigOption.itemId}
          itemSection={selectedConfigOption.itemSection}
          group={group}
          showCreateNew={showCreateNew}
          setShowCreateNew={setShowCreateNew}
        />
      )}
    </>
  );
}
