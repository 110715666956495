export function filterVDSOfficialIfWorkingExists({
  enabled,
  items,
}: {
  enabled: boolean;
  items: any[];
}) {
  if (!enabled) {
    return items;
  }
  const workings = items
    .filter((item) => item.Status === "W")
    .map((e) => e.VDS);
  return items.filter(
    (item) =>
      (item.Status === "O" && !workings.includes(item.VDS)) ||
      item.Status !== "O"
  );
}
