import {
  Button,
  CircularProgress,
  Icon,
  NativeSelect,
} from "@equinor/eds-core-react";
import {
  FlexColumnContainer,
  FlexColumnElement,
  FlexContainer,
} from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
  ModalWindowSimpleContainer,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { defaultUnsavedMessage } from "components/confirm/ConfirmContextModal";
import useConfirm from "components/confirm/useConfirm";
import { MultiselectFieldFromCommaSeparated } from "components/form/EditComponents";
import { Field, OptionsWithIds } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import {
  Fieldset,
  FieldsetContainer,
  FormRowBlock,
} from "components/form/Form";
import { PipeElementGroup } from "features/admin/pipe-element-groups/pipeElementGroupsConfig";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useOperators from "queries/useOperators";
import { usePFL2Verification } from "queries/usePFL2Verification";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { successMessage } from "utils/successMessage";
import {
  CreateMasterPipeElementType,
  EditMasterPipeElementType,
  useSaveMasterPipeElement,
} from "../queries/useSaveMasterPipeElement";
import { FlatMasterPipeElements } from "./EditPipeMaterialGroup";
import {
  check_circle_outlined,
  close_circle_outlined,
  remove_outlined,
} from "@equinor/eds-icons";
import { usePipeArticleConfig } from "../queries/usePipeArticleConfig";
import { priorities } from "../priorities";

export function CreateMasterPipeElement({
  materialGroupId,
  materialGroupRevision,
  setShowWindow,
  elementGroupId,
  setElementGroupId,
  pipeElementGroups,
}: {
  materialGroupId: string;
  materialGroupRevision: string;
  setShowWindow: React.Dispatch<boolean>;
  elementGroupId?: number;
  setElementGroupId: React.Dispatch<number | undefined>;
  pipeElementGroups: PipeElementGroup[] | undefined;
}) {
  const [elementGroupChoosen, setElementGroupChoosen] = useState(false);

  return (
    <>
      {!elementGroupChoosen ? (
        <ModalWindow
          closeModal={() => {
            setShowWindow(false);
          }}
          title="Choose Pipe Element Group"
          isOpen={true}
        >
          <ModalWindowContentDefault>
            <ModalSideMargin>
              <ModalWindowSimpleContainer>
                <NativeSelect
                  id="ElementGroup"
                  label="Pipe Element Group"
                  onChange={(e) => {
                    setElementGroupId(Number(e.target.value));
                  }}
                  value={elementGroupId ?? ""}
                >
                  {pipeElementGroups?.map((e) => (
                    <option key={e.ElementGroupID} value={e.ElementGroupID}>
                      {e.ElementGroup}
                    </option>
                  ))}
                </NativeSelect>
              </ModalWindowSimpleContainer>
            </ModalSideMargin>
          </ModalWindowContentDefault>
          <ModalWindowButtonContainer>
            <Button onClick={() => setElementGroupChoosen(true)}>
              Continue
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setShowWindow(false);
              }}
            >
              Cancel
            </Button>
          </ModalWindowButtonContainer>
        </ModalWindow>
      ) : (
        <EditMasterPipeElement
          editMPE={true}
          setEditMPE={setShowWindow}
          materialGroupId={materialGroupId}
          materialGroupRevision={materialGroupRevision}
          elementGroupId={elementGroupId}
        />
      )}
    </>
  );
}

type MasterPipeElementForm = {
  DimStandardID: string | number;
  ProductFormID: string | number;
  MaterialGradeID: string | number;
  AddDimensionManually: boolean;
  Priority: string | number;
  WeldingFactorMandatory: boolean;
  WeldingFactor: number | string;
  Note: string;
  OperatorValidity: string;
  AreaValidity: string;
  SpecialReqValidity: string;
  PFL2ID: number | string;
};

export function EditMasterPipeElement({
  materialGroupId,
  materialGroupRevision,
  editMPE,
  setEditMPE,
  flatData,
  elementGroupId,
}: {
  materialGroupId: string;
  materialGroupRevision: string;
  editMPE: number | boolean;
  setEditMPE: React.Dispatch<number | false> | React.Dispatch<boolean>;
  flatData?: FlatMasterPipeElements;
  elementGroupId?: number;
}) {
  const { isConfirmed } = useConfirm();

  const data = useMemo(
    () => flatData?.find((e) => e.MasterPipeElementID === editMPE),
    [editMPE, flatData]
  );

  const formData: Partial<MasterPipeElementForm> = useMemo(
    () => ({
      ...data,
      AddDimensionManually: data?.AddDimensionManually === "Y",
      WeldingFactorMandatory: data?.WeldingFactorIndicator === "Y",
    }),
    [data]
  );

  const methods = useForm<MasterPipeElementForm>({
    mode: "all",
    defaultValues: formData,
  });
  const { handleSubmit, formState, reset, watch, setValue, trigger } = methods;
  const { isDirty } = formState;

  const closeModal = useCallback(async () => {
    isDirty
      ? (await isConfirmed(defaultUnsavedMessage)) && setEditMPE(false)
      : setEditMPE(false);
  }, [isConfirmed, isDirty, setEditMPE]);

  const currentElementGroup = elementGroupId ?? data?.elementGroupId;

  const { data: pipeElementGroups, status: pipeElementGroupsStatus } =
    useCodelist({ codelist: "pipe-element-groups" });

  const currentPipeElementGroupData = useMemo(
    () =>
      pipeElementGroups?.find((e) => e.ElementGroupID === currentElementGroup),
    [currentElementGroup, pipeElementGroups]
  );

  const operatorValidity = useMemo(
    () =>
      data?.MasterPipeElementValidities.filter((e) => e.ValidityType === "O")
        .map((e) => e.ValidityID)
        .join(","),
    [data?.MasterPipeElementValidities]
  );
  const areaValidity = useMemo(
    () =>
      data?.MasterPipeElementValidities.filter((e) => e.ValidityType === "A")
        .map((e) => e.ValidityID)
        .join(","),
    [data?.MasterPipeElementValidities]
  );
  const specialRequiremenetValidity = useMemo(
    () =>
      data?.MasterPipeElementValidities.filter((e) => e.ValidityType === "S")
        .map((e) => e.ValidityID)
        .join(","),
    [data?.MasterPipeElementValidities]
  );

  const { data: operatorData, status: operatorStatus } = useOperators();
  const operatorOptions = useMemo(
    () =>
      operatorData?.map((e) => ({
        key: String(e.OperatorID),
        title: e.OperatorName,
      })),
    [operatorData]
  );
  const { data: areaData, status: areaStatus } = useCodelist({
    codelist: "areas",
  });
  const areaOptions = useMemo(
    () =>
      areaData?.map((e) => ({
        key: String(e.AreaID),
        title: e.Description,
      })) ?? [],
    [areaData]
  );
  const { data: specialRequirementsData, status: specialRequirementsStatus } =
    useCodelist({ codelist: "special-requirements" });
  const specialRequirementsOptions = useMemo(
    () =>
      specialRequirementsData?.map((e) => ({
        key: String(e.SpecialRequirementID),
        title: e.Description,
      })) ?? [],
    [specialRequirementsData]
  );

  const { data: dimStandards, status: dimStandardsStatus } = useCodelist({
    codelist: "pmg-dimension-standards",
  });
  const dimStandardsOptions = useMemo<OptionsWithIds>(
    () =>
      dimStandards
        ?.filter(
          (e) =>
            e.ElementGroupID === 0 || e.ElementGroupID === currentElementGroup
        )
        .map((e) => ({ id: e.DimStandardID, option: e.Description })) ?? [],
    [currentElementGroup, dimStandards]
  );

  const { data: productFormData, status: productFormStatus } = useCodelist({
    codelist: "pmg-productforms",
  });
  const productFormOptions = useMemo<OptionsWithIds>(
    () =>
      productFormData
        ?.filter(
          (e) =>
            e.ElementGroupID === 0 || e.ElementGroupID === currentElementGroup
        )
        .map((e) => ({ id: e.ProductFormID, option: e.Description })) ?? [],
    [currentElementGroup, productFormData]
  );

  const { data: materialGradeData, status: materialGradeStatus } = useCodelist({
    codelist: "material-grades",
  });
  const materialGradeOptions = useMemo<OptionsWithIds>(
    () =>
      materialGradeData?.map((e) => ({
        id: e.MaterialGradeID,
        option: e.Materialstandard + " " + e.MaterialGrade,
      })) ?? [],
    [materialGradeData]
  );

  const { data: pfl2Data, status: pfl2Status } = useCodelist({
    codelist: "product-form-level2",
  });
  const pfl2Options = useMemo<OptionsWithIds>(
    () =>
      pfl2Data?.map((e) => ({
        id: e.ProductFormLevel2ID,
        option: e.Description,
      })) ?? [],
    [pfl2Data]
  );

  const { data: pipeArticleConfigData, status: pipeArticleConfigStatus } =
    usePipeArticleConfig({ pipeElementGroup: currentElementGroup ?? 0 });

  const combinedStatuses = combineQueryStatuses(
    dimStandardsStatus,
    productFormStatus,
    materialGradeStatus,
    pfl2Status,
    pipeElementGroupsStatus,
    pipeArticleConfigStatus
  );

  const shouldClose = useRef(false);

  const onSuccess = useCallback(() => {
    reset(undefined, { keepDirty: false, keepValues: true });
    if (shouldClose.current) {
      setEditMPE(false);
    }
  }, [reset, setEditMPE]);

  const onSuccessClose = useCallback(() => {
    if (editMPE === true) {
      setEditMPE(false);
    }
  }, [editMPE, setEditMPE]);

  const {
    mutate,
    status: mutateStatus,
    error: mutateError,
    reset: mutateReset,
    data: mutateData,
  } = useSaveMasterPipeElement();

  const processFormData = (
    data: any,
    elementGroupId?: number
  ): EditMasterPipeElementType | CreateMasterPipeElementType => ({
    ...data,
    AddDimensionManually: data.AddDimensionManually ? "Y" : "N",
    WeldingFactorMandatory: data.WeldingFactorMandatory ? "Y" : "N",
    ...(elementGroupId ? { ElementGroupID: elementGroupId } : {}),
  });

  const onSubmit = useCallback(
    (data) => {
      if (editMPE === true) {
        mutate({
          materialGroupId,
          materialGroupRevision,
          data: processFormData(data, elementGroupId),
          method: "post",
        });
      } else if (!!editMPE) {
        mutate({
          materialGroupId,
          materialGroupRevision,
          data: processFormData(data, undefined),
          method: "put",
          mpeId: editMPE,
        });
      }
    },
    [editMPE, elementGroupId, materialGroupId, materialGroupRevision, mutate]
  );

  const fullEdit = data?.MasterPipeElementRevision === "0" || editMPE === true;

  const mandatoryWFWatch = watch("WeldingFactorMandatory");

  useEffect(() => {
    if (!mandatoryWFWatch) {
      setValue("WeldingFactor", "");
      trigger("WeldingFactor");
    }
  }, [mandatoryWFWatch, setValue, trigger]);

  const materialGradeId = watch("MaterialGradeID");
  const dimStandardId = watch("DimStandardID");
  const productFormId = watch("ProductFormID");

  const materialGrade = useMemo(
    () =>
      materialGradeId
        ? materialGradeOptions.find(
            (e) => String(e.id) === String(materialGradeId)
          )?.option ?? ""
        : "",
    [materialGradeId, materialGradeOptions]
  );
  const dimStandard = useMemo(
    () =>
      dimStandardId
        ? dimStandardsOptions.find(
            (e) => String(e.id) === String(dimStandardId)
          )?.option ?? ""
        : "",
    [dimStandardId, dimStandardsOptions]
  );
  const productForm = useMemo(
    () =>
      productFormId
        ? productFormOptions.find((e) => String(e.id) === String(productFormId))
            ?.option ?? ""
        : "",
    [productFormId, productFormOptions]
  );

  const {
    data: pfl2VerficationData,
    status: pfl2VerificationStatus,
    error: pfl2VerificationError,
  } = usePFL2Verification({
    materialGrade,
    dimStandard,
    productForm,
    elementGroupId: currentElementGroup,
    disabled: !fullEdit || (!materialGrade && !dimStandard && !productForm),
  });

  const verifiedValue = pfl2VerficationData?.getPFL2[0].ProductFormLevel2ID;

  useEffect(() => {
    if (verifiedValue) {
      setValue("PFL2ID", verifiedValue === -1 ? 0 : verifiedValue);
      setPFL2Manual(false);
    }
  }, [pfl2VerficationData, setValue, verifiedValue]);

  const [pfl2Manual, setPFL2Manual] = useState(false);

  return (
    <>
      <ModalWindow
        isOpen={!!editMPE}
        closeModal={closeModal}
        title={
          editMPE === true
            ? `Add a Master Pipe Element to ${currentPipeElementGroupData?.ElementGroup}`
            : `Edit Master Pipe Element ${
                currentPipeElementGroupData?.ElementGroup
              } ${editMPE ?? ""}`
        }
        status={combinedStatuses}
      >
        <ModalSideMargin>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldContext.Provider
                value={{
                  slim: true,
                }}
              >
                <FlexColumnContainer>
                  <FlexColumnElement>
                    <FieldsetContainer>
                      <Fieldset>
                        <Field
                          type="option"
                          value={data?.DimStandardID ?? 0}
                          optionsWithIds={dimStandardsOptions}
                          title="Dimension Standard"
                          prop="DimStandardID"
                          disabled={!fullEdit}
                          notSetValue={0}
                          maxWidth={400}
                        />
                        <Field
                          type="option"
                          value={data?.ProductFormID ?? 0}
                          optionsWithIds={productFormOptions}
                          title="Product Form"
                          prop="ProductFormID"
                          disabled={!fullEdit}
                          notSetValue={0}
                          maxWidth={400}
                        />
                        <Field
                          type="option"
                          value={data?.MaterialGradeID ?? 0}
                          optionsWithIds={materialGradeOptions}
                          title="Material Grade"
                          prop="MaterialGradeID"
                          disabled={!fullEdit}
                          notSetValue={0}
                          maxWidth={400}
                          validate={{
                            notNull: (value) =>
                              Number(value) !== 0 ||
                              "Material Grade must be selected.",
                          }}
                        />
                        <FlexContainer style={{ alignItems: "center" }}>
                          <div>
                            <Field
                              type="option"
                              value={data?.ProductFormLevel2ID ?? 0}
                              optionsWithIds={pfl2Options}
                              title="Product Form Level 2"
                              prop="PFL2ID"
                              disabled={!fullEdit}
                              notSetValue={0}
                              style={{ marginBottom: 0, marginTop: 0 }}
                              maxWidth={360}
                              onChange={() => {
                                setPFL2Manual(true);
                              }}
                              validate={{
                                notNull: (value) =>
                                  Number(value) !== 0 ||
                                  "PFL2 must be selected.",
                              }}
                            />
                          </div>
                          <div
                            style={{ alignSelf: "flex-end", marginBottom: 4 }}
                          >
                            {pfl2Manual ? (
                              <></>
                            ) : pfl2VerificationStatus === "loading" ? (
                              <div title="Loading PFL2 Mapping...">
                                <CircularProgress size={16} />
                              </div>
                            ) : pfl2VerificationStatus === "error" ? (
                              <Icon
                                data={close_circle_outlined}
                                title={pfl2VerificationError?.message ?? ""}
                                size={16}
                              />
                            ) : verifiedValue && verifiedValue > -1 ? (
                              <Icon
                                data={check_circle_outlined}
                                size={16}
                                title="PFL2 Mapping found and set."
                              />
                            ) : verifiedValue && verifiedValue === -1 ? (
                              <Icon
                                data={remove_outlined}
                                size={16}
                                title="PFL2 Mapping not found."
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </FlexContainer>
                        {pipeArticleConfigData &&
                          pipeArticleConfigData[0].ManualDimension === "Y" && (
                            <Field
                              type="checkbox"
                              value={data?.AddDimensionManually ?? "N"}
                              title="Add Dimension Manually"
                              prop="AddDimensionManually"
                              disabled={!fullEdit}
                            />
                          )}
                        <Field
                          type="option"
                          value={data?.Priority ?? "1"}
                          title="Priority"
                          prop="Priority"
                          options={priorities}
                          maxWidth={100}
                          disableNotSetOption
                        />
                        <Field
                          type="checkbox"
                          value={data?.WeldingFactorIndicator ?? "Y"}
                          title="Mandatory Welding Factor"
                          prop="WeldingFactorMandatory"
                        />
                        <Field
                          type="text"
                          value={data?.WeldingFactorValue ?? ""}
                          title="Welding Factor"
                          prop="WeldingFactor"
                          disabled={mandatoryWFWatch === false}
                          maxWidth={100}
                          validate={{
                            minMax: (value) =>
                              (mandatoryWFWatch &&
                                Number(value) > 0 &&
                                Number(value) <= 1) ||
                              !mandatoryWFWatch ||
                              "Welding Factor must be larger than 0 and less than or equal to 1",
                          }}
                        />
                        <Field
                          type="text"
                          rows={3}
                          value={data?.FreeTextNote ?? ""}
                          prop={"Note"}
                          title={"Note"}
                        />
                      </Fieldset>
                    </FieldsetContainer>
                  </FlexColumnElement>
                  <FlexColumnElement style={{ minWidth: 300 }}>
                    <FieldsetContainer>
                      <Fieldset>
                        <FormRowBlock>
                          <MultiselectFieldFromCommaSeparated
                            title="Operator Validity"
                            prop="OperatorValidity"
                            value={operatorValidity ?? ""}
                            options={operatorOptions}
                            noneSelectedString="All"
                            noneSelectedValue=""
                            status={operatorStatus}
                            height={335}
                          />
                        </FormRowBlock>
                        <FormRowBlock
                          style={{ marginTop: 30, marginBottom: 0 }}
                        >
                          <MultiselectFieldFromCommaSeparated
                            title="Area Validity"
                            prop="AreaValidity"
                            value={areaValidity ?? "1"}
                            options={areaOptions}
                            noneSelectedString="All"
                            noneSelectedValue=""
                            status={areaStatus}
                            height={200}
                          />
                        </FormRowBlock>
                      </Fieldset>
                    </FieldsetContainer>
                  </FlexColumnElement>
                  <FlexColumnElement style={{ minWidth: 300 }}>
                    <FieldsetContainer>
                      <Fieldset>
                        <FormRowBlock style={{ marginBottom: 0 }}>
                          <MultiselectFieldFromCommaSeparated
                            title="Special Requirement Validity"
                            prop="SpecialReqValidity"
                            value={specialRequiremenetValidity ?? ""}
                            options={specialRequirementsOptions}
                            noneSelectedString="None"
                            noneSelectedValue=""
                            status={specialRequirementsStatus}
                            height={565}
                          />
                        </FormRowBlock>
                      </Fieldset>
                    </FieldsetContainer>
                  </FlexColumnElement>
                </FlexColumnContainer>
                <ModalWindowButtonContainer>
                  {editMPE === true && <Button type="submit">Create</Button>}
                  {editMPE !== true && (
                    <Button
                      variant="outlined"
                      type="submit"
                      onClick={() => (shouldClose.current = false)}
                    >
                      Save
                    </Button>
                  )}
                  {editMPE !== true && (
                    <Button
                      type="submit"
                      onClick={() => (shouldClose.current = true)}
                    >
                      Save & Close
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Close
                  </Button>
                </ModalWindowButtonContainer>
              </FieldContext.Provider>
            </form>
          </FormProvider>
        </ModalSideMargin>
      </ModalWindow>
      <StatusModal
        status={mutateStatus}
        error={mutateError}
        onSuccess={onSuccess}
        onSettledClose={mutateReset}
        onSuccessClose={onSuccessClose}
        successMessage={successMessage(mutateData)}
      />
    </>
  );
}
