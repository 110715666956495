import config from "config/config";
import axios from "axios";
import { PlantProps } from "features/plant/types";
import {
  SheetTypes,
  plantDependentSheets,
  sheetProperties,
} from "features/sheets/config/sheetConfig";
import { SheetStatus } from "features/sheets/config/statusConfig";
import { VDSItemSections } from "features/sheets/types";
import { getItemID } from "features/sheets/util";
import { useQuery } from "react-query";

export type SheetFilter = Partial<{
  nameFilter: string;
  statusFilter: SheetStatus[];
  notInFilter: string;
  elementId: string | number;
  noteId: string | number;
  vds: string;
  elementGroupId: string | number;
  customName: "Y" | "N";
  valveCategoryId: string | number;
  specialReqId: string | number;
  basedOnSubsegment: "Y" | "N";
  valveTypeId: string | number;
  ratingClassId: string | number;
  materialGroupId: string | number;
  endConnectionId: string | number;
  boreId: string | number;
  vdsSizeId: string | number;
  specialCharacteristics: string;
  plantId: string | number;
  subsegmentRef: string | number;
  freeFormatfilter: "F";
  textBlockNameFilter: string;
  groupId: string | number;
  textBlockId: string;
}>;

export type SheetFilterDeprecated<T extends SheetTypes> = Partial<
  T extends "pcs"
    ? {
        nameFilter: string;
        statusFilter: SheetStatus[];
        notInFilter: number;
        elementId: number;
        noteId: number;
        vds: string;
      }
    : T extends "eds"
    ? {
        nameFilter: string;
        statusFilter: SheetStatus[];
        elementGroupId: number;
      }
    : T extends "vds"
    ? {
        nameFilter: string;
        statusFilter: SheetStatus[];
        customName: "Y" | "N";
        valveCategoryId: number;
        specialReqId: number;
        basedOnSubsegment: "Y";
        valveTypeId: number;
        ratingClassId: number;
        materialGroupId: number;
        endConnectionId: number;
        boreId: number;
        vdsSizeId: number;
        specialCharacteristics: number;
        plantId: number;
        subsegmentRef: string;
      }
    : T extends "vds-unstructured"
    ? { basedOnSubsegment: "N" }
    : T extends "general"
    ? {
        statusFilter: SheetStatus[];
      }
    : T extends "sc"
    ? {
        nameFilter: string;
        freeFormatfilter: "F";
      }
    : T extends "vsm"
    ? {
        nameFilter: string;
        freeformatFilter: "F";
      }
    : T extends "vds-subsegments"
    ? {
        materialTypeId: number;
        ratingClassId: number;
        statusFilter: SheetStatus[];
      }
    : T extends "vds-textblocks"
    ? {
        textBlockNameFilter: string;
        groupId: number;
        statusFilter: SheetStatus[];
      }
    : never
>;

type CommonSheetProps = {
  Status: SheetStatus;
  Revision: string;
  RevDate: string;
  LastUpdate: string;
  LastUpdateBy: string;
  itemID: string;
};

export type SheetsTypeData<T extends SheetTypes> = (T extends "pcs"
  ? {
      PCS: string;
      RatingClass: string;
      TestPressure: string;
      MaterialGroup: string;
      DesignCode: string;
      Approver: string;
      Notepad: string;
      SpecialReqID: number;
      TubePCS: string;
      NewVDSSection: string;
    }
  : T extends "eds"
  ? {
      ArticleContent: "N" | "Y";
      EDS: string;
      ElementGroupID: number;
      HTMLContent: string;
      Notepad: string;
    }
  : T extends "vds"
  ? {
      VDS: string;
      Revision: string;
      Description: string;
      Notepad: string;
      SpecialReqID: number;
      ValveTypeID: number;
      RatingClassID: number;
      MaterialGroupID: number;
      EndConnectionID: number;
      BoreID: number;
      VDSSizeID: number;
      SizeRange: string;
      CustomName: "N" | "Y";
      SubsegmentList: string;
    }
  : T extends "vds-unstructured"
  ? {
      VDS: string;
      Revision: string;
      Description: string;
      Notepad: string;
      SpecialReqID: number;
      ValveTypeID: number;
      RatingClassID: number;
      MaterialGroupID: number;
      EndConnectionID: number;
      BoreID: number;
      VDSSizeID: number;
      SizeRange: string;
      CustomName: "N" | "Y";
      SubsegmentList: string;
    }
  : T extends "general"
  ? {
      Notepad: string;
    }
  : T extends "sc"
  ? {
      SC: string;
      Format: string;
      Notepad: string;
    }
  : T extends "vsm"
  ? {
      VSM: string;
      Format: string;
      Notepad: string;
    }
  : T extends "vds-subsegments"
  ? {
      GroupID: number;
      SubsegmentID: number;
      SubsegmentName: string;
      VDSTextBlockID: string;
      ValveType: string;
      RatingClass: string;
      MaterialGroup: string;
      EndConnection: string;
      Bore: string;
      VDSSize: string;
      SpecialRequirement: string;
    }
  : T extends "vds-textblocks"
  ? {
      GroupID: number;
      TextBlockID: number;
      ItemDescription: string;
      TextBlockName: string;
      ItemID: number;
      ItemSection: VDSItemSections;
      VDSDescription: string;
      MinDesignTemperature: string;
      MaxDesignTemperature: string;
      MinOperatingTemperature: string;
      MaxOperatingTemperature: string;
      ValveType: string;
      RatingClass: string;
      MaterialGroup: string;
      EndConnection: string;
      Bore: string;
      VDSSize: string;
      SpecialRequirement: string;
    }
  : T extends "vsk"
  ? {
      VSK: string;
      Notepad: string;
    }
  : T extends "mds"
  ? {
      MDS: string;
      Notepad: string;
      Area: string;
    }
  : T extends "pmg/material-groups"
  ? {
      MaterialGroupID: number;
      MaterialGroup: string;
      Revision: string;
      Status: SheetStatus;
      RevDate: string;
      LastUpdate: string;
      LastUpdateBy: string;
      Notepad: string;
    }
  : never) &
  CommonSheetProps;

type SheetsAPIData<T extends SheetTypes> = T extends "pcs"
  ? {
      getPCS: SheetsTypeData<"pcs">[];
    }
  : T extends "vds"
  ? {
      getVDS: SheetsTypeData<"vds">[];
      totalCount: number;
    }
  : never;

export type SheetsReturnData<T extends SheetTypes> = {
  sheets: SheetsTypeData<T>[];
  totalCount?: number;
};

export function useSheetList<T extends SheetTypes>({
  plant,
  sheetType,
  select,
  disabled,
  sheetFilter,
}: {
  sheetType: T;
  plant?: PlantProps;
  select?: (arg0: any) => any;
  disabled?: boolean;
  sheetFilter?: SheetFilter;
}) {
  return useQuery<SheetsReturnData<T>, Error>(
    [
      "sheets",
      ...(plantDependentSheets.includes(sheetType) && plant
        ? [plant.PlantID]
        : []),
      sheetType,
      sheetFilter,
    ],
    async () => {
      const { listProperty, nameProperty, apiPath } =
        sheetProperties[sheetType];
      const url = `${config.restApiBaseUrl}${
        plantDependentSheets.includes(sheetType) && plant
          ? `plants/${plant.PlantID}/`
          : ""
      }${apiPath ?? sheetType}?${
        sheetFilter
          ? Object.keys(sheetFilter)
              .filter((f) => !!sheetFilter[f as keyof typeof sheetFilter])
              .map(
                (filter) =>
                  `${filter}=${sheetFilter[filter as keyof typeof sheetFilter]}`
              )
              .join("&")
          : ""
      }`;
      const { data } = await axios.get<SheetsAPIData<T>>(url);
      return {
        sheets:
          listProperty in data
            ? (
                data[listProperty as keyof typeof data] as SheetsTypeData<T>[]
              ).map((item: any) => {
                return {
                  ...item,
                  ...{
                    itemID: getItemID(item[nameProperty], item["Revision"]),
                  },
                  ...(sheetType === "vds-textblocks" && {
                    DesignTemperature: `${item.MinDesignTemperature}${
                      item.MaxDesignTemperature ? " / " : ""
                    }${item.MaxDesignTemperature}`,
                    OperatingTemperature: `${item.MinOperatingTemperature}${
                      item.MaxOperatingTemperature ? " / " : ""
                    }${item.MaxOperatingTemperature}`,
                  }),
                };
              })
            : [],
        totalCount: "totalCount" in data ? data["totalCount"] : 0,
      };
    },
    {
      enabled: !disabled,
      select,
    }
  );
}

export const useSheetsNamesNew = <T extends SheetTypes>({
  plant,
  sheetType,
  disabled,
  sheetFilter,
}: {
  sheetType: T;
  plant?: PlantProps;
  disabled?: boolean;
  sheetFilter?: SheetFilter;
}) =>
  useSheetList({
    plant,
    sheetType,
    sheetFilter,
    select: (data) => {
      const { nameProperty } = sheetProperties[sheetType];
      return data.sheets.map((item: any) => item[nameProperty]);
    },
    disabled,
  });
