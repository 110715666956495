import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

export function useOperatorList({
  oid,
  enabled,
}: {
  oid: number;
  enabled: boolean;
}) {
  return useQuery(
    ["document-space", "operators", oid],
    async () => {
      const { data } = await axios.get<{
        getOperatorList: { OperatorID: number; Description: string }[];
      }>(getApiUrl(["document-space", oid, "operator-list"].join("/")));
      return data.getOperatorList;
    },
    {
      enabled: !!oid && enabled,
    }
  );
}
