import config from "../config/config";

export const debugLog = (
  category: string,
  message: string | number | (string | number)[],
  logLevel: "info" | "warn" | "error" = "info"
) => {
  if (config.debugLog) {
    console[logLevel](
      `[${category}] ${
        typeof message === "string" || typeof message === "number"
          ? message
          : message.join("\n")
      }`
    );
  }
};
