import { useLocation } from "react-router-dom";
import { ReportFormHeader } from "../ReportFormHeader";
import { MDSVDSSearchData } from "./mdsVDSSearchConfig";
import { useMemo } from "react";
import { NativeSelect, TextField, Typography } from "@equinor/eds-core-react";
import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../../../components/ReportTable";
import { FlexContainer, FlexElement } from "components/Components";

export const mdsVDSSearchColumns: {
  key: keyof MDSVDSSearchData[0];
  title: string;
}[] = [
  { key: "MDS", title: "MDS" },
  { key: "VDS", title: "VDS" },
  { key: "Revision", title: "Revision" },
  { key: "Status", title: "Status" },
  { key: "RevDate", title: "Rev. Date" },
  { key: "LastUpdate", title: "Last Update" },
];

export function MDSVDSSearchReport({
  data,
}: {
  data: MDSVDSSearchData | undefined;
}) {
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <>
      <ReportFormHeader>
        <FlexContainer flexStart gap={24}>
          <FlexElement>
            <TextField
              defaultValue={parsedSearch.get("namefilter") || ""}
              label="MDS"
              id="namefilter"
            />
          </FlexElement>
          <FlexElement>
            <NativeSelect
              id="statusfilter"
              label="Status"
              defaultValue={parsedSearch.get("statusfilter") || ""}
            >
              <option value="B">Official & Working</option>
              <option value="O">Official</option>
              <option value="W">Working</option>
            </NativeSelect>
          </FlexElement>
        </FlexContainer>
      </ReportFormHeader>
      {data &&
        (data.length === 0 ? (
          <Typography>No result.</Typography>
        ) : (
          <ReportTable>
            <TableHead>
              <TableRow>
                {mdsVDSSearchColumns.map((col) => (
                  <HeadCell key={col.key}>{col.title}</HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={JSON.stringify(row)}>
                  {mdsVDSSearchColumns.map((col) => (
                    <ContentCell key={col.key}>{row[col.key]}</ContentCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </ReportTable>
        ))}
    </>
  );
}
