import {
  CircularProgress,
  EdsProvider,
  Icon,
  Label,
  NativeSelect,
  Search,
  Typography,
} from "@equinor/eds-core-react";
import { arrow_forward } from "@equinor/eds-icons";
import Area from "components/Area";
import { combineQueryStatuses } from "queries/queryUtil";
import { CodelistSpecification, useCodelist } from "queries/useCodelist";
import { useSheetList } from "features/sheets/queries/useSheetList";
import React, { useCallback, useMemo, useState } from "react";
import { filterItemsByProps } from "utils/filterItemsByProps";
import { FlexContainer, FlexElement } from "../../../components/Components";
import Table, {
  ColumnsProps,
  SmallTableContainer,
} from "../../../components/table/Table";
import { useTableSelect } from "../../../components/table/useTableSelect";
import { filterItemsByName } from "../../../utils/filterItemsByName";
import { PreviewButtons } from "../SheetPane";
import { NameFilterInfo } from "components/NameFilterInfo";

const vdsSubsegmentColumns: ColumnsProps[] = [
  {
    key: "GroupID",
    title: "Group",
    codelist: "vds-subsegment-groups",
    width: 100,
  },
  { key: "SubsegmentID", title: "ID", longTitle: "Subsegment ID", width: 56 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "SubsegmentName", title: "Subsegment Name", width: "100%" },
  { key: "Status", title: "Status" },
];

export function useFromToVDSSubsegmentSelector({
  defaultSelection,
  enabled,
}: {
  defaultSelection?: string[];
  enabled?: boolean;
}): {
  fromSelection: string[];
  toSelection: string[];
  vdsSelectorContent: React.ReactFragment;
} {
  const { data, status, isRefetching, error, refetch } = useSheetList({
    sheetType: "vds-subsegments",
    disabled: !enabled,
    sheetFilter: { statusFilter: ["O", "E"] },
  });

  const {
    data: vdsSubsegmentGroupCodelist,
    status: vdsSubsegmentGroupsStatus,
  } = useCodelist({
    codelist: "vds-subsegment-groups",
  });

  const { data: valveTypeCodelist, status: valveTypeStatus } = useCodelist({
    codelist: "valve-types",
  });
  const { data: ratingClassCodelist, status: ratingClassStatus } = useCodelist({
    codelist: "rating-classes",
  });
  const { data: materialGroupCodelist, status: materialGroupStatus } =
    useCodelist({
      codelist: "material-groups",
    });
  const { data: endConnectionCodelist, status: endConnectionStatus } =
    useCodelist({
      codelist: "end-connections",
    });

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        status,
        vdsSubsegmentGroupsStatus,
        valveTypeStatus,
        ratingClassStatus,
        materialGroupStatus,
        endConnectionStatus
      ),
    [
      status,
      vdsSubsegmentGroupsStatus,
      valveTypeStatus,
      ratingClassStatus,
      materialGroupStatus,
      endConnectionStatus,
    ]
  );

  const [fromNameFilter, setFromNameFilter] = useState("");
  const [toNameFilter, setToNameFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState("");
  const [valveTypeFilter, setValveTypeFilter] = useState("");
  const [ratingClassFilter, setRatingClassFilter] = useState("");
  const [materialGroupFilter, setMaterialGroupFilter] = useState("");
  const [endConnectionFilter, setEndConnectionFilter] = useState("");

  const selectionMode = "single";

  const { selection: fromSelection, selectionDispatch: fromSelectionDispatch } =
    useTableSelect({
      selectionMode,
    });
  const { selection: toSelection, selectionDispatch: toSelectionDispatch } =
    useTableSelect({
      selectionMode,
    });

  const toStatusItems = useMemo(
    () =>
      filterItemsByProps({
        filters: [
          {
            filterState: ["O"],
            prop: "Status",
            isArray: true,
          },
        ],
        items: data?.sheets || [],
      }),
    [data?.sheets]
  );

  const items = useCallback(
    ({ from }: { from?: boolean }) =>
      data
        ? filterItemsByName({
            nameFilter: from ? fromNameFilter : toNameFilter,
            nameProperty: ["SubsegmentName", "SubsegmentID"],
            items: filterItemsByProps({
              filters: [
                {
                  filterState: groupFilter,
                  prop: "GroupID",
                },
                {
                  filterState: valveTypeFilter,
                  prop: "ValveType",
                  commaSeparated: true,
                },
                {
                  filterState: ratingClassFilter,
                  prop: "RatingClass",
                  commaSeparated: true,
                },
                {
                  filterState: materialGroupFilter,
                  prop: "MaterialGroup",
                  commaSeparated: true,
                },
                {
                  filterState: endConnectionFilter,
                  prop: "EndConnection",
                  commaSeparated: true,
                },
              ],
              items: from ? data.sheets : toStatusItems,
            }),
          })
        : [],
    [
      data,
      fromNameFilter,
      toNameFilter,
      groupFilter,
      valveTypeFilter,
      ratingClassFilter,
      materialGroupFilter,
      endConnectionFilter,
      toStatusItems,
    ]
  );

  const codelists = useMemo(
    () => ({
      "vds-subsegment-groups": vdsSubsegmentGroupCodelist
        ? Object.fromEntries(
            vdsSubsegmentGroupCodelist.map((i) => [i.GroupID, i.Description])
          )
        : {},
    }),
    [vdsSubsegmentGroupCodelist]
  );

  const fromItems = useMemo(() => items({ from: true }), [items]);
  const toItems = useMemo(() => items({}), [items]);

  const vdsSelectorContent = enabled ? (
    <>
      <div style={{ paddingTop: 12 }}>
        <EdsProvider density="compact">
          <ControlHeader
            isLoading={status === "loading"}
            isRefetching={isRefetching}
            vdsSubsegmentGroups={vdsSubsegmentGroupCodelist}
            groupFilter={groupFilter}
            setGroupFilter={setGroupFilter}
            valveTypeCodelist={valveTypeCodelist}
            ratingClassCodelist={ratingClassCodelist}
            materialGroupCodelist={materialGroupCodelist}
            endConnectionCodelist={endConnectionCodelist}
            valveTypeFilter={valveTypeFilter}
            setValveTypeFilter={setValveTypeFilter}
            ratingClassFilter={ratingClassFilter}
            setRatingClassFilter={setRatingClassFilter}
            materialGroupFilter={materialGroupFilter}
            setMaterialGroupFilter={setMaterialGroupFilter}
            endConnectionFilter={endConnectionFilter}
            setEndConnectionFilter={setEndConnectionFilter}
          />
        </EdsProvider>
      </div>
      <Area.Container>
        <Area.FillElement style={{ maxWidth: 1000 }}>
          <SmallTableContainer style={{ minHeight: 254 }}>
            <Table
              controlHeader={
                <TableControlHeader
                  fromTo="from"
                  nameFilter={fromNameFilter}
                  setNameFilter={setFromNameFilter}
                  items={fromItems}
                />
              }
              density="compact"
              items={fromItems}
              itemIdProp="itemID"
              columns={vdsSubsegmentColumns}
              contextData={{ sheetType: "vds-subsegments" }}
              status={combinedStatuses}
              selectionMode={selectionMode}
              selection={fromSelection}
              selectionDispatch={fromSelectionDispatch}
              sortable={true}
              fullRowSelect={true}
              error={error}
              refetch={refetch}
              loadingString="Loading VDS Subsegments..."
              selectedFirst={!!defaultSelection}
              RowMenu={PreviewButtons}
              codelists={codelists}
              keepSelectionIfItemsChange={false}
            />
          </SmallTableContainer>
        </Area.FillElement>
        <Area.NormalElement style={{ flexBasis: 70 }}>
          <Area.CenterContent>
            <Icon data={arrow_forward} color="secondary" />
          </Area.CenterContent>
        </Area.NormalElement>
        <Area.FillElement style={{ maxWidth: 1000 }}>
          <SmallTableContainer style={{ minHeight: 254 }}>
            <Table
              controlHeader={
                <TableControlHeader
                  fromTo="to"
                  nameFilter={toNameFilter}
                  setNameFilter={setToNameFilter}
                  items={toItems}
                />
              }
              density="compact"
              items={toItems}
              itemIdProp="itemID"
              columns={vdsSubsegmentColumns}
              contextData={{ sheetType: "vds-subsegments" }}
              status={combinedStatuses}
              selectionMode={selectionMode}
              selection={toSelection}
              selectionDispatch={toSelectionDispatch}
              sortable={true}
              fullRowSelect={true}
              error={error}
              refetch={refetch}
              loadingString="Loading VDS Subsegments..."
              selectedFirst={!!defaultSelection}
              RowMenu={PreviewButtons}
              codelists={codelists}
              keepSelectionIfItemsChange={false}
            />
          </SmallTableContainer>
        </Area.FillElement>
      </Area.Container>
    </>
  ) : (
    <></>
  );

  return {
    vdsSelectorContent,
    fromSelection,
    toSelection,
  };
}

function TableControlHeader({
  fromTo,
  nameFilter,
  setNameFilter,
  items,
}: {
  fromTo: "from" | "to";
  nameFilter: string;
  setNameFilter: React.Dispatch<string>;
  items: any[];
}) {
  return (
    <FlexContainer
      style={{ alignItems: "flex-end", paddingBottom: 12, paddingTop: 6 }}
    >
      <FlexElement>
        <FlexContainer flexStart style={{ alignItems: "flex-end" }}>
          <FlexElement>
            <Label
              htmlFor={`${fromTo}NameFilter`}
              label="Subsegment ID, Name"
            />
            <Search
              id={`${fromTo}NameFilter`}
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              style={{ width: 200 }}
            />
          </FlexElement>
          <FlexElement style={{ marginBottom: 2 }}>
            <NameFilterInfo />
          </FlexElement>
        </FlexContainer>
      </FlexElement>
      <FlexElement>
        <Typography variant="overline">
          {items.length} {items.length > 1 ? "items" : "item"}
        </Typography>
      </FlexElement>
    </FlexContainer>
  );
}

const selectStyle = { width: 221 };

function ControlHeader({
  isLoading,
  isRefetching,
  vdsSubsegmentGroups,
  groupFilter,
  setGroupFilter,
  valveTypeCodelist,
  ratingClassCodelist,
  materialGroupCodelist,
  endConnectionCodelist,
  valveTypeFilter,
  setValveTypeFilter,
  ratingClassFilter,
  setRatingClassFilter,
  materialGroupFilter,
  setMaterialGroupFilter,
  endConnectionFilter,
  setEndConnectionFilter,
}: {
  isLoading: boolean;
  isRefetching: boolean;
  vdsSubsegmentGroups?: CodelistSpecification["vds-subsegment-groups"][];
  groupFilter: string;
  setGroupFilter: React.Dispatch<string>;
  valveTypeCodelist?: CodelistSpecification["valve-types"][];
  ratingClassCodelist?: CodelistSpecification["rating-classes"][];
  materialGroupCodelist?: CodelistSpecification["material-groups"][];
  endConnectionCodelist?: CodelistSpecification["end-connections"][];
  valveTypeFilter: string;
  setValveTypeFilter: React.Dispatch<string>;
  ratingClassFilter: string;
  setRatingClassFilter: React.Dispatch<string>;
  materialGroupFilter: string;
  setMaterialGroupFilter: React.Dispatch<string>;
  endConnectionFilter: string;
  setEndConnectionFilter: React.Dispatch<string>;
}) {
  return (
    <div>
      <FlexContainer
        style={{ justifyContent: "flex-start", gap: 24, paddingBottom: 8 }}
      >
        <FlexElement>
          <NativeSelect
            id="Group"
            label="Group"
            onChange={(e) => setGroupFilter(e.target.value)}
            value={groupFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {vdsSubsegmentGroups &&
              vdsSubsegmentGroups.map((group) => (
                <option value={group.GroupID} key={group.GroupID}>
                  {group.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="ValveType"
            label="Valve Type"
            onChange={(e) => setValveTypeFilter(e.target.value)}
            value={valveTypeFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {valveTypeCodelist &&
              valveTypeCodelist.map((valveType) => (
                <option
                  value={valveType.ValveTypeID}
                  key={valveType.ValveTypeID}
                >
                  ({valveType.VDSCode}) {valveType.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="RatingClass"
            label="Rating Class"
            onChange={(e) => setRatingClassFilter(e.target.value)}
            value={ratingClassFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {ratingClassCodelist &&
              ratingClassCodelist.map((ratingClass) => (
                <option
                  value={ratingClass.RatingClassID}
                  key={ratingClass.RatingClassID}
                >
                  {ratingClass.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="MaterialGroup"
            label="Material Group"
            onChange={(e) => setMaterialGroupFilter(e.target.value)}
            value={materialGroupFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {materialGroupCodelist &&
              materialGroupCodelist.map((materialGroup) => (
                <option
                  value={materialGroup.MaterialGroupID}
                  key={materialGroup.MaterialGroupID}
                >
                  {materialGroup.MaterialGroup}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="EndConnection"
            label="End Connection"
            onChange={(e) => setEndConnectionFilter(e.target.value)}
            value={endConnectionFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {endConnectionCodelist &&
              endConnectionCodelist.map((endConnection) => (
                <option
                  value={endConnection.EndConnectionID}
                  key={endConnection.EndConnectionID}
                >
                  ({endConnection.VDSCode}) {endConnection.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        {!isLoading && (
          <FlexElement style={{ marginLeft: "auto", alignSelf: "flex-end" }}>
            <CircularProgress
              size={16}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                marginRight: "12px",
                verticalAlign: "middle",
              }}
            />
          </FlexElement>
        )}
      </FlexContainer>
    </div>
  );
}
