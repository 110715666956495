import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useDeleteMasterPipeElement() {
  const queryClient = useQueryClient();

  async function deleteMasterPipeElement({
    materialGroupId,
    materialGroupRevision,
    masterPipeElementId,
  }: {
    materialGroupId: string;
    materialGroupRevision: string;
    masterPipeElementId: number;
  }) {
    return axios({
      method: "delete",
      url: getApiUrl(
        "pmg/material-groups/" +
          materialGroupId +
          "/rev/" +
          materialGroupRevision +
          "/master-pipe-elements/" +
          masterPipeElementId
      ),
    });
  }
  return useMutation(deleteMasterPipeElement, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["pmg", "material-groups"]);
      queryClient.invalidateQueries(["codelists", "pmg/master-pipe-elements"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
  });
}

export type UseDeleteMasterPipeElement = ReturnType<
  typeof useDeleteMasterPipeElement
>;
