import { Button, CircularProgress, Typography } from "@equinor/eds-core-react";
import React from "react";
import { QueryStatus } from "react-query";
import { useAppSelector } from "app/hooks";
import { DataTable, RevisionMark, StatusChip } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowProgressContainer,
  ModalWindowTitle,
} from "components/ModalWindow";
import useSheet from "features/sheets/queries/useSheet";
import { useMemo } from "use-memo-one";
import { selectMainPlant } from "../../plant/plantSlice";
import { sheetTypeNames, SheetTypes } from "../config/sheetConfig";
import { MaybeSheetStatus, sheetStatusConfig } from "../config/statusConfig";
import { StatusIcon } from "../ValidationLog";

function useValidationCheck({
  name,
  revision,
  wantToSetStatus,
  needsValidation,
}: {
  name: string;
  revision: string;
  wantToSetStatus: MaybeSheetStatus;
  needsValidation: boolean;
}): { ValidationCheck: React.ReactNode; valid: boolean; status: QueryStatus } {
  const plant = useAppSelector(selectMainPlant);
  const { data, status, error } = useSheet({
    name,
    revision,
    plant,
    sheetType: "pcs",
    postfix:
      revision.length === 1
        ? "main-revision-validation-check"
        : "sub-revision-validation-check",
    disabled: !needsValidation,
    cacheTime: 600,
  });
  const invalidChecks = useMemo(
    () =>
      data ? data.RevValidationCheck.filter((e) => e.Status === "0").length : 0,
    [data]
  );
  const valid = useMemo(
    () =>
      needsValidation ? (data && invalidChecks === 0 ? true : false) : true,
    [data, needsValidation, invalidChecks]
  );

  return {
    ValidationCheck: needsValidation ? (
      status === "loading" ? (
        <ModalWindowProgressContainer>
          <CircularProgress />
        </ModalWindowProgressContainer>
      ) : status === "success" ? (
        <>
          {data ? (
            <>
              <Typography style={{ marginBottom: 8, marginTop: 8 }} as="div">
                Validation checks:
              </Typography>
              <DataTable columns={3} style={{ rowGap: 3 }}>
                {data.RevValidationCheck.map((e) => (
                  <React.Fragment key={e.ValidationCheckID}>
                    <div style={{ paddingRight: 6, paddingLeft: 10 }}>
                      <StatusIcon status={e.Status} />
                    </div>
                    <div>Check {e.ValidationCheckID}:</div>
                    <div>{e.Description}</div>
                  </React.Fragment>
                ))}
              </DataTable>
            </>
          ) : (
            <></>
          )}
          {valid ? (
            <>
              {needsValidation && <p>Successful validation.</p>}
              {wantToSetStatus === "S" && (
                <p>
                  PCS sub revision will be locked for further editing. The
                  content of this sub revision will also be copied to main
                  revision.
                </p>
              )}
              <p>Do you want to continue?</p>
            </>
          ) : (
            <>
              <Typography
                color="danger"
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                {invalidChecks > 1 ? "V" : "A v"}alidation check
                {invalidChecks > 1 && "s"} failed. PCS cannot be approved.
              </Typography>
            </>
          )}
        </>
      ) : status === "error" ? (
        <>
          <Typography color="danger" variant="h4">
            Error
          </Typography>
          {error.message}
        </>
      ) : (
        <>Idle</>
      )
    ) : null,
    valid,
    status,
  };
}

export function SetStatus({
  name,
  revision,
  sheetType,
  wantToSetStatus,
  setWantToSetStatus,
  setSheetStatus,
}: {
  name: string;
  revision: string;
  sheetType: SheetTypes;
  wantToSetStatus: MaybeSheetStatus;
  setWantToSetStatus: React.Dispatch<MaybeSheetStatus>;
  setSheetStatus: Function;
}) {
  const closeModal = () => {
    setWantToSetStatus(undefined);
  };
  const needsValidation =
    sheetType === "pcs" && (wantToSetStatus === "I" || wantToSetStatus === "S");
  const { ValidationCheck, valid, status } = useValidationCheck({
    name,
    revision,
    wantToSetStatus,
    needsValidation,
  });
  const plant = useAppSelector(selectMainPlant);

  return wantToSetStatus ? (
    <ModalWindow
      title={
        status === "loading" ? (
          ""
        ) : (
          <ModalWindowTitle layer="default">
            Setting {sheetTypeNames[sheetType]} {name} rev.{" "}
            <RevisionMark style={{ verticalAlign: 2 }}>{revision}</RevisionMark>{" "}
            to status {sheetStatusConfig[wantToSetStatus]}{" "}
            <StatusChip
              style={{
                display: "inline-block",
                verticalAlign: 2,
                marginLeft: 2,
              }}
            >
              {wantToSetStatus}
            </StatusChip>{" "}
          </ModalWindowTitle>
        )
      }
      closeModal={() => closeModal()}
      isOpen={!!wantToSetStatus}
      hideCloseButton={true}
      layer="default"
    >
      <ModalSideMargin>
        {needsValidation ? ValidationCheck : <>Do you want to continue?</>}
        {status !== "loading" && (
          <ModalWindowButtonContainer>
            {valid ? (
              <>
                <Button
                  onClick={() => {
                    setSheetStatus({
                      plant,
                      sheetType,
                      name: name,
                      revision: revision,
                      status: wantToSetStatus,
                    });
                    closeModal();
                  }}
                >
                  Yes
                </Button>
                <Button onClick={() => closeModal()} variant="outlined">
                  Cancel
                </Button>
              </>
            ) : (
              <Button onClick={() => closeModal()} variant="outlined">
                Close
              </Button>
            )}
          </ModalWindowButtonContainer>
        )}
      </ModalSideMargin>
    </ModalWindow>
  ) : (
    <></>
  );
}
