import React, { useContext } from "react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowProgressContainer,
} from "components/ModalWindow";
import useIssues from "queries/useIssues";
import { RevisionMark } from "components/Components";
import { Button, CircularProgress } from "@equinor/eds-core-react";
import { useAppSelector } from "app/hooks";
import { selectMainPlant } from "features/plant/plantSlice";
import Table, { MediumTableContainer } from "components/table/Table";
import StatusModal from "components/StatusModal";
import useIssueSetPipeElements from "queries/useIssueSetPipeElements";
import { EditIssueContext } from "./EditIssueContext";
import { useTableSelect } from "../../components/table/useTableSelect";
import { IssueSheetRevisionMarkWithPreviewButtons } from "./EditIssue";
import { successMessage } from "../../utils/successMessage";
import useIssueCommand from "queries/useIssueCommand";
import { combineQueryStatuses } from "queries/queryUtil";
import { useMemo } from "use-memo-one";

export function IssueValidation() {
  const {
    revision,
    isEditable,
    issue,
    wantToSetStatus,
    showValidation,
    setShowValidation,
    setWantToSetStatus,
  } = useContext(EditIssueContext);
  const plant = useAppSelector(selectMainPlant);
  const { data, status } = useIssues({
    request: "validation",
    revision,
    plant,
    disabled: !showValidation,
  });
  const { data: subRevisionData, status: subRevisionStatus } = useIssues({
    request: "make-official-pcs-subrevision-list",
    revision,
    plant,
    disabled: !showValidation || wantToSetStatus !== "O",
  });
  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        status,
        ...(wantToSetStatus === "O" ? [subRevisionStatus] : [])
      ),
    [status, subRevisionStatus, wantToSetStatus]
  );
  const multipleSubRevision = subRevisionData && subRevisionData.length > 1;
  const canEdit = isEditable && issue?.Status === "W";
  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: canEdit ? "multi" : false,
  });
  const {
    mutate: issueSetPipeElements,
    status: issueSetPipeElementsStatus,
    error: issueSetPipeElementsError,
    reset: issueSetPipeElementsReset,
    data: issueSetPipeElementsData,
  } = useIssueSetPipeElements();
  const {
    mutate: issueCommand,
    status: issueCommandStatus,
    error: issueCommandError,
    reset: issueCommandReset,
    data: issueCommandData,
  } = useIssueCommand();

  return (
    <>
      <StatusModal
        status={issueSetPipeElementsStatus}
        error={issueSetPipeElementsError}
        onSettledClose={() => issueSetPipeElementsReset()}
        successMessage={successMessage(issueSetPipeElementsData)}
      />
      <StatusModal
        status={issueCommandStatus}
        error={issueCommandError}
        onSettledClose={() => {
          issueCommandReset();
        }}
        successMessage={successMessage(issueCommandData)}
      />
      <ModalWindow
        title={
          combinedStatuses === "success"
            ? wantToSetStatus === "O"
              ? `Make revision ${revision} Official`
              : wantToSetStatus === "M"
              ? `Set revision ${revision} as Master`
              : `Validation of Issue rev. ${revision}`
            : combinedStatuses === "error" || combinedStatuses === "idle"
            ? "Error"
            : ""
        }
        isOpen={showValidation}
        closeModal={() => setShowValidation(false)}
        layer="default"
        style={{ content: { maxWidth: "90vw" } }}
        hideCloseButton={status === "loading"}
      >
        <ModalSideMargin>
          {combinedStatuses === "loading" ? (
            <ModalWindowProgressContainer>
              <CircularProgress />
            </ModalWindowProgressContainer>
          ) : data && data.length > 0 ? (
            <>
              There's an "MDS inconsistency" in this issue revision.
              {issue && issue?.Status === "W" && (
                <>
                  Issue revision cannot be set as "Master Copy".
                  <p>Consider the following:</p>
                  <ol>
                    <li>Run "Update Revisions".</li>
                    <li>
                      Revise PMG to create SPE with reference to official MDS.
                    </li>
                    <li>Change MDS or SPE revisions manually.</li>
                  </ol>
                </>
              )}
              <MediumTableContainer
                style={{ width: "80vw", borderBottom: "none" }}
              >
                <Table
                  selectionMode={selectionMode}
                  selection={selection}
                  selectionDispatch={selectionDispatch}
                  columns={[
                    {
                      key: "ElementID",
                      title: "ID",
                      width: 45,
                    },
                    {
                      key: "Revision",
                      title: "Rev.",
                      Component: RevisionMark,
                      width: 45,
                    },
                    { key: "RevisionDate", title: "Rev. Date", width: 70 },
                    { key: "Status", title: "Status", width: 60 },
                    {
                      key: "DimensionStandard",
                      title: "Dim. Standard",
                      width: "10%",
                    },
                    { key: "ProductForm", title: "Type", width: "10%" },
                    {
                      key: "MaterialGrade",
                      title: "Material Name",
                      width: "10%",
                    },
                    { key: "MDS", title: "MDS", width: 80 },
                    {
                      key: "MDSRevision",
                      title: "MDS Rev.",
                      Component: IssueSheetRevisionMarkWithPreviewButtons,
                      width: 70,
                      type: "with-context" as "with-context",
                      componentProps: {
                        issueSheetType: "mds",
                        previewLocation: "",
                      },
                    },
                    { key: "Area", title: "Area", width: 100 },
                  ]}
                  items={data}
                  itemIdProp="ElementID"
                  status={status}
                  sortable={true}
                  resizable={true}
                  infoline={true}
                  totalCount={data.length}
                  footer={
                    canEdit ? (
                      <Button
                        disabled={selection.length === 0}
                        color="danger"
                        onClick={() => {
                          issueSetPipeElements({
                            method: "delete",
                            pipeelements: data
                              ? selection.map((e) => ({
                                  ElementID: Number(e),
                                  Revision: data.find(
                                    (d) => String(d.ElementID) === e
                                  )!["Revision"],
                                }))
                              : [],
                            plant,
                            revision,
                          });
                        }}
                      >
                        <span>
                          Delete SPE
                          <span
                            style={{
                              visibility:
                                selection.length === 1 ? "hidden" : "visible",
                            }}
                          >
                            s
                          </span>
                        </span>
                      </Button>
                    ) : undefined
                  }
                />
              </MediumTableContainer>
              <ModalWindowButtonContainer>
                <Button
                  variant="outlined"
                  onClick={() => setShowValidation(false)}
                >
                  Close
                </Button>
              </ModalWindowButtonContainer>
            </>
          ) : combinedStatuses === "success" ? (
            <>
              Validation successful.
              {wantToSetStatus === "M" && (
                <>
                  <p>
                    Revision will be set to "Master Copy".
                    <br />
                    The content of the issue revision will be visible on the
                    web.
                  </p>
                  <p>Do you want to continue?</p>
                </>
              )}
              {wantToSetStatus === "O" && (
                <>
                  <p>
                    The issue will be made official.
                    <br />
                    Official issues can't be edited.
                  </p>
                  {subRevisionData && subRevisionData.length > 0 && (
                    <>
                      <p>
                        {multipleSubRevision ? subRevisionData.length : "A"} PCS
                        sub revision
                        {multipleSubRevision ? "s were" : " was"} found in
                        status "Working":
                      </p>
                      <ul>
                        {subRevisionData.map((e) => (
                          <li key={e.PCS + e.Revision}>
                            {e.PCS} rev. {e.Revision}
                          </li>
                        ))}
                      </ul>
                      <p>
                        If you choose to continue,
                        {multipleSubRevision
                          ? " these drafts"
                          : " this draft"}{" "}
                        will not be included in this issue revision, and will be
                        converted to {!multipleSubRevision && "a "} new PCS main
                        revision
                        {multipleSubRevision && "s"}.
                      </p>
                    </>
                  )}
                  <p>Do you want to continue?</p>
                </>
              )}
              <ModalWindowButtonContainer>
                {wantToSetStatus === "M" ? (
                  <>
                    <Button
                      onClick={() => {
                        issueCommand({
                          command: "status",
                          plant,
                          revision,
                          status: "M",
                        });
                        setShowValidation(false);
                        setWantToSetStatus("");
                      }}
                    >
                      Set as Master
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowValidation(false);
                        setWantToSetStatus("");
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : wantToSetStatus === "O" ? (
                  <>
                    <Button
                      onClick={() => {
                        issueCommand({
                          command: "status",
                          plant,
                          revision,
                          status: "O",
                        });
                        setShowValidation(false);
                        setWantToSetStatus("");
                      }}
                    >
                      Make official
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowValidation(false);
                        setWantToSetStatus("");
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowValidation(false);
                    }}
                  >
                    Close
                  </Button>
                )}
              </ModalWindowButtonContainer>
            </>
          ) : combinedStatuses === "error" ? (
            <>Error loading data.</>
          ) : (
            <>Unknown error.</>
          )}
        </ModalSideMargin>
      </ModalWindow>
    </>
  );
}
