import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useQuery } from "react-query";

export type UsageInPCSItem = {
  Plant: string;
  PCS: string;
  Revision: string;
  Status: string;
};

export function useUsageInPCS({ speId }: { speId: number }) {
  return useQuery<UsageInPCSItem[], Error>(
    ["pmg", "structured-pipe-elements", speId, "pcs-where-used"],
    async ({ queryKey }) => {
      const { data } = await axios.get<{
        getPCSList: UsageInPCSItem[];
      }>(getApiUrl(queryKey.join("/")));
      return data.getPCSList;
    },
    { enabled: !!speId }
  );
}
