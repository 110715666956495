import { useMutation, useQueryClient } from "react-query";
import { PlantProps } from "features/plant/types";
import axios from "axios";
import config from "config/config";

export default function useIssueCommand() {
  const queryClient = useQueryClient();

  async function issueCommand({
    command,
    plant,
    revision,
    status,
  }: {
    command: "status" | "datasheets";
    plant: PlantProps;
    revision: string;
    status?: string;
  }) {
    return await axios({
      url:
        config.restApiBaseUrl +
        `plants/${plant.PlantID}/issues/rev/${revision}/${command}`,
      method: "put",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data:
        command === "datasheets"
          ? { BackEndAction: "UpdateRevision" }
          : command === "status"
          ? { Status: status }
          : {},
    });
  }

  return useMutation(issueCommand, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["issues", variables.plant.PlantID]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
