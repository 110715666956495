import { Button, Icon, Tooltip } from "@equinor/eds-core-react";
import { FlexContainer } from "components/Components";
import { FieldContext } from "components/form/FieldContext";
import {
  VDSItemSections,
  VDSSubsegment,
  VDSSubsegmentHeader,
} from "features/sheets/types";
import { CodelistSpecification } from "queries/useCodelist";
import { useContext, useState } from "react";
import { VDSTextBlockSectionH1 } from "../vds-textblocks/VDSTextBlockSectionH1";
import { VDSTextBlockSectionH2 } from "../vds-textblocks/VDSTextBlockSectionH2";
import { VDSTextBlockSectionH3 } from "../vds-textblocks/VDSTextBlockSectionH3";
import {
  VDSTextBlockSectionH4,
  ratingMatrixProps,
} from "../vds-textblocks/VDSTextBlockSectionH4";
import styled from "styled-components";
import { CenterInput } from "components/form/EditComponents";
import { Field } from "components/form/Field";
import { FieldValues, UseFormSetValue, useFormContext } from "react-hook-form";
import { delete_forever } from "@equinor/eds-icons";
import useConfirm from "components/confirm/useConfirm";

function HeadingRevisionInput({
  heading,
  prop,
  marginTop,
}: {
  heading: VDSSubsegmentHeader;
  prop: keyof VDSSubsegmentHeader;
  marginTop?: number;
}) {
  const { formState } = useFormContext();
  const { errors } = formState;
  const { clear } = useContext(FieldContext);
  const [value, setValue] = useState(clear ? "" : heading[prop]);
  // @ts-ignore
  const error = errors.getVDSSubsegmentHeader?.[0]?.[prop];
  return (
    <Tooltip
      title={
        !!error ? "Revision is max. two numbers or uppercase letters." : ""
      }
    >
      <div>
        <CenterInput
          style={{
            width: 40,
            marginRight: 32,
            marginBottom: 0,
            marginTop: marginTop ?? 16,
          }}
          title=""
          maxLength={2}
          value={value}
          onChange={(e) => {
            setValue(e.target.value.toUpperCase());
          }}
          validate={{ twoLetters: (v: string) => /^[A-Z0-9]{0,2}$/.test(v) }}
          prop={"getVDSSubsegmentHeader.0." + prop}
          type="text"
        />
      </div>
    </Tooltip>
  );
}

const HeadingButtonContainer = styled.div`
  margin-left: auto;
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  button:first-child {
    margin-left: 16px;
  }
`;

type HeadingProps = {
  data: VDSSubsegment;
  config: CodelistSpecification["vds-subsegment-groups-config"][];
  setSelectorOpen: React.Dispatch<boolean>;
  setItemSection: React.Dispatch<VDSItemSections>;
  setItemId: React.Dispatch<number>;
};

const clearFields = ({
  preProp,
  props,
  setValue,
}: {
  preProp: string;
  props: string[];
  setValue: UseFormSetValue<FieldValues>;
}) => {
  props.forEach((prop) => {
    setValue(preProp + prop, "", { shouldDirty: true });
  });
};

export function HeadingSection1({
  data,
  config,
  setSelectorOpen,
  setItemSection,
  setItemId,
}: HeadingProps) {
  const myConfig = config.filter((e) => e.ItemID === 1);
  const heading = data.getVDSSubsegmentHeader[0];
  const myValue = heading.ValveTypeTextBlockID;
  const preProp = "getVDSSubsegmentHeader.0.";
  const { setValue, watch } = useFormContext();
  const currentTextBlockId = watch(preProp + "ValveTypeTextBlockID");
  const currentTextBlockRevision = watch(
    preProp + "ValveTypeTextBlockRevision"
  );
  const { isConfirmed } = useConfirm();

  return myValue || myConfig.length > 0 ? (
    <FlexContainer flexStart>
      <div>
        <HeadingRevisionInput heading={heading} prop="ValveTypeRevision" />
      </div>
      <div style={{ flex: 1 }}>
        <FieldContext.Provider value={{ disabled: true }}>
          <VDSTextBlockSectionH1 subsegmentData={data} />
          <Field
            type="hidden"
            prop={preProp + "ValveTypeTextBlockID"}
            value={heading.ValveTypeTextBlockID}
          />
          <Field
            type="hidden"
            prop={preProp + "ValveTypeTextBlockRevision"}
            value={heading.ValveTypeTextBlockRevision}
          />
        </FieldContext.Provider>
      </div>
      <HeadingButtonContainer>
        <Button
          variant="outlined"
          disabled={myConfig.length === 0}
          title={
            "Current: " + currentTextBlockId + "-" + currentTextBlockRevision
          }
          onClick={() => {
            setItemSection("H");
            setItemId(1);
            setSelectorOpen(true);
          }}
        >
          {currentTextBlockId ? "Change" : "Select"} Valve Type
        </Button>
        <Button
          variant="ghost_icon"
          disabled={!currentTextBlockId}
          onClick={async () => {
            (await isConfirmed(
              "Are you sure you want to remove the Valve Type?",
              { buttonColor: "danger" }
            )) &&
              clearFields({
                preProp,
                setValue,
                props: [
                  "ValveTypeTextBlockID",
                  "ValveTypeTextBlockRevision",
                  "ValveType",
                ],
              });
          }}
        >
          <Icon data={delete_forever} />
        </Button>
      </HeadingButtonContainer>
    </FlexContainer>
  ) : null;
}

export function HeadingSection2({
  data,
  config,
  setSelectorOpen,
  setItemSection,
  setItemId,
}: HeadingProps) {
  const myConfig = config.filter((e) => e.ItemID === 2);
  const heading = data.getVDSSubsegmentHeader[0];
  const myValue = heading.DesignCodeTextBlockID;
  const preProp = "getVDSSubsegmentHeader.0.";
  const { setValue, watch } = useFormContext();
  const currentTextBlockId = watch(preProp + "DesignCodeTextBlockID");
  const currentTextBlockRevision = watch(
    preProp + "DesignCodeTextBlockRevision"
  );
  const { isConfirmed } = useConfirm();

  return myValue || myConfig.length > 0 ? (
    <FlexContainer flexStart>
      <div>
        <HeadingRevisionInput heading={heading} prop="DesignCodeRevision" />
      </div>
      <div style={{ flex: 1 }}>
        <FieldContext.Provider value={{ disabled: true }}>
          <VDSTextBlockSectionH2 subsegmentData={data} />
          <Field
            type="hidden"
            prop={preProp + "DesignCodeTextBlockID"}
            value={heading.DesignCodeTextBlockID}
          />
          <Field
            type="hidden"
            prop={preProp + "DesignCodeTextBlockRevision"}
            value={heading.DesignCodeTextBlockRevision}
          />
        </FieldContext.Provider>
      </div>
      <HeadingButtonContainer>
        <Button
          variant="outlined"
          disabled={myConfig.length === 0}
          title={
            "Current: " + currentTextBlockId + "-" + currentTextBlockRevision
          }
          onClick={() => {
            setItemSection("H");
            setItemId(2);
            setSelectorOpen(true);
          }}
        >
          {currentTextBlockId ? "Change" : "Select"} Code
        </Button>
        <Button
          variant="ghost_icon"
          disabled={!currentTextBlockId}
          onClick={async () => {
            (await isConfirmed(
              "Are you sure you want to remove the Design Code?",
              { buttonColor: "danger" }
            )) &&
              clearFields({
                preProp,
                setValue,
                props: [
                  "DesignCodeTextBlockID",
                  "DesignCodeTextBlockRevision",
                  "DesignCode",
                ],
              });
          }}
        >
          <Icon data={delete_forever} />
        </Button>
      </HeadingButtonContainer>
    </FlexContainer>
  ) : null;
}

export function HeadingSection3({
  data,
  config,
  setSelectorOpen,
  setItemSection,
  setItemId,
}: HeadingProps) {
  const myConfig = config.filter((e) => e.ItemID === 3);
  const heading = data.getVDSSubsegmentHeader[0];
  const myValue = heading.SizeRangeTextBlockID;
  const preProp = "getVDSSubsegmentHeader.0.";
  const { setValue, watch } = useFormContext();
  const currentTextBlockId = watch(preProp + "SizeRangeTextBlockID");
  const currentTextBlockRevision = watch(
    preProp + "SizeRangeTextBlockRevision"
  );
  const { isConfirmed } = useConfirm();

  return myValue || myConfig.length > 0 ? (
    <FlexContainer flexStart>
      <div>
        <HeadingRevisionInput heading={heading} prop="SizeRangeRevision" />
      </div>
      <FlexContainer flexStart style={{ columnGap: 48, flex: 1 }}>
        <FieldContext.Provider value={{ disabled: true }}>
          <VDSTextBlockSectionH3 subsegmentData={data} />
          <Field
            type="hidden"
            prop={preProp + "SizeRangeTextBlockID"}
            value={heading.SizeRangeTextBlockID}
          />
          <Field
            type="hidden"
            prop={preProp + "SizeRangeTextBlockRevision"}
            value={heading.SizeRangeTextBlockRevision}
          />
        </FieldContext.Provider>
      </FlexContainer>
      <HeadingButtonContainer>
        <Button
          variant="outlined"
          disabled={myConfig.length === 0}
          title={
            "Current: " + currentTextBlockId + "-" + currentTextBlockRevision
          }
          onClick={() => {
            setItemSection("H");
            setItemId(3);
            setSelectorOpen(true);
          }}
        >
          {currentTextBlockId ? "Change" : "Select"} Size Range
        </Button>
        <Button
          variant="ghost_icon"
          disabled={!currentTextBlockId}
          onClick={async () => {
            (await isConfirmed(
              "Are you sure you want to remove the Size Range?",
              { buttonColor: "danger" }
            )) &&
              clearFields({
                preProp,
                setValue,
                props: [
                  "SizeRangeTextBlockID",
                  "SizeRangeTextBlockRevision",
                  "SizeRangeFrom",
                  "SizeRangeTo",
                  "Unit",
                  "SizeRangeComment",
                ],
              });
          }}
        >
          <Icon data={delete_forever} />
        </Button>
      </HeadingButtonContainer>
    </FlexContainer>
  ) : null;
}
export function HeadingSection4({
  data,
  config,
  setSelectorOpen,
  setItemSection,
  setItemId,
}: HeadingProps) {
  const myConfig = config.filter((e) => e.ItemID === 4);
  const heading = data.getVDSSubsegmentHeader[0];
  const myValue = heading.RatingClassTextBlockID;
  const preProp = "getVDSSubsegmentHeader.0.";
  const { setValue, watch } = useFormContext();
  const currentTextBlockId = watch(preProp + "RatingClassTextBlockID");
  const currentTextBlockRevision = watch(
    preProp + "RatingClassTextBlockRevision"
  );
  const { isConfirmed } = useConfirm();

  return myValue || myConfig.length > 0 ? (
    <FlexContainer flexStart>
      <div>
        <HeadingRevisionInput
          heading={heading}
          prop="RatingClassRevision"
          marginTop={12}
        />
        <div style={{ marginTop: 60 }}>
          <HeadingRevisionInput
            heading={heading}
            prop="MaxDesignPressureRevision"
          />
          <HeadingRevisionInput
            heading={heading}
            prop="AtTemperatureRevision"
            marginTop={8}
          />
        </div>
      </div>
      <div style={{ maxWidth: 800 }}>
        <FieldContext.Provider value={{ disabled: true }}>
          <VDSTextBlockSectionH4 subsegmentData={data} />
          <Field
            type="hidden"
            prop={preProp + "RatingClassTextBlockID"}
            value={heading.RatingClassTextBlockID}
          />
          <Field
            type="hidden"
            prop={preProp + "RatingClassTextBlockRevision"}
            value={heading.RatingClassTextBlockRevision}
          />
        </FieldContext.Provider>
      </div>
      <HeadingButtonContainer>
        <Button
          variant="outlined"
          disabled={myConfig.length === 0}
          title={
            "Current: " + currentTextBlockId + "-" + currentTextBlockRevision
          }
          onClick={() => {
            setItemSection("H");
            setItemId(4);
            setSelectorOpen(true);
          }}
        >
          {currentTextBlockId ? "Change" : "Select"} Rating
        </Button>
        <Button
          variant="ghost_icon"
          disabled={!currentTextBlockId}
          onClick={async () => {
            (await isConfirmed("Are you sure you want to remove the Rating?", {
              buttonColor: "danger",
            })) &&
              clearFields({
                preProp,
                setValue,
                props: [
                  "RatingClassTextBlockID",
                  "RatingClassTextBlockRevision",
                  "RatingClass",
                  ...ratingMatrixProps.flat(),
                ],
              });
          }}
        >
          <Icon data={delete_forever} />
        </Button>
      </HeadingButtonContainer>
    </FlexContainer>
  ) : null;
}
