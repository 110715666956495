import {
  CircularProgress,
  Label,
  Search,
  Typography,
} from "@equinor/eds-core-react";
import { useSheetList } from "features/sheets/queries/useSheetList";
import { useMemo, useState } from "react";
import { PaneLocation } from "uiSlice";
import {
  FlexContainer,
  FlexElement,
  InlineBlock,
  StatusChip,
} from "../../../components/Components";
import Table, { SmallTableContainer } from "../../../components/table/Table";
import { useTableSelect } from "../../../components/table/useTableSelect";
import { filterItemsByName } from "../../../utils/filterItemsByName";
import { PlantProps } from "../../plant/types";
import { PreviewButtons } from "../SheetPane";

const VDSColumns = [
  { key: "VDS", title: "VDS", width: 86 },
  { key: "Description", title: "Description", width: "100%" },
];

const extendedVDSColumns = [
  { key: "VDS", title: "VDS", width: 86 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "Status", title: "Status", Component: StatusChip },
  { key: "SizeRange", title: "Size Range" },
  { key: "Description", title: "Description", width: "100%" },
];

export default function useVDSSelector({
  disabled,
  plant,
  defaultSelection,
  valveGroup,
  extendedDisplay,
  previewLocation,
}: {
  disabled?: boolean;
  plant?: PlantProps;
  defaultSelection?: string[];
  valveGroup?: number;
  extendedDisplay?: boolean;
  previewLocation?: PaneLocation;
}): {
  selection: string[];
  selectionDispatch: Function;
  vdsSelectorContent: React.ReactFragment;
  selectedLines: any[];
  length: number;
  isLoading: boolean;
} {
  const { data, status, isLoading, isRefetching, error, refetch } =
    useSheetList({
      sheetType: "vds",
      sheetFilter: {
        statusFilter: ["O"],
        valveCategoryId: valveGroup,
        plantId: plant?.PlantID,
      },
      disabled,
    });
  const [nameFilter, setNameFilter] = useState("");

  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: "single",
    selection: defaultSelection,
  });

  const items = useMemo(
    () =>
      (!disabled &&
        data &&
        filterItemsByName({
          nameFilter,
          nameProperty: "VDS",
          items: data.sheets,
        })) ||
      [],
    [nameFilter, data, disabled]
  );

  const vdsSelectorContent = !disabled ? (
    <SmallTableContainer>
      <Table
        density="compact"
        items={items}
        itemIdProp="VDS"
        controlHeader={
          <ControlHeader
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}
            count={items.length}
            isLoading={status === "loading"}
            isRefetching={isRefetching}
          />
        }
        columns={extendedDisplay ? extendedVDSColumns : VDSColumns}
        contextData={{ previewLocation, sheetType: "vds" }}
        status={status}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={true}
        error={error}
        refetch={refetch}
        loadingString="Loading VDSs..."
        selectedFirst={!!defaultSelection}
        RowMenu={PreviewButtons}
      />
    </SmallTableContainer>
  ) : (
    <></>
  );

  return {
    vdsSelectorContent,
    selection,
    selectionDispatch,
    selectedLines: selection.map((s) => items.find((i) => i.VDS === s)),
    length: items.length,
    isLoading,
  };
}

function ControlHeader({
  nameFilter,
  setNameFilter,
  count,
  isLoading,
  isRefetching,
}: {
  nameFilter: string;
  setNameFilter: Function;
  count: number;
  isLoading: boolean;
  isRefetching: boolean;
}) {
  return (
    <div style={{ marginBottom: 12 }}>
      <FlexContainer
        style={{ justifyContent: "flex-start", alignItems: "flex-end" }}
      >
        <FlexElement style={{ paddingRight: "1.5em" }}>
          <Label htmlFor="nameFilter" label="VDS filter" />
          <Search
            id="nameFilter"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </FlexElement>
        {!isLoading && (
          <FlexElement style={{ marginLeft: "auto", alignSelf: "flex-end" }}>
            <CircularProgress
              size={16}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                marginRight: "12px",
                verticalAlign: "middle",
              }}
            />
            <InlineBlock>
              <Typography variant="overline">
                {count} {count === 1 ? "item" : "items"}
              </Typography>
            </InlineBlock>
          </FlexElement>
        )}
      </FlexContainer>
    </div>
  );
}
