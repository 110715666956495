import {
  ModalWindow,
  ModalWindowButtonContainer,
} from "components/ModalWindow";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { UsageInPCSItem, useUsageInPCS } from "../queries/useUsageInPCS";
import { ColumnsProps, SmallTableContainer } from "components/table/Table";
import { useTable } from "components/table/useTable";
import { Button } from "@equinor/eds-core-react";

const columns: ColumnsProps<UsageInPCSItem>[] = [
  {
    key: "Plant",
    title: "Plant",
    width: 220,
  },
  { key: "PCS", title: "PCS", width: 120 },
  { key: "Revision", title: "Revision" },
  { key: "Status", title: "Status" },
];

export function UsageInPCS({ speId }: { speId: number }) {
  const history = useHistory();
  const closeModal = useCallback(() => {
    history.push({ search: "" });
  }, [history]);

  const query = useUsageInPCS({ speId });
  const { status, error } = query;

  const title = `Structured Pipe Element ID ${speId} Usage in PCS`;

  const { content } = useTable({
    query,
    columns: columns as ColumnsProps<unknown>[],
    title: "",
    density: "compact",
    hideDownloadFiltered: true,
    filename: title,
  });

  return (
    <ModalWindow
      title={title}
      isOpen={!!speId}
      closeModal={closeModal}
      status={status}
      error={error}
    >
      <SmallTableContainer style={{ height: "70vh", width: 800 }}>
        {content}
      </SmallTableContainer>
      <ModalWindowButtonContainer>
        <Button variant="outlined" onClick={closeModal}>
          Close
        </Button>
      </ModalWindowButtonContainer>
    </ModalWindow>
  );
}
