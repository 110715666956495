import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  changeMainPlant,
  selectMainPlant,
  changeSourcePlant,
  selectSourcePlant,
  unsetPlantState,
} from "./plantSlice";
import {
  UserPlantsWithOperators,
  useUserPlantsWithOperators,
} from "../../queries/useUserPlantsWithOperators";
import { OperatorProps, PlantProps } from "./types";
import { NativeSelect, Typography } from "@equinor/eds-core-react";
import {
  useLocation,
  useHistory,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { setPaneMode } from "uiSlice";
import config from "config/config";
import { sheetTypes } from "../sheets/config/sheetConfig";
import { useSettings } from "features/settings/useSettings";
import { useUpdateSetting } from "features/settings/useUpdateSetting";

interface PlantSelectorProps {
  kind: "main" | "source";
  label?: string;
}

type PlantLoaderState = "initial" | "unknown-url" | "set";

export function getPlantURLID(plant: PlantProps) {
  return config.commonLibPlantCodesInUrl && plant.CommonLibPlantCode
    ? plant.CommonLibPlantCode
    : plant.PlantID;
}

function isPositiveInteger(n: any) {
  return n >>> 0 === parseFloat(n);
}

function findPlantByID(plants: PlantProps[], id: number | string) {
  let foundPlant = undefined;
  if (typeof id === "number") {
    foundPlant = plants.find((plantObj: PlantProps) => plantObj.PlantID === id);
  } else if (typeof id === "string" && isPositiveInteger(id)) {
    foundPlant = plants.find(
      (plantObj: PlantProps) => plantObj.PlantID === parseInt(id)
    );
  }
  if (!foundPlant) {
    foundPlant = plants.find(
      (plantObj: PlantProps) => plantObj.CommonLibPlantCode === id
    );
  }
  return foundPlant || unsetPlantState;
}

export const groupPlants = (
  plants: PlantProps[] | undefined,
  operators: OperatorProps[] | undefined
) =>
  operators &&
  plants &&
  operators
    .map((operator) => {
      return {
        operatorName: operator.OperatorName,
        plants: plants.filter(
          (plant) => plant.OperatorID === operator.OperatorID
        ),
      };
    })
    .filter((plantGroup) => plantGroup.plants.length > 0);

export type GroupedPlants = ReturnType<typeof groupPlants>;

export function GroupedPlantsOptions({
  plantsGrouped,
}: {
  plantsGrouped: GroupedPlants | undefined;
}) {
  return (
    <>
      {plantsGrouped?.map((plantGroup) => {
        return (
          <optgroup
            key={plantGroup.operatorName}
            label={plantGroup.operatorName}
          >
            {plantGroup.plants.map((plant: PlantProps) => {
              return (
                <option key={plant.PlantID} value={plant.PlantID}>
                  {plant.LongDescription}
                </option>
              );
            })}
          </optgroup>
        );
      })}
    </>
  );
}

function PlantSelectorLoaded(
  props: RouteComponentProps &
    PlantSelectorProps & { data: UserPlantsWithOperators }
) {
  const { data, location, kind } = props;
  const plantData = useMemo(() => data.plants, [data.plants]);
  const settingsKey = kind + "Plant";
  const { [settingsKey]: storedPlant } = useSettings();
  const { updateSetting } = useUpdateSetting();
  const plantsGrouped = useMemo(
    () => groupPlants(plantData, data.operators),
    [data.operators, plantData]
  );

  const dispatch = useAppDispatch();
  let history = useHistory();
  let doChangePlant = (plant: number) => {};
  const plantIDFromUrl = useLocation().pathname.split("/")[2];
  const mainPlant = useAppSelector(selectMainPlant);
  const sourcePlant = useAppSelector(selectSourcePlant);
  let plant: PlantProps | undefined;
  const [state, setState] = useState("initial" as PlantLoaderState);

  useLayoutEffect(() => {
    if (storedPlant) {
      if (kind === "main") {
        dispatch(changeMainPlant(findPlantByID(plantData, storedPlant)));
      }
      if (kind === "source") {
        dispatch(changeSourcePlant(findPlantByID(plantData, storedPlant)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      plantIDFromUrl &&
      plantData &&
      parseInt(plantIDFromUrl) !== mainPlant.PlantID &&
      plantIDFromUrl !== mainPlant.CommonLibPlantCode &&
      state === "initial" &&
      props.kind === "main"
    ) {
      let newPlantFromURL = findPlantByID(plantData, plantIDFromUrl);
      if (newPlantFromURL.PlantID !== 0) {
        dispatch(changeMainPlant(newPlantFromURL));
        updateSetting({
          key: "mainPlant",
          value: String(newPlantFromURL.PlantID),
        });
      } else {
        setState("unknown-url");
      }
    }
    if (state !== "set") {
      setState("set");
    }
  }, [
    plantIDFromUrl,
    plantData,
    mainPlant,
    dispatch,
    state,
    props,
    updateSetting,
  ]);

  switch (props.kind) {
    case "main":
      plant = mainPlant;

      doChangePlant = (plant: number) => {
        let changeToPlant = plantData.find(
          (plantObj: PlantProps) => plantObj.PlantID === plant
        );
        if (changeToPlant) {
          if (changeToPlant.PlantID === mainPlant.PlantID) {
            return;
          }
          let url = ["/plant", getPlantURLID(changeToPlant)];
          const currentSheet = location.pathname.split(/[/]+/)[3];
          currentSheet &&
            (sheetTypes.includes(currentSheet) || currentSheet === "issues") &&
            url.push(currentSheet);
          const currentCommand = location.pathname.split(/[/]+/)[4];
          currentSheet &&
            (sheetTypes.includes(currentSheet) || currentSheet === "issues") &&
            url.push(currentCommand);
          history.push(url.join("/"));
          dispatch(changeMainPlant(changeToPlant));
          dispatch(setPaneMode({ location: "main", mode: false }));
          updateSetting({
            key: "mainPlant",
            value: String(changeToPlant.PlantID),
          });
        }
      };
      break;

    case "source":
      plant = sourcePlant;
      doChangePlant = (plant: number) => {
        dispatch(
          changeSourcePlant(
            plantData.find((plantObj: PlantProps) => plantObj.PlantID === plant)
          )
        );
        dispatch(setPaneMode({ location: "copyModal", mode: false }));
        updateSetting({ key: "sourcePlant", value: String(plant) });
      };
      break;
  }

  let noPlant = null;
  if (!plant || plant.PlantID === 0) {
    noPlant = <option key="initial">Select a Plant</option>;
  }
  if (state === "unknown-url") {
    noPlant = <option key="problem">Unknown Plant in URL</option>;
  }

  const operator = (
    <div style={{ whiteSpace: "nowrap" }}>
      <Typography variant="h6">{plant && plant.OperatorName}</Typography>
    </div>
  );

  return (
    <>
      <div style={{ display: "flex", gap: 12, alignItems: "baseline" }}>
        {plantData.length > 0 && (
          <>
            {props.kind === "main" && operator}
            <NativeSelect
              id="PlantSelector"
              label={props.label ? props.label : ""}
              value={plant?.PlantID}
              onChange={(e) => doChangePlant(parseInt(e.target.value))}
            >
              {noPlant}
              <GroupedPlantsOptions plantsGrouped={plantsGrouped} />
            </NativeSelect>
            {props.kind === "source" && operator}
          </>
        )}
      </div>
    </>
  );
}

function PlantSelector(props: RouteComponentProps & PlantSelectorProps) {
  const { isLoading, isError, data, error } = useUserPlantsWithOperators();
  if (isError) {
    throw new Error(
      error && error instanceof Error ? error.message : "Error: Plant API error"
    );
  } else if (isLoading) {
    return <div>Loading Plants...</div>;
  } else if (data) {
    return <PlantSelectorLoaded {...props} data={data} />;
  } else {
    return <></>;
  }
}

export default withRouter(PlantSelector);
