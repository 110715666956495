import {
  Button,
  EdsProvider,
  Icon,
  Menu,
  NativeSelect,
} from "@equinor/eds-core-react";
import { IconType } from "@equinor/eds-core-react/dist/types/components/Icon/Icon.types";
import {
  arrow_drop_down,
  arrow_drop_up,
  delete_forever,
  delete_to_trash,
  edit,
  more_horizontal,
  playlist_add,
} from "@equinor/eds-icons";
import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  FieldValues,
  UseFormUnregister,
  useFormContext,
} from "react-hook-form";
import { QueryStatus } from "react-query";
import { useAppSelector } from "app/hooks";
import { ContextMenu } from "components/ContextMenu";
import { selectMainPlant } from "features/plant/plantSlice";
import { TableIcon } from "components/table/Columns";
import Table, {
  ColumnsProps,
  ItemsClassNames,
  TableCellContentNoClamp,
} from "components/table/Table";
import { useTableSelect } from "components/table/useTableSelect";
import useConfirm, { IsConfirmed } from "components/confirm/useConfirm";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSheet from "features/sheets/queries/useSheet";
import { arrayMove } from "utils/util";
import { useMemoOne as useMemo } from "use-memo-one";
import {
  AbsoluteFlexContainer,
  ControlButtonContainer,
  FlexContentElement,
} from "../../../components/Components";
import { elementProperties } from "../config/elementConfig";
import { NoteButton } from "../../../components/form/EditComponents";
import { EditModalStatusDisplay } from "../../../components/ModalComponents";
import { PipeElementsAPIData } from "../modals/pcs/PCSPipeElements";
import { ValveElementsAPIData } from "../modals/pcs/PCSValveElements";
import { NoteSections } from "./useStandardNotes";

type ElementTypes = "valve-elements" | "pipe-elements";
type GroupTypes = "pipe-element-groups" | "valve-element-groups";
export type IdProp = string;

export type ElementListGrouped = {
  [index: number]: IdProp[];
};

export type ElementProperties = {
  getProp: string;
  submitProp: string;
  groupName: GroupTypes;
  elementGroupIdProp: string;
  groupIdProp: string;
  groupNameProp: string;
};

export type GroupsData = { id: number; name: string }[];

async function removeAndUnregisterRow({
  item,
  rowsDispatch,
  unregister,
  isConfirmed,
  conf,
}: {
  item: any;
  rowsDispatch: Function;
  unregister: UseFormUnregister<FieldValues>;
  isConfirmed: IsConfirmed;
  conf: ElementProperties;
}) {
  const confirmed = await isConfirmed(
    "Are you sure you want to hard delete (remove) this line?",
    { buttonColor: "danger" }
  );
  if (confirmed) {
    unregister(`${conf.submitProp}.${item.id}`);
    rowsDispatch({ type: "remove", payload: { toRemove: [item.id] } });
  }
}

async function removeAndUnregisterRows({
  isConfirmed,
  rowsDispatch,
  unregister,
  selection,
  conf,
}: {
  isConfirmed: IsConfirmed;
  rowsDispatch: Function;
  unregister: UseFormUnregister<FieldValues>;
  selection: string[];
  conf: ElementProperties;
}) {
  const confirmed = await isConfirmed(
    "Are you sure you want to remove the selected lines (hard delete)?",
    { buttonColor: "danger" }
  );
  if (confirmed) {
    rowsDispatch({
      type: "remove",
      payload: { toRemove: selection },
    });
    selection.forEach((idToUnregister) => {
      unregister(`${conf.submitProp}.${idToUnregister}`);
    });
  }
}

async function softDeleteRow({
  item,
  isConfirmed,
  deletedLines,
  setDeletedLines,
  setValue,
  conf,
}: {
  item: any;
  isConfirmed: IsConfirmed;
  deletedLines: string[];
  setDeletedLines: Function;
  setValue: Function;
  conf: ElementProperties;
}) {
  const confirmed = await isConfirmed(
    "Are you sure you want to mark this line logically deleted (soft delete)?"
  );
  if (confirmed) {
    setDeletedLines(deletedLines.concat(item.id));
    setValue(`${conf.submitProp}.${item.id}.NewDeletedLine`, "D", {
      shouldDirty: true,
    });
    setValue(`${conf.submitProp}.${item.id}.NoteID`, "", {
      shouldDirty: true,
    });
  }
}

export function IconWithInput({
  item,
  title,
  icon,
  prop,
  submitProp,
  trueValue,
  falseValue,
  conf,
}: {
  item: any;
  title: string;
  icon: IconType;
  prop: string;
  submitProp: string;
  trueValue: string;
  falseValue: string;
  conf: ElementProperties;
}) {
  const { register, getValues } = useFormContext();
  const itemProp = `${conf.submitProp}.${item.id}.${
    submitProp ? submitProp : prop
  }`;
  const value = getValues(itemProp);
  return (
    <>
      {value === trueValue ? (
        <TableIcon data={icon} title={title} />
      ) : value === falseValue ? (
        ""
      ) : (
        value
      )}
      <input type="hidden" value={item[prop]} {...register(itemProp)} />
    </>
  );
}

export function DisplayAsFormElement({
  item,
  prop,
  submitProp,
  conf,
  hideIf,
}: {
  item: any;
  prop: string;
  submitProp?: string;
  conf: ElementProperties;
  hideIf?: string[];
}) {
  const { register, getValues } = useFormContext();
  const fieldId = `${conf.submitProp}.${item.id}.${
    submitProp ? submitProp : prop
  }`;
  const value = getValues(fieldId);
  return (
    <>
      <input
        type="hidden"
        defaultValue={!item[prop] ? "" : item[prop]}
        {...register(fieldId)}
      />
      {!hideIf?.includes(value) && (
        <TableCellContentNoClamp>{value}</TableCellContentNoClamp>
      )}
    </>
  );
}

function EditCommandButton({
  item,
  setEditModal,
}: {
  item: ElementItemData;
  setEditModal: Function;
}) {
  return (
    <Button
      variant="ghost_icon"
      onClick={() => {
        setEditModal(item.id);
      }}
      title="Edit"
      style={{ visibility: item.NewDeletedLine === "D" ? "hidden" : "visible" }}
    >
      <Icon data={edit} />
    </Button>
  );
}

function moveLine({
  item,
  direction,
  rowsDispatch,
}: {
  item: any;
  direction: "up" | "down";
  rowsDispatch: Function;
}) {
  rowsDispatch({ type: direction, payload: { id: item.id } });
}

function MoveUpCommandButton({
  item,
  rows,
  rowsDispatch,
}: {
  item: any;
  rows: ElementListGrouped;
  rowsDispatch: Function;
}) {
  const groupId = getGroupId(rows, item.id);
  return (
    <Button
      style={{
        visibility: rows[groupId].indexOf(item.id) > 0 ? "visible" : "hidden",
      }}
      variant="ghost_icon"
      onClick={() => {
        moveLine({ item, rowsDispatch, direction: "up" });
      }}
      title="Move line up"
    >
      <Icon data={arrow_drop_up} />
    </Button>
  );
}

function MoveDownCommandButton({
  item,
  rows,
  rowsDispatch,
}: {
  item: any;
  rows: ElementListGrouped;
  rowsDispatch: Function;
}) {
  const groupId = getGroupId(rows, item.id);
  return (
    <Button
      style={{
        visibility:
          rows[groupId].indexOf(item.id) >= rows[groupId].length - 1
            ? "hidden"
            : "visible",
      }}
      variant="ghost_icon"
      onClick={() => {
        moveLine({ item, rowsDispatch, direction: "down" });
      }}
      title="Move line down"
    >
      <Icon data={arrow_drop_down} />
    </Button>
  );
}

function AddNewButton({
  setEditModal,
  setInsertToGroup,
  setInsertToPosition,
}: {
  setEditModal: React.Dispatch<string>;
  setInsertToGroup: React.Dispatch<number>;
  setInsertToPosition: React.Dispatch<number>;
}) {
  return (
    <EdsProvider density="comfortable">
      <Button
        variant="outlined"
        onClick={() => {
          setInsertToGroup(0);
          setInsertToPosition(0);
          setEditModal("_new");
        }}
      >
        Add new line
      </Button>
    </EdsProvider>
  );
}

function ControlHeader({
  selection,
  rows,
  rowsDispatch,
  setEditModal,
  setInsertToGroup,
  setInsertToPosition,
  conf,
  AdditionalControlHeader,
}: {
  selection: string[];
  rows: ElementListGrouped;
  rowsDispatch: Function;
  setEditModal: React.Dispatch<string>;
  setInsertToGroup: React.Dispatch<number>;
  setInsertToPosition: React.Dispatch<number>;
  conf: ElementProperties;
  AdditionalControlHeader?: React.ComponentType<any>;
}) {
  const { unregister } = useFormContext();
  const { isConfirmed } = useConfirm();
  return (
    <ControlButtonContainer>
      <EdsProvider density="comfortable">
        {Object.values(rows).flat().length > 0 ? (
          <>
            <AddNewButton
              setEditModal={setEditModal}
              setInsertToGroup={setInsertToGroup}
              setInsertToPosition={setInsertToPosition}
            />
            <Button
              variant="outlined"
              color="danger"
              disabled={!selection.length}
              onClick={() =>
                removeAndUnregisterRows({
                  isConfirmed,
                  rowsDispatch,
                  unregister,
                  selection,
                  conf,
                })
              }
            >
              <span>
                Remove selected line
                <span
                  style={{
                    visibility: selection.length === 1 ? "hidden" : "visible",
                  }} /* using visibility to avoid button resize */
                >
                  s
                </span>
              </span>
            </Button>
            {AdditionalControlHeader && <AdditionalControlHeader />}
          </>
        ) : null}
      </EdsProvider>
    </ControlButtonContainer>
  );
}

function RowMenu({
  item,
  rows,
  rowsDispatch,
  unregister,
  isConfirmed,
  setValue,
  deletedLines,
  setDeletedLines,
  AdditionalRowMenu,
  conf,
  setEditModal,
  setInsertToGroup,
  setInsertToPosition,
}: {
  item: any;
  rows: ElementListGrouped;
  rowsDispatch: Function;
  unregister: UseFormUnregister<FieldValues>;
  isConfirmed: IsConfirmed;
  setValue: Function;
  deletedLines: string[];
  setDeletedLines: Function;
  AdditionalRowMenu?: React.ComponentType<any>;
  conf: ElementProperties;
  setEditModal: React.Dispatch<string>;
  setInsertToGroup: React.Dispatch<number>;
  setInsertToPosition: React.Dispatch<number>;
}) {
  function addNewLine({
    group,
    position,
  }: {
    group: number;
    position: number;
  }) {
    setInsertToGroup(group);
    setInsertToPosition(position);
    setEditModal("_new");
  }

  const group = rows[item[conf.elementGroupIdProp]]
    ? rows[item[conf.elementGroupIdProp]]
    : [];

  return (
    <ContextMenu buttonContent={<Icon data={more_horizontal} />}>
      <Menu.Item
        onClick={() => setEditModal(item.id)}
        disabled={deletedLines.includes(item.id)}
      >
        <Icon size={16} data={edit} />
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          softDeleteRow({
            item,
            isConfirmed,
            deletedLines,
            setDeletedLines,
            setValue,
            conf,
          })
        }
        disabled={deletedLines.includes(item.id)}
      >
        <Icon size={16} data={delete_to_trash} />
        Soft delete (logical)
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          removeAndUnregisterRow({
            item,
            rowsDispatch,
            unregister,
            isConfirmed,
            conf,
          })
        }
      >
        <Icon size={16} data={delete_forever} />
        Hard delete (remove)
      </Menu.Item>
      <Menu.Section>
        <Menu.Item
          onClick={() => moveLine({ item, rowsDispatch, direction: "up" })}
          disabled={group.indexOf(item.id) === 0}
        >
          <Icon size={16} data={arrow_drop_up} />
          Move up
        </Menu.Item>
        <Menu.Item
          onClick={() => moveLine({ item, rowsDispatch, direction: "down" })}
          disabled={group.indexOf(item.id) === group.length - 1}
        >
          <Icon size={16} data={arrow_drop_down} />
          Move down
        </Menu.Item>
      </Menu.Section>
      <Menu.Section>
        <Menu.Item
          onClick={() =>
            addNewLine({
              group: item[conf.elementGroupIdProp],
              position: group.indexOf(item.id) + 1,
            })
          }
        >
          <Icon
            size={16}
            data={playlist_add}
            style={{ transform: "scaleY(-1)" }}
          />
          Add new line above
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            addNewLine({
              group: item[conf.elementGroupIdProp],
              position: group.indexOf(item.id) + 2,
            })
          }
        >
          <Icon size={16} data={playlist_add} /> Add new line below
        </Menu.Item>
      </Menu.Section>
      {AdditionalRowMenu && <AdditionalRowMenu item={item} />}
    </ContextMenu>
  );
}

export function ElementNote({
  elementName,
  item,
  elementGroupsData,
  conf,
  section,
}: {
  elementName: ElementTypes;
  item: any;
  elementGroupsData: any;
  conf: ElementProperties;
  section: NoteSections;
}) {
  return (
    <NoteButton
      note={item.NoteID}
      noteProp={`${conf.submitProp}.${item.id}.NoteID`}
      size={16}
      title={`Note for ${getGroupName({
        elementName,
        elementGroupsData,
        groupId: item[conf.elementGroupIdProp],
      })} element`}
      section={section}
    />
  );
}

export interface RowData {
  id: IdProp;
  _classNames?: string[];
  ElementGroupTitle: string;
}

export type ElementItemData = RowData &
  (PipeElementsAPIData & ValveElementsAPIData);

export function getGroupName({
  elementName,
  elementGroupsData,
  groupId,
}: {
  elementName: ElementTypes;
  elementGroupsData: any;
  groupId: number;
}) {
  const conf = elementProperties[elementName];
  if (!elementGroupsData) {
    return groupId;
  }
  const thisGroupData = elementGroupsData.find(
    (e: any) => e[conf.groupIdProp] === groupId
  );

  if (!thisGroupData || !thisGroupData.hasOwnProperty(conf.groupIdProp)) {
    return groupId;
  }
  return thisGroupData[conf.groupNameProp];
}

export function getGroupId(rows: any, id: IdProp) {
  return Object.keys(rows).reduce((a, c) => {
    return rows.hasOwnProperty(Number(c)) && rows[Number(c)].includes(id)
      ? Number(c)
      : a;
  }, 0);
}

function rowsReducer(
  state: ElementListGrouped,
  action: {
    type: "add" | "set" | "remove" | "up" | "down";
    payload: {
      toSet?: ElementListGrouped;
      toAdd?: { group: number; position: number; id: IdProp };
      toRemove?: IdProp[];
      id?: IdProp;
    };
  }
) {
  switch (action.type) {
    case "add":
      if (action.payload.toAdd) {
        const { group, position, id } = action.payload.toAdd;
        const pos = position - 1;
        const oldState = Object.assign({}, state);
        const updatedState = {
          ...oldState,
          ...{
            [group]: [
              ...(!!oldState[group] ? oldState[group].slice(0, pos) : []),
              id,
              ...(!!oldState[group] ? oldState[group].slice(pos) : []),
            ],
          },
        };
        return updatedState;
      }
      return state;
    case "set":
      return action.payload.toSet ? action.payload.toSet : {};
    case "remove":
      action.payload.toRemove &&
        action.payload.toRemove.forEach((idToRemove) => {
          const groupId = getGroupId(state, idToRemove);
          if (groupId && state[groupId]) {
            state[groupId].length > 1
              ? state[groupId].splice(state[groupId].indexOf(idToRemove), 1)
              : delete state[groupId];
          }
        });
      return Object.assign({}, state);
    case "up":
    case "down":
      if (!action.payload.id) {
        return state;
      }
      const id = action.payload.id;
      const groupId = getGroupId(state, id);
      const pos = state[groupId].indexOf(id);
      const updatedState = {
        ...state,
        [groupId]: arrayMove(
          state[groupId],
          pos,
          pos + (action.type === "up" ? -1 : 1)
        ),
      };
      return updatedState;
    default:
      return state;
  }
}

export default function useElementTable({
  elementName,
  setIsLoaded,
  saveSheetStatus,
  name,
  revision,
  columns,
  title,
  ElementHiddenInputs,
  AdditionalRowMenu,
  AdditionalControlHeader,
  EditModal,
}: {
  elementName: ElementTypes;
  setIsLoaded: Function;
  saveSheetStatus: QueryStatus;
  name: string;
  revision: string;
  columns: ColumnsProps[];
  title: string;
  ElementHiddenInputs?: React.ComponentType<any>;
  AdditionalRowMenu?: React.ComponentType<any>;
  AdditionalControlHeader?: React.ComponentType<any>;
  EditModal?: React.ComponentType<any>;
}): {
  elementTableContent: React.ReactFragment;
} {
  const conf = elementProperties[elementName];
  const plant = useAppSelector(selectMainPlant);

  const [editModal, setEditModal] = useState("");
  const [dataWithIds, setDataWithIds] = useState([] as any[]);
  const [deletedLines, setDeletedLines] = useState([] as IdProp[]);
  const [addedLines, setAddedLines] = useState([] as any[]);
  const [initing, setIniting] = useState(true);
  const [listIdsIniting, setListIdsIniting] = useState(true);
  const [insertToGroup, setInsertToGroup] = useState(0);
  const [insertToPosition, setInsertToPosition] = useState(0);
  const [defaultMaterialGroup, setDefaultMaterialGroup] = useState("");
  const [itemsClassNames, setItemsClassNames] = useState(
    [] as ItemsClassNames[]
  );

  const { isConfirmed } = useConfirm();

  const { data, status, isRefetching } = useSheet({
    plant,
    sheetType: "pcs",
    name,
    revision,
    postfix: elementName,
    cacheTime: 0,
  });

  const { data: elementGroupsData, status: elementGroupsStatus } = useCodelist({
    codelist: conf.groupName,
  });

  const { data: materialGroupsData, status: materialGroupsStatus } =
    useCodelist({
      codelist: "material-groups",
    });

  const groupsData: GroupsData = useMemo(
    () =>
      elementGroupsData
        ? elementGroupsData.map((e) => ({
            id: e[conf.groupIdProp as keyof typeof e],
            name: e[conf.groupNameProp as keyof typeof e],
          }))
        : [],
    [conf, elementGroupsData]
  );

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(status, elementGroupsStatus, materialGroupsStatus),
    [status, elementGroupsStatus, materialGroupsStatus]
  );

  const { reset, setValue, register, unregister, watch } = useFormContext();

  const form = watch();

  const [rows, rowsDispatch] = useReducer(
    rowsReducer,
    {} as ElementListGrouped
  );

  // Manage lifecycle of data
  useLayoutEffect(() => {
    if (saveSheetStatus === "success" && !isRefetching) {
      setIniting(true);
      setListIdsIniting(true);
      setAddedLines([]);
      setItemsClassNames([]);
    }
    if (saveSheetStatus === "success") {
      reset(undefined, { keepDirty: false });
    }
  }, [saveSheetStatus, isRefetching, combinedStatuses, reset]);

  // Prepare data for display
  useLayoutEffect(() => {
    if (initing && combinedStatuses === "success" && !isRefetching) {
      const preparedData = [
        ...data[conf.getProp].map((e: any, i: number) => {
          return {
            ...e,
            id: String(`e${i}`),
            ElementGroupTitle: getGroupName({
              elementName,
              elementGroupsData,
              groupId: e[conf.elementGroupIdProp],
            }),
          };
        }),
      ];
      setDataWithIds(preparedData);
      rowsDispatch({
        type: "set",
        payload: {
          toSet: preparedData.reduce((a: any, c: any) => {
            a[c[conf.elementGroupIdProp]]
              ? a[c[conf.elementGroupIdProp]].push(c.id)
              : (a[c[conf.elementGroupIdProp]] = [c.id]);
            return a;
          }, {}),
        },
      });
      setDeletedLines(
        preparedData.filter((e) => e.NewDeletedLine === "D").map((e) => e.id)
      );
      reset(undefined, { keepDirty: false });
      setIniting(false);
    }
  }, [
    dataWithIds,
    combinedStatuses,
    conf.elementGroupIdProp,
    initing,
    data,
    conf.getProp,
    addedLines,
    elementName,
    elementGroupsData,
    saveSheetStatus,
    isRefetching,
    reset,
  ]);

  useEffect(() => {
    setIsLoaded(combinedStatuses === "success");
  }, [combinedStatuses, setIsLoaded]);

  useEffect(() => {
    if (elementName === "pipe-elements" && materialGroupsData && data) {
      const defaultMaterialGroup = materialGroupsData.find(
        (e) => e.MaterialGroupID === data["MaterialGroupID"]
      );
      setDefaultMaterialGroup(
        defaultMaterialGroup ? defaultMaterialGroup["MaterialGroup"] : ""
      );
    } else {
      setDefaultMaterialGroup("");
    }
  }, [elementName, data, materialGroupsData]);

  const items = useMemo(() => {
    const allIds = [
      ...addedLines.map((e) => e.id),
      ...dataWithIds.map((e) => e.id),
    ];
    return rows && dataWithIds
      ? Object.values(rows)
          .flat()
          .filter((e) => allIds.includes(e))
          .map((row) => {
            return dataWithIds.find((e) => e["id"] === row)
              ? dataWithIds.find((e) => e.id === row)
              : addedLines.find((e) => e.id === row);
          })
          .map((e: any) => {
            return {
              ...e,
              _classNames: [deletedLines.includes(e["id"]) ? "_deleted" : ""],
            };
          })
      : [];
  }, [rows, dataWithIds, deletedLines, addedLines]);

  useEffect(() => {
    deletedLines.forEach((idToDelete) =>
      setValue(`${conf.submitProp}.${idToDelete}.NewDeletedLine`, "D")
    );
  }, [conf.submitProp, deletedLines, setValue]);

  useEffect(() => {
    if (!initing) {
      setValue(
        "listIds",
        Object.keys(rows)
          .map((groupId) => rows[Number(groupId)].join())
          .join(),
        { shouldDirty: !listIdsIniting }
      );
      setListIdsIniting(false);
    }

    // The sizes hidden input is set to represent the current list of lines
    // and the change is marked as dirty only when it's not the initial load.
    // eslint-disable-next-line
  }, [rows]);

  const { selection, selectionDispatch, selectionMode } = useTableSelect({
    selectionMode: "multi",
  });

  const elementTableContent =
    combinedStatuses === "success" &&
    data &&
    data.hasOwnProperty(conf.getProp) ? (
      <>
        <AbsoluteFlexContainer>
          <FlexContentElement>
            <input
              {...register("listIds")}
              type="hidden"
              defaultValue={dataWithIds.map((e) => e.id).join()}
            />
            <Table
              nonVirtual={true}
              totalCount={items.length}
              items={items}
              status={combinedStatuses}
              columns={columns}
              itemIdProp="id"
              density="compact"
              selection={selection}
              selectionMode={selectionMode}
              selectionDispatch={selectionDispatch}
              groupBy={conf.elementGroupIdProp}
              groupTitle="ElementGroupTitle"
              groupIdProp={conf.elementGroupIdProp}
              contextData={{
                rows,
                rowsDispatch,
                elementGroupsData,
                unregister,
                isConfirmed,
                setValue,
                deletedLines,
                setDeletedLines,
                elementName,
                conf,
                AdditionalRowMenu,
                editModal,
                setEditModal,
                setInsertToGroup,
                setInsertToPosition,
              }}
              Commands={[
                MoveUpCommandButton,
                MoveDownCommandButton,
                EditCommandButton,
              ]}
              RowMenu={RowMenu}
              controlHeader={
                <ControlHeader
                  selection={selection}
                  rows={rows}
                  rowsDispatch={rowsDispatch}
                  setEditModal={setEditModal}
                  setInsertToGroup={setInsertToGroup}
                  setInsertToPosition={setInsertToPosition}
                  conf={conf}
                  AdditionalControlHeader={AdditionalControlHeader}
                />
              }
              noItemsMessage={
                <>
                  No items.
                  <ControlButtonContainer>
                    <AddNewButton
                      setEditModal={setEditModal}
                      setInsertToGroup={setInsertToGroup}
                      setInsertToPosition={setInsertToPosition}
                    />
                  </ControlButtonContainer>
                </>
              }
              RowHiddenComponent={ElementHiddenInputs}
              resizable={true}
              itemsClassNames={itemsClassNames}
            />
          </FlexContentElement>
        </AbsoluteFlexContainer>
        {EditModal && editModal && (
          <EditModal
            setEditModal={setEditModal}
            editModal={editModal}
            items={items}
            groupsData={groupsData}
            rows={rows}
            rowsDispatch={rowsDispatch}
            group={insertToGroup}
            setInsertToGroup={setInsertToGroup}
            position={insertToPosition}
            defaultMaterialGroup={defaultMaterialGroup}
            form={form}
            dataWithIds={dataWithIds}
            setDataWithIds={setDataWithIds}
            setItemsClassNames={setItemsClassNames}
            addedLines={addedLines}
            setAddedLines={setAddedLines}
            revision={revision}
            name={name}
          />
        )}
      </>
    ) : (
      <EditModalStatusDisplay
        status={combinedStatuses}
        loadingString={`Loading ${title}...`}
      />
    );

  return {
    elementTableContent,
  };
}

export function useGroupSelector({
  groupsData,
  defaultGroup,
}: {
  groupsData: GroupsData;
  defaultGroup?: number;
}) {
  const [selectedGroup, setSelectedGroup] = useState(
    defaultGroup ? defaultGroup : groupsData[0].id
  );
  const GroupSelector = (
    <NativeSelect
      id="GroupSelector"
      label="Element Group"
      value={selectedGroup}
      onChange={(e) => setSelectedGroup(Number(e.target.value))}
    >
      {groupsData.map((e) => (
        <option value={e.id} key={e.id}>
          {e.name}
        </option>
      ))}
    </NativeSelect>
  );
  return {
    selectedGroup,
    GroupSelector,
  };
}

export function usePositionSelector({
  rows,
  group,
  defaultPosition,
}: {
  rows: ElementListGrouped;
  group: number;
  defaultPosition?: number;
}) {
  const [selectedPosition, setSelectedPosition] = useState(
    defaultPosition ? defaultPosition : 1
  );
  const groupOnMount = useRef(group);
  useEffect(() => {
    if (groupOnMount.current !== group) {
      setSelectedPosition(1);
    }
  }, [group]);
  const PositionSelector = (
    <NativeSelect
      id="PositionSelector"
      label="Position"
      value={selectedPosition}
      onChange={(e) => setSelectedPosition(Number(e.target.value))}
    >
      {rows[group] ? (
        Array(rows[group].length + 1)
          .fill("")
          .map((_, i) => (
            <option value={i + 1} key={i}>
              {i + 1}
            </option>
          ))
      ) : (
        <option>1</option>
      )}
    </NativeSelect>
  );
  return { selectedPosition, PositionSelector };
}
