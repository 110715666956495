import { NativeSelect, TextField, Typography } from "@equinor/eds-core-react";
import { FlexContainer, FlexElement } from "components/Components";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../../../components/ReportTable";
import { ReportFormHeader } from "../ReportFormHeader";
import { reportsConfig } from "../Reports";
import { PMGSPENoMatricesData } from "./pmgSPENoMatricesConfig";

export const pmgSPENoMatricesReportColumns: {
  key: keyof PMGSPENoMatricesData[0];
  title: string;
}[] = [
  { key: "MaterialGroup", title: "Material Group" },
  { key: "ElementGroup", title: "Element Group" },
  { key: "DimStandard", title: "Dim. Standard" },
  { key: "ProductForm", title: "Product Form" },
  { key: "MaterialGrade", title: "Material Grade" },
  { key: "MDS", title: "MDS" },
  { key: "MDSRevision", title: "MDS Rev." },
  { key: "Area", title: "Area" },
  { key: "SPEID", title: "SPE ID" },
  { key: "SPERev", title: "SPE Rev." },
];

export function PMGSPENoMatricesReport({
  data,
  filenameRef,
}: {
  data: PMGSPENoMatricesData | undefined;
  filenameRef: React.MutableRefObject<HTMLInputElement | null>;
}) {
  const { data: materialGroups, status: materialGroupsStatus } = useCodelist({
    codelist: "material-groups",
  });
  const { data: elementGroups, status: elementGroupsStatus } = useCodelist({
    codelist: "pipe-element-groups",
  });

  const status = combineQueryStatuses(
    materialGroupsStatus,
    elementGroupsStatus
  );
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);

  const selectedMaterialGroup =
    materialGroups &&
    materialGroups.find(
      (e) =>
        String(e.MaterialGroupID) ===
        String(parsedSearch.get("MaterialGroupID"))
    );

  const selectedElementGroup =
    elementGroups &&
    elementGroups.find(
      (e) =>
        String(e.ElementGroupID) === String(parsedSearch.get("ElementGroupID"))
    );

  const dimStandardFromURL = parsedSearch.get("DimStandard");

  const [dimStandard, setDimStandard] = useState<string>(
    dimStandardFromURL ?? "ASME"
  );

  const dimStandardCompiled = useMemo(
    () =>
      dimStandard === ""
        ? "*"
        : dimStandard[dimStandard.length - 1] === "*"
        ? dimStandard.slice(0, -1)
        : dimStandard,
    [dimStandard]
  );

  return status === "loading" ? (
    <>Loading Codelists...</>
  ) : status === "success" ? (
    <>
      <input
        type="hidden"
        value={reportsConfig["pmg/spe-no-matrices"].title}
        ref={filenameRef}
      />
      <ReportFormHeader>
        <FlexContainer flexStart gap={24}>
          <FlexElement>
            <NativeSelect
              id="MaterialGroupID"
              label="Material Group"
              defaultValue={selectedMaterialGroup?.MaterialGroupID ?? ""}
              style={{ width: 200 }}
            >
              <option value="*">- All -</option>
              {materialGroups?.map((e) => (
                <option key={e.MaterialGroupID} value={e.MaterialGroupID}>
                  {e.MaterialGroup}
                </option>
              ))}
            </NativeSelect>
          </FlexElement>
          <FlexElement>
            <NativeSelect
              id="ElementGroupID"
              label="Element Group"
              defaultValue={selectedElementGroup?.ElementGroupID ?? ""}
              style={{ width: 200 }}
            >
              <option value="*">- All -</option>
              {elementGroups?.map((e) => (
                <option key={e.ElementGroupID} value={e.ElementGroupID}>
                  {e.ElementGroup}
                </option>
              ))}
            </NativeSelect>
          </FlexElement>
          <FlexElement>
            <TextField
              label="Dimension Standard"
              value={dimStandard}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDimStandard(e.target.value)
              }
              id="DimStandardShadow"
            />
            <input type="hidden" id="DimStandard" value={dimStandardCompiled} />
          </FlexElement>
        </FlexContainer>
      </ReportFormHeader>
      {typeof data !== "undefined" && (
        <>
          <Typography variant="h6" style={{ marginBottom: 12 }}>
            Material Groups excluded from the list: GRP, PVDF, PVC, PP, UPVC,
            PE, Elastomer
          </Typography>
          <ReportTable>
            <TableHead>
              <TableRow>
                {pmgSPENoMatricesReportColumns.map((e) => (
                  <HeadCell>{e.title}</HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d, i) => (
                <TableRow key={i}>
                  {pmgSPENoMatricesReportColumns.map((e) => (
                    <ContentCell>{d[e.key]}</ContentCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </ReportTable>
        </>
      )}
    </>
  ) : (
    <></>
  );
}
