import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type PMGProductForms = {
  ProductFormID: number;
  Description: string;
  ElementGroupID: number;
};

export type PMGProductFormsCreate = {
  Description: string;
  ElementGroupID: string;
};

export type PMGProductFormsUpdate = CommonUpdateProps & {
  Description: string;
  ElementGroupID: string;
};

export const pmgProductFormsConfig: ItemAdminConfig<"pmg-product-forms"> = {
  title: "PMG Product Forms",
  singularTitle: "PMG Product Form",
  url: "codelists/pmg-productforms",
  itemIdProp: "ProductFormID",
  apiListProp: "getPMGProductForm",
  nameProperty: ["ProductFormID", "Description"],
  columns: [
    {
      key: "ProductFormID",
      title: "ID",
    },
    { key: "Description", title: "Product Form", width: "60%" },
    {
      key: "ElementGroupID",
      title: "Element Group",
      codelist: "pipe-element-groups",
      width: "40%",
    },
  ],
  codelistsToQuery: ["pipe-element-groups"],
};
