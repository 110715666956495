import { Button, NativeSelect } from "@equinor/eds-core-react";
import { FlexColumnContainer, FlexColumnElement } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { Fieldset, FormRow } from "components/form/Form";
import { reloadPreviewPane } from "features/sheets/SheetPane";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSaveVDS, {
  SaveVDSMutationProps,
  VDSSubsegmentItem,
} from "features/sheets/queries/useSaveVDS";
import React, { useMemo, useState } from "react";

function useSelectCharacteristics({
  title,
  id,
  value,
  unselectedOption,
  options,
  disabled,
}: {
  title: string;
  id: string;
  value: number;
  unselectedOption?: undefined | React.ReactFragment;
  options: { value: number; title: string }[];
  disabled?: boolean;
}) {
  const [thisState, setThisState] = useState(value);
  const selectStyle = { width: 380 };

  return {
    selectState: thisState,
    selectContent: (
      <FormRow>
        <NativeSelect
          label={title}
          id={id}
          value={thisState}
          onChange={(e) => {
            setThisState(Number(e.target.value));
          }}
          title={options.find((o) => o.value === thisState)?.title ?? ""}
          style={selectStyle}
          disabled={disabled}
        >
          {unselectedOption ?? null}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </NativeSelect>
      </FormRow>
    ),
  };
}

const selectAnOption = <option value="">- Select an option -</option>;
const emptySelectAnOption = <option value="">{""}</option>;

export function VDSCharacteristicsModal({
  isCreateNewEdit,
  isOpen,
  closeModal,
  name,
  revision,
  setShowEdit,
  setShowCharacteristics,
  newNameSeriesSetting,
  valveTypeSetting,
  setValveTypeSetting,
  ratingClassSetting,
  setRatingClassSetting,
  materialGroupSetting,
  setMaterialGroupSetting,
  endConnectionSetting,
  setEndConnectionSetting,
  boreSetting,
  setBoreSetting,
  sizeSetting,
  setSizeSetting,
  housingDesignSetting,
  setHousingDesignSetting,
  error,
  subsegments,
  specialRequirementSetting,
  note,
}: {
  isCreateNewEdit?: boolean;
  isOpen: boolean;
  closeModal: () => void;
  name: string;
  revision: string;
  setShowEdit?: React.Dispatch<boolean>;
  setShowCharacteristics: React.Dispatch<boolean>;
  newNameSeriesSetting: number;
  valveTypeSetting: number;
  setValveTypeSetting: React.Dispatch<number>;
  ratingClassSetting: number;
  setRatingClassSetting: React.Dispatch<number>;
  materialGroupSetting: number;
  setMaterialGroupSetting: React.Dispatch<number>;
  endConnectionSetting: number;
  setEndConnectionSetting: React.Dispatch<number>;
  boreSetting: number;
  setBoreSetting: React.Dispatch<number>;
  sizeSetting: number;
  setSizeSetting: React.Dispatch<number>;
  housingDesignSetting: number;
  setHousingDesignSetting: React.Dispatch<number>;
  error: Error | null;
  subsegments: VDSSubsegmentItem[];
  specialRequirementSetting: number;
  note: string;
}) {
  const {
    data: valveTypes,
    status: valveTypesStatus,
    error: valveTypesError,
  } = useCodelist({
    codelist: "valve-types",
  });
  const {
    data: ratingClasses,
    status: ratingClassesStatus,
    error: ratingClassesError,
  } = useCodelist({
    codelist: "rating-classes",
  });
  const {
    data: materialGroups,
    status: materialGroupsStatus,
    error: materialGroupsError,
  } = useCodelist({
    codelist: "material-groups",
  });
  const {
    data: endConnections,
    status: endConnectionStatus,
    error: endConnectionError,
  } = useCodelist({
    codelist: "end-connections",
  });
  const {
    data: bores,
    status: boresStatus,
    error: boresError,
  } = useCodelist({
    codelist: "bores",
  });
  const {
    data: sizes,
    status: sizesStatus,
    error: sizesError,
  } = useCodelist({
    codelist: "vds-sizes",
  });
  const {
    data: housingDesigns,
    status: housingDesignsStatus,
    error: housingDesignsError,
  } = useCodelist({
    codelist: "housing-design",
  });

  const combinedStatuses = combineQueryStatuses(
    valveTypesStatus,
    materialGroupsStatus,
    ratingClassesStatus,
    endConnectionStatus,
    boresStatus,
    sizesStatus,
    housingDesignsStatus
  );

  const allErrors = useMemo(
    () =>
      ({
        ...{ valveTypesError },
        ...{ materialGroupsError },
        ...{ ratingClassesError },
        ...{ endConnectionError },
        ...{ boresError },
        ...{ sizesError },
        ...{ housingDesignsError },
        ...{ error },
      } as { [index: string]: Error }),
    [
      boresError,
      endConnectionError,
      housingDesignsError,
      materialGroupsError,
      ratingClassesError,
      sizesError,
      valveTypesError,
      error,
    ]
  );

  const {
    selectState: valveTypeSelectionSetting,
    selectContent: valveTypeSelectionContent,
  } = useSelectCharacteristics({
    title: "Valve Type",
    id: "ValveType",
    value: valveTypeSetting,
    unselectedOption: !isCreateNewEdit && selectAnOption,
    options:
      valveTypes?.map((valveType) => ({
        value: valveType.ValveTypeID,
        title: `(${valveType.VDSCode}) ${valveType.Description}`,
      })) ?? [],
  });

  const selectedValveType = useMemo(
    () =>
      valveTypes?.find((vt) => vt.ValveTypeID === valveTypeSelectionSetting),
    [valveTypeSelectionSetting, valveTypes]
  );

  const boreDisabled =
    !selectedValveType || selectedValveType.FBRBIndicator === "N";
  const housingDesignDisabled =
    !selectedValveType || selectedValveType.HDIndicator === "N";

  const {
    selectState: ratingClassSelectionSetting,
    selectContent: ratingClassSelectionContent,
  } = useSelectCharacteristics({
    title: "Rating Class",
    id: "RatingClass",
    value: ratingClassSetting,
    unselectedOption: !isCreateNewEdit && selectAnOption,
    options:
      ratingClasses?.map((ratingClass) => ({
        value: ratingClass.RatingClassID,
        title: ratingClass.Description,
      })) ?? [],
  });

  const {
    selectState: materialGroupSelectionSetting,
    selectContent: materialGroupSelectionContent,
  } = useSelectCharacteristics({
    title: "Material Group",
    id: "MaterialGroup",
    value: materialGroupSetting,
    unselectedOption: !isCreateNewEdit && selectAnOption,
    options:
      materialGroups?.map((materialGroup) => ({
        value: materialGroup.MaterialGroupID,
        title: materialGroup.MaterialGroup,
      })) ?? [],
  });

  const {
    selectState: endConnectionSelectionSetting,
    selectContent: endConnectionSelectionContent,
  } = useSelectCharacteristics({
    title: "End Connection",
    id: "EndConnection",
    value: endConnectionSetting,
    unselectedOption: !isCreateNewEdit && selectAnOption,
    options:
      endConnections?.map((endConnection) => ({
        value: endConnection.EndConnectionID,
        title: `(${endConnection.VDSCode}) ${endConnection.Description}`,
      })) ?? [],
  });

  const {
    selectState: sizeSelectionSetting,
    selectContent: sizeSelectionContent,
  } = useSelectCharacteristics({
    title: "Size",
    id: "Size",
    value: sizeSetting,
    unselectedOption: !isCreateNewEdit && selectAnOption,
    options:
      sizes?.map((size) => ({
        value: size.VDSSizeID,
        title: size.Description,
      })) ?? [],
  });

  const {
    selectState: boreSelectionSetting,
    selectContent: boreSelectionContent,
  } = useSelectCharacteristics({
    title: "Bore",
    id: "Bore",
    value: boreSetting,
    unselectedOption: boreDisabled ? emptySelectAnOption : selectAnOption,
    options:
      bores?.map((bore) => ({
        value: bore.BoreID,
        title: bore.Description,
      })) ?? [],
    disabled: boreDisabled,
  });

  const {
    selectState: housingDesignSelectionSetting,
    selectContent: housingDesignSelectionContent,
  } = useSelectCharacteristics({
    title: "Housing Design",
    id: "HousingDesign",
    value: housingDesignSetting,
    unselectedOption: housingDesignDisabled
      ? emptySelectAnOption
      : selectAnOption,
    options:
      housingDesigns?.map((housingDesign) => ({
        value: housingDesign.HousingDesignID,
        title: housingDesign.Description,
      })) ?? [],
    disabled: housingDesignDisabled,
  });

  const allSelected = useMemo(
    () =>
      !!valveTypeSelectionSetting &&
      !!ratingClassSelectionSetting &&
      !!materialGroupSelectionSetting &&
      !!endConnectionSelectionSetting &&
      !!sizeSelectionSetting &&
      (selectedValveType?.FBRBIndicator === "N" || !!boreSelectionSetting) &&
      (selectedValveType?.HDIndicator === "N" ||
        !!housingDesignSelectionSetting),
    [
      boreSelectionSetting,
      endConnectionSelectionSetting,
      housingDesignSelectionSetting,
      materialGroupSelectionSetting,
      ratingClassSelectionSetting,
      selectedValveType?.FBRBIndicator,
      selectedValveType?.HDIndicator,
      sizeSelectionSetting,
      valveTypeSelectionSetting,
    ]
  );

  const closeThisModal = () => {
    setShowEdit && setShowEdit(true);
    setShowCharacteristics(false);
  };

  const {
    mutate: saveVDS,
    status: saveVDSStatus,
    error: saveVDSError,
    reset: saveVDSReset,
  } = useSaveVDS();

  const vdsSaveData = useMemo<SaveVDSMutationProps>(
    () => ({
      name,
      revision,
      content: {
        ValveTypeID: valveTypeSelectionSetting,
        RatingClassID: ratingClassSelectionSetting,
        MaterialTypeID: materialGroupSelectionSetting,
        EndConnectionID: endConnectionSelectionSetting,
        BoreID: boreDisabled ? 0 : boreSelectionSetting,
        VDSSizeID: sizeSelectionSetting,
        HousingDesignID: housingDesignDisabled
          ? 0
          : housingDesignSelectionSetting,
        SpecialReqID: specialRequirementSetting,
        Notepad: note,
        PreviewOnly: "Y",
        MakeNewName: "N",
        SerieID: newNameSeriesSetting,
        VDSSubsegment: subsegments,
      },
    }),
    [
      boreDisabled,
      boreSelectionSetting,
      endConnectionSelectionSetting,
      housingDesignDisabled,
      housingDesignSelectionSetting,
      materialGroupSelectionSetting,
      name,
      newNameSeriesSetting,
      note,
      ratingClassSelectionSetting,
      revision,
      sizeSelectionSetting,
      specialRequirementSetting,
      subsegments,
      valveTypeSelectionSetting,
    ]
  );

  return (
    <>
      <ModalWindow
        title="VDS Characteristics"
        isOpen={isOpen}
        closeModal={closeModal}
        status={combinedStatuses}
        error={allErrors}
      >
        <ModalSideMargin>
          <>
            <ModalWindowContentDefault>
              <FlexColumnContainer>
                <FlexColumnElement>
                  <Fieldset style={{ maxWidth: 480 }}>
                    {valveTypeSelectionContent}
                    {ratingClassSelectionContent}
                    {materialGroupSelectionContent}
                    {endConnectionSelectionContent}
                  </Fieldset>
                </FlexColumnElement>
                <FlexColumnElement>
                  <Fieldset>
                    {sizeSelectionContent}
                    {boreSelectionContent}
                    {housingDesignSelectionContent}
                  </Fieldset>
                </FlexColumnElement>
              </FlexColumnContainer>
            </ModalWindowContentDefault>
            <ModalWindowButtonContainer>
              <Button
                onClick={() => {
                  saveVDS({ ...vdsSaveData, characteristicChange: true });
                }}
                disabled={!allSelected}
              >
                {isCreateNewEdit ? "Apply" : "Continue"}
              </Button>
              <Button onClick={() => closeModal()} variant="outlined">
                Cancel
              </Button>
            </ModalWindowButtonContainer>
          </>
        </ModalSideMargin>
      </ModalWindow>
      <StatusModal
        status={saveVDSStatus}
        error={saveVDSError}
        onSuccess={() => {
          saveVDSReset();
          isCreateNewEdit && reloadPreviewPane("vdsEdit");
          closeThisModal();
        }}
        onSettledClose={() => {
          saveVDSReset();
        }}
      />
    </>
  );
}
