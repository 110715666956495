import config from "config/config";
import { UserRoles, userPower } from "app/userConfig";
import { Modules } from "config/config";
import { UserInfo } from "queries/useUser";
import { useUserConfig } from "UserConfigContext";

export const Access: React.FC<{
  cap?: string | string[];
  role?: UserRoles;
  hide?: boolean;
  enabled?: boolean;
  andOperator?: boolean;
  module?: Modules;
}> = ({ cap, role, enabled, children, hide, andOperator, module }) => {
  const { caps, userInfo } = useUserConfig();
  return hasAccess({
    cap,
    role,
    caps,
    userInfo,
    enabled,
    andOperator,
    module,
  }) ? (
    <>{children}</>
  ) : hide ? null : (
    <NoAccess />
  );
};

export function NoAccess() {
  return <div style={{ margin: "1em" }}>No access.</div>;
}

export const hasAccess = ({
  cap,
  role,
  enabled,
  caps,
  userInfo,
  andOperator,
  module,
}: {
  cap?: string | string[];
  role?: UserRoles;
  enabled?: boolean;
  caps: string[];
  userInfo: UserInfo;
  andOperator?: boolean;
  module?: Modules;
}) => {
  const capValue =
    cap &&
    (typeof cap === "string"
      ? caps.includes(cap)
      : cap.some((acap) => caps.includes(acap)));
  const roleValue = !!role && userPower[userInfo.Role] >= userPower[role];
  return (
    (!module || config.modules.includes(module) || userInfo.Role === "X") &&
    (enabled === false ||
      (andOperator ? capValue && roleValue : capValue || roleValue))
  );
};
