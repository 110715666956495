import React, { useEffect } from "react";
import ContentModal from "../../../components/ContentModal";
import Sheets from "../Sheets";
import { hideSheetCopyModal, setNewRevision } from "../sheetSlice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { getSheetState } from "../sheetSlice";
import { setPaneMode } from "uiSlice";
import { selectSourcePlant, selectMainPlant } from "../../plant/plantSlice";
import {
  ContentMessage,
  FlexContainer,
  FlexElement,
} from "../../../components/Components";
import PlantSelector from "../../plant/PlantSelector";
import { Typography, Radio, Tooltip, Input } from "@equinor/eds-core-react";
import { useForm } from "react-hook-form";
import {
  maxPCSNameLength,
  maxSheetNameLength,
  SheetTypes,
} from "../config/sheetConfig";
import { stringToCode, getControlID } from "../util";

export const NewNameInput = ({
  item,
  sheetType,
  newNames,
  setNewNames,
  trigger,
  mainItemsNames,
  register,
  formState,
  selectedItems,
}: {
  item: any;
  sheetType: SheetTypes;
  newNames: any;
  setNewNames: Function;
  trigger: Function;
  mainItemsNames: any[];
  register: any;
  formState: any;
  selectedItems: any[];
}) => {
  const ID = item.itemID;
  const checked = selectedItems.filter((i) => i.itemID === ID).length > 0;
  const codedID = stringToCode(ID);
  const maxLength = sheetType === "pcs" ? maxPCSNameLength : maxSheetNameLength;

  return (
    <Tooltip
      title={
        formState.errors &&
        formState.errors.newname &&
        formState.errors.newname[codedID]
          ? formState.errors.newname[codedID].message
          : ""
      }
    >
      <Input
        value={newNames[ID]}
        style={{ width: "105px" }}
        {...register(getControlID("newname", codedID), {
          value: newNames[ID],
          maxLength: {
            value: maxLength,
            message: `Maximum length is ${maxLength} characters.`,
          },
          validate: {
            blank: (name: string) => !checked || !!name || "Cannot be blank",
            exists: (name: string) =>
              !checked ||
              mainItemsNames.indexOf(name) === -1 ||
              "This name exists in the target plant.",
            duplicate: (name: string) => {
              const newNamesOfChecked = selectedItems
                .filter((item) => item.itemID !== ID)
                .map((item) => newNames[item.itemID]);
              return (
                !checked ||
                newNamesOfChecked.indexOf(name) === -1 ||
                "There's another selected data sheet with the same name."
              );
            },
            allowedChars: (name: string) =>
              !checked ||
              /^[A-Z0-9-]*$/g.test(name) ||
              "The name contains invalid characters. Allowed characters: A-Z, 0-9 and - (dash).",
          },
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setNewNames({ ...newNames, [ID]: e.target.value });
            setTimeout(() => trigger());
          },
        })}
        variant={
          formState?.errors &&
          formState.errors.newname &&
          formState.errors.newname[codedID]
            ? "error"
            : "default"
        }
      />
    </Tooltip>
  );
};

export default function CopySheetsModal() {
  const dispatch = useAppDispatch();
  const { register, watch } = useForm();
  const { isCopyModalOpen, newRevision, sheetType } =
    useAppSelector(getSheetState);
  const mainPlant = useAppSelector(selectMainPlant);
  const sourcePlant = useAppSelector(selectSourcePlant);
  const newRevisionWatch = watch("newRevision");

  useEffect(() => {
    if (typeof newRevisionWatch !== "undefined") {
      dispatch(setNewRevision(newRevisionWatch));
    }
  }, [newRevisionWatch, dispatch]);

  function closeModal() {
    dispatch(hideSheetCopyModal());
    dispatch(setPaneMode({ location: "copyModal", mode: false }));
  }

  return (
    <>
      {isCopyModalOpen && (
        <ContentModal
          isOpen={isCopyModalOpen}
          closeModal={closeModal}
          header={
            <>
              <FlexContainer>
                <FlexElement>
                  <Typography variant="h3" style={{ marginBottom: ".5em" }}>
                    Copy data sheets to {mainPlant.LongDescription}{" "}
                  </Typography>
                </FlexElement>
              </FlexContainer>
              <FlexContainer>
                <FlexElement>
                  <FlexContainer style={{ justifyContent: "flex-start" }}>
                    <FlexElement style={{ paddingRight: "1em" }}>
                      Source plant:
                    </FlexElement>
                    <FlexElement>
                      <PlantSelector kind="source"></PlantSelector>
                    </FlexElement>
                  </FlexContainer>
                </FlexElement>
                <FlexElement>
                  <form>
                    <FlexContainer style={{ justifyContent: "flex-end" }}>
                      <FlexElement style={{ paddingRight: "1em" }}>
                        New revision:
                      </FlexElement>
                      <FlexElement style={{ paddingRight: ".5em" }}>
                        <Radio
                          label="0"
                          value="0"
                          defaultChecked={newRevision === "0"}
                          {...register("newRevision")}
                        />
                      </FlexElement>
                      <FlexElement style={{ paddingRight: ".5em" }}>
                        <Radio
                          label="1"
                          value="1"
                          defaultChecked={newRevision === "1"}
                          {...register("newRevision")}
                        />
                      </FlexElement>
                    </FlexContainer>
                  </form>
                </FlexElement>
              </FlexContainer>
            </>
          }
        >
          {sourcePlant.PlantID > 0 ? (
            <Sheets
              plant={sourcePlant}
              displayType="copyModal"
              newRevision={newRevision}
              sheetType={sheetType}
              closeModal={closeModal}
            ></Sheets>
          ) : (
            <ContentMessage>Select a plant to copy from.</ContentMessage>
          )}
        </ContentModal>
      )}
    </>
  );
}
