import { FilterProps } from "../components/table/Filters";
import { Optional } from "./types";

export type CommonObjectContent = { [key in string]: any };

export type PureFilterProps = Optional<
  Optional<Optional<Optional<FilterProps, "group">, "title">, "setFilterState">,
  "type"
>;

export function filterItemsByProps<T extends CommonObjectContent>({
  filters,
  items,
  alwaysPresent,
}: {
  filters: PureFilterProps[];
  items: T[];
  alwaysPresent?: { idProp: string; ids: string[] };
}) {
  return !items || !items?.length
    ? []
    : !filters || !filters?.length
    ? items
    : items.filter(
        (item: T) =>
          (alwaysPresent &&
            alwaysPresent.idProp in item &&
            alwaysPresent.ids.includes(String(item[alwaysPresent.idProp]))) ||
          filters
            .filter((f) => !(f.serverSide && f.apiFilterPorp))
            .every((f) =>
              !f.filterState || f.filterState.length === 0
                ? true
                : f.filterFn
                ? f.filterFn(item)
                : f.commaSeparated
                ? (f.transformer ? f.transformer(item[f.prop]) : item[f.prop])
                    .split(",")
                    .some(
                      (i: string) =>
                        i === "*" ||
                        (typeof f.filterState === "string"
                          ? i === f.filterState
                          : f.filterState.includes(i))
                    )
                : typeof f.filterState === "string"
                ? String(item[f.prop]) === f.filterState
                : f.filterState.includes(item[f.prop])
            )
      );
}
