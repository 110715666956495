import { Button, Icon, Search, Tooltip } from "@equinor/eds-core-react";
import { add, refresh } from "@equinor/eds-icons";
import { Dispatch, useMemo, useReducer, useState } from "react";
import { UseQueryResult } from "react-query";
import { useLocation } from "react-router-dom";
import { filterItemsByName } from "utils/filterItemsByName";
import {
  CommonObjectContent,
  filterItemsByProps,
} from "utils/filterItemsByProps";
import { Optional } from "utils/types";
import {
  FlexContainer,
  FlexElement,
  HeaderButtonContainer,
} from "../Components";
import { ExtendedDownloads } from "../Downloads";
import { NameFilterInfo } from "../NameFilterInfo";
import Filters, {
  FilterGroupPropertiesProps,
  FilterProps,
  FiltersDispatchProps,
  areFiltersInDefaultState,
  filtersReducer,
  getServerSideFilters,
} from "./Filters";
import { HeaderProgress } from "./HeaderProgress";
import Table, {
  ColumnsProps,
  ControlHeaderContainer,
  ControlHeaderTitle,
  TableProps,
} from "./Table";

export function useTable({
  query,
  columns,
  title,
  filterGroupProperties,
  getItemID,
  nameProperty,
  nameFilterPlaceholder,
  controlHeaderBeginning,
  downloadColumns,
  downloadItems,
  createNew,
  dataProp,
  TitleComponent,
  hideDownloadFiltered,
  filename,
  hideHeader,
  ...rest
}: {
  query: UseQueryResult<unknown>;
  columns: ColumnsProps[];
  title: string;
  filterGroupProperties?: FilterGroupPropertiesProps[];
  getItemID?: (item: any) => string;
  nameProperty?: string | string[];
  nameFilterPlaceholder?: string;
  controlHeaderBeginning?: React.ReactElement;
  downloadColumns?: ColumnsProps[];
  downloadItems?: CommonObjectContent[];
  createNew?: () => void;
  dataProp?: string;
  hideDownloadFiltered?: boolean;
  TitleComponent?: React.ComponentType;
  filename?: string;
  hideHeader?: boolean;
} & Optional<Optional<TableProps, "itemIdProp">, "items">): {
  filters: FilterProps[];
  filtersDispatch: Dispatch<FiltersDispatchProps>;
  content: React.ReactFragment;
  editName: string;
  editRevision: string;
  editTab: string;
} {
  const { data, status, error, isRefetching, refetch, remove } = query;
  const [filters, filtersDispatch] = useReducer(
    filtersReducer,
    [] as FilterProps[]
  );
  const [nameFilter, setNameFilter] = useState("");
  const itemsToUse =
    rest.items ??
    ((dataProp && data && typeof data === "object" && dataProp in data
      ? data[dataProp as keyof typeof data]
      : data) as CommonObjectContent[]);
  const items = useMemo(
    () =>
      filterItemsByProps({
        items: filterItemsByName({
          items: itemsToUse
            ? itemsToUse.map((e) => ({
                ...(getItemID ? { itemID: getItemID(e) } : {}),
                ...e,
              }))
            : [],
          nameFilter,
          nameProperty,
        }),
        filters,
      }),
    [itemsToUse, filters, getItemID, nameFilter, nameProperty]
  );

  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const editName = parsedSearch.get("edit") ?? "";
  const editRevision = parsedSearch.get("rev") ?? "";
  const editTab = parsedSearch.get("tab") ?? "";
  const CurrentTitleComponent = TitleComponent ?? ControlHeaderTitle;

  const content = (
    <Table
      {...rest}
      columns={columns}
      itemIdProp={rest.itemIdProp ?? "itemID"}
      items={items}
      status={status}
      loadingString={rest?.loadingString ?? `Loading ${title}...`}
      totalCount={itemsToUse?.length}
      sortable
      resizable
      fillRest
      infoline
      infoLineExtraInfo={
        Object.keys(getServerSideFilters(filters))?.length > 0 &&
        !areFiltersInDefaultState(filters)
          ? ["Filtered"]
          : []
      }
      refetch={refetch}
      error={error}
      isRefetching={isRefetching}
      remove={remove}
      controlHeader={
        !hideHeader &&
        (rest.controlHeader ?? (
          <ControlHeaderContainer>
            <FlexContainer flexStart gap style={{ marginLeft: 8 }}>
              {controlHeaderBeginning && (
                <FlexElement>{controlHeaderBeginning}</FlexElement>
              )}
              {nameProperty && (
                <FlexElement>
                  <FlexContainer flexStart style={{ marginRight: -12 }}>
                    <FlexElement>
                      <Search
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        placeholder={
                          nameFilterPlaceholder ?? "Filter names, IDs"
                        }
                      />
                    </FlexElement>
                    <FlexElement>
                      <NameFilterInfo nameProperty={nameProperty} />
                    </FlexElement>
                  </FlexContainer>
                </FlexElement>
              )}
              {filters.length > 0 && (
                <FlexElement>
                  <Filters
                    filters={filters}
                    groupProperties={filterGroupProperties}
                    filterKey={title.toLowerCase().replaceAll(" ", "-")}
                  />
                </FlexElement>
              )}
              <FlexElement>
                <CurrentTitleComponent>{title}</CurrentTitleComponent>
              </FlexElement>
              <FlexElement style={{ marginLeft: "auto" }}>
                <HeaderButtonContainer>
                  <div>
                    <HeaderProgress isRefetching={isRefetching} />
                  </div>
                  <Tooltip title="Reload">
                    <Button variant="ghost_icon" onClick={() => refetch()}>
                      <Icon data={refresh} />
                    </Button>
                  </Tooltip>
                  <ExtendedDownloads
                    items={downloadItems ?? data}
                    filteredItems={downloadItems ? undefined : items}
                    columns={downloadColumns ?? columns}
                    filename={filename ?? title}
                    disabled={status !== "success"}
                    hideDownloadFiltered={
                      !!downloadItems || hideDownloadFiltered
                    }
                  />
                  {createNew && (
                    <Button variant="ghost_icon" onClick={createNew}>
                      <Icon data={add} />
                    </Button>
                  )}
                </HeaderButtonContainer>
              </FlexElement>
            </FlexContainer>
          </ControlHeaderContainer>
        ))
      }
    />
  );

  return {
    filters,
    filtersDispatch,
    content,
    editName,
    editRevision,
    editTab,
  };
}
