import { Controller, useFormContext } from "react-hook-form";
import Dropzone from "react-dropzone";

export function DropzoneField({ name }: { name: string }) {
  const form = useFormContext();

  return (
    <Controller
      control={form.control}
      name={name}
      rules={{
        required: { value: true, message: "This field is required" },
      }}
      render={({ field: { onChange, onBlur }, fieldState }) => (
        <Dropzone
          noClick
          onDrop={(acceptedFiles) => {
            form.setValue(name, acceptedFiles as unknown as FileList, {
              shouldValidate: true,
            });
          }}
          multiple={false}
        >
          {({
            getRootProps,
            getInputProps,
            open,
            isDragActive,
            acceptedFiles,
          }) => (
            <div>
              <div
                style={{
                  border: "4px dashed var(--borderDefault)",
                  borderRadius: 10,
                  padding: 16,
                  backgroundColor: isDragActive ? `#dddddd` : "transparent",
                }}
                {...getRootProps()}
              >
                <input
                  {...getInputProps({
                    id: name,
                    onChange,
                    onBlur,
                  })}
                />

                <div>
                  <button type="button" onClick={open}>
                    Choose a file
                  </button>{" "}
                  or drop one here
                </div>
                <div style={{ marginTop: 16 }}>
                  {acceptedFiles.length
                    ? acceptedFiles[0].name
                    : "No file selected."}

                  {fieldState.error && (
                    <span role="alert">{fieldState.error.message}</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      )}
    />
  );
}
