import { Button, Typography } from "@equinor/eds-core-react";
import { FlexElement } from "components/Components";
import { SemiBold, SubtitleContainer, SubtitleItem } from "components/Subtitle";
import {
  AbsoluteScroller,
  FlexContainerFullHeight,
  FlexElementFullHeight,
} from "components/FullHeightComponents";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFill,
  ModalWindowContentFixed,
  ModalWindowPrimaryContainer,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import { FieldContext } from "components/form/FieldContext";
import { closeEditSheet, editSheet } from "features/sheets/commands";
import {
  SaveVDSSubsegment,
  VDSItemSections,
  VDSSubsegment,
  VDSSubsegmentHeader,
} from "features/sheets/types";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSaveVDSSubsegment from "features/sheets/queries/useSaveVDSSubsegment";
import useSheet from "features/sheets/queries/useSheet";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { confirmNavigation } from "utils/confirmNavigation";
import { successMessageWithId } from "utils/successMessage";
import { Infoline } from "../../../../components/ModalComponents";
import { VDSValidators, statusesReducer } from "../VDSCommonProperties";
import {
  HeadingSection1,
  HeadingSection2,
  HeadingSection3,
  HeadingSection4,
} from "./HeadingSection";
import { ListSection } from "./ListSection";
import { TextBlockSelector } from "./TextBlockSelector";
import { useAttemptSaveVDSSubsegementProperties } from "./useAttemptSaveVDSSubsegementProperties";
import { QueryStatus } from "react-query";

type EditVDSSubsegmentProps = Partial<{
  isCreate: boolean;
  isEdit: boolean;
  isCopy: boolean;
  name: string;
  revision: string;
  tab: string;
  group: number;
  createNew: boolean;
  showCreateNew: boolean;
  setShowCreateNew: React.Dispatch<boolean>;
}>;

type EditVDSSubsegmentPropsForModes = EditVDSSubsegmentProps &
  (
    | {
        isEdit: true;
        name: string;
        revision: string;
        tab: string;
      }
    | {
        isCreate: true;
        group: number;
        showCreateNew: boolean;
        setShowCreateNew: React.Dispatch<boolean>;
      }
    | {
        isCopy: true;
        name: string;
        revision: string;
        showCreateNew: boolean;
        setShowCreateNew: React.Dispatch<boolean>;
      }
  );

export function EditVDSSubsegment({
  isCreate,
  isEdit,
  isCopy,
  name,
  revision,
  tab,
  group,
  createNew,
  showCreateNew,
  setShowCreateNew,
}: EditVDSSubsegmentPropsForModes) {
  const isOpen =
    !!(isEdit && name && revision && !tab) ||
    !!(isCreate && showCreateNew) ||
    !!(isCopy && showCreateNew);

  return isOpen ? (
    <EditVDSSubsegmentModal
      isEdit={isEdit}
      isCreate={isCreate}
      isCopy={isCopy}
      name={name}
      revision={revision}
      tab={tab}
      group={group}
      createNew={createNew}
      showCreateNew={showCreateNew}
      setShowCreateNew={setShowCreateNew}
    />
  ) : (
    <></>
  );
}

export const DisabledInputContainer = styled.div`
  min-width: 250px;
  // Hide adornment
  input + div {
    display: none;
  }
`;

function transformDataToSave(data: VDSSubsegment): SaveVDSSubsegment {
  const header =
    "getVDSSubsegmentHeader" in data
      ? data.getVDSSubsegmentHeader[0]
      : undefined;
  let designCounter = 0;
  let materialCounter = 0;
  let miscReqCounter = 0;
  let lastDesingItemId = 0;
  let lastMaterialItemId = 0;
  let lastMiscReqItemId = 0;
  return {
    ValveTypeTextBlockID: header?.ValveTypeTextBlockID ?? "",
    ValveTypeTextBlockRevision: header?.ValveTypeTextBlockRevision ?? "",
    ValveTypeRevision: header?.ValveTypeRevision ?? "",
    DesignCodeTextBlockID: header?.DesignCodeTextBlockID ?? "",
    DesignCodeTextBlockRevision: header?.DesignCodeTextBlockRevision ?? "",
    DesignCodeRevision: header?.DesignCodeRevision ?? "",
    SizeRangeTextBlockID: header?.SizeRangeTextBlockID ?? "",
    SizeRangeTextBlockRevision: header?.SizeRangeTextBlockRevision ?? "",
    SizeRangeRevision: header?.SizeRangeRevision ?? "",
    RatingClassTextBlockID: header?.RatingClassTextBlockID ?? "",
    RatingClassTextBlockRevision: header?.RatingClassTextBlockRevision ?? "",
    RatingClassRevision: header?.RatingClassRevision ?? "",
    MaxDesignPressureRevision: header?.MaxDesignPressureRevision ?? "",
    AtTemperatureRevision: header?.AtTemperatureRevision ?? "",
    VDSSubsegmentDesign: data.getVDSSubsegmentDesign?.map((e) => {
      if (e.DesignItemID !== lastDesingItemId) {
        designCounter = 0;
        lastDesingItemId = e.DesignItemID;
      }
      designCounter++;
      return {
        DesignSubItemID: designCounter,
        DesignItemRevision: e.DesignItemRevision,
        TextBlockID: e.TextBlockID,
        TextBlockRevision: e.TextBlockRevision,
      };
    }),
    VDSSubsegmentMaterial: data.getVDSSubsegmentMaterial?.map((e) => {
      if (e.MaterialItemID !== lastMaterialItemId) {
        materialCounter = 0;
        lastMaterialItemId = e.MaterialItemID;
      }
      materialCounter++;
      return {
        MaterialSubItemID: materialCounter,
        MaterialItemRevision: e.MaterialItemRevision,
        TextBlockID: e.TextBlockID,
        TextBlockRevision: e.TextBlockRevision,
      };
    }),
    VDSSubsegmentMiscReq: data.getVDSSubsegmentMiscReq?.map((e) => {
      if (e.MiscReqItemID !== lastMiscReqItemId) {
        miscReqCounter = 0;
        lastMiscReqItemId = e.MiscReqItemID;
      }
      miscReqCounter++;
      return {
        MiscReqSubItemID: miscReqCounter,
        MiscReqItemRevision: e.MiscReqItemRevision,
        TextBlockID: e.TextBlockID,
        TextBlockRevision: e.TextBlockRevision,
      };
    }),
  };
}

const emptySubsegment: VDSSubsegment = {
  getVDSSubsegmentHeader: [
    {
      SubsegmentID: 0,
      Revision: "",
      Status: "",
      RevDate: "",
      LastUpdateBy: "",
      GroupID: 0,
      GroupName: "",
      Notepad: "",
      SubsegmentName: "",
      VDSTextBlockID: "",
      ValveType: "",
      ValveTypeRevision: "",
      ValveTypeTextBlockID: "",
      ValveTypeTextBlockRevision: "",
      DesignCode: "",
      DesignCodeRevision: "",
      DesignCodeTextBlockID: "",
      DesignCodeTextBlockRevision: "",
      SizeRangeFrom: "",
      SizeRangeTo: "",
      SizeRangeComment: "",
      Unit: "",
      SizeRangeRevision: "",
      SizeRangeTextBlockID: "",
      SizeRangeTextBlockRevision: "",
      RatingClass: "",
      RatingClassRevision: "",
      RatingClassTextBlockID: "",
      RatingClassTextBlockRevision: "",
      MaxDesignPressure1: "",
      MaxDesignPressure2: "",
      MaxDesignPressure3: "",
      MaxDesignPressure4: "",
      MaxDesignPressure5: "",
      MaxDesignPressure6: "",
      MaxDesignPressure7: "",
      MaxDesignPressure8: "",
      MaxDesignPressure9: "",
      MaxDesignPressure10: "",
      MaxDesignPressure11: "",
      MaxDesignPressure12: "",
      MaxDesignPressureRevision: "",
      AtTemperature1: "",
      AtTemperature2: "",
      AtTemperature3: "",
      AtTemperature4: "",
      AtTemperature5: "",
      AtTemperature6: "",
      AtTemperature7: "",
      AtTemperature8: "",
      AtTemperature9: "",
      AtTemperature10: "",
      AtTemperature11: "",
      AtTemperature12: "",
      AtTemperatureRevision: "",
    },
  ],
  getVDSSubsegmentProperties: [
    {
      ValveTypeProperty: "",
      RatingClassProperty: "",
      MaterialGroupProperty: "",
      EndConnectionProperty: "",
      BoreProperty: "",
      VDSSizeProperty: "",
      SpecialRequirementProperty: "",
    },
  ],
  getVDSSubsegmentDesign: [],
  getVDSSubsegmentMaterial: [],
  getVDSSubsegmentMiscReq: [],
  getVDSSubsegmentCharInherited: [],
  getVDSSubsegmentCharIncludeExclude: [],
  getVDSSubsegmentCharSum: [],
};

const removeRevisions = (data: VDSSubsegment) => ({
  ...data,
  getVDSSubsegmentHeader: [
    {
      ...data.getVDSSubsegmentHeader[0],
      ValveTypeRevision: "",
      DesignCodeRevision: "",
      SizeRangeRevision: "",
      RatingClassRevision: "",
      MaxDesignPressureRevision: "",
      AtTemperatureRevision: "",
    },
  ],
  getVDSSubsegmentDesign: data.getVDSSubsegmentDesign.map((e) => ({
    ...e,
    DesignItemRevision: "",
  })),
  getVDSSubsegmentMaterial: data.getVDSSubsegmentMaterial.map((e) => ({
    ...e,
    MaterialItemRevision: "",
  })),
  getVDSSubsegmentMiscReq: data.getVDSSubsegmentMiscReq.map((e) => ({
    ...e,
    MiscReqItemRevision: "",
  })),
});

export function VDSSubsegmentSubtitle({
  heading,
  status,
  group,
  hideName,
}: {
  heading: VDSSubsegmentHeader | undefined;
  status: QueryStatus;
  group?: number;
  hideName?: boolean;
}) {
  const { data: vdsSubsegmentGroups, status: vdsSubsegmentGroupsStatus } =
    useCodelist({ codelist: "vds-subsegment-groups" });
  return (
    <SubtitleContainer>
      {vdsSubsegmentGroupsStatus === "success" &&
        (status === "idle" || status === "success") && (
          <SubtitleItem>
            Group:{" "}
            <SemiBold>
              {vdsSubsegmentGroups?.find((g) => group === g.GroupID)
                ?.Description ?? heading?.GroupName}
            </SemiBold>
          </SubtitleItem>
        )}
      {heading?.SubsegmentName && !hideName && (
        <SubtitleItem>
          Name: <SemiBold>{heading.SubsegmentName}</SemiBold>
        </SubtitleItem>
      )}
    </SubtitleContainer>
  );
}

function EditVDSSubsegmentModal({
  isCreate,
  isEdit,
  isCopy,
  name,
  revision,
  tab,
  group,
  createNew,
  showCreateNew,
  setShowCreateNew,
}: EditVDSSubsegmentProps) {
  const history = useHistory();

  const { isConfirmed } = useConfirm();

  const [createdName, setCreatedName] = useState<string | undefined>(undefined);
  const [formData, setFormData] = useState<VDSSubsegment | undefined>(
    undefined
  );

  const {
    data: loadedData,
    status,
    error,
  } = useSheet({
    sheetType: "vds-subsegments",
    name,
    revision,
    disabled: isCreate,
    cacheTime: 0,
  });

  const data = useMemo(
    () => (isCreate ? emptySubsegment : loadedData),
    [isCreate, loadedData]
  );

  const methods = useForm<VDSSubsegment>({
    defaultValues: data,
    mode: "all",
    shouldUnregister: true,
  });

  const { handleSubmit, reset, watch, setValue, formState } = methods;

  useEffect(() => {
    data && reset(isCopy ? removeRevisions(data) : data);
  }, [data, reset, isCopy]);

  const formValues = watch();

  const { dirtyFields } = formState;

  const [statuses, statusesDispatch] = useReducer(statusesReducer, {});

  const closeModal = async () => {
    if (
      (!Object.keys(dirtyFields).length && !(isCopy || isCreate)) ||
      (await confirmNavigation({ isConfirmed }))
    ) {
      closeEditSheet({ history });
      setShowCreateNew && setShowCreateNew(false);
    }
  };

  const { data: allConfigData, status: allConfigStatus } = useCodelist({
    codelist: "vds-subsegment-groups-config",
    enabled: status === "success" || isCreate,
  });

  const heading = data?.getVDSSubsegmentHeader[0];
  const designs = data?.getVDSSubsegmentDesign;
  const materials = data?.getVDSSubsegmentMaterial;
  const miscReqs = data?.getVDSSubsegmentMiscReq;

  const configData = useMemo(
    () =>
      allConfigData?.filter((e) => e.GroupID === (group ?? heading?.GroupID)),
    [allConfigData, heading, group]
  );

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(...Object.values(statuses), status, allConfigStatus),
    [allConfigStatus, status, statuses]
  );

  /* Configuration */
  const headingSectionConfig = configData?.filter((e) => e.ItemSection === "H");
  const designSectionConfig = configData?.filter((e) => e.ItemSection === "D");
  const materialSectionConfig = configData?.filter(
    (e) => e.ItemSection === "M"
  );
  const miscReqSectionConfig = configData?.filter((e) => e.ItemSection === "R");

  /* Actual textblocks, which can differ from the configuration in case there was a configuration change */
  const headingTextBlocks = useMemo(
    () =>
      [
        heading?.ValveTypeTextBlockID,
        heading?.DesignCodeTextBlockID,
        heading?.SizeRangeTextBlockID,
        heading?.RatingClassTextBlockID,
      ].filter((e) => !!e),
    [heading]
  );

  const [containerRef, containerSize] = useMeasure();
  const [titleRef, titleSize] = useMeasure();

  const [resized, setResized] = useState(false);

  useEffect(() => {
    setResized(true);
  }, [containerSize]);

  useEffect(() => {
    resized && setResized(false);
  }, [resized]);

  const validatorGap = 18;

  const validatorHeight =
    containerSize.height - validatorGap * 2 - titleSize.height;

  const validatorWidth = validatorHeight < 500 ? 701 + 20 : 701;

  const [itemSection, setItemSection] = useState<VDSItemSections>("H");
  const [itemId, setItemId] = useState<number>(0);
  const [selectorOpen, setSelectorOpen] = useState(false);

  const textBlockIds = useMemo(() => {
    const formHeading =
      formValues.getVDSSubsegmentHeader &&
      formValues.getVDSSubsegmentHeader.length > 0 &&
      formValues.getVDSSubsegmentHeader[0];
    return formHeading
      ? [
          formHeading.ValveTypeTextBlockID,
          formHeading.DesignCodeTextBlockID,
          formHeading.SizeRangeTextBlockID,
          formHeading.RatingClassTextBlockID,
        ]
      : [];
  }, [formValues]);

  const {
    mutate: saveVDSSubsegment,
    status: saveVDSSubsegmentStatus,
    error: saveVDSSubsegmentError,
    reset: saveVDSSubsegmentReset,
    data: saveVDSSubsegmentData,
  } = useSaveVDSSubsegment({ disableListInvalidation: isCreate || isCopy });

  // It seems that calling invalidateQueries while another invalidation did not yet finished
  // does not trigger a re-invalidation, which leads in our case a just partially refreshed
  // VDS Subsegment list in case a new Subsegment is created. This is caused by the fact that
  // the Subsegment is created first, then the properties are saved, and there have been
  // invalidation in both calls. Removing the invalidation from the save call fixed this issue.

  const shouldClose = useRef<boolean>(false);

  const resetForm = () => {
    reset(undefined, { keepValues: true, keepDirty: false });
  };

  const onSuccess = useCallback(() => {
    if (shouldClose.current) {
      resetForm();
      saveVDSSubsegmentReset();
      closeModal();
    }
    // closeModal is not useCallback-able
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, saveVDSSubsegmentReset]);

  const onSettledClose = useCallback(() => {
    saveVDSSubsegmentReset();
  }, [saveVDSSubsegmentReset]);

  const onSuccessClose = useCallback(() => {
    reset(undefined, { keepValues: true });
    saveVDSSubsegmentReset();
    setShowCreateNew && setShowCreateNew(false);
  }, [reset, saveVDSSubsegmentReset, setShowCreateNew]);

  const {
    attemptSaveVDSSubsegmentContent,
    onSubmit: propertiesOnSubmit,
    vdsSubsegmentPropertiesReset,
    affectedProgress,
  } = useAttemptSaveVDSSubsegementProperties({
    data: formData ?? data,
    name: createdName ?? name,
    revision: createdName ? "0" : revision,
    onlyError: true,
  });

  const createOrUpdateVDSSubsegment = useCallback(
    (data: VDSSubsegment) => {
      saveVDSSubsegment({
        name: isEdit ? name : undefined,
        revision: isEdit ? revision : undefined,
        content: transformDataToSave(data),
        method: isEdit ? "put" : "post",
        group: isCopy ? heading?.GroupID : isCreate ? group : undefined,
      });
      setFormData(data);
    },
    [
      group,
      heading?.GroupID,
      isCopy,
      isCreate,
      isEdit,
      name,
      revision,
      saveVDSSubsegment,
    ]
  );

  const submitData = useRef({} as VDSSubsegment);

  const formDataFromSubsegmentData = useCallback(
    (subsegmentData: VDSSubsegment) => ({
      ...subsegmentData.getVDSSubsegmentProperties[0],
      VDSCharacteristicProperty: [
        ...(data?.getVDSSubsegmentCharInherited.map(
          (e) => e.CharacteristicID
        ) ?? []),
        ...(data?.getVDSSubsegmentCharIncludeExclude
          .filter((ie) => ie.IncludeExcludeIndicator === "I")
          .map((i) => i.CharacteristicID) ?? []),
      ]
        .filter(
          (inherited) =>
            !data?.getVDSSubsegmentCharIncludeExclude
              .filter((ie) => ie.IncludeExcludeIndicator === "E")
              .map((e) => e.CharacteristicID)
              .includes(inherited)
        )
        .join(","),
    }),
    [
      data?.getVDSSubsegmentCharIncludeExclude,
      data?.getVDSSubsegmentCharInherited,
    ]
  );

  const onSubmit = (submittedData: VDSSubsegment) => {
    submitData.current = submittedData;

    if (isEdit) {
      propertiesOnSubmit(formDataFromSubsegmentData(submitData.current));
    }
    if (isCreate || isCopy) {
      createOrUpdateVDSSubsegment(submitData.current);
    }
  };

  useEffect(() => {
    if (isEdit) {
      if (affectedProgress === "done") {
        vdsSubsegmentPropertiesReset();
        createOrUpdateVDSSubsegment(submitData.current);
      }
    }
  }, [
    createOrUpdateVDSSubsegment,
    isEdit,
    vdsSubsegmentPropertiesReset,
    affectedProgress,
  ]);

  useEffect(() => {
    if (isCreate || isCopy) {
      if (saveVDSSubsegmentStatus === "success" && saveVDSSubsegmentData) {
        setCreatedName(saveVDSSubsegmentData.data.AdditionalInfo);
      }
    }
  }, [
    formDataFromSubsegmentData,
    isCopy,
    isCreate,
    propertiesOnSubmit,
    saveVDSSubsegmentData,
    saveVDSSubsegmentStatus,
  ]);

  useEffect(() => {
    if (isCreate || isCopy) {
      if (saveVDSSubsegmentStatus && !!createdName) {
        propertiesOnSubmit(formDataFromSubsegmentData(submitData.current));
      }
    }
  }, [
    createdName,
    formDataFromSubsegmentData,
    isCopy,
    isCreate,
    propertiesOnSubmit,
    saveVDSSubsegmentStatus,
  ]);

  const calculatedStatus = useMemo(
    () =>
      saveVDSSubsegmentStatus === "error"
        ? "error"
        : affectedProgress === "done" && saveVDSSubsegmentStatus === "success"
        ? "success"
        : affectedProgress === "processing" ||
          affectedProgress === "ready-to-save" ||
          saveVDSSubsegmentStatus === "loading"
        ? "loading"
        : // This is the case when the properties are saved, but the subsegment is not yet saved
        affectedProgress === "done" && saveVDSSubsegmentStatus === "success"
        ? "loading"
        : saveVDSSubsegmentStatus,
    [affectedProgress, saveVDSSubsegmentStatus]
  );

  const flattenFields = useCallback(
    (fields) =>
      "getVDSSubsegmentProperties" in fields &&
      Array.isArray(fields.getVDSSubsegmentProperties)
        ? Object.keys(fields.getVDSSubsegmentProperties[0])
        : [],
    []
  );

  return (
    <>
      <ModalWindow
        isOpen={true}
        closeModal={closeModal}
        title={
          isEdit
            ? `Edit VDS Subsegment ${name} rev. ${revision}`
            : "Create a new VDS Subsegment"
        }
        status={combinedStatuses}
        error={error}
        layer="primary"
        hasInfoline
        loadingString="Loading VDS Subsegment..."
        subtitle={
          <VDSSubsegmentSubtitle
            heading={heading}
            status={status}
            group={group}
          />
        }
      >
        {data && (
          <FieldContext.Provider
            value={{ disabled: isCopy || isCreate, clear: isCopy }}
          >
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalWindowPrimaryContainer>
                  <ModalWindowContentFill>
                    <FlexContainerFullHeight
                      ref={containerRef}
                      /* for the unlikely case that the flex here wraps */
                      style={{ overflow: "auto" }}
                    >
                      <FlexElementFullHeight style={{ minWidth: 400 }}>
                        <AbsoluteScroller>
                          <ModalSideMargin>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 24,
                              }}
                            >
                              {heading &&
                              headingSectionConfig &&
                              (headingSectionConfig?.length ||
                                headingTextBlocks.length) ? (
                                <div>
                                  <Typography variant="h4">
                                    Heading Section
                                  </Typography>
                                  <DisabledInputContainer>
                                    <HeadingSection1
                                      data={data}
                                      config={headingSectionConfig}
                                      setSelectorOpen={setSelectorOpen}
                                      setItemSection={setItemSection}
                                      setItemId={setItemId}
                                    />
                                    <HeadingSection2
                                      data={data}
                                      config={headingSectionConfig}
                                      setSelectorOpen={setSelectorOpen}
                                      setItemSection={setItemSection}
                                      setItemId={setItemId}
                                    />
                                    <HeadingSection3
                                      data={data}
                                      config={headingSectionConfig}
                                      setSelectorOpen={setSelectorOpen}
                                      setItemSection={setItemSection}
                                      setItemId={setItemId}
                                    />
                                    <HeadingSection4
                                      data={data}
                                      config={headingSectionConfig}
                                      setSelectorOpen={setSelectorOpen}
                                      setItemSection={setItemSection}
                                      setItemId={setItemId}
                                    />
                                  </DisabledInputContainer>
                                </div>
                              ) : (
                                <></>
                              )}
                              {designSectionConfig &&
                              designs &&
                              (designSectionConfig.length || designs.length) ? (
                                <ListSection
                                  section="D"
                                  config={designSectionConfig}
                                  itemSection={itemSection}
                                  setItemSection={setItemSection}
                                  itemId={itemId}
                                  setItemId={setItemId}
                                />
                              ) : (
                                <></>
                              )}
                              {materialSectionConfig &&
                              materials &&
                              (materialSectionConfig.length ||
                                materials?.length) ? (
                                <ListSection
                                  section="M"
                                  config={materialSectionConfig}
                                  itemSection={itemSection}
                                  setItemSection={setItemSection}
                                  itemId={itemId}
                                  setItemId={setItemId}
                                />
                              ) : (
                                <></>
                              )}
                              {miscReqSectionConfig &&
                              miscReqs &&
                              (miscReqSectionConfig.length ||
                                miscReqs.length) ? (
                                <ListSection
                                  section="R"
                                  config={miscReqSectionConfig}
                                  itemSection={itemSection}
                                  setItemSection={setItemSection}
                                  itemId={itemId}
                                  setItemId={setItemId}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </ModalSideMargin>
                        </AbsoluteScroller>
                      </FlexElementFullHeight>
                      <FlexElementFullHeight
                        style={{
                          maxWidth: validatorWidth,
                          minWidth: validatorWidth,
                        }}
                      >
                        <AbsoluteScroller
                          style={{ overflow: resized ? "hidden" : "auto" }}
                        >
                          <ModalSideMargin>
                            <FlexElement
                              style={{
                                borderLeft: "1px solid var(--borderDefault)",
                                paddingLeft: 20,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div ref={titleRef}>
                                <Typography
                                  variant="h4"
                                  style={{ marginBottom: 6 }}
                                >
                                  Valid for
                                </Typography>
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  gap: validatorGap,
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignContent: "flex-start",
                                }}
                              >
                                <VDSValidators
                                  data={
                                    data.getVDSSubsegmentProperties
                                      ? data.getVDSSubsegmentProperties[0]
                                      : undefined
                                  }
                                  statusesDispatch={statusesDispatch}
                                  autoSize={true}
                                  fullHeight={validatorHeight}
                                  preProp="getVDSSubsegmentProperties.0."
                                  isEdit={isEdit}
                                  flattenFields={flattenFields}
                                />
                              </div>
                            </FlexElement>
                          </ModalSideMargin>
                        </AbsoluteScroller>
                      </FlexElementFullHeight>
                    </FlexContainerFullHeight>
                  </ModalWindowContentFill>
                  <ModalWindowContentFixed>
                    <ModalWindowButtonContainer hasInfoline>
                      {isEdit && (
                        <Button
                          type="submit"
                          variant="outlined"
                          disabled={
                            combinedStatuses !== "success" ||
                            calculatedStatus === "loading"
                          }
                          onClick={() => {
                            shouldClose.current = false;
                          }}
                        >
                          Save
                        </Button>
                      )}
                      {isEdit && (
                        <Button
                          type="submit"
                          disabled={
                            combinedStatuses !== "success" ||
                            calculatedStatus === "loading"
                          }
                          onClick={() => {
                            shouldClose.current = true;
                          }}
                        >
                          Save & Close
                        </Button>
                      )}
                      {(isCreate || isCopy) && (
                        <Button
                          type="submit"
                          disabled={
                            combinedStatuses !== "success" ||
                            calculatedStatus === "loading" ||
                            ((isCreate || isCopy) &&
                              calculatedStatus === "success")
                          }
                        >
                          Create
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          closeModal();
                        }}
                        variant="outlined"
                      >
                        {isEdit ? "Close" : "Cancel"}
                      </Button>
                    </ModalWindowButtonContainer>
                    <ModalWindowContentFixed>
                      {heading && <Infoline item={heading} />}
                    </ModalWindowContentFixed>
                  </ModalWindowContentFixed>
                </ModalWindowPrimaryContainer>
              </form>
            </FormProvider>
          </FieldContext.Provider>
        )}
      </ModalWindow>
      {selectorOpen && (
        <TextBlockSelector
          isOpen={selectorOpen}
          setIsOpen={setSelectorOpen}
          itemSection={itemSection}
          setItemSection={setItemSection}
          itemId={itemId}
          setItemId={setItemId}
          groupsConfig={configData}
          setValue={setValue}
          disabledItems={textBlockIds}
          title="Heading"
        />
      )}
      {attemptSaveVDSSubsegmentContent}
      <StatusModal
        status={calculatedStatus}
        error={saveVDSSubsegmentError}
        onSuccess={onSuccess}
        onSettledClose={onSettledClose}
        onSuccessClose={onSuccessClose}
        successMessage={successMessageWithId(saveVDSSubsegmentData)}
        extraButton={
          (isCreate || isCopy) && saveVDSSubsegmentStatus === "success" ? (
            <Button
              variant="outlined"
              onClick={() => {
                editSheet({
                  history,
                  item: {
                    SubsegmentID: saveVDSSubsegmentData.data.AdditionalInfo,
                    Revision: "0",
                  },
                  sheetType: "vds-subsegments",
                });
                saveVDSSubsegmentReset();
                setShowCreateNew && setShowCreateNew(false);
              }}
            >
              Open the new Subsegment
            </Button>
          ) : null
        }
      />
    </>
  );
}
