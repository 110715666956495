import { VDSItemSections } from "features/sheets/types";
import { ValveElementGroups } from "./../features/admin/valve-element-groups/valveElementGroupsConfig";
import { PipeElementGroup } from "./../features/admin/pipe-element-groups/pipeElementGroupsConfig";
import axios from "axios";
import { PCSStandardNotes } from "features/admin/pcs-standard-notes/pcsStandardNotesConfig";
import { useQuery } from "react-query";
import config from "../config/config";
import { PlantMapping } from "features/admin/plant-mapping/plantMappingConfig";
import { AdminRoles, UserRoles } from "app/userConfig";
import { SheetStatus } from "features/sheets/config/statusConfig";

export type VDSSubsegmentGroupsConfig = {
  ItemID: number;
  ItemDescription: string;
  ItemSection: VDSItemSections;
  GroupID: number;
  GroupDescription: string;
};

export type MasterPipeElementCodelistItem = {
  MasterPipeElementID: number;
  MaterialGroup: string;
  ElementGroup: string;
  DimStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  Priority: number;
  WeldingFactorValue: number;
  AddDimensionManually: "Y" | "N";
};

export type StructuredPipeElementCodelistItem = {
  MaterialGroup: string;
  ElementGroup: string;
  ElementID: number;
  Revision: string;
  RevDate: string;
  Status: string;
  LastUpdate: string;
  LastUpdateBy: string;
  Priority: number;
  DimStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
  Note: string;
};

export type CodelistSpecification = {
  "valve-types": {
    ValveTypeID: number;
    Description: string;
    VDSCode: string;
    LongDescription: string;
    FBRBIndicator: "N" | "Y";
    ValveCategoryID: number;
    MainValveType: string;
    SubValveType: string;
    Seat: string;
    Pattern: string;
    Miscellaneous: string;
    HDIndicator: "N" | "Y";
  };
  "end-connections": {
    EndConnectionID: number;
    Description: string;
    VDSCode: string;
  };
  "material-groups": {
    MaterialGroupID: number;
    MaterialGroup: string;
    Description: string;
    Density: number;
    VDSCode1: string;
    VDSCode2: string;
    Sequence: number;
    MaterialType: string;
    PEBEWthMM: number;
    MinDesignTemperature: number;
    AllowableStress: number;
    InUse: "Y" | "N";
  };
  "special-requirements": {
    SpecialRequirementID: number;
    Description: string;
  };
  "vds-subsegment-groups": {
    GroupID: number;
    Description: string;
    OptionalSubsegment: "N" | "Y";
    Sequence: number;
    InUse: "Y" | "N";
  };
  "vds-subsegment-groups-config": VDSSubsegmentGroupsConfig;
  "rating-classes": { RatingClassID: number; Description: string };
  bores: {
    BoreID: number;
    Description: string;
  };
  "vds-sizes": { VDSSizeID: number; Description: string };
  "pcs-standard-notes": PCSStandardNotes;
  "nps-schedules": {
    NomSize: string;
    OuterDiameter: string;
    WallThickness: string;
    Schedule: string;
  };
  "under-tolerances": { ID: number; UnderToleranceValue: string };
  "pipe-element-groups": PipeElementGroup;
  "valve-element-groups": ValveElementGroups;
  "embedded-notes": { NoteID: string; NoteText: string };
  "pcs-embedded-note-template": { HTMLContent: string };
  "plant-categories": { CategoryID: number; Description: string };
  areas: {
    AreaID: number;
    Description: string;
  };
  "matrix-types": {
    MatrixTypeID: number;
    Description: string;
  };
  "vsk-groups": {
    GroupID: number;
    Description: string;
  };
  "product-form-level2": {
    ProductFormLevel2ID: number;
    Description: string;
  };
  "remote-systems": {
    RemoteSystemName: string;
  };
  "plant-mapping": PlantMapping;
  "vds-characteristics": {
    CharacteristicID: number;
    Description: string;
  };
  "vds-header": {
    ItemID: number;
    Description: string;
  };
  "vds-misc": {
    ItemID: number;
    Description: string;
    VDSKGroups: {
      GroupID: number;
      Description: string;
    }[];
  };
  "vds-material": {
    ItemID: number;
    Description: string;
  };
  "vds-design": {
    ItemID: number;
    Description: string;
  };
  "configuration-parameters": {
    ID: number;
    URL: string;
    Description: string;
  };
  "capability-role-matrix": {
    Capability: string;
    Role: UserRoles;
    AdminRole: AdminRoles;
    Description: string;
    Comment: string;
  };
  "housing-design": {
    HousingDesignID: number;
    Description: string;
    Sequence: string;
  };
  "vds/new-name-series": {
    SerieID: number;
    Description: string;
    SerieStart: string;
    SerieEnd: string;
  };
  "master-matrices": {
    TableID: number;
    Revision: string;
    RevDate: string;
    Status: SheetStatus;
    Name: string;
    MatrixType: string;
    Source: string;
    SSTReference: "N" | "Y";
    Overlength: "N" | "Y";
    AllowableStress: number;
    FixedCols: number;
    FixedRows: number;
  };
  "vds-end-connections": {
    EndConnection: string;
  };
  "pmg/master-pipe-elements": MasterPipeElementCodelistItem;
  "pmg/structured-pipe-elements": StructuredPipeElementCodelistItem;
  "article-rules": {
    RuleID: number;
    RuleName: string;
    Description: string;
    DBProcedure: string;
  };
  "weight-rules": {
    RuleID: number;
    RuleName: string;
    Description: string;
    DBProcedure: string;
  };
  subelements: {
    OID: number;
    ParentOID: number;
    Description: string;
    FirstLevelIndicator: "Y" | "N";
    LowestLevelIndicator: "Y" | "N";
    ArticleRuleID: number;
    WeightRuleID: number;
    PDMSElementCodeID: number;
    PDMSSymbolKeyID: number;
  };
  "pmg-dimension-standards": {
    DimStandardID: number;
    Description: string;
    ElementGroupID: number;
  };
  "pmg-productforms": {
    ProductFormID: number;
    Description: string;
    ElementGroupID: number;
  };
  "material-grades": {
    MaterialGradeID: number;
    MaterialTypeID: number;
    StandardOrg: string;
    Materialstandard: string;
    MaterialGrade: string;
  };
  "component-types": {
    ComponentTypeID: string;
    Description: string;
  };
};

export type Codelists = keyof CodelistSpecification;

type CodelistProperties = {
  listProperty: string;
  idProp: string;
  nameProp: string;
  titleSingular?: string;
  titlePlural?: string;
  property?: string;
  noCodelistInURL?: boolean;
  vdsProperty?: string;
};

export const codelistProperties: { [index: string]: CodelistProperties } = {
  "valve-types": {
    listProperty: "getValveTypes",
    idProp: "ValveTypeID",
    nameProp: "Description",
    titleSingular: "Valve Type",
    titlePlural: "Valve Types",
  },
  "end-connections": {
    listProperty: "getEndConnections",
    idProp: "EndConnectionID",
    nameProp: "Description",
    titleSingular: "End Connection",
    titlePlural: "End Connections",
  },
  "material-groups": {
    listProperty: "getMaterialGroup",
    idProp: "MaterialGroupID",
    nameProp: "MaterialGroup",
    titleSingular: "Material Group",
    titlePlural: "Material Groups",
    property: "MaterialGroup",
    vdsProperty: "MaterialTypeID",
  },
  "special-requirements": {
    listProperty: "getSpecialRequirement",
    idProp: "SpecialRequirementID",
    nameProp: "Description",
    titleSingular: "Special Requirement",
    titlePlural: "Special Requirements",
    property: "SpecialReq",
  },
  "vds-subsegment-groups": {
    listProperty: "getVDSSubsegmentGroups",
    idProp: "GroupID",
    nameProp: "Description",
    titleSingular: "VDS Subsegment Group",
    titlePlural: "VDS Subsegment Groups",
  },
  "vds-subsegment-groups-config": {
    listProperty: "getVDSSubsegmentGroupConfig",
    idProp: "ItemID",
    nameProp: "ItemDescription",
    titleSingular: "VDS Subsegment Group Config",
    titlePlural: "",
  },
  "rating-classes": {
    listProperty: "getRatingClass",
    idProp: "RatingClassID",
    nameProp: "Description",
    titleSingular: "Rating Class",
    titlePlural: "Rating Classes",
    property: "RatingClass",
  },
  bores: {
    listProperty: "getBore",
    idProp: "BoreID",
    nameProp: "Description",
    titleSingular: "Bore",
    titlePlural: "Bores",
  },
  "vds-sizes": {
    listProperty: "getVDSSize",
    idProp: "VDSSizeID",
    nameProp: "Description",
    titleSingular: "VDS Size",
    titlePlural: "VDS Sizes",
  },
  "pcs-standard-notes": {
    listProperty: "getStandardNotes",
    idProp: "NoteID",
    nameProp: "NoteText",
    titleSingular: "PCS Standard Note",
    titlePlural: "PCS Standard Notes",
  },
  "nps-schedules": {
    listProperty: "getNPSSchedules",
    idProp: "",
    nameProp: "",
  },
  "under-tolerances": {
    listProperty: "getUnderTolerances",
    idProp: "",
    nameProp: "",
  },
  "pipe-element-groups": {
    listProperty: "getPipeElementGroups",
    idProp: "ElementGroupID",
    nameProp: "ElementGroup",
  },
  "valve-element-groups": {
    listProperty: "getValveElementGroups",
    idProp: "ValveGroupID",
    nameProp: "ValveGroup",
  },
  "embedded-notes": {
    listProperty: "getEmbeddedNotes",
    idProp: "NoteID",
    nameProp: "NoteText",
  },
  "pcs-embedded-note-template": {
    listProperty: "getEmbeddedNoteTemplate",
    idProp: "",
    nameProp: "",
  },
  "plant-categories": {
    listProperty: "getPlantCategory",
    idProp: "CategoryID",
    nameProp: "Description",
  },
  areas: {
    listProperty: "getArea",
    idProp: "",
    nameProp: "",
  },
  "matrix-types": {
    listProperty: "getMatrixType",
    idProp: "MatrixTypeID",
    nameProp: "Description",
  },
  "vsk-groups": {
    listProperty: "getVSKGroups",
    idProp: "GroupID",
    nameProp: "Description",
  },
  "product-form-level2": {
    listProperty: "getProductFormLevel2",
    idProp: "ProductFormLevel2ID",
    nameProp: "Description",
  },
  "remote-systems": {
    listProperty: "getRemoteSystem",
    idProp: "RemoteSystemName",
    nameProp: "RemoteSystemName",
  },
  "plant-mapping": {
    listProperty: "getPlantMapping",
    idProp: "MappingID",
    nameProp: "ShortDescription",
  },
  "vds-characteristics": {
    listProperty: "getVDSCharacteristics",
    idProp: "CharacteristicID",
    nameProp: "Description",
  },
  "vds-header": {
    listProperty: "getVDSHeader",
    idProp: "ItemID",
    nameProp: "Description",
  },
  "vds-misc": {
    listProperty: "getVDSMisc",
    idProp: "ItemID",
    nameProp: "Description",
  },
  "vds-material": {
    listProperty: "getVDSMaterial",
    idProp: "ItemID",
    nameProp: "Description",
  },
  "vds-design": {
    listProperty: "getVDSDesign",
    idProp: "ItemID",
    nameProp: "Description",
  },
  "configuration-parameters": {
    listProperty: "getConfigParams",
    idProp: "ID",
    nameProp: "Description",
  },
  "capability-role-matrix": {
    listProperty: "getCapabilityRoleMtarix",
    idProp: "Capability",
    nameProp: "Description",
  },
  "housing-design": {
    listProperty: "getHousingDesign",
    idProp: "HousingDesignID",
    nameProp: "Description",
    titleSingular: "Housing Design",
  },
  "vds/new-name-series": {
    listProperty: "getVDSNewNameSeries",
    idProp: "SerieID",
    nameProp: "Description",
    titleSingular: "New Name Series",
    noCodelistInURL: true,
  },
  "master-matrices": {
    listProperty: "getMasterMatrix",
    idProp: "TableID",
    nameProp: "Name",
    titleSingular: "Master Matrix",
    titlePlural: "Master Matrices",
  },
  "vds-end-connections": {
    listProperty: "getVDSEndConnections",
    idProp: "EndConnection",
    nameProp: "EndConnection",
  },
  "pmg/master-pipe-elements": {
    listProperty: "getMasterPipeElements",
    idProp: "MasterPipeElementID",
    nameProp: "MasterPipeElementID",
    titleSingular: "Master Pipe Element",
    titlePlural: "Master Pipe Elements",
    noCodelistInURL: true,
  },
  "pmg/structured-pipe-elements": {
    listProperty: "getStructuredPipeElements",
    idProp: "ElementID",
    nameProp: "ElementID",
    titleSingular: "Structured Pipe Element",
    titlePlural: "Structured Pipe Elements",
    noCodelistInURL: true,
  },
  "article-rules": {
    listProperty: "getArticleRule",
    idProp: "RuleID",
    nameProp: "RuleName",
    titleSingular: "Article Rule",
    titlePlural: "Article Rules",
  },
  "weight-rules": {
    listProperty: "getWeightRule",
    idProp: "RuleID",
    nameProp: "RuleName",
    titleSingular: "Weight Rule",
    titlePlural: "Weight Rules",
  },
  subelements: {
    listProperty: "getSubElement",
    idProp: "OID",
    nameProp: "Description",
    titleSingular: "Subelement",
    titlePlural: "Subelements",
  },
  "pmg-dimension-standards": {
    listProperty: "getPMGDimStandard",
    idProp: "DimStandardID",
    nameProp: "Description",
    titleSingular: "Dimension Standard",
    titlePlural: "Dimension Standards",
  },
  "pmg-productforms": {
    listProperty: "getPMGProductForm",
    idProp: "ProductFormID",
    nameProp: "Description",
    titleSingular: "Product Form",
    titlePlural: "Product Forms",
  },
  "material-grades": {
    listProperty: "getMaterialGrade",
    idProp: "MaterialGradeID",
    nameProp: "MaterialGrade",
    titleSingular: "Material Grade",
    titlePlural: "Material Grades",
  },
  "component-types": {
    listProperty: "getComponentType",
    idProp: "ComponentTypeID",
    nameProp: "Description",
    titleSingular: "Component Type",
    titlePlural: "Component Types",
  },
};

type ExtraParams = {
  [key: string]: string | number;
};

type Subpath = string;

type CodelistQueryKey = ["codelists", Codelists, Subpath, ExtraParams?];

export const codelistQueryFn = async <T extends Codelists>({
  queryKey,
}: {
  queryKey: CodelistQueryKey;
}): Promise<CodelistSpecification[T][]> => {
  const [urlPart, codelist, subpath, extraParams] = queryKey;
  const { noCodelistInURL, listProperty } = codelistProperties[codelist];
  const url =
    config.restApiBaseUrl +
    `${noCodelistInURL ? `` : `${urlPart}/`}${codelist}${
      subpath ? `/${subpath}` : ""
    }${
      extraParams
        ? Object.keys(extraParams).reduce((acc, curr: string) => {
            return (acc += `${curr}=${extraParams[curr]}&`);
          }, "?")
        : ""
    }`;
  const { data } = await axios.get(url);
  return listProperty ? data[listProperty] : data;
};

export function useCodelist<T extends Codelists>({
  codelist,
  extraParams,
  subpath,
  enabled,
}: {
  codelist: T;
  extraParams?: ExtraParams;
  subpath?: string;
  enabled?: boolean;
}) {
  return useQuery<
    CodelistSpecification[T][],
    Error,
    CodelistSpecification[T][],
    CodelistQueryKey
  >(
    [
      "codelists",
      codelist,
      ...(subpath ? [subpath] : [""]),
      ...(extraParams ? [extraParams] : [""]),
    ] as CodelistQueryKey,
    codelistQueryFn,
    {
      enabled: enabled,
    }
  );
}
