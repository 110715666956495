import { TopBar } from "@equinor/eds-core-react";
import React from "react";
import { Link } from "react-router-dom";
import * as Space from "react-spaces";
import styled from "styled-components";
import Content from "../Content";
import { MainMenu } from "MainMenu";
import { H1Element } from "./Components";
import { breakpoints, breakpointsNumber } from "./breakpoints";

export const AppContainer = styled.div`
  background: var(--fadedBg);
  inset: 0;
  position: absolute;
  min-width: 768px;
  min-height: 768px;
`;

const MenuContainer = styled(Space.LeftResizable)`
  z-index: 200;
  @media (min-width: ${breakpoints.lg}) {
    margin: 1em 1em 1.5em 1em;
  }
`;

const ContentContainer = styled(Space.Fill)`
  background: var(--bg);
  position: absolute;
  inset: 0;
  border-radius: 4px;
  margin-bottom: 1.5em;
  @media (min-width: ${breakpoints.lg}) {
    margin: 1em 1em 1.5em 0;
  }
`;

export const AppHeader = styled(TopBar)`
  border-bottom: none;
  z-index: 99 !important;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  line-height: inherit;
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const AppTitleFullContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em 0 1em;
`;

const AppTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.35em;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1.25;
`;

const AppSubtitleContainer = styled.div`
  font-size: 50%;
  font-weight: normal;
  text-transform: uppercase;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  padding: 0.2em 0.3em 0;
  @media (min-width: ${breakpoints.lg}) {
    padding: 0.1em 0.25em 0;
  }
`;

export function AppSubtitle() {
  const subtitleColor =
    process.env.REACT_APP_SUBTITLE === "test"
      ? "var(--warningText)"
      : process.env.REACT_APP_SUBTITLE === "dev"
      ? "var(--dangerText)"
      : process.env.REACT_APP_SUBTITLE === "local"
      ? "var(--successText)"
      : "inherit";

  return !!process.env.REACT_APP_SUBTITLE ? (
    <div style={{ marginTop: -4 }}>
      <AppSubtitleContainer
        style={{
          color: subtitleColor,
          borderColor: subtitleColor,
        }}
      >
        {process.env.REACT_APP_SUBTITLE}
      </AppSubtitleContainer>
    </div>
  ) : null;
}

export function AppTitle({
  secondary,
  link,
}: {
  secondary?: boolean;
  link?: boolean;
}) {
  return (
    <H1Element style={secondary ? { marginBottom: 0 } : {}}>
      <AppTitleContainer>
        <div>
          {link ? (
            <Link
              to="/"
              style={{ textDecoration: "none", color: "var(--text)" }}
            >
              {process.env.REACT_APP_TITLE}
            </Link>
          ) : (
            process.env.REACT_APP_TITLE
          )}
        </div>

        <AppSubtitle />
      </AppTitleContainer>
    </H1Element>
  );
}

export const AppHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AppHeaderElement = styled.div``;

export function AppWithSettingsLoaded({ pageWidth }: { pageWidth: number }) {
  return (
    <>
      <MenuContainer
        size={pageWidth >= breakpointsNumber.lg ? 267 : 0}
        minimumSize={200}
        maximumSize={pageWidth / 2}
        scrollable={true}
      >
        <MainMenu />
      </MenuContainer>
      <ContentContainer scrollable={true}>
        <Content />
      </ContentContainer>
    </>
  );
}
