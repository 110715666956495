import React, { useEffect } from "react";
import {
  PanelContainer,
  PanelButtonContainer,
  PanelParagraph,
} from "../../../components/Panels";
import { Button, Icon } from "@equinor/eds-core-react";
import { arrow_forward } from "@equinor/eds-icons";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { selectMainPlant } from "../../plant/plantSlice";
import { setPipeElementFilter } from "../sheetSlice";
import { useForm, SubmitHandler } from "react-hook-form";
import usePipeDimensions from "../hooks/usePipeDimensions";
import usePipeElements from "../hooks/usePipeElements";
import { TopPadder, Tabs } from "../../../components/Components";
import { stripSheetArrayToIDs } from "../util";
import { successMessage } from "../../../utils/successMessage";
import { reloadPreviewPane } from "../SheetPane";
import { useModifyPipeElementInSheets } from "../queries/usePipeElementsQuery";
import StatusModal from "../../../components/StatusModal";
import { pcsCommandOnSelected } from "../commands";
import usePCSCommandQuery from "../queries/usePCSCommandQuery";
import { useTabs } from "../../../components/tabs/useTabs";
import Area from "../../../components/Area";
import { useToggleButtons } from "../../../components/useToggleButtons";
import useConfirm from "components/confirm/useConfirm";
import { PCSConfirmQuestion } from "./ConfirmQuestion";

function AddPipeElement({ selectedItems }: { selectedItems: any[] }) {
  const mainPlant = useAppSelector(selectMainPlant);
  const { register, handleSubmit, formState } = useForm({
    shouldUnregister: true,
    mode: "all",
  });
  const { fromSize, toSize, pipeDimensionsContent } = usePipeDimensions({
    formState,
    register,
  });
  const { pipeElementsContent, selection, selectionDispatch } = usePipeElements(
    {
      existing: false,
    }
  );
  const {
    mutate: modifyPipeElement,
    status: modifyPipeElementStatus,
    reset: modifyPipeElementReset,
    error: modifyPipeElementError,
    data: modifyPipeElementData,
  } = useModifyPipeElementInSheets();

  const { isConfirmed } = useConfirm();

  const onSubmit: SubmitHandler<any> = async (data) => {
    (await isConfirmed(
      PCSConfirmQuestion({
        message: `add Pipe Element ID ${selection[0]} to`,
        selectedItems,
      })
    )) &&
      modifyPipeElement({
        plant: mainPlant,
        sheets: stripSheetArrayToIDs(selectedItems, "pcs"),
        fromSize,
        toSize,
        method: "post",
        elementID: selection[0],
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {pipeElementsContent}
      <TopPadder>{pipeDimensionsContent}</TopPadder>
      <PanelButtonContainer>
        <Button
          type="submit"
          disabled={
            selectedItems.length === 0 ||
            selection.length === 0 ||
            !!Object.keys(formState.errors).length
          }
        >
          Add Pipe Element
        </Button>
      </PanelButtonContainer>
      <StatusModal
        status={modifyPipeElementStatus}
        successMessage={successMessage(modifyPipeElementData)}
        onSettledClose={modifyPipeElementReset}
        onSuccess={() => {
          reloadPreviewPane("main");
        }}
        onSuccessClose={() => {
          selectionDispatch({ type: "reset" });
        }}
        error={modifyPipeElementError}
      />
    </form>
  );
}

function RemovePipeElement({ selectedItems }: { selectedItems: any[] }) {
  const dispatch = useAppDispatch();
  const mainPlant = useAppSelector(selectMainPlant);
  const { handleSubmit } = useForm({
    shouldUnregister: true,
    mode: "all",
  });
  const { pipeElementsContent, selection } = usePipeElements({
    existing: true,
  });
  const {
    mutate: modifyPipeElement,
    status: modifyPipeElementStatus,
    reset: modifyPipeElementReset,
    error: modifyPipeElementError,
    data: modifyPipeElementData,
  } = useModifyPipeElementInSheets();

  const { isConfirmed } = useConfirm();

  const onSubmit: SubmitHandler<any> = async (data) => {
    (await isConfirmed(
      PCSConfirmQuestion({
        message: `remove Pipe Element ID ${selection[0]} from`,
        selectedItems,
      })
    )) &&
      modifyPipeElement({
        plant: mainPlant,
        sheets: stripSheetArrayToIDs(selectedItems, "pcs"),
        method: "delete",
        elementID: selection[0],
      });
  };

  useEffect(() => {
    dispatch(setPipeElementFilter(selection[0]));
  }, [selection, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPipeElementFilter(0));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {pipeElementsContent}
      <PanelButtonContainer>
        <Button
          type="submit"
          disabled={selectedItems.length === 0 || selection.length === 0}
        >
          Remove Pipe Element
        </Button>
      </PanelButtonContainer>
      <StatusModal
        status={modifyPipeElementStatus}
        successMessage={successMessage(modifyPipeElementData)}
        onSettledClose={modifyPipeElementReset}
        onSuccess={() => {
          reloadPreviewPane("main");
        }}
        error={modifyPipeElementError}
      />
    </form>
  );
}

function ChangePipeElement({ selectedItems }: { selectedItems: any[] }) {
  const dispatch = useAppDispatch();
  const mainPlant = useAppSelector(selectMainPlant);
  const { handleSubmit } = useForm({
    shouldUnregister: true,
    mode: "all",
  });
  const { pipeElementsContent, selection, groupID } = usePipeElements({
    existing: true,
  });
  const {
    pipeElementsContent: newPipeElementsContent,
    selection: newElementSelection,
  } = usePipeElements({
    existing: false,
    disableGroupSelector: true,
    selectedGroupID: groupID,
  });
  const {
    mutate: modifyPipeElement,
    status: modifyPipeElementStatus,
    reset: modifyPipeElementReset,
    error: modifyPipeElementError,
    data: modifyPipeElementData,
  } = useModifyPipeElementInSheets();

  const { isConfirmed } = useConfirm();

  const onSubmit: SubmitHandler<any> = async (data) => {
    (await isConfirmed(
      PCSConfirmQuestion({
        message: `change Pipe Element from ID ${selection[0]} to ID ${newElementSelection[0]} in`,
        selectedItems,
      })
    )) &&
      modifyPipeElement({
        plant: mainPlant,
        sheets: stripSheetArrayToIDs(selectedItems, "pcs"),
        method: "put",
        elementID: selection[0],
        newElementID: newElementSelection[0],
      });
  };

  useEffect(() => {
    dispatch(setPipeElementFilter(selection[0]));
  }, [selection, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPipeElementFilter(0));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Area.Container>
        <Area.FillElement style={{ maxWidth: 1000 }}>
          {pipeElementsContent}
        </Area.FillElement>
        <Area.NormalElement style={{ flexBasis: 70 }}>
          <Area.CenterContent>
            <Icon data={arrow_forward} color="secondary" />
          </Area.CenterContent>
        </Area.NormalElement>
        <Area.FillElement style={{ maxWidth: 1000 }}>
          {newPipeElementsContent}
        </Area.FillElement>
      </Area.Container>
      <PanelButtonContainer>
        <Button
          type="submit"
          disabled={
            selectedItems.length === 0 ||
            selection.length === 0 ||
            newElementSelection.length === 0
          }
        >
          Change Pipe Element
        </Button>
      </PanelButtonContainer>
      <StatusModal
        status={modifyPipeElementStatus}
        successMessage={successMessage(modifyPipeElementData)}
        onSettledClose={modifyPipeElementReset}
        onSuccess={() => {
          reloadPreviewPane("main");
        }}
        error={modifyPipeElementError}
      />
    </form>
  );
}

function UpdateArea({ selectedItems }: { selectedItems: any[] }) {
  const mainPlant = useAppSelector(selectMainPlant);
  const {
    mutate: pcsCommand,
    status: pcsCommandStatus,
    reset: pcsCommandReset,
    error: pcsCommandError,
    data: pcsCommandData,
  } = usePCSCommandQuery();

  return (
    <>
      <PanelContainer>
        <PanelParagraph>Select sheets to update Area on.</PanelParagraph>
        <PanelButtonContainer>
          <Button
            onClick={() =>
              pcsCommandOnSelected({
                selectedItems,
                mainPlant,
                command: "pipe-elements-area",
                method: "put",
                pcsCommand,
              })
            }
            disabled={selectedItems.length === 0}
          >
            Update Area
          </Button>
        </PanelButtonContainer>
      </PanelContainer>
      <StatusModal
        status={pcsCommandStatus}
        successMessage={successMessage(pcsCommandData)}
        onSettledClose={pcsCommandReset}
        error={pcsCommandError}
      />
    </>
  );
}

function PipeElement({ selectedItems }: { selectedItems: any[] }) {
  const { activeButton, toggleButtonsContent } = useToggleButtons({
    buttons: [
      { key: "add", title: "Add" },
      { key: "remove", title: "Remove" },
      { key: "change", title: "Change" },
    ],
    id: "PipeElement",
  });

  return (
    <>
      <PanelContainer>
        {toggleButtonsContent}
        {activeButton === "add" ? (
          <AddPipeElement selectedItems={selectedItems} />
        ) : activeButton === "remove" ? (
          <RemovePipeElement selectedItems={selectedItems} />
        ) : activeButton === "change" ? (
          <ChangePipeElement selectedItems={selectedItems} />
        ) : (
          <></>
        )}
      </PanelContainer>
    </>
  );
}

function CopyTextFromPMG({ selectedItems }: { selectedItems: any[] }) {
  const dispatch = useAppDispatch();
  const mainPlant = useAppSelector(selectMainPlant);
  const { handleSubmit } = useForm({
    shouldUnregister: true,
    mode: "all",
  });
  const { pipeElementsContent, selection } = usePipeElements({
    existing: true,
  });
  const {
    mutate: pcsCommand,
    status: pcsCommandStatus,
    reset: pcsCommandReset,
    error: pcsCommandError,
    data: pcsCommandData,
  } = usePCSCommandQuery();

  const onSubmit: SubmitHandler<any> = (data) => {
    pcsCommandOnSelected({
      selectedItems,
      mainPlant,
      command: "pipe-elements-text",
      method: "put",
      pcsCommand,
      extraParams: {
        ElementID: selection[0],
      },
    });
  };

  useEffect(() => {
    dispatch(setPipeElementFilter(selection[0]));
  }, [selection, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPipeElementFilter(0));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PanelContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          {pipeElementsContent}
          <PanelButtonContainer>
            <Button
              type="submit"
              disabled={selectedItems.length === 0 || selection.length === 0}
            >
              Copy Text From PMG
            </Button>
          </PanelButtonContainer>
        </form>
      </PanelContainer>
      <StatusModal
        status={pcsCommandStatus}
        successMessage={successMessage(pcsCommandData)}
        onSettledClose={pcsCommandReset}
        error={pcsCommandError}
      />
    </>
  );
}

export const PCSPipeElementsPanel = React.forwardRef(
  (
    {
      selectedItems,
    }: {
      selectedItems: any[];
    },
    ref: React.Ref<any>
  ) => {
    const { activeTab, handleChange } = useTabs({
      name: "PipeElementsPanelTabs",
    });

    return (
      <div ref={ref}>
        <Tabs activeTab={activeTab} onChange={handleChange} scrollable>
          <Tabs.List>
            <Tabs.Tab>Pipe Element</Tabs.Tab>
            <Tabs.Tab>Update Area</Tabs.Tab>
            <Tabs.Tab>Copy Text From PMG</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              {activeTab === 0 && <PipeElement selectedItems={selectedItems} />}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 1 && <UpdateArea selectedItems={selectedItems} />}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 2 && (
                <CopyTextFromPMG selectedItems={selectedItems} />
              )}
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </div>
    );
  }
);

export default PCSPipeElementsPanel;
