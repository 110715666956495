import { plantDependentSheets } from "../config/sheetConfig";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { PlantProps } from "../../plant/types";
import { SheetTypes } from "../config/sheetConfig";
import config from "../../../config/config";

export default function useCopySheets() {
  const queryClient = useQueryClient();

  async function copySheets({
    sourcePlant,
    targetPlant,
    sheetType,
    newRevision,
    sheets,
  }: {
    sourcePlant: PlantProps;
    targetPlant: PlantProps;
    sheetType: SheetTypes;
    newRevision: string;
    sheets: any[];
  }) {
    return await axios({
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      url: `${config.restApiBaseUrl}plants/${targetPlant.PlantID}/${sheetType}?sourcePlantID=${sourcePlant.PlantID}`,
      data: { sheets: sheets, NewRevision: newRevision },
    });
  }

  return useMutation(copySheets, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType)
          ? [variables.targetPlant.PlantID]
          : []),
        variables.sheetType,
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
