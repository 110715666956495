import { Icon, Menu } from "@equinor/eds-core-react";
import { ColumnsProps } from "./table/Table";
import { border_all, border_horizontal, download } from "@equinor/eds-icons";
import { ContextMenu } from "components/ContextMenu";
import {
  SheetTypes,
  plantDependentSheets,
} from "features/sheets/config/sheetConfig";
import { downloadAsXLSX, downloadAsCSV } from "../utils/download";
import { PlantProps } from "features/plant/types";

export function SimpleDownloads({
  columns,
  items,
  filename,
  disabled,
}: {
  columns: ColumnsProps[];
  items: any[] | undefined;
  filename: string;
  disabled?: boolean;
}) {
  return (
    <ContextMenu
      buttonContent={<Icon data={download} />}
      tooltipTitle="Download as..."
      disabled={disabled}
    >
      {items && (
        <Menu.Item onClick={() => downloadAsXLSX({ columns, items, filename })}>
          Download as XLSX
        </Menu.Item>
      )}
      {items && (
        <Menu.Item onClick={() => downloadAsCSV({ columns, items, filename })}>
          Download as CSV
        </Menu.Item>
      )}
    </ContextMenu>
  );
}

export function ExtendedDownloads({
  columns,
  items,
  sheetType,
  plant,
  filteredItems,
  filename,
  hideDownloadAll,
  hideDownloadFiltered,
  disabled,
}: {
  columns: ColumnsProps[];
  items?: any;
  sheetType?: SheetTypes;
  plant?: PlantProps;
  filteredItems?: any;
  filename: string;
  hideDownloadAll?: boolean;
  hideDownloadFiltered?: boolean;
  disabled?: boolean;
}) {
  const finalFilename =
    (sheetType && plant && plantDependentSheets.includes(sheetType)
      ? plant?.ShortDescription + "-"
      : "") + filename;

  return (
    <>
      <ContextMenu
        buttonContent={<Icon data={download} />}
        tooltipTitle="Download..."
        disabled={disabled}
      >
        {!hideDownloadFiltered && (
          <Menu.Item
            onClick={() => {
              downloadAsXLSX({
                columns,
                items: filteredItems,
                filename: finalFilename + "-filtered",
              });
            }}
            disabled={!filteredItems || filteredItems.length === 0}
            key="filtered-xlsx"
          >
            <Icon data={border_horizontal} /> Filtered data as XLSX
          </Menu.Item>
        )}
        {!hideDownloadFiltered && (
          <Menu.Item
            onClick={() => {
              downloadAsCSV({
                columns,
                items: filteredItems,
                filename: finalFilename + "-filtered",
              });
            }}
            disabled={!filteredItems || filteredItems.length === 0}
            key="filtered-csv"
          >
            <Icon data={border_horizontal} /> Filtered data as CSV
          </Menu.Item>
        )}
        {!hideDownloadAll && (
          <Menu.Item
            onClick={() => {
              downloadAsXLSX({
                columns,
                items,
                filename: finalFilename + "-all",
              });
            }}
            disabled={!items || items.length === 0}
            key="all-xlsx"
          >
            <Icon data={border_all} />{" "}
            {hideDownloadFiltered ? "Data" : "All data"} as XLSX
          </Menu.Item>
        )}
        {!hideDownloadAll && (
          <Menu.Item
            onClick={() => {
              downloadAsCSV({
                columns,
                items,
                filename: finalFilename + "-all",
              });
            }}
            disabled={!items || items.length === 0}
            key="all-csv"
          >
            <Icon data={border_all} />{" "}
            {hideDownloadFiltered ? "Data" : "All data"} as CSV
          </Menu.Item>
        )}
      </ContextMenu>
    </>
  );
}
