import { Button, TextField } from "@equinor/eds-core-react";
import { PanelButtonContainer, PanelContainerSingle } from "components/Panels";
import StatusModal from "components/StatusModal";
import useConfirm from "components/confirm/useConfirm";
import useVDSWorkbenchCommand from "features/sheets/queries/useVDSWorkbenchCommand";
import React, { useState } from "react";
import { successMessage } from "utils/successMessage";
import { VDSConfirmQuestion } from "./ConfirmQuestion";

export const VDSUpdateContent = React.forwardRef(
  (
    {
      selectedItems,
    }: {
      selectedItems: any[];
    },
    ref: React.Ref<any>
  ) => {
    const { mutate, status, error, reset, data } = useVDSWorkbenchCommand({
      command: "content",
    });
    const [note, setNote] = useState("");
    const { isConfirmed } = useConfirm();
    return (
      <div ref={ref}>
        <PanelContainerSingle>
          <TextField
            label="VDS Note"
            id="vds-note"
            value={note}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNote(e.target.value)
            }
          />
          <PanelButtonContainer>
            <Button
              disabled={!selectedItems.length}
              onClick={async () => {
                (await isConfirmed(
                  VDSConfirmQuestion({
                    message: `update VDS Content with Note "${note}" in`,
                    selectedItems,
                  })
                )) &&
                  mutate({
                    TaskDescription: note,
                    sheets: selectedItems.map((e) => ({
                      VDS: e.VDS,
                      Revision: e.Revision,
                      Note: note,
                    })),
                  });
              }}
            >
              Update Content
            </Button>
          </PanelButtonContainer>
        </PanelContainerSingle>
        <StatusModal
          status={status}
          error={error}
          onSettledClose={reset}
          successMessage={successMessage(data)}
        />
      </div>
    );
  }
);
