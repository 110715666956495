import { FiltersDispatch } from "components/table/Filters";
import { useCodelist } from "queries/useCodelist";
import { useEffect } from "react";
import { ValveTypes } from "./valveTypesConfig";

export function ValveTypesFilter({
  data,
  filtersDispatch,
  categoryFilter,
  setCategoryFilter,
}: {
  data?: ValveTypes[];
  filtersDispatch: FiltersDispatch;
  categoryFilter: string;
  setCategoryFilter: React.Dispatch<string>;
}) {
  const { data: valveElementGroups } = useCodelist({
    codelist: "valve-element-groups",
  });
  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Valve Element Group",
        prop: "ValveCategoryID",
        filterOptions:
          data && valveElementGroups
            ? data
                .map((i) => ({
                  key: String(i.ValveCategoryID),
                  title:
                    valveElementGroups.find(
                      (e) => e.ValveGroupID === i.ValveCategoryID
                    )?.ValveGroup ?? String(i.ValveCategoryID),
                }))
                .reduce(
                  (a, c) =>
                    a.map((e) => e.key).includes(c.key) ? a : [...a, c],
                  [] as { key: string; title: string }[]
                )
            : [],
        filterState: categoryFilter,
        defaultFilterState: "",
        setFilterState: setCategoryFilter,
        group: "",
      },
    });
    return () => filtersDispatch({ type: "reset" });
  }, [
    data,
    categoryFilter,
    setCategoryFilter,
    filtersDispatch,
    valveElementGroups,
  ]);
  return null;
}
