import axios, { AxiosResponse } from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";
import { EditDocumentData } from "./useEditDocument";
import { StatusResponse } from "components/StatusModal";

export type CreateDocumentType = "D" | "N" | "P" | "U" | "F" | "G" | "C";

type CreateDocumentData = {
  ParentOID: number;
  DocumentType: CreateDocumentType;
  DocumentIDLink: string;
  OperatorID: number;
  PlantID: number;
} & EditDocumentData;

export function useCreateDocument() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      data,
      file,
    }: {
      data: CreateDocumentData;
      file?: File;
    }): Promise<AxiosResponse<StatusResponse>> => {
      const form = new FormData();
      const blob = new Blob();
      form.append("data", JSON.stringify(data));
      form.append("file", file ?? blob);
      return await axios.post(getApiUrl("document-space"), form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["document-space"]);
      },
    }
  );
}
