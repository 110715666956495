import { receipt } from "@equinor/eds-icons";
import { Access } from "Access";
import { Category, MenuItem } from "components/MainMenuComponents";
import React from "react";
import { ReportURIs, reportsConfig } from "./Reports";

export function ReportsMenu() {
  return (
    <Access cap="reports" module="reports" hide>
      <Category title="Reports" addCategoryTitle>
        {Object.keys(reportsConfig).map((report) => {
          const { menuTitle, disabled, disableMenu } =
            reportsConfig[report as ReportURIs];
          return !disabled && !disableMenu ? (
            <Access
              role="X"
              enabled={report === "activity-log"}
              hide
              key={report}
            >
              <MenuItem
                title={menuTitle}
                to={`/reports/${report}`}
                icon={receipt}
              />
            </Access>
          ) : (
            <React.Fragment key={report} />
          );
        })}
      </Category>
    </Access>
  );
}
