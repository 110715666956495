import {
  CircularProgress,
  EdsProvider,
  Label,
  NativeSelect,
  Search,
  Switch,
} from "@equinor/eds-core-react";
import { FilterProps } from "components/table/Filters";
import { useSettings } from "features/settings/useSettings";
import { useUpdateSetting } from "features/settings/useUpdateSetting";
import { combineQueryStatuses } from "queries/queryUtil";
import { CodelistSpecification, useCodelist } from "queries/useCodelist";
import {
  SheetsTypeData,
  useSheetList,
} from "features/sheets/queries/useSheetList";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { filterItemsByProps } from "utils/filterItemsByProps";
import { FlexContainer, FlexElement } from "../../../components/Components";
import Table, {
  ColumnsProps,
  SmallTableContainer,
} from "../../../components/table/Table";
import {
  SelectionReducerAction,
  useTableSelect,
} from "../../../components/table/useTableSelect";
import { filterItemsByName } from "../../../utils/filterItemsByName";
import { PreviewButtons } from "../SheetPane";
import { sheetProperties } from "../config/sheetConfig";
import { sheetStatusConfig } from "../config/statusConfig";
import { getItemID, getItemName } from "../util";

const simpleVDSSubsegmentColumns = [
  { key: "SubsegmentID", title: "Subsegment ID", width: 86 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "SubsegmentName", title: "Subsegment Name", width: "100%" },
];

const extendedVDSSubsegmentColumns: ColumnsProps[] = [
  {
    key: "GroupID",
    title: "Group",
    codelist: "vds-subsegment-groups",
    width: 100,
  },
  { key: "SubsegmentID", title: "ID", longTitle: "Subsegment ID", width: 56 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "SubsegmentName", title: "Subsegment Name", width: "100%" },
  { key: "Status", title: "Status" },
];

const vdsSelectorIncludeAllRevisionsKey = "vdsSelectorIncludeAllRevisions";

export default function useVDSSubsegmentSelector({
  defaultSelection,
  extendedDisplay,
  enabled,
  filters,
}: {
  defaultSelection?: string[];
  extendedDisplay?: boolean;
  enabled?: boolean;
  filters?: FilterProps[];
}): {
  selection: string[];
  selectionDispatch: React.Dispatch<SelectionReducerAction>;
  vdsSelectorContent: React.ReactFragment;
  selectedLines: any[];
  length: number;
  isLoading: boolean;
} {
  const { data, status, isLoading, isRefetching, error, refetch } =
    useSheetList({
      sheetType: "vds-subsegments",
      disabled: !enabled,
    });

  const {
    data: vdsSubsegmentGroupCodelist,
    status: vdsSubsegmentGroupsStatus,
  } = useCodelist({
    codelist: "vds-subsegment-groups",
  });

  const { data: valveTypeCodelist, status: valveTypeStatus } = useCodelist({
    codelist: "valve-types",
  });
  const { data: ratingClassCodelist, status: ratingClassStatus } = useCodelist({
    codelist: "rating-classes",
  });
  const { data: materialGroupCodelist, status: materialGroupStatus } =
    useCodelist({
      codelist: "material-groups",
    });
  const { data: endConnectionCodelist, status: endConnectionStatus } =
    useCodelist({
      codelist: "end-connections",
    });

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        status,
        vdsSubsegmentGroupsStatus,
        valveTypeStatus,
        ratingClassStatus,
        materialGroupStatus,
        endConnectionStatus
      ),
    [
      status,
      vdsSubsegmentGroupsStatus,
      valveTypeStatus,
      ratingClassStatus,
      materialGroupStatus,
      endConnectionStatus,
    ]
  );

  const [nameFilter, setNameFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState("");
  const [valveTypeFilter, setValveTypeFilter] = useState("");
  const [ratingClassFilter, setRatingClassFilter] = useState("");
  const [materialGroupFilter, setMaterialGroupFilter] = useState("");
  const [endConnectionFilter, setEndConnectionFilter] = useState("");

  const [statusFilter, setStatusFilter] = useState(["O", "W"]);

  const { [vdsSelectorIncludeAllRevisionsKey]: defaultIncludeAllRevisions } =
    useSettings();

  const [includeAllRevisions, setIncludeAllRevisions] = useState(
    !!defaultIncludeAllRevisions ?? false
  );

  const parentFilterValveType = useMemo(
    () =>
      String(filters?.find((f) => f.prop === "ValveTypeID")?.filterState) || "",
    [filters]
  );
  const parentFilterRatingClass = useMemo(
    () =>
      String(filters?.find((f) => f.prop === "RatingClassID")?.filterState) ||
      "",
    [filters]
  );
  const parentFilterMaterialGroup = useMemo(
    () =>
      String(filters?.find((f) => f.prop === "MaterialGroupID")?.filterState) ||
      "",
    [filters]
  );
  const parentFilterEndConnection = useMemo(
    () =>
      String(filters?.find((f) => f.prop === "EndConnectionID")?.filterState) ||
      "",
    [filters]
  );

  useEffect(() => {
    setValveTypeFilter(parentFilterValveType);
  }, [parentFilterValveType]);
  useEffect(() => {
    setRatingClassFilter(parentFilterRatingClass);
  }, [parentFilterRatingClass]);
  useEffect(() => {
    setMaterialGroupFilter(parentFilterMaterialGroup);
  }, [parentFilterMaterialGroup]);
  useEffect(() => {
    setEndConnectionFilter(parentFilterEndConnection);
  }, [parentFilterEndConnection]);

  const { selectionMode, selection, selectionDispatch } = useTableSelect({
    selectionMode: "multi",
    selection: defaultSelection,
  });

  const items = useMemo(
    () =>
      data
        ? filterItemsByName({
            nameFilter,
            nameProperty: ["SubsegmentName", "SubsegmentID"],
            items: filterItemsByProps({
              filters: [
                {
                  filterState: groupFilter,
                  prop: "GroupID",
                },
                {
                  filterState: valveTypeFilter,
                  prop: "ValveType",
                  commaSeparated: true,
                },
                {
                  filterState: ratingClassFilter,
                  prop: "RatingClass",
                  commaSeparated: true,
                },
                {
                  filterState: materialGroupFilter,
                  prop: "MaterialGroup",
                  commaSeparated: true,
                },
                {
                  filterState: endConnectionFilter,
                  prop: "EndConnection",
                  commaSeparated: true,
                },
                { filterState: statusFilter, prop: "Status", isArray: true },
              ],
              items: data.sheets,
            }),
          })
        : [],
    [
      data,
      nameFilter,
      groupFilter,
      valveTypeFilter,
      ratingClassFilter,
      materialGroupFilter,
      endConnectionFilter,
      statusFilter,
    ]
  );

  const codelists = useMemo(
    () => ({
      "vds-subsegment-groups": vdsSubsegmentGroupCodelist
        ? Object.fromEntries(
            vdsSubsegmentGroupCodelist.map((i) => [i.GroupID, i.Description])
          )
        : {},
    }),
    [vdsSubsegmentGroupCodelist]
  );

  const previousSelection = useRef(selection);

  useEffect(() => {
    if (includeAllRevisions) {
      const justSelected = selection.filter(
        (selected) => !previousSelection.current.includes(selected)
      );
      if (justSelected.length > 0) {
        const otherRevisions = data?.sheets
          .filter(
            (sheet) =>
              String(sheet.SubsegmentID) === getItemName(justSelected[0])
          )
          .map((sheet) => getItemID(sheet.SubsegmentID, sheet.Revision));
        selectionDispatch({ type: "select", payload: otherRevisions });
      } else {
        const justDeselected = previousSelection.current.filter(
          (selected) => !selection.includes(selected)
        );
        if (justDeselected.length > 0) {
          const otherRevisions = data?.sheets
            .filter(
              (sheet) =>
                String(sheet.SubsegmentID) === getItemName(justDeselected[0])
            )
            .map((sheet) => getItemID(sheet.SubsegmentID, sheet.Revision));
          selectionDispatch({
            type: "deselect",
            payload: otherRevisions,
          });
        }
      }
    }
    previousSelection.current = selection;
  }, [selection, includeAllRevisions, selectionDispatch, data?.sheets]);

  const vdsSelectorContent = enabled ? (
    <SmallTableContainer style={{ minHeight: 500 }}>
      <Table
        density="compact"
        items={items}
        itemIdProp="itemID"
        controlHeader={
          <ControlHeader
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}
            count={items.length}
            isLoading={status === "loading"}
            isRefetching={isRefetching}
            items={items}
            vdsSubsegmentGroups={vdsSubsegmentGroupCodelist}
            groupFilter={groupFilter}
            setGroupFilter={setGroupFilter}
            valveTypeCodelist={valveTypeCodelist}
            ratingClassCodelist={ratingClassCodelist}
            materialGroupCodelist={materialGroupCodelist}
            endConnectionCodelist={endConnectionCodelist}
            valveTypeFilter={valveTypeFilter}
            setValveTypeFilter={setValveTypeFilter}
            ratingClassFilter={ratingClassFilter}
            setRatingClassFilter={setRatingClassFilter}
            materialGroupFilter={materialGroupFilter}
            setMaterialGroupFilter={setMaterialGroupFilter}
            endConnectionFilter={endConnectionFilter}
            setEndConnectionFilter={setEndConnectionFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            includeAllRevisions={includeAllRevisions}
            setIncludeAllRevisions={setIncludeAllRevisions}
          />
        }
        columns={
          extendedDisplay
            ? extendedVDSSubsegmentColumns
            : simpleVDSSubsegmentColumns
        }
        contextData={{ sheetType: "vds-subsegments" }}
        status={combinedStatuses}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={true}
        error={error}
        refetch={refetch}
        loadingString="Loading VDS Subsegments..."
        selectedFirst={!!defaultSelection}
        RowMenu={PreviewButtons}
        codelists={codelists}
        keepSelectionIfItemsChange={true}
        totalCount={data?.sheets.length || 0}
        infoline={true}
      />
    </SmallTableContainer>
  ) : (
    <></>
  );

  return {
    vdsSelectorContent,
    selection,
    selectionDispatch,
    selectedLines: selection.map((s) =>
      data?.sheets.find((i) => i.itemID === s)
    ),
    length: items.length,
    isLoading,
  };
}

const selectStyle = { width: 221 };

function ControlHeader({
  nameFilter,
  setNameFilter,
  count,
  isLoading,
  isRefetching,
  items,
  vdsSubsegmentGroups,
  groupFilter,
  setGroupFilter,
  valveTypeCodelist,
  ratingClassCodelist,
  materialGroupCodelist,
  endConnectionCodelist,
  valveTypeFilter,
  setValveTypeFilter,
  ratingClassFilter,
  setRatingClassFilter,
  materialGroupFilter,
  setMaterialGroupFilter,
  endConnectionFilter,
  setEndConnectionFilter,
  statusFilter,
  setStatusFilter,
  includeAllRevisions,
  setIncludeAllRevisions,
}: {
  nameFilter: string;
  setNameFilter: React.Dispatch<string>;
  count: number;
  isLoading: boolean;
  isRefetching: boolean;
  items: SheetsTypeData<"vds-subsegments">[];
  vdsSubsegmentGroups?: CodelistSpecification["vds-subsegment-groups"][];
  groupFilter: string;
  setGroupFilter: React.Dispatch<string>;
  valveTypeCodelist?: CodelistSpecification["valve-types"][];
  ratingClassCodelist?: CodelistSpecification["rating-classes"][];
  materialGroupCodelist?: CodelistSpecification["material-groups"][];
  endConnectionCodelist?: CodelistSpecification["end-connections"][];
  valveTypeFilter: string;
  setValveTypeFilter: React.Dispatch<string>;
  ratingClassFilter: string;
  setRatingClassFilter: React.Dispatch<string>;
  materialGroupFilter: string;
  setMaterialGroupFilter: React.Dispatch<string>;
  endConnectionFilter: string;
  setEndConnectionFilter: React.Dispatch<string>;
  statusFilter: string[];
  setStatusFilter: React.Dispatch<string[]>;
  includeAllRevisions: boolean;
  setIncludeAllRevisions: React.Dispatch<boolean>;
}) {
  const { updateSetting } = useUpdateSetting();
  return (
    <div style={{ paddingBottom: 12 }}>
      <FlexContainer
        style={{ justifyContent: "flex-start", gap: 24, marginBottom: 12 }}
      >
        <FlexElement>
          <Label htmlFor="nameFilter" label="Subsegment ID, Name" />
          <Search
            id="nameFilter"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            style={selectStyle}
          />
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="Group"
            label="Group"
            onChange={(e) => setGroupFilter(e.target.value)}
            value={groupFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {vdsSubsegmentGroups &&
              vdsSubsegmentGroups.map((group) => (
                <option value={group.GroupID} key={group.GroupID}>
                  {group.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="Status"
            label="Status"
            onChange={(e) => setStatusFilter(e.target.value.split(","))}
            value={statusFilter.join(",")}
            style={selectStyle}
          >
            <option value="O,W">Official, Working</option>
            <option
              value={sheetProperties["vds-subsegments"].statuses.join(",")}
            >
              All Statuses
            </option>
            {sheetProperties["vds-subsegments"].statuses.map((status) => (
              <option value={status} key={status}>
                {sheetStatusConfig[status]}
              </option>
            ))}
          </NativeSelect>
        </FlexElement>
        {!isLoading && (
          <FlexElement style={{ marginLeft: "auto", alignSelf: "flex-end" }}>
            <CircularProgress
              size={16}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                marginRight: "12px",
                verticalAlign: "middle",
              }}
            />
          </FlexElement>
        )}
      </FlexContainer>
      <FlexContainer
        style={{ justifyContent: "flex-start", gap: 24, marginBottom: 12 }}
      >
        <FlexElement>
          <NativeSelect
            id="ValveType"
            label="Valve Type"
            onChange={(e) => setValveTypeFilter(e.target.value)}
            value={valveTypeFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {valveTypeCodelist &&
              valveTypeCodelist.map((valveType) => (
                <option
                  value={valveType.ValveTypeID}
                  key={valveType.ValveTypeID}
                >
                  ({valveType.VDSCode}) {valveType.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="RatingClass"
            label="Rating Class"
            onChange={(e) => setRatingClassFilter(e.target.value)}
            value={ratingClassFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {ratingClassCodelist &&
              ratingClassCodelist.map((ratingClass) => (
                <option
                  value={ratingClass.RatingClassID}
                  key={ratingClass.RatingClassID}
                >
                  {ratingClass.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="MaterialGroup"
            label="Material Group"
            onChange={(e) => setMaterialGroupFilter(e.target.value)}
            value={materialGroupFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {materialGroupCodelist &&
              materialGroupCodelist.map((materialGroup) => (
                <option
                  value={materialGroup.MaterialGroupID}
                  key={materialGroup.MaterialGroupID}
                >
                  {materialGroup.MaterialGroup}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
        <FlexElement>
          <NativeSelect
            id="EndConnection"
            label="End Connection"
            onChange={(e) => setEndConnectionFilter(e.target.value)}
            value={endConnectionFilter}
            style={selectStyle}
          >
            <option value="">*</option>
            {endConnectionCodelist &&
              endConnectionCodelist.map((endConnection) => (
                <option
                  value={endConnection.EndConnectionID}
                  key={endConnection.EndConnectionID}
                >
                  ({endConnection.VDSCode}) {endConnection.Description}
                </option>
              ))}
          </NativeSelect>
        </FlexElement>
      </FlexContainer>
      <FlexContainer>
        <FlexElement>
          <EdsProvider density="comfortable">
            <Switch
              onChange={() => {
                setIncludeAllRevisions(!includeAllRevisions);
                updateSetting({
                  key: vdsSelectorIncludeAllRevisionsKey,
                  value: includeAllRevisions ? "" : "1",
                });
              }}
              checked={includeAllRevisions}
              label="Include all revisions upon (de)selection"
            />
          </EdsProvider>
        </FlexElement>
      </FlexContainer>
    </div>
  );
}
