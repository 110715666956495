import { Button, Icon, Input } from "@equinor/eds-core-react";
import { delete_forever } from "@equinor/eds-icons";
import { FlexContainer, FlexElement } from "components/Components";
import useConfirm from "components/confirm/useConfirm";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import Table, { SmallTableContainer } from "components/table/Table";
import { useCodelist } from "queries/useCodelist";
import useUpdateRemoteSystems, {
  UpdateRemoteSystems,
} from "queries/useUpdateRemoteSystems";
import React, { useState } from "react";
import { successMessage } from "utils/successMessage";

function DeleteCommandButton({
  item,
  updateRemoteSystems,
}: {
  item: { RemoteSystemName: string };
  updateRemoteSystems: UpdateRemoteSystems["mutate"];
}) {
  const { isConfirmed } = useConfirm();
  return (
    <Button
      variant="ghost_icon"
      onClick={async () =>
        (await isConfirmed(
          `Are you sure you want to delete Remote System ${item.RemoteSystemName}?`,
          { buttonColor: "danger" }
        )) &&
        updateRemoteSystems({
          method: "delete",
          remoteSystem: item.RemoteSystemName,
        })
      }
    >
      <Icon data={delete_forever} />
    </Button>
  );
}

export default function RemoteSystemManager({
  remoteSystemManagerOpen,
  closeModal,
}: {
  remoteSystemManagerOpen: boolean;
  closeModal: () => void;
}) {
  const { data, status, error } = useCodelist({ codelist: "remote-systems" });
  const {
    mutate: updateRemoteSystems,
    data: updateRemoteSystemsData,
    status: updateRemoteSystemsStatus,
    error: updateRemoteSystemsError,
    reset: updateRemoteSystemsReset,
  } = useUpdateRemoteSystems();
  const [remoteSystem, setRemoteSystem] = useState("");
  return (
    <>
      <ModalWindow
        isOpen={remoteSystemManagerOpen}
        closeModal={closeModal}
        title="Remote Systems"
      >
        <ModalWindowContentDefault style={{ minWidth: 500 }}>
          <ModalSideMargin style={{ paddingTop: 2 }}>
            <FlexContainer style={{ marginBottom: 24, gap: 16 }}>
              <FlexElement style={{ flex: 1 }}>
                <Input
                  placeholder="New Remote System"
                  value={remoteSystem}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRemoteSystem(e.target.value)
                  }
                />
              </FlexElement>
              <FlexElement>
                <Button
                  onClick={() =>
                    updateRemoteSystems({ remoteSystem, method: "post" })
                  }
                >
                  Create
                </Button>
              </FlexElement>
            </FlexContainer>
            <SmallTableContainer style={{ height: "50vh" }}>
              <Table
                items={data}
                status={status}
                error={error}
                columns={[
                  { key: "RemoteSystemName", title: "Remote System Name" },
                ]}
                itemIdProp="RemoteSystemName"
                Commands={DeleteCommandButton}
                contextData={{ updateRemoteSystems }}
              />
            </SmallTableContainer>
          </ModalSideMargin>
        </ModalWindowContentDefault>
      </ModalWindow>
      <StatusModal
        status={updateRemoteSystemsStatus}
        error={updateRemoteSystemsError}
        successMessage={successMessage(updateRemoteSystemsData)}
        onSuccess={() => setRemoteSystem("")}
        onSettledClose={updateRemoteSystemsReset}
      />
    </>
  );
}
