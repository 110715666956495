import axios from "axios";
import { getApiUrl } from "queries/queryUtil";
import { useMutation, useQueryClient } from "react-query";

export function useUpdateStructuredPipeElement({
  materialGroupId,
  materialGroupRevision,
  masterPipeElementId,
}: {
  materialGroupId: string;
  materialGroupRevision: string;
  masterPipeElementId: number | undefined;
}) {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      subElementId,
      componentTypeId,
      matrixId,
      pipeElements,
    }: {
      subElementId: number;
      componentTypeId: string;
      matrixId: string;
      pipeElements: { elementId: number; revision: string }[];
    }) =>
      await axios({
        url: getApiUrl(
          "pmg/material-groups/" +
            materialGroupId +
            "/rev/" +
            materialGroupRevision +
            "/master-pipe-elements/" +
            masterPipeElementId +
            "/structured-pipe-elements"
        ),
        method: "put",
        data: JSON.stringify({
          SubElementID: String(subElementId),
          ComponentTypeID: componentTypeId,
          MatrixID: matrixId,
          pipeelements: pipeElements.map((e) => ({
            ElementID: e.elementId,
            Revision: e.revision,
          })),
        }),
      }),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["pmg", "material-groups"]);
        queryClient.invalidateQueries([
          "codelists",
          "pmg/structured-pipe-elements",
        ]);
      },
      onError: (error, variables, context) => {
        console.error(`error ${context}`, error, variables);
      },
    }
  );
}
