import { Icon } from "@equinor/eds-core-react";
import { chevron_right } from "@equinor/eds-icons";
import { DocumentSpaceItem } from "./queries/useDocumentSpace";
import { DirectoryElement } from "./DocumentSpace";

export function FolderBreadcrumbs({
  targetParentItems,
  targetItem,
  gap,
}: {
  targetParentItems: DocumentSpaceItem[];
  targetItem: DocumentSpaceItem | undefined;
  gap?: number;
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: gap ?? 8,
        flexWrap: "wrap",
      }}
    >
      {[...targetParentItems, ...(targetItem ? [targetItem] : [])].map(
        (e, i) => (
          <div
            key={e.OID}
            style={{ display: "flex", alignItems: "center", gap: gap ?? 8 }}
          >
            {i > 0 ? (
              <>
                <Icon data={chevron_right} size={16} />
              </>
            ) : (
              <></>
            )}
            <DirectoryElement>{e.Description}</DirectoryElement>
          </div>
        )
      )}
    </div>
  );
}
