import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getApiUrl } from "../../../queries/queryUtil";
import { StatusResponseWithInfo } from "components/StatusModal";

export default function useSetMaterialGroupStatus() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      name,
      revision,
      status,
    }: {
      name: string;
      revision: string;
      status: string;
    }): Promise<AxiosResponse<StatusResponseWithInfo>> =>
      await axios({
        url: getApiUrl(
          "pmg/material-groups/" + name + "/rev/" + revision + "/status"
        ),
        method: "put",
        data: JSON.stringify({ Status: status }),
      }),

    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["pmg", "material-groups"]);
      },
      onError: (error, variables, context) => {
        console.error(`error in ${context}`, error, variables);
      },
    }
  );
}

export type UseSetMaterialGroupStatus = ReturnType<
  typeof useSetMaterialGroupStatus
>;
