import React from "react";

export const FieldContext = React.createContext<{
  hasRev?: boolean;
  disabled?: boolean;
  slim?: boolean;
  clear?: boolean;
}>({
  hasRev: false,
  disabled: false,
  slim: false,
  clear: false,
});
