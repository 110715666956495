import { flame } from "@equinor/eds-icons";
import { Category, MenuItem } from "components/MainMenuComponents";
import { Route } from "react-router-dom";
import { TableExample } from "./table/TableExample";
import { TableWithButtonExample } from "./table/TableWithButtonExample";
import { TableWithFilterExample } from "./table/TableWithFilter";
import { TableWithSelectionExample } from "./table/TableWithSelectionExample";
import { SmallTableExample } from "./table/SmallTableExample";
import { ModalExample } from "./modal/ModalExample";
import { ModalLayersExample } from "./modal/ModalLayersExample";
import { FormExample } from "./form/FormExample";
import { CompactTableExample } from "./table/CompactTable";

export function ExamplesMenu() {
  return (
    <Category title="Examples">
      <MenuItem to="/examples/table/" title="Table" icon={flame}>
        <MenuItem to="/examples/small-table/" title="Small" icon={flame} />
        <MenuItem to="/examples/compact-table/" title="Compact" icon={flame} />
        <MenuItem
          to="/examples/table-with-select/"
          title="With Select"
          icon={flame}
        />
        <MenuItem
          to="/examples/table-with-button/"
          title="With Button"
          icon={flame}
        />
        <MenuItem
          to="/examples/table-with-filter/"
          title="With Filter"
          icon={flame}
        />
      </MenuItem>
      <MenuItem to="/examples/modal/" title="Modal" icon={flame}>
        <MenuItem
          to="/examples/modal-layers/"
          title="Layers"
          icon={flame}
        ></MenuItem>
      </MenuItem>
      <MenuItem to="/examples/form/" title="Form" icon={flame}></MenuItem>
    </Category>
  );
}

export function Examples() {
  return (
    <>
      <Route path="/examples/table">
        <TableExample />
      </Route>
      <Route path="/examples/small-table">
        <SmallTableExample />
      </Route>
      <Route path="/examples/compact-table">
        <CompactTableExample />
      </Route>
      <Route path="/examples/table-with-select">
        <TableWithSelectionExample />
      </Route>
      <Route path="/examples/table-with-button">
        <TableWithButtonExample />
      </Route>
      <Route path="/examples/table-with-filter">
        <TableWithFilterExample />
      </Route>
      <Route path="/examples/modal">
        <ModalExample />
      </Route>
      <Route path="/examples/modal-layers">
        <ModalLayersExample />
      </Route>
      <Route path="/examples/form">
        <FormExample />
      </Route>
    </>
  );
}
