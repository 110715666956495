import React, { useRef, useState, useContext } from "react";
import { FixedSizeList, FixedSizeListProps } from "react-window";
import { Table } from "@equinor/eds-core-react";

const VirtualTableContext = React.createContext<{
  top: number;
  setTop: (top: number) => void;
  header: React.ReactNode;
  tableClassName: string;
  TableComponent: React.ComponentType<any>;
  TableBodyComponent: React.ComponentType<any>;
  TableRowComponent: React.ComponentType<any>;
}>({
  top: 0,
  setTop: (value: number) => {},
  header: <></>,
  tableClassName: "",
  TableComponent: Table,
  TableBodyComponent: Table.Body,
  TableRowComponent: Table.Row,
});

export function VirtualTable({
  row,
  header,
  tableClassName,
  TableComponent,
  TableBodyComponent,
  TableRowComponent,
  nonVirtual,
  style,
  ...rest
}: {
  header?: React.ReactNode;
  row?: FixedSizeListProps["children"];
  tableClassName: string;
  TableComponent: React.ComponentType<any>;
  TableBodyComponent: React.ComponentType<any>;
  TableRowComponent: React.ComponentType<any>;
  style?: React.CSSProperties;
  nonVirtual?: boolean;
} & Omit<FixedSizeListProps, "children" | "innerElementType">) {
  const listRef = useRef<FixedSizeList | null>();
  const [top, setTop] = useState(0);
  const virtualRow = row || VirtualRow;

  if (nonVirtual) {
    const { itemData } = rest;
    const { items, contextData, tableData, Row } = itemData;
    const { itemIdProp } = tableData;
    return (
      <TableComponent className={tableClassName} style={style}>
        {header}
        <TableBodyComponent>
          {items.map((item: any, index: number) => {
            const key =
              item.hasOwnProperty("_type") && item._type === "groupHeader"
                ? `_group_${item[itemIdProp]}`
                : item[itemIdProp];
            return (
              <TableRowComponent
                key={key}
                index={index}
                item={item}
                contextData={contextData}
                tableData={tableData}
                Row={Row}
              />
            );
          })}
        </TableBodyComponent>
      </TableComponent>
    );
  }

  return (
    <VirtualTableContext.Provider
      value={{
        top,
        setTop,
        header,
        tableClassName,
        TableComponent,
        TableBodyComponent,
        TableRowComponent,
      }}
    >
      <FixedSizeList
        {...rest}
        style={style}
        overscanCount={40}
        innerElementType={Inner}
        onItemsRendered={(props: any) => {
          const style =
            listRef.current &&
            // @ts-ignore private method access
            listRef.current._getItemStyle(props.overscanStartIndex);
          setTop((style && style.top) || 0);

          // Call the original callback
          rest.onItemsRendered && rest.onItemsRendered(props);
        }}
        ref={(el) => {
          if (el) listRef.current = el;
        }}
      >
        {virtualRow}
      </FixedSizeList>
    </VirtualTableContext.Provider>
  );
}

const VirtualRow = React.memo(
  ({ index, data }: { index: number; data: any }) => {
    const { items, contextData, tableData, Row } = data;
    const { TableRowComponent } = useContext(VirtualTableContext);
    const item = items[index];
    return (
      <TableRowComponent
        index={index}
        item={item}
        contextData={contextData}
        tableData={tableData}
        Row={Row}
      />
    );
  }
);

const Inner = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function Inner({ children, ...rest }, ref) {
    const { header, top, tableClassName, TableComponent, TableBodyComponent } =
      useContext(VirtualTableContext);
    return (
      <div {...rest} ref={ref}>
        <TableComponent
          style={{ top, position: "absolute", width: "100%" }}
          className={tableClassName}
        >
          {header}
          <TableBodyComponent>{children}</TableBodyComponent>
        </TableComponent>
      </div>
    );
  }
);
